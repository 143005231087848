import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    makeStyles,
    Typography,
    Grid
} from "@material-ui/core";
import TextInput from "./TextInput";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";
import DropdownSelect from "./DropdownSelect";

const EditSchematicsTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        eqptId: VERBIAGE.TEXT.EMPTY_STRING,
        eqpt_type: "NOT_SELECTED", // VERBIAGE.TEXT.EMPTY_STRING,
        label: VERBIAGE.TEXT.EMPTY_STRING,
        type: VERBIAGE.TEXT.EMPTY_STRING,
        x_axis: VERBIAGE.TEXT.EMPTY_STRING,
        y_axis: VERBIAGE.TEXT.EMPTY_STRING,
        zoom: VERBIAGE.TEXT.EMPTY_STRING,
        labelZoom: VERBIAGE.TEXT.EMPTY_STRING,
        labelMargin: VERBIAGE.TEXT.EMPTY_STRING,
        label_x_axis: VERBIAGE.TEXT.EMPTY_STRING,
        labelAlign: VERBIAGE.TEXT.EMPTY_STRING,
        dataZoom: VERBIAGE.TEXT.EMPTY_STRING,
        dataMargin: VERBIAGE.TEXT.EMPTY_STRING,
        data_x_axis: VERBIAGE.TEXT.EMPTY_STRING,
        dataAlign: VERBIAGE.TEXT.EMPTY_STRING,
        status: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleSite = ({ target: { value } }) => {
        updateConfig({ ...config, siteId: value });
    };

    const handleEqpt = ({ target: { value } }) => {
        updateConfig({ ...config, eqptId: value });
    };

    const handleEqptType = ({ target: { value } }) => {
        updateConfig({ ...config, eqpt_type: value });
    };

    const handleLabel = ({ target: { value } }) => {
        updateConfig({ ...config, label: value });
    };

    const handleType = ({ target: { value } }) => {
        updateConfig({ ...config, type: value });
    };

    const handleXAxis = ({ target: { value } }) => {
        updateConfig({ ...config, x_axis: value });
    };

    const handleYAxis = ({ target: { value } }) => {
        updateConfig({ ...config, y_axis: value });
    };

    const handleZoom = ({ target: { value } }) => {
        updateConfig({ ...config, zoom: value });
    };

    const handleLabelZoom = ({ target: { value } }) => {
        updateConfig({ ...config, labelZoom: value });
    };

    const handleLabelMargin = ({ target: { value } }) => {
        updateConfig({ ...config, labelMargin: value });
    };

    const handleLabelXAxis = ({ target: { value } }) => {
        updateConfig({ ...config, label_x_axis: value });
    };

    const handleLabelAlign = ({ target: { value } }) => {
        updateConfig({ ...config, labelAlign: value });
    };

    const handleDataZoom = ({ target: { value } }) => {
        updateConfig({ ...config, dataZoom: value });
    };

    const handleDataMargin = ({ target: { value } }) => {
        updateConfig({ ...config, dataMargin: value });
    };

    const handleDataXAxis = ({ target: { value } }) => {
        updateConfig({ ...config, data_x_axis: value });
    };

    const handleDataAlign = ({ target: { value } }) => {
        updateConfig({ ...config, dataAlign: value });
    };

    const handleStatus = ({ target: { value } }) => {
        updateConfig({ ...config, status: value });
    };

    const handleSubmit = async () => {
        if (config.eqpt_type === "NOT_SELECTED") {
            setError("Please change the Equipment Type to a valid value");
            return;
        }
        if (
            !config.zoom ||
            !config.labelZoom ||
            !config.labelMargin ||
            !config.label_x_axis ||
            !config.labelAlign ||
            !config.dataZoom ||
            !config.dataMargin ||
            !config.data_x_axis ||
            !config.dataAlign ||
            !config.status ||
            !config.y_axis ||
            !config.x_axis ||
            // !config.label ||
            !config.eqptId ||
            !config.siteId
        ) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        setError("");

        const data = {
            siteId: config.siteId,
            eqptId: config.eqptId,
            eqpt_type: config.eqpt_type,
            label: config.label,
            type: config.type,
            x_axis: config.x_axis,
            y_axis: config.y_axis,
            zoom: config.zoom,
            labelZoom: config.labelZoom,
            labelMargin: config.labelMargin,
            label_x_axis: config.label_x_axis,
            labelAlign: config.labelAlign,
            dataZoom: config.dataZoom,
            dataMargin: config.dataMargin,
            data_x_axis: config.data_x_axis,
            dataAlign: config.dataAlign,
            status: config.status
        };
        const url = URL.DATA.PLANT_SETTINGS.SCHEMATICS;
        try {
            if (isEdit) {
                await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } else {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            }
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();
    const eqptTypeOptions = [
        "NOT_SELECTED",
        "PIPES",
        "CHILLER",
        "COOLING_TOWER",
        "CHILLER_PUMP_PRIMARY",
        "CHILLER_PUMP_SECONDARY",
        "CONDENSER_PUMP",
        "TEMPERATURE_SENSOR",
        "PRESSURE_SENSOR",
        "FLOW_SENSOR",
        "GENERIC_KW_SENSOR",
        "VALVE",
        "ARROW"
    ];

    const schematicsDisplayOption = ["SHOW", "HIDE"];

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} the schematics table, please ${
                    isEdit ? "update" : "enter"
                } the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
                              onChange={handleSite}
                              value={config.siteId}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <DropdownSelect
                              name={config.status}
                              options={schematicsDisplayOption}
                              onChange={handleStatus}
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_STATUS}
                              isMulti={false}
                              closeMenuOnSelect
                            />
                            {/* <TextInput
                placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_STATUS}
                onChange={handleStatus}
                value={config.status}
                type="text"
              /> */}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <DropdownSelect
                              name={config.eqpt_type}
                              options={eqptTypeOptions}
                              onChange={handleEqptType}
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_EQPT_TYPE}
                              isMulti={false}
                              closeMenuOnSelect
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_EQPT_ID}
                              onChange={handleEqpt}
                              value={config.eqptId}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LABEL}
                              onChange={handleLabel}
                              value={config.label}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TYPE}
                              onChange={handleType}
                              value={config.type}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_EQPT_ZOOM}
                              onChange={handleZoom}
                              value={config.zoom}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_X_AXIS}
                              onChange={handleXAxis}
                              value={config.x_axis}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_Y_AXIS}
                              onChange={handleYAxis}
                              value={config.y_axis}
                              type="text"
                            />
                        </div>
                    </Grid>
                </Grid>
                <Divider />
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  display="block"
                  variant="body2"
                  align="left"
                >
                    Label Alignment
                </Typography>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LABEL_ALIGN}
                              onChange={handleLabelAlign}
                              value={config.labelAlign}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LABEL_ZOOM}
                              onChange={handleLabelZoom}
                              value={config.labelZoom}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LABEL_X_AXIS}
                              onChange={handleLabelXAxis}
                              value={config.label_x_axis}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LABEL_MARGIN}
                              onChange={handleLabelMargin}
                              value={config.labelMargin}
                              type="text"
                            />
                        </div>
                    </Grid>
                </Grid>
                <Divider />
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  display="block"
                  variant="body2"
                  align="left"
                >
                    Data Alignment
                </Typography>
                <Grid container spacing={-1}>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_DATA_ALIGN}
                              onChange={handleDataAlign}
                              value={config.dataAlign}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_DATA_ZOOM}
                              onChange={handleDataZoom}
                              value={config.dataZoom}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_DATA_X_AXIS}
                              onChange={handleDataXAxis}
                              value={config.data_x_axis}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={EditSchematicsTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_DATA_MARGIN}
                              onChange={handleDataMargin}
                              value={config.dataMargin}
                              type="text"
                            />
                        </div>
                    </Grid>
                </Grid>

                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditSchematicsTable.styles = {
    inputContainer: "webclient__mapTable-inputLeft",
    errorText: "webclient__mapTable-error-text"
};

export default EditSchematicsTable;
