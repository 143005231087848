import React, { useState, useEffect, useMemo } from "react";
import {
    Button,
    makeStyles,
    Typography,
    Grid,
    Paper,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import { Edit, EditOutlined } from "@material-ui/icons";

import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import API from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { getResponseErrorDetails, scrollIntoFirstErrorElement } from "../../../utils/helpers";

import FormRenderField from "../../../dashboard/common/components/FormRenderField";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    title: {
        fontSize: theme.spacing(2),
        fontWeight: "bold"
    },
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    },
    hide: {
        display: "none"
    }
}));

const EditCoolingTowerSpecOverview = ({ ctId }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const { CoolingTowerMtValTypes, CoolingTowerTypes } = useSelector(state => state.dwh.config);

    const CoolingTowerSpecSchema = Yup.object().shape({
        ctDimension: Yup.string(),
        ctManuYear: Yup.number().integer(),
        ctVolt: Yup.number(),
        ctPhase: Yup.number(),
        ctHz: Yup.number(),
        ctHeatrej: Yup.number(),
        ctLift: Yup.number(),
        ctF: Yup.number(),
        ctCwsT: Yup.number(),
        ctCwrT: Yup.number(),
        ctCwDt: Yup.number(),
        ctWbt: Yup.number(),
        ctAppT: Yup.number(),
        ctRpm: Yup.number().integer(),
        ctFanEff: Yup.number(),
        ctMtrEff: Yup.number(),
        ctNoFan: Yup.number().integer(),
        ctFanKw: Yup.number().integer(),
        ctTtlFanKw: Yup.number().integer(),
        ctrMtrValveInstalled: Yup.boolean(),
        ctMtrValType: Yup.mixed().oneOf(CoolingTowerMtValTypes),
        ctMtrEffType: Yup.number(),
        ctPartition: Yup.boolean(),
        ctType: Yup.mixed().oneOf(CoolingTowerTypes),
        ctFanMtrCoupling: Yup.string()
    });

    const initData = useMemo(
        () => ({
            ctDimension: VERBIAGE.TEXT.EMPTY_STRING,
            ctManuYear: 0,
            ctVolt: 0,
            ctPhase: 0,
            ctHz: 0,
            ctHeatrej: 0,
            ctLift: 0,
            ctF: 0,
            ctCwsT: 0,
            ctCwrT: 0,
            ctCwDt: 0,
            ctWbt: 0,
            ctAppT: 0,
            ctRpm: 0,
            ctFanEff: 0,
            ctMtrEff: 0,
            ctNoFan: 0,
            ctFanKw: 0,
            ctTtlFanKw: 0,
            ctrMtrValveInstalled: false,
            ctMtrValType: VERBIAGE.TEXT.EMPTY_STRING,
            ctMtrEffType: 0,
            ctPartition: false,
            ctType: VERBIAGE.TEXT.EMPTY_STRING,
            ctFanMtrCoupling: VERBIAGE.TEXT.EMPTY_STRING
        }),
        [],
    );
    const [values, setValues] = useState(initData);

    useEffect(() => {
        const fetchCoolingTowerSpec = async () => {
            try {
                setLoading(true);

                const url = `${URL.DATA.DWH.COOLING_TOWER_SPEC}/${ctId}/coolingTower`;
                const response = await API.get(url, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data) {
                    setValues({
                        ...initData,
                        ...response.data
                    });
                }
            } catch (err) {
                if (err.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            } finally {
                setLoading(false);
            }
        };

        if (ctId) {
            fetchCoolingTowerSpec();
        }
    }, [ctId, initData]);

    const {
        control,
        handleSubmit,
        setError: setFormError,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(CoolingTowerSpecSchema),
        values
    });

    useEffect(() => {
        if (Object.keys(errors).length) {
            scrollIntoFirstErrorElement({
                elementIds: Object.keys(errors)
            });
        } else {
            setError(null);
        }
    }, [errors]);

    const onClose = () => {
        setError(null);
        setIsEdit(false);
    };

    const toggleEdit = () => {
        setIsEdit(e => !e);
    };

    const handleResponseError = response => {
        const resError = getResponseErrorDetails(response);
        if (resError.type === "invalid") {
            Object.keys(resError.errors).forEach(field => {
                setFormError(field, { type: "invalid", message: resError.errors[field] });
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstErrorElement({ elementIds: Object.keys(resError.errors) });
        } else {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        }
    };

    const onSubmit = async coolingTowerData => {
        try {
            setLoading(true);

            const requestConfig = {
                data: coolingTowerData,
                headers: {
                    "x-auth-token": localStorage.token
                }
            };
            if (values._id) {
                requestConfig.method = "PUT";
                requestConfig.url = `${URL.DATA.DWH.COOLING_TOWER_SPEC}/${values._id}`;
            } else {
                requestConfig.method = "POST";
                requestConfig.url = `${URL.DATA.DWH.COOLING_TOWER_SPEC}/${ctId}/coolingTower`;
            }
            const response = await API.request(requestConfig);
            if (response.data?.status === "error") {
                handleResponseError(response);
                return;
            }

            setValues(prev => ({ ...prev, ...response.data }));
            onClose();
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else if (err.response.status === 404) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            } else {
                handleResponseError(err.response);
            }
        } finally {
            setLoading(false);
        }
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    const classes = useStyles();

    const inputCols1 = [
        {
            name: "ctSpecId",
            label: "CT Specification ID",
            type: "number",
            disabled: true
        },
        {
            name: "ctManuYear",
            label: "CT Manu. Year",
            type: "number",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_MANUFACTURER_YEAR
        },
        {
            name: "ctDimension",
            label: "Dimension",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_DIMENSION
        },
        {
            name: "ctVolt",
            label: "Volts",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctPhase",
            label: "Phase",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctHz",
            label: "Hz",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctHeatrej",
            label: "Heat Rejection",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctLift",
            label: "Lift",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctF",
            label: "Flow",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctCwsT",
            label: "CT_CWS_T",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctCwrT",
            label: "CT_CWR_T",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctCwDt",
            label: "CT_CW_DT",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctWbt",
            label: "CT_WBT",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctAppT",
            label: "CT_APP_T",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctRpm",
            label: "CT_RPM",
            type: "number"
        },
        {
            name: "ctFanEff",
            label: "Fan Eff.",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctMtrEff",
            label: "Motor Eff.",
            type: "number",
            numberType: "double"
        },
        {
            name: "ctNoFan",
            label: "No of Fan",
            type: "number"
        },
        {
            name: "ctFanKw",
            label: "Fan KW",
            type: "number"
        }
    ];

    const inputCols2 = useMemo(() => {
        // remove empty string
        const ctMtrValTypeOptions = [...CoolingTowerMtValTypes].filter(v => v !== "");
        const ctTypeOptions = [...CoolingTowerTypes].filter(v => v !== "");
        return [
            {
                name: "ctTtlFanKw",
                label: "Total Fan KW",
                type: "number"
            },
            {
                name: "ctrMtrValveInstalled",
                label: "Motor Valved Installed",
                type: "switch",
                switchLabels: { true: "Yes", false: "No" }
            },
            {
                name: "ctMtrValType",
                label: "Motor Val Type",
                type: "select",
                selectOptions: ctMtrValTypeOptions,
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_MTR_VAL_TYPE
            },
            {
                name: "ctMtrEffType",
                label: "Motor Eff. Type",
                type: "number",
                numberType: "double"
            },
            {
                name: "ctPartition",
                label: "Partition",
                type: "switch",
                switchLabels: { true: "Yes", false: "No" }
            },
            {
                name: "ctType",
                label: "TYPE",
                type: "select",
                selectOptions: ctTypeOptions,
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_TYPE
            },
            {
                name: "ctFanMtrCoupling",
                label: "Fan Motor Coupling"
            },
            {
                name: "createdAt",
                label: "Created Date",
                disabled: true
            },
            {
                name: "updatedAt",
                label: "Updated Date",
                disabled: true
            }
        ];
    }, [CoolingTowerMtValTypes, CoolingTowerTypes]);

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12} container direction="row" alignItems="center" spacing={1}>
                    <Typography variant="span" className={classes.title}>
                        CT Specification
                    </Typography>
                    {loading ? (
                        <CircularProgress size={12} />
                    ) : (
                        <IconButton onClick={toggleEdit}>
                            {isEdit ? <Edit /> : <EditOutlined />}
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={12} md={6} container spacing={0}>
                    {inputCols1.map(input => {
                        if (input.hide) return null;
                        return (
                            <Grid item container alignItems="center" key={input.name}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">{input.label}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {isEdit ? (
                                        <Controller
                                          name={input.name}
                                          control={control}
                                          render={({ field: { value, onChange } }) => (
                                                <FormRenderField
                                                  input={input}
                                                  value={value}
                                                  onChange={onChange}
                                                  errors={errors}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <Typography>{values[input.name]}</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item xs={12} md={6} container>
                    {inputCols2.map(input => {
                        if (input.hide) return null;
                        return (
                            <Grid item container alignItems="center" key={input.name}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">{input.label}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {isEdit ? (
                                        <Controller
                                          name={input.name}
                                          control={control}
                                          render={({ field: { value, onChange } }) => (
                                                <FormRenderField
                                                  input={input}
                                                  value={value}
                                                  onChange={onChange}
                                                  errors={errors}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <Typography>{values[input.name]?.toString()}</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                {!!error && (
                    <Grid item xs={12} className={classes.error}>
                        <Typography variant="subtitle1">{error}</Typography>
                    </Grid>
                )}
                {isEdit && (
                    <Grid item xs={12} container>
                        <Button
                          color="primary"
                          onClick={handleSubmit(onSubmit, onError)}
                          disabled={loading}
                        >
                            {VERBIAGE.BUTTONS.SUBMIT}
                        </Button>
                        <Button className={classes.error} onClick={onClose} disabled={loading}>
                            {VERBIAGE.BUTTONS.CLOSE}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default EditCoolingTowerSpecOverview;
