import { colors, createMuiTheme } from "@material-ui/core";
import VERBIAGE from "../../utils/enums/Verbiage";

const themeConfigs = [
    {
        name: "LIGHT",
        overrides: {
            MuiInputBase: {
                input: {
                    "&::placeholder": {
                        opacity: 1,
                        color: colors.blueGrey[600]
                    }
                }
            }
        },
        palette: {
            type: "light",
            action: {
                active: colors.blueGrey[600]
            },
            primary: {
                main: VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
            },
            secondary: {
                main: VERBIAGE.COLOUR_LIGHT_BLUE // "#3865A7"
            },
            background: {
                default: "#F2F2F2",
                pid: "#F6F6F6",
                light_grey: "#E2E2E2",
                chart: "#E4E7EB"
            },
            text: {
                light_grey: "#707070",
                light_black: "#414141",
                grey: "#A0A0A0"
            }
        }
    },
    {
        name: "DARK",
        palette: {
            type: "dark",
            action: {
                active: "rgba(255, 255, 255, 0.54)",
                hover: "rgba(255, 255, 255, 0.04)",
                selected: "rgba(255, 255, 255, 0.08)",
                disabled: "rgba(255, 255, 255, 0.26)",
                disabledBackground: "rgba(255, 255, 255, 0.12)",
                focus: "rgba(255, 255, 255, 0.12)"
            },
            primary: {
                main: "#3687FF" // causing header text color to black when dark mode
            },
            secondary: {
                main: VERBIAGE.COLOUR_LIGHT_BLUE // "#8a85ff"
            },
            text: {
                primary: "#e6e5e8",
                secondary: "#adb0bb",
                dark: "#A0A0A0",
                grey: "#ACACAC",
                dark_grey: "#DFDFDF"
            },
            background: {
                default: "#161616",
                tab: "#201f25",
                button: "#444444",
                pid: "#2F2F2F",
                grey_dark: "#3A3A3A",
                chart: "#3A3A3A"
                // header: "#002A41"
            }
        }
    }
];

export default function changeTheme(mode) {
    let themeConfig = themeConfigs.find(theme => theme.name === mode);

    if (!themeConfig) {
        [themeConfig] = themeConfigs;
    }

    const theme = createMuiTheme(themeConfig);

    return theme;
}
