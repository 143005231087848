/* eslint-disable */
import React, { useEffect, useState, useReducer, useRef } from "react";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    withStyles,
    makeStyles,
    Grid,
    useMediaQuery,
    TableCell,
    Accordion,
    AccordionSummary,
    createMuiTheme,
    MuiThemeProvider,
    IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from "file-saver";
import "../styles/chillerTable.scss";
import AnomalyDiagnosisActions from "../../redux_AnomalyDiagnosis/AnomalyDiagnosisActions";
import { REFRESH_TIMER, VERBIAGE, ICONS } from "../../../utils/enums/index";
import URL from "../../../utils/URL";
import "../styles/analyticsTable.scss";
import AnalyticsDropdown from "./AnalyticsDropdown";
// import AnalyticsDropdownSearch from "./AnalyticsDropdownSearch";
import ExportDiagnosisSummaryCSV from "./ExportDiagnosisSummaryCSV";
import { ExpandLess, ExpandMore, Event } from "@material-ui/icons";
import CustomFooter from "../components/CustomFooter";
import MUIDataTable from "mui-datatables";

Moment.globalLocal = true;
const moment = require("moment");
const MobileDetect = require("mobile-detect");

const KEY_UP = 38;
const KEY_DOWN = 40;

const sortTypes = {
    up: {
        sortImage: ICONS.SORT_UP,
        sortDateFunction: (date1, date2) => date1.start - date2.start,
        sortTimeFunction: (time1, time2) => parseFloat(time1.duration) - parseFloat(time2.duration),
        sortEnergyKWFunction: (obj1, obj2) =>
            parseFloat(obj1.energyConsumption) - parseFloat(obj2.energyConsumption),
        sortPredEnergyKWFunction: (obj1, obj2) =>
            parseFloat(obj1.predictionEnergyConsumption) -
            parseFloat(obj2.predictionEnergyConsumption),
        sortActEffFunction: (obj1, obj2) =>
            parseFloat(obj1.actualEfficiency) - parseFloat(obj2.actualEfficiency),
        sortPredffFunction: (obj1, obj2) =>
            parseFloat(obj1.predictionEfficiency) - parseFloat(obj2.predictionEfficiency),
        sortDevEffFunction: (obj1, obj2) =>
            parseFloat(obj1.deviationEfficiency) - parseFloat(obj2.deviationEfficiency),
    },
    down: {
        sortImage: ICONS.SORT_DOWN,
        sortDateFunction: (date1, date2) => date2.start - date1.start,
        sortTimeFunction: (time1, time2) => parseFloat(time2.duration) - parseFloat(time1.duration),
        sortEnergyKWFunction: (obj1, obj2) =>
            parseFloat(obj2.energyConsumption) - parseFloat(obj1.energyConsumption),
        sortPredEnergyKWFunction: (obj1, obj2) =>
            parseFloat(obj2.predictionEnergyConsumption) -
            parseFloat(obj1.predictionEnergyConsumption),
        sortActEffFunction: (obj1, obj2) =>
            parseFloat(obj2.actualEfficiency) - parseFloat(obj1.actualEfficiency),
        sortPredffFunction: (obj1, obj2) =>
            parseFloat(obj2.predictionEfficiency) - parseFloat(obj1.predictionEfficiency),
        sortDevEffFunction: (obj1, obj2) =>
            parseFloat(obj2.deviationEfficiency) - parseFloat(obj1.deviationEfficiency),
    },
    default: {
        sortImage: ICONS.SORT,
        sortDateFunction: date1 => date1,
        sortTimeFunction: time1 => time1,
        sortEnergyKWFunction: obj1 => obj1,
        sortPredEnergyKWFunction: obj1 => obj1,
        sortActEffFunction: obj1 => obj1,
        sortPredffFunction: obj1 => obj1,
        sortDevEffFunction: obj1 => obj1,
    },
};

let tableTimer = null;
let sortFnVar;
const AnomalyDiagnosisTable = props => {
    const newInterval = localStorage.getItem("interval");
    const newIntervalType = localStorage.getItem("intervalType");
    const {
        updateDiagnosisInterval,
        diagnosis,
        changeUrl,
        handleNewFeedback, // handleNewLabel,
        setDataValue,
        // first,
        // fetchNotes,
        fetchChartPosition,
        setActiveDiagnosis,
        updateDiagnosisSelected,
        // fetchDiagnosisChart,
        statusOfFilter,
    } = props;
    const md = new MobileDetect(window.navigator.userAgent);
    let isNarrow = false;
    if (!!md.tablet()) {
        isNarrow = useMediaQuery("(max-width: 1023px)");
    } else {
        isNarrow = useMediaQuery("(max-width: 850px)");
    }
    const useStyles = makeStyles(theme => ({
        paper: {
            borderRadius: "0px 0px 4px 4px",
            border: isNarrow
                ? ""
                : theme.palette.type === "dark"
                ? "10px solid #201F25"
                : "10px solid #ffffff",
            height: isNarrow ? "450px" : "320px",
            width: "100%",
            overflow: "auto",
        },
        paperCSV: {
            height: "40px",
            borderRadius: "4px 4px 0px 0px",
            width: "100%",
            border: theme.palette.type === "dark" ? "10px solid #201F25" : "10px solid #ffffff",
            background: theme.palette.type === "dark" ? "#201F25" : "#ffffff",
        },
        time: {
            marginTop: "1vh",
            fontSize: "12px",
        },
        label: {
            color: theme.palette.type === "dark" ? "#ACACAC" : "#707070",
        },
        table: {
            fontSize: "1rem",
            textAlign: "center",
            color: theme.palette.type === "dark" ? "#FFFFFF" : "#A0A0A0",
            borderRadius: "4px",
            // maxHeight: "300px",
            // overflow: "auto",
            // display: "block",
        },
        head: {
            fontWeight: "500",
            color: theme.palette.type === "dark" ? "#FFFFFF" : "#707070",
            background: theme.palette.type === "dark" ? "#2F2F2F" : "#F7F7F7",
            textAlignLast: "center",
        },
        tr: {
            height: "40px",
        },
        trhead: {
            height: "20px",
        },
        td: {
            position: "relative",
        },
        indication: {
            // color: theme.palette.type === "dark" ? "#FFFFFF" : "#707070",
            color: VERBIAGE.COLOUR_LIGHT_BLUE,
            // backgroundColor: theme.palette.,
        },
        tdStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: theme.palette.primary.main,
            // minWidth: "100px",
        },
        tdStyle2: {
            position: "sticky",
            top: "20px",
            backgroundColor: theme.palette.primary.main,
            // minWidth: "100px",
        },
        white: {
            color: theme.palette.common.white,
            // width: "19vw",
        },
        colStyle: {
            position: "sticky",
            top: "20px",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            // minWidth: "100px",
        },
        colStyleLabel: {
            position: "sticky",
            top: "0px",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            padding: "1px 16px",
            // minWidth: "100px",
        },
        colStyleEmpty: {
            position: "sticky",
            top: "0px",
            backgroundColor: theme.palette.type === "dark" ? "#2F2F2F" : "#F7F7F7",
            color: theme.palette.type === "dark" ? "#FFFFFF" : "#707070",
            padding: "1px 16px",
            // minWidth: "100px",
        },
    }));
    const classes = useStyles();
    const [id, setId] = useState(1);
    const [currentDateSort, setCurrentDateSort] = useState("default");
    const [currentTimeSort, setCurrentTimeSort] = useState("default");
    const [currentEnergyKWSort, setCurrentEnergyKWSort] = useState("default");
    const [currentPredEnergyKWSort, setCurrentPredEnergyKWSort] = useState("default");
    const [currentActEffSort, setCurrentActEffSort] = useState("default");
    const [currentPredEffSort, setCurrentPredEffSort] = useState("default");
    const [currentDevEffSort, setCurrentDevEffSort] = useState("default");

    const [tdata, setTdata] = useState(diagnosis);
    const [dropDown, setDropDown] = useState();
    const [currentfilter, setCurrentFilter] = useState();
    const [fulltable, setFullTable] = useState(true);
    const [startData, setStartData] = useState(0);
    const [endData, setEndData] = useState(10);
    const [selectionData, setSelectionData] = useState({
        selectedIndex: 0,
        keyCode: 0,
    });

    const paperScroll = React.useRef(null);
    const faultTable = React.useRef(null);

    const page = React.useRef(0);
    const data = React.useRef(10);
    //TODO
    const anomalyEvents = diagnosis && diagnosis.length;

    const borderStyle = { borderRadius: "0 4px 0 0" };
    function setData(data) {
        setTdata(data);
        // fetchNotes(data[0].noteId);
        setDataValue(
            data[0].start,
            data[0].equipment,
            data[0].duration,
            data[0].status,
            data[0].feedback,
            data[0].lastUpdated,
            data[0].reason1,
            data[0].reason2,
            data[0].reason3,
        );
        handleNewFeedback(data[0].start, data[0].equipment, data[0].status, data[0].feedback);
        fetchChartPosition(data[0].code, data[0].parameter);
        // fetchDiagnosisChart(data[0].equipment, data[0].firstReason, data[0].secondReason, data[0].thirdReason);
    }
    //Function for Keyboard selection
    const KeyHandler = event => {
        if (event.keyCode === KEY_UP || event.keyCode === KEY_DOWN) {
            event.stopImmediatePropagation();
            event.preventDefault();
        }

        //upkey event
        if (event.keyCode === KEY_UP) {
            if (selectionData.selectedIndex > 0) {
                const {
                    start,
                    equipment,
                    duration,
                    status,
                    feedback,
                    lastUpdated,
                    reason1,
                    reason2,
                    reason3,
                    id,
                } = tdata[selectionData.selectedIndex - 1];
                handleEditChange(
                    start,
                    equipment,
                    duration,
                    status,
                    feedback,
                    lastUpdated,
                    reason1,
                    reason2,
                    reason3,
                    id,
                    KEY_UP,
                );
            }
        }
        //downkey event
        else if (event.keyCode === KEY_DOWN) {
            if (selectionData.selectedIndex < tdata.length) {
                const {
                    start,
                    equipment,
                    duration,
                    status,
                    feedback,
                    lastUpdated,
                    reason1,
                    reason2,
                    reason3,
                    id,
                } = tdata[selectionData.selectedIndex + 1];
                handleEditChange(
                    start,
                    equipment,
                    duration,
                    status,
                    feedback,
                    lastUpdated,
                    reason1,
                    reason2,
                    reason3,
                    id,
                    KEY_DOWN,
                );
            }
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", KeyHandler);

        return () => {
            document.removeEventListener("keydown", KeyHandler);
        };
    });
    // useeffect for diagnosis
    useEffect(() => {
        if (diagnosis && diagnosis.length) {
            if ((!tdata.length || fulltable) && selectionData.selectedIndex === 0) {
                setFullTable(true);
                setData(diagnosis);
            } else if (statusOfFilter) {
                const idList = tdata.map(item => item[currentfilter]);
                const selectedItems = diagnosis.filter(
                    item => idList.indexOf(item[currentfilter]) !== -1,
                );
                if (selectedItems.length) {
                    setData(selectedItems);
                } else {
                    setFullTable(true);
                    setData(diagnosis);
                }
            } else {
                const idList = tdata.map(item => item.id);
                const selectedItems = diagnosis.filter(item => idList.indexOf(item.id) !== -1);
                if (selectedItems.length) {
                    setTdata(selectedItems);
                    const index = selectionData.selectedIndex;
                    setDataValue(
                        selectedItems[index].start,
                        selectedItems[index].equipment,
                        selectedItems[index].duration,
                        selectedItems[index].status,
                        selectedItems[index].feedback,
                        selectedItems[index].lastUpdated,
                        selectedItems[index].reason1,
                        selectedItems[index].reason2,
                        selectedItems[index].reason3,
                    );
                    handleNewFeedback(
                        selectedItems[index].start,
                        selectedItems[index].equipment,
                        selectedItems[index].status,
                        selectedItems[index].feedback,
                    );
                }
            }
        }
    }, [diagnosis]);

    // function to filter data
    const filterData = (selectedList, field) => {
        setCurrentFilter(field);
        let items = [];
        let isAll = true;
        let newTData;
        if (selectedList.length) {
            items = diagnosis.filter(item => {
                if (selectedList.indexOf(item.id) !== -1) {
                    return true;
                } else {
                    isAll = false;
                    return false;
                }
            });
            if (!items.length) {
                setFullTable(true);
                setTdata(diagnosis);
                newTData = diagnosis;
            } else {
                setFullTable(isAll);
                setTdata(items);
                newTData = items;
            }
        } else {
            setFullTable(true);
            setTdata(diagnosis);
            newTData = diagnosis;
        }
        setStartData(0);
        page.current = Math.floor(newTData.length / 10);
        data.current = Math.ceil(newTData.length / page.current);
        setEndData(data.current);
    };

    // on edit button click
    const handleEditChange = async (
        start,
        equipment,
        duration,
        status,
        feedback,
        lastUpdated,
        reason1,
        reason2,
        reason3,
        newId,
        pressedKeyCode,
    ) => {
        // console.log("handleEditChange");
        setId(newId);
        const url = `${URL.DATA.ANALYTICS.EDIT}/${equipment}`;
        changeUrl(url, start, duration);
        handleNewFeedback(start, equipment, status, feedback);
        // fetchNotes(noteId);

        clearTimeout(tableTimer);
        tableTimer = setTimeout(() => {
            tableTimer = null;
            // fetchChartPosition(code, equipment);
            // fetchDiagnosisChart(equipment, reason1, reason2, reason3);
            const index = tdata.findIndex(item => {
                return item.id === newId;
            });
            setSelectionData({
                lastKeyCode: selectionData.keyCode,
                selectedIndex: index,
                keyCode: pressedKeyCode,
            });
        }, 1000);
    };

    useEffect(() => {
        const selectedRow = document.querySelector(".currentlySelected");
        // console.log("currentlySelected");
        if (
            selectedRow &&
            selectionData.lastKeyCode === selectionData.keyCode &&
            selectionData.keyCode !== undefined
        ) {
            const hasPressedUp = selectionData.keyCode === KEY_UP;
            // const mainScrollPostion = document.querySelector(".main").scrollTop;

            selectedRow.scrollIntoView(hasPressedUp);

            if (hasPressedUp) {
                const container = document.querySelector(".tableContainer");
                const header = document.querySelector(".tableContainer thead");
                container.scroll(container.scrollLeft, container.scrollTop - header.offsetHeight);
            }
            // document.querySelector(".main").scrollTop = mainScrollPostion;
        }
    }, [selectionData]);

    // useeffect for data
    useEffect(() => {
        // console.log("useeffect for handleEditChange");
        if (tdata.length) {
            const selection = tdata.find(item => item.id === id);
            if (!selection) {
                const selectedItem = tdata[0];
                handleEditChange(
                    selectedItem.start,
                    selectedItem.equipment,
                    selectedItem.duration,
                    selectedItem.status,
                    selectedItem.feedback,
                    selectedItem.lastUpdated,
                    selectedItem.reason1,
                    selectedItem.reason2,
                    selectedItem.reason3,
                    selectedItem.id,
                );
            } else {
                // console.log("option 2");
                setDataValue(
                    selection.start,
                    selection.equipment,
                    selection.duration,
                    selection.status,
                    selection.feedback,
                    selection.lastUpdated,
                    selection.reason1,
                    selection.reason2,
                    selection.reason3,
                );
                setActiveDiagnosis(
                    selection.start,
                    selection.equipment,
                    selection.duration,
                    selection.status,
                    selection.feedback,
                    selection.lastUpdated,
                    selection.reason1,
                    selection.reason2,
                    selection.reason3,
                );
                // handleNewLabel(selection.label, selection.start, selection.code);
                // fetchChartPosition(selection.code, selection.parameter);
            }
        }
    }, [handleEditChange, id, tdata]);

    // function to populate dropdown
    const getFieldData = (field, data) => {
        let items = [];
        if (data) {
            if (field === "label") {
                const labeled = data.find(item => item[field].length);
                if (labeled) {
                    items.push("Labeled");
                }
                const notLabeled = data.find(item => !item[field].length);
                if (notLabeled) {
                    items.push("No Label");
                }
            } else {
                items = data.map(item => item[field]);
            }
            items.sort(function(first, second) {
                if (first < second) {
                    return -1;
                }
                if (first > second) {
                    return 1;
                }
                return 0;
            });
            return [...new Set(items.flat())];
        } else {
            return [];
        }
    };

    const handleScrollBar = event => {
        const scrollLength = parseInt(event.currentTarget.scrollTop);
        if (scrollLength > 200 && endData < tdata.length) {
            setEndData(endData + data.current);
        }
    };

    const onSortChange = sort => {
        sortFnVar = sort;
        let nextSort;
        let sortVar;
        switch (sort) {
            case "date":
                sortVar = currentDateSort;
                break;
            case "time":
                sortVar = currentTimeSort;
                break;
            case "energy_kw":
                sortVar = currentEnergyKWSort;
                break;
            case "pred_energy_kw":
                sortVar = currentPredEnergyKWSort;
                break;
            case "act_eff":
                sortVar = currentActEffSort;
                break;
            case "pred_eff":
                sortVar = currentPredEffSort;
                break;
            case "dev_eff":
                sortVar = currentDevEffSort;
                break;
        }
        // const sortVar = sort === "date" ? currentDateSort : currentTimeSort;

        if (sortVar === "down") nextSort = "up";
        else if (sortVar === "up") nextSort = "default";
        else if (sortVar === "default") nextSort = "down";
        // sort === "date" ? setCurrentDateSort(nextSort) : setCurrentTimeSort(nextSort);
        switch (sort) {
            case "date":
                setCurrentDateSort(nextSort);
                break;
            case "time":
                setCurrentTimeSort(nextSort);
                break;
            case "energy_kw":
                setCurrentEnergyKWSort(nextSort);
                break;
            case "pred_energy_kw":
                setCurrentPredEnergyKWSort(nextSort);
                break;
            case "act_eff":
                setCurrentActEffSort(nextSort);
                break;
            case "pred_eff":
                setCurrentPredEffSort(nextSort);
                break;
            case "dev_eff":
                setCurrentDevEffSort(nextSort);
                break;
        }
        // setDataPerPage(10);
    };
    const sortDateIcon =
        sortTypes && sortTypes[currentDateSort] ? sortTypes[currentDateSort].sortImage : "";
    const sortTimeIcon =
        sortTypes && sortTypes[currentTimeSort] ? sortTypes[currentTimeSort].sortImage : "";
    const sortEnergyKWIcon =
        sortTypes && sortTypes[currentEnergyKWSort] ? sortTypes[currentEnergyKWSort].sortImage : "";
    const sortPredEnergyKWIcon =
        sortTypes && sortTypes[currentPredEnergyKWSort]
            ? sortTypes[currentPredEnergyKWSort].sortImage
            : "";
    const sortActEffIcon =
        sortTypes && sortTypes[currentActEffSort] ? sortTypes[currentActEffSort].sortImage : "";
    const sortPredEffIcon =
        sortTypes && sortTypes[currentPredEffSort] ? sortTypes[currentPredEffSort].sortImage : "";
    const sortDevEffIcon =
        sortTypes && sortTypes[currentDevEffSort] ? sortTypes[currentDevEffSort].sortImage : "";

    const sortingDate =
        sortTypes && sortTypes[currentDateSort] ? sortTypes[currentDateSort].sortDateFunction : "";
    const sortingTime =
        sortTypes && sortTypes[currentTimeSort] ? sortTypes[currentTimeSort].sortTimeFunction : "";
    const sortingEnergyKW =
        sortTypes && sortTypes[currentEnergyKWSort]
            ? sortTypes[currentEnergyKWSort].sortEnergyKWFunction
            : "";
    const sortingPredEnergyKW =
        sortTypes && sortTypes[currentPredEnergyKWSort]
            ? sortTypes[currentPredEnergyKWSort].sortPredEnergyKWFunction
            : "";
    const sortingActEff =
        sortTypes && sortTypes[currentActEffSort]
            ? sortTypes[currentActEffSort].sortActEffFunction
            : "";
    const sortingPredEff =
        sortTypes && sortTypes[currentPredEffSort]
            ? sortTypes[currentPredEffSort].sortPredEffFunction
            : "";
    const sortingDevEff =
        sortTypes && sortTypes[currentDevEffSort]
            ? sortTypes[currentDevEffSort].sortDevEffFunction
            : "";
    // const sorting = sortFnVar === "date" ? sortingDate : sortingTime;
    let sorting;
    switch (sortFnVar) {
        case "date":
            sorting = sortingDate;
            break;
        case "time":
            sorting = sortingTime;
            break;
        case "energy_kw":
            sorting = sortingEnergyKW;
            break;
        case "pred_energy_kw":
            sorting = sortingPredEnergyKW;
            break;
        case "act_eff":
            sorting = sortingActEff;
            break;
        case "pred_eff":
            sorting = sortingPredEff;
            break;
        case "dev_eff":
            sorting = sortingDevEff;
            break;
    }

    useEffect(() => {
        // const timerValue = REFRESH_TIMER();
        updateDiagnosisInterval();
        // const timer = setInterval(() => {
        // updateDiagnosisInterval();
        // }, timerValue);
        // return () => clearInterval(timer);
    }, [updateDiagnosisInterval]);

    const StyledTableRow = withStyles(theme => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.type === "dark" ? "#414141" : "#FBFBFB",
            },
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.type === "dark" ? "#3A3A3A" : "#F6F6F6",
            },
        },
    }))(TableRow);

    const StyledTableCell = withStyles(() => ({
        root: props => ({
            padding: "16px",
            // color: props.selected ? "#FFFFFF" : "#000000",
            color:
                localStorage.dark === "true" || props.selected
                    ? "#FFFFFF"
                    : props.selected
                    ? "#FFFFFF"
                    : "#A0A0A0",
        }),
    }))(TableCell);

    const handleCsv = () => {
        const csvData = tdata.map(row => {
            const csvRow = {};
            csvRow.id = row.id;
            csvRow.start = moment(row.start).format("Do MMMM YYYY, HH:mm:ss");
            csvRow.duration = row.duration;
            csvRow.code = row.code;
            csvRow.parameter = row.parameter;
            csvRow.description = row.description;
            csvRow.label = row.label;
            csvRow.tag = row.tag;
            csvRow.userTag = row.userTag;
            return csvRow;
        });
        const blob = new Blob([jsonToCSV(csvData)], { type: "text/csv;charset=utf-8;" });
        const now = moment().format("YYYYMMDD");
        saveAs(blob, `${csvData[0].siteId}_Fault_Events_${now}.csv`);
        // saveAs(blob, "Fault_Events.csv");
    };

    const cols = [
        { label: "Start Date", name: "start" },
        { label: "Duration", name: "duration" },
        { label: "Code", name: "code" },
        { label: "Parameter", name: "parameter" },
        { label: "Diagnosis", name: "description" },
        { label: "Fault Labels", name: "label" },
        { label: "Tags", name: "tag" },
        { label: "User Tags", name: "userTag" },
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});
    const expandedDefault = useRef(false);

    const customRowRender = (row, dataIndex, rowIndex) => {
        // const handleAccordion = (event, expanded) => {
        //     event.stopPropagation();
        //     setIsRowExpanded(prev => {
        //         return {
        //             ...prev,
        //             [dataIndex]: !prev[dataIndex],
        //         };
        //     });
        // };
        const rowData = tdata[dataIndex];
        const selected = rowData.id === id;
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion
                        key={dataIndex}
                        // onChange={handleAccordion}
                        elevation={0}
                        style={
                            selected
                                ? {
                                      backgroundColor: "gray",
                                      color: "#ffffff",
                                  }
                                : {
                                      backgroundColor: !!(rowData.id % 2)
                                          ? localStorage.dark === "true"
                                              ? "#414141"
                                              : "#FBFBFB"
                                          : localStorage.dark === "true"
                                          ? "#3A3A3A"
                                          : "#F6F6F6",
                                  }
                        }
                        expanded={
                            isRowExpanded[dataIndex] === undefined
                                ? expandedDefault.current
                                : isRowExpanded[dataIndex]
                        }
                        square={true}
                    >
                        <AccordionSummary
                            onClick={event => {
                                event.stopPropagation();
                                handleEditChange(
                                    rowData.start,
                                    rowData.equipment,
                                    rowData.duration,
                                    rowData.status,
                                    rowData.feedback,
                                    rowData.lastUpdated,
                                    rowData.reason1,
                                    rowData.reason2,
                                    rowData.reason3,
                                    rowData.id,
                                );
                            }}
                        >
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell selected={selected}>
                                            {VERBIAGE.ANOMALY_DIAGNOSIS.START}
                                        </StyledTableCell>
                                        <StyledTableCell selected={selected}>
                                            <Moment unix tz="UTC" format="Do MMMM YYYY, HH:mm:ss">
                                                {(rowData.start + rowData.utcOffset * 60000) / 1000}
                                            </Moment>
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell selected={selected}>
                                            {VERBIAGE.ANOMALY_DIAGNOSIS.DURATION}
                                        </StyledTableCell>
                                        <StyledTableCell selected={selected}>
                                            {rowData.duration}
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell selected={selected}>
                                            {VERBIAGE.ANOMALY_DIAGNOSIS.PARAMETER}
                                        </StyledTableCell>
                                        <StyledTableCell selected={selected}>
                                            {rowData.equipment}
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell selected={selected}>
                                            {VERBIAGE.ANOMALY_DIAGNOSIS.ENERGY_KW}
                                        </StyledTableCell>
                                        <StyledTableCell selected={selected}>
                                            {rowData.energyConsumption}
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell selected={selected}>
                                            {VERBIAGE.ANOMALY_DIAGNOSIS.PRED_ENERGY_KW}
                                        </StyledTableCell>
                                        <StyledTableCell selected={selected}>
                                            {rowData.predictionEnergyConsumption}
                                        </StyledTableCell>
                                    </TableRow>
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.ACTUAL_EFF}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {`${parseFloat(
                                                        rowData.actualEfficiency,
                                                    ).toFixed(3)}`}{" "}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.PREDICTED_EFF}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {`${parseFloat(
                                                        rowData.predictionEfficiency,
                                                    ).toFixed(3)}`}{" "}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.EFF_DEVIATION}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {`${parseFloat(
                                                        rowData.deviationEfficiency,
                                                    ).toFixed(3)}`}{" "}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.EFF_DEV_PERCENT}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {`${parseFloat(
                                                        rowData.percentageDeviationEfficiency,
                                                    ).toFixed(2)}%`}{" "}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.REASON_1}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {rowData.reason1}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.REASON_2}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {rowData.reason2}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.REASON_3}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {rowData.reason3}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.COST_IMPACT}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {`$${parseFloat(rowData.costImpact).toFixed(
                                                        2,
                                                    )}`}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.ENERGY_IMPACT}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {`${parseFloat(rowData.energyImpact).toFixed(
                                                        2,
                                                    )}`}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    {VERBIAGE.ANOMALY_DIAGNOSIS.STATUS}
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {rowData.status}
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
                <TableCell
                    style={
                        selected
                            ? {
                                  backgroundColor: "gray",
                                  color: "#ffffff",
                              }
                            : {
                                  backgroundColor: !!(rowData.id % 2)
                                      ? localStorage.dark === "true"
                                          ? "#414141"
                                          : "#FBFBFB"
                                      : localStorage.dark === "true"
                                      ? "#3A3A3A"
                                      : "#F6F6F6",
                              }
                    }
                >
                    <IconButton
                        onClick={() => {
                            setIsRowExpanded(prev => {
                                return {
                                    ...prev,
                                    [dataIndex]: !prev[dataIndex],
                                };
                            });
                        }}
                    >
                        {isRowExpanded[dataIndex] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        // fixedHeader: true,
        tableBodyHeight: "333px",
        filter: true,
        filterType: "multiselect",
        print: false,
        viewColumns: false,
        elevation: 0,
        onDownload: () => {
            handleCsv();
            return false;
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    changeRowsPerPage={changeRowsPerPage}
                    changePage={changePage}
                    textLabels={textLabels}
                />
            );
        },
        customRowRender: customRowRender,
    };

    const getMuiTheme = () =>
        createMuiTheme({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 400,
                    md: 600,
                    lg: 1280,
                    xl: 1920,
                },
            },
            overrides: {
                MUIDataTableBodyCell: {
                    root: {
                        fontSize: "1.25rem",
                    },
                },
                MuiTableCell: {
                    root: {
                        fontSize: "1rem",
                        padding: 0,
                    },
                },
                MuiAccordionSummary: {
                    root: {
                        padding: 0,
                    },
                    content: {
                        margin: 0,
                        "&$expanded": {
                            margin: 0,
                        },
                    },
                },
                MUIDataTableToolbar: {
                    filterPaper: {
                        maxWidth: "100%",
                        width: "300px",
                    },
                    root: {
                        background: localStorage.dark === "true" ? "#201F25" : "#ffffff",
                    },
                },
                MuiIconButton: {
                    root: {
                        color: localStorage.dark === "true" ? "#ffffff" : "rgba(0, 0, 0, 0.54)",
                    },
                },
                MuiTableHead: {
                    root: {
                        display: "none",
                    },
                },
            },
        });

    const theme = getMuiTheme();

    return (
        <Grid container>
            <Paper elevation={0} className={classes.paperCSV}>
                <ExportDiagnosisSummaryCSV data={tdata} isNarrow={isNarrow} />
            </Paper>
            <Paper
                id="paper"
                elevation={0}
                className={`${classes.paper} tableContainer`}
                onScroll={handleScrollBar}
                ref={paperScroll}
            >
                {isNarrow ? (
                    <MuiThemeProvider theme={theme}>
                        <MUIDataTable data={tdata} columns={cols} options={options} />
                    </MuiThemeProvider>
                ) : (
                    <Table ref={faultTable} className={classes.table}>
                        <TableHead className={classes.head}>
                            <TableRow className={classes.trhead}>
                                <TableCell className={classes.colStyleEmpty} />
                                <TableCell className={classes.colStyleEmpty} />
                                <TableCell className={classes.colStyleEmpty} />
                                <TableCell className={classes.colStyleEmpty} />
                                <TableCell className={classes.colStyleEmpty} />
                                <TableCell colSpan={7} className={classes.colStyleLabel}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.CAUSE}
                                </TableCell>
                                <TableCell className={classes.colStyleEmpty} />
                                <TableCell className={classes.colStyleEmpty} />
                                {/* <TableCell className={classes.colStyleEmpty} /> */}
                                <TableCell colSpan={1} className={classes.colStyleLabel}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.FEEDBACK}
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.tr}>
                                <td
                                    className={`${AnomalyDiagnosisTable.styles.time} ${classes.colStyle}`}
                                >
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.START}
                                    <img
                                        alt="sorticon"
                                        src={sortDateIcon}
                                        onClick={() => onSortChange("date")}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.DURATION}
                                    <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        onClick={() => onSortChange("time")}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                {/* <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.PARAMETER}
                                    <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td> */}
                                <td
                                    className={`${classes.td} ${classes.tdStyle2} ${
                                        currentfilter == "equipment" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                >
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.PARAMETER}
                                    {anomalyEvents ? (
                                        <AnalyticsDropdown
                                            analyticsData={diagnosis}
                                            statusOfFilter={statusOfFilter}
                                            items={getFieldData("equipment", diagnosis)}
                                            currentItems={getFieldData("equipment", tdata)}
                                            field="equipment"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            search
                                            fulltable={fulltable}
                                        />
                                    ) : null}
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.ENERGY_KW}
                                    <img
                                        alt="sorticon"
                                        src={sortEnergyKWIcon}
                                        onClick={() => onSortChange("energy_kw")}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.PRED_ENERGY_KW}
                                    <img
                                        alt="sorticon"
                                        src={sortPredEnergyKWIcon}
                                        onClick={() => onSortChange("pred_energy_kw")}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.ACTUAL_EFF}
                                    <img
                                        alt="sorticon"
                                        src={sortActEffIcon}
                                        onClick={() => onSortChange("act_eff")}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.PREDICTED_EFF}
                                    <img
                                        alt="sorticon"
                                        src={sortPredEffIcon}
                                        onClick={() => onSortChange("pred_eff")}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.EFF_DEVIATION}
                                    <img
                                        alt="sorticon"
                                        src={sortDevEffIcon}
                                        onClick={() => onSortChange("dev_eff")}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.EFF_DEV_PERCENT}
                                    <img
                                        alt="sorticon"
                                        src={sortDevEffIcon}
                                        onClick={() => onSortChange("dev_eff")}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                {/* <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.REASON_1}
                                    <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td> */}
                                <td
                                    className={`${classes.td} ${classes.tdStyle2} ${
                                        currentfilter == "reason1" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                >
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.REASON_1}
                                    {anomalyEvents ? (
                                        <AnalyticsDropdown
                                            analyticsData={diagnosis}
                                            statusOfFilter={statusOfFilter}
                                            items={getFieldData("reason1", diagnosis)}
                                            currentItems={getFieldData("reason1", tdata)}
                                            field="reason1"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            search
                                            fulltable={fulltable}
                                        />
                                    ) : null}
                                </td>
                                {/* <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.REASON_2}
                                    <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td> */}
                                <td
                                    className={`${classes.td} ${classes.tdStyle2} ${
                                        currentfilter == "reason2" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                >
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.REASON_2}
                                    {anomalyEvents ? (
                                        <AnalyticsDropdown
                                            analyticsData={diagnosis}
                                            statusOfFilter={statusOfFilter}
                                            items={getFieldData("reason2", diagnosis)}
                                            currentItems={getFieldData("reason2", tdata)}
                                            field="reason2"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            search
                                            fulltable={fulltable}
                                        />
                                    ) : null}
                                </td>
                                {/* <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.REASON_3}
                                    <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td> */}
                                <td
                                    className={`${classes.td} ${classes.tdStyle2} ${
                                        currentfilter == "reason3" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                >
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.REASON_3}
                                    {anomalyEvents ? (
                                        <AnalyticsDropdown
                                            analyticsData={diagnosis}
                                            statusOfFilter={statusOfFilter}
                                            items={getFieldData("reason3", diagnosis)}
                                            currentItems={getFieldData("reason3", tdata)}
                                            field="reason3"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            search
                                            fulltable={fulltable}
                                        />
                                    ) : null}
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.COST_IMPACT}
                                    <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                {/* <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.WATER_IMPACT}
                                    <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td> */}
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.ENERGY_IMPACT}
                                    <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    />
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.STATUS}
                                    {/* <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        className={AnomalyDiagnosisTable.styles.sortIcon}
                                    /> */}
                                </td>
                            </TableRow>
                        </TableHead>
                        {anomalyEvents ? (
                            <TableBody>
                                {[...tdata]
                                    .sort(sorting)
                                    .slice(startData, endData)
                                    .map(table => (
                                        <StyledTableRow
                                            key={table.id}
                                            className={table.id === id ? "currentlySelected" : ""}
                                            style={
                                                table.id === id
                                                    ? {
                                                          backgroundColor: "gray",
                                                          color: "#ffffff",
                                                          cursor: "pointer",
                                                          height: "50px",
                                                      }
                                                    : { cursor: "pointer", height: "50px" }
                                            }
                                            onClick={() => {
                                                handleEditChange(
                                                    table.start,
                                                    table.equipment,
                                                    table.duration,
                                                    table.status,
                                                    table.feedback,
                                                    table.lastUpdated,
                                                    table.reason1,
                                                    table.reason2,
                                                    table.reason3,
                                                    table.id,
                                                    table.index,
                                                );
                                            }}
                                        >
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "10%" }}
                                            >
                                                <Moment
                                                    unix
                                                    tz="UTC"
                                                    format="Do MMMM YYYY, HH:mm:ss"
                                                >
                                                    {(table.start + table.utcOffset * 60000) / 1000}
                                                </Moment>
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.duration}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.equipment}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.energyConsumption}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.predictionEnergyConsumption}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "1%" }}
                                            >
                                                {`${parseFloat(table.actualEfficiency).toFixed(3)}`}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "1%" }}
                                            >
                                                {`${parseFloat(table.predictionEfficiency).toFixed(
                                                    3,
                                                )}`}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "1%" }}
                                            >
                                                {`${parseFloat(table.deviationEfficiency).toFixed(
                                                    3,
                                                )}`}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "1%" }}
                                            >
                                                {`${parseFloat(
                                                    table.percentageDeviationEfficiency,
                                                ).toFixed(2)}%`}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.reason1}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.reason2}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.reason3}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {`$${table.costImpact}`}
                                            </td>
                                            {/* <td
                                                    className={AnomalyDiagnosisTable.styles.col}
                                                    style={{ width: "7%" }}
                                                >
                                                    {table.waterImpact}
                                                </td> */}
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.energyImpact}
                                            </td>
                                            <td
                                                className={AnomalyDiagnosisTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.status}
                                            </td>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        ) : null}
                    </Table>
                )}
                {anomalyEvents ? null : (
                    <div className={AnomalyDiagnosisTable.styles.tableError}>
                        {`No Faults found in the last ${newInterval} ${newIntervalType}`}
                    </div>
                )}
            </Paper>
        </Grid>
    );
};
AnomalyDiagnosisTable.styles = {
    row: "webclient__chillerTable-row",
    col: "webclient__chillerTable-col",
    sortIcon: "webclient__analyticsTable-sortIcon",
    time: "webclient__analyticsTable-time",
    tableError: "webclient__analyticsTable-tableError",
};
const mapStateToProps = state => ({
    diagnosis: state.diagnosis.tableData,
    statusOfFilter: state.diagnosis.statusOfFilter,
});
const mapDispatchToProps = dispatch => ({
    updateDiagnosisInterval: () => {
        dispatch(AnomalyDiagnosisActions.updateDiagnosisInterval());
    },
    fetchChartPosition: (code, parameter) => {
        dispatch(AnomalyDiagnosisActions.fetchChartPosition(code, parameter));
    },
    setActiveDiagnosis: (
        start,
        equipment,
        duration,
        status,
        feedback,
        lastUpdated,
        reason1,
        reason2,
        reason3,
    ) => {
        dispatch(
            AnomalyDiagnosisActions.setActiveDiagnosis({
                start,
                equipment,
                duration,
                status,
                feedback,
                lastUpdated,
                reason1,
                reason2,
                reason3,
            }),
        );
        dispatch(AnomalyDiagnosisActions.updateDiagnosisSelected(start, equipment));
    },
    // fetchEOPCH: () => {
    //     dispatch(AnomalyDiagnosisActions.fetchEOPCH());
    // },

    // fetchDiagnosisChart: (equipment, reason1, reason2, reason3) => {
    //     dispatch(AnomalyDiagnosisActions.fetchDiagnosisFirstChart(equipment, reason1));
    //     dispatch(AnomalyDiagnosisActions.fetchDiagnosisSecondChart(equipment, reason2));
    //     dispatch(AnomalyDiagnosisActions.fetchDiagnosisThirdChart(equipment, reason3));
    // }
});
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(AnomalyDiagnosisTable),
    () => true,
);
