import AuditLogActionTypes from "./AuditLogActionTypes";

import api from "../../utils/API";
import URL from "../../utils/URL";

// TODO: use interceptor to send auth token header
function getRequestFilter(options = {}) {
    const token = localStorage.getItem("token");
    return {
        headers: {
            "x-auth-token": token
        },
        ...options
    };
}

const AuditLogActions = {
    setAuditLogLoading: data => ({
        type: AuditLogActionTypes.SET_AUDITLOG_LOADING,
        data
    }),
    setAuditLogError: data => ({
        type: AuditLogActionTypes.SET_AUDITLOG_ERROR,
        data
    }),
    setAuditLogData: data => ({
        type: AuditLogActionTypes.SET_AUDITLOG_DATA,
        data
    }),
    setAuditLogFilters: data => ({
        type: AuditLogActionTypes.SET_AUDITLOG_FILTER,
        data
    }),
    setAuditLogRequest: () => dispatch => {
        dispatch(AuditLogActions.setAuditLogLoading(true));
        dispatch(AuditLogActions.setAuditLogError(null));
    },
    getAuditLogData: (auditLogsParams = {}) => async (dispatch, getState) => {
        try {
            dispatch(AuditLogActions.setAuditLogRequest());
            dispatch({
                type: AuditLogActionTypes.SET_AUDITLOG_PARAMS,
                data: auditLogsParams
            });

            const {
                auditLogs: {
                    auditLogData: { params }
                }
            } = getState();

            const response = await api.get(URL.DATA.AUDITLOG.DATA, getRequestFilter({ params }));
            dispatch(AuditLogActions.setAuditLogData(response.data));
            // if (isInitial && response.data.count > 0) {
            //     let initFilter = []
            //     //     console.log('getAuditLogData 7');
            //     //     dispatch(AuditLogActions.getAuditLogFilter());
            //     //     console.log('getAuditLogData 8');
            //     dispatch(AuditLogActions.setAuditLogFilters({
            //     intFilter,
            //     intFilter,
            //     intFilter,
            //     intFilter,
            //     intFilter,
            //     intFilter,
            //     intFilter
            // }));

            // }
            const {
                USERID: userIds,
                SITEID: siteIds,
                USERNAME: usernames,
                PERMISSION: permissions,
                ACTION: actions,
                TYPE: types,
                STATUS: status
            } = response.data.filters;
            dispatch(
                AuditLogActions.setAuditLogFilters({
                    userIds,
                    siteIds,
                    usernames,
                    permissions,
                    actions,
                    types,
                    status
                }),
            );
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
                return;
            }
            dispatch(AuditLogActions.setAuditLogError(error.message));
        } finally {
            dispatch(AuditLogActions.setAuditLogLoading(false));
        }
    },
    getAuditLogFilter: () => async dispatch => {
        try {
            const url = `${URL.DATA.AUDITLOG.DATA}/filter`;
            const response = await api.get(url, getRequestFilter());
            const {
                USERID: userIds,
                SITEID: siteIds,
                USERNAME: usernames,
                PERMISSION: permissions,
                ACTION: actions,
                TYPE: types,
                STATUS: status
            } = response.data;
            dispatch(
                AuditLogActions.setAuditLogFilters({
                    userIds,
                    siteIds,
                    usernames,
                    permissions,
                    actions,
                    types,
                    status
                }),
            );
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }
};

export default AuditLogActions;
