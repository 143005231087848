import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid, Tooltip, Typography, makeStyles } from "@material-ui/core";

import LoadingRetry from "../components/LoadingRetry";

import MLActions from "../../redux/MLActions";
import { toClientDate } from "../../../utils/helpers";
import InterlockButton from "./components/InterlockButton";

const useStyles = makeStyles(theme => ({
    arrow: {
        color: theme.palette.common.black
    },
    tooltip: {
        backgroundColor: theme.palette.common.black
    }
}));
const PIDAutoMLInterlocks = () => {
    const { modbusHB, plcHB, setpoint } = useSelector(state => state.ML);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(MLActions.getModbusHeartBeat());
        dispatch(MLActions.getPLCHeartBeat());
    }, [dispatch]);

    const isModbusHbLoading = () => {
        return !modbusHB.loaded && modbusHB.loading;
    };

    const isPlcHbLoading = () => {
        return !plcHB.loaded && plcHB.loading;
    };

    const isSpInRangeLoading = () => {
        return !setpoint.loaded && setpoint.loading;
    };

    const classes = useStyles();
    return (
        <>
            <Grid item>
                <Typography variant="subtitle1">Interlocks</Typography>
            </Grid>
            <Grid item container xs={12} sm={4} md={3} justifyContent="center">
                <LoadingRetry
                  loading={isModbusHbLoading()}
                  error={!!modbusHB.error}
                  retryFn={() => dispatch(MLActions.getModbusHeartBeat())}
                />
                {modbusHB.loaded && !modbusHB.error && (
                    <Tooltip
                      arrow
                      classes={classes}
                      placement="top"
                      title={`Last updated on ${toClientDate(modbusHB.data.meta?.dt_server)}`}
                    >
                        <InterlockButton
                          variant="contained"
                          color="primary"
                          active={modbusHB.data.value.toString()}
                          fullWidth
                        >
                            Modbus Heartbeat
                        </InterlockButton>
                    </Tooltip>
                )}
            </Grid>
            <Grid item container xs={12} sm={4} md={3} justifyContent="center">
                <LoadingRetry
                  loading={isPlcHbLoading()}
                  error={!!plcHB.error}
                  retryFn={() => dispatch(MLActions.getPLCHeartBeat())}
                />
                {plcHB.loaded && !plcHB.error && (
                    <Tooltip
                      arrow
                      classes={classes}
                      placement="top"
                      title={`Last updated on ${toClientDate(plcHB.data.meta?.dt_server)}`}
                    >
                        <InterlockButton
                          variant="contained"
                          color="primary"
                          active={plcHB.data.value.toString()}
                          fullWidth
                        >
                            PLC Heartbeat
                        </InterlockButton>
                    </Tooltip>
                )}
            </Grid>
            <Grid item container xs={12} sm={4} md={3} justifyContent="center">
                {isSpInRangeLoading() ? (
                    <CircularProgress size={18} />
                ) : (
                    <InterlockButton
                      variant="contained"
                      color="primary"
                      active={setpoint.spInRange.toString()}
                      fullWidth
                    >
                        SP in Range
                    </InterlockButton>
                )}
            </Grid>
        </>
    );
};

export default PIDAutoMLInterlocks;
