import React from "react";

import { TableCell, TableSortLabel, Grid } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const CustomDataTableSortIcon = ({ sort, name }) => {
    let icon = (
        <>
            <KeyboardArrowUp />
            <KeyboardArrowDown />
        </>
    );
    if (name === sort?.name && sort?.direction === "asc") {
        icon = <KeyboardArrowUp />;
    } else if (name === sort?.name && sort?.direction === "desc") {
        icon = <KeyboardArrowDown />;
    }
    return (
        <span
          style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
            }}
        >
            {icon}
        </span>
    );
};

const CustomDataTableHead = ({ columnMeta, handleToggleColumn, sort }) => {
    return (
        <TableCell onClick={() => handleToggleColumn(columnMeta.index)}>
            <Grid container justifyContent="space-between">
                <TableSortLabel direction={sort.direction} hideSortIcon>
                    {columnMeta.label}
                </TableSortLabel>
                <CustomDataTableSortIcon name={columnMeta.name} sort={sort} />
            </Grid>
        </TableCell>
    );
};

export default CustomDataTableHead;
