/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { useTheme } from "@material-ui/core";
import { getCenter } from "ol/extent";
import Layer from "ol/layer/Layer";
import ImageLayer from "ol/layer/Image";
import Projection from "ol/proj/Projection";
import Static from "ol/source/ImageStatic";
import { composeCssTransform } from "ol/transform";
import { SVG } from "../../../utils/enums";

const style = {
    height: "calc(100vh - 100px)",
    // width: "calc(100vw - 100px)"
    width: "100vw"
};

const getDeviceLayers = (devices, imgWidth, imgHeight, theme) => {
    return [
        new Layer({
            render: frameState => {
                const svgLayerContainer = document.createElement("div");
                svgLayerContainer.style.width = style.width;
                svgLayerContainer.style.height = style.height;
                svgLayerContainer.style.transformOrigin = "top left";
                svgLayerContainer.className = "svg-layer";

                for (let index = 0; index < svgLayerContainer.children.length; index++) {
                    svgLayerContainer.removeChild(svgLayerContainer.children[index]);
                }

                svgLayerContainer.appendChild(SVG.getStyle());
                SVG.getTheme(theme);
                devices.forEach(device => {
                    svgLayerContainer.appendChild(SVG.buildDevice(device));
                });

                const svgResolution = 2;
                const { resolution, center, rotation } = frameState.viewState;
                const scale = svgResolution / resolution;
                const frameCenter = center;
                const { size } = frameState;
                const cssTransform = composeCssTransform(
                    size[0] / 2,
                    size[1] / 2,
                    scale,
                    scale,
                    rotation,
                    -frameCenter[0] / svgResolution - imgWidth / 2,
                    center[1] / svgResolution - imgHeight / 2,
                );

                svgLayerContainer.style.transform = cssTransform;
                return svgLayerContainer;
            }
        })
    ];
};

const OpenLayerWrapper = ({ options }) => {
    const theme = useTheme();
    const MAP_DIV_ID = "bbp_olw_map";
    const mapDiv = useRef();
    const currentZoom = useRef(options.image.zoom);
    const currentCenter = useRef([0, 0]);
    let map;
    useEffect(() => {
        if (mapDiv) {
            const extent = [
                -options.image.width,
                -options.image.height,
                options.image.width,
                options.image.height
            ];
            const projection = new Projection({
                code: "bbp-po-map",
                units: "pixels",
                extent
            });

            // currentZoom.current = options.image.zoom;

            // eslint-disable-next-line no-new
            map = new Map({
                layers: [
                    new ImageLayer({
                        source: new Static({
                            attributions: `
              © BBP:
              <a href='${options.image.url}' target='_blank'>
                Plant Overview
              </a>
              `,
                            url: options.image.url,
                            projection,
                            imageExtent: extent
                        })
                    }),
                    ...getDeviceLayers(
                        options.devices,
                        options.image.width,
                        options.image.height,
                        theme,
                    )
                ],
                target: MAP_DIV_ID,
                view: new View({
                    projection,
                    // center: getCenter(extent),
                    center: currentCenter.current,
                    zoom: currentZoom.current,
                    maxZoom: 4,
                    enableRotation: false
                })
            });
            map.on("moveend", function(e) {
                currentZoom.current = map.getView().getZoom();
                currentCenter.current = map.getView().getCenter();
            });
        }
    }, [mapDiv, options, map, theme]);

    return <div id={MAP_DIV_ID} ref={mapDiv} style={style} key={Math.random()} />;
};

export default OpenLayerWrapper;
