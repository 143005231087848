/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
import React, { useRef, useEffect } from "react";
import Helmet from "react-helmet";
import kebabCase from "lodash/kebabCase";
import noop from "lodash/noop";
import URLConstans from "../../../utils/URL";
import { getTableauVizV3Properties } from "../../../utils/helpers";
import TableauV3Adapter from "../../../utils/TableauV3Adapter";

const TableauV3 = ({ name, link, token, options = {}, onVizLoad = noop }) => {
    const vizRef = useRef();

    const vizProps = {};
    // component configs
    // https://help.tableau.com/current/api/embedding_api/en-us/docs/embedding_api_configure.html
    const { width, height, hideTabs, hideEditButton, hideToolbar, device } = options;
    if (hideTabs === true) {
        vizProps["hide-tabs"] = true;
    }
    if (hideEditButton === true) {
        vizProps["hide-edit-button"] = true;
    }
    if (hideToolbar === true) {
        vizProps.toolbar = "hidden";
    }
    if (width !== "100%") {
        vizProps.width = width;
    }
    if (height !== "100%") {
        vizProps.height = height;
    }
    if (device) {
        vizProps.device = device;
    }

    const vizFilters = [];
    const vizProperties = getTableauVizV3Properties();
    Object.keys(options).forEach(key => {
        if (vizProperties.indexOf(key) === -1) {
            vizFilters.push({ field: key, value: options[key] });
        }
    });

    /**
     * With the Embedding API v3,
     * you no longer add parameters to view URLs to filter when embedding.
     * Instead you filter by adding attributes to a filter web component
     *
     * https://help.tableau.com/current/pro/desktop/en-us/embed_structure.htm
     * https://help.tableau.com/current/pro/desktop/en-us/embed_list.htm#URL_params
     */
    const vizId = kebabCase(name);
    const url = new URL(link);
    url.searchParams.forEach((value, key) => {
        if (!key.startsWith(":")) {
            vizFilters.push({ field: key, value });
            url.searchParams.delete(key);
        }
    });
    const vizSrc = url.toString();

    useEffect(() => {
        const viz = vizRef.current;
        if (viz) {
            onVizLoad(new TableauV3Adapter(viz), vizId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vizRef]);

    return (
        <>
            <Helmet>
                <script type="module" src={URLConstans.TABLEAU.EMBED_API_V3_URL} async></script>
            </Helmet>
            <tableau-viz ref={vizRef} id={vizId} src={vizSrc} token={token} {...vizProps}>
                {vizFilters.map(({ field, value }) => (
                    <viz-filter key={field} field={field} value={value} />
                ))}
            </tableau-viz>
        </>
    );
};

export default TableauV3;
