/* eslint-disable no-console */
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { VERBIAGE } from "../utils/enums";

const usePlatform = () => {
    const [platform, setPlatform] = useState(localStorage.getItem("platform"));

    const history = useHistory();

    const changeToAnalytics = () => {
        localStorage.setItem("platform", "analytics");
        history.push(VERBIAGE.DASHBOARD_PATH.SITE_OVERVIEW);
    };

    const changeToDwh = () => {
        localStorage.setItem("platform", "dwh");
        history.push(VERBIAGE.WAREHOUSE_PATH.SITE_MANAGEMENT);
    };

    return {
        platform,
        setPlatform,
        changeToAnalytics,
        changeToDwh
    };
};

export default usePlatform;
