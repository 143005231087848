import moment from "moment";

import isEmpty from "lodash/isEmpty";
import camelCase from "lodash/camelCase";

const getCookie = name => {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
        if (name === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
};

const getMonthNameByMonth = monthNumber => {
    const date = new Date();
    date.setDate(1);
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
        month: "long"
    });
};

const toIntlDateTimeFormat = (date, options = {}) => {
    const locale = navigator.language || "en-US";
    const df = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
        ...options
    });
    return df.format(date);
};

function toClientDate(dateUTC, format = "YYYY-MM-DD HH:mm:ss") {
    if (!dateUTC) return "-";
    let offset = +localStorage.getItem("timezone")?.match(/\d+/)?.[0];
    if (Number.isNaN(offset)) {
        const tzOffset = new Date().getTimezoneOffset();
        offset = tzOffset < 0 ? Math.abs(tzOffset) : -Math.abs(tzOffset);
    }
    return moment
        .utc(dateUTC)
        .utcOffset(offset)
        .format(format);
}

function durationFromSeconds(seconds, format = "HH:mm:ss") {
    return moment.utc(seconds * 1000).format(format);
}

function durationFromDates(startDate, endDate, format = "HH:mm:ss") {
    if (!startDate || !endDate) return "-";
    const seconds = moment(endDate).diff(moment(startDate), "seconds");
    return durationFromSeconds(seconds, format);
}

const getSiteName = site => {
    if (
        isEmpty(site.siteStatus) ||
        site.siteStatus.trim().length === 0 ||
        site.siteStatus === "null" ||
        site.siteStatus === "ACTIVE"
    ) {
        return site.siteName;
    }
    return `${site.siteName} (${site.siteStatus})`;
};

const getActiveSiteName = () => {
    const siteName = localStorage.getItem("siteName");
    const siteStatus = localStorage.getItem("siteStatus");
    return getSiteName({ siteName, siteStatus });
};

const getResponseErrorDetails = response => {
    const { details } = response?.data || {};
    if (typeof details === "string") {
        // eslint-disable-next-line no-unused-vars
        const [_, errStrings] = details.split("-");
        try {
            const invalidFields = JSON.parse(errStrings.trim());
            const errors = invalidFields.reduce((acc, field) => {
                acc[field] = `This field value is invalid`;
                return acc;
            }, {});
            return {
                type: "invalid",
                errors
            };
        } catch (e) {
            //
        }
    } else if (typeof details === "object" && details.errors) {
        const errors = Object.keys(details.errors).reduce((acc, field) => {
            const message = details.errors[field].message
                .replace(/^Path/i, "")
                .replace(new RegExp(`\`${field}\`|"${field}"|${field}`), "This field")
                .trim();
            acc[field] = message;
            return acc;
        }, {});
        return {
            type: "invalid",
            errors
        };
    }
    return {
        type: "error"
    };
};

const scrollIntoFirstErrorElement = ({ elementIds }) => {
    if (isEmpty(elementIds)) return;
    const elements = elementIds.map(name => document.getElementById(name)).filter(el => !!el);
    elements.sort((a, b) => b.scrollHeight - a.scrollHeight);
    // eslint-disable-next-line no-unused-expressions
    elements[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
};

const isFieldRequired = (schema, field) => {
    return schema.fields[field]?.exclusiveTests?.required !== undefined ?? false;
};

// eslint-disable-next-line max-len
// https://help.tableau.com/current/api/embedding_api/en-us/docs/embedding_api_configure.html#table-of-properties-and-values-for-embedded-objects-and-components
const getTableauVizV3Properties = () => {
    const vizProperties = [
        "src",
        "width",
        "height",
        "hide-tabs",
        "hide-toolbar",
        "hide-toolbar",
        "instance-id-to-clone",
        "disable-url-actions",
        "hide-close-button",
        "hide-edit-button",
        "hide-edit-in-desktop-button",
        "suppress-default-edit-behavior"
    ];
    return vizProperties.reduce((acc, key) => {
        if (key.indexOf("-") > -1) {
            acc.push(camelCase(key));
        }
        acc.push(key);
        return acc;
    }, []);
};

const isEdgeUI = () => process.env.EDGE_UI === "true";

export {
    // eslint-disable-next-line import/prefer-default-export
    getCookie,
    getMonthNameByMonth,
    getSiteName,
    getActiveSiteName,
    toIntlDateTimeFormat,
    getTableauVizV3Properties,
    durationFromSeconds,
    durationFromDates,
    toClientDate,
    getResponseErrorDetails,
    scrollIntoFirstErrorElement,
    isFieldRequired,
    isEdgeUI
};
