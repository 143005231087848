import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import ReportTableauChart from "../charts/ReportTableauChart";
import URL from "../../../utils/URL";

const DynamicReportTab = ({ tab, onError }) => {
    const [reportChartURL, setReportChartURL] = useState(null);

    useEffect(() => {
        setReportChartURL(`${URL.DATA.REPORT.CHART}/dynamic/${tab}`);
    }, [tab]);

    // eslint-disable-next-line no-unused-vars
    const [worksheets, setWorksheets] = useState([]);
    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    return (
        <Grid item container justifyContent="flex-start">
            {reportChartURL && (
                <ReportTableauChart
                  url={reportChartURL}
                  addWorksheet={addWorksheet}
                  onError={error => onError(tab, error)}
                  report
                  xs={12}
                />
            )}
        </Grid>
    );
};

export default DynamicReportTab;
