import crypto from "crypto";
import AuthenticationActionTypes from "./AuthenticationActionTypes";
import api from "../../utils/API";
import URL from "../../utils/URL";

const AuthenticationActions = {
    setAuthenticationResponse: (payload, credentials) => ({
        type: AuthenticationActionTypes.SET_CREDENTIALS,
        payload,
        credentials
    }),
    postCredentials: (encryptedLogin, data, algorithm, secretKey) => async dispatch => {
        // aes-256-ctr
        if (encryptedLogin) {
            const iv = crypto.randomBytes(16);
            const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
            const encrypted = Buffer.concat([cipher.update(JSON.stringify(data)), cipher.final()]);
            const payload = {
                iv: iv.toString("hex"),
                content: encrypted.toString("hex")
            };
            const credentials = {
                userName: "Boreas User",
                password: payload,
                email: data.email,
                googleSignIn: data.googleSignIn,
                encryptedLogin
            };
            const response = await api.post(URL.AUTHENTICATION.LOGIN, null, {
                params: {
                    user: credentials.googleSignIn ? credentials.email : credentials.userName,
                    pass: payload,
                    googleSignIn: credentials.googleSignIn,
                    payload: credentials.payload
                }
            });
            dispatch(AuthenticationActions.setAuthenticationResponse(response.data, credentials));
        } else {
            const response = await api.post(URL.AUTHENTICATION.LOGIN, null, {
                params: {
                    user: data.email,
                    pass: data.googleSignIn ? data.tokenId : data.password,
                    googleSignIn: data.googleSignIn,
                    payload: data.payload
                }
            });
            dispatch(AuthenticationActions.setAuthenticationResponse(response.data, data));
        }
    },
    updateChangedPassword: credentials => ({
        type: AuthenticationActionTypes.UPDATE_CHANGED_PASSWORD,
        credentials
    }),
    handleChangeSite: credentials => ({
        type: AuthenticationActionTypes.HANDLE_CHANGE_SITE,
        credentials
    })
};

export default AuthenticationActions;
