import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    // KeyboardDateTimePicker,
    KeyboardDatePicker
} from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
// import MomentAdapter from "@date-io/moment";
// import { createMuiTheme, useTheme } from "@material-ui/core/styles";
import "../styles/mapTable.scss";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

const moment = require("moment");
// const moment = new MomentUtils();

const EditSavingsConfigTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    // const [data, setData] = useState([]); // eslint-disable-line no-unused-vars
    const [error, setError] = useState("");

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        lastHourlyData: VERBIAGE.TEXT.EMPTY_STRING,
        lastDailyData: VERBIAGE.TEXT.EMPTY_STRING,
        lastMonthlyData: VERBIAGE.TEXT.EMPTY_STRING,
        pauseHourly: false,
        pauseDaily: false,
        pauseMonthly: false,
        since: VERBIAGE.TEXT.EMPTY_STRING,
        updated: VERBIAGE.TEXT.EMPTY_STRING,
        status: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleSite = ({ target: { value } }) => {
        updateConfig({ ...config, siteId: value });
    };

    const handleSince = event => {
        let newTimestamp = new Date(event._d).getTime();
        newTimestamp = moment(newTimestamp)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .valueOf();
        updateConfig({ ...config, since: newTimestamp });
    };

    const handleHourly = event => {
        const newTimestamp = new Date(event._d).getTime();
        updateConfig({ ...config, lastHourlyData: newTimestamp });
    };

    const handleDaily = event => {
        const newTimestamp = new Date(event._d).getTime();
        updateConfig({ ...config, lastDailyData: newTimestamp });
    };

    const handleMonthly = event => {
        const newTimestamp = new Date(event._d).getTime();
        updateConfig({ ...config, lastMonthlyData: newTimestamp });
    };

    const handleHourlyToggle = event => {
        updateConfig({ ...config, pauseHourly: event.target.checked });
        // setIsLiveData(event.target.checked);
        // localStorage.setItem("isLiveData", event.target.checked);
    };

    const handleDailyToggle = event => {
        updateConfig({ ...config, pauseDaily: event.target.checked });
    };

    const handleMonthlyToggle = event => {
        updateConfig({ ...config, pauseMonthly: event.target.checked });
    };

    const handleStatus = ({ target: { value } }) => {
        updateConfig({ ...config, status: value });
    };

    const handleSubmit = async () => {
        if (isEdit) {
            if (
                !config.siteId ||
                !config.lastHourlyData ||
                !config.lastDailyData ||
                !config.lastMonthlyData ||
                !config.since
            ) {
                setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
                return;
            }
            const _data = {
                siteId: config.siteId,
                lastHourlyData: config.lastHourlyData,
                lastDailyData: config.lastDailyData,
                lastMonthlyData: config.lastMonthlyData,
                pauseHourly: config.pauseHourly,
                pauseDaily: config.pauseDaily,
                pauseMonthly: config.pauseMonthly,
                since: config.since,
                status: config.status
            };
            const url = `${URL.DATA.MAINTENANCE_SETTINGS.SAVINGS_CONFIG}/${config.siteId}`;
            try {
                const response = await api.put(url, _data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        } else {
            if (!config.siteId || !config.since) {
                setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
                return;
            }
            const _data = {
                ...config
            };
            const url = URL.DATA.MAINTENANCE_SETTINGS.SAVINGS_CONFIG;
            try {
                const response = await api.post(url, _data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    // Override border color of datetime picker
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE
                }
            }
        }
    });

    if (isEdit) {
        return (
            <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    {`To ${
                        isEdit ? "edit" : "add to"
                    } the Savings Configuration, please enter the details below.`}
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextInput
                              onChange={handleSite}
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
                              value={config.siteId}
                              type="text"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MuiThemeProvider theme={themeOverride}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                      variant="dialog"
                                      label={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SAVINGS_CONFIG_SINCE}
                                      inputVariant="outlined"
                                      value={config.since}
                                      onChange={handleSince}
                                      format="DD/MM/YYYY"
                                      fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <MuiThemeProvider theme={themeOverride}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                      variant="dialog"
                                      label={
                                            VERBIAGE.PLACEHOLDER_TEXT.ENTER_SAVINGS_CONFIG_HOURLY
                                        }
                                      inputVariant="outlined"
                                      value={config.lastHourlyData}
                                      onChange={handleHourly}
                                        // format="DD/MM/YYYY HH:mm"
                                      format="DD/MM/YYYY"
                                      fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                              label="Pause Hourly tasks"
                              control={(
                                    <Checkbox
                                      checked={config.pauseHourly}
                                      onChange={handleHourlyToggle}
                                    />
                                  )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MuiThemeProvider theme={themeOverride}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                      variant="dialog"
                                      label={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SAVINGS_CONFIG_DAILY}
                                      inputVariant="outlined"
                                      value={config.lastDailyData}
                                      onChange={handleDaily}
                                        // format="DD/MM/YYYY HH:mm"
                                      format="DD/MM/YYYY"
                                      fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                              label="Pause Daily tasks"
                              control={(
                                    <Checkbox
                                      checked={config.pauseDaily}
                                      onChange={handleDailyToggle}
                                    />
                                  )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MuiThemeProvider theme={themeOverride}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                      variant="dialog"
                                      label={
                                            VERBIAGE.PLACEHOLDER_TEXT.ENTER_SAVINGS_CONFIG_MONTHLY
                                        }
                                      inputVariant="outlined"
                                      value={config.lastMonthlyData}
                                      onChange={handleMonthly}
                                        // format="DD/MM/YYYY HH:mm"
                                      format="DD/MM/YYYY"
                                      fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                              label="Pause Monthly tasks"
                              control={(
                                    <Checkbox
                                      checked={config.pauseMonthly}
                                      onChange={handleMonthlyToggle}
                                    />
                                  )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DropdownSelect
                              name={config.status}
                              options={VERBIAGE.TEXT.STATUS_OPTIONS}
                              onChange={handleStatus}
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_STATUS}
                            />
                        </Grid>
                        {!!error && (
                            <div className={classes.error}>
                                <Typography>{error}</Typography>
                            </div>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleSubmit}>
                        {VERBIAGE.BUTTONS.SUBMIT}
                    </Button>
                    <Button
                      className={classes.error}
                      onClick={() => {
                            setOpen(false);
                            setError("");
                        }}
                    >
                        {VERBIAGE.BUTTONS.CLOSE}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    // config.since = moment().set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0})
    const since = moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("DD MMM YYYY")
        .toString();
    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${
                    isEdit ? "edit" : "add to"
                } the Savings Configuration, please enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextInput
                          onChange={handleSite}
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
                          value={config.siteId}
                          type="text"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiThemeProvider theme={themeOverride}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  variant="dialog"
                                  label={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SAVINGS_CONFIG_SINCE}
                                  inputVariant="outlined"
                                  defaultValue={since}
                                  value={config.since || since}
                                  onChange={handleSince}
                                  format="DD MMM YYYY"
                                  fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <DropdownSelect
                          name={config.status}
                          options={VERBIAGE.TEXT.STATUS_OPTIONS}
                          onChange={handleStatus}
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_STATUS}
                        />
                    </Grid>
                    {!!error && (
                        <div className={classes.error}>
                            <Typography>{error}</Typography>
                        </div>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setOpen(false);
                        setError("");
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditSavingsConfigTable.styles = {
    inputContainer: "webclient__mapTable-input"
};

export default EditSavingsConfigTable;
