import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const ToggleButton = withStyles(() => ({
    root: {
        cursor: props => (props.locked === "true" ? "not-allowed" : "")
    }
}))(Button);

export default ToggleButton;
