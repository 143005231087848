/* eslint-disable max-len */
import PIDActionTypes from "./PIDActionTypes";
import api from "../../utils/API";
import URL from "../../utils/URL";

const config = {
    headers: {
        // "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        "x-auth-token": ""
    }
};
function constructHeader() {
    const token = localStorage.getItem("token");
    config.headers["x-auth-token"] = `${token}`;
    return config;
}
const PIDActions = {
    setData: data => ({
        type: PIDActionTypes.SET_DATA,
        data
    }),
    fetchData: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.PLANT_SCHEMATIC.PID_DATA, config)
            .then(response => {
                dispatch(PIDActions.setData(response.data.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    }
};
export default PIDActions;
