import React, { forwardRef } from "react";
import { Button, withStyles } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";

const InterlockButton = withStyles(theme => {
    const getColor = props => {
        return props.active === "true" ? green[500] : grey[200];
    };
    return {
        root: {
            color: props => theme.palette.getContrastText(getColor(props)),
            backgroundColor: props => getColor(props),
            "&:hover": {
                backgroundColor: props => getColor(props),
                color: props => theme.palette.getContrastText(getColor(props))
            },
            borderRadius: 0
            // cursor: "not-allowed"
            // pointerEvents: "none"
        }
    };
})(forwardRef((props, ref) => <Button disableElevation ref={ref} {...props} />));

export default InterlockButton;
