import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import ToggleButton from "./components/ToggleButton";
import useModbusEnableML from "./hooks/useModbusEnableML";

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: 0,
        boxShadow: theme.shadows[2],
        "&:hover": {
            backgroundColor: props => props.bgColor
        }
    }
}));
const PIDModbusEnableMLStatus = () => {
    const { updating, error, enabled, toggleStatus } = useModbusEnableML();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggleStatus = () => {
        setOpen(false);
        toggleStatus();
    };

    const getButtonText = () => {
        if (updating) {
            return enabled ? "DISABLING..." : "ENABLING...";
        }
        return enabled ? "ENABLED" : "DISABLED";
    };

    const opacity = updating ? 200 : 500;
    const bgColor = enabled ? green[opacity] : red[opacity];
    const classes = useStyles({ bgColor });
    return (
        <>
            <Box bgcolor={bgColor} color="black" clone>
                <Button disabled={updating} className={classes.button} onClick={handleOpen}>
                    {getButtonText()}
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Typography>
                        Are you sure want to 
{' '}
<strong>{enabled ? "Disable" : "Enable"}</strong>
{' '}
ML ?
                    </Typography>
                    {error && (
                        <Typography align="center" color="error">
                            {error}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <ToggleButton color="primary" disabled={updating} onClick={handleToggleStatus}>
                        {enabled ? "Disable" : "Enable"}
                    </ToggleButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PIDModbusEnableMLStatus;
