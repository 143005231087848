/* eslint-disable array-callback-return  */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CardData from "./CardData";
import REFRESH_TIMER from "../../../utils/enums/Enum";
import PortfolioActions from "../../redux/PortfolioActions";
import { IMAGE } from "../../../utils/enums/index";

const useStyles = makeStyles(theme => ({
    grid: {
        marginTop: "0.3vh"
    },
    saving: {
        width: "100%",
        // height: "100%",
        // marginTop: "1vh",
        display: "flex"
    },
    paper: {
        padding: theme.spacing(0),
        width: "100%",
        // height: "100px",
        backgroundColor: theme.palette.type === "dark" ? "#201F25" : "white",
        color: theme === "dark" ? "white" : "black",
        alignItems: "center"
    }
}));

const CardPortfolioSavings = props => {
    const { fetchPortfolioSavings, portfolio } = props;
    const classes = useStyles();

    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        fetchPortfolioSavings();
        const timer = setInterval(() => {
            fetchPortfolioSavings();
        }, timerValue);
        return () => clearInterval(timer);
    }, [fetchPortfolioSavings, REFRESH_TIMER()]);

    const handleIcon = img => {
        const entries = Object.entries(IMAGE);
        let imgURL;
        entries.map(([prop, val]) => {
            if (prop === img) {
                imgURL = val;
            }
        });
        return imgURL;
    };
    return (
      <Grid container item className={classes.grid} elevation={0} spacing={1}>
        {portfolio &&
                portfolio.savings &&
                portfolio.savings.savings.map(savings => (
                  <Grid
                    container
                    item
                    xs={6}
                    className={classes.grid}
                    elevation={0}
                    key={`${savings.icon}_savings`}
                  >
                    <Paper className={classNames(classes.saving, classes.paper)} elevation={0}>
                      <CardData
                        image={handleIcon(savings.icon)}
                        title={savings.label}
                        savings={savings.value}
                        unit={savings.unit}
                        styleValue={CardPortfolioSavings.styles.image}
                      />
                    </Paper>
                  </Grid>
                ))}
      </Grid>
    );
};
CardPortfolioSavings.styles = {
    image: "webclient__portfolioRightView-imageCardPortfolioSavings"
};
const mapStateToProps = state => ({
    portfolio: state.portfolio
});
const mapDispatchToProps = dispatch => ({
    fetchPortfolioSavings: () => {
        dispatch(PortfolioActions.fetchPortfolioSavings());
    }
});
CardPortfolioSavings.propTypes = {
    fetchPortfolioSavings: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardPortfolioSavings));
