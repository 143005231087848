import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Button,
    Grid,
    Paper,
    Typography,
    Dialog,
    List,
    ListItem,
    ListItemText,
    makeStyles
} from "@material-ui/core";

import useQueryParams from "../../../hooks/useQueryParams";

import Colors from "../../../utils/Colors";
import api from "../../../utils/API";
import URL from "../../../utils/URL";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    label: {
        color: Colors.DARK_BLUE
    },
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1)
        }
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        "& img": {
            objectFit: "contain"
        }
    },
    list: {
        columns: 2
    }
}));

const WaterPumpSelector = () => {
    const { getQuery, setQuery, deleteQuery } = useQueryParams();
    const siteId = getQuery("siteNumber");
    const [wpId, setWpId] = useState(getQuery("wpId"));

    const [open, setOpen] = useState(false);
    const [waterpumps, setWaterpumps] = useState([]);

    const history = useHistory();
    const location = useLocation();

    const isMounted = useRef(false);

    const resetWpId = useCallback(() => {
        setWpId(null);
        history.push({
            pathname: location.pathname,
            search: deleteQuery("wpId")
        });
    }, [deleteQuery, history, location.pathname]);

    useEffect(() => {
        const fetchWaterPumps = async () => {
            try {
                const response = await api.get(URL.DATA.DWH.SITE_WATER_PUMP, {
                    headers: {
                        "x-auth-token": localStorage.token
                    },
                    params: {
                        siteId,
                        isActive: true,
                        _select: ["_id", "wpId", "wpMBrand", "wpMModel", "wpPBrand", "wpPModel"],
                        _sort: "wpId",
                        _order: "ASC"
                    }
                });

                if (isMounted.current) {
                    setWaterpumps(response.data);
                }
            } catch (error) {
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        if (siteId) {
            fetchWaterPumps();
        }

        return () => (isMounted.current = false);
    }, [siteId]);

    useEffect(() => {
        if (wpId && waterpumps.length) {
            const index = waterpumps.findIndex(item => item.wpId === +wpId);
            if (index === -1) {
                resetWpId();
            }
        }
    }, [waterpumps, wpId, resetWpId]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSelect = waterPump => {
        if (waterPump) {
            setWpId(waterPump.wpId);
            history.push({
                pathname: location.pathname,
                search: setQuery({ wpId: waterPump.wpId })
            });
        }
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={3} lg={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!waterpumps.length}
                      onClick={handleOpen}
                    >
                        Select Pump
                    </Button>
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    {wpId && (
                        <Typography className={classes.label}>
                            Selected Water Pump ID: 
{' '}
{wpId}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <WaterPumpSelectorDialog
              fullWidth
              maxWidth="xs"
              open={open}
              onClose={handleClose}
              onSelect={handleSelect}
              waterpumps={waterpumps}
              selected={wpId}
            />
        </Paper>
    );
};

const WaterPumpSelectorDialog = ({ waterpumps, selected, onSelect, ...props }) => {
    const classes = useStyles();

    const getDetails = waterPump => {
        return (
            <ul className={classes.list}>
                <li>
                    Motor Brand:
                    {waterPump.wpMBrand}
                </li>
                <li>
                    Motor Model:
                    {waterPump.wpMModel}
                </li>
                <li>
                    Pump Brand:
                    {waterPump.wpPBrand}
                </li>
                <li>
                    Pump Model:
                    {waterPump.wpPModel}
                </li>
            </ul>
        );
    };

    return (
        <Dialog {...props}>
            <List>
                {waterpumps.map(waterPump => (
                    <ListItem
                      key={waterPump._id}
                      selected={waterPump.wpId === selected}
                      onClick={() => onSelect(waterPump)}
                      className={classes.root}
                      button
                    >
                        <ListItemText
                          primary={(
                                <>
                                    <Typography variant="subtitle1">Water Pump ID</Typography>
                                    <Typography variant="subtitle2">{waterPump.wpId}</Typography>
                                </>
                              )}
                        />
                        <ListItemText primary={getDetails(waterPump)} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default WaterPumpSelector;
