import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import MLActions from "../../../redux/MLActions";

const useModbusEnableML = () => {
    const { modbusEnableML } = useSelector(state => state.ML);

    const dispatch = useDispatch();

    const getStatus = useCallback(() => {
        dispatch(MLActions.getModbusEnableML());
    }, [dispatch]);

    const toggleStatus = () => {
        dispatch(MLActions.postModbusEnableML(!modbusEnableML.enabled));
    };

    return {
        ...modbusEnableML,
        getStatus,
        toggleStatus
    };
};

export default useModbusEnableML;
