/* eslint-disable react/destructuring-assignment  */
/* eslint-disable no-unused-vars  */
import React, { useState } from "react";
import { Grid, Tab, Tabs, useMediaQuery } from "@material-ui/core";

const FaultsAndAlarmsMainTabs = props => {
    const { classes, tabStyle, value, handleChange } = props;

    return (
        <Grid container className={classes.tabContainer}>
            <Grid item xs={12} style={{ marginTop: -15 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons={useMediaQuery("(max-width: 850px)") ? "on" : "auto"}
                  classes={{
                        fixed: classes.fixed,
                        scrollable: classes.fixed
                    }}
                >
                    <Tab style={tabStyle} value="fault_summary" label="Summary" wrapped />
                    <Tab
                      style={tabStyle}
                      value="fault_anomaly_diagnosis"
                      label="Anomaly Diagnosis"
                      wrapped
                    />
                </Tabs>
            </Grid>
        </Grid>
    );
};

export default FaultsAndAlarmsMainTabs;
