import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Button,
    Grid,
    Paper,
    Typography,
    Dialog,
    List,
    ListItem,
    ListItemText,
    makeStyles
} from "@material-ui/core";

import useQueryParams from "../../../hooks/useQueryParams";

import Colors from "../../../utils/Colors";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import { VERBIAGE } from "../../../utils/enums";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    label: {
        color: Colors.DARK_BLUE
    },
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1)
        }
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        "& img": {
            objectFit: "contain"
        }
    }
}));

const PidSelector = () => {
    const { getQuery, setQuery, deleteQuery } = useQueryParams();
    const siteId = getQuery("siteNumber");
    const [pidId, setPidId] = useState(getQuery("pidId"));

    const [open, setOpen] = useState(false);
    const [pids, setPids] = useState([]);

    const history = useHistory();
    const location = useLocation();

    const isMounted = useRef(false);

    useEffect(() => {
        const fetchPids = async () => {
            try {
                const response = await api.get(URL.DATA.DWH.SITE_PID, {
                    headers: {
                        "x-auth-token": localStorage.token
                    },
                    params: {
                        siteId,
                        _select: ["_id", "pidId", "spSelection"],
                        _sort: "pidId",
                        _order: "asc"
                    }
                });

                if (isMounted.current) {
                    setPids(response.data);
                }
            } catch (error) {
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        if (siteId) {
            fetchPids();
        }

        return () => (isMounted.current = false);
    }, [siteId]);

    useEffect(() => {
        const resetPIdId = () => {
            setPidId(null);
            history.push({
                pathname: location.pathname,
                search: deleteQuery("pidId")
            });
        };
        if (pidId) {
            if (pids.length) {
                const index = pids.findIndex(item => item.pidId === +pidId);
                if (index > -1) return;
            }
            resetPIdId();
        }
    }, [pids, pidId, history, location.pathname, deleteQuery]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSelect = pid => {
        if (pid) {
            setPidId(pid.pidId);
            const pathname =
                location.pathname === VERBIAGE.WAREHOUSE_PATH.PID_OVERVIEW
                    ? VERBIAGE.WAREHOUSE_PATH.PID_TIME_BASED
                    : location.pathname;
            history.push({
                pathname,
                search: setQuery({ pidId: pid.pidId })
            });
        }
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={3} lg={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!pids.length}
                      onClick={handleOpen}
                    >
                        Select PID
                    </Button>
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    {pidId && (
                        <Typography className={classes.label}>
                            Selected PID:
                            {pidId}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <PidSelectorDialog
              fullWidth
              maxWidth="xs"
              open={open}
              onClose={handleClose}
              onSelect={handleSelect}
              pids={pids}
              selected={pidId}
            />
        </Paper>
    );
};

const PidSelectorDialog = ({ pids, selected, onSelect, ...props }) => {
    const classes = useStyles();

    const getDetails = pid => {
        return (
            <ul className={classes.list}>
                <li>
                    Sp Selection:
                    {pid.spSelection}
                </li>
            </ul>
        );
    };

    return (
        <Dialog {...props}>
            <List>
                {pids.map(pid => (
                    <ListItem
                      key={pid._id}
                      selected={pid.pidId === selected}
                      onClick={() => onSelect(pid)}
                      className={classes.root}
                      button
                    >
                        <ListItemText
                          primary={(
                                <>
                                    <Typography variant="subtitle1">Pid ID</Typography>
                                    <Typography variant="subtitle2">{pid.pidId}</Typography>
                                </>
                              )}
                        />
                        <ListItemText primary={getDetails(pid)} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default PidSelector;
