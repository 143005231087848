/* eslint-disable max-len */
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
// import axios from "axios";
import "babel-polyfill";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Store from "./app/Store";
// import App from "./app/App";
import Router from "./app/routes/Routes";
import URL from "./app/utils/URL";
import api from "./app/utils/API";

if (process.env.SENTRY_DSN) {
    Sentry.init({
        dsn: `${process.env.SENTRY_DSN}`,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
}
// To Use React Router with Redux,
// a configured router is required.

const fetchRefreshToken = () => {
    const authToken = localStorage.getItem("token");
    api.get(URL.AUTHENTICATION.REFRESH_TOKEN, {
        headers: {
            "x-auth-token": authToken
        }
    })
        .then(response => {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("maintenance", JSON.stringify(response.data.maintenance));
            localStorage.setItem("server_version", response.data.version);
        })
        .catch(error => {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        });
};
// try to reduce 1 or 2 minutes from the servertimer
// for example if its 30 mins in server then use 28 or 29 in client
const timerValue = 5 * 1000 * 60;
setInterval(() => {
    fetchRefreshToken();
}, timerValue);
if (window.performance.navigation.type === 1) {
    fetchRefreshToken();
}
render(
    <Provider store={Store}>
        <Router />
    </Provider>,
    document.getElementById("container"),
);
