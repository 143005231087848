import ReportActionTypes from "./ReportActionTypes";

import api from "../../utils/API";
import URL from "../../utils/URL";
import DynamicReportStorage from "../../utils/DynamicReportStorage";

// TODO: use interceptor to send auth token header
function getRequestConfig(options = {}) {
    const token = localStorage.getItem("token");
    return {
        headers: {
            "x-auth-token": token
        },
        ...options
    };
}

function handleRequestError(error, cb) {
    switch (error?.response?.status) {
        case 401:
            localStorage.clear();
            window.location.href = "/";
            return;
        default:
    }
    cb();
}

const dynamicReportStorage = new DynamicReportStorage("dynamicReportTabs");

const ReportActions = {
    setReportLoading: data => ({
        type: ReportActionTypes.SET_LOADING,
        data
    }),
    setReportError: data => ({
        type: ReportActionTypes.SET_ERROR,
        data
    }),
    fetchReportSiteList: () => async dispatch => {
        try {
            dispatch(ReportActions.setReportLoading(true));
            dispatch(ReportActions.setReportError(null));

            const config = getRequestConfig();
            const response = await api.get(URL.DATA.REPORT.SITE_LIST, config);
            dispatch({
                type: ReportActionTypes.SET_SITE_REPORT,
                data: response.data
            });
        } catch (error) {
            handleRequestError(error, () => {
                dispatch(ReportActions.setReportError(error.message));
            });
        } finally {
            dispatch(ReportActions.setReportLoading(false));
        }
    },
    fetchDynamicReportData: () => async dispatch => {
        try {
            dispatch(ReportActions.setReportLoading(true));
            dispatch(ReportActions.setReportError(null));

            const url = URL.DATA.REPORT.DYNAMIC;
            const config = getRequestConfig();
            const response = await api.get(url, config);
            const data = response.data.map((item, index) => ({
                ...item,
                no: index + 1
            }));
            dispatch({
                type: ReportActionTypes.SET_DYNAMIC_REPORT_DATA,
                data
            });
            // need to sync the saved dynamic reports
            const [hasChanged, newTabs] = dynamicReportStorage.sync(response.data);
            if (hasChanged) {
                dispatch({
                    type: ReportActionTypes.SET_DYNAMIC_REPORT_SET_TABS,
                    tabs: newTabs
                });
                dispatch(ReportActions.saveDynamicReportTabs());
            }
        } catch (error) {
            handleRequestError(error, () => {
                dispatch(ReportActions.setReportError(error.message));
            });
        } finally {
            dispatch(ReportActions.setReportLoading(false));
        }
    },
    loadDynamicReportTabs: () => dispatch => {
        const tabs = dynamicReportStorage.load();
        dispatch({
            type: ReportActionTypes.SET_DYNAMIC_REPORT_SET_TABS,
            tabs
        });
    },
    addDynamicReportTab: report => dispatch => {
        if (!report?.main || !report?.name) {
            return;
        }

        const reportTabs = dynamicReportStorage.load();
        const reportTab = { label: report.name, value: report.main };
        const reportTabIndex = reportTabs.findIndex(tab => tab.value === reportTab.value);
        if (reportTabIndex > -1) {
            dispatch({
                type: ReportActionTypes.SET_DYNAMIC_REPORT_UPDATE_TAB,
                index: reportTabIndex,
                tab: reportTab
            });
        } else {
            dispatch({
                type: ReportActionTypes.SET_DYNAMIC_REPORT_ADD_TAB,
                tab: reportTab
            });
        }
        dispatch(ReportActions.selectDynamicReportTab(reportTab));
    },
    selectDynamicReportTab: report => dispatch => {
        if (!report) {
            dispatch({
                type: ReportActionTypes.SET_DYNAMIC_REPORT_SELECTED_TAB,
                tab: null
            });
            return;
        }

        dispatch({
            type: ReportActionTypes.SET_DYNAMIC_REPORT_SELECTED_TAB,
            tab: report
        });
        dispatch(ReportActions.saveDynamicReportTabs());
    },
    removeDynamicReportTab: removedTab => (dispatch, getState) => {
        if (!removedTab) return;

        const {
            dynamicReport: { tabs }
        } = getState().report;
        const index = tabs.findIndex(tab => tab.value === removedTab.value);
        if (index === -1) return;
        dispatch({
            type: ReportActionTypes.SET_DYNAMIC_REPORT_REMOVE_TAB,
            index
        });
        dispatch(ReportActions.saveDynamicReportTabs());
    },
    saveDynamicReportTabs: () => (_dispatch, getState) => {
        const {
            dynamicReport: { tabs }
        } = getState().report;
        dynamicReportStorage.set(tabs);
    }
};

export default ReportActions;
