/* eslint-disable max-len */
import React from "react";
import { Select, FormControl, FormHelperText, InputLabel, MenuItem } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { VERBIAGE } from "../../../utils/enums/index";

const DropdownSelectGroupings = props => {
    const {
        id,
        name,
        options,
        onChange,
        placeholder,
        isMulti,
        disabled,
        error,
        helperText,
        inputRef
    } = props;

    // Override border color of input
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            }
        }
    });

    return (
        <MuiThemeProvider theme={themeOverride}>
            <FormControl variant="outlined" fullWidth error={error}>
                <InputLabel>{placeholder}</InputLabel>
                <Select
                  id={id}
                  inputRef={inputRef}
                  disabled={disabled}
                  label={placeholder}
                  value={!name ? "" : name}
                  onChange={onChange}
                  fullWidth
                  variant="outlined"
                  multiple={isMulti}
                  defaultValue="None"
                    // grouping={grouping}
                  MenuProps={{ disableScrollLock: true }}
                >
                    <MenuItem value="" disabled>
                        Please select...
                    </MenuItem>
                    {options.map(option => {
                        return (
                            <MenuItem key={option.userId} value={option.userId}>
                                {`${option.publicName}(${option.email})`}
                            </MenuItem>
                        );
                    })}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </MuiThemeProvider>
    );
};

export default DropdownSelectGroupings;
