import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { dark } from "@material-ui/core/colors";
import VERBIAGE from "../../../utils/enums/Verbiage";

const theme = createMuiTheme({
    palette: {
        primary: dark
    }
});
const ButtonView = props => {
    const { label, className, path } = props;

    return (
      <div>
        <ThemeProvider theme={theme}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={className}
            component={RouterLink}
            to={path}
          >
            {label}
          </Button>
        </ThemeProvider>
      </div>
    );
};
ButtonView.propTypes = {
    label: PropTypes.string,
    path: PropTypes.string
};

ButtonView.defaultProps = {
    label: "apply",
    path: VERBIAGE.TEXT.EMPTY_STRING
};
export default ButtonView;
