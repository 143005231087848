import React, { useState } from "react";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import "../styles/header.scss";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/sidebar.scss";
import FilterModal from "../../modal/FilterModal";

const Header = props => {
    const { home, download, modal, handleCollapse, collapse, password } = props;
    const [showModal, setShowModal] = useState(false);
    const handleModalOpen = () => {
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };

    const handlehome = () => {
        home.isPasswordChange = false;
        props.history.push("/dashboard/home");
    };

    const downloadPDF = () => {
        const input = window.document.getElementsByClassName("div2PDF")[0];
        const pdf = new JsPDF("l", "pt", ["1800", "900"]); // height , width
        html2canvas(input).then(canvas => {
            const img = canvas.toDataURL("image/png");
            pdf.addImage(
                img,
                "png",
                10, // left margin
                30, // top margin
                600,
                240, // graph height
            );
            const table = window.document.getElementById("table");
            html2canvas(table).then(canvasData => {
                const img2 = canvasData.toDataURL("image/png");
                pdf.addImage(
                    img2,
                    "png",
                    10, // left margin
                    280, // top margin
                    600,
                    table.clientHeight - 100,
                );
                pdf.save("report.pdf");
            });
        });
    };

    return (
      <div>
        <div className={Header.styles.rootContainer}>
          <div className={Header.styles.subContainer}>
            {modal && (
            <button
              type="button"
              className={Header.styles.filter}
              onClick={handleModalOpen}
            >
              {VERBIAGE.BUTTONS.DATE_FILTER}
            </button>
                    )}
            {password && (
            <button type="button" className={Header.styles.home} onClick={handlehome}>
              {VERBIAGE.TEXT.CHANGE_PASSWORD}
            </button>
                    )}
            {collapse && (
            <button
              type="button"
              className={Header.styles.filter}
              onClick={handleCollapse}
            >
              {VERBIAGE.BUTTONS.CHILLER_OBSERVER}
            </button>
                    )}
            {download && (
            <i
              className="fa fa-download"
              aria-hidden="true"
              onClick={downloadPDF}
              style={{
                                cursor: "pointer",
                                fontSize: "1.8rem",
                                display: "flex",
                                justifyContent: "flex-end"
                            }}
            />
                    )}
          </div>
          <div>
            <Modal
              isOpen={showModal}
              onRequestClose={handleModalClose}
              className={Header.styles.content}
              overlayClassName={Header.styles.overlay}
              ariaHideApp={false}
            >
              <FilterModal handleClose={handleModalClose} />
            </Modal>
          </div>
        </div>
      </div>
    );
};

Header.styles = {
    rootContainer: "webclient__header-container-root",
    username: "webclient__header-username",
    home: "webclient__header-change-password",
    subContainer: "webclient__header-container-sub",
    filter: "webclient__header-filter",
    content: "webclient__header-container-content",
    overlay: "webclient__header-container-overlay"
};

Header.propTypes = {
    download: PropTypes.bool,
    modal: PropTypes.bool,
    collapse: PropTypes.bool,
    handleCollapse: PropTypes.func,
    password: PropTypes.bool
};

const mapStateToProps = state => ({
    home: state.home
});

Header.defaultProps = {
    download: false,
    modal: false,
    collapse: false,
    password: false,
    handleCollapse: () => {}
};

export default connect(mapStateToProps)(withRouter(Header));
