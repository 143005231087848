import React from "react";
import OnboardingList from "./siteOnboarding/OnboardingList";
import OnboardingMappingTable from "./siteOnboarding/OnboardingMappingTable";

const SiteOnboardingTabs = props => {
    const { value } = props;

    return (
        <div>
            {value === "onboardingList" && <OnboardingList />}
            {value === "onboardingMap" && <OnboardingMappingTable />}
        </div>
    );
};

export default SiteOnboardingTabs;
