/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable jsx-a11y/no-distracting-elements  */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, useMediaQuery, Menu, MenuItem } from "@material-ui/core";
import {
    ArrowLeft,
    ArrowRight,
    DesktopWindowsOutlined,
    ExpandLess,
    ExpandMore,
    PhoneIphoneOutlined,
    ErrorOutline
} from "@material-ui/icons";
import { connect } from "react-redux";
import _moment from "moment";
import HomeActions from "../../features/redux/HomeActions";

import ICONS from "../../../utils/enums/IconData";
import SideBar from "./sideBar/SideBar";
import Clock from "./Clock";
import "../styles/systemHeader.scss";
import VERBIAGE from "../../../utils/enums/Verbiage";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        position: "relative"
    },
    title: {
        flexGrow: 1,
        marginLeft: "18px"
    },
    maintenance: {
        class: "marquee",
        flexGrow: 1,
        marginLeft: "18px",
        color: "#FF0000",
        fontSize: "14px"
    },
    design: {
        background: VERBIAGE.COLOUR_DARK_BLUE // "#000"
    },
    rightToolbar: {
        marginLeft: "auto",
        marginRight: -12
    },
    mobile: {
        background: theme.palette.type === "dark" ? "#0B0B0B" : "#fff",
        overflow: "auto",
        justifyContent: "space-between",
        position: "relative"
    },
    rightScroll: {
        position: "fixed",
        right: -10
    },
    leftScroll: {
        position: "fixed",
        left: -10
    },
    bottomScroll: {
        position: "fixed",
        bottom: 0,
        left: -3
    },
    topScroll: {
        position: "fixed",
        top: 60,
        left: -3
    },
    button: {
        color: "white",
        padding: 8,
        minWidth: 30,
        maxWidth: 30
    },
    icon: {
        minHeight: "24px",
        minWidth: "24px",
        color: VERBIAGE.COLOUR_NEW_YELLOW
    }
}));
const MobileDetect = require("mobile-detect");

const SystemHeader = props => {
    const { handleSN, desktopMobileSwitch, toggleDesktopMobile, setOrientation } = props;
    const classes = useStyles();
    const handleSignOut = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const [isMobile, setIsMobile] = useState(!!md.mobile());
    const [isTablet, setIsTablet] = useState(!!md.tablet());

    const landscape = useMediaQuery("(orientation: landscape)");
    const portrait = useMediaQuery("(orientation: portrait)");

    useEffect(() => {
        if (landscape) {
            setIsMobile(false);
            setIsTablet(false);
            setOrientation("landscape");
        } else if (desktopMobileSwitch === "desktop") {
            setIsMobile(false);
            setIsTablet(false);
        } else {
            setIsMobile(!!md.mobile());
            setIsTablet(!!md.tablet());
            setOrientation("portrait");
        }
    }, [landscape, portrait, desktopMobileSwitch]);

    const [isScrolledRight, setIsScrolledRight] = useState(false);

    const scrollClickHorizontal = () => {
        const element = document.getElementById("mobile-tabs");
        if (isScrolledRight) {
            element.scrollLeft = 0;
            setIsScrolledRight(false);
        } else {
            element.scrollLeft = 500;
            setIsScrolledRight(true);
        }
    };

    const handleScrollHorizontalSidebar = () => {
        const element = document.getElementById("mobile-tabs");
        if (element.scrollLeft >= 200) {
            setIsScrolledRight(true);
        } else {
            setIsScrolledRight(false);
        }
    };

    const [isScrolledDown, setIsScrolledDown] = useState(false);

    const scrollClickVertical = () => {
        const element = document.getElementById("mySidebar");
        if (isScrolledDown) {
            element.scrollTop = 0;
            setIsScrolledDown(false);
        } else {
            element.scrollTop = 500;
            setIsScrolledDown(true);
        }
    };

    const handleScrollVerticalSidebar = () => {
        const element = document.getElementById("mySidebar");
        if (!element) return;
        if (element.scrollTop >= 100) {
            setIsScrolledDown(true);
        } else {
            setIsScrolledDown(false);
        }
    };

    const renderTopSidebar = () => {
        if (desktopMobileSwitch === "mobile") {
            // if mobile device
            if (md.mobile()) {
                // if portrait orientation on mobile, show top sidebar
                if (portrait) {
                    return (
                        <>
                            <Toolbar
                              className={classes.mobile}
                              id="mobile-tabs"
                              onScroll={handleScrollHorizontalSidebar}
                            >
                                <SideBar
                                  handleSiteName1={handleSN}
                                  isMobile={isMobile}
                                  isTablet={isTablet}
                                  landscape={landscape}
                                  portrait={portrait}
                                  narrow
                                />
                                {/* with arrow indicators for non-tablets */}
                                {!md.tablet() && (
                                    <>
                                        {!isScrolledRight && (
                                            <IconButton
                                              onClick={scrollClickHorizontal}
                                              className={classes.rightScroll}
                                            >
                                                <ArrowRight />
                                            </IconButton>
                                        )}
                                        {isScrolledRight && (
                                            <IconButton
                                              onClick={scrollClickHorizontal}
                                              className={classes.leftScroll}
                                            >
                                                <ArrowLeft />
                                            </IconButton>
                                        )}
                                    </>
                                )}
                            </Toolbar>
                        </>
                    );
                }
            } else {
                // computer, selected mobile view
                return (
                    <>
                        <Toolbar
                          className={classes.mobile}
                          id="mobile-tabs"
                          onScroll={handleScrollHorizontalSidebar}
                        >
                            <SideBar
                              handleSiteName1={handleSN}
                              isMobile
                              isTablet
                              landscape={landscape}
                              portrait={portrait}
                            />
                        </Toolbar>
                    </>
                );
            }
        }
        return <></>;
    };

    const renderLeftSidebar = () => {
        if (desktopMobileSwitch === "desktop") {
            return (
                <SideBar
                  handleSiteName1={handleSN}
                    // isMobile={isMobile}
                    // isTablet={isTablet}
                  landscape={landscape}
                  portrait={portrait}
                />
            );
        }
        if (landscape && !!md.mobile()) {
            if (md.tablet()) {
                return (
                    <SideBar
                      handleSiteName1={handleSN}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      landscape={landscape}
                      portrait={portrait}
                    />
                );
            }
            return (
                <>
                    <SideBar
                      handleSiteName1={handleSN}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      landscape={landscape}
                      portrait={portrait}
                    />
                    {isScrolledDown && !!md.mobile() && (
                        <IconButton className={classes.topScroll} onClick={scrollClickVertical}>
                            <ExpandLess />
                        </IconButton>
                    )}
                    {!isScrolledDown && !!md.mobile() && (
                        <IconButton className={classes.bottomScroll} onClick={scrollClickVertical}>
                            <ExpandMore />
                        </IconButton>
                    )}
                </>
            );
        }
        return <></>;
    };

    const curentUser = localStorage.getItem("currentUser");
    // const lastUpdate = localStorage.getItem("lastUpdate"); // eslint-disable-line no-unused-vars
    return (
        <div className={classes.root} onScroll={handleScrollVerticalSidebar}>
            {renderLeftSidebar()}
            <AppBar className={classes.design}>
                <Toolbar>
                    <div className={SystemHeader.styles.logoContainer}>
                        <img alt="logo" src={ICONS.LOGO} height="55" />
                    </div>
                    <div className={classes.title}>
                        {/* <div styles={{ marginRight: "18px", width: "max-content" }}> */}
                        {/* To hide datetime in mobile view */}
                        &nbsp;&nbsp;&nbsp;
                        {!isMobile && window.innerWidth > 500 && <Clock />}
                    </div>
                    {localStorage.maintenance &&
                    JSON.parse(localStorage.maintenance).length > 0 && ( // eslint-disable max-len
                            <div className={classes.maintenance}>
                                {/* <div class="marquee" > */}
                                <marquee behavior="scroll" direction="left">
                                    <p>
                                        <b>
                                            {`System Notice : ${
                                                JSON.parse(localStorage.maintenance)[0].notification
                                            } `}
                                            {_moment(
                                                JSON.parse(localStorage.maintenance)[0].date,
                                            ).format("MMMM Do YYYY, h:mm:ss a")}
{" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {`System Notice : ${
                                                JSON.parse(localStorage.maintenance)[0].notification
                                            } `}
                                            {_moment(
                                                JSON.parse(localStorage.maintenance)[0].date,
                                            ).format("MMMM Do YYYY, h:mm:ss a")}
                                        </b>
                                    </p>
                                </marquee>
                            </div>
                        )}
                    <div className={SystemHeader.styles.sub1Container}>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </div>
                    <div className={SystemHeader.styles.sub1Container}>
                        {!isMobile && window.innerWidth > 500 && (
                            <>
                                <div>
                                    {curentUser}
                                    {/* {`,`}
                  </div>
                  <div className={SystemHeader.styles.greyuserContainer}>
                    {curentUser} */}
                                </div>
                            </>
                        )}
                        {localStorage.maintenance &&
                            JSON.parse(localStorage.maintenance).length > 0 && (
                                <>
                                    <div>
                                        <IconButton
                                          onClick={event => setAnchorEl(event.currentTarget)}
                                          classes={{ root: classes.button }}
                                        >
                                            <div />
                                            <ErrorOutline
                                              classes={{
                                                    root: classes.icon
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={handleClose}
                                      style={{ top: "40px" }}
                                    >
                                        {JSON.parse(localStorage.maintenance).map(mt => {
                                            return (
                                                <MenuItem key={mt.id}>{mt.notification}</MenuItem>
                                            );
                                        })}
                                    </Menu>
                                    <div className={SystemHeader.styles.horizonContainer} />
                                </>
                            )}
                        {/* <div>
                <img
                  src={ICONS.NOTIFICATION}
                  alt="notification"
                  className={SystemHeader.styles.iconContainer}
                />
              </div>
              <div className={SystemHeader.styles.horizonContainer} /> */}
                        <div>
                            <IconButton
                              classes={{ root: classes.button }}
                              onClick={toggleDesktopMobile}
                            >
                                {desktopMobileSwitch === "desktop" ? (
                                    <DesktopWindowsOutlined />
                                ) : (
                                    <PhoneIphoneOutlined />
                                )}
                            </IconButton>
                        </div>
                        {/* <div className={SystemHeader.styles.horizonContainer} /> */}
                        {/* <div>
                <img
                  src={ICONS.LOCK}
                  alt="lock"
                  className={SystemHeader.styles.iconContainer}
                />
              </div> */}
                        <div className={SystemHeader.styles.horizonContainer} />
                        <div>
                            <button
                              type="button"
                              onClick={handleSignOut}
                              className={SystemHeader.styles.logoutContainer}
                            >
                                <img src={ICONS.LOGOUT} alt="moon" path="#" />
                            </button>
                        </div>
                    </div>
                </Toolbar>
                {renderTopSidebar()}
            </AppBar>
        </div>
    );
};

SystemHeader.styles = {
    rootContainer: "webclient__systemheader-container-root",
    timeZoneContainer: "webclient__systemheader-container-timezone",
    logoContainer: "webclient__systemheader-container-logo",
    iconContainer: "webclient__systemheader-container-icon",
    subContainer: "webclient__systemheader-container-sub",
    sub1Container: "webclient__systemheader-container-sub1",
    horizonContainer: "webclient__systemheader-container-horizon",
    logoutContainer: "webclient__systemheader-container-logout",
    greyuserContainer: "webclient__systemheader-container-greyuser"
};
SystemHeader.propTypes = {
    handleSN: PropTypes.func
};

SystemHeader.defaultProps = {
    handleSN: () => {}
};

const mapStateToProps = state => ({
    desktopMobileSwitch: state.home.desktopMobileSwitch
});
const mapDispatchToProps = dispatch => ({
    toggleDesktopMobile: () => {
        dispatch(HomeActions.toggleDesktopMobile());
    },
    setOrientation: payload => {
        dispatch(HomeActions.setOrientation(payload));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemHeader);
