/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-unused-vars  */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Typography, makeStyles, Button } from "@material-ui/core";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/login.scss";
import TextInput from "../../../dashboard/common/components/TextInput";
import URL from "../../../utils/URL";
import api from "../../../utils/API";

const ForgotPassword = props => {
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");

    const handleEmail = event => {
        setEmail(event.target.value);
    };

    const handleClick = () => {
        props.history.push(VERBIAGE.DASHBOARD_PATH.LOGIN);
    };

    const handleSubmit = async () => {
        const url = `${URL.AUTHENTICATION.RESET_PASSWORD_REQUEST}/${email}`;
        await api.put(url);
        setStatus(VERBIAGE.TEXT.FORGOT_PASSWORD_SUBMITTED);
    };

    const useStyles = makeStyles(theme => ({
        status: {
            color: theme.palette.success.dark,
            variant: "subtitle1",
            textAlign: "center",
            margin: "2vh 0 2vh 0"
        },
        button: {
            marginTop: "20px"
        },
        main: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center",
            background: "#ffffff"
        },
        sub: {
            border: "2px solid #006ab4",
            height: "300px",
            width: "30%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        input: {
            height: "50px",
            width: "70%",
            fontSize: "large",
            marginBottom: "30px",
            marginTop: "50px"
        }
    }));

    const classes = useStyles();

    return (
      <div className={classes.main}>
        <h1 className={ForgotPassword.style.header}>{VERBIAGE.TEXT.FORGOT_PASSWORD}</h1>
        <div className={classes.sub}>
          <div className={classes.input}>
            <TextInput
              onChange={event => handleEmail(event)}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.EMAIL}
              value={email}
            />
          </div>
          <input
            type="button"
            className={ForgotPassword.style.button}
            value={VERBIAGE.BUTTONS.SUBMIT}
            onClick={handleSubmit}
          />
          <Button
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleClick}
          >
            Back to Login
          </Button>
          <div className={classes.status}>
            <Typography>{status}</Typography>
          </div>
        </div>
      </div>
    );
};

ForgotPassword.style = {
    mainContainer: "webclient__login-screen-container-main",
    subContainer: "webclient__login-screen-container-sub",
    inputContainer: "webclient__login-screen-container-input",
    button: "webclient__login-screen-button",
    header: "webclient__login-screen-header",
    forgotPassword: "webclient__login-screen-forgot-password",
    errorText: "webclient__login-screen-error-text"
};
export default withRouter(ForgotPassword);
