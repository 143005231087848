import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import MLActions from "../../../redux/MLActions";

const useMLSetpointData = () => {
    const {
        modbusEnableML,
        setpoint: { setpoint, errors, ...setpointStates }
    } = useSelector(state => state.ML);
    const dispatch = useDispatch();

    const setpoints = useMemo(() => {
        return Object.keys(setpoint).reduce((acc, sp) => {
            acc.push({
                SP: sp,
                ...setpoint[sp]
            });
            return acc;
        }, []);
    }, [setpoint]);

    const fetchData = useCallback(() => {
        dispatch(MLActions.getSetpointData());
    }, [dispatch]);

    const setRecommended = payload => {
        dispatch(MLActions.postSetpointRecData(payload));
    };

    const setStatus = payload => {
        dispatch(MLActions.postSetpointStatus(payload));
    };

    const hasErrors = Object.keys(errors).length > 0;
    const setErrors = (sp, error) => {
        dispatch(MLActions.setSetpointError(sp, error));
    };
    const clearErrors = () => {
        dispatch(MLActions.clearSetpointErrors());
    };

    return {
        disabled: !modbusEnableML.enabled,
        setpoints,
        fetchData,
        setRecommended,
        setStatus,
        errors,
        setErrors,
        hasErrors,
        clearErrors,
        ...setpointStates
    };
};

export default useMLSetpointData;
