/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";

import LoadingRetry from "../components/LoadingRetry";
import PIDModbusEnableMLStatus from "./PID_ModbusEnableMLStatus";
import useModbusEnableML from "./hooks/useModbusEnableML";

const PIDAutoMLModbusEnable = () => {
    const { loading, loaded, error, getStatus } = useModbusEnableML();

    useEffect(() => {
        getStatus();
    }, [getStatus]);

    return (
        <>
            <Grid item>
                <Typography variant="subtitle1">Machine Learning Setpoints</Typography>
            </Grid>
            <Grid item>
                {loaded ? (
                    <PIDModbusEnableMLStatus />
                ) : (
                    <LoadingRetry loading={loading} error={!!error} retryFn={getStatus} />
                )}
            </Grid>
        </>
    );
};

export default PIDAutoMLModbusEnable;
