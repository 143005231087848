import MLActionTypes from "./MLActionTypes";
import { getDefaultMLSettings } from "../../utils/MLsettings";

const initialState = {
    config: {
        ...getDefaultMLSettings()
    },
    modbusEnableML: {
        loading: false,
        loaded: false,
        updating: false,
        error: null,
        enabled: false,
        lastUpdatedAt: null
    },
    modbusHB: {
        loading: false,
        loaded: false,
        error: null,
        data: {
            value: false,
            meta: {}
        }
    },
    plcHB: {
        loading: false,
        loaded: false,
        error: null,
        data: {
            value: false,
            meta: {}
        }
    },
    setpoint: {
        loading: false,
        loaded: false,
        error: null,
        errors: {},
        spInRange: false,
        setpoint: {},
        lastUpdatedAt: null
    },
    train: {
        loading: false,
        error: null,
        dagId: null,
        dagLink: null,
        lastDagRun: null,
        dagRuns: [],
        triggerDagRun: null,
        tasks: [],
        taskInstances: {},
        lastTaskId: null
    },
    predict: {
        loading: false,
        error: null,
        dagId: null,
        dagLink: null,
        lastDagRun: null,
        dagRuns: [],
        triggerDagRun: null,
        tasks: [],
        taskInstances: {},
        lastTaskId: null
    }
};

const MLReducer = (state = initialState, action) => {
    switch (action.type) {
        case MLActionTypes.SET_ML_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                }
            };
        case MLActionTypes.SET_MODBUS_ENABLE_ML:
            return {
                ...state,
                modbusEnableML: {
                    ...state.modbusEnableML,
                    ...action.payload
                }
            };
        case MLActionTypes.SET_ML_MODBUS_HEARTBEAT:
            return {
                ...state,
                modbusHB: {
                    ...state.modbusHB,
                    ...action.payload
                }
            };
        case MLActionTypes.SET_ML_PLC_HEARTBEAT:
            return {
                ...state,
                plcHB: {
                    ...state.plcHB,
                    ...action.payload
                }
            };
        case MLActionTypes.SET_ML_SETPOINT:
            return {
                ...state,
                setpoint: {
                    ...state.setpoint,
                    ...action.payload
                }
            };
        case MLActionTypes.SET_ML_SETPOINT_SP: {
            let _errors = {};
            if (action.payload.error) {
                _errors = {
                    ...state.setpoint.errors,
                    [action.sp]: action.payload.error
                };
            } else {
                const cloneSpErrors = { ...state.setpoint.errors };
                delete cloneSpErrors[action.sp];
                _errors = cloneSpErrors;
            }
            return {
                ...state,
                setpoint: {
                    ...state.setpoint,
                    errors: _errors,
                    setpoint: {
                        ...state.setpoint.setpoint,
                        [action.sp]: {
                            ...state.setpoint.setpoint[action.sp],
                            ...action.payload
                        }
                    }
                }
            };
        }
        case MLActionTypes.SET_ML_SETPOINT_SP_ERROR: {
            let _errors = {};
            if (action.error) {
                _errors = {
                    ...state.setpoint.errors,
                    [action.sp]: action.error
                };
            } else {
                const cloneSpErrors = { ...state.setpoint.errors };
                delete cloneSpErrors[action.sp];
                _errors = cloneSpErrors;
            }
            return {
                ...state,
                setpoint: {
                    ...state.setpoint,
                    errors: _errors
                }
            };
        }
        case MLActionTypes.SET_ML_TRAIN:
            return {
                ...state,
                train: {
                    ...state.train,
                    ...action.payload
                }
            };
        case MLActionTypes.SET_ML_PREDICT:
            return {
                ...state,
                predict: {
                    ...state.predict,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default MLReducer;
