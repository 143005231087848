import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    RuleBasedOptimizationCDWPConfigActions,
    RuleBasedOptimizationCDWPMinMaxLoadActions,
    RuleBasedOptimizationCDWPMinMaxTimeActions
} from "../../../redux/RuleBasedOptimizationCDWPActions";

const useRuleBasedOptimizationCDWP = () => {
    const { config, minMaxLoad, minMaxTime } = useSelector(
        state => state.ruleBasedOptimizationCDWP,
    );

    const dispatch = useDispatch();

    const fetchConfig = useCallback(
        force => {
            dispatch(RuleBasedOptimizationCDWPConfigActions.fetch(force));
        },
        [dispatch],
    );
    const saveConfig = useCallback(
        data => {
            dispatch(RuleBasedOptimizationCDWPConfigActions.save(data));
        },
        [dispatch],
    );

    const fetchMinMaxLoad = useCallback(
        force => {
            dispatch(RuleBasedOptimizationCDWPMinMaxLoadActions.fetch(force));
        },
        [dispatch],
    );
    const deleteMinMaxLoad = useCallback(
        id => {
            dispatch(RuleBasedOptimizationCDWPMinMaxLoadActions.delete(id));
        },
        [dispatch],
    );

    const fetchMinMaxTime = useCallback(
        force => {
            dispatch(RuleBasedOptimizationCDWPMinMaxTimeActions.fetch(force));
        },
        [dispatch],
    );
    const deleteMinMaxTime = useCallback(
        id => {
            dispatch(RuleBasedOptimizationCDWPMinMaxTimeActions.delete(id));
        },
        [dispatch],
    );

    return {
        config,
        fetchConfig,
        saveConfig,
        minMaxLoad,
        fetchMinMaxLoad,
        deleteMinMaxLoad,
        minMaxTime,
        fetchMinMaxTime,
        deleteMinMaxTime
    };
};

export default useRuleBasedOptimizationCDWP;
