import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const SiteOverviewDefault = ({ combined, overview, tall }) => {
    const getHeight = () => {
        if (combined && overview) return "560px";
        if (combined) return "820px";
        if (tall) return "600px";
        return "250px";
    };

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(0.5),
            color: theme.palette.text.secondary,
            height: getHeight()
        },
        spacing: {
            padding: theme.spacing(0.5),
            height: "10px",
            opacity: 0
        },
        grid: {
            padding: "8px 5px"
        }
    }));

    const classes = useStyles();

    return (
        <Grid container className={classes.grid}>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0} />
            </Grid>
        </Grid>
    );
};

export default SiteOverviewDefault;
