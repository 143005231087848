import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// import padStart from "lodash/padStart";
// import padEnd from "lodash/padEnd";
import moment from "moment";

import FormRenderField from "../components/FormRenderField";

import API from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { getResponseErrorDetails, scrollIntoFirstErrorElement } from "../../../utils/helpers";

const CHWPMinMaxLoadSchema = Yup.object().shape({
    TIME_FROM: Yup.string()
        .label("TIME_FROM")
        .required(),
    TIME_TO: Yup.string()
        .label("TIME_TO")
        .required(),
    MIN_DT: Yup.number()
        .label("MIN_DT")
        .required(),
    MAX_DT: Yup.number()
        .label("MAX_DT")
        .required(),
    MIN_FLOW: Yup.number()
        .nullable(true)
        .transform((_, val) => (val ? Number(val) : null))
        .label("MIN_FLOW")
});

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    }
}));
const RuleBasedOptimizationCHWPMinMaxTimeFrom = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, onSuccess } = props;

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({});

    const {
        control,
        handleSubmit,
        reset,
        setError: setFormError,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(CHWPMinMaxLoadSchema),
        values
    });

    const initData = {
        TIME_FROM: moment().startOf("d"),
        TIME_TO: moment().startOf("d"),
        MIN_DT: 0,
        MAX_DT: 0
    };

    // const transformToTimeString = (timeString) => {
    //   let hasEdit = false;
    //   let [start,end] = timeString.split(":");
    //   if (start.length === 1) {
    //     start = padStart(start, 2, '0');
    //     hasEdit = true;
    //   }
    //   if (end.length === 1) {
    //     end = padEnd(end, 2, '0')
    //     hasEdit = true;
    //   }
    //   if (!hasEdit) return null;
    //   return [start,end].join(":");
    // }

    const transformToDateTime = timeString => {
        const [hours, minutes] = timeString.split(":");
        return moment()
            .startOf("d")
            .hours(hours)
            .minutes(minutes);
    };

    useImperativeHandle(ref, () => ({
        init() {
            setValues({ ...initData });
        },
        update(data) {
            const override = {};
            if (data.TIME_FROM) {
                const transform = transformToDateTime(data.TIME_FROM);
                if (transform) override.TIME_FROM = transform;
            }
            if (data.TIME_TO) {
                const transform = transformToDateTime(data.TIME_TO);
                if (transform) override.TIME_TO = transform;
            }
            setValues({ ...initData, ...data, ...override });
        }
    }));

    useEffect(() => {
        if (Object.keys(errors).length) {
            scrollIntoFirstErrorElement({
                elementIds: Object.keys(errors)
            });
        } else {
            setError(null);
        }
    }, [errors]);

    const onClose = () => {
        setValues(initData);
        setError(null);
        setOpen(false);
        reset();
    };

    const handleResponseError = response => {
        const resError = getResponseErrorDetails(response);
        if (resError.type === "invalid") {
            Object.keys(resError.errors).forEach(field => {
                setFormError(field, { type: "invalid", message: resError.errors[field] });
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstErrorElement({ elementIds: Object.keys(resError.errors) });
        } else {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        }
    };

    const onSubmit = async data => {
        try {
            setLoading(true);

            const { id, TIME_FROM, TIME_TO, ...restData } = data;

            const requestConfig = {
                data: {
                    ...restData,
                    TIME_FROM: moment(TIME_FROM).format("HH:mm"),
                    TIME_TO: moment(TIME_TO).format("HH:mm")
                },
                headers: {
                    "x-auth-token": localStorage.token
                }
            };
            if (isEdit) {
                requestConfig.method = "PUT";
                requestConfig.url = `${URL.DATA.RULE_OPTMIZATION.CHWP_MIN_MAX_TIME}/${id}`;
            } else {
                requestConfig.method = "POST";
                requestConfig.url = URL.DATA.RULE_OPTMIZATION.CHWP_MIN_MAX_TIME;
            }
            const response = await API.request(requestConfig);
            if (response.data?.status === "error") {
                handleResponseError(response);
                return;
            }
            if (onSuccess) {
                onSuccess();
            }
            onClose();
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else if (err.response.status === 404) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            } else {
                handleResponseError(err.response);
            }
        } finally {
            setLoading(false);
        }
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    const classes = useStyles();

    const inputs = [
        {
            name: "TIME_FROM",
            label: "TIME FROM",
            placeholder: "Enter TIME FROM",
            type: "time",
            required: true
        },
        {
            name: "TIME_TO",
            label: "TIME TO",
            placeholder: "Enter TIME TO",
            type: "time",
            required: true
        },
        {
            name: "MIN_DT",
            label: "MIN DT",
            placeholder: "Enter MIN DT",
            type: "number",
            required: true
        },
        {
            name: "MAX_DT",
            label: "MAX DT",
            placeholder: "Enter MAX DT",
            type: "number",
            required: true
        },
        {
            name: "MIN_FLOW",
            label: "MIN FLOW",
            placeholder: "Enter MIN FLOW",
            type: "number"
        }
    ];

    return (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="form-optimization-cdwp-min-max-time-dialog"
        >
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} CHWP Min Max Time enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    {inputs.map(input => (
                        <Grid item key={input.name} container alignItems="center">
                            <Grid item xs={3}>
                                <FormLabel required={input.required} error={errors[input.name]}>
                                    {input.label}
                                </FormLabel>
                            </Grid>
                            <Grid item xs={9}>
                                <Controller
                                  name={input.name}
                                  control={control}
                                  render={({ field }) => (
                                        <FormRenderField
                                          input={input}
                                          value={field.value}
                                          onChange={field.onChange}
                                          errors={errors}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    {!!error && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography variant="subtitle1">{error}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                  color="primary"
                  onClick={handleSubmit(onSubmit, onError)}
                  disabled={loading}
                >
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={onClose} disabled={loading}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default RuleBasedOptimizationCHWPMinMaxTimeFrom;
