import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { ICONS } from "../../../utils/enums/index";
import "../styles/cardSavings.scss";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "5px"
    },
    savingFont: {
        fontSize: "1.2rem",
        fontWeight: 500,
        color:
            theme.palette.type === "dark"
                ? theme.palette.primary.contrastText
                : theme.palette.primary.main,
        [theme.breakpoints.up("sm")]: {
            fontSize: "1.5rem"
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "2.5rem"
        }
    },
    titleFont: {
        fontSize: ".8rem",
        [theme.breakpoints.up("sm")]: {
            fontSize: "1rem"
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "1.25rem"
        }
    },
    card: {
        paddingTop: "4px"
    },
    unit: {
        padding: "8px 0 4px 0"
    }
}));

const CardSavings = props => {
    const { image, title, savings, unit, copyRight, percent } = props;
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <Grid container item xm={12} justify="space-between" className={classes.card}>
          <Grid item xm={3}>
            <img src={image} alt="savings" className={CardSavings.styles.image} />
          </Grid>
          <Grid item xm={3}>
            <div>
              {percent ? (
                <span className={CardSavings.styles.percent}>{percent}</span>
                        ) : null}
              <span className={classNames(classes.titleFont, CardSavings.styles.title)}>
                {title}
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid container item xm={4} justify="flex-end">
          <Grid item>
            <Typography variant="h3" className={classes.savingFont}>
              {savings}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xm={12} justify="space-between" className={classes.unit}>
          <Grid item xm={3}>
            <span className={CardSavings.styles.unit}>{copyRight}</span>
          </Grid>
          <Grid item xm={3}>
            <span className={CardSavings.styles.unit}>{unit}</span>
          </Grid>
        </Grid>
      </div>
    );
};

CardSavings.styles = {
    rootContainer: "webclient__cardSavings-container-root",
    box1Container: "webclient__cardSavings-container-box1",
    box2Container: "webclient__cardSavings-container-box2",
    image: "webclient__cardSavings-image",
    savings: "webclient__cardSavings-savings",
    title: "webclient__cardSavings-title",
    unit: "webclient__cardSavings-unit",
    percent: "webclient__cardSavings-percent"
};
CardSavings.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    savings: PropTypes.string,
    unit: PropTypes.string,
    copyRight: PropTypes.string,
    percent: PropTypes.string
};

CardSavings.defaultProps = {
    image: ICONS.WATER_SAVINGS,
    title: "Cost Savings",
    savings: "234567",
    unit: "Dollors",
    copyRight: "Since 2019 Q1",
    percent: "15%"
    // image: ICONS.WATER_SAVINGS,
    // title: false,
    // savings: false,
    // unit: false,
    // copyRight: false,
    // percent: false
};

export default CardSavings;
