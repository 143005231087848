import React, { useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import RuleBasedOptimizationCT from "./RuleBasedOptimizationCT";
import RuleBasedOptimizationCDWP from "./RuleBasedOptimizationCDWP";
import RuleBasedOptimizationCHWP from "./RuleBasedOptimizationCHWP";

import { VERBIAGE } from "../../../utils/enums";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.type === "dark" ? "white" : "black",
        backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff"
    }
}));
const RuleBasedOptimization = () => {
    const tabs = useMemo(
        () => [
            {
                label: VERBIAGE.RULE_BASED_OPTIMIZATION_TAB_TEXT.COOLING_TOWER,
                value: VERBIAGE.RULE_BASED_OPTIMIZATION_TAB_VALUE.COOLING_TOWER
            },
            {
                label: VERBIAGE.RULE_BASED_OPTIMIZATION_TAB_TEXT.CONDENSER_WATER_PUMP,
                value: VERBIAGE.RULE_BASED_OPTIMIZATION_TAB_VALUE.CONDENSER_WATER_PUMP
            },
            {
                label: VERBIAGE.RULE_BASED_OPTIMIZATION_TAB_TEXT.CHILLED_WATER_PUMP,
                value: VERBIAGE.RULE_BASED_OPTIMIZATION_TAB_VALUE.CHILLED_WATER_PUMP
            }
        ],
        [],
    );

    const [tab, setTab] = useState(tabs[0].value);

    const handleChangeTab = (_, selectedTab) => {
        setTab(selectedTab);
    };

    const classes = useStyles();
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item style={{ width: "100%" }}>
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                >
                    {tabs.map(t => (
                        <Tab key={t.value} value={t.value} label={t.label} />
                    ))}
                </Tabs>
                <Paper className={classes.paper} elevation={0}>
                    <RuleBasedOptimizationTabPanel tab={tab} />
                </Paper>
            </Grid>
        </Grid>
    );
};

const RuleBasedOptimizationTabPanel = ({ tab }) => {
    switch (tab) {
        case VERBIAGE.RULE_BASED_OPTIMIZATION_TAB_VALUE.COOLING_TOWER:
            return <RuleBasedOptimizationCT />;
        case VERBIAGE.RULE_BASED_OPTIMIZATION_TAB_VALUE.CONDENSER_WATER_PUMP:
            return <RuleBasedOptimizationCDWP />;
        case VERBIAGE.RULE_BASED_OPTIMIZATION_TAB_VALUE.CHILLED_WATER_PUMP:
            return <RuleBasedOptimizationCHWP />;
        default:
    }
    return null;
};

export default RuleBasedOptimization;
