/* eslint-disable no-shadow */
import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTablePagination from "@material-ui/core/TablePagination";
import {
    withStyles,
    makeStyles,
    useTheme,
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

const defaultFooterStyles = {};

const useStyles = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    }
}));

const TablePaginationActions = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
};

const CustomFooter = props => {
    const { count, textLabels, rowsPerPage, page, changeRowsPerPage, changePage } = props;
    const theme1 = createMuiTheme({
        overrides: {
            MuiTablePagination: {
                toolbar: {
                    color: localStorage.dark === "true" ? "#FFFFFF" : ""
                }
            },
            MuiSelect: {
                icon: {
                    color: localStorage.dark === "true" ? "#FFFFFF" : ""
                }
            },
            MuiSvgIcon: {
                root: {
                    color: localStorage.dark === "true" ? "#FFFFFF" : ""
                }
            }
        }
    });
    const footerStyle = {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 24px 0px 24px",
        backgroundColor: localStorage.dark === "true" ? "#201F25" : "#FFFFFF"
    };

    const handleRowChange = event => {
        changeRowsPerPage(event.target.value);
    };

    const handlePageChange = (_, page) => {
        changePage(page);
    };

    return (
      <MuiThemeProvider theme={theme1}>
        <TableFooter>
          <TableRow>
            <TableCell style={footerStyle} colSpan={1000}>
              <MuiTablePagination
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={textLabels.rowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} ${textLabels.displayRows} ${count}`
                            }
                backIconButtonProps={{
                                "aria-label": textLabels.previous
                            }}
                nextIconButtonProps={{
                                "aria-label": textLabels.next
                            }}
                rowsPerPageOptions={[10, 20, 100]}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowChange}
                ActionsComponent={TablePaginationActions}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </MuiThemeProvider>
    );
};

export default withStyles(defaultFooterStyles, { name: "CustomFooter" })(CustomFooter);
