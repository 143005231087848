import React, { useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AnalyticsActions from "../../redux_Analytics/AnalyticsActions";
import AnomalyDiagnosisActions from "../../redux_AnomalyDiagnosis/AnomalyDiagnosisActions";
import Interval from "../components/Interval";

const useStyles = makeStyles(() => ({
    dropdownStyle: {
        // paddingTop: "2vh",
        // paddingRight: "1vw",
        display: "flex",
        // background: "#FFFFFF",
        height: "4vh",
        justifyContent: "flex-end"
        // [theme.breakpoints.up("sm")]: {
        //     background: "none"
        // },
        // [theme.breakpoints.up("xs")]: {
        //     background: "none"
        // },
        // [theme.breakpoints.up("lg")]: {
        //     background: theme.palette.type === "dark" ? "#201F25" : ""
        // },
        // [theme.breakpoints.up("md")]: {
        //     background: theme.palette.type === "dark" ? "#201F25" : ""
        // }
    }
}));
const AnalyticsTime = props => {
    const interval = localStorage.getItem("interval");
    const intervalTypeLS = localStorage.getItem("intervalType");
    const { updateFaultInterval } = props;
    const classes = useStyles();
    const [number, setNumber] = useState(interval);
    const [intervalType, setIntervalType] = useState(intervalTypeLS);

    // on apply button click
    const handleApply = async () => {
        localStorage.setItem("interval", number);
        localStorage.setItem("intervalType", intervalType);
        await updateFaultInterval({ number, intervalType });
    };
    return (
        <Grid container item className={classes.dropdownStyle}>
            <Interval
              intervalValue={number}
              intervalTypeValue={intervalType}
              intervalTypeChange={event => setIntervalType(event.target.value)}
              intervalChange={event => setNumber(event.target.value)}
              onClick={handleApply}
              button
            />
        </Grid>
    );
};

// const mapStateToProps = state => ({
//     analytics: state.analytics
// });
const mapDispatchToProps = dispatch => ({
    updateFaultInterval: interval => {
        dispatch(AnalyticsActions.updateFaultInterval(interval));
        dispatch(AnomalyDiagnosisActions.updateDiagnosisInterval(interval));
    }
});

export default connect(null, mapDispatchToProps)(withRouter(AnalyticsTime));
