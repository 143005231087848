/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable prefer-rest-params */
/* eslint-disable max-len */
/* eslint-disable func-names */
const v3VizEventTypeListenerMap = {
    parameterValueChange: "parameterchanged",
    parametervaluechange: "parameterchanged"
};

const v2DateRangeType = ["current", "last", "lastn", "next", "nextn", "todate"];
// https://help.tableau.com/current/api/embedding_api/en-us/reference/enums/tableau.daterangetype.html
const v3DateRangeType = ["current", "last", "last-n", "next", "next-n", "to-date"];
// https://help.tableau.com/current/api/embedding_api/en-us/reference/enums/tableau.periodtype.html
const periodTypeMap = {
    year: "years",
    quarter: "quarters",
    month: "months",
    week: "weeks",
    day: "days",
    hour: "hours",
    minute: "minutes",
    second: "seconds"
};

function TableauV3Adapter(viz) {
    const vizProxy = new Proxy(viz, {
        get(target, prop) {
            switch (prop) {
                case "addEventListener":
                case "removeEventListener":
                    return function(eventType, value) {
                        const v3EventName = v3VizEventTypeListenerMap[eventType] || eventType;
                        return target[prop].apply(target, [v3EventName, value]);
                    };
                case "applyRelativeDateFilterAsync":
                    return function(fieldName, relativeDateFilterOptions) {
                        const { periodType, rangeType, ...options } = relativeDateFilterOptions;
                        const mappingOptions = {};
                        if (periodType) {
                            mappingOptions.periodType =
                                periodTypeMap[periodType.toLowerCase()] || periodType;
                        }
                        if (rangeType) {
                            const v2Index = v2DateRangeType.indexOf(rangeType.toLowerCase());
                            mappingOptions.rangeType = v3DateRangeType[v2Index] || rangeType;
                        }
                        return target[prop].apply(target, [
                            fieldName,
                            { ...options, ...mappingOptions }
                        ]);
                    };
                case "changeParameterValueAsync":
                    return async function(name, value) {
                        const loggerKey = `workbook: ${this.name} ${prop}()`;
                        try {
                            const parameters = await this.getParametersAsync();
                            const param = parameters.find(p => p.name === name);
                            if (!param) {
                                console.debug(`${loggerKey} invalid parameter: ${name}`);
                                return;
                            }
                            return target[prop].apply(target, [name, value]);
                        } catch (error) {
                            console.debug(`${loggerKey} error:`, error);
                        }
                    };
                case "getId":
                    return () => Reflect.get(target, "id");
                case "getName":
                    return () => Reflect.get(target, "name");
                case "getActiveSheet":
                    return () => Reflect.get(target, "activeSheet");
                case "getSheetType":
                    return () => Reflect.get(target, "sheetType");
                case "getWorksheets":
                    return () => Reflect.get(target, "worksheets");
                case "getWorkbook":
                    return () => Reflect.get(target, "workbook");
                default:
            }

            const value = Reflect.get(...arguments);
            if (typeof value === "function") {
                return value.bind(target);
            }
            return value;
        }
    });
    return vizProxy;
}

export default TableauV3Adapter;
