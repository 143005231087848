/* eslint-disable no-param-reassign */
import React from "react";
import { FormLabel, FormGroup } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";

const DateRangeFilterDataTable = ({ filterList, onChange, index, column }) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <FormLabel>{column.label}</FormLabel>
            <FormGroup row>
                <DateTimePicker
                  placeholder="From"
                  showTodayButton
                  clearable
                  value={filterList[index][0] ?? null}
                  onChange={momentDate => {
                        if (!momentDate && filterList[index][0]) {
                            filterList[index][0] = null;
                        } else {
                            const fromDate = momentDate.toDate();
                            filterList[index][0] = fromDate;
                        }
                        onChange(filterList[index], index, column);
                    }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <DateTimePicker
                  placeholder="To"
                  showTodayButton
                  clearable
                  value={filterList[index][1] ?? null}
                  onChange={momentDate => {
                        if (!momentDate && filterList[index][1]) {
                            filterList[index][1] = null;
                        } else {
                            const toDate = momentDate.toDate();
                            filterList[index][1] = toDate;
                        }
                        onChange(filterList[index], index, column);
                    }}
                  style={{ width: "45%" }}
                />
            </FormGroup>
        </MuiPickersUtilsProvider>
    );
};

export default DateRangeFilterDataTable;
