import React from "react";

import useQueryParams from "../../../hooks/useQueryParams";

import SiteSelector from "../SiteSelector";
import WaterPumpSelector from "./WaterPumpSelector";
import EditWaterPumpSpecCurve from "./EditWaterPumpSpecCurve";

const WaterPumpSpecCurve = () => {
    const { getQuery } = useQueryParams();
    const wpId = getQuery("wpId");

    return (
        <>
            <SiteSelector />
            <WaterPumpSelector />
            {wpId && <EditWaterPumpSpecCurve wpId={wpId} />}
        </>
    );
};

export default WaterPumpSpecCurve;
