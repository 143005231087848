/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";
import URL from "../../../utils/URL";
import SystemHeader from "../../common/components/SystemHeader";
import VERBIAGE from "../../../utils/enums/Verbiage";
import WaterTreatmentTableauChart from "../../common/charts/WaterTreatmentTableauChart";

const MobileDetect = require("mobile-detect");

const md = new MobileDetect(window.navigator.userAgent);

const WaterTreatmentDashboard1 = props => {
    const { home } = props;
    const useStyles = makeStyles(() => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : "unset"
        },
        grid: {
            // padding: "8px 5px",
            fontSize: "x-large",
            fontWeight: "500",
            marginTop: "6rem"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box"
        }
    }));
    const classes = useStyles();
    const [worksheets, setWorksheets] = useState([]);

    const initialFilter = () => {};

    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    return (
        <Grid className="main-wrapper">
            <Grid>
                <SystemHeader authentication={props.authentication} />
            </Grid>
            <Grid className={classNames(classes.root, classes.main)}>
                <Grid className={classes.grid} item container>
                    <span>{VERBIAGE.MENU_TEXT.WATER_TREATMENT_DASHBOARD1}</span>
                </Grid>
                <Grid item container justify="center">
                    <WaterTreatmentTableauChart
                      url={URL.DATA.REPORT.WATER_TREATMENT_DASHBOARD1}
                      addWorksheet={addWorksheet}
                      report
                      fullWidth
                      initialFilter={initialFilter}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
const mapStateToProps = state => ({
    home: state.home
});

export default connect(mapStateToProps)(WaterTreatmentDashboard1);
