import React, { useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";

import RuleBasedOptimizationCHWPMinMaxLoad from "./RuleBasedOptimizationCHWPMinMaxLoad";
import RuleBasedOptimizationCHWPMinMaxTime from "./RuleBasedOptimizationCHWPMinMaxTime";
import RuleBasedOptimizationCHWPConfig from "./RuleBasedOptimizationCHWPConfig";
import { VERBIAGE } from "../../../utils/enums";

const useStyles = makeStyles(theme => ({
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`
    }
}));
const RuleBasedOptimizationCHWP = () => {
    const tabs = useMemo(
        () => [
            {
                label: VERBIAGE.RULE_BASED_OPTIMIZATION_CHWP_TAB_TEXT.CONFIG,
                value: VERBIAGE.RULE_BASED_OPTIMIZATION_CHWP_TAB_VALUE.CONFIG
            },
            {
                label: VERBIAGE.RULE_BASED_OPTIMIZATION_CHWP_TAB_TEXT.MIN_MAX_LOAD,
                value: VERBIAGE.RULE_BASED_OPTIMIZATION_CHWP_TAB_VALUE.MIN_MAX_LOAD
            },
            {
                label: VERBIAGE.RULE_BASED_OPTIMIZATION_CHWP_TAB_TEXT.MIN_MAX_TIME,
                value: VERBIAGE.RULE_BASED_OPTIMIZATION_CHWP_TAB_VALUE.MIN_MAX_TIME
            }
        ],
        [],
    );

    const [tab, setTab] = useState(tabs[0].value);

    const handleChangeTab = (_, selectedTab) => {
        setTab(selectedTab);
    };

    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Tabs
                  orientation="vertical"
                  value={tab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="Rule based optimization CHWP vertical tabs"
                  className={classes.tabs}
                >
                    {tabs.map(t => (
                        <Tab key={t.value} value={t.value} label={t.label} />
                    ))}
                </Tabs>
            </Grid>
            <Grid item xs={10}>
                <RuleBasedOptimizationCHWPTabPanel tab={tab} />
            </Grid>
        </Grid>
    );
};

const RuleBasedOptimizationCHWPTabPanel = ({ tab }) => {
    switch (tab) {
        case VERBIAGE.RULE_BASED_OPTIMIZATION_CHWP_TAB_VALUE.MIN_MAX_LOAD:
            return <RuleBasedOptimizationCHWPMinMaxLoad />;
        case VERBIAGE.RULE_BASED_OPTIMIZATION_CHWP_TAB_VALUE.MIN_MAX_TIME:
            return <RuleBasedOptimizationCHWPMinMaxTime />;
        case VERBIAGE.RULE_BASED_OPTIMIZATION_CHWP_TAB_VALUE.CONFIG:
            return <RuleBasedOptimizationCHWPConfig />;
        default:
    }
    return null;
};

export default RuleBasedOptimizationCHWP;
