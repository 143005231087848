/* eslint-disable max-len */
/* eslint-disable array-callback-return  */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CardData from "./CardData";
import PortfolioActions from "../../redux/PortfolioActions";
import { IMAGE, VERBIAGE } from "../../../utils/enums/index";
import REFRESH_TIMER from "../../../utils/enums/Enum";
import Dropdown from "../components/Dropdown";

const useStyles = makeStyles(theme => ({
    grid: {
        padding: "5px 5px"
    },
    saving: {
        width: "100%",
        // height: "100%",
        // marginTop: "2vh",
        display: "flex"
    },
    paper: {
        padding: theme.spacing(0),
        width: "100%",
        // height: "100%",
        backgroundColor: theme.palette.type === "dark" ? "#201F25" : "white",
        color: theme === "dark" ? "white" : "black",
        alignItems: "center"
    },
    time: {
        marginTop: "1vh",
        fontSize: "12px"
    },
    field: {
        padding: "5px"
    },
    dropdown: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}`, // "1px solid #3865A7",
        borderRadius: "4px",
        height: "23px",
        width: "150px"
    },
    horizontal: {
        width: "100%",
        borderBottom: theme.palette.type === "dark" ? "1px solid #444444" : "1px solid #EAEAEA"
    },
    button: {
        textTransform: "none",
        height: "25px",
        width: "63px",
        background: theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
    },
    intervalButton: {
        border: "none",
        borderRadius: "4px",
        color: "#FFFFFF",
        height: "23px",
        fontSize: "10px",
        outline: "none",
        cursor: "pointer"
    },
    label: {
        color: theme.palette.type === "dark" ? "#ACACAC" : "#707070"
    }
}));

const KpiSystem = props => {
    const { fetchPortfolioSavings, portfolio, updatePortfolioInterval } = props;
    const classes = useStyles();
    const [date, setDate] = useState("Last 2 Weeks");
    const [number, setNumber] = useState("2");
    const [intervalType, setIntervalType] = useState("Weeks");

    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        fetchPortfolioSavings();
        const timer = setInterval(() => {
            fetchPortfolioSavings();
        }, timerValue);
        return () => clearInterval(timer);
    }, [fetchPortfolioSavings, REFRESH_TIMER()]);

    const handleIcon = img => {
        const entries = Object.entries(IMAGE);
        let imgURL;
        entries.map(([prop, val]) => {
            if (prop === img) {
                imgURL = val;
            }
        });
        return imgURL;
    };

    const handleApply = async () => {
        await updatePortfolioInterval({ number, intervalType });
    };
    const handleOnChange = event => {
        const val = event.target.value;
        const intervalArr = val.split(" ");
        setDate(val);
        setNumber(intervalArr[1]);
        setIntervalType(intervalArr[2]);
    };
    return (
        <div>
            <Grid
              xs={12}
              container
              item
              justify="center"
              className={KpiSystem.styles.rootContainer}
            >
                <div className={classNames(classes.time, classes.label)}>Date/Time</div>
                <div>
                    <Grid item className={classes.field}>
                        <Dropdown
                          className={classes.dropdown}
                          options={VERBIAGE.SYSTEM_OVERVIEW.TIME}
                          value={date}
                          onChange={handleOnChange}
                        />
                    </Grid>
                </div>

                <div>
                    <Grid item className={classes.field}>
                        <div>
                            <button
                              type="submit"
                              className={classNames(classes.intervalButton, classes.button)}
                              onClick={handleApply}
                            >
                                {VERBIAGE.BUTTONS.APPLY}
                            </button>
                        </div>
                    </Grid>
                </div>
            </Grid>
            <Grid>
                <div className={classes.horizontal} />
            </Grid>
            <Grid container item xs={12} elevation={0}>
                {portfolio &&
                    portfolio.savings &&
                    portfolio.savings.savingsByTime.map(savings => (
                        <Grid
                          container
                          item
                          xs={6}
                          className={classes.grid}
                          elevation={0}
                          key={`${savings.icon}_savings`}
                        >
                            <Paper
                              className={classNames(classes.saving, classes.paper)}
                              elevation={0}
                            >
                                <CardData
                                  image={handleIcon(savings.icon)}
                                  title={savings.label}
                                  savings={savings.value}
                                  unit={savings.unit}
                                  styleValue={KpiSystem.styles.image}
                                  id="KpiSystem"
                                />
                            </Paper>
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
};
KpiSystem.styles = {
    rootContainer: "webclient__portfolioRightView-container-root",
    image: "webclient__portfolioRightView-imageKpiSystem"
};
const mapStateToProps = state => ({
    portfolio: state.portfolio
});
const mapDispatchToProps = dispatch => ({
    fetchPortfolioSavings: () => {
        dispatch(PortfolioActions.fetchPortfolioSavings());
    },
    updatePortfolioInterval: interval => {
        dispatch(PortfolioActions.updatePortfolioInterval(interval));
    }
});
KpiSystem.propTypes = {
    fetchPortfolioSavings: PropTypes.func.isRequired,
    updatePortfolioInterval: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(KpiSystem));
