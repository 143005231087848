const MLActionTypes = {
    SET_ML_CONFIG: "SET_ML_CONFIG",
    SET_MODBUS_ENABLE_ML: "SET_MODBUS_ENABLE_ML",
    SET_ML_MODBUS_HEARTBEAT: "SET_ML_MODBUS_HEARTBEAT",
    SET_ML_PLC_HEARTBEAT: "SET_ML_PLC_HEARTBEAT",
    SET_ML_SETPOINT: "SET_ML_SETPOINT",
    SET_ML_SETPOINT_SP: "SET_ML_SETPOINT_SP",
    SET_ML_SETPOINT_SP_ERROR: "SET_ML_SETPOINT_SP_ERROR",
    SET_ML_TRAIN: "SET_ML_TRAIN",
    SET_ML_PREDICT: "SET_ML_PREDICT"
};

export default MLActionTypes;
