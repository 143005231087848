import React, { useState, useCallback, useEffect, useRef } from "react";

import API from "../../../utils/API";
import useQueryParams from "../../../hooks/useQueryParams";

import DWHDataTable from "../DwhDataTable";
import EditPidSpec from "./EditPidSpec";

const PidSpec = ({ title, baseUrl }) => {
    const { getQuery } = useQueryParams();
    const pidId = getQuery("pidId");

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const editRef = useRef();

    const fetchData = useCallback(
        async id => {
            try {
                setLoading(true);

                const url = `${baseUrl}/${id}/pid`;
                const response = await API.get(url, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data) {
                    setData(response.data);
                }
            } catch (err) {
                if (err.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            } finally {
                setLoading(false);
            }
        },
        [baseUrl],
    );

    useEffect(() => {
        if (pidId) {
            fetchData(pidId);
        }
    }, [pidId, fetchData]);

    const handleClickDataTable = (e, { action, params }) => {
        switch (action) {
            case "add":
                editRef.current.init();
                setIsEdit(false);
                setOpen(true);
                break;
            case "edit":
                editRef.current.update({
                    ...data[params.dataIndex]
                });
                setIsEdit(true);
                setOpen(true);
                break;
            default:
        }
    };

    const handleSaveSuccess = id => {
        fetchData(id);
    };

    const columns = [
        { actions: ["edit"] },
        { label: "FROM", name: "from" },
        { label: "TO", name: "to" },
        { label: "SP", name: "sp" },
        { label: "Created Date", name: "createdAt", _options: { type: "date" }, narrow: true },
        { label: "Updated Date", name: "updatedAt", _options: { type: "date" }, narrow: true }
    ];

    return (
        <>
            <DWHDataTable
              title="PID Specification"
              loading={loading}
              data={data}
              columns={columns}
              onClick={handleClickDataTable}
            />
            <EditPidSpec
              title={title}
              baseUrl={baseUrl}
              ref={editRef}
              isEdit={isEdit}
              open={open}
              setOpen={setOpen}
              onSuccess={handleSaveSuccess}
            />
        </>
    );
};

export default PidSpec;
