const APPLICATION = {
    NAME: "WEBCLIENT",
    COMPANY_LOGO_URL: "",
    LOGO_URL: "",
    COPYRIGHT: "Copyright ©2019. 91Social. All rights reserved.",
    BASE_URL: ""
};

export default APPLICATION;

// COMPANY_LOGO_URL:
//     "https://firebasestorage.googleapis.com/v0/b/" +
//     "ctd-ui.appspot.com/o/91Logo.svg?" +
//     "alt=media&token=8b30a063-347e-4ffa-94e3-12d5c87b18f8",
