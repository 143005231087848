/* eslint-disable react/destructuring-assignment  */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { VERBIAGE } from "../../../utils/enums/index";
import "../styles/portfolioView.scss";
import PortfolioRightView from "../../common/portfolio/PortfolioRightView";
import SystemHeader from "../../common/components/SystemHeader";
import PortfolioMap from "../../common/charts/PortfolioMap";

const MobileDetect = require("mobile-detect");

const PortfolioView = props => {
    const { home } = props;
    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        paper: {
            padding: theme.spacing(0),
            textAlign: "center",
            // height: "87vh",
            height:
                home.desktopMobileSwitch === "desktop"
                    ? md.mobile()
                        ? "87vh"
                        : "87vh"
                    : home.orientation === "portrait"
                    ? "77vh"
                    : "87vh",

            // [theme.breakpoints.up("xs")]: {
            //     height: "77vh"
            // },
            // [theme.breakpoints.up("sm")]: {
            //     height: "85vh"
            // },
            // [theme.breakpoints.up("md")]: {
            //     height: "85%"
            // },
            // [theme.breakpoints.up("lg")]: {
            //     height: "85%"
            // },
            // [theme.breakpoints.up("xl")]: {
            //     height: "85%"
            // },
            color: theme.palette.type === "dark" ? "white" : "black",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff"
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box",
            paddingTop: "70px"
        }
    }));
    const classes = useStyles();
    const [siteName, setSiteName] = useState("");

    const handelSiteName = name => {
        localStorage.setItem("siteName", name);
        setSiteName(name);
    };
    useEffect(() => {
        const siteName1 = localStorage.getItem("siteName");
        setSiteName(siteName1);
    }, []);
    return (
      <div className="main-wrapper">
        <div>
          <SystemHeader authentication={props.authentication} handleSN={handelSiteName} />
        </div>
        <div className={classes.main}>
          <Grid container item xs={12} className={classes.root}>
            {/* header start */}
            <Grid container item xs={12}>
              <div
                className={classNames(
                                PortfolioView.styles.headerContainer,
                                classes.title,
                            )}
              >
                <span>{VERBIAGE.MENU_TEXT.PORTFOLIO_VIEW}</span>
              </div>
            </Grid>
            {/* header end */}
            <Grid container spacing={2}>
              {/* Leftpanel map start */}
              <Grid item xs={12} sm={12} md={8} lg={9}>
                <Paper className={classes.paper} elevation={0}>
                  <PortfolioMap handelSiteName={handelSiteName} />
                </Paper>
              </Grid>

              {/* leftpanel map end */}

              {/* Right panel start */}
              <Grid item xs={12} sm={12} md={4} lg={3} zeroMinWidth elevation={0}>
                {/* Lastestdata  */}
                <PortfolioRightView siteName={siteName} />
              </Grid>
              {/* Right panel end  */}
            </Grid>
          </Grid>
        </div>
      </div>
    );
};
PortfolioView.styles = {
    headerContainer: "webclient__portfolioView-container-header"
};
const mapStateToProps = state => ({
    home: state.home
});
export default connect(mapStateToProps)(PortfolioView);
