import React from "react";
import { Grid } from "@material-ui/core";
// import AnomalyDetectionCharts from "../components/AnomalyDetectionCharts";
import AnalyticsView from "../../features/home/AnalyticsView";
import AnalyticsOverview from "./AnalyticsOverview";
import AnomalyDiagnosis from "../../features/home/AnomalyDiagnosisView";
import EnergyCycleChart from "../components/EnergyCycleChart";
import EnergyOptimizationPage from "../components/EnergyOptimizationPage";
import AlarmConfigTable from "../components/AlarmConfigTable";
import FaultsAndAlarmsPage from "../components/FaultsAndAlarmsPage";

const AnalyticsTabs = props => {
    const {
        value,
        addWorksheet,
        worksheets,
        changeTab,
        // lifecycleChart,
        setTabDates,
        setTabMax,
        setTabMin,
        dates,
        min,
        max,
        classes,
        tabStyle,
        dateSlider,
        handleSliderChange,
        handleEndDateInput,
        handleStartDateInput,
        onSliderChangeCommitted,
        initialFilter
    } = props;
    // const isAnalyticsDeveloped = localStorage.getItem("isAnalyticsDeveloped");

    return (
        <Grid item xs={12}>
            {!(value !== "analyticsOverview") && (
                <div hidden={value !== "analyticsOverview"}>
                    <AnalyticsOverview changeTab={changeTab} />
                </div>
            )}
            {/* <div hidden={value !== "anomalyDetection"}>
                <AnomalyDetectionCharts
                    addWorksheet={addWorksheet}
                    setTabDates={setTabDates}
                    setTabMax={setTabMax}
                    setTabMin={setTabMin}
                />
            </div> */}
            {!(value !== "anomalyDetection") && (
                <div hidden={value !== "anomalyDetection"}>
                    <AnalyticsView
                      setTabDates={setTabDates}
                      setTabMax={setTabMax}
                      setTabMin={setTabMin}
                      addWorksheet={addWorksheet}
                      worksheets={worksheets}
                      tab={value}
                      dateSlider={dateSlider}
                      dates={dates}
                      min={min}
                      max={max}
                      handleSliderChange={handleSliderChange}
                      onSliderChangeCommitted={onSliderChangeCommitted}
                      handleStartDateInput={handleStartDateInput}
                      handleEndDateInput={handleEndDateInput}
                      initialFilter={initialFilter}
                    />
                </div>
            )}
            {!(value !== "faults_and_alarms") && (
                <div hidden={value !== "faults_and_alarms"}>
                    <FaultsAndAlarmsPage
                      classes={classes}
                      tabStyle={tabStyle}
                      setTabDates={setTabDates}
                      setTabMax={setTabMax}
                      setTabMin={setTabMin}
                      addWorksheet={addWorksheet}
                      worksheets={worksheets}
                      tab={value}
                      dateSlider={dateSlider}
                      dates={dates}
                      min={min}
                      max={max}
                      handleSliderChange={handleSliderChange}
                      onSliderChangeCommitted={onSliderChangeCommitted}
                      handleStartDateInput={handleStartDateInput}
                      handleEndDateInput={handleEndDateInput}
                      initialFilter={initialFilter}
                    />
                </div>
            )}
            {!(value !== "anomalyDiagnosis") && (
                <div hidden={value !== "anomalyDiagnosis"}>
                    <AnomalyDiagnosis
                      setTabDates={setTabDates}
                      setTabMax={setTabMax}
                      setTabMin={setTabMin}
                      addWorksheet={addWorksheet}
                      worksheets={worksheets}
                      tab={value}
                      dateSlider={dateSlider}
                      dates={dates}
                      min={min}
                      max={max}
                      handleSliderChange={handleSliderChange}
                      onSliderChangeCommitted={onSliderChangeCommitted}
                      handleStartDateInput={handleStartDateInput}
                      handleEndDateInput={handleEndDateInput}
                      initialFilter={initialFilter}
                    />
                </div>
            )}
            {!(value !== "equipmentLifecycle") && (
                <div hidden={value !== "equipmentLifecycle"}>
                    <EnergyCycleChart
                        // lifecycleChart={lifecycleChart}
                      classes={classes}
                      tabStyle={tabStyle}
                    />
                </div>
            )}
            {!(value !== "config") && (
                <div hidden={value !== "config"}>
                    <AlarmConfigTable />
                </div>
            )}
            {!(value !== "energyOptimization") && (
                <div hidden={value !== "energyOptimization"}>
                    <EnergyOptimizationPage
                        // lifecycleChart={lifecycleChart}
                      classes={classes}
                      tabStyle={tabStyle}
                    />
                </div>
            )}
        </Grid>
    );
};
export default AnalyticsTabs;
