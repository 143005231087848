import React, { useState, useRef, useEffect, useCallback } from "react";

import API from "../../../utils/API";
import URL from "../../../utils/URL";
import useQueryParams from "../../../hooks/useQueryParams";

import SiteSelector from "../SiteSelector";
import DwhDataTable from "../DwhDataTable";
import EditSitePid from "./EditSitePid";

const SitePid = () => {
    const { getQuery } = useQueryParams();
    const siteNumber = getQuery("siteNumber");

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [loading, setLoading] = useState(false);

    const editRef = useRef();

    const fetchData = useCallback(async siteId => {
        try {
            setLoading(true);

            const url = URL.DATA.DWH.SITE_PID;
            const response = await API.get(url, {
                params: {
                    siteId,
                    _sort: "id",
                    _order: "ASC"
                },
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            if (response.data) {
                setData(response.data);
            }
        } catch (err) {
            if (err.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (siteNumber) {
            fetchData(siteNumber);
        }
    }, [siteNumber, fetchData]);

    const handleClickDataTable = (e, { action, params }) => {
        switch (action) {
            case "add":
                editRef.current.init();
                setIsEdit(false);
                setOpen(true);
                break;
            case "edit":
                editRef.current.update({
                    ...data[params.dataIndex]
                });
                setIsEdit(true);
                setOpen(true);
                break;
            default:
        }
    };

    const handleSaveSuccess = siteId => {
        fetchData(siteId);
    };

    const columns = [
        { actions: ["edit"] },
        { label: "PID ID", name: "pidId" },
        { label: "SP Selection", name: "spSelection" },
        { label: "Created Date", name: "createdAt", _options: { type: "date" }, narrow: true },
        { label: "Updated Date", name: "updatedAt", _options: { type: "date" }, narrow: true }
    ];

    return (
        <>
            <SiteSelector />
            {siteNumber && (
                <DwhDataTable
                  name="Site PID"
                  loading={loading}
                  data={data}
                  columns={columns}
                  onClick={handleClickDataTable}
                />
            )}
            <EditSitePid
              ref={editRef}
              isEdit={isEdit}
              open={open}
              setOpen={setOpen}
              onSuccess={handleSaveSuccess}
            />
        </>
    );
};

export default SitePid;
