/* eslint-disable no-lonely-if */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme, MuiThemeProvider, createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Map, CircleMarker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import classNames from "classnames";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

import ICONS from "../../../utils/enums/IconData";
import PortfolioActions from "../../redux/PortfolioActions";
import "../styles/mapComponent.scss";
import "./map.css";
import REFRESH_TIMER from "../../../utils/enums/Enum";
import VERBIAGE from "../../../utils/enums/Verbiage";

const MobileDetect = require("mobile-detect");

const md = new MobileDetect(window.navigator.userAgent);

let newFilterArray = ["All"]; // Array.from(newFilter);
let newSiteId;
let newSiteName;

const useStyles = makeStyles(theme => ({
    filter: {
        filter: theme.palette.type === "dark" ? "brightness(0) invert(0.5)" : ""
    }
}));

// let imageWidth = "128px";
// let maxWidth =
const PortfolioMap = props => {
    const { home } = props;
    const { fetchPortfolioChangeSite, fetchPortfolioMap, portfolio, handelSiteName } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [position, setPosition] = useState([0, 0]); // latitude, longitude
    const [zoom, setZoom] = useState(10);
    const [mapId, setMapId] = useState("");
    const [value, setValue] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filter, setFilter] = useState("All");
    const [activePopup, setActivePopup] = useState(false);
    const [newFilter, setNewFilter] = useState(new Set());
    const open = Boolean(anchorEl);
    const efficiencyStyle = {
        color: theme.palette.type === "dark" ? "#ACACAC" : "#707070"
    };

    const getCookie = name => {
        // Split cookie string and get all individual name=value pairs in an array
        const cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name === cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }

        // Return null if not found
        return null;
    };

    const theme1 = createMuiTheme({
        overrides: {
            MuiMenuItem: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    height: "2.6vh"
                    // top:"20px"
                }
            },
            MuiMenu: {
                list: {
                    background: theme.palette.type === "dark" ? "#201F25" : "FFFFFF",
                    color: theme.palette.type === "dark" ? "#ACACAC" : "#707070"
                }
            }
        }
    });
    const menuStyle = {
        left: "-140px",
        top: "35px",
        maxHeight: "300px"
    };
    // to open popup at starting
    const initMarker = ref => {
        if (ref) {
            ref.leafletElement.openPopup();
        }
    };

    // on filter menu item click
    const handleCheckChange = event => {
        const name = event.target.name;
        const checkValue = event.target.checked;
        if (name === "All" && checkValue === false) {
            newFilter.clear();
            setFilter(Math.random());
            newFilterArray = Array.from(newFilter);
            return "";
        }
        if (name === "All" && checkValue === true) {
            newFilter.clear();
            newFilter.add("All");
            setFilter(name);
            newFilterArray = Array.from(newFilter);
            return "";
        }
        if (name !== "All") {
            newFilter.delete("All");
        }
        if (newFilter.has(name)) {
            newFilter.delete(name);
        } else {
            newFilter.add(name);
        }

        if (checkValue) {
            setFilter(name);
        } else {
            setFilter(Math.random());
        }
        setActivePopup(false);
        document.cookie = `siteId=0000`;
        newFilterArray = Array.from(newFilter);
    };

    // build filter option
    const buildFilterOptions = () => {
        if (!portfolio.mapData) {
            return [];
        }
        return portfolio.mapData.locations.map((data, index) => {
            let checked = false;
            if (newFilter.has("All")) {
                checked = true;
            } else if (newFilter.has(data.siteName)) {
                checked = true;
            }
            return [
                <FormControlLabel
                  key={index}
                  control={(
                        <Checkbox
                          key={index}
                          color="primary"
                          checked={checked}
                          onChange={e => {
                                handleCheckChange(e);
                            }}
                          name={`${data.siteName}`}
                        />
                      )}
                  label={data.siteName}
                />
            ];
        });
    };

    // on filter icon click
    const handleFilterIconClick = event => {
        setAnchorEl(event.currentTarget);
    };

    // on filterMenu Close
    const handleFilterMenuClose = event => {
        callRightSideAPI(newSiteId, newSiteName);
        setAnchorEl(null);
    };

    // on tab change
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // Build Tabs
    const buildTab = () => {
        if (!portfolio.mapData) {
            return [];
        }

        if (portfolio.sites.length > 0) {
            const activeSite = localStorage.getItem("activeSite");
            portfolio.mapData.locations.map(data => {
                // console.log(`${mapId} === "" && ${activeSite} === ${data.siteId}`)
                if (mapId === "" && activeSite === data.siteId) {
                    // setMapId(data.mapId)
                    setValue(data.mapId);
                    portfolio.mapData.maps.map(_map => {
                        if (data.mapId === _map.mapId) {
                            setPosition([_map.latitude, _map.longitude]);
                            setZoom(_map.zoom);
                            setMapId(_map.mapId);
                        }
                        return _map;
                    });
                }
                return data;
            });
        }

        return portfolio.mapData.maps.map(data => {
            return data;
        });
    };

    // Build PopUp
    const buildPopup = () => {
        const newData = {
            id: ""
        };
        if (!portfolio.mapData) {
            return [];
        }
        if (filter === "none") {
            return [];
        }
        if (filter === "All") {
            return portfolio.mapData.locations.map(data => {
                if (data.mapId === mapId) {
                    return data;
                }
                return newData;
            });
        }
        // const newFilterArray = Array.from(newFilter);
        const newFilterData = [];
        const data = portfolio.mapData.locations;
        for (let i = 0; i < newFilterArray.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (data[j].mapId === mapId) {
                    if (data[j].siteName === newFilterArray[i]) {
                        newFilterData.push(data[j]);
                    }
                }
            }
        }
        return newFilterData;
    };

    const buildMap = () => {
        try {
            // if (position[0] === 0) {
            //     setPosition([
            //         portfolio.mapData.maps[0].latitude,
            //         portfolio.mapData.maps[0].longitude
            //     ]);
            //     setZoom(portfolio.mapData.maps[0].zoom);
            //     setMapId(portfolio.mapData.maps[0].mapId);
            //     // console.log('setMapId again in build Map ', portfolio.mapData.maps[0].mapId)
            // }
        } catch (e) {
            // console.log(e);
        }
    };

    // On Map Change
    const handleMapChange = (newPosition, newZoom, id) => {
        setMapId(id);
        setPosition(newPosition);
        setZoom(newZoom);
    };

    // call right side API
    const callRightSideAPI = async (siteId, siteName) => {
        setLogoLocal(siteId);
        handelSiteName(siteName);
        const requestBody = {
            siteId: { siteId } // "10027",
        };
        await fetchPortfolioChangeSite({ requestBody });
    };

    // on popup click
    const handleToolTip = (siteId, siteName) => {
        callRightSideAPI(siteId, siteName);
        setActivePopup(siteId);
    };

    const setLogoLocal = siteId => {
        // document.cookie = siteId;
        document.cookie = `siteId=${siteId}`;
        portfolio.mapData.locations.map(mapData => {
            if (mapData.siteId === siteId) {
                localStorage.setItem("imageOpenDark", mapData.nightModeLogo.mainLogo);
                localStorage.setItem("imageOpenLight", mapData.dayModeLogo.mainLogo);
                localStorage.setItem("imageCloseDark", mapData.nightModeLogo.circleLogo);
                localStorage.setItem("imageCloseLight", mapData.dayModeLogo.circleLogo);
            }
            return "";
        });
    };

    useEffect(() => {
        if (newFilter.size === 0) {
            newFilter.add("All");
        }
        const timerValue = REFRESH_TIMER();
        fetchPortfolioMap();
        const timer = setInterval(() => {
            fetchPortfolioMap();
        }, timerValue);
        return () => clearInterval(timer);
    }, [fetchPortfolioMap, REFRESH_TIMER()]);

    const mapStyle = {
        height: home.desktopMobileSwitch === "desktop" ? (md.mobile() ? "72vh" : "78vh") : "69vh"
        // height: "78vh"
        // height: !small ? "595px" : !medium ? "930px" : !large ? "590px" : "600px"
    };
    const mapContainerStyle = {
        padding: "10px",
        height: "78vh"
        // height: !small ? "595px" : !medium ? "930px" : !large ? "590px" : "600px"
    };

    return (
        <div>
            <div
              height="10%"
              style={{
                    paddingLeft: "10px",
                    // paddingRight: "10px",
                    // paddingTop: "10px",
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <div>
                    <Tabs
                      value={value}
                      onChange={handleTabChange}
                      indicatorColor="secondary"
                      textColor="secondary"
                    >
                        {buildTab().map((mapData, index) => {
                            return (
                                <Tab
                                  key={index}
                                  style={{
                                        // color: "#3865A7",
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        textTransform: "capitalize",
                                        minWidth: "100px"
                                    }}
                                  onClick={() =>
                                        handleMapChange(
                                            [mapData.latitude, mapData.longitude],
                                            mapData.zoom,
                                            mapData.mapId,
                                        )
                                    }
                                  label={mapData.mapName}
                                  value={mapData.mapId}
                                />
                            );
                        })}
                    </Tabs>
                </div>
                <div style={{ paddingTop: "6px" }}>
                    {/* <label style={efficiencyStyle}>
              Show {newFilterArray.length === 0 ? "None" : newFilterArray} only
            </label> */}
                    <IconButton onClick={handleFilterIconClick}>
                        <img className={classes.filter} src={ICONS.FILTER} alt="filter" />
                    </IconButton>
                    <MuiThemeProvider theme={theme1}>
                        <Menu
                          id="long-menu"
                          style={menuStyle}
                          anchorEl={anchorEl}
                          keepMounted
                          open={open}
                          onClose={e => {
                                handleFilterMenuClose(e);
                            }}
                        >
                            <MenuItem>
                                <FormControlLabel
                                  control={(
                                        <Checkbox
                                          color="primary"
                                          checked={newFilter.has("All") ? true : false}
                                          onChange={e => {
                                                handleCheckChange(e, 1);
                                            }}
                                          name="All"
                                        />
                                      )}
                                  label="Select/Deselect All"
                                />
                            </MenuItem>
                            {buildFilterOptions().map((option, index) => (
                                <MenuItem key={index}>{option}</MenuItem>
                            ))}
                        </Menu>
                    </MuiThemeProvider>
                </div>
            </div>
            <div style={mapContainerStyle}>
                {/* {buildMap()} */}
                <Map
                  id="map"
                  style={mapStyle}
                  center={position}
                  zoom={zoom}
                  zoomControl={false}
                  load={buildMap()}
                >
                    <ZoomControl position="bottomright" />
                    <TileLayer
                      key={theme.palette.type}
                      className={
                            theme.palette.type === "dark"
                                ? PortfolioMap.styles.mapDark
                                : PortfolioMap.styles.mapLight
                        }
                      attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.png"
                    />
                    {buildPopup().map((mapData, index) => {
                        if (mapData.id === "") {
                            return "";
                        }
                        const siteIdCookie = getCookie("siteId");
                        let displaylabel = "none";
                        let imageWidth = "75px";
                        const newPosition = [mapData.location.latitude, mapData.location.longitude];
                        const siteName = mapData.siteName;
                        const markerColour = mapData.info
                            ? mapData.info[0].color
                            : VERBIAGE.COLOUR_YELLOW;
                        const siteId = mapData.location.siteId;
                        const bigIcon = mapData.bigLogo;
                        const infoName = mapData.info ? mapData.info[0].name : "System Efficiency";
                        const infoValue = mapData.info ? mapData.info[0].value : "0";
                        if (filter === "All") {
                            if (siteIdCookie === siteId) {
                                displaylabel = "block";
                                imageWidth = "128px";
                            } else {
                                displaylabel = "none";
                                imageWidth = "75px";
                            }
                        } else {
                            if (displaylabel === "none") {
                                if (index === 0 && activePopup === false) {
                                    displaylabel = "block";
                                    imageWidth = "128px";
                                } else if (siteIdCookie === siteId) {
                                    displaylabel = "block";
                                    imageWidth = "128px";
                                }
                            }
                        }

                        if (displaylabel === "block") {
                            newSiteId = siteId;
                            newSiteName = siteName;
                            imageWidth = "128px";
                        }

                        return (
                            <CircleMarker
                              key={index}
                              center={newPosition}
                              radius="4"
                              ref={initMarker}
                              color={markerColour}
                              fillOpacity="1"
                            >
                                <Popup
                                  key={theme.palette.type}
                                    // minWidth={minWidth}
                                    // maxWidth="300px"
                                    // minWidth="150"
                                    // minHeight="30"
                                  className={classNames(
                                        theme.palette.type === "dark"
                                            ? PortfolioMap.styles.popupDark
                                            : PortfolioMap.styles.popupLight,
                                        displaylabel === "none"
                                            ? PortfolioMap.styles.popupMarginClose
                                            : PortfolioMap.styles.popupMarginOpen,
                                    )}
                                  position={newPosition}
                                  closeButton={false}
                                  closeOnClick={false}
                                  autoClose={false}
                                >
                                    <div
                                      onClick={() => {
                                            handleToolTip(siteId, siteName);
                                        }}
                                      onKeyDown={() => {
                                            handleToolTip(siteId, siteName);
                                        }}
                                    >
                                        <img
                                            // height={height}
                                          style={{
                                                // paddingLeft: "10px",
                                                background:
                                                    theme.palette.type === "dark" ? "#FFFFFF" : "",
                                                width: imageWidth
                                            }}
                                          alt=""
                                          src={bigIcon}
                                        />
                                        <div
                                          style={{
                                                color:
                                                    theme.palette.type === "dark"
                                                        ? "#ACACAC"
                                                        : "#707070"
                                            }}
                                          className={classes.status}
                                        >
                                            <Typography variant="subtitle1" align="center">
                                                {siteName}
                                            </Typography>
                                        </div>
                                        <div
                                          style={{ display: displaylabel }}
                                          className={PortfolioMap.styles.label}
                                        >
                                            <label style={efficiencyStyle}>{infoName}: </label>
                                            <label
                                              style={{
                                                    color:
                                                        markerColour === VERBIAGE.COLOUR_YELLOW
                                                            ? VERBIAGE.COLOUR_NEW_YELLOW
                                                            : markerColour
                                                }}
                                            >
                                                {infoValue}
                                            </label>
                                        </div>
                                    </div>
                                </Popup>
                            </CircleMarker>
                        );
                    })}
                </Map>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    home: state.home,
    portfolio: state.portfolio
});
const mapDispatchToProps = dispatch => ({
    fetchPortfolioMap: () => {
        dispatch(PortfolioActions.fetchPortfolioMap());
    },
    fetchPortfolioChangeSite: credentials => {
        dispatch(PortfolioActions.fetchPortfolioChangeSite(credentials));
    }
});

PortfolioMap.styles = {
    mapLight: "webclient__mapComponent-mapLight",
    mapDark: "webclient__mapComponent-mapDark",
    popupLight: "webclient__mapComponent-popupLight",
    popupDark: "webclient__mapComponent-popupDark",
    label: "webclient__mapComponent-label",
    popupMarginClose: "webclient__mapComponent-popupMarginClose",
    popupMarginOpen: "webclient__mapComponent-popupMarginOpen"
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PortfolioMap));
