/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-nested-ternary  */
import React, { useState, useEffect } from "react";
import { Paper, makeStyles, Grid, Chip, Typography, useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";
// import { withRouter } from "react-router-dom";
import _moment from "moment";
import { VERBIAGE } from "../../../utils/enums/index";
// import AnalyticsChartTableau from "../../common/components/AnalyticsChartTableau";
import "../styles/portfolioView.scss";
import AnalyticTable from "../../common/Analytics/analyticTable";
import REFRESH_TIMER from "../../../utils/enums/Enum";
import ButtonCommon from "../../common/Analytics/ButtonCommon";
import URL from "../../../utils/URL";
import AnalyticsActions from "../../redux_Analytics/AnalyticsActions";
// import CT_CH from "../../../../assets/CT_CH.png";
// import CH_CD_PUMP from "../../../../assets/CH_CD_PUMP.png";
import Interval from "../../common/components/Interval";
import AnomalyDetectionCharts from "../../common/components/AnomalyDetectionCharts";
import TextInput from "../../common/components/TextInput";
import FaultAlarmsTabs from "../../common/Analytics/FaultAlarmsTabs";
// import AnomalyDetectionTabs from "../../common/Analytics/AnomalyDetectionTabs";

const AnalyticsView = props => {
    const {
        analyticNotes,
        fetchAnalyticsChips,
        handleLabel,
        fetchSeverity,
        setTabDates,
        setTabMax,
        setTabMin,
        fetchOldNotes,
        handleNotes,
        fetchUserTagsChips,
        fetchNotesChips,
        addWorksheet,
        worksheets,
        tab,
        userTagsChips,
        notesChips,
        severityChips,
        analyticChips,
        dates,
        min,
        max,
        // dateSlider,
        // fetchChartPosition,
        handleSliderChange,
        handleEndDateInput,
        handleStartDateInput,
        onSliderChangeCommitted,
        initialFilter
    } = props;
    const styleTheme = useTheme();
    const [url, setUrl] = useState();
    const [timestamp, setTimeStamp] = useState(Date.now());
    const [startDate, setStartDate] = useState(Date.now());
    const [endDate, setEndDate] = useState(Date.now());
    const [label, setLabel] = useState("");
    const [notes, setNotes] = useState("");
    const [value, setValue] = useState("1");
    const [unit, setUnit] = useState("Days");
    const [time, setTime] = useState("");
    const [code, setCode] = useState("");
    const [durationValue, setDurationValue] = useState("");
    const [inActiveNote, setInActiveNote] = useState(false);
    const [inActiveLabel, setInActiveLabel] = useState(false);
    // const [first, setFirst] = useState(true);
    const [oldNotes, setOldNotes] = useState([]);
    const [noteId, setNoteId] = useState();
    const [enableOldNoteUpdate, setEnableOldNoteUpdate] = useState(true);
    const [parameter, setParameter] = useState("");
    const [tabValue, setTabValue] = useState("details");
    // const [parameterURL, setParameterURL] = useState();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // const [size, setSize] = useState(tab === "anomalyDetection" ? 6 : 4);
    // const [sizeTableau, setSizeTableau] = useState(4);
    // const expansion = count => {
    //     setSize(count);
    //     if (count === 4) {
    //         setSizeTableau(count);
    //     } else {
    //         setSizeTableau(count * 2);
    //     }
    // };

    const handleClick = tag => {
        setLabel(prev => `${prev} ${tag} `);
    };
    const handleNotesClick = tag => {
        setNotes(prev => `${prev} ${tag} `);
    };

    const handleLabelClick = () => {
        handleLabel({
            time,
            code,
            label,
            parameter,
            duration: {
                unit,
                value
            }
        });
    };

    const handleNewLabel = (newLabel, newTime, newCode, newParameter) => {
        if (newLabel === null) {
            newLabel = "";
        }
        setLabel(newLabel);
        setTime(newTime);
        setCode(newCode);
        setParameter(newParameter);
        setInActiveLabel(false);
        setInActiveNote(false);
    };

    // function to handle notes
    const fetchNotes = currentNoteId => {
        setNotes("");
        setEnableOldNoteUpdate(true);
        setNoteId(currentNoteId);
        if (currentNoteId) {
            fetchOldNotes(currentNoteId);
        } else {
            setOldNotes([]);
        }
    };

    const handleNotesSubmit = () => {
        setEnableOldNoteUpdate(false);
        if (notes) {
            setNotes("");
            setOldNotes([...oldNotes, notes]);
            handleNotes(time, code, noteId, notes, parameter);
        }
    };

    // function to handle submission of both label and notes
    const handleLabelSubmit = () => {
        setInActiveLabel(true);
        handleLabelClick();
    };

    // function to handle submission of both label and notes
    const handleNote = () => {
        setInActiveNote(true);
        handleNotesSubmit();
    };

    useEffect(() => {
        // if (analyticNotes.length && enableOldNoteUpdate) {
        if (enableOldNoteUpdate) {
            setOldNotes(analyticNotes);
        }
    }, [analyticNotes, enableOldNoteUpdate]);

    // useEffect(() => {
    //     if (tab === "anomalyDetection") {
    //         setSize(6);
    //     } else {
    //         setSize(4);
    //     }
    // }, [tab]);
    useEffect(() => {
        if (durationValue) {
            const [unitValue, unitTypeValue] = durationValue.split(" ");
            setValue(unitValue);
            switch (unitTypeValue) {
                case "month":
                case "months":
                    setUnit("Months");
                    break;
                case "day":
                case "days":
                    setUnit("Days");
                    break;
                case "hour":
                case "hours":
                    setUnit("Hours");
                    break;
                case "min":
                case "mins":
                    setUnit("Minutes");
                    break;
                default:
                    setUnit("");
            }
        }
    }, [durationValue]);

    const [timePeriod, setTimePeriod] = useState("day");
    const selectTimeline = timeZone => {
        setTimePeriod(timeZone);
        if (timeZone === "year" || timeZone === "years" || timeZone === "Years") {
            return 31557600000;
        }
        if (timeZone === "month" || timeZone === "months" || timeZone === "Months") {
            return 2629800000;
        }
        if (timeZone === "day" || timeZone === "days" || timeZone === "Days") {
            return 86400000;
        }
        if (timeZone === "hour" || timeZone === "hours" || timeZone === "Hours") {
            return 3600000;
        }
        if (timeZone === "min" || timeZone === "mins" || timeZone === "Minutes") {
            return 60000;
        }
        return 0;
    };
    const getTimeBuffer = period => {
        let buffer;
        switch (period) {
            case "month":
            case "months":
                buffer = 2629800000 * 3;
                break;
            case "day":
            case "days":
                buffer = 86400000;
                break;
            case "hour":
            case "hours":
                buffer = 3600000 * 4;
                break;
            case "min":
            case "mins":
                buffer = 3600000 * 4;
                break;
            default:
                buffer = 86400000;
        }
        return buffer;
    };
    const changeDurationStructure = durationTime => {
        const newDurationTime = durationTime.split(" ");
        let newDate = 0;
        for (let i = 0; i < newDurationTime.length; i += 2) {
            newDate +=
                parseInt(newDurationTime[i], 10) * selectTimeline(newDurationTime[i + 1]) +
                getTimeBuffer(newDurationTime[i + 1]);
        }
        return newDate;
    };
    const addStartBuffer = durationTime => {
        if (durationTime === "1 month") {
            return 31557600000;
        }
        return 0;
    };

    // const handleRefBand = (start, duration) => {
    //     const [length, interval] = duration.split(" ");
    //     const refBandLength = parseInt(length, 10) * selectTimeline(interval);
    //     const end = start + refBandLength;

    //     worksheets.forEach(ws => {
    //         const GMT_8_OFFSET = 8 * 1000 * 60 * 60;
    //         const [filterStart, filterEnd] = dates;
    //         // 3 cases for each start and end of band
    //         // Either < filter view, within filter view, or > filter view
    //         let actualBandStart;
    //         let actualBandEnd;
    //         if (start < filterStart) {
    //             // start < filter view, NOT OK, set band start to filter start
    //             actualBandStart = filterStart;
    //         } else if (start >= filterStart && start <= filterEnd) {
    //             // start within filter view, OK
    //             actualBandStart = start;
    //         } else {
    //             // start > filter view, NOT OK, set band start and end to filter end
    //             actualBandStart = filterEnd;
    //             actualBandEnd = filterEnd; // if start > filter view, end also > filter view
    //         }

    //         if (end < filterStart) {
    //             actualBandEnd = filterStart;
    //         } else if (end >= filterStart && end <= filterEnd) {
    //             actualBandEnd = end;
    //         } else {
    //             actualBandEnd = filterEnd;
    //         }

    //         actualBandStart += GMT_8_OFFSET;
    //         actualBandEnd += GMT_8_OFFSET;

    //         ws.getWorkbook().changeParameterValueAsync(
    //             "WEBAPP_REFSTART",
    //             new Date(actualBandStart),
    //         );
    //         ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(actualBandEnd));
    //     });
    // };

    const initialLoad = ws => {
        initialFilter(ws);
        const [length, interval] = durationValue.split(" ");
        const refBandLength = parseInt(length, 10) * selectTimeline(interval);
        const end = timestamp + refBandLength;

        const GMT_8_OFFSET = 8 * 1000 * 60 * 60;
        const [filterStart, filterEnd] = dates;
        // 3 cases for each start and end of band
        // Either < filter view, within filter view, or > filter view
        let actualBandStart;
        let actualBandEnd;
        if (timestamp < filterStart) {
            // start < filter view, NOT OK, set band start to filter start
            actualBandStart = filterStart;
        } else if (timestamp >= filterStart && timestamp <= filterEnd) {
            // start within filter view, OK
            actualBandStart = timestamp;
        } else {
            // start > filter view, NOT OK, set band start and end to filter end
            actualBandStart = filterEnd;
            actualBandEnd = filterEnd; // if start > filter view, end also > filter view
        }

        if (end < filterStart) {
            actualBandEnd = filterStart;
        } else if (end >= filterStart && end <= filterEnd) {
            actualBandEnd = end;
        } else {
            actualBandEnd = filterEnd;
        }

        // actualBandStart += GMT_8_OFFSET;
        // actualBandEnd += GMT_8_OFFSET;

        ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(actualBandStart));
        ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(actualBandEnd));
    };

    // useEffect(() => {
    //     if (worksheets.length >= 1) {
    //         handleRefBand(timestamp, durationValue);
    //     }
    // }, [worksheets, dates]);

    const changeUrl = (newUrl, newStartDate, newDuration) => {
        setUrl(newUrl);
        setDurationValue(newDuration);
        setStartDate(
            newStartDate - addStartBuffer(newDuration) - changeDurationStructure(newDuration),
        );
        const newEndDate = newStartDate + changeDurationStructure(newDuration);
        setEndDate(newEndDate);
        setTabDates([
            newStartDate - addStartBuffer(newDuration) - changeDurationStructure(newDuration),
            newEndDate
        ]);
        setTabMin(
            newStartDate - addStartBuffer(newDuration) - changeDurationStructure(newDuration),
        );
        setTabMax(newEndDate);
        // setParameterURL(newUrl)
    };

    const setDataValue = (
        newParameter,
        newLabel,
        newDuration,
        newStartDate,
        noteIdNew,
        newCode,
    ) => {
        const offset = new Date().getTimezoneOffset();
        const adjustedTime = _moment(newStartDate)
            .add(offset, "m")
            .valueOf();
        // console.log('setDataValue')
        // const offset = new Date().getTimezoneOffset();
        // console.log('offset : ', offset)
        // // const localTime = _moment(newStartDate).utcOffset(-1 * offset , false);
        // const localTime = newStartDate - (offset * 60000)
        // console.log('newStartDate : ', newStartDate)
        // console.log('localTime : ', localTime)

        // fetchChartPosition(newCode, newParameter);
        setLabel(newLabel);
        setParameter(newParameter);
        setDurationValue(newDuration);
        setUrl(`${URL.DATA.ANALYTICS.EDIT}/${newParameter}`);
        setTimeStamp(newStartDate);
        setStartDate(adjustedTime - addStartBuffer(newDuration));
        setEndDate(adjustedTime + changeDurationStructure(newDuration));
        // setStartDate(newStartDate - addStartBuffer(newDuration));
        // setEndDate(newStartDate + changeDurationStructure(newDuration));
        fetchNotes(noteIdNew);
        // setParameterURL(URL.DATA.ANALYTICS.CHARTS.CHART_1);
    };
    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        fetchAnalyticsChips();
        fetchSeverity();
        fetchNotesChips();
        fetchUserTagsChips();
        const timer = setInterval(() => {
            fetchAnalyticsChips();
            fetchSeverity();
            fetchNotesChips();
            fetchUserTagsChips();
        }, timerValue);
        return () => clearInterval(timer);
    }, [fetchAnalyticsChips, fetchSeverity, fetchNotesChips, fetchUserTagsChips]);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px 23px",
            paddingTop: "69px"
        },
        table: {
            height: "41vh"
        },
        paper: {
            // height: "32vh",
            padding: "15px"
        },
        header: {
            margin: "0px",
            padding: "0px"
        },
        picture: {
            padding: theme.spacing(0),
            color: theme.palette.text.secondary,
            height: "80vh" // heightMulti
        },
        chipContainer: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            padding: theme.spacing(0.5),
            minHeight: "30px",
            maxHeight: "30px",
            overflowY: "hidden",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        chipSeverity: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            padding: theme.spacing(0.5),
            minHeight: "30px",
            maxHeight: "61px",
            overflowY: "hidden",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        chipUsers: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            padding: theme.spacing(0.5),
            minHeight: "40px",
            maxHeight: "90px",
            overflowY: "hidden",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        chip: {
            margin: theme.spacing(0.5)
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        label: {
            height: "165px",
            marginBottom: "0.4vh",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        notes: {
            height: "230px",
            marginBottom: "0.4vh",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        notesTextarea: {
            backgroundColor: "lightgrey"
        },
        grid: {
            display: "flex",
            alignContent: "baseline"
        },
        overrideRoot: {
            display: tab !== "faults&Alarms" ? "none" : "flex"
        },
        headerText: {
            color:
                theme.palette.type === "dark"
                    ? theme.palette.text.dark_grey
                    : theme.palette.text.light_black,
            paddingTop: "1.2vh",
            paddingBottom: "1.6vh",
            fontSize: "13px"
        }
    }));
    const noteDisabled = {
        color:
            styleTheme.palette.type === "dark"
                ? styleTheme.palette.text.grey
                : styleTheme.palette.text.light_grey
    };
    const classes = useStyles();

    return (
        <Grid elevation={0}>
            <Grid container spacing={1}>
                {/* table start */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AnalyticTable
                      changeUrl={changeUrl}
                      handleNewLabel={handleNewLabel}
                        //   first={first}
                      setDataValue={setDataValue}
                      fetchNotes={fetchNotes}
                    />
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FaultAlarmsTabs
                      value={tabValue}
                      handleChange={handleChange}
                      setTabDates={setTabDates}
                      setTabMax={setTabMax}
                      setTabMin={setTabMin}
                      code={code}
                      parameter={parameter}
                      startAnalyticDate={startDate}
                      endAnalyticDate={endDate}
                      timePeriod={timePeriod}
                      dates={dates}
                      min={min}
                      max={max}
                      handleSliderChange={handleSliderChange}
                      onSliderChangeCommitted={onSliderChangeCommitted}
                      handleStartDateInput={handleStartDateInput}
                      handleEndDateInput={handleEndDateInput}
                    />
                </Grid>
                {/* table end */}
                {/* chart start */}

                {/* faultLabel start */}
                <Grid item container spacing={1} xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Grid>
                            <Typography className={classes.headerText} variant="h6">
                                {VERBIAGE.ANALYTICS.FAULT_LABEL}
                            </Typography>
                            <Paper
                              elevation={0}
                              className={classNames(classes.paper, classes.label)}
                            >
                                {/* {console.log(durationValue)} */}
                                <Interval
                                  intervalValue={value}
                                  intervalTypeValue={unit}
                                  intervalTypeChange={event => setUnit(event.target.value)}
                                  intervalChange={event => setValue(event.target.value)}
                                  faultLabel
                                />
                                <ButtonCommon
                                  labelText={label}
                                  labelChange={event => setLabel(event.target.value)}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.TYPE_HERE}
                                  onClick={handleLabelSubmit}
                                  inActive={inActiveLabel}
                                  className={AnalyticsView.styles.textContainer}
                                  buttonLabel={VERBIAGE.ANALYTICS.SUBMIT}
                                  rows={3}
                                />
                            </Paper>
                            <Paper elevation={0} className={classes.chipContainer}>
                                {analyticChips &&
                                    analyticChips.map(data => {
                                        const countColor =
                                            data.count < 5
                                                ? "#64b5f6"
                                                : data.count < 10
                                                ? "#1e88e5"
                                                : "#0d47a1";
                                        return (
                                            <li key={data.key}>
                                                <Chip
                                                  label={data.label}
                                                  className={classes.chip}
                                                  onClick={() => handleClick(data.value)}
                                                  style={{
                                                        color:
                                                            styleTheme.palette.type === "dark"
                                                                ? styleTheme.palette.text.dark_grey
                                                                : styleTheme.palette.text
                                                                      .light_black,
                                                        border: `1px solid ${countColor}`,
                                                        background: "transparent",
                                                        height: "20px"
                                                    }}
                                                />
                                            </li>
                                        );
                                    })}
                            </Paper>
                            <Paper elevation={0} className={classes.chipSeverity}>
                                {severityChips &&
                                    severityChips.map(data => {
                                        return (
                                            <li key={data.key}>
                                                <Chip
                                                  label={data.label}
                                                  className={classes.chip}
                                                  onClick={() => handleClick(data.value)}
                                                  style={{
                                                        color:
                                                            styleTheme.palette.type === "dark"
                                                                ? styleTheme.palette.text.dark_grey
                                                                : styleTheme.palette.text
                                                                      .light_black,
                                                        border: `1px solid ${data.color}`,
                                                        background: "transparent",
                                                        height: "20px"
                                                    }}
                                                />
                                            </li>
                                        );
                                    })}
                            </Paper>
                        </Grid>
                        <Grid>
                            <Typography className={classes.headerText} variant="h6">
                                {VERBIAGE.ANALYTICS.NOTES}
                            </Typography>
                            <Paper
                              elevation={0}
                              className={classNames(classes.paper, classes.notes)}
                            >
                                {/* <textarea
                                readOnly
                                className={classNames(
                                    AnalyticsView.styles.notesContainer,
                                    classes.notesTextarea,
                                )}
                                value={oldNotes.join(" ")}
                            /> */}
                                <TextInput
                                  styles={{ style: noteDisabled }}
                                  disabled
                                  value={oldNotes.join("")}
                                  multiline
                                  rows={5}
                                />
                                <ButtonCommon
                                  labelText={notes}
                                  userTagsChips={userTagsChips}
                                  labelChange={event => setNotes(event.target.value)}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.TYPE_HERE}
                                  onClick={handleNote}
                                  inActive={inActiveNote}
                                  className={AnalyticsView.styles.notesContainer}
                                  buttonLabel={VERBIAGE.BUTTONS.ADD_NOTES}
                                    // disableButton
                                  rows={2}
                                  parse
                                />
                            </Paper>
                            {/* <Paper elevation={0} className={classes.chipSeverity}>
                  {userTagsChips &&
                                    userTagsChips.map(data => {
                                        return (
                                          <li key={data.key}>
                                            <Chip
                                              label={data.label}
                                              className={classes.chip}
                                              onClick={() => handleNotesClick(data.value)}
                                              style={{
                                                        color:
                                                            styleTheme.palette.type === "dark"
                                                                ? styleTheme.palette.text.dark_grey
                                                                : styleTheme.palette.text
                                                                      .light_black,
                                                        border: `1px solid ${data.color}`,
                                                        background: "transparent",
                                                        height: "20px"
                                                    }}
                                            />
                                          </li>
                                        );
                                    })}
                </Paper> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                        {(tabValue === "parameter" ||
                            tabValue === "details" ||
                            tabValue === "overview") && (
                            <AnomalyDetectionCharts
                                // newChartPosition={newChartPosition}
                              addWorksheet={addWorksheet}
                              tabValue={tabValue}
                              initialFilter={initialLoad}
                              value={startDate}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {/* faultLabel end */}
            {/* chip start */}
            {/* <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper elevation={0} className={classes.picture}>
              <img src={CH_CD_PUMP} alt="CH CD Pump" height="100%" width="100%" />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={0} className={classes.picture}>
              <img src={CT_CH} alt="CT CH" height="100%" width="100%" />
            </Paper>
          </Grid>
          {/* </div> }
        </Grid> */}
            {/* </Grid> */}
        </Grid>
    );
};
AnalyticsView.styles = {
    headerContainer: "webclient__portfolioView-container-header",
    textContainer: "webclient__buttonCommon-container-text",
    notesContainer: "webclient__buttonCommon-container-notes"
};
const mapStateToProps = state => ({
    analyticNotes: state.analytics.notes,
    analyticChips: state.analytics.analyticChip,
    severityChips: state.analytics.severity,
    notesChips: state.analytics.notesChip,
    userTagsChips: state.analytics.userTagsChip
});
const mapDispatchToProps = dispatch => ({
    fetchAnalyticsChips: () => {
        dispatch(AnalyticsActions.fetchAnalyticsChips());
    },
    fetchSeverity: () => {
        dispatch(AnalyticsActions.fetchSeverity());
    },
    handleLabel: fault => {
        dispatch(AnalyticsActions.handleLabel(fault));
    },
    fetchOldNotes: noteId => {
        dispatch(AnalyticsActions.fetchOldNotes(noteId));
    },
    handleNotes: (start, code, noteId, notes, parameter) => {
        dispatch(AnalyticsActions.handleNotes(start, code, noteId, notes, parameter));
    },
    fetchNotesChips: () => {
        dispatch(AnalyticsActions.fetchNotesChips());
    },
    fetchUserTagsChips: () => {
        dispatch(AnalyticsActions.fetchUserTagsChips());
    }
    // fetchChartPosition: (code, parameter) => {
    //     dispatch(AnalyticsActions.fetchChartPosition(code, parameter));
    // }
});
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsView);
