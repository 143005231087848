/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect, useState, useCallback } from "react";
import { MenuItem, Input } from "@material-ui/core";
import _ from "lodash";

const menuItemStyle = {
    paddingTop: "0px",
    paddingBottom: "0px",
    position: "sticky",
    top: "0px",
    zIndex: 10,
    backgroundColor: "#ffffff"
};

const SearchFilter = React.forwardRef((props, ref) => {
    const {
        items,
        setMenuItems,
        emptyInput,
        setEmptyInput,
        setTableUpdate,
        tableUpdate,
        onTextSearch
    } = props;
    const [inputText, setInputText] = useState("");

    const getMenuItems = inputValue => {
        let menuList = [];
        menuList = items.filter(item => {
            const searchItem = item.toLowerCase();
            if (searchItem.indexOf(inputValue.toLowerCase()) > -1) {
                return item;
            }
        });
        return menuList;
    };

    useEffect(() => {
        if (emptyInput) {
            setInputText("");
            setEmptyInput(true);
        }
    }, [emptyInput]);

    const updateData = useCallback(
        _.debounce(input => {
            if (onTextSearch) {
                onTextSearch(input);
                return;
            }

            if (!tableUpdate) {
                setTableUpdate(true);
            }

            if (input) {
                const menuList = getMenuItems(input);
                setMenuItems(menuList);
            } else {
                setMenuItems(items);
            }
        }, 300),
        [],
    );

    const handleChange = e => {
        const input = e.target.value;
        setInputText(input);
        updateData(input);
    };

    return (
      <MenuItem style={menuItemStyle} onKeyDown={e => e.stopPropagation()} ref={ref}>
        <Input type="text" placeholder="Search" value={inputText} onChange={handleChange} />
      </MenuItem>
    );
});

export default SearchFilter;
