/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect, useRef } from "react";
import {
    Accordion,
    AccordionSummary,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Add, EditOutlined, ExpandMore } from "@material-ui/icons";
import api from "../../../../utils/API";
import URL from "../../../../utils/URL";
import EditAdminTable from "../EditAdminTable";
import EditOnboardingList from "./EditOnboardingList";
import { VERBIAGE } from "../../../../utils/enums";
import CustomFooter from "../CustomFooter";

const moment = require("moment");

const OnboardingList = () => {
    const [data, setData] = useState([]);

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    const isNarrow = useMediaQuery("(max-width: 600px)");
    const [dataCount, setDataCount] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(0);
    const [pageCnt, setPageCnt] = useState(0);

    const isMounted = useRef(false);

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        codeVersion: VERBIAGE.TEXT.EMPTY_STRING,
        dataVersion: VERBIAGE.TEXT.EMPTY_STRING,
        timestamp: VERBIAGE.TEXT.EMPTY_STRING
    };

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.SITE_ONBOARDING.ONBOARDING_LIST, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (isMounted.current) {
                    setDataCount(response.data.length);
                    const tempData = [];
                    for (let i = 0; i < response.data.length; i++) {
                        const newData = {};
                        newData.id = response.data[i].id;
                        newData.siteId = response.data[i].siteId;
                        newData.timestamp = moment(response.data[i].timestamp).format(
                            "Do MMMM YYYY, HH:mm:ss",
                        );
                        newData.codeVersion = response.data[i].codeVersion;
                        newData.dataVersion = response.data[i].dataVersion;
                        tempData.push(newData);
                    }
                    setData(tempData);
                    if (response.data && response.data.length) {
                        const optionsData = response.data.map(site => {
                            return { siteId: site.siteId, dataVersion: site.dataVersion };
                        });
                        localStorage.setItem("optionsData", JSON.stringify(optionsData));
                    }
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: "16px",
            overflowWrap: "anywhere"
        }
    }))(TableCell);

    const cols = [
        // {
        //     name: "",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         empty: true,
        //         customBodyRenderLite: (dataIndex, rowIndex) => {
        //             return (
        //                 <IconButton
        //                   onClick={() => {
        //                         editRef.current.update({
        //                             ...editRef.current.getConfig(),
        //                             ...data[dataIndex]
        //                         });
        //                         setIsEdit(true);
        //                         handleClick();
        //                     }}
        //                 >
        //                     <EditOutlined />
        //                 </IconButton>
        //             );
        //         }
        //     }
        // },
        { label: "Site ID", name: "siteId" },
        { label: "Code Version", name: "codeVersion" },
        { label: "Data Version", name: "dataVersion" },
        { label: "Timestamp", name: "timestamp" }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        const rowData = data[dataIndex];
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Table>
                                <TableBody>
                                    {/* <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell>
                                            <IconButton
                                              onClick={event => {
                                                    event.stopPropagation();
                                                    editRef.current.update({
                                                        ...editRef.current.getConfig(),
                                                        ...data[dataIndex]
                                                    });
                                                    setIsEdit(true);
                                                    handleClick();
                                                }}
                                              onFocus={event => event.stopPropagation()}
                                            >
                                                <EditOutlined />
                                            </IconButton>
                                        </StyledTableCell>
                                    </TableRow> */}
                                    <TableRow>
                                        <StyledTableCell>Site ID</StyledTableCell>
                                        <StyledTableCell>{rowData.siteId}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Code Version</StyledTableCell>
                                        <StyledTableCell>{rowData.codeVersion}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Data Version</StyledTableCell>
                                        <StyledTableCell>{rowData.dataVersion}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Timestamp</StyledTableCell>
                                        <StyledTableCell>{rowData.timestamp}</StyledTableCell>
                                    </TableRow>
                                    {/* {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell>Permissions</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.permissions}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Active Site</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.activeSite}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Group</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.groupName}
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null} */}
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    };

    const changeRowColor = async (rowIndex, dataIndex) => {
        let i = 0;
        // console.log("dataCount : ", dataCount);
        // console.log("rowIndex : ", rowIndex);
        // console.log("dataIndex : ", dataIndex);
        // console.log("pageCnt : ", pageCnt);
        // const totalCt = dataCount/
        for (i = 0; i < dataCount; i++) {
            // console.log("rowPerPage : ", i % rowPerPage);
            const row = document.getElementById(`MUIDataTableBodyRow-${i}`);
            // console.log("dataIndex : ", dataIndex);
            // console.log("i : ", i);
            if (i === dataIndex) {
                // console.log("gray");
                row.setAttribute("style", "background: gray");
            } else {
                // console.log("white");
                row.setAttribute("style", "background: white");
            }
        }
    };

    const handleRowClick = (rowData, rowMeta) => {
        // console.log(rowData);
        // console.log(rowData, rowMeta);

        changeRowColor(rowMeta.rowIndex, rowMeta.dataIndex);
        localStorage.setItem("onboardingSite", rowData[0]);
    };

    // const onRowSelectionChange = (ev, ex, ez) => {
    //     console.log(ez);
    //     changeRowColor(rowIndex.rowIndex);
    //   };

    const options = {
        // isRowSelectable: true,
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "400px",
        filter: false,
        // selectableRowsOnClick: true,
        // filterType: 'custom',
        // filterType: 'dropdown',
        // onRowSelectionChange,
        // onRowsSelect:(currentRowsSelected: any, allRowsSelected: any) => {console.log(allRowsSelected);
        // onRowClick: handleRowClick,
        print: false,
        viewColumns: false,
        download: false,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title="Add Map">
                        <IconButton
                          onClick={() => {
                                handleClick();
                            }}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            setRowPerPage(rowsPerPage);
            setPageCnt(page);

            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    return (
        <div>
            {/* <EditAdminTable
              isEdit={isEdit}
              open={open}
              handleClick={handleClick}
              setOpen={setOpen}
              ref={editRef}
            /> */}
            {<EditOnboardingList open={open} handleClick={handleClick} setOpen={setOpen} />}
            <div className={classes.table}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      title="Site Onboarding Info"
                      data={data}
                      columns={cols}
                      options={options}
                    />
                </MuiThemeProvider>
            </div>
        </div>
    );
};

export default OnboardingList;
