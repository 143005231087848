import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";

const EditNameMapTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        webName: VERBIAGE.TEXT.EMPTY_STRING,
        dataName: VERBIAGE.TEXT.EMPTY_STRING,
        clientName: VERBIAGE.TEXT.EMPTY_STRING,
        status: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleSite = ({ target: { value } }) => {
        updateConfig({ ...config, siteId: value });
    };

    const handleWeb = ({ target: { value } }) => {
        updateConfig({ ...config, webName: value });
    };

    const handleData = ({ target: { value } }) => {
        updateConfig({ ...config, dataName: value });
    };

    const handleClient = ({ target: { value } }) => {
        updateConfig({ ...config, clientName: value });
    };

    const handleStatus = ({ target: { value } }) => {
        updateConfig({ ...config, status: value });
    };

    const handleSubmit = async () => {
        if (
            !config.webName ||
            !config.dataName ||
            !config.clientName ||
            !config.status ||
            !config.siteId
        ) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        const data = {
            siteId: config.siteId,
            webName: config.webName,
            dataName: config.dataName,
            clientName: config.clientName,
            status: config.status
        };
        const url = URL.DATA.ANALYTICS.SETTINGS.NAME_MAPPING;
        try {
            if (isEdit) {
                await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } else {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            }
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
      <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
        <DialogTitle>
          {`To ${isEdit ? "edit" : "add to"} the name mapping, please ${
                    isEdit ? "update" : "enter"
                } the details below.`}
        </DialogTitle>

        <DialogContent>
          <div className={EditNameMapTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
              onChange={handleSite}
              value={config.siteId}
              type="text"
            />
          </div>
          <div className={EditNameMapTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_WEB_NAME}
              onChange={handleWeb}
              value={config.webName}
              type="text"
            />
          </div>
          <div className={EditNameMapTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_DATA_NAME}
              onChange={handleData}
              value={config.dataName}
              type="text"
            />
          </div>
          <div className={EditNameMapTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CLIENT_NAME}
              onChange={handleClient}
              value={config.clientName}
              type="text"
            />
          </div>
          <div className={EditNameMapTable.styles.inputContainer}>
            <DropdownSelect
              name={config.status}
              options={VERBIAGE.TEXT.STATUS_OPTIONS}
              onChange={handleStatus}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_NAME_MAPPING_STATUS}
            />
          </div>
          {!!error && (
            <div className={classes.error}>
              <Typography>{error}</Typography>
            </div>
                )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleSubmit}>
            {VERBIAGE.BUTTONS.SUBMIT}
          </Button>
          <Button
            className={classes.error}
            onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
          >
            {VERBIAGE.BUTTONS.CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    );
});

EditNameMapTable.styles = {
    inputContainer: "webclient__mapTable-input",
    errorText: "webclient__mapTable-error-text"
};

export default EditNameMapTable;
