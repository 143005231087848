/* eslint-disable max-len */
import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import DropdownSelectSite from "./DropdownSelectSite";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";

const EditVersionHistoryTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");
    const [siteOptions, setSiteOptions] = useState([]);
    const [changeType, setChangeType] = useState([]);
    const [versionRef, setVersionRef] = useState(VERBIAGE.TEXT.EMPTY_STRING);

    const isMounted = useRef(false);

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        type: VERBIAGE.TEXT.EMPTY_STRING,
        details: VERBIAGE.TEXT.EMPTY_STRING,
        version: VERBIAGE.TEXT.EMPTY_STRING,
        date: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleDate = event => {
        const newDate = new Date(event._d).getTime();
        updateConfig({ ...config, date: newDate });
    };

    const handleSite = ({ target: { value } }) => {
        const foundSite = siteOptions.find(({ siteId }) => {
            return siteId === `${value}`;
        });
        setVersionRef(foundSite.version);
        updateConfig({ ...config, siteId: value, version: foundSite.version });
    };

    const handleType = ({ target: { value } }) => {
        const versionData = versionRef.split("-");
        if (value === "PIPELINE") versionData[1]++;
        else if (value === "TABLEAU") versionData[2]++;
        updateConfig({ ...config, type: value, version: versionData.join("-") });
    };

    const handleDetails = ({ target: { value } }) => {
        updateConfig({ ...config, details: value });
    };

    const handleVersion = ({ target: { value } }) => {
        updateConfig({ ...config, version: value });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (isMounted.current) {
                    const { data } = await api.get(URL.DATA.VERSION.SITES, {
                        headers: {
                            "x-auth-token": localStorage.token
                        }
                    });
                    const options = [{ siteId: "all", siteName: "all", version: VERBIAGE.VERSION }];
                    data.forEach(site => {
                        options.push({
                            siteId: site.siteId,
                            siteName: site.siteName,
                            version: site.version
                        });
                    });
                    setSiteOptions(options);
                    setChangeType(["WEBAPP", "PIPELINE", "TABLEAU", "FEATURE", "PLATFORM"]);
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open]);

    const handleSubmit = async () => {
        if (!config.date || !config.siteId || !config.type || !config.details) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }

        const data = {
            siteId: config.siteId,
            type: config.type,
            details: config.details,
            version: config.version,
            date: config.date
        };
        const url = URL.DATA.ADMIN_SETTINGS.VERSION_HISTORY;
        try {
            if (isEdit) {
                await api.put(`${url}/${config.id}`, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } else {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            }
            setError("");
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    // Override border color of input
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            }
        }
    });

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} the Version History, please ${
                    isEdit ? "update" : "enter"
                } the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        {/* <div className={EditVersionHistoryTable.styles.inputContainer}> */}
                        <DropdownSelectSite
                          name={config.siteId}
                          options={siteOptions}
                          onChange={handleSite}
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_HISTORY_SITE_ID}
                        />
                        {/* </div> */}
                    </Grid>
                    <Grid item xs={6}>
                        {/* <div className={EditVersionHistoryTable.styles.inputContainer}> */}
                        <DropdownSelect
                          name={config.type}
                          options={changeType}
                          onChange={handleType}
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_HISTORY_TYPE}
                        />
                        {/* </div> */}
                    </Grid>
                    <Grid item xs={6}>
                        {/* <div className={EditVersionHistoryTable.styles.inputContainer}> */}
                        <TextInput
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_HISTORY_VERSION}
                          onChange={handleVersion}
                          value={config.version}
                          type="text"
                        />
                        {/* </div> */}
                    </Grid>
                    <Grid item xs={6}>
                        {/* <div className={EditVersionHistoryTable.styles.inputContainer}> */}
                        <MuiThemeProvider theme={themeOverride}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDateTimePicker
                                  variant="dialog"
                                  label={VERBIAGE.PLACEHOLDER_TEXT.ENTER_HISTORY_DATE}
                                  inputVariant="outlined"
                                  value={config.date}
                                  onChange={handleDate}
                                  format="DD/MM/YYYY HH:mm"
                                  fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                        {/* </div> */}
                        {!!error && (
                            <div className={classes.error}>
                                <Typography>{error}</Typography>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {/* <div className={EditVersionHistoryTable.styles.inputContainer}> */}
                        <TextInput
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_HISTORY_DETAILS}
                          onChange={handleDetails}
                          value={config.details}
                          type="text"
                          multiline
                        />
                        {/* </div> */}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditVersionHistoryTable.styles = {
    inputContainer: "webclient__mapTable-input",
    errorText: "webclient__mapTable-error-text"
};

export default EditVersionHistoryTable;
