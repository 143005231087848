import ReportActionTypes from "./ReportActionTypes";

const initialState = {
    loading: false,
    error: null,
    siteReport: {
        combined: null,
        charts: null
    },
    dynamicReport: {
        tabs: [],
        data: []
    },
    dynamicReportTab: null,
    dynamicReportChart: null
};

const ReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case ReportActionTypes.SET_LOADING:
            return {
                ...state,
                loading: action.data
            };
        case ReportActionTypes.SET_ERROR:
            return {
                ...state,
                error: action.data
            };
        case ReportActionTypes.SET_SITE_REPORT:
            return {
                ...state,
                siteReport: action.data
            };
        case ReportActionTypes.SET_DYNAMIC_REPORT_DATA:
            return {
                ...state,
                dynamicReport: {
                    ...state.dynamicReport,
                    data: action.data
                }
            };
        case ReportActionTypes.SET_DYNAMIC_REPORT_SET_TABS:
            return {
                ...state,
                dynamicReport: {
                    ...state.dynamicReport,
                    tabs: action.tabs
                }
            };
        case ReportActionTypes.SET_DYNAMIC_REPORT_ADD_TAB:
            return {
                ...state,
                dynamicReport: {
                    ...state.dynamicReport,
                    tabs: [...state.dynamicReport.tabs, action.tab]
                }
            };
        case ReportActionTypes.SET_DYNAMIC_REPORT_UPDATE_TAB:
            return {
                ...state,
                dynamicReport: {
                    ...state.dynamicReport,
                    tabs: state.dynamicReport.tabs.map((tab, index) => {
                        if (index !== action.index) {
                            return tab;
                        }
                        return {
                            ...tab,
                            ...action.tab
                        };
                    })
                }
            };
        case ReportActionTypes.SET_DYNAMIC_REPORT_REMOVE_TAB:
            return {
                ...state,
                dynamicReport: {
                    ...state.dynamicReport,
                    tabs: [
                        ...state.dynamicReport.tabs.slice(0, action.index),
                        ...state.dynamicReport.tabs.slice(action.index + 1)
                    ]
                }
            };
        case ReportActionTypes.SET_DYNAMIC_REPORT_SELECTED_TAB:
            return {
                ...state,
                dynamicReportTab: action.tab
            };
        case ReportActionTypes.SET_DYNAMIC_REPORT_CHART:
            return {
                ...state,
                dynamicReportChart: action.chart
            };
        default:
            return state;
    }
};

export default ReportReducer;
