/* eslint-disable max-len */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { ICONS } from "../../../utils/enums/index";
import ButtonView from "../components/ButtonView";
import VERBIAGE from "../../../utils/enums/Verbiage";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "0.8vh"
    },
    paper: {
        height: "44px",
        display: "flex",
        padding: "5px",
        backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff"
    },
    label: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "19px",
        color: "#E53935",
        paddingLeft: "8px",
        paddingTop: "6px"
    },
    alert: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "19px",
        color: theme.palette.type === "dark" ? "#ACACAC" : "#707070",
        paddingLeft: "8px",
        paddingTop: "6px"
    },
    number: {
        fontWeight: "500",
        fontSize: "10px",
        background: "#FEEBEE",
        width: "15px",
        height: "20px",
        borderRadius: "2px",
        color: "#E53935",
        padding: "3px"
    },
    number1: {
        fontWeight: "500",
        fontSize: "10px",
        background: "#ECEFF1",
        width: "15px",
        height: "20px",
        borderRadius: "2px",
        color: "#263238",
        padding: "3px"
    },
    digit: {
        paddingLeft: "8px",
        paddingTop: "6px"
    },
    button: {
        textTransform: "none",
        height: "25px",
        width: "52px",
        marginTop: "5px",
        background: theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
    },
    view: {
        display: "flex",
        width: "100%"
    },
    image: {
        width: "20px"
    },
    imageContainer: {
        paddingTop: "4px"
    }
}));

const MessageBulletin = () => {
    const classes = useStyles();
    return (
        <div>
            <Grid>
                <Grid item xs={12} className={classes.root}>
                    <Paper className={classes.paper} elevation={0}>
                        <div className={classes.view}>
                            <div className={classes.imageContainer}>
                                <img
                                  className={classes.image}
                                  alt="Alertcircle"
                                  src={ICONS.CIRCLE}
                                />
                            </div>
                            <div className={classes.label}>{VERBIAGE.PORTFOLIO_SCREEN.ANOMALY}</div>
                            <div className={classes.digit}>
                                <div className={classes.number}>2</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <ButtonView
                                  className={classes.button}
                                  label="View"
                                  path={VERBIAGE.DASHBOARD_PATH.REPORTS}
                                />
                            </div>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} className={classes.root}>
                    <Paper className={classes.paper} elevation={0}>
                        <div className={classes.view}>
                            <div className={classes.imageContainer}>
                                <img
                                  className={classes.image}
                                  alt="Alertcircle"
                                  src={ICONS.TRIANGLE}
                                />
                            </div>
                            <div className={classes.alert}>{VERBIAGE.PORTFOLIO_SCREEN.ALERTS}</div>
                            <div className={classes.digit}>
                                <div className={classes.number1}>3</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <ButtonView
                                  className={classes.button}
                                  label="View"
                                  path={VERBIAGE.DASHBOARD_PATH.REPORTS}
                                />
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};
export default MessageBulletin;
