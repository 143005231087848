import { isEdgeUI } from "./helpers";

/* eslint-disable max-len  */
const url = {
    AUTHENTICATION: {
        LOGIN: `${process.env.BASE_URL}/login`,
        REGISTER: `${process.env.BASE_URL}/api/v1/admins`,
        CHANGE_PASSWORD: `${process.env.BASE_URL}/api/v1/profile/password`,
        RESET_PASSWORD_REQUEST: `${process.env.BASE_URL}/api/v1/password/reset`,
        RESET_PASSWORD: `${process.env.BASE_URL}/api/v1/password/reset/new`,
        REFRESH_TOKEN: `${process.env.BASE_URL}/api/v1/sessions/extend`,
        MISSING_DATA_RESOLVED: `${process.env.BASE_URL}/api/v1/missing/done`
    },
    DATA: {
        BAR_GRAPH: `${process.env.BASE_URL}/api/v1/reports`,
        CHART: `${process.env.BASE_URL}/api/v1/charts`,
        LINE_POINT: `${process.env.BASE_URL}/api/v1/ad/chart1`,
        DATEFILTER: `${process.env.BASE_URL}/api/v1/config/dateFilter/`,
        ANOMOLY: `${process.env.BASE_URL}/api/v1/ad/cost`,
        SITEOVERVIEW: `${process.env.BASE_URL}/api/v1/siteSummary/efficiency`,
        SAVINGS: `${process.env.BASE_URL}/api/v1/siteSummary/savings`,
        CHILLER_TABLE_GAUGE: `${process.env.BASE_URL}/api/v1/siteSummary/performance`,
        PLANT: `${process.env.BASE_URL}/api/v1/siteSummary/plant`,
        INTERVAL: `${process.env.BASE_URL}/api/v1/siteSummary/interval`,
        LAST_UPDATE: `${process.env.BASE_URL}/api/v1/siteSummary/lastUpdate`,
        SITE_OVERVIEW_CHARTS: {
            SYS_PLANT_EFF: `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYS_PLANT_EFF`,
            SYS_PLANT_RT: `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYS_PLANT_RT`,
            SYS_PLANT_KW: `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYS_PLANT_KW`,
            SYS_PLANT_HEATBAL: `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYS_PLANT_HEATBAL`,
            EQP_CH_EFF: `${process.env.BASE_URL}/api/v1/siteSummary/chart/EQP_CH_EFF`,
            PUMP_EFF: `${process.env.BASE_URL}/api/v1/siteSummary/chart/PUMP_EFF`,
            EQP_CT_EFF: `${process.env.BASE_URL}/api/v1/siteSummary/chart/EQP_CT_EFF`,
            SYSTEM_OVERVIEW_CHARTS: `${process.env.BASE_URL}/api/v1/siteSummary/list/overview`,
            SYSTEM_EFFICIENCY_CHARTS: `${process.env.BASE_URL}/api/v1/siteSummary/list/efficiency`,
            SYSTEM_PERFORMANCE_CHARTS: `${process.env.BASE_URL}/api/v1/siteSummary/list/performance`,
            SYSTEM_OVERVIEW_COMBINED: `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYSTEM_OVERVIEW_COMBINED`,
            SYSTEM_EFFICIENCY_COMBINED: `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYSTEM_EFFICIENCY_COMBINED`,
            SYSTEM_PERFORMANCE_COMBINED: `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYSTEM_PERFORMANCE_COMBINED`
        },
        TABLEAU: {
            TOP_HALF: `${process.env.BASE_URL}/api/v1/tableau/ad/1`,
            LOWER_LEFT: `${process.env.BASE_URL}/api/v1/tableau/ad/2`,
            LOWER_RIGHT: `${process.env.BASE_URL}/api/v1/tableau/ad/3`,
            TEST: `${process.env.BASE_URL}/api/v1/tableau/ad/4`,
            DATA_TABLE: `${process.env.BASE_URL}/api/v1/tableau/ad/5`,
            MOBILE_TEST: `${process.env.BASE_URL}/api/v1/tableau/ad/6`
        },
        PORTFOLIO: {
            SAVINGS: `${process.env.BASE_URL}/api/v1/portfolio/savings`,
            KPI: `${process.env.BASE_URL}/api/v1/portfolio/performance`,
            INTERVAL: `${process.env.BASE_URL}/api/v1/portfolio/interval`,
            MAP: `${process.env.BASE_URL}/api/v1/portfolio/locations`,
            CHANGE_SITE: `${process.env.BASE_URL}/api/v1/portfolio/changeSite`,
            SITES: `${process.env.BASE_URL}/api/v1/config/sites`,
            UPDATE_SITE: `${process.env.BASE_URL}/api/v1/config/changeSite`,
            BENCHMARK: `${process.env.BASE_URL}/api/v1/portfolio/benchmark`
        },
        PLANT_SETTINGS: {
            MAP: `${process.env.BASE_URL}/admin/v1/sites/maps`,
            SITES: `${process.env.BASE_URL}/admin/v1/sites`,
            STATUS: `${process.env.BASE_URL}/admin/v1/sites/status`,
            GROUPS: `${process.env.BASE_URL}/admin/v1/sites/groups`,
            INCHARGE: `${process.env.BASE_URL}/admin/v1/sites/incharge`,
            MANAGER: `${process.env.BASE_URL}/admin/v1/sites/manager`,
            THRESHOLD: `${process.env.BASE_URL}/admin/v1/range`,
            THRESHOLD_BULK: `${process.env.BASE_URL}/admin/v1/range/bulk`,
            SCHEMATICS: `${process.env.BASE_URL}/admin/v1/schematics`,
            SCHEMATICS_BULK: `${process.env.BASE_URL}/admin/v1/schematics/bulk`,
            BASELINES: `${process.env.BASE_URL}/admin/v1/sites/baselines`,
            TARGETS: `${process.env.BASE_URL}/admin/v1/sites/targets`,
            TARIFFS: `${process.env.BASE_URL}/admin/v1/sites/tariffs`,
            SAVINGS: `${process.env.BASE_URL}/admin/v1/savings`,
            ASSETS: isEdgeUI()
                ? `${process.env.BASE_URL}/admin/v3/assets`
                : `${process.env.BASE_URL}/admin/v1/sites/assets`,
            ASSETS_UPLOAD: isEdgeUI()
                ? `${process.env.BASE_URL}/admin/v3/assets/upload`
                : `${process.env.BASE_URL}/admin/v1/sites/assets/upload`,
            ASSETS_REMOVE: isEdgeUI()
                ? `${process.env.BASE_URL}/admin/v3/assets`
                : `${process.env.BASE_URL}/admin/v1/sites/assets/remove`,
            ASSETS_BULK: `${process.env.BASE_URL}/admin/v3/assets/bulk`,
            PIDDATA: `${process.env.BASE_URL}/admin/v1/pidData`,
            PIDDATA_BULK: `${process.env.BASE_URL}/admin/v1/pidData/bulk`
        },
        MAINTENANCE_SETTINGS: {
            EFFICIENCY_PARAMS_BULK: `${process.env.BASE_URL}/admin/v2/eff/params/bulk`,
            EFFICIENCY_PARAMS: `${process.env.BASE_URL}/admin/v2/eff/params`,
            SAVINGS_OFFSET: `${process.env.BASE_URL}/admin/v2/savings/offset`,
            SAVINGS_OFFSET_BULK: `${process.env.BASE_URL}/admin/v2/savings/offset/bulk`,
            SAVINGS_CONFIG: `${process.env.BASE_URL}/admin/v2/savings/config`,
            MISSING_DATA_MAILER: `${process.env.BASE_URL}/admin/v2/missing/mailer`,
            SITE_CONFIG: `${process.env.BASE_URL}/admin/v2/siteConfig`,
            CURRENCY_CONVERTER: `${process.env.BASE_URL}/admin/v2/currency`,
            CURRENCY_CONVERTER_BULK: `${process.env.BASE_URL}/admin/v2/currency/bulk`
        },
        ADMIN_SETTINGS: {
            ADMIN: `${process.env.BASE_URL}/admin/v1/admins`,
            SITES: `${process.env.BASE_URL}/admin/v1/admins/sites`,
            GROUPS: `${process.env.BASE_URL}/admin/v1/admins/groups`,
            MAINTENANCE: `${process.env.BASE_URL}/admin/v1/maintenance`,
            SESSIONS: `${process.env.BASE_URL}/admin/v1/sessions`,
            VERSION_HISTORY: `${process.env.BASE_URL}/admin/v1/history`
        },
        CHIP: `${process.env.BASE_URL}/api/v1/fault/tags`,
        ANALYTICS: {
            FAULT_TABLE: `${process.env.BASE_URL}/analytics/v1/fault/list`,
            UPDATE_FAULT_TABLE: `${process.env.BASE_URL}/analytics/v1/fault/interval`,
            CHIP: `${process.env.BASE_URL}/analytics/v1/fault/tags`,
            TABLE: `${process.env.BASE_URL}/analytics/v1/fault/chart/CDWR_HDR_T`,
            EDIT: `${process.env.BASE_URL}/analytics/v1/fault/chart`,
            LABEL: `${process.env.BASE_URL}/analytics/v1/fault/label`,
            NOTES: `${process.env.BASE_URL}/analytics/v1/fault/notes`,
            SEVERITY_CHIP: `${process.env.BASE_URL}/analytics/v1/fault/tags/severity`,
            SYS_PLANT_EFF: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/SYS_PLANT_EFF`,
            GROUP_kW: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/GROUP_kW`,
            CDWS_HDR_T: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CDWS_HDR_T`,
            CDWS_HDR_F: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CDWS_HDR_F`,
            CDWR_HDR_T: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CDWR_HDR_T`,
            CHWS_HDR_T: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHWS_HDR_T`,
            CHWS_HDR_F: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHWS_HDR_F`,
            CHWR_HDR_T: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHWR_HDR_T`,
            NOTES_CHIP: `${process.env.BASE_URL}/analytics/v1/fault/tags/notes`,
            USER_TAGS_CHIP: `${process.env.BASE_URL}/analytics/v1/fault/tags/user`,
            OVERVIEW: {
                SYS_PLANT_EFF: `${process.env.BASE_URL}/analytics/v1/overview/chart/SYS_PLANT_EFF`,
                SYS_PLANT_KW_RT: `${process.env.BASE_URL}/analytics/v1/overview/chart/SYS_PLANT_KW_RT`,
                GROUP_KW: `${process.env.BASE_URL}/analytics/v1/overview/chart/GROUP_kW`,
                FAULT: `${process.env.BASE_URL}/analytics/v1/overview/list/fault`,
                LIFECYCLE: `${process.env.BASE_URL}/analytics/v1/overview/chart/ChillerHealthScore`
            },
            SETTINGS: {
                PARAM_CONFIG: `${process.env.BASE_URL}/analytics/v1/config`,
                CHART_MAP: `${process.env.BASE_URL}/analytics/v1/config/chart`,
                BULK_UPLOAD: `${process.env.BASE_URL}/analytics/v1/config/chart/bulk`,
                FAULT_CODE: `${process.env.BASE_URL}/analytics/v1/config/code`,
                NAME_MAPPING: `${process.env.BASE_URL}/analytics/v1/mapping`,
                NAME_MAP_BULK: `${process.env.BASE_URL}/analytics/v1/mapping/bulk`
            },
            CHARTS: {
                CHART: `${process.env.BASE_URL}/analytics/v1/anomaly/chart`,
                CHART_1: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHART_1`
                // CHART_3: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHART_3`,
                // CHART_4: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHART_4`,
                // CHART_5: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHART_5`,
                // CHART_6: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHART_6`,
                // CHART_7: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHART_7`,
                // CHART_8: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHART_8`,
                // CHART_9: `${process.env.BASE_URL}/analytics/v1/anomaly/chart/CHART_9`
            },
            ALARM_CONFIG: {
                TABLE: `${process.env.BASE_URL}/analytics/v1/parameter`,
                CHART: `${process.env.BASE_URL}/analytics/v1/config/chart/`
            },
            GET_CHARTS: `${process.env.BASE_URL}/analytics/v1/fault/list/selected`, // analytics/v1/fault/list/selected/{{code}}/{{parameter}}
            OPTMIZATION: {
                SETPOINT: `${process.env.BASE_URL}/analytics/v1/optimization/setpoint`,
                EFF_CHART: `${process.env.BASE_URL}/analytics/v1/optimization/chart/EFF_CHART`,
                COST_IMPACT: `${process.env.BASE_URL}/analytics/v1/optimization/chart/COST_IMPACT`,
                FET_IMPACT: `${process.env.BASE_URL}/analytics/v1/optimization/chart/FET_IMPACT`,
                ENERGY_LOSS: `${process.env.BASE_URL}/analytics/v1/optimization/chart/ENERGY_LOSS`,
                LOOKUP_COMPLETE: `${process.env.BASE_URL}/analytics/v1/optimization/chart/COMPLETE`,
                LOOKUP_BEST: `${process.env.BASE_URL}/analytics/v1/optimization/chart/BEST`,
                LOOKUP_EFFICIENCY: `${process.env.BASE_URL}/analytics/v1/optimization/chart/EFFICIENCY`
            },
            ANOMALY: {
                DIAGNOSIS_TABLE: `${process.env.BASE_URL}/analytics/v1/diagnosis/list`,
                FEEDBACK: `${process.env.BASE_URL}/analytics/v1/diagnosis/feedback`,
                DIAGNOSIS_CHART: `${process.env.BASE_URL}/analytics/v1/diagnosis/chart/`,
                EQUIPMENT_ENERGY: `${process.env.BASE_URL}/analytics/v1/diagnosis/chart/Energy Consumption (kW)`, // EQPT_KW`,
                EQUIPMENT_EFF: `${process.env.BASE_URL}/analytics/v1/diagnosis/chart/Energy Efficiency (kW%2FRT)`, // EQPT_EFF`,
                DEVIATION: `${process.env.BASE_URL}/analytics/v1/diagnosis/chart/Energy and Efficiency Deviation`, // DEVIATION`,
                DEVIATION_PERCENT: `${process.env.BASE_URL}/analytics/v1/diagnosis/chart/Percentage Deviation (%25)`, // DEVIATION_PERCENT`,
                REASON1: `${process.env.BASE_URL}/analytics/v1/diagnosis/chart/REASON1`,
                REASON2: `${process.env.BASE_URL}/analytics/v1/diagnosis/chart/REASON2`,
                REASON3: `${process.env.BASE_URL}/analytics/v1/diagnosis/chart/REASON3`,
                ALL_PARAMS: `${process.env.BASE_URL}/analytics/v1/diagnosis/chart/All Parameters`, // ALL_PARAMS`,
                UPDATE_DIAGNOSIS_INTERVAL: `${process.env.BASE_URL}/analytics/v1/diagnosis/interval`,
                UPDATE_DIAGNOSIS_SELECTED: `${process.env.BASE_URL}/analytics/v1/diagnosis/selected`,
                GET_EOP_DATA: `${process.env.BASE_URL}/analytics/v1/diagnosis/eop/`
            }
        },
        LIFE_CYCLE: {
            CHART: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart`,
            HEALTH_STATUS: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/HealthStatus`,
            HEALTH_SCORE: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/ChillerHealthScore`,
            // CHILLER_PUMP: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/ChillerPumpHealthScore`,
            // CONDENSOR_PUMP: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CondenserPumpHealthScore`,
            CHILLER: {
                CH_1: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHILLER_1`,
                CH_2: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHILLER_2`,
                CH_3: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHILLER_3`,
                CH_4: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHILLER_4`,
                LIST: `${process.env.BASE_URL}/analytics/v1/lifecycle/list/CHILLER`,
                CHART: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart`,
                CHART_COMBINED: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/combined/CHILLER_COMBINED`
            },
            CHWR_PUMP: {
                PUMP_1: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHWR_PUMP_1`,
                PUMP_2: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHWR_PUMP_2`,
                PUMP_3: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHWR_PUMP_3`,
                PUMP_4: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHWR_PUMP_4`,
                LIST: `${process.env.BASE_URL}/analytics/v1/lifecycle/list/CHWR_PUMP`,
                CHART_COMBINED: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/combined/CHWR_PUMP_COMBINED`
            },
            CHWP: {
                PUMP_1: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHWR_PUMP_1`,
                PUMP_2: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHWR_PUMP_2`,
                PUMP_3: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHWR_PUMP_3`,
                PUMP_4: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CHWR_PUMP_4`,
                LIST: `${process.env.BASE_URL}/analytics/v1/lifecycle/list/CHWP`,
                CHART_COMBINED: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/combined/CHWP_COMBINED`
            },
            CDWS_PUMP: {
                PUMP_1: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CDWS_PUMP_1`,
                PUMP_2: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CDWS_PUMP_2`,
                PUMP_3: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CDWS_PUMP_3`,
                PUMP_4: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CDWS_PUMP_4`,
                LIST: `${process.env.BASE_URL}/analytics/v1/lifecycle/list/CDWS_PUMP`,
                CHART_COMBINED: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/combined/CDWS_PUMP_COMBINED`
            },
            CDWP: {
                PUMP_1: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CDWS_PUMP_1`,
                PUMP_2: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CDWS_PUMP_2`,
                PUMP_3: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CDWS_PUMP_3`,
                PUMP_4: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CDWS_PUMP_4`,
                LIST: `${process.env.BASE_URL}/analytics/v1/lifecycle/list/CDWP`,
                CHART_COMBINED: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/combined/CDWP_COMBINED`
            },
            CT: {
                CT_1: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CT_1`,
                CT_2: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/CT_2`,
                LIST: `${process.env.BASE_URL}/analytics/v1/lifecycle/list/CT`,
                CHART_COMBINED: `${process.env.BASE_URL}/analytics/v1/lifecycle/chart/combined/CT_COMBINED`
            }
        },
        PLANT_SCHEMATIC: {
            GET_EQUIPMENT: `${process.env.BASE_URL}/api/v1/schematics/equipments`,
            GET_IMAGE: `${process.env.BASE_URL}/api/v1/schematics/diagram`,
            PID_DATA: `${process.env.BASE_URL}/api/v1/pid/data`
        },
        SITE_ONBOARDING: {
            ONBOARDING_LIST: `${process.env.BASE_URL}/admin/v1/onboarding`,
            MAPPING_TABLE: `${process.env.BASE_URL}/admin/v1/onboarding/mapping`,
            MAPPING_BULK: `${process.env.BASE_URL}/admin/v1/onboarding/mapping/bulk`
        },
        REPORT: {
            CHART: `${process.env.BASE_URL}/api/v1/reports/chart`,
            SITE_LIST: `${process.env.BASE_URL}/api/v1/reports/list/SITE_REPORT`,
            DYNAMIC: `${process.env.BASE_URL}/api/v1/reports/dynamic`,
            WATER_TREATMENT_SCHEMATICS: `${process.env.BASE_URL}/api/v1/reports/chart/dynamic/WATER_TREATMENT`,
            WATER_TREATMENT_DASHBOARD1: `${process.env.BASE_URL}/api/v1/reports/chart/dynamic/WATER_TREATMENT_DASHBOARD1`,
            WATER_TREATMENT_DASHBOARD2: `${process.env.BASE_URL}/api/v1/reports/chart/dynamic/WATER_TREATMENT_DASHBOARD2`,
            WATER_TREATMENT_REPORT: `${process.env.BASE_URL}/api/v1/reports/chart/dynamic/WATER_TREATMENT_REPORT`
        },
        VERSION: {
            HISTORY: `${process.env.BASE_URL}/admin/v1/history`,
            SITES: `${process.env.BASE_URL}/admin/v1/history/sites`
        },
        SYSTEM: {
            MONITORING: `${process.env.BASE_URL}/admin/v1/monitoring`
        },
        DWH: {
            CONFIG: `${process.env.BASE_URL}/api/v1/dwh/config`,
            SITE_DETAILS: `${process.env.BASE_URL}/api/v1/dwh/siteDetails`,
            SITE_WATER_PUMP: `${process.env.BASE_URL}/api/v1/dwh/siteWaterPump`,
            SITE_COOLING_TOWER: `${process.env.BASE_URL}/api/v1/dwh/siteCoolingTower`,
            SITE_PID: `${process.env.BASE_URL}/api/v1/dwh/sitePid`,
            SITE_INSTRUMENT: `${process.env.BASE_URL}/api/v1/dwh/siteInstrument`,
            SITE_CHILLER: `${process.env.BASE_URL}/api/v1/dwh/siteChiller`,
            SITE_ENVIRONMENT: `${process.env.BASE_URL}/api/v1/dwh/siteEnvironment`,
            SCADA_DETAILS: `${process.env.BASE_URL}/api/v1/dwh/scadaDetails`,
            CHILLER_SPEC: `${process.env.BASE_URL}/api/v1/dwh/chillerSpec`,
            CHILLER_PARTLOAD: `${process.env.BASE_URL}/api/v1/dwh/chillerPartload`,
            WATERPUMP_SPEC: `${process.env.BASE_URL}/api/v1/dwh/waterPumpSpec`,
            WATERPUMP_CURVE: `${process.env.BASE_URL}/api/v1/dwh/waterPumpCurve`,
            PID_TIME_BASED: `${process.env.BASE_URL}/api/v1/dwh/pidTimeBased`,
            PID_PRESSURE_BASED: `${process.env.BASE_URL}/api/v1/dwh/pidPressureBased`,
            PID_LOAD_BASED: `${process.env.BASE_URL}/api/v1/dwh/pidLoadBased`,
            PID_TEMP_BASED: `${process.env.BASE_URL}/api/v1/dwh/pidTempBased`,
            COOLING_TOWER_SPEC: `${process.env.BASE_URL}/api/v1/dwh/coolingTowerSpec`
        },
        REPORTING: {
            MISSING_DATA: `${process.env.BASE_URL}/admin/v2/missing/report`
        },
        ML: {
            MODBUS_ENABLE_ML: `${process.env.BASE_URL}/ml/v1/modbus/ml`,
            MODBUS_HEARTBEAT: `${process.env.BASE_URL}/ml/v1/modbus/hb`,
            PLC_HEARTBEAT: `${process.env.BASE_URL}/ml/v1/modbus/plc-hb`,
            SETPOINT_DATA: `${process.env.BASE_URL}/ml/v1/setpoint/data`,
            SETPOINT_STATUS: `${process.env.BASE_URL}/ml/v1/setpoint/status`,
            SETPOINT_RECOMMENDED: `${process.env.BASE_URL}/ml/v1/setpoint/recommended`,
            AIRFLOW: `${process.env.BASE_URL}/ml/v1/airflow`,
            TRAIN: `${process.env.BASE_URL}/ml/v1/train`,
            PREDICT: `${process.env.BASE_URL}/ml/v1/predict`
        },
        ML_SETTINGS: {
            ML_SETTINGS: `${process.env.BASE_URL}/api/v1/mlSetting`,
            AIRFLOW: `${process.env.BASE_URL}/api/v1/airflow`,
            SITE_DAG: `${process.env.BASE_URL}/api/v1/mlDag`
        },
        RULE_OPTMIZATION: {
            CT_CAPACITY: `${process.env.BASE_URL}/admin/v1/ruleOptimization/ct/capacity`,
            CT_SETPOINT: `${process.env.BASE_URL}/admin/v1/ruleOptimization/ct/setpoint`,
            CDWP_CONFIG: `${process.env.BASE_URL}/admin/v1/ruleOptimization/cdwp/config`,
            CDWP_MIN_MAX_LOAD: `${process.env.BASE_URL}/admin/v1/ruleOptimization/cdwp/minMaxLoad`,
            CDWP_MIN_MAX_TIME: `${process.env.BASE_URL}/admin/v1/ruleOptimization/cdwp/minMaxTime`,
            CHWP_CONFIG: `${process.env.BASE_URL}/admin/v1/ruleOptimization/chwp/config`,
            CHWP_MIN_MAX_LOAD: `${process.env.BASE_URL}/admin/v1/ruleOptimization/chwp/minMaxLoad`,
            CHWP_MIN_MAX_TIME: `${process.env.BASE_URL}/admin/v1/ruleOptimization/chwp/minMaxTime`
        },
        AUDITLOG: {
            DATA: `${process.env.BASE_URL}/admin/v1/auditLogs`
        }
    },
    TABLEAU: {
        EMBED_API_V3_URL: process.env.TABLEAU_EMBED_API_V3_URL
    },
    BBP: {
        MAIN: process.env.BBP_URL,
        DASHBOARD: process.env.DASHBOARD_URL,
        ASSET_URL: `${process.env.BASE_URL}/assets/v3`
    },
    STREAM_URL: `${process.env.BASE_URL}/events/stream`
};

export default url;
