/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect } from "react";

import useTableau from "../../../hooks/useTableau";

import SiteOverviewChartV2 from "./SiteOverviewChartV2";
import SiteOverviewChartV3 from "./SiteOverviewChartV3";
import SiteOverviewChartDefault from "./SiteOverviewChartDefault";

const SiteOverviewChart = props => {
    const { url, ...chartProps } = props;

    const {
        fetchChart,
        tableauVersion,
        tableauName,
        tableauLink,
        tableauToken,
        tableauOptions
    } = useTableau();

    const fetchSiteChart = useCallback(
        dispose => {
            const themeState = localStorage.dark === "true" ? "DARK" : "LIGHT";
            const siteChartUrl = `${url}/${themeState}`;
            fetchChart(siteChartUrl, dispose);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [url],
    );

    useEffect(() => {
        fetchSiteChart();
    }, [fetchSiteChart]);

    switch (tableauVersion) {
        case 2:
            return (
                <SiteOverviewChartV2
                  tableauLink={tableauLink}
                  tableauName={tableauName}
                  tableauOptions={tableauOptions}
                  {...props}
                />
            );
        case 3:
            return (
                <SiteOverviewChartV3
                  tableauName={tableauName}
                  tableauLink={tableauLink}
                  tableauToken={tableauToken}
                  tableauOptions={tableauOptions}
                  tableauRefresh={() => fetchSiteChart(true)}
                  {...chartProps}
                />
            );
        default:
    }

    return (
        <SiteOverviewChartDefault
          overview={props.overview}
          combined={props.combined}
          tall={props.tall}
        />
    );
};

export default SiteOverviewChart;
