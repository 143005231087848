import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Select, FormControl, MenuItem, InputBase } from "@material-ui/core";
// import NativeSelect from "@material-ui/core/NativeSelect";

const Dropdown = props => {
    const { value, options, onChange, placeholder, className } = props;
    const BootstrapInput = withStyles(theme => ({
        input: {
            fontSize: 14,
            color:
                theme.palette.type === "dark"
                    ? theme.palette.text.grey
                    : theme.palette.text.light_grey,
            // color: theme.palette.type === "dark" ? "#ACACAC" : "#707070",
            paddingLeft: "9px",
            paddingTop: "11px",
            fontWeight: 500
        }
    }))(InputBase);

    return (
        <div>
            <FormControl variant="outlined">
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  label={placeholder}
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                  className={className}
                  input={<BootstrapInput />}
                >
                    {options.map(option => {
                        return (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default Dropdown;
