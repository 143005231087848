/* eslint-disable max-len */
const mlSettingsOptions = [
    {
        name: "LOCKING_DURATION",
        description:
            "Duration in seconds to lock the button, after setpoint has been enabled/disabled",
        type: "number",
        defaultValue: 15
    },
    {
        name: "POOLING_INTERVAL_LOCKING",
        description:
            "Interval in seconds to periodically check the (SCADA) status, after setpoint (CLOUD) is enabled/disabled",
        type: "number",
        defaultValue: 5
    },
    {
        name: "POOLING_INTERVAL_MAIN",
        description:
            "Interval in seconds to periodically check Heartbeat, Enable ML status, and Setpoint status",
        type: "number",
        defaultValue: 60
    }
];

const getDefaultMLSettings = () => {
    return mlSettingsOptions.reduce((acc, setting) => {
        acc[setting.name] = setting.defaultValue;
        return acc;
    }, {});
};

module.exports = {
    mlSettingsOptions,
    getDefaultMLSettings
};
