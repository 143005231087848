const ReportActionTypes = {
    SET_LOADING: "SET_LOADING",
    SET_ERROR: "SET_ERROR",
    SET_SITE_REPORT: "SET_SITE_REPORT",
    SET_DYNAMIC_REPORT_DATA: "SET_DYNAMIC_REPORT_DATA",
    SET_DYNAMIC_REPORT_SET_TABS: "SET_DYNAMIC_REPORT_SET_TABS",
    SET_DYNAMIC_REPORT_ADD_TAB: "SET_DYNAMIC_REPORT_ADD_TAB",
    SET_DYNAMIC_REPORT_UPDATE_TAB: "SET_DYNAMIC_REPORT_UPDATE_TAB",
    SET_DYNAMIC_REPORT_REMOVE_TAB: "SET_DYNAMIC_REPORT_REMOVE_TAB",
    SET_DYNAMIC_REPORT_SELECTED_TAB: "SET_DYNAMIC_REPORT_SELECTED_TAB",
    SET_DYNAMIC_REPORT_CHART: "SET_DYNAMIC_REPORT_CHART"
};

export default ReportActionTypes;
