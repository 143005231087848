import React, { useState } from "react";
import { Switch, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ICONS from "../../../../utils/enums/IconData";

import usePlatform from "../../../../hooks/usePlatform";

const SwitchPlatform = ({ permission, open = true }) => {
    const [state, setState] = useState({
        checked: localStorage.getItem("platform") !== "analytics"
    });

    const { changeToAnalytics, changeToDwh, platform } = usePlatform();

    const togglePlatform = event => {
        const { checked } = event.target;
        setState({ ...state, [event.target.name]: checked });
        if (checked) {
            changeToDwh();
        } else {
            changeToAnalytics();
        }
    };

    const useStyles = makeStyles(theme => ({
        container: {
            marginBottom: theme.spacing(1)
        },
        switch: {
            marginBottom: "0 !important"
        },
        icon: {
            filter: "brightness(0.3) sepia(1) hue-rotate(180deg) saturate(6)"
        }
    }));
    const classes = useStyles();

    const canSwitchPlatform =
        ["admin_access", "asset_access", "developer_access"].indexOf(permission) > -1;
    if (!canSwitchPlatform) {
        return null;
    }

    const showSelectedPlatform = () => {
        if (platform === "dwh") {
            return <img src={ICONS.HOME} alt="warehouse" className={classes.icon} />;
        }
        return <img src={ICONS.ALGORITHM} alt="analytics" className={classes.icon} />;
    };

    return (
        <Grid container justifyContent="center" className={classes.container}>
            {open ? (
                <img
                  src={ICONS.ALGORITHM}
                  alt="analytics"
                  className={platform === "analytics" ? classes.icon : null}
                />
            ) : (
                showSelectedPlatform()
            )}
            <Switch
              size="small"
              checked={state.checked}
              onChange={togglePlatform}
              color="primary"
              name="checked"
              className={classes.switch}
            />
            {open && (
                <img
                  src={ICONS.HOME}
                  alt="warehouse"
                  className={platform === "dwh" ? classes.icon : null}
                />
            )}
        </Grid>
    );
};

export default SwitchPlatform;
