import AnalyticsActionTypes from "./AnalyticsActionTypes";

const initialState = {
    tableData: [],
    analyticChip: "",
    severity: "",
    notes: [],
    notesChip: "",
    userTagsChip: "",
    chartData: {
        charts: {
            details: [],
            overview: []
        },
        code: "",
        parameter: ""
    },
    // chart_position: "",
    statusOfFilter: false,
    alarmConfig: []
};

const AnalyticReducer = (state = initialState, action) => {
    switch (action.type) {
        case AnalyticsActionTypes.GET_FAULTTABLE:
            return {
                ...state,
                tableData: action.data,
                statusOfFilter: action.filterType
            };
        case AnalyticsActionTypes.UPDATE_FAULTTABLE:
            return {
                ...state
            };
        case AnalyticsActionTypes.SET_ANALYTICS_CHIPS:
            return {
                ...state,
                analyticChip: action.data
            };
        case AnalyticsActionTypes.SET_LABEL:
            return {
                ...state
                // analyticLabel: action.data
            };
        case AnalyticsActionTypes.SET_CHIP_SEVERITY:
            return {
                ...state,
                severity: action.data
            };
        case AnalyticsActionTypes.SET_NOTES:
            return {
                ...state,
                notes: action.data
            };
        case AnalyticsActionTypes.SET_NOTES_CHIPS:
            return {
                ...state,
                notesChip: action.data
            };
        case AnalyticsActionTypes.SET_USERTAGS_CHIPS:
            return {
                ...state,
                userTagsChip: action.data
            };
        case AnalyticsActionTypes.GET_CHART_POSITION:
            return {
                ...state,
                chartData: action.data
            };
        case AnalyticsActionTypes.SET_ALARM_CONFIG:
            return {
                ...state,
                alarmConfig: action.data
            };
        default:
            return state;
    }
};

export default AnalyticReducer;
