/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Switch,
    FormControlLabel,
    Grid
} from "@material-ui/core";
import { connect } from "react-redux";
import TextInput from "./TextInput";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";
import DropdownSelect from "./DropdownSelect";

const EditAdminTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick, authentication } = props;
    const [error, setError] = useState("");

    const emptyConfig = {
        email: VERBIAGE.TEXT.EMPTY_STRING,
        publicName: VERBIAGE.TEXT.EMPTY_STRING,
        username: VERBIAGE.TEXT.EMPTY_STRING,
        permissions: VERBIAGE.TEXT.EMPTY_STRING,
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        groupName: VERBIAGE.TEXT.EMPTY_STRING,
        receiveEmail: "NO",
        status: "ENABLED" // VERBIAGE.TEXT.EMPTY_STRING
    };

    const [isGroup, setIsGroup] = useState(false);

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            const rowData = {
                ...config,
                ...data
            };
            if (!rowData.siteId) {
                rowData.siteId = VERBIAGE.TEXT.EMPTY_STRING;
            }
            if (!rowData.groupName) {
                rowData.groupName = VERBIAGE.TEXT.EMPTY_STRING;
            }
            updateConfig(rowData);
        },
        getConfig() {
            return config;
        }
    }));

    const [siteOptions, setSiteOptions] = useState([]);
    // const [siteList, setSiteList] = useState([]);

    const [groupOptions, setGroupOptions] = useState([]);
    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const siteResponse = await api.get(URL.DATA.ADMIN_SETTINGS.SITES, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (isMounted) {
                    // setSiteList(siteResponse.data);
                    setSiteOptions(siteResponse.data.map(row => row.siteId));
                }
                const groupResponse = await api.get(URL.DATA.ADMIN_SETTINGS.GROUPS, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (isMounted) {
                    setGroupList(groupResponse.data);
                    setGroupOptions(groupResponse.data.map(row => row.groupName));
                    setError(VERBIAGE.ERROR_TEXT.EMPTY_STRING);
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        fetchData();
        if (isMounted) {
            setIsGroup(false);
            if (config.groupName) {
                // } && isEdit) {
                setIsGroup(true);
            }
        }

        return () => (isMounted = false);
    }, [config.groupName, isEdit, open]);

    const handleEmailChange = event => {
        updateConfig({ ...config, email: event.target.value });
    };
    const handleNameChange = event => {
        updateConfig({ ...config, publicName: event.target.value });
    };
    const handleUserNameChange = event => {
        updateConfig({ ...config, username: event.target.value });
    };
    const handlePermissionsChange = event => {
        updateConfig({ ...config, permissions: event.target.value });
    };
    const handleSiteIDChange = event => {
        updateConfig({ ...config, siteId: event.target.value });
    };
    const handleGroupChange = event => {
        updateConfig({ ...config, groupName: event.target.value });
    };
    const handleStatusChange = event => {
        updateConfig({ ...config, status: event.target.value });
    };
    const handleReceiveEmailChange = event => {
        updateConfig({ ...config, receiveEmail: event.target.value });
    };
    const toggleSwitch = event => {
        setIsGroup(event.target.checked);
    };

    const handleSubmit = async () => {
        let groupId;
        groupList.forEach(group => {
            if (group.groupName === config.groupName) {
                groupId = group.groupId;
            }
        });
        if (isEdit) {
            if (
                !config.publicName ||
                !config.email ||
                !config.permissions ||
                !config.receiveEmail ||
                !config.status ||
                (!config.siteId && !isGroup) ||
                (!config.groupName && isGroup)
            ) {
                setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
                return;
            }
            // if (authentication.currentUser !== "root") {
            //     setError(VERBIAGE.ERROR_TEXT.PERMISSION);
            //     return;
            // }
            const data = {
                email: config.email,
                publicName: config.publicName,
                permissions: config.permissions,
                receiveEmail: config.receiveEmail,
                status: config.status,
                siteId: isGroup ? "" : config.siteId,
                groupId: isGroup ? groupId : ""
            };
            const url = `${URL.DATA.ADMIN_SETTINGS.ADMIN}/${config.userId}`;
            try {
                const response = await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                } else {
                    setOpen(false);
                }
            } catch (err) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        } else if (!VERBIAGE.TEXT.EMAIL_REGEX.test(config.email)) {
            setError(VERBIAGE.ERROR_TEXT.EMAIL_INVALID);
        } else {
            if (
                !config.email ||
                !config.publicName ||
                !config.username ||
                !config.permissions ||
                (!config.siteId && !config.groupName) ||
                !config.receiveEmail ||
                !config.status
            ) {
                setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
                return;
            }
            const data = {
                ...config,
                siteId: isGroup ? "" : config.siteId,
                groupId: isGroup ? groupId : ""
            };
            const url = URL.DATA.ADMIN_SETTINGS.ADMIN;
            try {
                const response = await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                } else {
                    setOpen(false);
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(VERBIAGE.ERROR_TEXT.USER_EXISTS);
            }
        }
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        },
        root: {
            margin: "0px !important"
        }
    }));

    const classes = useStyles();

    return (
      <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
        <DialogTitle>
          {`To ${isEdit ? "edit" : "add to"} the admin list, please ${
                    isEdit ? "update" : "enter"
                } the details below.`}
        </DialogTitle>
        <DialogContent>
          <div className={EditAdminTable.styles.inputContainer}>
            <TextInput
              onChange={event => handleEmailChange(event)}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.EMAIL}
              value={config.email}
              type="email"
            />
          </div>
          <div className={EditAdminTable.styles.inputContainer}>
            <TextInput
              onChange={event => handleNameChange(event)}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.NAME}
              value={config.publicName}
              type="text"
            />
          </div>
          {/* {!isEdit && ( */}
          <div>
            <div className={EditAdminTable.styles.inputContainer}>
              <TextInput
                onChange={event => handleUserNameChange(event)}
                placeholder={VERBIAGE.PLACEHOLDER_TEXT.USERNAME}
                value={config.username}
                disabled={isEdit}
                type="text"
              />
            </div>
          </div>
          {/* )} */}
          <div className={EditAdminTable.styles.inputContainer}>
            <DropdownSelect
              name={config.status}
              options={VERBIAGE.TEXT.STATUS_OPTIONS}
              onChange={handleStatusChange}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_STATUS}
            />
          </div>
          <div className={EditAdminTable.styles.inputContainer}>
            <DropdownSelect
              name={config.permissions}
              options={VERBIAGE.TEXT.PERMISSIONS}
              onChange={handlePermissionsChange}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.PERMISSIONS}
              closeMenuOnSelect
            />
          </div>
          <div className={EditAdminTable.styles.inputContainer}>
            <Typography component="div">
              <Grid
                component="label"
                container
                justify="space-around"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4}>
                  {VERBIAGE.PLACEHOLDER_TEXT.SITE}
                </Grid>
                <Grid item xs={4}>
                  <Switch
                    checked={isGroup}
                    onChange={toggleSwitch}
                    classes={{
                                        root: classes.root
                                    }}
                    color="primary"
                    name="isGroup"
                  />
                </Grid>
                <Grid item xs={4}>
                  {VERBIAGE.PLACEHOLDER_TEXT.SELECT_GROUP}
                </Grid>
              </Grid>
            </Typography>
          </div>
          {!isGroup && (
            <div className={EditAdminTable.styles.inputContainer}>
              <DropdownSelect
                name={config.siteId}
                options={siteOptions}
                onChange={handleSiteIDChange}
                placeholder={VERBIAGE.PLACEHOLDER_TEXT.SITE}
              />
            </div>
                )}
          {isGroup && (
            <div className={EditAdminTable.styles.inputContainer}>
              <DropdownSelect
                name={config.groupName}
                options={groupOptions}
                onChange={handleGroupChange}
                placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_GROUP}
              />
            </div>
                )}
          <div className={EditAdminTable.styles.inputContainer}>
            <DropdownSelect
              name={config.receiveEmail}
              options={VERBIAGE.TEXT.BOOLEAN_OPTIONS}
              onChange={handleReceiveEmailChange}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_RECEIVE_EMAIL}
            />
          </div>
          {!!error && (
            <div className={classes.error}>
              <Typography>{error}</Typography>
            </div>
                )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleSubmit}>
            {VERBIAGE.BUTTONS.SUBMIT}
          </Button>
          <Button
            className={classes.error}
            onClick={() => {
                        setError(VERBIAGE.TEXT.EMPTY_STRING);
                        setOpen(false);
                    }}
          >
            {VERBIAGE.BUTTONS.CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    );
});

const mapStateToProps = state => ({
    authentication: state.authentication
});

EditAdminTable.styles = {
    inputContainer: "webclient__mapTable-input",
    errorText: "webclient__mapTable-error-text"
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(EditAdminTable);
