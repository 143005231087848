/* eslint-disable max-len */
import PortfolioActionTypes from "./PortfolioActionTypes";
import api from "../../utils/API";
import URL from "../../utils/URL";
// import PlantSActions from "../redux_PlantSchematic/PlantSActions";
// import REFRESH_TIMER from '../../utils/enums/Enum';
import AuthenticationActions from "../../authentication/redux/AuthenticationActions";
// import HomeActions from "../features/redux/HomeActions";

const config = {
    headers: {
        // "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        "x-auth-token": ""
    }
};

function constructHeader() {
    const token = localStorage.getItem("token");

    config.headers["x-auth-token"] = `${token}`;
    return config;
}

const PortfolioActions = {
    setPortfolioSavings: data => ({
        type: PortfolioActionTypes.SET_PORTFOLIOSAVINGS,
        data
    }),
    fetchPortfolioSavings: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.PORTFOLIO.SAVINGS, config)
            .then(response => {
                dispatch(PortfolioActions.setPortfolioSavings(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setPortfolioKpi: data => ({
        type: PortfolioActionTypes.SET_KPI,
        data
    }),
    fetchPortfolioKpi: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.PORTFOLIO.KPI, config)
            .then(response => {
                dispatch(PortfolioActions.setPortfolioKpi(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setInterval: data => ({
        type: PortfolioActionTypes.SET_PORTFOLIOINTERVAL,
        data
    }),
    updatePortfolioInterval: interval => dispatch => {
        constructHeader();
        api.put(
            URL.DATA.PORTFOLIO.INTERVAL,
            {
                interval: interval.number,
                intervalType: interval.intervalType
            },
            config,
        )
            .then(() => {
                // dispatch(PortfolioActions.fetchPortfolioSavings());
                // dispatch(PortfolioActions.fetchPortfolioKpi());
                dispatch(PortfolioActions.fetchPortfolioBenchmarks());
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setPortfolioMap: data => ({
        type: PortfolioActionTypes.SET_MAP,
        data
    }),
    fetchPortfolioMap: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.PORTFOLIO.MAP, config)
            .then(response => {
                dispatch(PortfolioActions.setPortfolioMap(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setPortfolioChangeSite: data => ({
        type: PortfolioActionTypes.SET_CHANGE_SITE,
        data
    }),
    fetchPortfolioChangeSite: credentials => dispatch => {
        if (localStorage.getItem("userType") === "null") {
            dispatch("");
        }
        constructHeader();
        api.put(URL.DATA.PORTFOLIO.CHANGE_SITE, credentials.requestBody.siteId, config)
            .then(response => {
                dispatch(PortfolioActions.setPortfolioChangeSite(response.data));
                dispatch(PortfolioActions.fetchPortfolioSavings());
                dispatch(PortfolioActions.fetchPortfolioKpi());
                dispatch(PortfolioActions.fetchPortfolioBenchmarks());
                dispatch(AuthenticationActions.handleChangeSite(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setToggleOption: data => ({
        type: PortfolioActionTypes.SET_TOGGLE_SIDEBAR,
        data
    }),
    setSites: data => dispatch => {
        const activeSite = localStorage.getItem("activeSite");
        const siteStatus = localStorage.getItem("siteStatus");
        const foundSite = data.find(site => site.siteId === activeSite);
        if (foundSite && siteStatus && foundSite.siteStatus !== siteStatus) {
            localStorage.setItem("siteStatus", foundSite.siteStatus);
        }
        dispatch({
            type: PortfolioActionTypes.SET_SITES,
            data
        });
    },
    fetchSites: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.PORTFOLIO.SITES, config)
            .then(response => {
                dispatch(PortfolioActions.setSites(response.data));
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setUpdateChangeSite: data => ({
        type: PortfolioActionTypes.UPDATE_SITES,
        data
    }),
    fetchUpdateChangeSite: credentials => dispatch => {
        constructHeader();
        api.put(URL.DATA.PORTFOLIO.UPDATE_SITE, credentials.requestBody.siteId, config)
            .then(response => {
                dispatch(PortfolioActions.setUpdateChangeSite(response.data));
                dispatch(AuthenticationActions.handleChangeSite(response.data));
                // REFRESH_TIMER();
                // TBD :  call dispatch for all components
                // dispatch(HomeActions.fetchSavings());
                // dispatch(HomeActions.fetchChillerGauge());
                // dispatch(HomeActions.getSystemOverview());
                // dispatch(HomeActions.fetchPlantChart());

                // dispatch(PortfolioActions.fetchPortfolioSavings());
                // dispatch(PortfolioActions.fetchPortfolioKpi());
                // dispatch(PortfolioActions.fetchPortfolioMap());
                // dispatch(PlantSActions.getEquipment());
                // dispatch(PlantSActions.getImage());
                window.location.reload();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setPortfolioBenchmarks: data => ({
        type: PortfolioActionTypes.SET_PORTFOLIO_BENCHMARKS,
        data
    }),
    fetchPortfolioBenchmarks: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.PORTFOLIO.BENCHMARK, config)
            .then(response => {
                dispatch(PortfolioActions.setPortfolioBenchmarks(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    }
};

export default PortfolioActions;
