/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */

import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "./styles/dashboard.scss";
// import FetchData from "./features/home/FetchData";
// import Report from "./features/home/Report";
// import ChillerObserver from "./features/home/ChillerObserver";
import SiteOverview from "./features/home/SiteOverview";
// import LinkView from "./features/home/LinkView";
import PortfolioView from "./features/home/PortfolioView";
// import Onboarding from "./features/home/Onboarding";
import PlantSettings from "./features/home/PlantSettings";
import MaintenanceSettings from "./features/home/MaintenanceSettings";
// import { ChangePassword } from "./common/index";
import { VERBIAGE } from "../utils/enums/index";
import PlantSchematic from "./features/home/PlantSchematic";
import AnalyticsTabSettings from "./common/Analytics/AnalyticsTabSettings";
import AnalyticsSettings from "./features/home/AnalyticsSettings";
import AdminSettings from "./features/home/AdminSettings";
import { ChangePassword } from "./common";
import PIDView from "./features/home/PIDView";
// import ReportView from "./features/home/ReportView";
import ReportsView from "./features/home/ReportsView";
import WaterTreatmentView from "./features/home/WaterTreatmentView";
import WaterTreatmentDashboard1 from "./features/home/WaterTreatmentDashboard1";
import WaterTreatmentDashboard2 from "./features/home/WaterTreatmentDashboard2";
import WaterTreatmentReport from "./features/home/WaterTreatmentReport";
import Monitoring from "./features/home/Monitoring";
import SiteOnboarding from "./features/home/SiteOnboarding";
import AppVersion from "./features/home/AppVersion";
import Reporting from "./features/home/Reporting";
import MLSettings from "./features/home/MLSettings";
import DataWarehouseContainer from "../dwh/DataWarehouse";

const DashboardContainer = () => {
    const isLogin = localStorage.getItem("isUserAuthenticated");
    if (isLogin === null) {
        window.location.href = "/";
    }
    if (window.innerWidth < 1000) {
        localStorage.setItem("open", false);
    } else {
        localStorage.setItem("open", true);
    }
    return (
        <div>
            <Router>
                <div>
                    {/* <Route
              path={VERBIAGE.DASHBOARD_PATH.CHANGE_PASSWORD}
              component={ChangePassword}
            /> */}
                    {/* <Route path={VERBIAGE.DASHBOARD_PATH.FETCH_DATA} component={FetchData} />
            <Route path={VERBIAGE.DASHBOARD_PATH.ALGORITHM} component={ChillerObserver} /> */}
                    <Route path={VERBIAGE.DASHBOARD_PATH.SITE_OVERVIEW} component={SiteOverview} />
                    {/* <Route path={VERBIAGE.DASHBOARD_PATH.LINK_VIEW} component={LinkView} /> */}
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.PORTFOLIO_VIEW}
                      component={PortfolioView}
                    />
                    {/*
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.ONBOARDING} 
                      component={Onboarding} 
                    /> */}
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.PLANT_SETTINGS}
                      component={PlantSettings}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.MAINTENANCE_SETTINGS}
                      component={MaintenanceSettings}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.ANALYTICS_OVERVIEW}
                      component={AnalyticsTabSettings}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.PLANT_OVERVIEW}
                      component={PlantSchematic}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.ANALYTICS_SETTINGS}
                      component={AnalyticsSettings}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.ADMIN_SETTINGS}
                      component={AdminSettings}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.SITE_ONBOARDING}
                      component={SiteOnboarding}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.CHANGE_PASSWORD}
                      component={ChangePassword}
                    />
                    <Route path={VERBIAGE.DASHBOARD_PATH.PID} component={PIDView} />
                    <Route path={VERBIAGE.DASHBOARD_PATH.REPORTS} component={ReportsView} />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.WATER_TREATMENT_SCHEMATICS}
                      component={WaterTreatmentView}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.WATER_TREATMENT_DASHBOARD1}
                      component={WaterTreatmentDashboard1}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.WATER_TREATMENT_DASHBOARD2}
                      component={WaterTreatmentDashboard2}
                    />
                    <Route
                      path={VERBIAGE.DASHBOARD_PATH.WATER_TREATMENT_REPORT}
                      component={WaterTreatmentReport}
                    />
                    <Route path={VERBIAGE.DASHBOARD_PATH.MONITORING} component={Monitoring} />
                    <Route path={VERBIAGE.DASHBOARD_PATH.REPORTING} component={Reporting} />
                    <Route path={VERBIAGE.DASHBOARD_PATH.ML_SETTINGS} component={MLSettings} />
                    <Route path={VERBIAGE.DASHBOARD_PATH.APP_VERSION} component={AppVersion} />
                    <Route path="/dwh" component={DataWarehouseContainer} />
                </div>
            </Router>
        </div>
    );
};

DashboardContainer.styles = {
    content: "nos-ds__dashboard-content"
};

export default DashboardContainer;
