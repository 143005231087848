import Dolllars from "../../../assets/Cash Stack.svg";
import Energy from "../../../assets/battery-charging.svg";
import Carbon from "../../../assets/Leaf.svg";
import Water from "../../../assets/droplet.svg";
import Thermometer from "../../../assets/Thermometer.svg";
import Unit from "../../../assets/ac_unit_24px.svg";
import Cash from "../../../assets/Cash.svg";
import Battery from "../../../assets/Energy.svg";
import Bulb from "../../../assets/lightbulb_24px.svg";
import logo from "../../../assets/BCA.svg";

const IMAGE = {
    cash_stack: Dolllars,
    "battery-charging": Energy,
    leaf: Carbon,
    droplet: Water,
    lightbulb: Bulb,
    thermometer: Thermometer,
    ac_unit: Unit,
    cash: Cash,
    energy: Battery,
    BCA: logo
};
export default IMAGE;
