import RuleBasedOptimizationCDWPActionTypes from "./RuleBasedOptimizationCDWPActionTypes";

import API from "../../utils/API";
import URL from "../../utils/URL";

// TODO: use interceptor to send auth token header
function getRequestConfig(options = {}) {
    const token = localStorage.getItem("token");
    return {
        timeout: 30000,
        headers: {
            "x-auth-token": token
        },
        ...options
    };
}

export const RuleBasedOptimizationCDWPConfigActions = {
    init: payload => ({
        type: RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_CONFIG,
        payload: { loading: true, error: null, ...payload }
    }),
    setError: error => ({
        type: RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_CONFIG,
        payload: { loading: false, error }
    }),
    setData: data => ({
        type: RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_CONFIG_DATA,
        payload: { loading: false, data }
    }),
    fetch: () => dispatch => {
        dispatch(RuleBasedOptimizationCDWPConfigActions.init());
        API.get(URL.DATA.RULE_OPTMIZATION.CDWP_CONFIG, getRequestConfig())
            .then(({ data }) => dispatch(RuleBasedOptimizationCDWPConfigActions.setData(data)))
            .catch(error =>
                dispatch(RuleBasedOptimizationCDWPConfigActions.setError(error.message)),
            );
    },
    save: data => dispatch => {
        dispatch(RuleBasedOptimizationCDWPConfigActions.init());
        API.post(URL.DATA.RULE_OPTMIZATION.CDWP_CONFIG, data, getRequestConfig())
            .then(() => dispatch(RuleBasedOptimizationCDWPConfigActions.fetch()))
            .catch(error =>
                dispatch(RuleBasedOptimizationCDWPConfigActions.setError(error.message)),
            );
    }
};

export const RuleBasedOptimizationCDWPMinMaxLoadActions = {
    init: payload => ({
        type: RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_LOAD,
        payload: { loading: true, error: null, ...payload }
    }),
    setError: error => ({
        type: RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_LOAD,
        payload: { loading: false, error }
    }),
    setData: data => ({
        type: RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_LOAD_DATA,
        payload: { loading: false, data }
    }),
    fetch: () => dispatch => {
        dispatch(RuleBasedOptimizationCDWPMinMaxLoadActions.init());
        API.get(URL.DATA.RULE_OPTMIZATION.CDWP_MIN_MAX_LOAD, getRequestConfig())
            .then(({ data }) => dispatch(RuleBasedOptimizationCDWPMinMaxLoadActions.setData(data)))
            .catch(error =>
                dispatch(RuleBasedOptimizationCDWPConfigActions.setError(error.message)),
            );
    },
    delete: id => dispatch => {
        dispatch(RuleBasedOptimizationCDWPMinMaxLoadActions.init({ data: [] }));
        const url = `${URL.DATA.RULE_OPTMIZATION.CDWP_MIN_MAX_LOAD}/${id}`;
        API.delete(url, getRequestConfig())
            .then(() => dispatch(RuleBasedOptimizationCDWPMinMaxLoadActions.fetch()))
            .catch(error =>
                dispatch(RuleBasedOptimizationCDWPMinMaxLoadActions.setError(error.message)),
            );
    }
};

export const RuleBasedOptimizationCDWPMinMaxTimeActions = {
    init: payload => ({
        type: RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_TIME,
        payload: { loading: true, error: null, ...payload }
    }),
    setError: error => ({
        type: RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_TIME,
        payload: { loading: false, error }
    }),
    setData: data => ({
        type: RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_TIME_DATA,
        payload: { loading: false, data }
    }),
    fetch: () => dispatch => {
        dispatch(RuleBasedOptimizationCDWPMinMaxTimeActions.init());
        API.get(URL.DATA.RULE_OPTMIZATION.CDWP_MIN_MAX_TIME, getRequestConfig())
            .then(({ data }) => dispatch(RuleBasedOptimizationCDWPMinMaxTimeActions.setData(data)))
            .catch(error =>
                dispatch(RuleBasedOptimizationCDWPConfigActions.setError(error.message)),
            );
    },
    delete: id => dispatch => {
        dispatch(RuleBasedOptimizationCDWPMinMaxTimeActions.init({ data: [] }));
        const url = `${URL.DATA.RULE_OPTMIZATION.CDWP_MIN_MAX_TIME}/${id}`;
        API.delete(url, getRequestConfig())
            .then(() => dispatch(RuleBasedOptimizationCDWPMinMaxTimeActions.fetch()))
            .catch(error =>
                dispatch(RuleBasedOptimizationCDWPMinMaxTimeActions.setError(error.message)),
            );
    }
};
