import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
import "../styles/mapTable.scss";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

const EditFaultCodeTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");
    const [siteOptions, setSiteOptions] = useState([]);

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        code: VERBIAGE.TEXT.EMPTY_STRING,
        parameter: VERBIAGE.TEXT.EMPTY_STRING,
        description: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleSite = ({ target: { value } }) => {
        updateConfig({ ...config, siteId: value });
    };

    const handleCode = ({ target: { value } }) => {
        updateConfig({ ...config, code: value });
    };

    const handleParam = ({ target: { value } }) => {
        updateConfig({ ...config, parameter: value });
    };

    const handleDesc = ({ target: { value } }) => {
        updateConfig({ ...config, description: value });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await api.get(URL.DATA.PLANT_SETTINGS.SITES, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                const options = ["all"];
                data.forEach(site => {
                    options.push(site.siteId);
                });
                setSiteOptions(options);
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();
    }, [open]);

    const handleSubmit = async () => {
        if (!config.siteId || !config.code || !config.parameter || !config.description) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        if (isEdit) {
            const data = {
                ...config
            };
            const url = URL.DATA.ANALYTICS.SETTINGS.FAULT_CODE;
            try {
                await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                return;
            }
        } else {
            const data = {
                ...config
            };
            const url = URL.DATA.ANALYTICS.SETTINGS.FAULT_CODE;
            try {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                return;
            }
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
      <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
        <DialogTitle>
          {`To ${
                    isEdit ? "edit" : "add to"
                } the fault code list, please enter the details below.`}
        </DialogTitle>

        <DialogContent>
          <div className={EditFaultCodeTable.styles.inputContainer}>
            <DropdownSelect
              name={config.siteId}
              options={siteOptions}
              onChange={handleSite}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.SITE}
            />
          </div>
          <div className={EditFaultCodeTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CODE}
              onChange={handleCode}
              value={config.code}
              type="text"
            />
          </div>
          <div className={EditFaultCodeTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MAIN}
              onChange={handleParam}
              value={config.parameter}
              type="text"
            />
          </div>
          <div className={EditFaultCodeTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_DESC}
              onChange={handleDesc}
              value={config.description}
              type="text"
            />
          </div>
          {!!error && (
            <div className={classes.error}>
              <Typography>{error}</Typography>
            </div>
                )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleSubmit}>
            {VERBIAGE.BUTTONS.SUBMIT}
          </Button>
          <Button className={classes.error} onClick={() => setOpen(false)}>
            {VERBIAGE.BUTTONS.CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    );
});

EditFaultCodeTable.styles = {
    inputContainer: "webclient__mapTable-input"
};

export default EditFaultCodeTable;
