import React from "react";

import useQueryParams from "../../../hooks/useQueryParams";

import SiteSelector from "../SiteSelector";
import ChillerSelector from "./ChillerSelector";
import EditChillerSpecOverview from "./EditChillerSpecOverview";

const ChillerSpecOverview = () => {
    const { getQuery } = useQueryParams();
    const chId = getQuery("chId");

    return (
        <>
            <SiteSelector />
            <ChillerSelector />
            {chId && <EditChillerSpecOverview chId={chId} />}
        </>
    );
};

export default ChillerSpecOverview;
