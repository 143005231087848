/* eslint-disable react/destructuring-assignment  */
/* eslint-disable no-unused-vars  */
import React, { useState } from "react";
import { Grid, Tab, Tabs } from "@material-ui/core";

const SiteOverviewTabs = props => {
    const { classes, value, handleChange } = props;

    const tabStyle = {
        fontSize: "13px",
        fontWeight: "500",
        textTransform: "capitalize",
        minWidth: "100px"
    };

    return (
        <Grid container className={classes.tabContainer}>
            <Grid item xs={12}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  classes={{
                        fixed: classes.fixed,
                        scrollable: classes.fixed
                    }}
                >
                    <Tab
                      style={tabStyle}
                      value="systemPerformance"
                      label="System Performance"
                      wrapped
                    />
                    <Tab
                      style={tabStyle}
                      value="systemEfficiency"
                      label="System Efficiency"
                      wrapped
                    />
                    <Tab style={tabStyle} value="systemOverview" label="System Overview" wrapped />
                </Tabs>
            </Grid>
        </Grid>
    );
};

export default SiteOverviewTabs;
