const AnalyticsActionTypes = {
    GET_FAULTTABLE: "data/GET_FAULTTABLE",
    UPDATE_FAULTTABLE: "data/UPDATE_FAULTTABLE",
    SET_ANALYTICS_CHIPS: "data/SET_ANALYTICS_CHIPS",
    SET_LABEL: "data/SET_LABEL",
    SET_CHIP_SEVERITY: "data/SET_CHIP_SEVERITY",
    SET_NOTES: "data/SET_NOTES",
    SET_NOTES_CHIPS: "data/SET_NOTES_CHIPS",
    SET_USERTAGS_CHIPS: "data/SET_USERTAGS_CHIPS",
    GET_CHART_POSITION: "data/GET_CHART_POSITION",
    SET_ALARM_CONFIG: "data/SET_ALARM_CONFIG"
};

export default AnalyticsActionTypes;
