import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    RuleBasedOptimizationCTCapacityActions,
    RuleBasedOptimizationCTSetpointActions
} from "../../../redux/RuleBasedOptimizationCTActions";

const useRuleBasedOptimizationCT = () => {
    const { ctCapacity, ctSetpoint } = useSelector(state => state.ruleBasedOptimizationCT);

    const dispatch = useDispatch();

    const fetchCapacity = useCallback(
        force => {
            dispatch(RuleBasedOptimizationCTCapacityActions.fetch(force));
        },
        [dispatch],
    );

    const deleteCapacity = useCallback(
        id => {
            dispatch(RuleBasedOptimizationCTCapacityActions.delete(id));
        },
        [dispatch],
    );

    const fetchSetpoint = useCallback(
        force => {
            dispatch(RuleBasedOptimizationCTSetpointActions.fetch(force));
        },
        [dispatch],
    );

    const updateSetpoint = useCallback(
        payload => {
            dispatch(RuleBasedOptimizationCTSetpointActions.updateSetpoint(payload));
        },
        [dispatch],
    );

    const deletePercentage = useCallback(
        payload => {
            dispatch(RuleBasedOptimizationCTSetpointActions.deletePercentage(payload));
        },
        [dispatch],
    );

    const deleteWBT = useCallback(
        payload => {
            dispatch(RuleBasedOptimizationCTSetpointActions.deleteWBT(payload));
        },
        [dispatch],
    );

    return {
        ctCapacity,
        fetchCapacity,
        deleteCapacity,
        ctSetpoint,
        fetchSetpoint,
        updateSetpoint,
        deletePercentage,
        deleteWBT
    };
};

export default useRuleBasedOptimizationCT;
