/* eslint-disable max-len */
import React from "react";
import { makeStyles, MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Grid } from "@material-ui/core";
import { VERBIAGE } from "../../../utils/enums/index";

const useStyles = makeStyles(theme => ({
    root: {
        // width: "100%",
        textAlign: "center",
        paddingTop: "1vh",
        paddingRight: "1vw",
        display: "flex",
        // background: "#FFFFFF",
        borderRadius: "4px 4px 0 0"
        // [theme.breakpoints.down("sm")]: {
        //     paddingRight: "4vw",
        //     alignItems: "center",
        //     justifyContent: "center"
        // },
        // [theme.breakpoints.up("md")]: {
        //     justifyContent: "flex-start"
        // }
        // [theme.breakpoints.up("lg")]: {
        //     background: theme.palette.type === "dark" ? "#201F25" : ""
        // },
        // [theme.breakpoints.up("md")]: {
        //     background: theme.palette.type === "dark" ? "#201F25" : ""
        // }
    },
    notchedOutline: {
        borderColor: theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
    },
    sliderContainer: {
        marginLeft: "10px"
    },
    textColor: {
        color: "#7e7e7e"
    }
}));
const DateRangeSlider = props => {
    const theme = useTheme();
    const classes = useStyles();
    const theme1 = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                input: {
                    padding: "5px",
                    color: theme.palette.type === "dark" ? "#ffffff" : "#000000"
                }
            },
            MuiIconButton: {
                root: {
                    color: theme.palette.type === "dark" ? "#ffffff" : ""
                }
            },
            MuiInputLabel: {
                outlined: {
                    color: theme.palette.type === "dark" ? "#ffffff" : ""
                }
            },
            MuiSlider: {
                root: {
                    // padding: "10px",
                    // "@media (pointer:coarse)": {
                    //     padding: "10px"
                    // },
                    width: "93%"
                }
            },
            MuiInputBase: {
                root: {
                    width: "136px"
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor:
                        theme.palette.type === "dark" ? "#161616" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            },
            MuiPaper: {
                root: {
                    backgroundColor: theme.palette.type === "dark" ? "#424242" : "#ffffff",
                    color: theme.palette.type === "dark" ? "#e6e5e8" : "#000000"
                }
            },
            MuiButton: {
                textPrimary: {
                    color:
                        theme.palette.type === "dark"
                            ? VERBIAGE.COLOUR_LIGHT_BLUE
                            : VERBIAGE.COLOUR_DARK_BLUE // "#3687FF" : "#3865A7"
                }
            },
            MuiPickersDay: {
                daySelected: {
                    backgroundColor:
                        theme.palette.type === "dark"
                            ? VERBIAGE.COLOUR_LIGHT_BLUE
                            : VERBIAGE.COLOUR_DARK_BLUE // "#3687FF" : "#3865A7"
                },
                day: {
                    color: theme.palette.type === "dark" ? "#e6e5e8" : "#000000"
                }
            },
            MuiPickersCalendarHeader: {
                dayLabel: {
                    color: theme.palette.type === "dark" ? "#ffffff" : "#000000",
                    opacity: theme.palette.type === "dark" ? "0.5" : "0.3"
                },
                iconButton: {
                    backgroundColor: theme.palette.type === "dark" ? "#424242" : "#ffffff"
                }
            }
        }
    });
    const {
        dates,
        max,
        min,
        handleDates,
        handleStartInput,
        handleEndInput,
        onSliderChangeCommitted,
        id,
        format
    } = props;
    // const themes = theme1;
    const gridSize = 12;
    const borderClass = id ? classes.notchedOutline : null;
    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={theme1}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid container className={classes.sliderContainer} justify="center">
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12} sm={gridSize / 2}>
                                <KeyboardDatePicker
                                  variant="dialog"
                                  label="Start Date"
                                  inputVariant="outlined"
                                  value={dates[0]}
                                  onChange={handleStartInput}
                                  format={format}
                                  DialogProps={{ disableScrollLock: true }}
                                  InputProps={{
                                        style: {
                                            height: "30px"
                                        },
                                        classes: {
                                            notchedOutline: borderClass,
                                            input: classes.textColor
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={gridSize / 2}>
                                <KeyboardDatePicker
                                  variant="dialog"
                                  label="End Date"
                                  inputVariant="outlined"
                                  value={dates[1]}
                                  onChange={handleEndInput}
                                  format={format}
                                  DialogProps={{ disableScrollLock: true }}
                                  InputProps={{
                                        style: {
                                            height: "30px"
                                        },
                                        classes: {
                                            notchedOutline: borderClass,
                                            input: classes.textColor
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={gridSize}>
                            <Slider
                              value={dates}
                              onChange={handleDates}
                              min={min}
                              max={max}
                              step={86400000}
                              onChangeCommitted={onSliderChangeCommitted}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </div>
    );
};
export default DateRangeSlider;
