/* eslint-disable array-callback-return  */
import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import { ProgressBar } from "@91social/progress-bar";
import Box from "@material-ui/core/Box";
// import CardData from "./CardData";
import CardData2 from "./CardData2";
import REFRESH_TIMER from "../../../utils/enums/Enum";
import PortfolioActions from "../../redux/PortfolioActions";
import IMAGE from "../../../utils/enums/Image";
import VERBIAGE from "../../../utils/enums/Verbiage";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    grid: {
        background: theme.palette.type === "dark" ? "#201F25" : "#ffffff",
        paddingBottom: "6px"
    },
    saving: {
        width: "100%",
        height: "100%",
        display: "flex"
    },
    paper: {
        padding: theme.spacing(0),
        width: "100%",
        display: "block",
        // marginTop: "8px",
        maxHeight: "70px",
        // height: "7vh",
        // backgroundColor: "#201F25",
        backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff",
        alignItems: "center"
    }
}));

const delayRender = (callback, timeout) => {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            callback();
        }, timeout);
    };
};

const SystemEfficiency = props => {
    const { fetchPortfolioKpi, portfolio } = props;
    const theme = useTheme();
    const classes = useStyles();
    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        fetchPortfolioKpi();
        const timer = setInterval(() => {
            fetchPortfolioKpi();
        }, timerValue);
        return () => clearInterval(timer);
    }, [fetchPortfolioKpi, REFRESH_TIMER()]);

    const handleIcon = img => {
        const entries = Object.entries(IMAGE);
        let imgURL;
        entries.map(([prop, val]) => {
            if (prop === img) {
                imgURL = val;
            }
        });
        return imgURL;
    };

    const [containerWidth, setContainerWidth] = useState({});
    const progressBarContainer = useRef();

    const getContainerWidth = () => {
        if (progressBarContainer.current) {
            setContainerWidth(progressBarContainer.current.offsetWidth - 20);
        }
    };

    useEffect(() => {
        const render = delayRender(getContainerWidth, 200);
        window.addEventListener("resize", render);
        return () => window.removeEventListener("resize", render);
    });

    useLayoutEffect(() => {
        getContainerWidth();
    }, []);

    const dataValueStyle = {
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        marginLeft: "10px"
    };

    const unitValueStyle = {
        // position: "absolute",
        margin: "0px",
        color: theme.palette.type === "dark" ? "#ACACAC" : "#707070"
    };
    useEffect(() => {
        getContainerWidth();
    }, [portfolio.toggle]);
    return (
      <div>
        <Grid elevation={0}>
          <Paper elevation={0}>
            <div ref={progressBarContainer}>
              {portfolio &&
                            portfolio.kpi &&
                            portfolio.kpi.efficiency.map(item => (
                              <div key={item.id}>
                                <div
                                  style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            // alignItems: "center",
                                            paddingBottom: "4px",
                                            backgroundColor:
                                                theme.palette.type === "dark" ? "#201F25" : ""
                                        }}
                                >
                                  <div style={dataValueStyle}>
                                    <h1
                                      style={{
                                                    color: `${
                                                        item.color === VERBIAGE.COLOUR_YELLOW
                                                            ? VERBIAGE.COLOUR_NEW_YELLOW
                                                            : item.color
                                                    }`,
                                                    margin: "0px",
                                                    fontSize: "26px"
                                                }}
                                    >
                                      {item.currentValue}
                                    </h1>
                                    <h4 style={unitValueStyle}>kW/RT</h4>
                                  </div>
                                  {item.showIcon ? (
                                    <img
                                      src={handleIcon(item.icon)}
                                      alt="Logo"
                                      style={{ height: "53px", width: "65px" }}
                                    />
                                        ) : null}
                                </div>
                                <div
                                  style={{
                                            background:
                                                theme.palette.type === "dark" ? "#201F25" : "",
                                            position: "relative"
                                        }}
                                >
                                  {containerWidth && (
                                    <ProgressBar
                                      key={item.id}
                                      value={item.currentValue}
                                      max={item.maxValue}
                                      min={item.minValue}
                                      staticLabels={item.staticLabels}
                                      staticZones={item.staticZones}
                                      width={containerWidth}
                                      height={22}
                                      fontColor={
                                                    theme.palette.type === "dark"
                                                        ? "#FFFFFF"
                                                        : "#000000"
                                                }
                                      fontSize={10}
                                    />
                                        )}
                                </div>
                              </div>
                            ))}
            </div>
            <div>
              <Box m={1} bgcolor="background.paper" />
            </div>

            <Grid container item className={classes.grid} elevation={0}>
              {portfolio &&
                            portfolio.kpi &&
                            portfolio.kpi.others.map(kpi => (
                              <Grid
                                key={kpi.id}
                                container
                                item
                                xs={6}
                                className={classes.grid}
                                elevation={0}
                              >
                                <Paper
                                  className={classNames(classes.saving, classes.paper)}
                                  elevation={0}
                                >
                                  <CardData2
                                    key={kpi.id}
                                    image={handleIcon(kpi.icon)}
                                    title={kpi.name}
                                    savings={ _.toString(kpi.value) }
                                    valueColor={
                                                kpi.color === VERBIAGE.COLOUR_YELLOW
                                                    ? VERBIAGE.COLOUR_NEW_YELLOW
                                                    : kpi.color
                                            }
                                    id="system"
                                    styleValue={SystemEfficiency.styles.image}
                                  />
                                </Paper>
                              </Grid>
                            ))}
            </Grid>
          </Paper>
        </Grid>
      </div>
    );
};
SystemEfficiency.styles = {
    image: "webclient__portfolioRightView-imageSystemEfficiency"
};
const mapStateToProps = state => ({
    portfolio: state.portfolio
});
const mapDispatchToProps = dispatch => ({
    fetchPortfolioKpi: () => {
        dispatch(PortfolioActions.fetchPortfolioKpi());
    }
});
SystemEfficiency.propTypes = {
    fetchPortfolioKpi: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemEfficiency));
