import React, { useState, useEffect, useRef } from "react";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import classNames from "classnames";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
// import Version from "../../../Version";
import { VERBIAGE } from "../../../utils/enums/index";
import SystemHeader from "../../common/components/SystemHeader";
import "../styles/appVersion.scss";
import api from "../../../utils/API";
import URL from "../../../utils/URL";

const MobileDetect = require("mobile-detect");

const AppVersion = props => {
    const { home } = props;
    const { _authentication } = props;
    const isMounted = useRef(false);
    const [allChanges, setAllChanges] = useState("");
    const [siteChanges, setSiteChanges] = useState("");

    const getCookie = name => {
        // Split cookie string and get all individual name=value pairs in an array
        const cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
          and compare it with the given string */
            if (name === cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }

        // Return null if not found
        return null;
    };

    const activeSite = getCookie("siteId");
    const md = new MobileDetect(window.navigator.userAgent);
    // const markdownText = Version.CHANGE_NOTES + Version.OLD_CHANGES;
    // const siteChanges = Version[`SITE_${activeSite}_CHANGES`]
    //     ? Version[`SITE_${activeSite}_CHANGES`]
    //     : `* No Changes`;

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "left",
            verticalAlign: "top",
            height: "100%",
            color: theme.palette.type === "dark" ? "white" : "black",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff"
        },
        grid: {
            paddingLeft: "30px"
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box"
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.VERSION.HISTORY, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });

                if (response.data && response.data.status === "error") {
                    setAllChanges(`### ${response.data.details}`);
                    setSiteChanges(`### ${response.data.details}`);
                } else {
                    setAllChanges(response.data.all);
                    setSiteChanges(response.data[`${activeSite}`]);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();
        return () => (isMounted.current = false);
    }, [activeSite]);

    return (
        <div className="main-wrapper">
            <div>
                <SystemHeader authentication={_authentication} />
            </div>
            <div className={classNames(classes.root, classes.main)}>
                <Grid container>
                    <Grid container item xs={12}>
                        <span className={AppVersion.styles.siteText}>
                            {VERBIAGE.MENU_TEXT.APP_VERSION}
                        </span>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Paper className={classNames(classes.paper, classes.grid)}>
                                <ReactMarkdown>{allChanges}</ReactMarkdown>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Paper className={classNames(classes.paper, classes.grid)}>
                                <ReactMarkdown>{siteChanges}</ReactMarkdown>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

AppVersion.styles = {
    siteText: "webclient__appVersion-site-text"
};

const mapStateToProps = state => ({
    home: state.home,
    authentication: state.authentication
});
export default connect(mapStateToProps)(AppVersion);
