/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-unused-vars  */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Typography, makeStyles, Button } from "@material-ui/core";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/login.scss";
import TextInput from "../../../dashboard/common/components/TextInput";
import URL from "../../../utils/URL";
import api from "../../../utils/API";

const MissingDataDone = props => {
    const { match } = props;
    const { status } = match.params;

    const useStyles = makeStyles(theme => ({
        status: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center",
            margin: "2vh 0 2vh 0"
        },
        button: {
            marginTop: "20px"
        },
        main: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center",
            background: "#ffffff"
        },
        sub: {
            border: "2px solid #006ab4",
            height: "400px",
            width: "35%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        input: {
            height: "50px",
            width: "70%",
            fontSize: "large",
            marginBottom: "30px"
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.main}>
            <h1 className={MissingDataDone.style.header}>
                {VERBIAGE.TEXT.MISSING_DATA_DONE_FAIL_MAIN}
            </h1>
            <h1>
                Your missing data done has failed. The link may have expired or used previously.
            </h1>
        </div>
    );
};

MissingDataDone.style = {
    mainContainer: "webclient__login-screen-container-main",
    subContainer: "webclient__login-screen-container-sub",
    inputContainer: "webclient__login-screen-container-input",
    button: "webclient__login-screen-button",
    header: "webclient__login-screen-header",
    forgotPassword: "webclient__login-screen-forgot-password",
    errorText: "webclient__login-screen-error-text"
};
export default withRouter(MissingDataDone);
