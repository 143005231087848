import ReportingActionTypes from "./ReportingActionTypes";

const initialState = {
    loading: false,
    error: null,
    missingData: {
        config: {
            types: [],
            params: [],
            status: []
        },
        params: {
            MISSING_TYPE: null,
            MISSING_PARAMS: null,
            STATUS: null,
            SYS_TS_DATETIME_START: null,
            SYS_TS_DATETIME_END: null,
            page: 1,
            perPage: 10,
            sortBy: null,
            orderBy: null
        },
        count: 0,
        data: []
    }
};

const ReportingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ReportingActionTypes.SET_REPORT_LOADING:
            return {
                ...state,
                loading: action.data
            };
        case ReportingActionTypes.SET_REPORT_ERROR:
            return {
                ...state,
                error: action.data
            };
        case ReportingActionTypes.SET_REPORT_MISSING_DATA:
            return {
                ...state,
                missingData: {
                    ...state.missingData,
                    ...action.data
                }
            };
        case ReportingActionTypes.SET_REPORT_MISSING_CONFIG:
            return {
                ...state,
                missingData: {
                    ...state.missingData,
                    config: action.data
                }
            };
        case ReportingActionTypes.SET_REPORT_MISSING_PARAMS:
            return {
                ...state,
                missingData: {
                    ...state.missingData,
                    params: {
                        ...state.missingData.params,
                        ...action.data
                    }
                }
            };
        default:
            return state;
    }
};

export default ReportingReducer;
