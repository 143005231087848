/* eslint-disable jsx-a11y/anchor-is-valid  */
/* eslint-disable no-unused-vars  */
/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";
import SiteOverviewChart from "../components/SiteOverviewChart";
import URL from "../../../utils/URL";

const OptimizationEfficiencyView = props => {
    const [worksheets, setWorksheets] = useState([]);
    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <SiteOverviewChart
                  url={URL.DATA.ANALYTICS.OPTMIZATION.LOOKUP_EFFICIENCY}
                  addWorksheet={addWorksheet}
                  xs={12}
                  tall
                />
            </Grid>
        </Grid>
    );
};

export default connect(null, null)(OptimizationEfficiencyView);
