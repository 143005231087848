import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";

// import SiteReport from "./SiteReport";
import ReportView from "./ReportView";
import DynamicReportTable from "./DynamicReportTable";
import DynamicReportTab from "./DynamicReportTab";

import { VERBIAGE } from "../../../utils/enums";
import ReportActions from "../../redux/ReportActions";

const MobileDetect = require("mobile-detect");

const md = new MobileDetect(window.navigator.userAgent);

const ReportTabs = ({ tab, changeTab }) => {
    const { desktopMobileSwitch } = useSelector(state => state.home);
    const dispatch = useDispatch();

    const useStyles = makeStyles(() => ({
        root: {
            flexGrow: 1
        },
        main: {
            transition: "margin-left 0.5s",
            height: desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box"
        }
    }));
    const classes = useStyles();

    const handleDynamicReportError = (value, error) => {
        // if report doesn't exist, remove the report tab
        // and change tab to DYNAMIC_REPORT
        if (error?.response?.status === 404) {
            dispatch(ReportActions.removeDynamicReportTab({ value }));
            changeTab(VERBIAGE.REPORT_TAB_VALUE.DYNAMIC_REPORT);
        }
    };

    const tabView = () => {
        switch (tab) {
            case VERBIAGE.REPORT_TAB_VALUE.DYNAMIC_REPORT:
                return <DynamicReportTable />;
            case VERBIAGE.REPORT_TAB_VALUE.SITE_REPORT:
                // return <SiteReport />;
                return <ReportView />;
            default:
                return <DynamicReportTab tab={tab} onError={handleDynamicReportError} />;
        }
    };

    return <Grid className={classNames(classes.root, classes.main)}>{tabView()}</Grid>;
};

export default ReportTabs;
