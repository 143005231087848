import AuditLogActionTypes from "./AuditLogActionTypes";

const initialState = {
    loading: false,
    error: null,
    auditLogData: {
        filter: {
            userId: [],
            siteId: [],
            action: [],
            status: []
            // types: [],
            // params: [],
            // status: []
        },
        params: {
            userId: null,
            siteId: null,
            action: null,
            status: null,
            page: 1,
            perPage: 10,
            sortBy: "timestamp",
            orderBy: "desc"
        },
        count: 0,
        data: []
    }
};

const AuditLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case AuditLogActionTypes.SET_AUDITLOG_LOADING:
            return {
                ...state,
                loading: action.data
            };
        case AuditLogActionTypes.SET_AUDITLOG_ERROR:
            return {
                ...state,
                error: action.data
            };
        case AuditLogActionTypes.SET_AUDITLOG_DATA:
            return {
                ...state,
                auditLogData: {
                    ...state.auditLogData,
                    ...action.data
                }
            };
        case AuditLogActionTypes.SET_AUDITLOG_FILTER:
            return {
                ...state,
                auditLogData: {
                    ...state.auditLogData,
                    filter: action.data
                }
            };
        case AuditLogActionTypes.SET_AUDITLOG_PARAMS:
            return {
                ...state,
                auditLogData: {
                    ...state.auditLogData,
                    params: {
                        ...state.auditLogData.params,
                        ...action.data
                    }
                }
            };
        default:
            return state;
    }
};

export default AuditLogReducer;
