/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Switch,
    Typography
} from "@material-ui/core";
import compact from "lodash/compact";
import TextInput from "./TextInput";

import API from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

import "../styles/mapTable.scss";
import DropdownSelect from "./DropdownSelect";
import { mlSettingsOptions } from "../../../utils/MLsettings";

const EditMLSetting = forwardRef((props, ref) => {
    const { isEdit, open, onClose } = props;

    const [inputErrors, setInputErrors] = useState({});
    const [hasInputError, setHasInputError] = useState(false);
    const [error, setError] = useState("");

    const [loading, setLoading] = useState(false);

    const [mlSetting, setMLSetting] = useState(null);

    const initConfig = {
        name: VERBIAGE.TEXT.EMPTY_STRING,
        value: VERBIAGE.TEXT.EMPTY_STRING,
        isActive: true
    };

    const [config, setConfig] = useState(initConfig);

    useImperativeHandle(ref, () => ({
        init() {
            setConfig(initConfig);
        },
        update(data) {
            const mlSettingOption = mlSettingsOptions.find(item => item.name === data.name);
            setMLSetting(mlSettingOption);
            setConfig({
                ...config,
                ...data
            });
        }
    }));

    useEffect(() => {
        setHasInputError(compact(Object.values(inputErrors)).length);
    }, [inputErrors]);

    const handleTextChange = (field, options = {}) => {
        const { required = true, type = "text" } = options;
        return ({ target: { value } }) => {
            const errRequired = required && !value ? "This field is required" : undefined;
            setInputErrors(curr => ({ ...curr, [field]: errRequired }));
            if (errRequired) return;

            let fieldValue = value;
            if (type === "number") {
                fieldValue = +value;
            }
            setConfig({ ...config, [field]: fieldValue });
        };
    };

    const handleSwitchChange = field => {
        return e => {
            setConfig({ ...config, [field]: e.target.checked });
        };
    };

    const handleNameChange = e => {
        const settingName = e.target.value;
        const mlSettingOption = mlSettingsOptions.find(item => item.name === settingName);
        if (mlSettingOption) {
            setConfig(prev => ({ ...prev, name: settingName, value: VERBIAGE.TEXT.EMPTY_STRING }));
            setMLSetting(mlSettingOption);
        }
    };

    const closeDialog = (e, refresh) => {
        setError(null);
        setInputErrors({});
        onClose(refresh);
    };

    const checkError = () => {
        let hasErrRequired = false;
        ["name", "value"].forEach(field => {
            const errRequired = !config[field] ? "This field is required" : undefined;
            if (errRequired && !hasErrRequired) {
                hasErrRequired = true;
            }
            setInputErrors(curr => ({ ...curr, [field]: errRequired }));
        });
        return hasErrRequired;
    };

    const handleSubmit = async () => {
        if (checkError()) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }

        try {
            setLoading(true);

            const { _id, ...data } = config;

            const requestConfig = {
                headers: {
                    "x-auth-token": localStorage.token
                },
                url: URL.DATA.ML_SETTINGS.ML_SETTINGS,
                method: "POST",
                data
            };
            if (_id) {
                requestConfig.method = "PUT";
                requestConfig.url += `/${_id}`;
            }

            const response = await API.request(requestConfig);
            if ([200, 201].includes(response.status)) {
                closeDialog(null, true);
            }
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else {
                const { details } = err.response.data;
                if (typeof details === "object" && details.errors) {
                    Object.keys(details.errors).forEach(field => {
                        const message = details.errors[field].message
                            .replace(/^Path/i, "")
                            .replace(new RegExp(`\`${field}\`|"${field}"|${field}`), "This field")
                            .trim();
                        setInputErrors(curr => ({ ...curr, [field]: message }));
                    });
                    setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${
                    isEdit ? "edit" : "add to"
                } the ML Settings, please enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <div className={EditMLSetting.styles.inputContainer}>
                    <DropdownSelect
                      name={config.name}
                      options={mlSettingsOptions.map(item => item.name)}
                      onChange={handleNameChange}
                      placeholder="Select a config"
                      closeMenuOnSelect
                      required
                    />
                </div>
                <div className={EditMLSetting.styles.inputContainer}>
                    <TextInput
                      type={mlSetting?.type ?? "text"}
                      onChange={handleTextChange("value", { type: mlSetting?.type })}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_VALUE}
                      value={config.value}
                      error={!!inputErrors.value}
                      helperText={mlSetting?.description ?? inputErrors.value}
                      disabled={!mlSetting}
                      required
                      {...(mlSetting?.type === "number"
                            ? { InputProps: { inputProps: { min: 1 } } }
                            : {})}
                    />
                </div>
                <div className={EditMLSetting.styles.inputContainer}>
                    <Typography component="div">
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item xs={3}>
                                <Typography>Is Active</Typography>
                            </Grid>
                            <Grid item>
                                <Switch
                                  checked={config.isActive}
                                  onChange={handleSwitchChange("isActive")}
                                  color="primary"
                                  name="isActive"
                                  helperText="is Active ?"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                {VERBIAGE.PLACEHOLDER_TEXT.EMPTY_STRING}
                            </Grid>
                        </Grid>
                    </Typography>
                </div>
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={loading || hasInputError} onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={closeDialog}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditMLSetting.styles = {
    inputContainer: "webclient__mapTable-input"
};

export default EditMLSetting;
