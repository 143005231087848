/* eslint-disable react/destructuring-assignment  */
import React, { useState } from "react";
import { Tab, Tabs, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";
import "moment-timezone";
import { VERBIAGE } from "../../../utils/enums/index";
import "../styles/portfolioView.scss";
import SystemHeader from "../../common/components/SystemHeader";
import AnalyticsSettingsTabs from "../../common/components/AnalyticsSettingsTabs";

const MobileDetect = require("mobile-detect");

const AnalyticsSettings = props => {
    const { home } = props;
    const [value, setValue] = useState("faultCode");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        paper: {
            padding: theme.spacing(0),
            textAlign: "center",
            height: "95%",
            [theme.breakpoints.up("xs")]: {
                height: "905px"
            },
            [theme.breakpoints.up("sm")]: {
                height: "905px"
            },
            [theme.breakpoints.up("md")]: {
                height: "95%"
            },
            [theme.breakpoints.up("lg")]: {
                height: "95%"
            },
            [theme.breakpoints.up("xl")]: {
                height: "95%"
            },
            color: theme.palette.type === "dark" ? "white" : "black",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff"
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            paddingTop: "70px",
            boxSizing: "border-box"
        }
    }));

    const classes = useStyles();

    return (
      <div className="main-wrapper">
        <div>
          <SystemHeader authentication={props.authentication} />
        </div>
        <div className={classes.main}>
          <Grid container item xs={12} className={classes.root}>
            {/* header start */}
            <Grid item xs={12}>
              <div
                className={classNames(
                                AnalyticsSettings.styles.headerContainer,
                                classes.title,
                            )}
              >
                <span>{VERBIAGE.MENU_TEXT.ANALYTICS_SETTINGS}</span>
              </div>
            </Grid>
            {/* header end */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons={window.innerWidth < 850 ? "on" : "auto"}
                >
                  <Tab value="faultCode" label="Fault Code" />
                  <Tab value="paramConfig" label="Parameter Config" />
                  <Tab value="chartMap" label="Chart Map" />
                  <Tab value="nameMap" label="Name Map" />
                </Tabs>
                <AnalyticsSettingsTabs value={value} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
};
AnalyticsSettings.styles = {
    headerContainer: "webclient__portfolioView-container-header"
};
const mapStateToProps = state => ({
    home: state.home
});
export default connect(mapStateToProps)(AnalyticsSettings);
