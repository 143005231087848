/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";

const EditParamConfigTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");

    const emptyConfig = {
        timestamp: VERBIAGE.TEXT.EMPTY_STRING,
        model: VERBIAGE.TEXT.EMPTY_STRING,
        code: VERBIAGE.TEXT.EMPTY_STRING,
        main: VERBIAGE.TEXT.EMPTY_STRING,
        parameter: VERBIAGE.TEXT.EMPTY_STRING,
        value: VERBIAGE.TEXT.EMPTY_STRING,
        valueString: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleTime = event => {
        const newTimestamp = new Date(event._d).getTime();
        updateConfig({ ...config, timestamp: newTimestamp });
    };

    const handleModel = ({ target: { value } }) => {
        updateConfig({ ...config, model: value });
    };

    const handleCode = ({ target: { value } }) => {
        updateConfig({ ...config, code: value });
    };

    const handleMain = ({ target: { value } }) => {
        updateConfig({ ...config, main: value });
    };

    const handleParam = ({ target: { value } }) => {
        updateConfig({ ...config, parameter: value });
    };

    const handleValue = ({ target: { value } }) => {
        updateConfig({ ...config, value });
    };

    const handleValueString = ({ target: { value } }) => {
        switch (value) {
            case "True":
                updateConfig({ ...config, value: "1", valueString: value });
                break;
            case "False":
                updateConfig({ ...config, value: "0", valueString: value });
                break;
            default:
                updateConfig({ ...config, valueString: value });
                break;
        }
    };

    const handleSubmit = async () => {
        if (
            !config.timestamp ||
            !config.code ||
            !config.model ||
            !config.parameter ||
            (!config.value && config.value < 0) ||
            !config.valueString
        ) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        const valueStringParsed = config.valueString === "null" ? null : config.valueString;

        const data = {
            model: config.model,
            code: config.code,
            parameter: config.parameter,
            main: config.main,
            value: config.value.toString(),
            timestamp: config.timestamp,
            valueString: valueStringParsed
        };
        const url = URL.DATA.ANALYTICS.SETTINGS.PARAM_CONFIG;
        try {
            if (isEdit) {
                await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } else {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            }
            setError("");
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    // Override border color of input
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            }
        }
    });

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} the configuration list, please ${
                    isEdit ? "update" : "enter"
                } the details below.`}
            </DialogTitle>

            <DialogContent>
                <div className={EditParamConfigTable.styles.inputContainer}>
                    <MuiThemeProvider theme={themeOverride}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDateTimePicker
                              variant="dialog"
                              label={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TIME}
                              inputVariant="outlined"
                              value={config.timestamp}
                              onChange={handleTime}
                              format="DD/MM/YYYY HH:mm"
                              fullWidth
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </div>
                <div className={EditParamConfigTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MODEL}
                      onChange={handleModel}
                      value={config.model}
                      type="text"
                    />
                </div>
                <div className={EditParamConfigTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CODE}
                      onChange={handleCode}
                      value={config.code}
                      type="text"
                    />
                </div>
                <div className={EditParamConfigTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MAIN}
                      onChange={handleMain}
                      value={config.main}
                      type="text"
                    />
                </div>
                <div className={EditParamConfigTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PARAM}
                      onChange={handleParam}
                      value={config.parameter}
                      type="text"
                    />
                </div>
                <div className={EditParamConfigTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_VALUE}
                      onChange={handleValue}
                      value={config.value}
                      type="text"
                    />
                </div>
                <div className={EditParamConfigTable.styles.inputContainer}>
                    <DropdownSelect
                      name={config.valueString}
                      options={VERBIAGE.TEXT.VALUE_STRING_OPTIONS}
                      onChange={handleValueString}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_VALUE_BOOLEAN}
                    />
                </div>
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditParamConfigTable.styles = {
    inputContainer: "webclient__mapTable-input",
    errorText: "webclient__mapTable-error-text"
};

export default EditParamConfigTable;
