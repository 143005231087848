/* eslint-disable no-unused-vars  */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import URL from "../../../utils/URL";
import SiteOverviewChart from "./SiteOverviewChart";
import OptimizationTabs from "../Analytics/OptimizationTabs";
import OptimizationPerformanceView from "../Analytics/OptimizationView";
import OptimizationAutomationView from "../Analytics/OptimizationAutomationView";
import OptimizationSummaryView from "../Analytics/OptimizationSummaryView";
import OptimizationEfficiencyView from "../Analytics/OptimizationEfficiencyView";

const EnergyOptimizationPage = props => {
    const { classes, tabStyle } = props;

    // const [url, setUrl] = useState(URL.DATA.LIFE_CYCLE.HEALTH_SCORE);
    const [value, setValue] = useState("recommended");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [worksheets, setWorksheets] = useState([]);
    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    // useEffect(() => {
    //     let newUrl;
    //     // if (lifecycleChart === "Health Status") {
    //     // newUrl = URL.DATA.LIFE_CYCLE.HEALTH_STATUS;
    //     // } else
    //     if (lifecycleChart === "Chiller") {
    //         newUrl = URL.DATA.LIFE_CYCLE.HEALTH_SCORE;
    //     } else if (lifecycleChart === "Chiller Pump") {
    //         newUrl = URL.DATA.LIFE_CYCLE.CHILLER_PUMP;
    //     } else if (lifecycleChart === "Condenser Pump") {
    //         newUrl = URL.DATA.LIFE_CYCLE.CONDENSOR_PUMP;
    //     }
    //     setUrl(newUrl);
    // }, [lifecycleChart]);

    return (
        <React.Fragment>
            <OptimizationTabs
              classes={classes}
              tabStyle={tabStyle}
              value={value}
              handleChange={handleChange}
            />

            {value === "summary" && (
                <Grid container spacing={2}>
                    <OptimizationSummaryView />
                </Grid>
            )}

            {value === "recommended" && (
                <Grid container spacing={2}>
                    <OptimizationPerformanceView />
                </Grid>
            )}

            {value === "analysis" && (
                <Grid container spacing={2}>
                    <OptimizationAutomationView />
                </Grid>
            )}

            {value === "efficiency" && (
                <Grid container spacing={2}>
                    <OptimizationEfficiencyView />
                </Grid>
            )}
        </React.Fragment>
    );
};

export default EnergyOptimizationPage;
