import React from "react";
import { CircularProgress, Grid, Typography, Button, makeStyles } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    }
}));

const LoadingRetry = ({
    loading,
    loadingText = "",
    error,
    errorText,
    retryFn,
    retryText = "Retry"
}) => {
    const classes = useStyles();

    const handleRetry = () => {
        if (retryFn) {
            retryFn();
        }
    };

    if (loading) {
        return (
            <Grid container direction="row" justifyContent="center" spacing={2}>
                <Grid item>
                    <CircularProgress size={18} />
                </Grid>
                <Grid item>
                    <Typography>{loadingText}</Typography>
                </Grid>
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
                {errorText && (
                    <Grid item>
                        <Typography color="error">{errorText}</Typography>
                    </Grid>
                )}
                <Grid item>
                    <Button
                      className={classes.button}
                      startIcon={<ReplayIcon size={18} />}
                      onClick={handleRetry}
                    >
                        {retryText}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    return null;
};

export default LoadingRetry;
