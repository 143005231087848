/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import { Typography, CircularProgress, IconButton } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
// import { EditOutlined } from "@material-ui/icons";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";

import { SelectMultipleFilterDataTable, DateRangeFilterDataTable } from "./dataTable";
import { toIntlDateTimeFormat } from "../../../utils/helpers";
import AuditLogActions from "../../redux/AuditLogActions";
import ShowAuditLogDetails from "./ShowAuditLogDetails";

const AuditLogTable = () => {
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    // const isMounted = useRef(false);

    const {
        loading,
        auditLogData: { filter, count, data }
    } = useSelector(state => state.auditLogs);
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log("dispatch(AuditLogActions.getAuditLogsData({}, true));");
        dispatch(AuditLogActions.getAuditLogData({}));
    }, [dispatch]);

    const handleClick = () => {
        setOpen(!open);
    };

    const auditLogColumns = useMemo(() => {
        return [
            {
                label: "id",
                name: "id",
                options: {
                    display: "false",
                    hide: true,
                    viewColumns: false
                }
            },
            {
                label: "oldData",
                name: "oldData",
                options: {
                    display: "false",
                    hide: true,
                    viewColumns: false
                }
            },
            {
                label: "newData",
                name: "newData",
                options: {
                    display: "false",
                    hide: true,
                    viewColumns: false
                }
            },
            { label: "No.", name: "ctr", options: { filter: false, sort: false } },
            {
                label: "Date Time",
                name: "timestamp",
                options: {
                    filter: true,
                    filterType: "custom",
                    selectedRows: {
                        data
                    },
                    customBodyRender: value => {
                        return toIntlDateTimeFormat(new Date(value));
                    },
                    customFilterListOptions: {
                        render: v => {
                            const dfOptions = {
                                second: undefined,
                                timeZoneName: undefined
                            };
                            const from = v[0] ? toIntlDateTimeFormat(v[0], dfOptions) : null;
                            const to = v[1] ? toIntlDateTimeFormat(v[1], dfOptions) : null;
                            if (from && to) {
                                return [`Start From: ${from}`, `Start To: ${to}`];
                            }
                            if (from) {
                                return `Start From: ${from}`;
                            }
                            if (to) {
                                return `Start To: ${to}`;
                            }
                            return [];
                        },
                        update: (filterList, filterPos, index) => {
                            if (filterPos === 0) {
                                filterList[index].splice(filterPos, 1, "");
                            } else if (filterPos === 1) {
                                filterList[index].splice(filterPos, 1);
                            } else if (filterPos === -1) {
                                filterList[index] = [];
                            }
                            return filterList;
                        }
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = { filterList, onChange, index, column };
                            return <DateRangeFilterDataTable {...props} />;
                        }
                    }
                }
            },
            {
                label: "Site ID",
                name: "siteId",
                options: {
                    display: "true",
                    filter: true,
                    filterType: "custom",
                    selectedRows: {
                        data
                    },
                    customFilterListOptions: {
                        render: v => v.map(type => `Site ID: ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: filter.siteIds
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            },
            {
                label: "User ID",
                name: "userId",
                options: {
                    display: "false",
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: v => v.map(type => `User ID: ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: filter.userIds
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            },
            {
                label: "User",
                name: "username",
                options: {
                    display: "true",
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: v => v.map(type => `User: ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: filter.usernames
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            },
            {
                label: "Permissions",
                name: "permissions",
                options: {
                    display: "true",
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: v => v.map(type => `Permissions: ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: filter.permissions
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            },
            {
                label: "Data Type",
                name: "type",
                options: {
                    display: "true",
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: v => v.map(type => `Data Type": ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: filter.types
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            },
            {
                label: "Action",
                name: "action",
                options: {
                    display: "true",
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: v => v.map(type => `Action: ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: filter.actions
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            },
            { label: "Description", name: "description", options: { filter: false, sort: false } },
            {
                name: "",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRenderLite: dataIndex => {
                        return (
                            <IconButton
                              onClick={() => {
                                    editRef.current.update({
                                        ...editRef.current.getConfig(),
                                        ...data[dataIndex]
                                    });
                                    setIsEdit(true);
                                    handleClick();
                                }}
                            >
                                <DvrOutlinedIcon />
                            </IconButton>
                        );
                    }
                }
            },
            {
                label: "Status",
                name: "status",
                options: {
                    display: "true",
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: v => v.map(type => `Status: ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: filter.status
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            }
        ];
    }, [
        data,
        filter.actions,
        filter.permissions,
        filter.siteIds,
        filter.status,
        filter.types,
        filter.userIds,
        filter.usernames,
        handleClick
    ]);

    // const onRowSelectionChange = (ev, ex, ez) => {
    //     console.log(ev);
    //     console.log(ex);
    //     console.log(ez);
    // };

    // const onRowClick = (ev, clickedRow) => {
    //     console.log("clickedRow : ", clickedRow);

    // }
    // const onRowClick = () => {
    //     // console.log("here it is", rowData, rowState);
    //     editRef.current.update({
    //         ...editRef.current.getConfig(),
    //         ...data[dataIndex]
    //     });
    //     setIsEdit(true);
    //     setOpen(!open);
    // };
    const auditLogOptions = {
        selectableRows: "none",
        // selectableRowsHideCheckboxes: true,
        print: false,
        download: false,
        serverSide: true,
        rowsPerPageOptions: [10, 20, 50, 100],
        count,
        customSearchRender: debounceSearchRender(500),
        onSearchChange: searchText => {
            dispatch(AuditLogActions.getAuditLogData({ search: searchText }));
        },
        onFilterChange: (column, filterList, type, changedColumnIndex) => {
            if (type === "reset") {
                dispatch(
                    AuditLogActions.getAuditLogData({
                        MISSING_PARAMS: null,
                        MISSING_TYPE: null,
                        STATUS: null,
                        SYS_TS_DATETIME_START: null,
                        SYS_TS_DATETIME_END: null
                    }),
                );
                return;
            }
            const value = filterList[changedColumnIndex]?.map(v => {
                if (v instanceof Date) {
                    return v.valueOf();
                }
                return v;
            });
            dispatch(
                AuditLogActions.getAuditLogData({
                    [column]: value
                }),
            );
        },
        onChangePage: currentPage => {
            dispatch(AuditLogActions.getAuditLogData({ page: currentPage + 1 }));
        },
        onChangeRowsPerPage: numberOfRows => {
            dispatch(AuditLogActions.getAuditLogData({ perPage: numberOfRows }));
        },
        onColumnSortChange: (changedColumn, direction) => {
            dispatch(
                AuditLogActions.getAuditLogData({
                    sortBy: changedColumn,
                    orderBy: direction
                }),
            );
        },
        // onRowSelectionChange,
        // onRowClick,
        // onSelectionChange: selectedRows => {
        //     console.log("selectedRows : ", selectedRows);
        // },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress size={24} />
                ) : (
                    "Sorry, there is no matching data to display"
                )
            }
        }
    };

    return (
        <div>
            <ShowAuditLogDetails
              isEdit={isEdit}
              open={open}
                //   onRowClick={onRowClick}
              setOpen={setOpen}
              ref={editRef}
            />
            <>
                <MuiThemeProvider>
                    <MUIDataTable
                      title={(
                            <Typography variant="h6">
                                Audit Logs
                                {loading && (
                                    <CircularProgress
                                      size={24}
                                      style={{
                                            marginLeft: 15,
                                            position: "relative",
                                            top: 4
                                        }}
                                    />
                                )}
                            </Typography>
                          )}
                      data={data}
                      columns={auditLogColumns}
                      options={auditLogOptions}
                    />
                </MuiThemeProvider>
            </>
        </div>
    );
};

export default AuditLogTable;
