import RuleBasedOptimizationCHWPActionTypes from "./RuleBasedOptimizationCHWPActionTypes";

import API from "../../utils/API";
import URL from "../../utils/URL";

// TODO: use interceptor to send auth token header
function getRequestConfig(options = {}) {
    const token = localStorage.getItem("token");
    return {
        timeout: 30000,
        headers: {
            "x-auth-token": token
        },
        ...options
    };
}

export const RuleBasedOptimizationCHWPConfigActions = {
    init: payload => ({
        type: RuleBasedOptimizationCHWPActionTypes.SET_OPTIMIZATION_CHWP_CONFIG,
        payload: { loading: true, error: null, ...payload }
    }),
    setError: error => ({
        type: RuleBasedOptimizationCHWPActionTypes.SET_OPTIMIZATION_CHWP_CONFIG,
        payload: { loading: false, error }
    }),
    setData: data => ({
        type: RuleBasedOptimizationCHWPActionTypes.SET_OPTIMIZATION_CHWP_CONFIG_DATA,
        payload: { loading: false, data }
    }),
    fetch: () => dispatch => {
        dispatch(RuleBasedOptimizationCHWPConfigActions.init());
        API.get(URL.DATA.RULE_OPTMIZATION.CHWP_CONFIG, getRequestConfig())
            .then(({ data }) => dispatch(RuleBasedOptimizationCHWPConfigActions.setData(data)))
            .catch(error =>
                dispatch(RuleBasedOptimizationCHWPConfigActions.setError(error.message)),
            );
    },
    save: data => dispatch => {
        dispatch(RuleBasedOptimizationCHWPConfigActions.init());
        API.post(URL.DATA.RULE_OPTMIZATION.CHWP_CONFIG, data, getRequestConfig())
            .then(() => dispatch(RuleBasedOptimizationCHWPConfigActions.fetch()))
            .catch(error =>
                dispatch(RuleBasedOptimizationCHWPConfigActions.setError(error.message)),
            );
    }
};

export const RuleBasedOptimizationCHWPMinMaxLoadActions = {
    init: payload => ({
        type: RuleBasedOptimizationCHWPActionTypes.SET_OPTIMIZATION_CHWP_MIN_MAX_LOAD,
        payload: { loading: true, error: null, ...payload }
    }),
    setError: error => ({
        type: RuleBasedOptimizationCHWPActionTypes.SET_OPTIMIZATION_CHWP_MIN_MAX_LOAD,
        payload: { loading: false, error }
    }),
    setData: data => ({
        type: RuleBasedOptimizationCHWPActionTypes.SET_OPTIMIZATION_CHWP_MIN_MAX_LOAD_DATA,
        payload: { loading: false, data }
    }),
    fetch: () => dispatch => {
        dispatch(RuleBasedOptimizationCHWPMinMaxLoadActions.init());
        API.get(URL.DATA.RULE_OPTMIZATION.CHWP_MIN_MAX_LOAD, getRequestConfig())
            .then(({ data }) => dispatch(RuleBasedOptimizationCHWPMinMaxLoadActions.setData(data)))
            .catch(error =>
                dispatch(RuleBasedOptimizationCHWPConfigActions.setError(error.message)),
            );
    },
    delete: id => dispatch => {
        dispatch(RuleBasedOptimizationCHWPMinMaxLoadActions.init({ data: [] }));
        const url = `${URL.DATA.RULE_OPTMIZATION.CHWP_MIN_MAX_LOAD}/${id}`;
        API.delete(url, getRequestConfig())
            .then(() => dispatch(RuleBasedOptimizationCHWPMinMaxLoadActions.fetch()))
            .catch(error =>
                dispatch(RuleBasedOptimizationCHWPMinMaxLoadActions.setError(error.message)),
            );
    }
};

export const RuleBasedOptimizationCHWPMinMaxTimeActions = {
    init: payload => ({
        type: RuleBasedOptimizationCHWPActionTypes.SET_OPTIMIZATION_CHWP_MIN_MAX_TIME,
        payload: { loading: true, error: null, ...payload }
    }),
    setError: error => ({
        type: RuleBasedOptimizationCHWPActionTypes.SET_OPTIMIZATION_CHWP_MIN_MAX_TIME,
        payload: { loading: false, error }
    }),
    setData: data => ({
        type: RuleBasedOptimizationCHWPActionTypes.SET_OPTIMIZATION_CHWP_MIN_MAX_TIME_DATA,
        payload: { loading: false, data }
    }),
    fetch: () => dispatch => {
        dispatch(RuleBasedOptimizationCHWPMinMaxTimeActions.init());
        API.get(URL.DATA.RULE_OPTMIZATION.CHWP_MIN_MAX_TIME, getRequestConfig())
            .then(({ data }) => dispatch(RuleBasedOptimizationCHWPMinMaxTimeActions.setData(data)))
            .catch(error =>
                dispatch(RuleBasedOptimizationCHWPConfigActions.setError(error.message)),
            );
    },
    delete: id => dispatch => {
        dispatch(RuleBasedOptimizationCHWPMinMaxTimeActions.init({ data: [] }));
        const url = `${URL.DATA.RULE_OPTMIZATION.CHWP_MIN_MAX_TIME}/${id}`;
        API.delete(url, getRequestConfig())
            .then(() => dispatch(RuleBasedOptimizationCHWPMinMaxTimeActions.fetch()))
            .catch(error =>
                dispatch(RuleBasedOptimizationCHWPMinMaxTimeActions.setError(error.message)),
            );
    }
};
