/* eslint-disable max-len */
/* eslint-disable prefer-template */
import AuthenticationActionTypes from "./AuthenticationActionTypes";

const initialState = {
    token: "",
    isUserAuthenticated: false,
    currentPassword: "",
    currentUser: "",
    userId: "",
    error: "",
    refreshRate: "",
    isLiveData: "",
    defaultDate: "",
    lastUpdate: "",
    utcOffset: "",
    timezone: "",
    isSchematicsDeveloped: true,
    isAnalyticsDeveloped: true,
    isLifecycleCTDeveloped: true,
    isLifecycleCHWPDeveloped: true,
    isLifecycleCDWPDeveloped: true,
    isOptimizationDeveloped: true,
    isReportsDeveloped: true,
    isAnalyticsBeingDeveloped: true,
    isAutoMLActive: true,
    isGreenMarkCertified: true,
    bbpId: "",
    platform: "analytics"
};

const authenticationReducer = (state = initialState, action) => {
    // console.log(action);
    switch (action.type) {
        case AuthenticationActionTypes.SET_CREDENTIALS:
            if (action.payload.userId) {
                localStorage.setItem("isUserAuthenticated", true);
                localStorage.setItem("userId", action.payload.userId);
                localStorage.setItem("token", action.payload.token);
                localStorage.setItem("currentUser", action.credentials.email);
                localStorage.setItem("googleSignIn", action.credentials.googleSignIn);
                localStorage.setItem("encryptedLogin", action.credentials.encryptedLogin);
                localStorage.setItem("userType", action.payload.groupId);
                localStorage.setItem("imageOpenDark", action.payload.nightModeLogo.mainLogo);
                localStorage.setItem("imageOpenLight", action.payload.dayModeLogo.mainLogo);
                localStorage.setItem("imageCloseDark", action.payload.nightModeLogo.circleLogo);
                localStorage.setItem("imageCloseLight", action.payload.dayModeLogo.circleLogo);
                localStorage.setItem("open", true);
                localStorage.setItem("permission", action.payload.permissions);
                // localStorage.setItem("refreshRate", action.payload.refreshRate);
                localStorage.setItem("siteName", action.payload.activeSiteName);
                localStorage.setItem("siteStatus", action.payload.activeSiteStatus);
                localStorage.setItem("refreshRate", action.payload.refreshRate);
                localStorage.setItem("isLiveData", action.payload.isLiveData);
                localStorage.setItem("defaultDate", action.payload.defaultDate);
                localStorage.setItem(
                    "interval",
                    action.payload.userConfig.faultsAlarmsConfig.dateFilter.interval,
                );
                localStorage.setItem(
                    "intervalType",
                    action.payload.userConfig.faultsAlarmsConfig.dateFilter.intervalType,
                );
                localStorage.setItem("isSchematicsDeveloped", action.payload.isSchematicsDeveloped);
                localStorage.setItem("isAnalyticsDeveloped", action.payload.isAnalyticsDeveloped);
                localStorage.setItem(
                    "isLifecycleCTDeveloped",
                    action.payload.isLifecycleCTDeveloped,
                );
                localStorage.setItem(
                    "isLifecycleCHWPDeveloped",
                    action.payload.isLifecycleCHWPDeveloped,
                );
                localStorage.setItem(
                    "isLifecycleCDWPDeveloped",
                    action.payload.isLifecycleCDWPDeveloped,
                );
                localStorage.setItem(
                    "isOptimizationDeveloped",
                    action.payload.isOptimizationDeveloped,
                );
                localStorage.setItem("isReportsDeveloped", action.payload.isReportsDeveloped);
                localStorage.setItem(
                    "isAnalyticsBeingDeveloped",
                    action.payload.isAnalyticsBeingDeveloped,
                );
                localStorage.setItem("isAutoMLActive", action.payload.isAutoMLActive);
                localStorage.setItem("isGreenMarkCertified", action.payload.isGreenMarkCertified);
                localStorage.setItem("bbpId", action.payload.bbpId);
                localStorage.setItem("maintenance", JSON.stringify(action.payload.maintenance));
                localStorage.setItem("server_version", action.payload.version);
                localStorage.setItem("platform", "analytics");
                localStorage.setItem("activeSite", action.payload.activeSite);
                // const date = new Date();
                // date.setTime(date.getTime() + 1000 * 60 * 29);
                // const expires = "; expires=" + date.toGMTString();
                // document.cookie = `token=${action.payload.token}`;
                // document.cookie = `siteId=${action.payload.activeSite}`;
                // document.cookie = `siteName=${action.payload.activeSiteName}`;
            }
            // console.log(action.payload.activeSiteName,"sn");
            return {
                ...state,
                isUserAuthenticated: !!action.payload.token,
                token: action.payload.token,
                currentPassword: action.credentials.password,
                currentUser: action.credentials.user,
                googleSignIn: action.credentials.googleSignIn,
                encryptedLogin: action.credentials.encryptedLogin,
                userId: action.payload.userId,
                error: action.payload.details,
                refreshRate: action.payload.refreshRate,
                isLiveData: action.payload.isLiveData,
                defaultDate: action.payload.defaultDate,
                isSchematicsDeveloped: action.payload.isSchematicsDeveloped,
                isAnalyticsDeveloped: action.payload.isAnalyticsDeveloped,
                isLifecycleCTDeveloped: action.payload.isLifecycleCTDeveloped,
                isLifecycleCHWPDeveloped: action.payload.isLifecycleCHWPDeveloped,
                isLifecycleCDWPDeveloped: action.payload.isLifecycleCDWPDeveloped,
                isOptimizationDeveloped: action.payload.isOptimizationDeveloped,
                isReportsDeveloped: action.payload.isReportsDeveloped,
                isAnalyticsBeingDeveloped: action.payload.isAnalyticsBeingDeveloped,
                isAutoMLActive: action.payload.isAutoMLActive,
                isGreenMarkCertified: action.payload.isGreenMarkCertified,
                bbpId: action.payload.bbpId
            };

        case AuthenticationActionTypes.UPDATE_CHANGED_PASSWORD:
            return {
                ...state,
                currentPassword: action.credentials.newPassword
            };
        case AuthenticationActionTypes.HANDLE_CHANGE_SITE:
            localStorage.setItem("isLiveData", action.credentials.isLiveData);
            localStorage.setItem("defaultDate", action.credentials.defaultDate);
            localStorage.setItem("isSchematicsDeveloped", action.credentials.isSchematicsDeveloped);
            localStorage.setItem("isAnalyticsDeveloped", action.credentials.isAnalyticsDeveloped);
            localStorage.setItem(
                "isLifecycleCTDeveloped",
                action.credentials.isLifecycleCTDeveloped,
            );
            localStorage.setItem(
                "isLifecycleCHWPDeveloped",
                action.credentials.isLifecycleCHWPDeveloped,
            );
            localStorage.setItem(
                "isLifecycleCDWPDeveloped",
                action.credentials.isLifecycleCDWPDeveloped,
            );
            localStorage.setItem(
                "isOptimizationDeveloped",
                action.credentials.isOptimizationDeveloped,
            );
            localStorage.setItem("isReportsDeveloped", action.credentials.isReportsDeveloped);
            localStorage.setItem(
                "isAnalyticsBeingDeveloped",
                action.credentials.isAnalyticsBeingDeveloped,
            );
            localStorage.setItem("isAutoMLActive", action.credentials.isAutoMLActive);
            localStorage.setItem("isGreenMarkCertified", action.credentials.isGreenMarkCertified);
            localStorage.setItem("bbpId", action.credentials.bbpId);
            localStorage.setItem("siteVersion", action.credentials.version);
            return {
                ...state,
                isLiveData: action.credentials.isLiveData,
                defaultDate: action.credentials.defaultDate,
                isSchematicsDeveloped: action.credentials.isSchematicsDeveloped,
                isAnalyticsDeveloped: action.credentials.isAnalyticsDeveloped,
                isLifecycleCTDeveloped: action.credentials.isLifecycleCTDeveloped,
                isLifecycleCHWPDeveloped: action.credentials.isLifecycleCHWPDeveloped,
                isLifecycleCDWPDeveloped: action.credentials.isLifecycleCDWPDeveloped,
                isOptimizationDeveloped: action.credentials.isOptimizationDeveloped,
                isReportsDeveloped: action.credentials.isReportsDeveloped,
                isAnalyticsBeingDeveloped: action.credentials.isAnalyticsBeingDeveloped,
                isAutoMLActive: action.credentials.isAutoMLActive,
                isGreenMarkCertified: action.credentials.isGreenMarkCertified,
                bbpId: action.credentials.bbpId
            };
        default:
            return state;
    }
};

export default authenticationReducer;
