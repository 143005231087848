import React, { useEffect } from "react";
import { Grid, Tab, Tabs, Paper, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RangeSlider from "../components/RangeSlider";

const FaultAlarmsTabs = props => {
    const {
        value,
        handleChange,
        dates,
        setTabDates,
        setTabMax,
        setTabMin,
        startAnalyticDate,
        endAnalyticDate,
        max,
        min,
        timePeriod,
        handleSliderChange,
        handleEndDateInput,
        handleStartDateInput,
        onSliderChangeCommitted,
        code,
        parameter
    } = props;
    const tabStyle = {
        fontSize: "13px",
        fontWeight: "500",
        textTransform: "capitalize",
        minWidth: "100px",
        marginTop: "6px"
    };

    const useStyles = makeStyles(theme => ({
        headerStyle: {
            // width: "50%",
            // marginLeft: "auto",
            // [theme.breakpoints.up("xs")]: {
            //     marginLeft: "0px"
            // },
            // [theme.breakpoints.up("sm")]: {
            //     marginLeft: "0px",
            //     background: "none"
            // },
            // [theme.breakpoints.up("1160")]: {
            //     marginLeft: "auto"
            // },
            // [theme.breakpoints.up("lg")]: {
            //     marginLeft: "auto"
            // },
            // [theme.breakpoints.up("xl")]: {
            //     marginLeft: "auto"
            // }
            background: "none"
        },
        grid: {
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : // : theme.palette.common.white,
                      "none",
            [theme.breakpoints.up("1160")]: {
                height: "8vh"
            },
            [theme.breakpoints.up("lg")]: {
                height: "8vh"
            },
            [theme.breakpoints.up("xl")]: {
                height: "8vh"
            },
            [theme.breakpoints.down("sm")]: {
                height: value === "support" ? "8vh" : "160px"
            },
            [theme.breakpoints.down("xs")]: {
                height: value === "support" ? "8vh" : "160px"
            }
        }
    }));
    const classes = useStyles();

    const getTimeBuffer = period => {
        let buffer;
        switch (period) {
            case "month":
            case "months":
                buffer = [2629800000 * 3, 0];
                break;
            case "day":
            case "days":
                buffer = [86400000, 86400000];
                break;
            case "hour":
            case "hours":
                buffer = [3600000 * 4, 3600000 * 4];
                break;
            case "min":
            case "mins":
                buffer = [3600000 * 4, 3600000 * 4];
                break;
            default:
                buffer = [86400000, 86400000];
        }
        return buffer;
    };
    useEffect(() => {
        const displayBuffer = 0.15; // 15%
        const TIME_BUFFER = getTimeBuffer(timePeriod);
        const duration = endAnalyticDate - startAnalyticDate;
        const minPreValue = (TIME_BUFFER[1] / 3) * 20;
        let preBuffer = TIME_BUFFER[0];
        let postBuffer = TIME_BUFFER[1];
        if (duration > minPreValue) {
            preBuffer = duration * displayBuffer;
            postBuffer = duration * displayBuffer;
        }
        setTabDates([startAnalyticDate - preBuffer, endAnalyticDate + postBuffer]);
        setTabMin(startAnalyticDate - preBuffer);
        setTabMax(endAnalyticDate + postBuffer);
    }, [
        parameter,
        code,
        setTabDates,
        startAnalyticDate,
        endAnalyticDate,
        setTabMin,
        setTabMax,
        timePeriod
    ]);

    const isNarrow = useMediaQuery("(max-width: 850px)");

    return (
        <Grid container item xs={12} className={classes.grid}>
            <Grid item xs={12} md={6}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons={isNarrow ? "on" : "auto"}
                >
                    {/* <Tab style={tabStyle} value="support" label="Label Support" wrapped /> */}
                    {/* <Tab style={tabStyle} value="parameter" label="Parameter" wrapped /> */}
                    <Tab style={tabStyle} value="details" label="Parameter Details" wrapped />
                    <Tab style={tabStyle} value="overview" label="System Overview" wrapped />
                </Tabs>
            </Grid>

            {/* {value === "support" ? null : ( */}
            <Grid item xs={12} md={6}>
                <Paper elevation={0} className={classes.headerStyle}>
                    <RangeSlider
                      dates={dates}
                      max={max}
                      min={min}
                      handleDates={handleSliderChange}
                      onSliderChangeCommitted={onSliderChangeCommitted}
                      handleStartInput={handleStartDateInput}
                      handleEndInput={handleEndDateInput}
                      id="settings"
                    />
                </Paper>
            </Grid>
            {/* )} */}
        </Grid>
    );
};
export default FaultAlarmsTabs;
