import React, { useState, useRef, useEffect, useCallback } from "react";

import API from "../../../utils/API";
import URL from "../../../utils/URL";

import DwhDataTable from "../DwhDataTable";
import EditSiteEnvironment from "./EditSiteEnvironment";

const SiteEnvironment = () => {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [loading, setLoading] = useState(false);

    const editRef = useRef();

    const fetchData = useCallback(async siteId => {
        try {
            setLoading(true);

            const url = URL.DATA.DWH.SITE_ENVIRONMENT;
            const response = await API.get(url, {
                params: {
                    siteId,
                    _populate: "site",
                    _populate_select: {
                        site: "siteId siteName"
                    },
                    _sort: "id",
                    _order: "ASC"
                },
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            if (response.data) {
                setData(response.data);
            }
        } catch (err) {
            if (err.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleClickDataTable = (e, { action, params }) => {
        switch (action) {
            case "add":
                editRef.current.init();
                setIsEdit(false);
                setOpen(true);
                break;
            case "edit":
                {
                    const { site, ...environment } = data[params.dataIndex];
                    editRef.current.update({
                        site: site._id,
                        siteName: site.siteName,
                        siteId: site.siteId,
                        ...environment
                    });
                    setIsEdit(true);
                    setOpen(true);
                }
                break;
            default:
        }
    };

    const handleSaveSuccess = siteId => {
        fetchData(siteId);
    };

    const columns = [
        { actions: ["edit"] },
        { label: "Site Name", name: "site.siteName" },
        { label: "Site Number", name: "site.siteId" },
        { label: "Plant Room WBT", name: "plantRoomWBT" },
        { label: "Plant Room DBT", name: "plantRoomDBT" },
        { label: "Humidity", name: "plantRoomHumidity" },
        { label: "Climate", name: "climate" },
        { label: "Created Date", name: "createdAt", _options: { type: "date" }, narrow: true },
        { label: "Updated Date", name: "updatedAt", _options: { type: "date" }, narrow: true }
    ];

    const options = {
        enableNestedDataAccess: "."
    };

    return (
        <>
            <DwhDataTable
              name="Site Environment"
              loading={loading}
              data={data}
              columns={columns}
              options={options}
              onClick={handleClickDataTable}
            />
            <EditSiteEnvironment
              ref={editRef}
              isEdit={isEdit}
              open={open}
              setOpen={setOpen}
              onSuccess={handleSaveSuccess}
            />
        </>
    );
};

export default SiteEnvironment;
