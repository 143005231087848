import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Button,
    Grid,
    Paper,
    Typography,
    Dialog,
    List,
    ListItem,
    ListItemText,
    makeStyles
} from "@material-ui/core";

import useQueryParams from "../../../hooks/useQueryParams";

import Colors from "../../../utils/Colors";
import api from "../../../utils/API";
import URL from "../../../utils/URL";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    label: {
        color: Colors.DARK_BLUE
    },
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1)
        }
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        "& img": {
            objectFit: "contain"
        }
    }
}));

const CoolingTowerSelector = () => {
    const { getQuery, setQuery, deleteQuery } = useQueryParams();
    const siteId = getQuery("siteNumber");
    const [ctId, setCtId] = useState(getQuery("ctId"));

    const [open, setOpen] = useState(false);
    const [coolingTowers, setCoolingTowers] = useState([]);

    const history = useHistory();
    const location = useLocation();

    const isMounted = useRef(false);

    useEffect(() => {
        const fetchCoolingTowers = async () => {
            try {
                const response = await api.get(URL.DATA.DWH.SITE_COOLING_TOWER, {
                    headers: {
                        "x-auth-token": localStorage.token
                    },
                    params: {
                        siteId,
                        isActive: true,
                        _select: ["_id", "ctId", "ctBrand", "ctModel"],
                        _sort: "ctId",
                        _order: "asc"
                    }
                });

                if (isMounted.current) {
                    setCoolingTowers(response.data);
                }
            } catch (error) {
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        if (siteId) {
            fetchCoolingTowers();
        }

        return () => (isMounted.current = false);
    }, [siteId]);

    useEffect(() => {
        const resetCtId = () => {
            setCtId(null);
            history.push({
                pathname: location.pathname,
                search: deleteQuery("ctId")
            });
        };
        if (ctId) {
            if (coolingTowers.length) {
                const index = coolingTowers.findIndex(item => item.ctId === +ctId);
                if (index > -1) return;
            }
            resetCtId();
        }
    }, [coolingTowers, ctId, history, location.pathname, deleteQuery]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSelect = coolingTower => {
        if (coolingTower) {
            setCtId(coolingTower.ctId);
            history.push({
                pathname: location.pathname,
                search: setQuery({ ctId: coolingTower.ctId })
            });
        }
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={3} lg={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!coolingTowers.length}
                      onClick={handleOpen}
                    >
                        Select CT
                    </Button>
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    {ctId && (
                        <Typography className={classes.label}>
                            Selected CT ID:
                            {ctId}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <CoolingTowerSelectorDialog
              fullWidth
              maxWidth="xs"
              open={open}
              onClose={handleClose}
              onSelect={handleSelect}
              coolingTowers={coolingTowers}
              selected={ctId}
            />
        </Paper>
    );
};

const CoolingTowerSelectorDialog = ({ coolingTowers, selected, onSelect, ...props }) => {
    const classes = useStyles();

    const getDetails = coolingTower => {
        return (
            <ul className={classes.list}>
                <li>
                    Brand:
                    {coolingTower.ctBrand}
                </li>
                <li>
                    Model:
                    {coolingTower.ctModel}
                </li>
            </ul>
        );
    };

    return (
        <Dialog {...props}>
            <List>
                {coolingTowers.map(coolingTower => (
                    <ListItem
                      key={coolingTower._id}
                      selected={coolingTower.ctId === selected}
                      onClick={() => onSelect(coolingTower)}
                      className={classes.root}
                      button
                    >
                        <ListItemText
                          primary={(
                                <>
                                    <Typography variant="subtitle1">CoolingTower ID</Typography>
                                    <Typography variant="subtitle2">{coolingTower.ctId}</Typography>
                                </>
                              )}
                        />
                        <ListItemText primary={getDetails(coolingTower)} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default CoolingTowerSelector;
