/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "tableau-api";
import api from "../../../utils/API";
import TableauMenu from "./TableauMenu";
import SelectChart from "./SelectChart";
import URL from "../../../utils/URL";
import { VERBIAGE } from "../../../utils/enums";
import REFRESH_TIMER from "../../../utils/enums/Enum";

const SiteOverviewChartV2 = props => {
    const {
        url,
        addWorksheet,
        tall,
        combined,
        overview,
        xs,
        sm,
        md,
        lg,
        configValues,
        setConfigValues,
        handleTitleLink,
        alarmConfig,
        alarmConfigParam,
        initialFilter,
        value,
        tableauLink,
        tableauName,
        tableauOptions
        // download
    } = props;

    const [viz, setViz] = useState(null);
    const [chartTitle, setChartTitle] = useState("");
    const configLoaded = useRef(false);
    const [refresh, toggleRefresh] = useState(false);

    const isMounted = useRef(false);
    const getHeight = height => {
        if (combined && overview) return "560px";
        if (combined) return "820px";
        if (tall) return "600px";
        return height;
    };

    const [height, setHeight] = useState(getHeight("250px"));
    // const [height, setHeight] = useState(tall ? "600px" : combined ? "820px" : "250px");

    const initialGridSize = {
        xs,
        sm,
        md,
        lg
    };

    const [gridSize, setGridSize] = useState(initialGridSize);
    const container = useRef();

    const changeGrid = expand => {
        if (expand) {
            setGridSize(prev => {
                return {
                    xs,
                    sm: xs,
                    md: xs,
                    lg: xs
                };
            });
            setHeight("600px");
        } else {
            setGridSize(initialGridSize);
            setHeight(getHeight("250px"));
            // setHeight(tall ? "600px" : (combined ? "820px" : "250px"));
        }
    };

    const refreshChart = () => {
        toggleRefresh(prev => {
            return !prev;
        });
    };
    // const exportPDF = () => {
    //     viz.showExportPDFDialog();
    // };

    // Preserve order of useEffects

    const [flag, setFlag] = useState(true);

    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        const timer = setInterval(() => {
            if (viz !== null) {
                viz.refreshDataAsync();
            }
        }, timerValue);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (viz !== null) {
            viz.dispose();
            setTimeout(() => {
                setFlag(!flag);
            }, 500);
        }
    }, [url, height, configLoaded.current, refresh, localStorage.dark, value]);

    useEffect(() => {
        if (configValues && !configLoaded.current) {
            if (configValues.length > 0) {
                configLoaded.current = true;
            }
        }
        if (alarmConfig && !configLoaded.current) {
            if (alarmConfig.length > 0) {
                configLoaded.current = true;
            }
        }
    }, [configValues, alarmConfig]);

    // Fetch link from API, then pass into Tableau JS API
    useEffect(() => {
        // If Alarm Config page (passing in param values in configValues)
        // then wait for configValues to be loaded and passed in before init viz
        if (configValues && !configLoaded.current) {
            return;
        }

        // Tableau JS API initialising
        const initTableau = link => {
            const options = {
                ...tableauOptions,
                onFirstInteractive: () => {
                    // Pass the worksheet to the parent component for easier handling of filters

                    const parameterCallback = async e => {
                        if (configValues) {
                            const param = await e.getParameterAsync();
                            const paramName = param.getName();
                            const paramNewValue = param.getCurrentValue().formattedValue;
                            if (isMounted.current) {
                                setConfigValues(prev => {
                                    const index = prev.findIndex(
                                        config => config.name === paramName,
                                    );
                                    const arr = [...prev];
                                    arr[index] = { ...arr[index], value: paramNewValue };
                                    return arr;
                                });
                            }
                        }
                    };
                    vz.addEventListener("parameterValueChange", parameterCallback);

                    try {
                        // If dashboard
                        vz.getWorkbook()
                            .getActiveSheet()
                            .getWorksheets()
                            .forEach(ws => {
                                addWorksheet(ws);
                                if (initialFilter) {
                                    initialFilter(ws);
                                }
                            });
                    } catch (error) {
                        // If worksheet
                        addWorksheet(vz.getWorkbook().getActiveSheet());
                        if (initialFilter) {
                            initialFilter(vz.getWorkbook().getActiveSheet());
                        }
                    }
                }
            };
            if (configValues) {
                configValues.forEach(config => {
                    options[config.name] = config.value;
                    if (config.value === "True" || config.value === "False") {
                        options[config.name] = config.value === "True";
                    }
                });
            }

            if (alarmConfig && alarmConfigParam) {
                alarmConfig.forEach(row => {
                    if (row.param === alarmConfigParam) {
                        row.data.forEach(config => {
                            options[config.name] = config.value;
                        });
                    }
                });
            }

            const vz = new window.tableau.Viz(container.current, link, options);
            return vz;
        };

        const init = async () => {
            const themeState = localStorage.dark === "true" ? "DARK" : "LIGHT";
            const fetchData = async () => {
                try {
                    const response = await api.get(`${url}/${themeState}`, {
                        headers: {
                            "x-auth-token": localStorage.token
                        }
                    });
                    if (response.request.status === 403) {
                        fetchData();
                    } else {
                        if (isMounted.current) {
                            setChartTitle(response.data.name);
                        }
                        return response.data.link;
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.href = "/";
                    }
                }
            };

            const link = tableauLink ?? (await fetchData());

            if (isMounted.current) {
                setViz(initTableau(link));
                if (tableauName) {
                    setChartTitle(tableauName);
                }
            }
        };
        isMounted.current = true;

        init();

        return () => (isMounted.current = false);
    }, [flag]);

    // Paper styling
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(0.5),
            color: theme.palette.text.secondary,
            height: getHeight(height) // tall ? "600px" : combined ? "820px" : height
        },
        spacing: {
            padding: theme.spacing(0.5),
            height: "10px",
            opacity: 0
        },
        grid: {
            padding: "8px 5px"
        }
    }));

    const classes = useStyles();

    return (
        <Grid
          container
          item
          xs={gridSize.xs}
          sm={gridSize.sm}
          md={gridSize.md}
          lg={gridSize.lg}
          className={classes.grid}
        >
            <Grid item xs={12}>
                <TableauMenu
                  chartTitle={chartTitle}
                  changeGrid={changeGrid}
                  viz={viz}
                  headerValue
                  tall={tall}
                    // report={download}
                    // exportPDF={exportPDF}
                  refreshChart={refreshChart}
                  handleTitleLink={handleTitleLink}
                />
            </Grid>

            <Grid item xs={12}>
                <Paper className={classes.spacing} />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0}>
                    <div
                      ref={c => {
                            container.current = c;
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SiteOverviewChartV2;
