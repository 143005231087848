/* eslint-disable import/no-cycle */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import HomeActionTypes from "./HomeActionTypes";
import api from "../../../utils/API";
import URL from "../../../utils/URL";

const config = {
    headers: {
        // "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        "x-auth-token": ""
    }
};

function constructHeader() {
    const token = localStorage.getItem("token");
    // console.log(token);
    config.headers["x-auth-token"] = `${token}`;
    return config;
}

const HomeActions = {
    setHomeResponse: payload => ({
        type: HomeActionTypes.SET_PASSWORD,
        payload
    }),

    setDataResponse: data => ({
        type: HomeActionTypes.SET_DATA,
        data
    }),

    setLineGraphResponse: data => ({
        type: HomeActionTypes.SET_LINE_GRAPH,
        data
    }),

    setDataCompositeResponse: data => ({
        type: HomeActionTypes.SET_DATA_COMPOSITE,
        data
    }),

    setPostDataCompositeResponse: data => ({
        type: HomeActionTypes.SET_POST_DATA_COMPOSITE,
        data
    }),

    setDataCost: data => ({
        type: HomeActionTypes.SET_COST,
        data
    }),
    setDataSystemOverview: data => ({
        type: HomeActionTypes.SET_SYSTEM_OVERVIEW,
        data
    }),
    setSavings: data => ({
        type: HomeActionTypes.SET_SAVINGS,
        data
    }),
    setChillerGauge: data => ({
        type: HomeActionTypes.SET_CHILLER_GAUGE,
        data
    }),
    setPlant: data => ({
        type: HomeActionTypes.SET_PLANT,
        data
    }),
    setInterval: data => ({
        type: HomeActionTypes.SET_INTERVAL,
        data
    }),
    setOrientation: data => ({
        type: HomeActionTypes.SET_ORIENTATION,
        data
    }),
    toggleDesktopMobile: data => ({
        type: HomeActionTypes.TOGGLE_DESKTOP_MOBILE,
        data
    }),
    postCredentials: credentials => dispatch => {
        api.post(URL.AUTHENTICATION.LOGIN, {
            params: {
                currentPassword: credentials.currentPassword,
                newPassword: credentials.newPassword,
                confirmPassword: credentials.confirmPassword
            }
        })
            .then(response => {
                dispatch(HomeActions.setHomeResponse(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },

    getData: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.BAR_GRAPH, config)
            .then(response => {
                dispatch(HomeActions.setDataResponse(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },

    postLineGraph: credentials => dispatch => {
        constructHeader();
        api.post(URL.DATA.CHART, credentials.requestBody, config)
            .then(response => {
                dispatch(HomeActions.setLineGraphResponse(response));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },

    getDataComposite: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.LINE_POINT, config)
            .then(response => {
                dispatch(HomeActions.setDataCompositeResponse(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },

    getCost: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.ANOMOLY, config)
            .then(response => {
                dispatch(HomeActions.setDataCost(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },

    postDataComposite: credentials => dispatch => {
        constructHeader();
        const userId = localStorage.getItem("userId");
        api.put(
            URL.DATA.DATEFILTER + "" + userId,
            {
                startDate: credentials.startDate,
                endDate: credentials.endDate
            },
            config,
        )
            .then(() => {
                window.location.reload();
                // dispatch(HomeActions.getCost());
                // dispatch(HomeActions.getDataComposite());
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },

    getSystemOverview: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.SITEOVERVIEW, config)
            .then(response => {
                dispatch(HomeActions.setDataSystemOverview(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    fetchSavings: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.SAVINGS, config)
            .then(response => {
                dispatch(HomeActions.setSavings(response.data.savings));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    fetchChillerGauge: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.CHILLER_TABLE_GAUGE, config)
            .then(response => {
                dispatch(HomeActions.setChillerGauge(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    fetchPlantChart: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.PLANT, config)
            .then(response => {
                dispatch(HomeActions.setPlant(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    updateInterval: interval => dispatch => {
        constructHeader();
        api.put(
            URL.DATA.INTERVAL,
            {
                interval: interval.interval,
                intervalType: interval.intervalType
            },
            config,
        )
            .then(() => {
                dispatch(HomeActions.getSystemOverview());
                dispatch(HomeActions.fetchPlantChart());
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    }
};

export default HomeActions;
