/* eslint-disable max-len */
/* eslint-disable no-useless-escape */

const VERSION = "v2.5";

/*
 Use MARKDOWN syntax for change-notes
 See https://www.markdownguide.org/basic-syntax for guide
*/
const CHANGE_NOTES = `

# Release Notes

## Version 1.1.0 ( *released 18 June 2021* )
### Features
* Google Sign In
* Secured login
* Site specific Efficiency values configuration
* Auto Mapping generator UI
* User Session Monitoring
* Savings Estimate configuration
* Version History

### Fixes
* Error Handling for missing data/image

---
`;

const OLD_CHANGES = `

# Previous Releases

## Version 1.0.4 ( *released 08 June 2021* )

### Features
* Support for multiple currency and timezone
* Site Settings: Manage Assets/Images
* Schematics: Supports for component frequency status

### Fixes
* Fix Portfolio Map doesn't always select the 1st tab
* Webapp unable to change site's map
* Unable to upload name map for more than 10 equipments

---
`;

const SITE_10003_CHANGES = `

# Global Foundries (DF - 7G) Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Report Deployed

---
`;

const SITE_10020_CHANGES = `

# Tiong Bahru Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed

---
`;

const SITE_10022_CHANGES = `

# 3M (Tuas) Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Report Deployed

---
`;

const SITE_10023_CHANGES = `

# Regent Hotel Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Report Deployed

---
`;

const SITE_10027_CHANGES = `

# Capitaland (Galaxis) Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Analytics Deployed
  - Analytics Overview
  - Faults & Alarms
  - Equipment Lifecycle
  - Alarm Config
  - Energy Optimization

---
`;

const SITE_10028_CHANGES = `

# Orchard Central Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Report Deployed

---
`;

const SITE_10030_CHANGES = `

# Capitaland (Atrium) Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Analytics Deployed
  - Analytics Overview
  - Faults & Alarms
  - Equipment Lifecycle
  - Alarm Config
  - Energy Optimization
* Reports Deployed

---
`;

const SITE_10032_CHANGES = `

# Causeway Point Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Report Deployed

---
`;

const SITE_14002_CHANGES = `

# Impact (Exhibition Center) Changes

## Features Available ( *to be released soon* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Reports Deployed

---
`;

const SITE_14003_CHANGES = `

# Impact (Challenger) Changes

## Features Available ( *to be released soon* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Reports Deployed

---
`;

const SITE_16002_CHANGES = `

# Keppel IFC Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Report Deployed

---
`;

const SITE_16003_CHANGES = `

# Central Park (Jakarta) Changes

## Features Available ( *released 18 June 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Report Deployed

---
`;

const SITE_99009_CHANGES = `

# BBP Demo Site Changes

## Features Available ( *released 01 January 2021* )
* Plant Overview Deployed
* Portfolio Overview Deployed
* Plant Schematics Deployed
* Report Deployed

---
`;

export default {
    VERSION,
    CHANGE_NOTES,
    OLD_CHANGES,
    SITE_10003_CHANGES,
    SITE_10020_CHANGES,
    SITE_10022_CHANGES,
    SITE_10023_CHANGES,
    SITE_10027_CHANGES,
    SITE_10028_CHANGES,
    SITE_10030_CHANGES,
    SITE_10032_CHANGES,
    SITE_14002_CHANGES,
    SITE_14003_CHANGES,
    SITE_16002_CHANGES,
    SITE_16003_CHANGES,
    SITE_99009_CHANGES
};
