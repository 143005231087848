/* eslint-disable react/destructuring-assignment  */
/* eslint-disable array-callback-return  */
/* eslint-disable no-unused-vars  */
/* eslint-disable no-nested-ternary  */
/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint max-len: ["error", { "code": 120 }] */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { Paper } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CardSavings from "../../common/components/CardSavings";
import ChillerTable from "../../common/components/ChillerTable";
import "../styles/siteOverview.scss";
import { REFRESH_TIMER, IMAGE, VERBIAGE } from "../../../utils/enums/index";
import DialGaugeChart from "../../common/charts/DialGaugeChart";
import HomeActions from "../redux/HomeActions";
import SystemHeader from "../../common/components/SystemHeader";
import SiteOverviewChart from "../../common/components/SiteOverviewChart";
import URL from "../../../utils/URL";
import Time from "../../common/components/Time";
import SiteOverviewTabs from "../../common/components/SiteOverviewTabs";
import api from "../../../utils/API";

const MobileDetect = require("mobile-detect");

let initialFilterCalled = 0;

const SiteOverview = props => {
    const { home, fetchSavings, fetchChillerGauge, authentication } = props;

    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            paddingLeft: "10px",
            paddingRight: "5px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : "unset"
        },
        paper: {
            padding: theme.spacing(0),
            width: "100%",
            height: "230px",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "white"
        },
        saving: {
            // width: "100%",
            // height: "100%",
            padding: theme.spacing(0),
            width: "100%",
            // height: "29vh",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "white"
        },
        gauge: {
            width: "100%",
            height: "100%"
        },
        grid: {
            padding: "8px 5px"
        },
        savingcard: {
            width: "100%",
            height: "100%",

            [theme.breakpoints.up("sm")]: {
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center"
            },
            [theme.breakpoints.up("md")]: {
                width: "100%",
                height: "100%"
            }
        },
        settings: {
            padding: theme.spacing(0),
            width: "100%",
            height: "5vh",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "white"
        },
        tabContainer: {
            flexDirection: "column",
            height: "60px",
            width: "calc(100% - 8px)"
        },
        fixed: {
            display: "flex"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box"
        }
    }));
    const classes = useStyles();
    // const [columnName, setColumnName] = useState("column_3");
    // const [yLabel, setYLabel] = useState("RTon");
    // const [line, setLine] = useState("2");
    const [randomNumbers, setRandomNumbers] = useState(Array(4).map(Math.random));

    const [value, setValue] = useState("systemPerformance");
    const [worksheets, setWorksheets] = useState([]);
    const handleChange = (event, newValue) => {
        initialFilterCalled = 0;
        setWorksheets([]);
        setValue(newValue);
    };

    const [overviewChartList, setOverviewChartList] = useState([]);

    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    const [interval, setInterval] = useState();
    const [intervalType, setIntervalType] = useState();

    // Just for the Last N period datetime filter
    const [timeLength, setTimeLength] = useState("3");
    const [timePeriod, setTimePeriod] = useState("Days");

    const { isLiveData, defaultDate } = localStorage;
    const [combinedOverview, setCombinedOverview] = useState(false);
    const [combinedPerformance, setCombinedPerformance] = useState(null);
    const [combinedEfficiency, setCombinedEfficiency] = useState(false);

    const getCookie = name => {
        // Split cookie string and get all individual name=value pairs in an array
        const cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
          and compare it with the given string */
            if (name === cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }

        // Return null if not found
        return null;
    };

    const noBaselineSite = ["10027", "10030", "10031"];
    let noBaseline = false;
    const siteId = getCookie("siteId");
    if (noBaselineSite.includes(siteId)) {
        noBaseline = true;
    }

    const initialFilter = ws => {
        initialFilterCalled++;
        const lastUpdate = parseInt(localStorage.getItem("lastUpdate"), 10); // eslint-disable-line no-unused-vars
        const period = timePeriod.slice(0, -1); // removes the 's'
        // let date = new Date(Date.now());
        let date = new Date(lastUpdate);
        if (isLiveData === "false") {
            date = new Date(parseInt(defaultDate, 10));
        }
        date = date.getTime() - date.getTimezoneOffset() * 2 * 60 * 1000;
        // date = date.getTime() - date.getTimezoneOffset() * 60 * 1000 - 1;
        let range;
        switch (period) {
            case "Hour":
                range = 60;
                break;
            case "Day":
                range = 24 * 60;
                break;
            case "Week":
                range = 7 * 24 * 60;
                break;
            default:
                range = 1;
                break;
        }
        ws.applyRelativeDateFilterAsync("SYS_TS_DATETIME", {
            anchorDate: new Date(date),
            periodType: "Minute",
            rangeType: "LASTN",
            rangeN: range * parseInt(timeLength, 10)
        });
        // WEBAPP_REFSTART & WEBAPP_REFEND is not needed in this page
        // ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(date));
        // ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(date));
    };

    const handleSubmit = (intvl, intvlType) => {
        const lastUpdate = parseInt(localStorage.getItem("lastUpdate"), 10); // eslint-disable-line no-unused-vars
        setTimeLength(intvl);
        setTimePeriod(intvlType);
        const period = intvlType && intvlType.slice(0, -1); // removes the 's'
        // let date = new Date(Date.now());
        let date = new Date(lastUpdate);
        if (isLiveData === "false") {
            date = new Date(parseInt(defaultDate, 10));
        }
        // date = date.getTime() - date.getTimezoneOffset() * 60 * 1000 - 1;
        date = date.getTime() - date.getTimezoneOffset() * 2 * 60 * 1000;
        worksheets.forEach(ws => {
            let range;
            switch (period) {
                case "Hour":
                    range = 60;
                    break;
                case "Day":
                    range = 24 * 60;
                    break;
                case "Week":
                    range = 7 * 24 * 60;
                    break;
                default:
                    range = 1;
                    break;
            }
            if (ws.getSheetType() === "worksheet") {
                ws.applyRelativeDateFilterAsync("SYS_TS_DATETIME", {
                    anchorDate: new Date(date),
                    periodType: "Minute",
                    rangeType: "LASTN",
                    rangeN: range * parseInt(intvl, 10)
                });
            }
            // if (isLiveData === "false") {
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(date));
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(date));
            // }
        });
    };

    const delayRender = (callback, timeout) => {
        let timer;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                callback();
            }, timeout);
        };
    };

    // When worksheets are being loaded (added to worksheets array), apply filter
    useEffect(() => {
        if (worksheets.length > 0 && worksheets.length === initialFilterCalled) {
            handleSubmit(timeLength, timePeriod);
        }
    }, [handleSubmit, timeLength, timePeriod, worksheets]);

    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        fetchSavings();
        fetchChillerGauge();
        // fetchPlantChart();
        // getSystemOverview();
        const timer = setInterval(() => {
            fetchSavings();
            fetchChillerGauge();
            // fetchPlantChart();
            // getSystemOverview();
        }, timerValue);
        return () => clearInterval(timer);
    }, [fetchSavings, fetchChillerGauge]);

    useEffect(() => {
        const generateRandomNumbers = () => {
            setRandomNumbers((home.chillerGauge.radial || Array(4)).map(Math.random));
        };

        const render = delayRender(generateRandomNumbers, 200);
        window.addEventListener("resize", render);
        return () => window.removeEventListener("resize", render);
    });

    // const handleDropdown = data => {
    //     let columnNum = 3;
    //     let label = "RTon";
    //     let lineName = 2;
    //     if (data === "Cooling Load") {
    //         columnNum = 3;
    //         label = "RTon";
    //         lineName = 2;
    //     } else if (data === "Power") {
    //         columnNum = 4;
    //         label = "kw";
    //         lineName = 3;
    //     } else {
    //         columnNum = 5;
    //         label = "%";
    //         lineName = 4;
    //     }
    //     const column = `column_${columnNum}`;
    //     setColumnName(column);
    //     setYLabel(label);
    //     setLine(lineName);
    // };

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const list = [];
                const response = await api.get(
                    URL.DATA.SITE_OVERVIEW_CHARTS.SYSTEM_OVERVIEW_CHARTS,
                    {
                        headers: {
                            "x-auth-token": localStorage.token
                        }
                    },
                );
                response.data.forEach(i => {
                    const url = `${URL.DATA.ANALYTICS.CHARTS.CHART}/CHART_${i}/system`;
                    list.push(url);
                });
                if (isMounted) {
                    if (list.length === 1) {
                        setCombinedOverview(true);
                    }
                    setOverviewChartList(list);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();

        return () => (isMounted = false);
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const list = [];
                const response = await api.get(
                    URL.DATA.SITE_OVERVIEW_CHARTS.SYSTEM_EFFICIENCY_CHARTS,
                    {
                        headers: {
                            "x-auth-token": localStorage.token
                        }
                    },
                );
                response.data.forEach(i => {
                    const url = `${URL.DATA.ANALYTICS.CHARTS.CHART}/CHART_${i}/system`;
                    list.push(url);
                });
                if (isMounted) {
                    if (list.length === 1) {
                        setCombinedEfficiency(true);
                    }
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();

        return () => (isMounted = false);
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const list = [];
                const response = await api.get(
                    URL.DATA.SITE_OVERVIEW_CHARTS.SYSTEM_PERFORMANCE_CHARTS,
                    {
                        headers: {
                            "x-auth-token": localStorage.token
                        }
                    },
                );
                response.data.forEach(i => {
                    const url = `${URL.DATA.ANALYTICS.CHARTS.CHART}/CHART_${i}/system`;
                    list.push(url);
                });
                if (isMounted) {
                    setCombinedPerformance(list.length === 1);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setCombinedPerformance(false);
            }
        };
        fetchData();

        return () => (isMounted = false);
    }, []);

    const handleIcon = img => {
        const entries = Object.entries(IMAGE);
        let imgURL;
        entries.map(([prop, val]) => {
            if (prop === img) {
                imgURL = val;
            }
        });
        return imgURL;
    };

    const efficiencyColumnHeaders =
        home && home.systemData && home.systemData.column_headers
            ? home.systemData.column_headers
            : "";
    const plantColumnHeaders =
        home && home.plant && home.plant.column_headers ? home.plant.column_headers : "";
    return (
        <div className="main-wrapper">
            <div>
                <SystemHeader authentication={props.authentication} />
            </div>
            <div className={classNames(classes.root, classes.main)}>
                <Grid container>
                    <Grid container item xs={12} className={classes.grid}>
                        <span className={SiteOverview.styles.siteText}>
                            {VERBIAGE.MENU_TEXT.SYSTEM}
                        </span>
                    </Grid>
                    {home &&
                        home.savings &&
                        home.savings.map(card => {
                            if (home && home.savings.length < 4) {
                                return (
                                    <Grid
                                      container
                                      item
                                      xs={6}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      className={classes.grid}
                                      key={`${card.icon}_card`}
                                    >
                                        <Paper className={classNames(classes.saving)} elevation={0}>
                                            <CardSavings
                                              key={card.label}
                                              image={handleIcon(card.icon)}
                                              title={card.label}
                                              savings={card.value}
                                              unit={
                                                    card.tarrif
                                                        ? `Tarrif ${card.tarrif} | ${card.unit}`
                                                        : card.unit
                                                }
                                              copyRight={card.since}
                                              percent={card.percent}
                                            />
                                        </Paper>
                                    </Grid>
                                );
                            }
                            return (
                                <Grid
                                  container
                                  item
                                  xs={6}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  className={classes.grid}
                                  key={`${card.icon}_card`}
                                >
                                    <Paper className={classNames(classes.saving)} elevation={0}>
                                        <CardSavings
                                          key={card.label}
                                          image={handleIcon(card.icon)}
                                          title={card.label}
                                          savings={card.value}
                                          unit={
                                                card.tarrif
                                                    ? `Tarrif ${card.tarrif} | ${card.unit}`
                                                    : card.unit
                                            }
                                          copyRight={card.since}
                                          percent={card.percent}
                                        />
                                    </Paper>
                                </Grid>
                            );
                        })}

                    <Grid container item xs={12} className={classes.grid}>
                        <div className={SiteOverview.styles.chillerplantContainer}>
                            <div>
                                <div>
                                    <span className={SiteOverview.styles.plantText}>
                                        {VERBIAGE.SYSTEM_OVERVIEW.CHILLER_PLANT}
                                    </span>
                                </div>
                                {noBaseline ? (
                                    <div />
                                ) : (
                                    <div className={SiteOverview.styles.rowContainer}>
                                        <div className={SiteOverview.styles.targetContainer}>
                                            {VERBIAGE.TEXT.TARGET} 
{' '}
{home.chillerGauge.target}
{" "}
                                            {VERBIAGE.TEXT.EFF_UNIT}
                                        </div>
                                        <div className={SiteOverview.styles.underContainer}>|</div>
                                        <div className={SiteOverview.styles.baselineContainer}>
                                            {VERBIAGE.TEXT.BASELINE} 
{' '}
{home.chillerGauge.baseline}
{" "}
                                            {VERBIAGE.TEXT.EFF_UNIT}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* <div>
                  {" "}
                  <i
                    className="fa fa-ellipsis-v icon"
                    style={{ paddingTop: "0.6vh" }}
                  />
                </div> */}
                        </div>
                    </Grid>

                    <Grid container item xs={12} sm={12}>
                        <Grid container direction="row" item sm={12} md={7} lg={7}>
                            {home &&
                                home.chillerGauge &&
                                home.chillerGauge.radial &&
                                home.chillerGauge.radial.map((gauge, index) => (
                                    <Grid
                                      key={randomNumbers[index] || Math.random()}
                                      container
                                      direction="row"
                                      item
                                      xs={6}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      className={classes.grid}
                                    >
                                        <Paper className={classNames(classes.paper)} elevation={0}>
                                            <DialGaugeChart
                                              max={gauge.maxValue}
                                              min={gauge.minValue}
                                              labelValue={gauge.value}
                                              value={gauge.currentValue}
                                              valueColor={gauge.color}
                                              staticLabels={gauge.staticLabels}
                                              staticZones={gauge.staticZones}
                                              unit={gauge.unit}
                                              label={gauge.label}
                                            />
                                        </Paper>
                                    </Grid>
                                ))}
                        </Grid>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          item
                          xs={12}
                          sm={12}
                          md={5}
                          lg={5}
                          className={classes.grid}
                        >
                            <Paper className={classes.paper} elevation={0}>
                                <ChillerTable />
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* <Grid container item xs={12}>
              <Grid item xs={12} sm={12} md={7} lg={7} className={classes.grid}>
                <Paper className={classes.paper} elevation={0}>
                  <PlantChart
                    chartName="column_2"
                    lineName={plantColumnHeaders[1]}
                    height="80%"
                    width="100%"
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} className={classes.grid}>
                <Paper className={classes.paper} elevation={0}>
                  <PlantViewChart
                    chartName={columnName}
                    lineName={plantColumnHeaders[line]}
                    height="80%"
                    width="100%"
                    callPlantView={handleDropdown}
                    yLabel={yLabel}
                  />
                </Paper>
              </Grid>
            </Grid> */}

                    {/* <Grid item xs={12} sm={12} md={4} lg={4} className={classes.grid}>
              <div className={SiteOverview.styles.pumpContainer}>
                <span>{VERBIAGE.SYSTEM_OVERVIEW.CHILLER_EFFICIENCY}</span>
                <i className="fa fa-ellipsis-v" style={{ paddingTop: "0.6vh" }} />
              </div>

              <Paper className={classes.paper} elevation={0}>
                <ChillerEfficiencyChart
                  chartName="column_2"
                  lineName={efficiencyColumnHeaders[1]}
                  height="80%"
                  width="100%"
                />
              </Paper>
            </Grid> */}

                    {/* <Grid item xs={12} sm={12} md={4} lg={4} className={classes.grid}>
               <div className={SiteOverview.styles.pumpContainer}>
                <span>{VERBIAGE.SYSTEM_OVERVIEW.PUMP_EFFICIENCY}</span>
                <i className="fa fa-ellipsis-v" style={{ paddingTop: "0.6vh" }} />
              </div>
              <Paper className={classes.paper} elevation={0}>
                 <PumpEfficiencyChart
                  chartName={["column_4", "column_5"]}
                  lineName1={efficiencyColumnHeaders[3]}
                  lineName2={efficiencyColumnHeaders[4]}
                  height="80%"
                  width="100%"
                />
              </Paper>
            </Grid> */}
                    <Grid item xs={12} md={7}>
                        <SiteOverviewTabs
                          classes={classes}
                          value={value}
                          handleChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={5} className={classes.grid}>
                        <Time handleSubmit={handleSubmit} />
                        {/* </Paper> */}
                    </Grid>

                    {value === "systemPerformance" || value === "systemEfficiency" ? (
                        // For Performance tab
                        value === "systemPerformance" ? (
                            combinedPerformance === null ? (
                                <Grid item xs={12} className={classes.grid}>
                                    <Paper className={classes.paper} />
                                </Grid>
                            ) : combinedPerformance === true ? (
                                <SiteOverviewChart
                                  url={URL.DATA.SITE_OVERVIEW_CHARTS.SYSTEM_PERFORMANCE_COMBINED}
                                  addWorksheet={addWorksheet}
                                  combined
                                  overview
                                  xs={12}
                                  initialFilter={initialFilter}
                                />
                            ) : (
                                <React.Fragment>
                                    <SiteOverviewChart
                                      url={URL.DATA.SITE_OVERVIEW_CHARTS.SYS_PLANT_EFF}
                                      addWorksheet={addWorksheet}
                                      double
                                      xs={12}
                                      sm={12}
                                      md={7}
                                      lg={7}
                                      initialFilter={initialFilter}
                                      value={value}
                                    />
                                    <SiteOverviewChart
                                      url={URL.DATA.SITE_OVERVIEW_CHARTS.SYS_PLANT_RT}
                                      addWorksheet={addWorksheet}
                                      double
                                      xs={12}
                                      sm={12}
                                      md={5}
                                      lg={5}
                                      initialFilter={initialFilter}
                                    />
                                    <SiteOverviewChart
                                      url={URL.DATA.SITE_OVERVIEW_CHARTS.SYS_PLANT_KW}
                                      addWorksheet={addWorksheet}
                                      double
                                      xs={12}
                                      sm={12}
                                      md={7}
                                      lg={7}
                                      initialFilter={initialFilter}
                                    />
                                    <SiteOverviewChart
                                      url={URL.DATA.SITE_OVERVIEW_CHARTS.SYS_PLANT_HEATBAL}
                                      addWorksheet={addWorksheet}
                                      double
                                      xs={12}
                                      sm={12}
                                      md={5}
                                      lg={5}
                                      initialFilter={initialFilter}
                                    />
                                </React.Fragment>
                            )
                        ) : combinedEfficiency === true ? (
                            <SiteOverviewChart
                              url={URL.DATA.SITE_OVERVIEW_CHARTS.SYSTEM_EFFICIENCY_COMBINED}
                              addWorksheet={addWorksheet}
                              combined
                              overview
                              xs={12}
                              initialFilter={initialFilter}
                            />
                        ) : (
                            // For Efficiency tab
                            <React.Fragment>
                                <SiteOverviewChart
                                  url={URL.DATA.SITE_OVERVIEW_CHARTS.SYS_PLANT_EFF}
                                  addWorksheet={addWorksheet}
                                  double
                                  xs={12}
                                  sm={12}
                                  md={7}
                                  lg={7}
                                  initialFilter={initialFilter}
                                  value={value}
                                />
                                <SiteOverviewChart
                                  url={URL.DATA.SITE_OVERVIEW_CHARTS.EQP_CT_EFF}
                                  addWorksheet={addWorksheet}
                                  double
                                  xs={12}
                                  sm={12}
                                  md={5}
                                  lg={5}
                                  initialFilter={initialFilter}
                                />
                                <SiteOverviewChart
                                  url={URL.DATA.SITE_OVERVIEW_CHARTS.EQP_CH_EFF}
                                  addWorksheet={addWorksheet}
                                  double
                                  xs={12}
                                  sm={12}
                                  md={7}
                                  lg={7}
                                  initialFilter={initialFilter}
                                />
                                <SiteOverviewChart
                                  url={URL.DATA.SITE_OVERVIEW_CHARTS.PUMP_EFF}
                                  addWorksheet={addWorksheet}
                                  double
                                  xs={12}
                                  sm={12}
                                  md={5}
                                  lg={5}
                                  initialFilter={initialFilter}
                                />
                            </React.Fragment>
                        )
                    ) : combinedOverview === true ? (
                        <SiteOverviewChart
                          url={URL.DATA.SITE_OVERVIEW_CHARTS.SYSTEM_OVERVIEW_COMBINED}
                          addWorksheet={addWorksheet}
                          combined
                          xs={12}
                          initialFilter={initialFilter}
                        />
                    ) : (
                        // For Overview tab
                        <React.Fragment>
                            {overviewChartList.map(link => {
                                return (
                                    <SiteOverviewChart
                                      key={link}
                                      url={link}
                                      addWorksheet={addWorksheet}
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={4}
                                      initialFilter={initialFilter}
                                    />
                                );
                            })}
                        </React.Fragment>
                    )}

                    {/* <Grid item xs={12} sm={12} md={4} lg={4} className={classes.grid}>
              <div className={SiteOverview.styles.pumpContainer}>
                <span>{VERBIAGE.SYSTEM_OVERVIEW.CHILLER_EFFICIENCY}</span>
                <i className="fa fa-ellipsis-v" style={{ paddingTop: "0.6vh" }} />
              </div>

              <Paper className={classes.paper} elevation={0}>
                <ChillerEfficiencyChart
                  chartName="column_3"
                  lineName={efficiencyColumnHeaders[2]}
                  height="80%"
                  width="100%"
                />
              </Paper>
            </Grid> */}
                </Grid>
            </div>
        </div>
    );
};

SiteOverview.styles = {
    rootContainer: "webclient__siteOverview-container-root",
    siteContainer: "webclient__siteOverview-container-site",
    gaugeContainer: "webclient__siteOverview-container-gauge",
    plantContainer: "webclient__siteOverview-container-plant",
    tableContainer: "webclient__siteOverview-container-table",
    chillerplantContainer: "webclient__siteOverview-container-chillerplant",
    rowContainer: "webclient__siteOverview-container-row",
    underContainer: "webclient__siteOverview-container-under",
    plantChartContainer: "webclient__siteOverview-container-plant-chart",
    plantChart1Container: "webclient__siteOverview-container-plant-chart1",
    plantChart2Container: "webclient__siteOverview-container-plant-chart2",
    efficiencyContainer: "webclient__siteOverview-container-efficiency",
    pumpContainer: "webclient__siteOverview-container-pump",
    chillerContainer: "webclient__siteOverview-container-chiller",
    coolingContainer: "webclient__siteOverview-container-cooling",
    plantText: "webclient__siteOverview-plant-text",
    siteText: "webclient__siteOverview-site-text",
    targetContainer: "webclient__siteOverview-container-target",
    baselineContainer: "webclient__siteOverview-container-baseline"
};

const mapStateToProps = state => ({
    home: state.home,
    authentication: state.authentication
});

const mapDispatchToProps = dispatch => ({
    fetchSavings: () => {
        dispatch(HomeActions.fetchSavings());
    },
    fetchChillerGauge: () => {
        dispatch(HomeActions.fetchChillerGauge());
    }
    // fetchPlantChart: () => {
    //     dispatch(HomeActions.fetchPlantChart());
    // },
    // getSystemOverview: () => {
    //     dispatch(HomeActions.getSystemOverview());
    // }
});

SiteOverview.propTypes = {
    fetchSavings: PropTypes.func.isRequired,
    fetchChillerGauge: PropTypes.func.isRequired
    // fetchPlantChart: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SiteOverview));
