import AnomalyDiagnosisActionTypes from "./AnomalyDiagnosisActionTypes";

const initialState = {
    tableData: [],
    active: {
        start: "",
        equipment: "",
        duration: "",
        status: "",
        feedback: "",
        lastUpdated: "",
        reason1: "",
        reason2: "",
        reason3: ""
    },
    chartsURL: {
        equipmentEnergy: "",
        equipmentEfficiency: "",
        deviation: "",
        deviationPercentage: "",
        reason1: "",
        reason2: "",
        reason3: "",
        allParameters: ""
    }
};

const AnomalyDiagnosisReducer = (state = initialState, action) => {
    switch (action.type) {
        case AnomalyDiagnosisActionTypes.GET_DIAGNOSIS_TABLE:
            return {
                ...state,
                tableData: action.data,
                statusOfFilter: action.filterType
            };
        case AnomalyDiagnosisActionTypes.UPDATE_DIAGNOSIS_TABLE:
            return {
                ...state
            };
        case AnomalyDiagnosisActionTypes.GET_FEEDBACK:
            return {
                ...state,
                tableData: action.data,
                statusOfFilter: action.filterType
            };
        case AnomalyDiagnosisActionTypes.UPDATE_ACTIVE_DIAGNOSIS:
            localStorage.setItem("diagnosis_start", action.data.start);
            localStorage.setItem("diagnosis_equipment", action.data.equipment);

            return {
                ...state,
                active: action.data
            };
        // case AnomalyDiagnosisActionTypes.SET_ANALYTICS_CHIPS:
        //     return {
        //         ...state,
        //         analyticChip: action.data
        //     };
        // case AnomalyDiagnosisActionTypes.SET_LABEL:
        //     return {
        //         ...state
        //         // analyticLabel: action.data
        //     };
        // case AnomalyDiagnosisActionTypes.SET_CHIP_SEVERITY:
        //     return {
        //         ...state,
        //         severity: action.data
        //     };
        // case AnomalyDiagnosisActionTypes.SET_NOTES:
        //     return {
        //         ...state,
        //         notes: action.data
        //     };
        // case AnomalyDiagnosisActionTypes.SET_NOTES_CHIPS:
        //     return {
        //         ...state,
        //         notesChip: action.data
        //     };
        // case AnomalyDiagnosisActionTypes.SET_USERTAGS_CHIPS:
        //     return {
        //         ...state,
        //         userTagsChip: action.data
        //     };
        // case AnomalyDiagnosisActionTypes.GET_CHART_POSITION:
        //     return {
        //         ...state,
        //         chartData: action.data
        //     };
        // case AnomalyDiagnosisActionTypes.SET_ALARM_CONFIG:
        //     return {
        //         ...state,
        //         alarmConfig: action.data
        //     };
        default:
            return state;
    }
};

export default AnomalyDiagnosisReducer;
