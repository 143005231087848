import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";
import { useSelector } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Grid,
    FormLabel
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import omit from "lodash/omit";

import FormRenderField from "../../../dashboard/common/components/FormRenderField";

import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { getResponseErrorDetails, scrollIntoFirstErrorElement } from "../../../utils/helpers";

import useQueryParams from "../../../hooks/useQueryParams";

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    },
    hide: {
        display: "none"
    }
}));

const EditSiteWaterPump = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, onSuccess } = props;

    const { getQuery } = useQueryParams();
    const siteNumber = getQuery("siteNumber");

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const { SiteWaterPumpTypes } = useSelector(state => state.dwh.config);
    const siteWaterPumpSchema = useMemo(() => {
        return Yup.object().shape({
            wpType: Yup.mixed()
                .oneOf(SiteWaterPumpTypes)
                .label("Type"),
            wpMBrand: Yup.string()
                .label("Motor Brand")
                .required(),
            wpMModel: Yup.string()
                .label("Motor Model")
                .required(),
            wpPBrand: Yup.string()
                .label("Pump Brand")
                .required(),
            wpPModel: Yup.string()
                .label("Pump Model")
                .required(),
            typeOfStarter: Yup.string().label("Type of Starter"),
            isPrimary: Yup.boolean(),
            vsdBypassAvail: Yup.boolean(),
            wpOutForce: Yup.boolean(),
            wpOutForceValue: Yup.string()
                .label("Type of Starter")
                .when("isOutForce", (value, schema) => {
                    if (value[0]) return schema.required();
                    return schema;
                })
        });
    }, [SiteWaterPumpTypes]);

    const initData = {
        wpType: VERBIAGE.TEXT.EMPTY_STRING,
        isPrimary: false,
        wpMBrand: VERBIAGE.TEXT.EMPTY_STRING,
        wpMModel: VERBIAGE.TEXT.EMPTY_STRING,
        wpPBrand: VERBIAGE.TEXT.EMPTY_STRING,
        wpPModel: VERBIAGE.TEXT.EMPTY_STRING,
        typeOfStarter: VERBIAGE.TEXT.EMPTY_STRING,
        vsdBypassAvail: false,
        wpOutForce: false,
        wpOutForceValue: 0,
        isActive: true
    };

    const [values, setValues] = useState(initData);

    const {
        control,
        handleSubmit,
        watch,
        reset,
        setError: setFormError,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(siteWaterPumpSchema),
        values
    });
    const watchWpOutForce = watch("wpOutForce");

    useImperativeHandle(ref, () => ({
        init() {
            setValues({ ...initData });
        },
        update(data) {
            setValues({ ...initData, ...data });
        }
    }));

    useEffect(() => {
        if (Object.keys(errors).length) {
            scrollIntoFirstErrorElement({
                elementIds: Object.keys(errors)
            });
        } else {
            setError(null);
        }
    }, [errors]);

    const onClose = () => {
        reset(initData);
        setError(null);
        setOpen(false);
    };

    const handleResponseError = response => {
        const resError = getResponseErrorDetails(response);
        if (resError.type === "invalid") {
            Object.keys(resError.errors).forEach(field => {
                setFormError(field, { type: "invalid", message: resError.errors[field] });
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstErrorElement({ elementIds: Object.keys(resError.errors) });
        } else {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        }
    };

    const onSubmit = async data => {
        try {
            setLoading(true);

            const requestConfig = {
                headers: {
                    "x-auth-token": localStorage.token
                }
            };
            if (isEdit) {
                requestConfig.url = `${URL.DATA.DWH.SITE_WATER_PUMP}/${values._id}`;
                requestConfig.method = "PUT";
                requestConfig.data = data;
            } else {
                requestConfig.method = "POST";
                requestConfig.url = `${URL.DATA.DWH.SITE_WATER_PUMP}/${siteNumber}/create`;
                requestConfig.data = omit(data, "_id");
            }
            const response = await api.request(requestConfig);
            if (response.data?.status === "error") {
                handleResponseError(response);
                return;
            }
            if (onSuccess) {
                onSuccess(siteNumber);
            }
            onClose();
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else if (err.response.status === 404) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            } else {
                handleResponseError(err.response);
            }
        } finally {
            setLoading(false);
        }
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    const classes = useStyles();

    const inputs = useMemo(() => {
        return [
            {
                name: "wpId",
                label: "Water Pump ID",
                disabled: true,
                hide: !isEdit
            },
            {
                name: "isPrimary",
                label: "Is Primary",
                type: "switch",
                switchLabels: { true: "TRUE", false: "FALSE" },
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_IS_PRIMARY,
                required: true
            },
            {
                name: "wpMBrand",
                label: "Motor Brand",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_MOTOR_BRAND,
                required: true
            },
            {
                name: "wpMModel",
                label: "Motor Model",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_MOTOR_MODEL,
                required: true
            },
            {
                name: "wpPBrand",
                label: "Pump Brand",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_PUMP_BRAND,
                required: true
            },
            {
                name: "wpPModel",
                label: "Pump Model",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_PUMP_MODEL,
                required: true
            },
            {
                name: "wpType",
                label: "Type",
                type: "select",
                selectOptions: SiteWaterPumpTypes.filter(v => v !== ""),
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_TYPE
            },
            {
                name: "typeOfStarter",
                label: "Type of Starter",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_TYPE_OF_STARTER
            },
            {
                name: "vsdBypassAvail",
                label: "Is VSD Bypass",
                type: "switch",
                switchLabels: { true: "TRUE", false: "FALSE" },
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_IS_VSD_BYPASS,
                required: true
            },
            {
                name: "wpOutForce",
                label: "Is Out Force",
                type: "switch",
                switchLabels: { true: "TRUE", false: "FALSE" },
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_IS_OUT_FORCE,
                required: true
            },
            {
                name: "wpOutForceValue",
                label: "Out Force Value",
                type: "number",
                numberType: "double",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_OUT_FORCE_VALUE,
                required: true,
                hide: !watchWpOutForce
            },
            {
                name: "isActive",
                label: "Active",
                type: "switch",
                switchLabels: { true: "Yes", false: "No" }
            }
        ];
    }, [isEdit, SiteWaterPumpTypes, watchWpOutForce]);

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-site-dialog">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} site water pump data enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    {inputs.map(input => {
                        if (input.hide) return null;
                        return (
                            <Grid item container alignItems="center" key={input.name}>
                                <Grid item xs={3}>
                                    <FormLabel required={input.required} error={errors[input.name]}>
                                        {input.label}
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={9}>
                                    <Controller
                                      name={input.name}
                                      control={control}
                                      render={({ field: { value, onChange } }) => (
                                            <FormRenderField
                                              input={input}
                                              value={value}
                                              onChange={onChange}
                                              errors={errors}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                    {!!error && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography variant="subtitle1">{error}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                  color="primary"
                  onClick={handleSubmit(onSubmit, onError)}
                  disabled={loading}
                >
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={onClose} disabled={loading}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default EditSiteWaterPump;
