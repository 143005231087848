import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";

import TextInput from "../TextInput";
import AssetImg from "./AssetImg";
import api from "../../../../utils/API";
import URL from "../../../../utils/URL";
import VERBIAGE from "../../../../utils/enums/Verbiage";
import "../../styles/mapTable.scss";

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        variant: "subtitle1",
        textAlign: "center"
    }
}));
const AssetDetailForm = forwardRef((props, ref) => {
    const { open, setOpen } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const emptyData = {
        name: VERBIAGE.TEXT.EMPTY_STRING,
        type: VERBIAGE.TEXT.EMPTY_STRING,
        createdOn: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [formData, updateData] = useState(emptyData);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateData({
                ...formData,
                ...data
            });
        },
        getData() {
            return formData;
        }
    }));

    const handleRemove = async () => {
        setLoading(true);
        try {
            const url = URL.DATA.PLANT_SETTINGS.ASSETS_REMOVE;
            await api.delete(`${url}/${formData._id}`, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            setOpen(false);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setError(null);
        setOpen(false);
    };

    const classes = useStyles();
    return (
        <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle>Preview</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextInput placeholder="Name" value={formData.name} type="text" disabled />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                          placeholder="Created On"
                          value={formData.parsedDate}
                          type="text"
                          disabled
                        />
                    </Grid>
                    {error && (
                        <Grid item xs={12}>
                            <Typography color="error">{error}</Typography>
                        </Grid>
                    )}
                    <Grid item container justifyContent="center" alignItems="center" xs={12}>
                        {formData.url ? (
                            <img className="preview" src={formData.url} alt={formData.name} />
                        ) : (
                            <AssetImg className="preview" filename={formData.name} />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                        // eslint-disable-next-line no-alert
                        if (window.confirm("Are you sure you wish to remove this item?")) {
                            handleRemove();
                        }
                    }}
                >
                    Remove
                </Button>
                <Button disabled={loading} className={classes.error} onClick={handleClose}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

AssetDetailForm.styles = {
    inputContainer: "webclient__mapTable-input"
};

export default AssetDetailForm;
