import ActionTypes from "./DwhActionTypes";

const initialState = {
    loading: false,
    error: null,
    config: {
        SiteACSystemTypes: [],
        SiteConfiguration: [],
        SiteWaterPumpTypes: [],
        SiteInstrumentOrientations: [],
        ScadaTypes: [],
        CoolingTowerMtValTypes: [],
        CoolingTowerTypes: []
    }
};

const dwhReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case ActionTypes.SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case ActionTypes.SET_CONFIG:
            return {
                ...state,
                config: action.config
            };
        default:
            return state;
    }
};

export default dwhReducer;
