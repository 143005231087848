const RuleBasedOptimizationCDWPActionTypes = {
    SET_OPTIMIZATION_CDWP_CONFIG: "SET_OPTIMIZATION_CDWP_CONFIG",
    SET_OPTIMIZATION_CDWP_CONFIG_DATA: "SET_OPTIMIZATION_CDWP_CONFIG_DATA",
    SET_OPTIMIZATION_CDWP_MIN_MAX_LOAD: "SET_OPTIMIZATION_CDWP_MIN_MAX_LOAD",
    SET_OPTIMIZATION_CDWP_MIN_MAX_LOAD_DATA: "SET_OPTIMIZATION_CDWP_MIN_MAX_LOAD_DATA",
    SET_OPTIMIZATION_CDWP_MIN_MAX_TIME: "SET_OPTIMIZATION_CDWP_MIN_MAX_TIME",
    SET_OPTIMIZATION_CDWP_MIN_MAX_TIME_DATA: "SET_OPTIMIZATION_CDWP_MIN_MAX_TIME_DATA"
};

export default RuleBasedOptimizationCDWPActionTypes;
