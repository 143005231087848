import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
import "../styles/mapTable.scss";
import TextInput from "./TextInput";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

const EditMapTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");

    const emptyConfig = {
        mapName: VERBIAGE.TEXT.EMPTY_STRING,
        longitude: VERBIAGE.TEXT.EMPTY_STRING,
        latitude: VERBIAGE.TEXT.EMPTY_STRING,
        zoom: VERBIAGE.TEXT.EMPTY_STRING,
        mapId: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleMap = ({ target: { value } }) => {
        updateConfig({ ...config, mapName: value });
    };

    const handleLong = ({ target: { value } }) => {
        updateConfig({ ...config, longitude: value });
    };

    const handleLat = ({ target: { value } }) => {
        updateConfig({ ...config, latitude: value });
    };

    const handleZoom = ({ target: { value } }) => {
        updateConfig({ ...config, zoom: value });
    };

    const handleSubmit = async () => {
        if (!config.mapName || !config.longitude || !config.latitude || !config.zoom) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        if (isEdit) {
            const data = {
                ...config
            };
            const url = `${URL.DATA.PLANT_SETTINGS.MAP}/${config.mapId}`;
            try {
                await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                return;
            }
        } else {
            const data = {
                ...config
            };
            const url = URL.DATA.PLANT_SETTINGS.MAP;
            try {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                return;
            }
        }
        setOpen(false);
    };

    // const isDisabled = () => {
    //     if (!config.mapName || !config.longitude || !config.latitude || !config.zoom) {
    //         return true;
    //     }
    //     return false;
    // };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            // wordWrap: "break-word",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
      <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
        <DialogTitle>
          {`To ${isEdit ? "edit" : "add to"} the maps list, please enter the details below.`}
        </DialogTitle>

        <DialogContent>
          <div className={EditMapTable.styles.inputContainer}>
            <TextInput
              onChange={handleMap}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MAP}
              value={config.mapName}
              type="text"
            />
          </div>
          <div className={EditMapTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LONG}
              onChange={handleLong}
              value={config.longitude}
              type="text"
            />
          </div>
          <div className={EditMapTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LAT}
              onChange={handleLat}
              value={config.latitude}
              type="text"
            />
          </div>
          <div className={EditMapTable.styles.inputContainer}>
            <TextInput
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_ZOOM}
              onChange={handleZoom}
              value={config.zoom}
              type="text"
            />
          </div>
          {!!error && (
            <div className={classes.error}>
              <Typography>{error}</Typography>
            </div>
                )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleSubmit}>
            {VERBIAGE.BUTTONS.SUBMIT}
          </Button>
          <Button className={classes.error} onClick={() => setOpen(false)}>
            {VERBIAGE.BUTTONS.CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    );
});

EditMapTable.styles = {
    inputContainer: "webclient__mapTable-input",
    errorText: "webclient__mapTable-error-text"
};

export default EditMapTable;
