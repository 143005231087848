/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import AuthenticationActions from "../../../authentication/redux/AuthenticationActions";
import VERBIAGE from "../../../utils/enums/Verbiage";
import TextInput from "../../common/components/TextInput";
import SystemHeader from "../../common/components/SystemHeader";
import "../styles/changePassword.scss";
import "../styles/portfolioView.scss";
import api from "../../../utils/API";
import URL from "../../../utils/URL";

const MobileDetect = require("mobile-detect");

const ChangePassword = props => {
    const { authentication, updateChangedPassword, home } = props;

    const [oldPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);

    const handleSubmit = async () => {
        setPasswordChangeSuccess(false);
        if (!oldPassword || !newPassword || !confirmPassword) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        if (
            !authentication.encryptedLogin &&
            !authentication.googleSignIn &&
            oldPassword !== authentication.currentPassword
        ) {
            setError(VERBIAGE.ERROR_TEXT.CURRENT_PASSWORD);
            return;
        }
        if (newPassword !== confirmPassword) {
            setError(VERBIAGE.ERROR_TEXT.CONFIRM_PASSWORD);
            return;
        }
        if (newPassword.length < 8 || !VERBIAGE.TEXT.PASSWORD_REGEX.test(newPassword)) {
            setError(VERBIAGE.ERROR_TEXT.INVALID_PASSWORD);
            return;
        }
        const data = {
            oldPassword,
            newPassword,
            confirmPassword
        };
        try {
            const response = await api.put(URL.AUTHENTICATION.CHANGE_PASSWORD, data, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            if (response.request.status === 200) {
                setPasswordChangeSuccess(true);
                setError(VERBIAGE.TEXT.PASSWORD_CHANGE_SUCCESS);
                updateChangedPassword({ newPassword });
            }
        } catch (err) {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    };

    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        paper: {
            padding: theme.spacing(0),
            textAlign: "center",
            height: "550px",
            display: "flex",
            [theme.breakpoints.up("xs")]: {
                height: "550px"
            },
            color: theme.palette.type === "dark" ? "white" : "black",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff"
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        sub: {
            border: "2px solid #006ab4",
            height: "400px",
            width: "30%",
            display: "flex",
            margin: "auto",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff",
            alignItems: "center",
            [theme.breakpoints.up("xs")]: {
                width: "80%"
            },
            [theme.breakpoints.up("sm")]: {
                width: "60%"
            },
            [theme.breakpoints.up("md")]: {
                width: "40%"
            }
        },
        error: {
            color: passwordChangeSuccess ? theme.palette.success.dark : theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center",
            marginTop: "2vh"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            paddingTop: "70px",
            boxSizing: "border-box"
        }
    }));

    const classes = useStyles();

    return (
        <div className="main-wrapper">
            <div>
                <SystemHeader authentication={props.authentication} />
            </div>
            <div className={classes.main}>
                <Grid container item xs={12} className={classes.root} spacing={2}>
                    {/* header start */}
                    <Grid item xs={12}>
                        <div
                          className={classNames(
                                ChangePassword.styles.headerContainer,
                                classes.title,
                            )}
                        >
                            <span>{VERBIAGE.TEXT.CHANGE_PASSWORD}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <div className={classes.sub}>
                                <div className={ChangePassword.styles.input}>
                                    <TextInput
                                      className={ChangePassword.styles.input}
                                      type="password"
                                      onChange={event => setCurrentPassword(event.target.value)}
                                      value={oldPassword}
                                      placeholder={VERBIAGE.TEXT.CURRENT_PASSWORD}
                                    />
                                </div>

                                <div className={ChangePassword.styles.input}>
                                    <TextInput
                                      className={ChangePassword.styles.input}
                                      type="password"
                                      onChange={event => setNewPassword(event.target.value)}
                                      value={newPassword}
                                      placeholder={VERBIAGE.TEXT.NEW_PASSWORD}
                                    />
                                </div>
                                <div className={ChangePassword.styles.input}>
                                    <TextInput
                                      className={ChangePassword.styles.input}
                                      type="password"
                                      onChange={event => setConfirmPassword(event.target.value)}
                                      value={confirmPassword}
                                      placeholder={VERBIAGE.TEXT.CONFIRM_PASSWORD}
                                    />
                                </div>
                                <button
                                  type="submit"
                                  className={ChangePassword.styles.button}
                                  onClick={handleSubmit}
                                >
                                    {VERBIAGE.TEXT.CHANGE_PASSWORD}
                                </button>
                                {!!error && (
                                    <div className={classes.error}>
                                        <Typography>{error}</Typography>
                                    </div>
                                )}
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

// ChangePassword.propTypes = {
//     postCredentials: PropTypes.func.isRequired
// };

const mapStateToProps = state => ({
    authentication: state.authentication,
    home: state.home
});

const mapDispatchToProps = dispatch => ({
    updateChangedPassword: credentials => {
        dispatch(AuthenticationActions.updateChangedPassword(credentials));
    }
});

ChangePassword.styles = {
    rootContainer: "webclient__changePassword-container-root",
    subContainer: "webclient__changePassword-container-sub",
    buttonContainer: "webclient__changePassword-container-button",
    header: "webclient__changePassword-header",
    label: "webclient__changePassword-label",
    input: "webclient__changePassword-input",
    button: "webclient__changePassword-button",
    errorText: "webclient__changePassword-error-text",
    headerContainer: "webclient__portfolioView-container-header"
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePassword));
