import React, { useState, forwardRef } from "react";
import {
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Grid,
    Divider
} from "@material-ui/core";
import TextInput from "../TextInput";
import api from "../../../../utils/API";
import URL from "../../../../utils/URL";
import VERBIAGE from "../../../../utils/enums/Verbiage";

const EditOnboardingList = forwardRef(props => {
    const { open, setOpen, handleClick } = props;
    const [error, setError] = useState("");

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        chillerGroupCount: 1,
        chillerGroupInfo: [{ cnt: 0 }],
        coolingTowerGroupCount: 1,
        coolingTowerGroupInfo: [{ cnt: 0 }],
        condenserPumpGroupCount: 1,
        condenserPumpGroupInfo: [{ cnt: 0 }],
        chilledWaterPumpPrimaryGroupCount: 1,
        chilledWaterPumpPrimaryGroupInfo: [{ cnt: 0 }],
        chilledWaterPumpSecondaryGroupCount: 1,
        chilledWaterPumpSecondaryGroupInfo: [{ cnt: 0 }],
        headerChilledWaterGroupCount: 1,
        headerChilledWaterGroupInfo: [{ cnt: 0 }],
        headerCondenserWaterGroupCount: 1,
        headerCondenserWaterGroupInfo: [{ cnt: 0 }]
    };

    const [config, updateConfig] = useState(emptyConfig);

    const handleSite = ({ target: { value } }) => {
        updateConfig({ ...config, siteId: value });
    };

    const handleDeleteChiller = idx => () => {
        updateConfig({
            ...config,
            chillerGroupCount: (config.chillerGroupCount -= 1),
            chillerGroupInfo: config.chillerGroupInfo.filter((c, sidx) => idx !== sidx)
        });
    };

    // add
    const handleAddChiller = () => {
        updateConfig({
            ...config,
            chillerGroupCount: (config.chillerGroupCount += 1),
            chillerGroupInfo: config.chillerGroupInfo.concat({ cnt: 0 })
        });
        // console.log(config.chillerGroupInfo);
    };

    const handleChillerInfoChange = idx => evt => {
        const newChillerGroupInfo = config.chillerGroupInfo.map((c, sidx) => {
            if (idx !== sidx) return c;
            return { ...c, cnt: Number(evt.target.value) };
        });
        updateConfig({ ...config, chillerGroupInfo: newChillerGroupInfo });

        // console.log(config.chillerGroupInfo);
    };

    const handleDeleteCoolingTower = idx => () => {
        updateConfig({
            ...config,
            coolingTowerGroupCount: (config.coolingTowerGroupCount -= 1),
            coolingTowerGroupInfo: config.coolingTowerGroupInfo.filter((c, sidx) => idx !== sidx)
        });
    };

    const handleAddCoolingTower = () => {
        updateConfig({
            ...config,
            coolingTowerGroupCount: (config.coolingTowerGroupCount += 1),
            coolingTowerGroupInfo: config.coolingTowerGroupInfo.concat({ cnt: 0 })
        });
    };

    const handleCoolingTowerChange = idx => evt => {
        const newCoolingTowerGroupInfo = config.coolingTowerGroupInfo.map((c, sidx) => {
            if (idx !== sidx) return c;
            return { ...c, cnt: Number(evt.target.value) };
        });
        updateConfig({ ...config, coolingTowerGroupInfo: newCoolingTowerGroupInfo });
    };

    const handleDeleteCondenserPump = idx => () => {
        updateConfig({
            ...config,
            condenserPumpGroupCount: (config.condenserPumpGroupCount -= 1),
            condenserPumpGroupInfo: config.condenserPumpGroupInfo.filter((c, sidx) => idx !== sidx)
        });
    };

    const handleAddCondenserPump = () => {
        updateConfig({
            ...config,
            condenserPumpGroupCount: (config.condenserPumpGroupCount += 1),
            condenserPumpGroupInfo: config.condenserPumpGroupInfo.concat({ cnt: 0 })
        });
    };

    const handleCondenserPumpChange = idx => evt => {
        const newCondenserPumpInfo = config.condenserPumpGroupInfo.map((c, sidx) => {
            if (idx !== sidx) return c;
            return { ...c, cnt: Number(evt.target.value) };
        });
        updateConfig({ ...config, condenserPumpGroupInfo: newCondenserPumpInfo });
    };

    const handleDeleteChilledWaterPumpPri = idx => () => {
        updateConfig({
            ...config,
            chilledWaterPumpPrimaryGroupCount: (config.chilledWaterPumpPrimaryGroupCount -= 1),
            chilledWaterPumpPrimaryGroupInfo: config.chilledWaterPumpPrimaryGroupInfo.filter(
                (c, sidx) => idx !== sidx,
            )
        });
    };

    const handleAddChilledWaterPumpPri = () => {
        updateConfig({
            ...config,
            chilledWaterPumpPrimaryGroupCount: (config.chilledWaterPumpPrimaryGroupCount += 1),
            chilledWaterPumpPrimaryGroupInfo: config.chilledWaterPumpPrimaryGroupInfo.concat({
                cnt: 0
            })
        });
    };

    const handleChilledWaterPumpPriChange = idx => evt => {
        const newChilledWaterPumpPriInfo = config.chilledWaterPumpPrimaryGroupInfo.map(
            (c, sidx) => {
                if (idx !== sidx) return c;
                return { ...c, cnt: Number(evt.target.value) };
            },
        );
        updateConfig({ ...config, chilledWaterPumpPrimaryGroupInfo: newChilledWaterPumpPriInfo });
    };

    const handleDeleteChilledWaterPumpSec = idx => () => {
        updateConfig({
            ...config,
            chilledWaterPumpSecondaryGroupCount: (config.chilledWaterPumpSecondaryGroupCount -= 1),
            chilledWaterPumpSecondaryGroupInfo: config.chilledWaterPumpSecondaryGroupInfo.filter(
                (c, sidx) => idx !== sidx,
            )
        });
    };

    const handleAddChilledWaterPumpSec = () => {
        updateConfig({
            ...config,
            chilledWaterPumpSecondaryGroupCount: (config.chilledWaterPumpSecondaryGroupCount += 1),
            chilledWaterPumpSecondaryGroupInfo: config.chilledWaterPumpSecondaryGroupInfo.concat({
                cnt: 0
            })
        });
    };

    const handleChilledWaterPumpSecChange = idx => evt => {
        const newChilledWaterPumpSecInfo = config.chilledWaterPumpSecondaryGroupInfo.map(
            (c, sidx) => {
                if (idx !== sidx) return c;
                return { ...c, cnt: Number(evt.target.value) };
            },
        );
        updateConfig({ ...config, chilledWaterPumpSecondaryGroupInfo: newChilledWaterPumpSecInfo });
    };

    const handleDeleteHeaderChilledWater = idx => () => {
        updateConfig({
            ...config,
            headerChilledWaterGroupCount: (config.headerChilledWaterGroupCount -= 1),
            headerChilledWaterGroupInfo: config.headerChilledWaterGroupInfo.filter(
                (c, sidx) => idx !== sidx,
            )
        });
    };

    const handleAddHeaderChilledWater = () => {
        updateConfig({
            ...config,
            headerChilledWaterGroupCount: (config.headerChilledWaterGroupCount += 1),
            headerChilledWaterGroupInfo: config.headerChilledWaterGroupInfo.concat({ cnt: 0 })
        });
    };

    const handleHeaderChilledWaterChange = idx => evt => {
        const newHeaderChilledWaterInfo = config.headerChilledWaterGroupInfo.map((c, sidx) => {
            if (idx !== sidx) return c;
            return { ...c, cnt: Number(evt.target.value) };
        });
        updateConfig({ ...config, headerChilledWaterGroupInfo: newHeaderChilledWaterInfo });
    };

    const handleDeleteHeaderCondenserWater = idx => () => {
        updateConfig({
            ...config,
            headerCondenserWaterGroupCount: (config.headerCondenserWaterGroupCount -= 1),
            headerCondenserWaterGroupInfo: config.headerCondenserWaterGroupInfo.filter(
                (c, sidx) => idx !== sidx,
            )
        });
    };

    const handleAddHeaderCondenserWater = () => {
        updateConfig({
            ...config,
            headerCondenserWaterGroupCount: (config.headerCondenserWaterGroupCount += 1),
            headerCondenserWaterGroupInfo: config.headerCondenserWaterGroupInfo.concat({ cnt: 0 })
        });
    };

    const handleHeaderCondenserWaterChange = idx => evt => {
        const newHeaderCondenserWaterInfo = config.headerCondenserWaterGroupInfo.map((c, sidx) => {
            if (idx !== sidx) return c;
            return { ...c, cnt: Number(evt.target.value) };
        });
        updateConfig({ ...config, headerCondenserWaterGroupInfo: newHeaderCondenserWaterInfo });
    };

    const handleSubmit = async () => {
            if (
                !config.siteId
            ) {
                setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
                return;
            }
            const data = { ...config }
            const url = URL.DATA.SITE_ONBOARDING.ONBOARDING_LIST;
            try {
                const response = await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                    console.log(error);
                } else {
                    setOpen(false);
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(VERBIAGE.ERROR_TEXT.USER_EXISTS);
            }
        updateConfig(emptyConfig);
        setError("");
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>To add to site</DialogTitle>
            <DialogContent>
                <Grid container align="center" spacing={1}>
                    <Grid item xs display="flex" justify="center">
                        <Box pt={4.1}>
                            <div className={EditOnboardingList.styles.inputContainer}>
                                <TextInput
                                  onChange={handleSite}
                                  placeholder="Site ID"
                                  value={config.siteId}
                                  type="text"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs display="flex" justify="center">
                        <Box pb={1}>
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleAddChiller}
                            >
                                Add Chiller
                            </Button>
                        </Box>
                        {config.chillerGroupInfo.map((chiller, idx) => (
                            <div className={EditOnboardingList.styles.inputContainer}>
                                <TextInput
                                  placeholder={`CH Grp #${idx + 1} count`}
                                  value={chiller.cnt}
                                  onChange={handleChillerInfoChange(idx)}
                                />
                                <Button
                                  color="secondary"
                                  size="small"
                                  onClick={handleDeleteChiller(idx)}
                                >
                                    Delete
                                </Button>
                            </div>
                        ))}
                    </Grid>

                    <Grid item xs display="flex" justify="center">
                        <Box pb={1}>
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleAddCoolingTower}
                            >
                                Add Cooling Tower
                            </Button>
                        </Box>
                        {config.coolingTowerGroupInfo.map((tower, idx) => (
                            <div className={EditOnboardingList.styles.inputContainer}>
                                <TextInput
                                  placeholder={`CT Grp #${idx + 1} count`}
                                  value={tower.cnt}
                                  onChange={handleCoolingTowerChange(idx)}
                                />
                                <Button
                                  color="secondary"
                                  size="small"
                                  onClick={handleDeleteCoolingTower(idx)}
                                >
                                    Delete
                                </Button>
                            </div>
                        ))}
                    </Grid>
                </Grid>

                <Divider variant="middle" />

                <Grid container align="center" spacing={1}>
                    <Grid item xs display="flex" justify="center">
                        <Box pb={1} pt={1}>
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleAddCondenserPump}
                            >
                                Add Condenser Pump
                            </Button>
                        </Box>
                        {config.condenserPumpGroupInfo.map((condenser, idx) => (
                            <div className={EditOnboardingList.styles.inputContainer}>
                                <TextInput
                                  placeholder={`CD Grp #${idx + 1} count`}
                                  value={condenser.cnt}
                                  onChange={handleCondenserPumpChange(idx)}
                                />
                                <Button
                                  color="secondary"
                                  size="small"
                                  onClick={handleDeleteCondenserPump(idx)}
                                >
                                    Delete
                                </Button>
                            </div>
                        ))}
                    </Grid>

                    <Grid item xs display="flex" justify="center">
                        <Box pb={1} pt={1}>
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleAddChilledWaterPumpPri}
                            >
                                Add Chilled Water Pump Pri
                            </Button>
                        </Box>
                        {config.chilledWaterPumpPrimaryGroupInfo.map((chilledWaterPumpPri, idx) => (
                            <div className={EditOnboardingList.styles.inputContainer}>
                                <TextInput
                                  placeholder={`CHWP Pri Grp #${idx + 1} count`}
                                  value={chilledWaterPumpPri.cnt}
                                  onChange={handleChilledWaterPumpPriChange(idx)}
                                />
                                <Button
                                  color="secondary"
                                  size="small"
                                  onClick={handleDeleteChilledWaterPumpPri(idx)}
                                >
                                    Delete
                                </Button>
                            </div>
                        ))}
                    </Grid>

                    <Grid item xs display="flex" justify="center">
                        <Box pb={1} pt={1}>
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleAddChilledWaterPumpSec}
                            >
                                Add Chilled Water Pump Sec
                            </Button>
                        </Box>
                        {config.chilledWaterPumpSecondaryGroupInfo.map(
                            (chilledWaterPumpSec, idx) => (
                                <div className={EditOnboardingList.styles.inputContainer}>
                                    <TextInput
                                      placeholder={`CHWP Sec Grp #${idx + 1} count`}
                                      value={chilledWaterPumpSec.cnt}
                                      onChange={handleChilledWaterPumpSecChange(idx)}
                                    />
                                    <Button
                                      color="secondary"
                                      size="small"
                                      onClick={handleDeleteChilledWaterPumpSec(idx)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            ),
                        )}
                    </Grid>
                </Grid>

                <Divider variant="middle" />

                <Grid container align="center" spacing={1}>
                    <Grid item xs display="flex" justify="center">
                        <Box pb={1} pt={1}>
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleAddHeaderChilledWater}
                            >
                                Add Header Chilled Water
                            </Button>
                        </Box>
                        {config.headerChilledWaterGroupInfo.map((headerChilledWater, idx) => (
                            <div className={EditOnboardingList.styles.inputContainer}>
                                <TextInput
                                  placeholder={`CHW Header Grp #${idx + 1} count`}
                                  value={headerChilledWater.cnt}
                                  onChange={handleHeaderChilledWaterChange(idx)}
                                />
                                <Button
                                  color="secondary"
                                  size="small"
                                  onClick={handleDeleteHeaderChilledWater(idx)}
                                >
                                    Delete
                                </Button>
                            </div>
                        ))}
                    </Grid>

                    <Grid item xs display="flex" justify="center">
                        <Box pb={1} pt={1}>
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleAddHeaderCondenserWater}
                            >
                                Add Header Condenser Water
                            </Button>
                        </Box>
                        {config.headerCondenserWaterGroupInfo.map((headerCondenserWater, idx) => (
                            <div className={EditOnboardingList.styles.inputContainer}>
                                <TextInput
                                  placeholder={`CDW Header Grp #${idx + 1} count`}
                                  value={headerCondenserWater.cnt}
                                  onChange={handleHeaderCondenserWaterChange(idx)}
                                />
                                <Button
                                  color="secondary"
                                  size="small"
                                  onClick={handleDeleteHeaderCondenserWater(idx)}
                                >
                                    Delete
                                </Button>
                            </div>
                        ))}
                    </Grid>
                </Grid>
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError(VERBIAGE.TEXT.EMPTY_STRING);
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditOnboardingList.styles = {
    inputContainer: "webclient__mapTable-input",
    errorText: "webclient__mapTable-error-text"
};

export default EditOnboardingList;
