import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, Grid, makeStyles, useMediaQuery, Paper } from "@material-ui/core";

import classNames from "classnames";
import MobileDetect from "mobile-detect";
import { VERBIAGE } from "../../../utils/enums/index";

import SystemHeader from "../../common/components/SystemHeader";
import MLSettingsTabs from "../../common/components/MLSettingsTabs";

import "../styles/portfolioView.scss";

const MLSettings = () => {
    const { authentication, home } = useSelector(state => state);

    const tabs = [
        {
            label: VERBIAGE.ML_SETTINGS_TAB_TEXT.ML_SETTINGS,
            value: VERBIAGE.ML_SETTINGS_TAB_VALUE.ML_SETTINGS
        },
        {
            label: VERBIAGE.ML_SETTINGS_TAB_TEXT.AIRFLOW,
            value: VERBIAGE.ML_SETTINGS_TAB_VALUE.AIRFLOW
        },
        {
            label: VERBIAGE.ML_SETTINGS_TAB_TEXT.SITE_DAG,
            value: VERBIAGE.ML_SETTINGS_TAB_VALUE.SITE_DAG
        }
    ];
    const [tab, setTab] = useState(tabs[0].value);
    const handleChange = (_event, newValue) => {
        setTab(newValue);
    };

    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        paper: {
            // padding: theme.spacing(0),
            height: "95%",
            [theme.breakpoints.up("xs")]: {
                height: "905px"
            },
            [theme.breakpoints.up("sm")]: {
                height: "905px"
            },
            [theme.breakpoints.up("md")]: {
                height: "95%"
            },
            [theme.breakpoints.up("lg")]: {
                height: "95%"
            },
            [theme.breakpoints.up("xl")]: {
                height: "95%"
            },
            color: theme.palette.type === "dark" ? "white" : "black",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff"
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            paddingTop: "70px",
            boxSizing: "border-box"
        }
    }));

    const classes = useStyles();

    return (
        <div className="main-wrapper">
            <div>
                <SystemHeader authentication={authentication} />
            </div>
            <div className={classes.main}>
                <Grid container item xs={12} className={classes.root}>
                    {/* header start */}
                    <Grid item xs={12}>
                        <div
                          className={classNames(MLSettings.styles.headerContainer, classes.title)}
                        >
                            <span>{VERBIAGE.MENU_TEXT.ML_SETTINGS}</span>
                        </div>
                    </Grid>
                    {/* header end */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Tabs
                              value={tab}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                              scrollButtons={useMediaQuery("(max-width: 850px)") ? "on" : "auto"}
                            >
                                {tabs.map(t => (
                                    <Tab value={t.value} label={t.label} />
                                ))}
                            </Tabs>
                            <Paper className={classes.paper} elevation={0}>
                                <MLSettingsTabs tab={tab} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
MLSettings.styles = {
    headerContainer: "webclient__portfolioView-container-header"
};

export default MLSettings;
