/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: 0,
        boxShadow: theme.shadows[2],
        cursor: props => (props.locked ? "not-allowed" : ""),
        "&:hover": {
            backgroundColor: props => props.bgColor
        }
    }
}));
const useTooltipStyles = makeStyles(theme => ({
    arrow: {
        color: theme.palette.common.black
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 11
    }
}));
const PIDSPCloudButton = ({ disabled, onClick, ...setpoint }) => {
    const { cloud_status: enable, cloud_registry: address, loading, cloudLocking } = setpoint;

    const lockedRef = useRef(null);
    const [locked, setLocked] = useState(false);

    useEffect(() => {
        if (cloudLocking) {
            const lockingInMs = cloudLocking - new Date();
            if (lockingInMs > 0) {
                setLocked(true);
                lockedRef.current = setTimeout(() => {
                    setLocked(false);
                }, lockingInMs);
                return () => {
                    console.log("locking:cloudLocking:destroy", lockedRef.current);
                    clearTimeout(lockedRef.current);
                    setLocked(false);
                };
            }
        }

        if (lockedRef.current) {
            console.log("locking:destroy", lockedRef.current);
            clearTimeout(lockedRef.current);
            setLocked(false);
        }

        return () => {
            if (lockedRef.current) {
                console.log("locking:unomunt:destroy", lockedRef.current);
                clearTimeout(lockedRef.current);
                setLocked(false);
            }
        };
    }, [cloudLocking]);

    const colorWeight = loading ? 200 : 500;
    const bgColor = enable ? green[colorWeight] : red[colorWeight];

    const getText = () => {
        if (loading) {
            return enable ? "DISABLING..." : "ENABLING...";
        }
        return enable ? "ENABLED" : "DISABLED";
    };

    const handleClick = () => {
        if (disabled || !locked) {
            onClick();
        }
    };

    const classes = useStyles({ bgColor, locked });
    const tooltipClasses = useTooltipStyles();
    return (
        <Box bgcolor={bgColor} color="black" clone>
            <Tooltip arrow placement="top" classes={tooltipClasses} title={`Address: ${address}`}>
                <Button
                  fullWidth
                  disabled={disabled || loading}
                  className={classes.button}
                  endIcon={locked ? <LockIcon style={{ fontSize: 12 }} /> : null}
                  onClick={handleClick}
                >
                    {getText()}
                </Button>
            </Tooltip>
        </Box>
    );
};

export default PIDSPCloudButton;
