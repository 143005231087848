/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-nested-ternary  */
import React, { useState, useEffect } from "react";
import { Paper, makeStyles, Grid, Chip, Typography, useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { VERBIAGE } from "../../../utils/enums/index";
import "../styles/portfolioView.scss";
import AnomalyDiagnosisTable from "../../common/Analytics/AnomalyDiagnosisTable";
import REFRESH_TIMER from "../../../utils/enums/Enum";
import ButtonCommon from "../../common/Analytics/ButtonCommon";
import URL from "../../../utils/URL";
import Interval from "../../common/components/Interval";
import FeedBackStatusDropdown from "../../common/components/FeedBackStatusDropdown";
import AnomalyDiagnosisCharts from "../../common/components/AnomalyDiagnosisCharts";
import TextInput from "../../common/components/TextInput";
import AnomalyDiagnosisTabs from "../../common/Analytics/AnomalyDiagnosisTabs";
import AnomalyDiagnosisActions from "../../redux_AnomalyDiagnosis/AnomalyDiagnosisActions";
import SiteOverviewChart from "../../common/components/SiteOverviewChart";

const AnomalyDiagnosisView = props => {
    const {
        handleFeedback,
        setTabDates,
        setTabMax,
        setTabMin,
        addWorksheet,
        worksheets,
        tab,
        dates,
        min,
        max,
        dateSlider,
        handleSliderChange,
        handleEndDateInput,
        handleStartDateInput,
        onSliderChangeCommitted,
        initialFilter,
        activeData
    } = props;
    const styleTheme = useTheme();
    const [url, setUrl] = useState();
    const [startDate, setStartDate] = useState(Date.now());
    const [endDate, setEndDate] = useState(Date.now());
    const [label, setLabel] = useState("");
    const [value, setValue] = useState("1");
    const [unit, setUnit] = useState("Days");
    const [time, setTime] = useState("");
    const [code, setCode] = useState("");
    const [durationValue, setDurationValue] = useState("");
    const [tabValue, setTabValue] = useState("anomaly_details");

    const [timestamp, setTimeStamp] = useState(Date.now());
    const [equipment, setEquipment] = useState("");
    const [status, setStatus] = useState("PENDING ACK.");
    const [feedback, setFeedback] = useState("");
    const [lastUpdated, setLastUpdated] = useState(useState(""));
    const [reason1, setReason1] = useState("");
    const [reason2, setReason2] = useState("");
    const [reason3, setReason3] = useState("");

    const [inActiveFeedback, setInActiveFeedback] = useState(false);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleClick = tag => {
        setLabel(prev => `${prev} ${tag} `);
    };

    const handleFeedbackClick = () => {
        handleFeedback({
            time,
            equipment,
            status,
            feedback
        });
    };

    useEffect(() => {
        // console.log(">>>>>>>>>>>>>>>>>time useeffect sample");
        // const timerValue = REFRESH_TIMER();
        // updateDiagnosisInterval();
        // const timer = setInterval(() => {
        // updateDiagnosisInterval();
        // }, timerValue);
        // return () => clearInterval(timer);
    }, [time]);

    const handleNewFeedback = (newTime, newEquipment, newStatus, newFeedback) => {
        if (newFeedback === null) {
            newFeedback = "";
        }
        const offset = new Date().getTimezoneOffset();
        const adjustedTime = moment(newTime)
            .add(offset, "m")
            .valueOf();
        // setTime(adjustedTime);
        setTime(newTime); // use new time to match the utc time in webserver
        setEquipment(newEquipment);
        setFeedback(newFeedback);
        setStatus(newStatus);
        setInActiveFeedback(false);
    };

    // function to handle submission of feedback
    const handleFeedbackSubmit = () => {
        setInActiveFeedback(true);
        handleFeedbackClick();
    };

    useEffect(() => {
        if (durationValue) {
            const [unitValue, unitTypeValue] = durationValue.split(" ");
            setValue(unitValue);
            switch (unitTypeValue) {
                case "month":
                case "months":
                    setUnit("Months");
                    break;
                case "day":
                case "days":
                    setUnit("Days");
                    break;
                case "hour":
                case "hours":
                    setUnit("Hours");
                    break;
                case "min":
                case "mins":
                    setUnit("Minutes");
                    break;
                default:
                    setUnit("");
            }
        }
    }, [durationValue]);

    const [timePeriod, setTimePeriod] = useState("day");
    const selectTimeline = timeZone => {
        setTimePeriod(timeZone);
        if (timeZone === "year" || timeZone === "years" || timeZone === "Years") {
            return 31557600000;
        }
        if (timeZone === "month" || timeZone === "months" || timeZone === "Months") {
            return 2629800000;
        }
        if (timeZone === "day" || timeZone === "days" || timeZone === "Days") {
            return 86400000;
        }
        if (timeZone === "hour" || timeZone === "hours" || timeZone === "Hours") {
            return 3600000;
        }
        if (timeZone === "min" || timeZone === "mins" || timeZone === "Minutes") {
            return 60000;
        }
        return 0;
    };
    const getTimeBuffer = period => {
        let buffer;
        switch (period) {
            case "month":
            case "months":
                buffer = 2629800000 * 3;
                break;
            case "day":
            case "days":
                buffer = 86400000;
                break;
            case "hour":
            case "hours":
                buffer = 3600000 * 4;
                break;
            case "min":
            case "mins":
                buffer = 3600000 * 4;
                break;
            default:
                buffer = 86400000;
        }
        return buffer;
    };
    const changeDurationStructure = durationTime => {
        const newDurationTime = durationTime.split(" ");
        let newDate = 0;
        for (let i = 0; i < newDurationTime.length; i += 2) {
            newDate +=
                parseInt(newDurationTime[i], 10) * selectTimeline(newDurationTime[i + 1]) +
                getTimeBuffer(newDurationTime[i + 1]);
        }
        return newDate;
    };
    const addStartBuffer = durationTime => {
        if (durationTime === "1 month") {
            return 31557600000;
        }
        return 0;
    };

    const initialLoad = ws => {
        initialFilter(ws);
        const [length, interval] = durationValue.split(" ");
        const refBandLength = parseInt(length, 10) * selectTimeline(interval);
        const end = timestamp + refBandLength;

        const GMT_8_OFFSET = 8 * 1000 * 60 * 60;
        const [filterStart, filterEnd] = dates;
        // 3 cases for each start and end of band
        // Either < filter view, within filter view, or > filter view
        let actualBandStart;
        let actualBandEnd;
        if (timestamp < filterStart) {
            // start < filter view, NOT OK, set band start to filter start
            actualBandStart = filterStart;
        } else if (timestamp >= filterStart && timestamp <= filterEnd) {
            // start within filter view, OK
            actualBandStart = timestamp;
        } else {
            // start > filter view, NOT OK, set band start and end to filter end
            actualBandStart = filterEnd;
            actualBandEnd = filterEnd; // if start > filter view, end also > filter view
        }

        if (end < filterStart) {
            actualBandEnd = filterStart;
        } else if (end >= filterStart && end <= filterEnd) {
            actualBandEnd = end;
        } else {
            actualBandEnd = filterEnd;
        }

        // actualBandStart += GMT_8_OFFSET;
        // actualBandEnd += GMT_8_OFFSET;

        ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(actualBandStart));
        ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(actualBandEnd));
    };

    // useEffect(() => {
    //     if (worksheets.length >= 1) {
    //         handleRefBand(timestamp, durationValue);
    //     }
    // }, [worksheets, dates]);

    const changeUrl = (newUrl, newStartDate, newDuration) => {
        setUrl(newUrl);
        setDurationValue(newDuration);
        setStartDate(
            newStartDate - addStartBuffer(newDuration) - changeDurationStructure(newDuration),
        );
        const newEndDate = newStartDate + changeDurationStructure(newDuration);
        setEndDate(newEndDate);
        setTabDates([
            newStartDate - addStartBuffer(newDuration) - changeDurationStructure(newDuration),
            newEndDate
        ]);
        setTabMin(
            newStartDate - addStartBuffer(newDuration) - changeDurationStructure(newDuration),
        );
        setTabMax(newEndDate);
    };

    const setDataValue = (
        newStartDate,
        newEquipment,
        newDuration,
        newStatus,
        newFeedback,
        newLastUpdated,
        newReason1,
        newReason2,
        newReason3,
    ) => {
        const offset = new Date().getTimezoneOffset();
        const adjustedTime = moment(newStartDate)
            .add(offset, "m")
            .valueOf();
        // console.log(">>>> setDataValue");
        // const offset = new Date().getTimezoneOffset();
        // console.log('offset : ', offset)
        // // const localTime = moment(newStartDate).utcOffset(-1 * offset , false);
        // const localTime = newStartDate - (offset * 60000)
        // console.log('newStartDate : ', newStartDate)
        // console.log('localTime : ', localTime)

        // fetchChartPosition(newCode, newParameter);
        setTimeStamp(newStartDate);
        setEquipment(newEquipment);
        setDurationValue(newDuration);
        setFeedback(newFeedback);
        setStatus(newStatus);
        setLastUpdated(newLastUpdated);
        setReason1(newReason1);
        setReason2(newReason2);
        setReason3(newReason3);
        // fetchDiagnosisChart(newEquipment, newReason1, newReason2, newReason3);

        // URL.DATA.ANALYTICS.ANOMALY.DIAGNOSIS_CHART

        // setLabel(newLabel);
        // setUrl(`${URL.DATA.ANALYTICS.EDIT}/${newParameter}`);
        // setTimeStamp(newStartDate);
        setStartDate(adjustedTime - addStartBuffer(newDuration));
        setEndDate(adjustedTime + changeDurationStructure(newDuration));
        // setStartDate(newStartDate - addStartBuffer(newDuration));
        // setEndDate(newStartDate + changeDurationStructure(newDuration));
        // fetchNotes(noteIdNew);
        // setParameterURL(URL.DATA.ANALYTICS.CHARTS.CHART_1);
    };

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px 23px",
            paddingTop: "69px"
        },
        table: {
            height: "41vh"
        },
        paper: {
            padding: "15px"
        },
        header: {
            margin: "0px",
            padding: "0px"
        },
        picture: {
            padding: theme.spacing(0),
            color: theme.palette.text.secondary,
            height: "80vh" // heightMulti
        },
        chipContainer: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            padding: theme.spacing(0.5),
            minHeight: "30px",
            maxHeight: "30px",
            overflowY: "hidden",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        chipSeverity: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            padding: theme.spacing(0.5),
            minHeight: "30px",
            maxHeight: "61px",
            overflowY: "hidden",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        chipUsers: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            padding: theme.spacing(0.5),
            minHeight: "40px",
            maxHeight: "90px",
            overflowY: "hidden",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        chip: {
            margin: theme.spacing(0.5)
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        label: {
            height: "250px",
            marginBottom: "0.4vh",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        notes: {
            height: "230px",
            marginBottom: "0.4vh",
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white
        },
        notesTextarea: {
            backgroundColor: "lightgrey"
        },
        grid: {
            display: "flex",
            alignContent: "baseline"
        },
        overrideRoot: {
            display: tab !== "faults&Alarms" ? "none" : "flex"
        },
        headerText: {
            color:
                theme.palette.type === "dark"
                    ? theme.palette.text.dark_grey
                    : theme.palette.text.light_black,
            paddingTop: "1.2vh",
            paddingBottom: "1.6vh",
            fontSize: "13px"
        },
        text: {
            color:
                theme.palette.type === "dark"
                    ? theme.palette.text.grey
                    : theme.palette.text.light_grey,
            fontSize: "14px",
            fontWeight: 500
        },
        lastUpdated: {
            color:
                theme.palette.type === "dark"
                    ? theme.palette.text.grey
                    : theme.palette.text.light_grey,
            paddingRight: "4px",
            fontSize: "14px",
            fontWeight: 500
        }
    }));
    const noteDisabled = {
        color:
            styleTheme.palette.type === "dark"
                ? styleTheme.palette.text.grey
                : styleTheme.palette.text.light_grey
    };
    const classes = useStyles();

    return (
        <Grid elevation={0}>
            <Grid container spacing={1}>
                {/* table start */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AnomalyDiagnosisTable
                      changeUrl={changeUrl}
                      handleNewFeedback={handleNewFeedback}
                        //   first={first}
                      setDataValue={setDataValue}
                        //   fetchNotes={fetchNotes}
                    />
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AnomalyDiagnosisTabs
                      value={tabValue}
                      handleChange={handleChange}
                      setTabDates={setTabDates}
                      setTabMax={setTabMax}
                      setTabMin={setTabMin}
                      code={code}
                      equipment={equipment}
                      startAnalyticDate={startDate}
                      endAnalyticDate={endDate}
                      timePeriod={timePeriod}
                      dates={dates}
                      min={min}
                      max={max}
                      handleSliderChange={handleSliderChange}
                      onSliderChangeCommitted={onSliderChangeCommitted}
                      handleStartDateInput={handleStartDateInput}
                      handleEndDateInput={handleEndDateInput}
                    />
                </Grid>
                <Grid item container spacing={1} xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Grid>
                            <Typography className={classes.headerText} variant="h6">
                                {VERBIAGE.ANOMALY_DIAGNOSIS.FEEDBACK}
                            </Typography>
                            <Paper
                              elevation={0}
                              className={classNames(classes.paper, classes.label)}
                            >
                                <FeedBackStatusDropdown
                                  currentValue={status || "PENDING ACK."}
                                  valueChange={event => setStatus(event.target.value)}
                                  list={VERBIAGE.ANOMALY_DIAGNOSIS.FEEDBACK_STATUS_LIST}
                                />
                                &nbsp;
                                <Grid container item justify="space-between">
                                    <Typography className={classes.text}>
                                        {`${VERBIAGE.ANOMALY_DIAGNOSIS.UPDATED_ON} : `}
                                    </Typography>
                                    <Typography className={classes.lastUpdated}>
                                        {`${
                                            lastUpdated && lastUpdated - 3600000 * 8 !== startDate
                                                ? moment(lastUpdated).format(
                                                      "Do MMMM YYYY, HH:mm:ss",
                                                  )
                                                : moment(startDate).format("Do MMMM YYYY, HH:mm:ss")
                                        }`}
                                    </Typography>
                                </Grid>
                                &nbsp;
                                <Typography className={classes.text}>
                                    {VERBIAGE.ANOMALY_DIAGNOSIS.DIAGNOSIS}
                                </Typography>
                                <ButtonCommon
                                  labelText={feedback}
                                  labelChange={event => setFeedback(event.target.value)}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.TYPE_HERE}
                                  onClick={handleFeedbackSubmit}
                                  inActive={inActiveFeedback}
                                  className={AnomalyDiagnosisView.styles.textContainer}
                                  buttonLabel={VERBIAGE.ANALYTICS.SUBMIT}
                                  rows={3}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <SiteOverviewChart
                          url={URL.DATA.ANALYTICS.ANOMALY.EQUIPMENT_ENERGY}
                          addWorksheet={addWorksheet}
                          double
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          initialFilter={initialLoad}
                          value={time}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <SiteOverviewChart
                          url={URL.DATA.ANALYTICS.ANOMALY.EQUIPMENT_EFF}
                          addWorksheet={addWorksheet}
                          double
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          initialFilter={initialLoad}
                          value={time}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={1} xs={12} sm={12} md={12} lg={12}>
                    {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SiteOverviewChart
                          url={URL.DATA.ANALYTICS.ANOMALY.DEVIATION}
                          addWorksheet={addWorksheet}
                          double
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          initialFilter={initialLoad}
                          value={time}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SiteOverviewChart
                          url={URL.DATA.ANALYTICS.ANOMALY.DEVIATION_PERCENT}
                          addWorksheet={addWorksheet}
                          double
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          initialFilter={initialLoad}
                          value={time}
                        />
                    </Grid>
                    {activeData.reason1 ? (                    
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SiteOverviewChart
                            url={URL.DATA.ANALYTICS.ANOMALY.REASON1}
                            addWorksheet={addWorksheet}
                            double
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            initialFilter={initialLoad}
                            value={time}
                            />
                        </Grid>
                    ): null}
                    {activeData.reason2 ? (                    
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SiteOverviewChart
                            url={URL.DATA.ANALYTICS.ANOMALY.REASON2}
                            addWorksheet={addWorksheet}
                            double
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            initialFilter={initialLoad}
                            value={time}
                            />
                        </Grid>
                    ): null}
                    {activeData.reason3 ? (                    
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SiteOverviewChart
                                // url={URL.DATA.ANALYTICS.OVERVIEW.LIFECYCLE}
                            url={URL.DATA.ANALYTICS.ANOMALY.REASON3}
                            addWorksheet={addWorksheet}
                            double
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            initialFilter={initialLoad}
                            value={time}
                            />
                        </Grid>
                    ): null}
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SiteOverviewChart
                          url={URL.DATA.ANALYTICS.ANOMALY.ALL_PARAMS}
                          addWorksheet={addWorksheet}
                          double
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          initialFilter={initialLoad}
                          value={time}
                        />
                    </Grid> */}
                    <AnomalyDiagnosisCharts
                      addWorksheet={addWorksheet}
                      initialFilter={initialLoad}
                      value={startDate}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
AnomalyDiagnosisView.styles = {
    headerContainer: "webclient__portfolioView-container-header",
    textContainer: "webclient__buttonCommon-container-text",
    notesContainer: "webclient__buttonCommon-container-notes",
    text: "webclient__time-text"
};
const mapStateToProps = state => ({
    diagnosis: state.diagnosis.tableData,
    activeData: state.diagnosis.active
    // analyticNotes: state.analytics.notes,
    // analyticChips: state.analytics.analyticChip,
    // severityChips: state.analytics.severity,
    // notesChips: state.analytics.notesChip,
    // userTagsChips: state.analytics.userTagsChip
});
const mapDispatchToProps = dispatch => ({
    // fetchAnalyticsChips: () => {
    //     dispatch(AnalyticsActions.fetchAnalyticsChips());
    // },
    // fetchSeverity: () => {
    //     dispatch(AnalyticsActions.fetchSeverity());
    // },
    // handleLabel: fault => {
    //     console.log('fault: ', fault)
    //     dispatch(AnalyticsActions.handleLabel(fault));
    // },
    handleFeedback: reason => {
        dispatch(AnomalyDiagnosisActions.handleFeedback(reason));
    }
    // fetchOldNotes: noteId => {
    //     dispatch(AnalyticsActions.fetchOldNotes(noteId));
    // },
    // handleNotes: (start, code, noteId, notes, parameter) => {
    //     dispatch(AnalyticsActions.handleNotes(start, code, noteId, notes, parameter));
    // },
    // fetchNotesChips: () => {
    //     dispatch(AnalyticsActions.fetchNotesChips());
    // },
    // fetchUserTagsChips: () => {
    //     dispatch(AnalyticsActions.fetchUserTagsChips());
    // }
    // fetchChartPosition: (code, parameter) => {
    //     dispatch(AnalyticsActions.fetchChartPosition(code, parameter));
    // }
    // fetchDiagnosisChart: (equipment, reason1, reason2, reason3) => {
    //     dispatch(AnomalyDiagnosisActions.fetchDiagnosisFirstChart(equipment, reason1));
    //     dispatch(AnomalyDiagnosisActions.fetchDiagnosisSecondChart(equipment, reason2));
    //     dispatch(AnomalyDiagnosisActions.fetchDiagnosisThirdChart(equipment, reason3));
    // }
});
export default connect(mapStateToProps, mapDispatchToProps)(AnomalyDiagnosisView);
