import DwhActionTypes from "./DwhActionTypes";

import api from "../../utils/API";
import URL from "../../utils/URL";

// TODO: use interceptor to send auth token header
function getRequestConfig(options = {}) {
    const token = localStorage.getItem("token");
    return {
        headers: {
            "x-auth-token": token
        },
        ...options
    };
}

function handleRequestError(error, cb) {
    switch (error?.response?.status) {
        case 401:
            localStorage.clear();
            window.location.href = "/";
            return;
        default:
    }
    cb();
}

const DwhActions = {
    setLoading: loading => ({
        type: DwhActionTypes.SET_LOADING,
        loading
    }),
    setError: error => ({
        type: DwhActionTypes.SET_ERROR,
        error
    }),
    fetchConfig: () => async dispatch => {
        try {
            dispatch(DwhActions.setLoading(true));
            dispatch(DwhActions.setError(null));

            const response = await api.get(URL.DATA.DWH.CONFIG, getRequestConfig());
            dispatch({
                type: DwhActionTypes.SET_CONFIG,
                config: response.data
            });
        } catch (error) {
            handleRequestError(error, () => {
                dispatch(DwhActions.setError(error.message));
            });
        } finally {
            dispatch(DwhActions.setLoading(false));
        }
    }
};

export default DwhActions;
