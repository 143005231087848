import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    // KeyboardDateTimePicker,
    KeyboardDatePicker
} from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";

const EditCurrencyConverterTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    // const [data, setData] = useState([]); // eslint-disable-line no-unused-vars
    const [error, setError] = useState("");

    const emptyConfig = {
        currency: VERBIAGE.TEXT.EMPTY_STRING,
        rate: VERBIAGE.TEXT.EMPTY_STRING,
        validFrom: Date.now()
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    // const handleSite = ({ target: { value } }) => {
    //     updateConfig({ ...config, siteId: value });
    // };

    const handleCurrency = ({ target: { value } }) => {
        updateConfig({ ...config, currency: value });
    };

    // const handleMonth = ({ target: { value } }) => {
    //     let index = VERBIAGE.TEXT.MONTH_OPTIONS.findIndex(item => {
    //         return item === value;
    //     });
    //     index++;
    //     updateConfig({ ...config, month: index, monthName: value });
    // };
    const handleRate = ({ target: { value } }) => {
        updateConfig({ ...config, rate: value });
    };

    const handleValidFrom = event => {
        let newTimestamp = new Date(event._d).getTime();
        newTimestamp = moment(newTimestamp)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .valueOf();
        updateConfig({ ...config, validFrom: newTimestamp });
    };

    // const handleValidFrom = ({ target: { value } }) => {
    //     updateConfig({ ...config, validFrom: value });
    // };

    const validFrom = moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("DD MMM YYYY")
        .toString();

    const handleSubmit = async () => {
        updateConfig({ ...config, validFrom: config.validFrom || validFrom });
        if (
            !config.currency ||
            !config.rate // ||
            //! config.validFrom
        ) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        if (isEdit) {
            const _data = {
                currency: config.currency,
                rate: config.rate,
                validFrom: moment(config.validFrom)
                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    .valueOf()
            };
            const url = `${URL.DATA.MAINTENANCE_SETTINGS.CURRENCY_CONVERTER}/${config.id}`;
            try {
                const response = await api.put(url, _data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        } else {
            const _validFrom = config.validFrom || Date.now();
            const _data = {
                currency: config.currency,
                rate: config.rate,
                validFrom: moment(_validFrom)
                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    .valueOf()
            };
            const url = URL.DATA.MAINTENANCE_SETTINGS.CURRENCY_CONVERTER;
            try {
                const response = await api.post(url, _data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    // Override border color of datetime picker
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE
                }
            }
        }
    });

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${
                    isEdit ? "edit" : "add to"
                } the Currency Converter, please enter the details below.`}
            </DialogTitle>

            <DialogContent>
                {/* <div className={EditCurrencyConverterTable.styles.inputContainer}>
                    <TextInput
                      onChange={handleSite}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
                      value={config.siteId}
                      type="text"
                      styles={{ readOnly: !!isEdit }}
                    />
                </div> */}
                <div className={EditCurrencyConverterTable.styles.inputContainer}>
                    {isEdit ? (
                        <TextInput
                          placeholder={
                                VERBIAGE.PLACEHOLDER_TEXT.ENTER_CURRENCY_CONVERTER_CURRENCY
                            }
                          value={config.currency}
                          type="text"
                          styles={{ readOnly: true }}
                        />
                    ) : (
                        <DropdownSelect
                          name={config.currency}
                          options={VERBIAGE.TEXT.CURRENCY_OPTIONS}
                          onChange={handleCurrency}
                          placeholder={
                                VERBIAGE.PLACEHOLDER_TEXT.ENTER_CURRENCY_CONVERTER_CURRENCY
                            }
                        />
                    )}
                </div>
                <div className={EditCurrencyConverterTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CURRENCY_CONVERTER_RATE}
                      onChange={handleRate}
                      value={config.rate}
                      type="text"
                      styles={{ readOnly: !!isEdit }}
                    />
                </div>
                {/* <div className={EditCurrencyConverterTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CURRENCY_CONVERTER_VALID_FROM}
                      onChange={handleValidFrom}
                      value={config.validFrom}
                      type="text"
                    />
                </div> */}
                <div className={EditCurrencyConverterTable.styles.inputContainer}>
                    {isEdit ? (
                        <MuiThemeProvider theme={themeOverride}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  variant="dialog"
                                  label={
                                        VERBIAGE.PLACEHOLDER_TEXT
                                            .ENTER_CURRENCY_CONVERTER_VALID_FROM
                                    }
                                  inputVariant="outlined"
                                  defaultValue={validFrom}
                                  value={config.validFrom || validFrom}
                                  onChange={handleValidFrom}
                                  format="DD/MM/YYYY"
                                  fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                    ) : (
                        <MuiThemeProvider theme={themeOverride}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  variant="dialog"
                                  label={
                                        VERBIAGE.PLACEHOLDER_TEXT
                                            .ENTER_CURRENCY_CONVERTER_VALID_FROM
                                    }
                                  inputVariant="outlined"
                                  defaultValue={validFrom}
                                  value={config.validFrom || validFrom}
                                  onChange={handleValidFrom}
                                  format="DD/MM/YYYY"
                                  fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                    )}
                </div>
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setOpen(false);
                        setError("");
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditCurrencyConverterTable.styles = {
    inputContainer: "webclient__mapTable-input"
};

export default EditCurrencyConverterTable;
