import PortfolioActionTypes from "./PortfolioActionTypes";

const initialState = {
    savings: "",
    kpi: "",
    mapData: "",
    change_site: "",
    toggle: "",
    sites: [],
    update_sites: "",
    benchmarks: ""
};

const PortfolioReducer = (state = initialState, action) => {
    switch (action.type) {
        case PortfolioActionTypes.SET_PORTFOLIOSAVINGS:
            return {
                ...state,
                savings: action.data
            };
        case PortfolioActionTypes.SET_KPI:
            localStorage.setItem("lastUpdate", action.data.lastUpdate);
            localStorage.setItem("utcOffset", action.data.utcOffset);
            localStorage.setItem("timezone", action.data.timezone);
            return {
                ...state,
                kpi: action.data
            };
        case PortfolioActionTypes.SET_PORTFOLIOINTERVAL:
            return {
                ...state
            };
        case PortfolioActionTypes.SET_MAP:
            return {
                ...state,
                mapData: action.data
            };
        case PortfolioActionTypes.SET_CHANGE_SITE:
            localStorage.setItem("refreshRate", action.data.refreshRate);
            return {
                ...state,
                change_site: action.data
            };
        case PortfolioActionTypes.SET_TOGGLE_SIDEBAR:
            return {
                ...state,
                toggle: action.data
            };
        case PortfolioActionTypes.SET_SITES:
            return {
                ...state,
                sites: [...action.data]
            };
        case PortfolioActionTypes.UPDATE_SITES:
            localStorage.setItem("refreshRate", action.data.refreshRate);
            localStorage.setItem("lastUpdate", action.data.lastUpdate);
            localStorage.setItem("utcOffset", action.data.utcOffset);
            localStorage.setItem("timezone", action.data.timezone);
            return {
                ...state,
                update_sites: action.data
            };
        case PortfolioActionTypes.SET_PORTFOLIO_BENCHMARKS:
            return {
                ...state,
                benchmarks: action.data
            };
        default:
            return state;
    }
};

export default PortfolioReducer;
