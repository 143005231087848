import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";
import { useSelector } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Grid,
    FormLabel
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { getResponseErrorDetails, scrollIntoFirstErrorElement } from "../../../utils/helpers";
import useQueryParams from "../../../hooks/useQueryParams";

import FormRenderField from "../../../dashboard/common/components/FormRenderField";
import TextInput from "../../../dashboard/common/components/TextInput";

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    },
    hide: {
        display: "none"
    }
}));

const EditSiteInstrument = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, onSuccess } = props;

    const { getQuery } = useQueryParams();
    const siteNumber = getQuery("siteNumber");

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const { SiteInstrumentOrientations } = useSelector(state => state.dwh.config);
    const siteInstrumentSchema = useMemo(() => {
        return Yup.object().shape({
            instCategory: Yup.string().required(),
            instSno: Yup.string().required(),
            instManufacturer: Yup.string().required(),
            instModel: Yup.string().required(),
            instDesc: Yup.string(),
            instCalibrationDate: Yup.date().required(),
            instCommisionDate: Yup.date().required(),
            instStraightLineDs: Yup.number().min(0),
            instStraightLineUp: Yup.number().min(0),
            instOrientation: Yup.mixed().oneOf(SiteInstrumentOrientations),
            instSuppliedVoltage: Yup.number(),
            instSuppliedUom: Yup.string(),
            instOutputType1: Yup.number(),
            instOutputType1Uom: Yup.string(),
            instOutputType2: Yup.number(),
            instOutputType2Uom: Yup.string(),
            instType: Yup.string(),
            isActive: Yup.boolean()
        });
    }, [SiteInstrumentOrientations]);

    const initData = {
        instCategory: VERBIAGE.TEXT.EMPTY_STRING,
        instSno: VERBIAGE.TEXT.EMPTY_STRING,
        instManufacturer: VERBIAGE.TEXT.EMPTY_STRING,
        instModel: VERBIAGE.TEXT.EMPTY_STRING,
        instDesc: VERBIAGE.TEXT.EMPTY_STRING,
        instCalibrationDate: new Date(),
        instCommisionDate: new Date(),
        instStraightLineDs: 0,
        instStraightLineUp: 0,
        instOrientation: VERBIAGE.TEXT.EMPTY_STRING,
        instSuppliedVoltage: 0,
        instSuppliedUom: VERBIAGE.TEXT.EMPTY_STRING,
        instOutputType1: 0,
        instOutputType1Uom: VERBIAGE.TEXT.EMPTY_STRING,
        instOutputType2: 0,
        instOutputType2Uom: VERBIAGE.TEXT.EMPTY_STRING,
        instType: VERBIAGE.TEXT.EMPTY_STRING,
        isActive: true
    };
    const [values, setValues] = useState(initData);

    const {
        control,
        handleSubmit,
        reset,
        setError: setFormError,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(siteInstrumentSchema),
        values
    });

    useImperativeHandle(ref, () => ({
        init() {
            setValues({ ...initData });
        },
        update(data) {
            setValues({ ...initData, ...data });
        }
    }));

    useEffect(() => {
        if (Object.keys(errors).length) {
            scrollIntoFirstErrorElement({
                elementIds: Object.keys(errors)
            });
        } else {
            setError(null);
        }
    }, [errors]);

    const onClose = () => {
        reset(initData);
        setError(null);
        setOpen(false);
    };

    const handleResponseError = response => {
        const resError = getResponseErrorDetails(response);
        if (resError.type === "invalid") {
            Object.keys(resError.errors).forEach(field => {
                setFormError(field, { type: "invalid", message: resError.errors[field] });
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstErrorElement({ elementIds: Object.keys(resError.errors) });
        } else {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        }
    };

    const onSubmit = async data => {
        try {
            setLoading(true);

            const requestConfig = {
                data,
                headers: {
                    "x-auth-token": localStorage.token
                }
            };
            if (values._id) {
                requestConfig.method = "PUT";
                requestConfig.url = `${URL.DATA.DWH.SITE_INSTRUMENT}/${values._id}`;
            } else {
                requestConfig.method = "POST";
                requestConfig.url = `${URL.DATA.DWH.SITE_INSTRUMENT}/${siteNumber}/create`;
            }
            const response = await api.request(requestConfig);
            if (response.data?.status === "error") {
                handleResponseError(response);
                return;
            }
            if (onSuccess) {
                onSuccess(siteNumber);
            }
            onClose();
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else if (err.response.status === 404) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            } else {
                handleResponseError(err.response);
            }
        } finally {
            setLoading(false);
        }
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    const classes = useStyles();

    const inputs = useMemo(() => {
        const instOrientationOptions = SiteInstrumentOrientations.filter(v => v !== "");
        return [
            {
                name: "instId",
                label: "Instrument ID",
                disabled: true,
                hide: !isEdit
            },
            {
                name: "instCategory",
                label: "Category",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_CATEGORY,
                required: true
            },
            {
                name: "instSno",
                label: "Serial No",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_SERIAL_NO,
                required: true
            },
            {
                name: "instManufacturer",
                label: "Manufacturer",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_MANUFACTURER,
                required: true
            },
            {
                name: "instModel",
                label: "Model",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_MODEL,
                required: true
            },
            {
                name: "instDesc",
                label: "Description",
                type: "textarea",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_DESCRIPTION
            },
            {
                name: "instCalibrationDate",
                label: "Calibration Date",
                type: "date",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_CALIBRATION_DATE,
                required: true
            },
            {
                name: "instCommisionDate",
                label: "Commision Date",
                type: "date",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_COMMISION_DATE,
                required: true
            },
            {
                name: "instStraightLineDs",
                label: "St.Line Ds",
                type: "number",
                numberType: "double",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_ST_LINE_DS
            },
            {
                name: "instStraightLineUp",
                label: "St.Line Up",
                type: "number",
                numberType: "double",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_ST_LINE_UP
            },
            {
                name: "instOrientation",
                label: "Orientation",
                type: "select",
                selectOptions: instOrientationOptions,
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_ORIENTATION
            },
            {
                name: "instSuppliedVoltage",
                label: "Voltage",
                type: "number",
                numberType: "double",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_VOLTAGE
            },
            {
                name: "instSuppliedUom",
                label: "UOM",
                placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_UOM
            }
        ];
    }, [SiteInstrumentOrientations, isEdit]);

    const inputs2 = [
        {
            name: "instType",
            label: "Type",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_TYPE
        },
        {
            name: "isActive",
            label: "Active",
            type: "switch",
            switchLabels: { true: "Yes", false: "No" },
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_IS_ACTIVE
        }
    ];

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-site-dialog">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} site instrument data enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    {inputs.map(input => {
                        if (input.hide) return null;
                        return (
                            <Grid item container alignItems="center" key={input.name}>
                                <Grid item xs={3}>
                                    <FormLabel required={input.required} error={errors[input.name]}>
                                        {input.label}
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={9}>
                                    <Controller
                                      name={input.name}
                                      control={control}
                                      render={({ field: { value, onChange } }) => (
                                            <FormRenderField
                                              input={input}
                                              value={value}
                                              onChange={onChange}
                                              errors={errors}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                    {/* Out.Type 1/UOM */}
                    <Grid item container alignItems="center">
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">Out.Type 1/UOM</Typography>
                        </Grid>
                        <Grid item container xs={9} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                  name="instOutputType1"
                                  control={control}
                                  render={({ field: { name, value, onChange } }) => (
                                        <TextInput
                                          type="number"
                                          name={name}
                                          inputProps={{ step: 0.1 }}
                                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_VOLTAGE}
                                          value={value}
                                          onChange={onChange}
                                          error={!!errors.outputType1?.value}
                                          helperText={errors.outputType1?.value?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                  name="instOutputType1Uom"
                                  control={control}
                                  render={({ field: { name, value, onChange } }) => (
                                        <TextInput
                                          name={name}
                                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_UOM}
                                          value={value}
                                          onChange={onChange}
                                          error={!!errors.outputType1?.uom}
                                          helperText={errors.outputType1?.uom?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Out.Type 2/UOM */}
                    <Grid item container alignItems="center">
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">Out.Type 2/UOM</Typography>
                        </Grid>
                        <Grid item container xs={9} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                  name="instOutputType2"
                                  control={control}
                                  render={({ field: { name, value, onChange } }) => (
                                        <TextInput
                                          type="number"
                                          inputProps={{ step: 0.1 }}
                                          name={name}
                                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_VOLTAGE}
                                          value={value}
                                          onChange={onChange}
                                          error={!!errors.outputType2?.value}
                                          helperText={errors.outputType2?.value?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                  name="instOutputType2Uom"
                                  control={control}
                                  render={({ field: { name, value, onChange } }) => (
                                        <TextInput
                                          name={name}
                                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_UOM}
                                          value={value}
                                          onChange={onChange}
                                          error={!!errors.outputType2?.uom}
                                          helperText={errors.outputType2?.uom?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {inputs2.map(input => (
                        <Grid item container alignItems="center" key={input.name}>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">{input.label}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Controller
                                  name={input.name}
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                        <FormRenderField
                                          input={input}
                                          value={value}
                                          onChange={onChange}
                                          errors={errors}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    {!!error && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography variant="subtitle1">{error}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                  color="primary"
                  onClick={handleSubmit(onSubmit, onError)}
                  disabled={loading}
                >
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={onClose} disabled={loading}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default EditSiteInstrument;
