import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Grid,
    FormLabel
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { getResponseErrorDetails, scrollIntoFirstErrorElement } from "../../../utils/helpers";

import useQueryParams from "../../../hooks/useQueryParams";

import FormRenderField from "../../../dashboard/common/components/FormRenderField";

const SiteChillerSchema = Yup.object().shape({
    chBrand: Yup.string()
        .label("Brand")
        .required(),
    chModel: Yup.string()
        .label("Model")
        .required()
});

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    },
    hide: {
        display: "none"
    }
}));

const EditSiteChiller = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, onSuccess } = props;

    const { getQuery } = useQueryParams();
    const siteNumber = getQuery("siteNumber");

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const initData = {
        chBrand: VERBIAGE.TEXT.EMPTY_STRING,
        chModel: VERBIAGE.TEXT.EMPTY_STRING,
        isActive: true
    };
    const [values, setValues] = useState(initData);

    const {
        control,
        handleSubmit,
        reset,
        setError: setFormError,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(SiteChillerSchema),
        values
    });

    useImperativeHandle(ref, () => ({
        init() {
            setValues({ ...initData });
        },
        update(data) {
            setValues({ ...initData, ...data });
        }
    }));

    useEffect(() => {
        if (Object.keys(errors).length) {
            scrollIntoFirstErrorElement({
                elementIds: Object.keys(errors)
            });
        } else {
            setError(null);
        }
    }, [errors]);

    const onClose = () => {
        reset(initData);
        setError(null);
        setOpen(false);
    };

    const handleResponseError = response => {
        const resError = getResponseErrorDetails(response);
        if (resError.type === "invalid") {
            Object.keys(resError.errors).forEach(field => {
                setFormError(field, { type: "invalid", message: resError.errors[field] });
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstErrorElement({ elementIds: Object.keys(resError.errors) });
        } else {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        }
    };

    const onSubmit = async data => {
        try {
            setLoading(true);

            const requestConfig = {
                data,
                headers: {
                    "x-auth-token": localStorage.token
                }
            };
            if (isEdit) {
                requestConfig.method = "PUT";
                requestConfig.url = `${URL.DATA.DWH.SITE_CHILLER}/${values.chId}`;
            } else {
                requestConfig.method = "POST";
                requestConfig.url = `${URL.DATA.DWH.SITE_CHILLER}/${siteNumber}/create`;
            }
            const response = await api.request(requestConfig);
            if (response.data?.status === "error") {
                handleResponseError(response);
                return;
            }
            if (onSuccess) {
                onSuccess(siteNumber);
            }
            onClose();
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else if (err.response.status === 404) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            } else {
                handleResponseError(err.response);
            }
        } finally {
            setLoading(false);
        }
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    const classes = useStyles();

    const inputs = [
        {
            name: "chId",
            label: "Chiller ID",
            disabled: true,
            hide: !isEdit
        },
        {
            name: "chBrand",
            label: "Brand",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_BRAND,
            required: true
        },
        {
            name: "chModel",
            label: "Model",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_MODEL,
            required: true
        },
        {
            name: "isActive",
            label: "Active",
            type: "switch",
            switchLabels: { true: "Yes", false: "No" },
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_IS_ACTIVE
        }
    ];

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-site-dialog">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} site chiller data enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    {inputs.map(input => {
                        if (input.hide) return null;
                        return (
                            <Grid item container alignItems="center" key={input.name}>
                                <Grid item xs={3}>
                                    <FormLabel required={input.required} error={errors[input.name]}>
                                        {input.label}
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={9}>
                                    <Controller
                                      name={input.name}
                                      control={control}
                                      render={({ field: { value, onChange } }) => (
                                            <FormRenderField
                                              input={input}
                                              value={value}
                                              onChange={onChange}
                                              errors={errors}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                    {!!error && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography variant="subtitle1">{error}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                  color="primary"
                  onClick={handleSubmit(onSubmit, onError)}
                  disabled={loading}
                >
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={onClose} disabled={loading}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditSiteChiller.styles = {
    inputContainer: "webclient__mapTable-fixed"
};

export default EditSiteChiller;
