import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";

import { VERBIAGE } from "../../../utils/enums";
import PIDOverall from "../PID/PIDOverall";
import PIDAutoML from "../PID/PIDAutoML";
import RuleBasedOptimization from "../PID/RuleBasedOptimization";

const PIDTabs = ({ tab }) => {
    const useStyles = makeStyles(() => ({
        root: {
            flexGrow: 1,
            padding: "10px"
        },
        main: props => ({
            transition: "margin-left 0.5s",
            overflow: props.tab === VERBIAGE.PID_TAB_VALUE.OVERALL ? "auto" : "hidden",
            flex: "1",
            boxSizing: "border-box"
        })
    }));
    const classes = useStyles({ tab });

    const tabView = () => {
        switch (tab) {
            case VERBIAGE.PID_TAB_VALUE.AUTO_ML:
                return <PIDAutoML />;
            case VERBIAGE.PID_TAB_VALUE.OPTIMIZATION:
                return <RuleBasedOptimization />;
            case VERBIAGE.PID_TAB_VALUE.OVERALL:
            default:
                return <PIDOverall />;
        }
    };
    return <Grid className={classNames(classes.root, classes.main)}>{tabView()}</Grid>;
};

export default PIDTabs;
