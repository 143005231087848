/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import SiteOverviewChart from "./SiteOverviewChart";
import RangeSlider from "./RangeSlider";
import URL from "../../../utils/URL";

const AnomalyDiagnosisCharts = props => {
    const {
        addWorksheet,
        newChartPositionDetails,
        newChartPositionOverview,
        code,
        parameter,
        activeData,
        tabValue,
        initialFilter,
        value
    } = props;

    return (
        <Grid>
            <Grid container item>
                {/* {displayDiagnosisCharts()} */}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <SiteOverviewChart
                      url={URL.DATA.ANALYTICS.ANOMALY.DEVIATION}
                      addWorksheet={addWorksheet}
                      double
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      initialFilter={initialFilter}
                      value={value}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <SiteOverviewChart
                      url={URL.DATA.ANALYTICS.ANOMALY.DEVIATION_PERCENT}
                      addWorksheet={addWorksheet}
                      double
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      initialFilter={initialFilter}
                      value={value}
                    />
                </Grid>
                {activeData.reason1 ? (
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SiteOverviewChart
                          url={URL.DATA.ANALYTICS.ANOMALY.REASON1}
                          addWorksheet={addWorksheet}
                          double
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          initialFilter={initialFilter}
                          value={value}
                        />
                    </Grid>
                ) : null}
                {activeData.reason2 ? (
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SiteOverviewChart
                          url={URL.DATA.ANALYTICS.ANOMALY.REASON2}
                          addWorksheet={addWorksheet}
                          double
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          initialFilter={initialFilter}
                          value={value}
                        />
                    </Grid>
                ) : null}
                {activeData.reason3 ? (
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SiteOverviewChart
                            // url={URL.DATA.ANALYTICS.OVERVIEW.LIFECYCLE}
                          url={URL.DATA.ANALYTICS.ANOMALY.REASON3}
                          addWorksheet={addWorksheet}
                          double
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          initialFilter={initialFilter}
                          value={value}
                        />
                    </Grid>
                ) : null}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <SiteOverviewChart
                      url={URL.DATA.ANALYTICS.ANOMALY.ALL_PARAMS}
                      addWorksheet={addWorksheet}
                      double
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      initialFilter={initialFilter}
                      value={value}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
const mapStateToProps = state => ({
    newChartPositionDetails: state.analytics.chartData.charts.details,
    newChartPositionOverview: state.analytics.chartData.charts.overview,
    code: state.analytics.chartData.code,
    parameter: state.analytics.chartData.parameter,
    activeData: state.diagnosis.active
});
export default connect(mapStateToProps)(AnomalyDiagnosisCharts);
