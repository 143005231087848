/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable no-unused-vars  */
import React, { useState } from "react";
import { Grid, Tab, Tabs, useMediaQuery } from "@material-ui/core";

const OptimizationTabs = props => {
    const { classes, tabStyle, value, handleChange } = props;

    // const isLifecycleCTDeveloped = localStorage.getItem("isLifecycleCTDeveloped");
    // const isLifecycleCHWPDeveloped = localStorage.getItem("isLifecycleCHWPDeveloped");
    // const isLifecycleCDWPDeveloped = localStorage.getItem("isLifecycleCDWPDeveloped");

    return (
        <Grid container className={classes.tabContainer}>
            <Grid item xs={12} style={{ marginTop: -15 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons={useMediaQuery("(max-width: 850px)") ? "on" : "auto"}
                  classes={{
                        fixed: classes.fixed,
                        scrollable: classes.fixed
                    }}
                >
                    {/* <Tab style={tabStyle} value="summary" label="Summary" wrapped /> */}
                    <Tab
                      style={tabStyle}
                      value="recommended"
                      label="Recommended Setpoint"
                      wrapped
                    />
                    <Tab style={tabStyle} value="efficiency" label="Design Efficiency" wrapped />
                    {/* <Tab style={tabStyle} value="analysis" label="Setpoint Analysis" wrapped /> */}
                    {/* <Tab
              style={tabStyle}
              value="coolingTower"
              label="Cooling Tower"
              wrapped
              disabled={isLifecycleCTDeveloped === "false"}
            />
            <Tab
              style={tabStyle}
              value="chwPump"
              label="Chilled Water Pump"
              wrapped
              disabled={isLifecycleCHWPDeveloped === "false"}
            />
            <Tab
              style={tabStyle}
              value="cdwPump"
              label="Condenser Water Pump"
              wrapped
              disabled={isLifecycleCDWPDeveloped === "false"}
            /> */}
                </Tabs>
            </Grid>
        </Grid>
    );
};

export default OptimizationTabs;
