/* eslint-disable max-len */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { VERBIAGE } from "../../utils/enums";
import ChillerSpecOverview from "./chillerSpec/ChillerSpecOverview";
import ChillerSpecPartload from "./chillerSpec/ChillerSpecPartload";

const ChillerSpecManagementTabs = () => {
    return (
        <Switch>
            <Route
              path={VERBIAGE.WAREHOUSE_PATH.CHILLER_SPEC_OVERVIEW}
              component={ChillerSpecOverview}
            />
            <Route
              path={VERBIAGE.WAREHOUSE_PATH.CHILLER_SPEC_PARTLOAD}
              component={ChillerSpecPartload}
            />
            <Route render={() => <Redirect to={VERBIAGE.WAREHOUSE_PATH.CHILLER_SPEC_OVERVIEW} />} />
        </Switch>
    );
};

export default ChillerSpecManagementTabs;
