/* eslint-disable no-unused-expressions */
import { useState, useMemo } from "react";
import noop from "lodash/noop";

import api from "../utils/API";
import TableauV3Adapter from "../utils/TableauV3Adapter";

const useTableau = props => {
    const { onError = noop, options = {} } = props || {};

    const [tableauName, setTableauName] = useState("");
    const [tableauVersion, setTableauVersion] = useState(null);
    const [tableauLink, setTableauLink] = useState(null);
    const [tableauToken, setTableauToken] = useState(null);

    const tableauOptions = useMemo(
        () => ({
            width: "100%",
            height: "100%",
            hideTabs: true,
            hideToolbar: true,
            ...options
        }),
        [options],
    );

    const fetchChart = async (url, dispose = false) => {
        if (dispose) {
            setTableauLink(null);
        }
        try {
            const response = await api.get(url, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            const { version = 2, link, name, token } = response.data;

            setTableauName(name);
            setTableauVersion(version);
            setTableauLink(link);
            if (version === 3 && token) {
                setTableauToken(token);
            }
        } catch (error) {
            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            onError(error.message);
        }
    };

    const tableauV3Adapter = workbook => {
        return new TableauV3Adapter(workbook);
    };

    return {
        fetchChart,
        tableauVersion,
        tableauName,
        tableauLink,
        tableauToken,
        tableauOptions,
        tableauV3Adapter
    };
};

export default useTableau;
