/* eslint-disable */
import React, { useEffect, useState, useReducer, useRef } from "react";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    withStyles,
    makeStyles,
    Grid,
    useMediaQuery,
    TableCell,
    Accordion,
    AccordionSummary,
    createMuiTheme,
    MuiThemeProvider,
    IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from "file-saver";
import "../styles/chillerTable.scss";
import AnalyticsActions from "../../redux_Analytics/AnalyticsActions";
import { REFRESH_TIMER, VERBIAGE, ICONS } from "../../../utils/enums/index";
import URL from "../../../utils/URL";
import "../styles/analyticsTable.scss";
import AnalyticsDropdown from "./AnalyticsDropdown";
import AnalyticsDropdownSearch from "./AnalyticsDropdownSearch";
import MenuExportCsv from "./MenuExportCsv";
import { ExpandLess, ExpandMore, Event } from "@material-ui/icons";
import CustomFooter from "../components/CustomFooter";
import MUIDataTable from "mui-datatables";

Moment.globalLocal = true;
const moment = require("moment");
const MobileDetect = require("mobile-detect");

const KEY_UP = 38;
const KEY_DOWN = 40;

const sortTypes = {
    up: {
        sortImage: ICONS.SORT_UP,
        sortDateFunction: (date1, date2) => date1.start - date2.start,
        sortTimeFunction: (time1, time2) => parseFloat(time1.duration) - parseFloat(time2.duration),
    },
    down: {
        sortImage: ICONS.SORT_DOWN,
        sortDateFunction: (date1, date2) => date2.start - date1.start,
        sortTimeFunction: (time1, time2) => parseFloat(time2.duration) - parseFloat(time1.duration),
    },
    default: {
        sortImage: ICONS.SORT,
        sortDateFunction: date1 => date1,
        sortTimeFunction: time1 => time1,
    },
};

let tableTimer = null;
let sortFnVar;
const AnalyticTable = props => {
    const newInterval = localStorage.getItem("interval");
    const newIntervalType = localStorage.getItem("intervalType");
    const {
        updateFaultInterval,
        analytics,
        changeUrl,
        handleNewLabel,
        setDataValue,
        // first,
        fetchNotes,
        fetchChartPosition,
        statusOfFilter,
    } = props;
    const md = new MobileDetect(window.navigator.userAgent);
    let isNarrow = false;
    if (!!md.tablet()) {
        isNarrow = useMediaQuery("(max-width: 1023px)");
    } else {
        isNarrow = useMediaQuery("(max-width: 850px)");
    }
    const useStyles = makeStyles(theme => ({
        paper: {
            borderRadius: "0px 0px 4px 4px",
            border: isNarrow
                ? ""
                : theme.palette.type === "dark"
                ? "10px solid #201F25"
                : "10px solid #ffffff",
            height: isNarrow ? "450px" : "320px",
            width: "100%",
            overflow: "auto",
        },
        paperCSV: {
            height: "70px",
            borderRadius: "4px 4px 0px 0px",
            width: "100%",
            border: theme.palette.type === "dark" ? "10px solid #201F25" : "10px solid #ffffff",
            background: theme.palette.type === "dark" ? "#201F25" : "#ffffff",
        },
        time: {
            marginTop: "1vh",
            fontSize: "12px",
        },
        label: {
            color: theme.palette.type === "dark" ? "#ACACAC" : "#707070",
        },
        table: {
            fontSize: "1rem",
            textAlign: "center",
            color: theme.palette.type === "dark" ? "#FFFFFF" : "#A0A0A0",
            borderRadius: "4px",
            // maxHeight: "300px",
            // overflow: "auto",
            // display: "block",
        },
        head: {
            fontWeight: "500",
            color: theme.palette.type === "dark" ? "#FFFFFF" : "#707070",
            background: theme.palette.type === "dark" ? "#2F2F2F" : "#F7F7F7",
            textAlignLast: "center",
        },
        tr: {
            height: "50px",
        },
        td: {
            position: "relative",
        },
        indication: {
            color: VERBIAGE.COLOUR_LIGHT_BLUE,
            // color: theme.palette.common.black,
        },
        tdStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: theme.palette.primary.main,
            // minWidth: "100px",
        },
        white: {
            color: theme.palette.common.white,
            // width: "19vw",
        },
        colStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            // minWidth: "100px",
        },
    }));
    const classes = useStyles();
    const [id, setId] = useState(1);
    const [currentDateSort, setCurrentDateSort] = useState("default");
    const [currentTimeSort, setCurrentTimeSort] = useState("default");

    const [tdata, setTdata] = useState(analytics);
    const [dropDown, setDropDown] = useState();
    const [currentfilter, setCurrentFilter] = useState();
    const [fulltable, setFullTable] = useState(true);
    const [startData, setStartData] = useState(0);
    const [endData, setEndData] = useState(10);
    const [selectionData, setSelectionData] = useState({
        selectedIndex: 0,
        keyCode: 0,
    });

    const paperScroll = React.useRef(null);
    const faultTable = React.useRef(null);

    const page = React.useRef(0);
    const data = React.useRef(10);

    const faultEvents = analytics && analytics.length;

    const borderStyle = { borderRadius: "0 4px 0 0" };
    function setData(data) {
        setTdata(data);
        // fetchNotes(data[0].noteId);
        setDataValue(
            data[0].parameter,
            data[0].label,
            data[0].duration,
            data[0].start,
            data[0].noteId,
            data[0].code,
        );
        handleNewLabel(data[0].label, data[0].start, data[0].code, data[0].parameter);
        fetchChartPosition(data[0].code, data[0].parameter);
    }
    //Function for Keyboard selection
    const KeyHandler = event => {
        if (event.keyCode === KEY_UP || event.keyCode === KEY_DOWN) {
            event.stopImmediatePropagation();
            event.preventDefault();
        }

        //upkey event
        if (event.keyCode === KEY_UP) {
            if (selectionData.selectedIndex > 0) {
                const { parameter, label, start, code, id, duration, noteId } = tdata[
                    selectionData.selectedIndex - 1
                ];
                handleEditChange(parameter, label, start, code, id, duration, noteId, KEY_UP);
            }
        }
        //downkey event
        else if (event.keyCode === KEY_DOWN) {
            if (selectionData.selectedIndex < tdata.length) {
                const { parameter, label, start, code, id, duration, noteId } = tdata[
                    selectionData.selectedIndex + 1
                ];
                handleEditChange(parameter, label, start, code, id, duration, noteId, KEY_DOWN);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", KeyHandler);

        return () => {
            document.removeEventListener("keydown", KeyHandler);
        };
    });
    // useeffect for analytics
    useEffect(() => {
        if (analytics && analytics.length) {
            // setStartData(0);
            // page.current = Math.floor(analytics.length / 10);
            // data.current = Math.ceil(analytics.length / page.current);
            // setEndData(data.current);
            if ((!tdata.length || fulltable) && selectionData.selectedIndex === 0) {
                setFullTable(true);
                setData(analytics);
            } else if (statusOfFilter) {
                const idList = tdata.map(item => item[currentfilter]);
                const selectedItems = analytics.filter(
                    item => idList.indexOf(item[currentfilter]) !== -1,
                );
                if (selectedItems.length) {
                    setData(selectedItems);
                } else {
                    setFullTable(true);
                    setData(analytics);
                }
            } else {
                const idList = tdata.map(item => item.id);
                const selectedItems = analytics.filter(item => idList.indexOf(item.id) !== -1);
                if (selectedItems.length) {
                    setTdata(selectedItems);
                    const index = selectionData.selectedIndex;
                    setDataValue(
                        selectedItems[index].parameter,
                        selectedItems[index].label,
                        selectedItems[index].duration,
                        selectedItems[index].start,
                        selectedItems[index].noteId,
                        selectedItems[index].code,
                    );
                    handleNewLabel(
                        selectedItems[index].label,
                        selectedItems[index].start,
                        selectedItems[index].code,
                        selectedItems[index].parameter,
                    );
                }
            }
        }
    }, [analytics]);

    // function to filter data
    const filterData = (selectedList, field) => {
        setCurrentFilter(field);
        let items = [];
        let isAll = true;
        let newTData;
        if (selectedList.length) {
            items = analytics.filter(item => {
                if (selectedList.indexOf(item.id) !== -1) {
                    return true;
                } else {
                    isAll = false;
                    return false;
                }
            });
            if (!items.length) {
                setFullTable(true);
                setTdata(analytics);
                newTData = analytics;
            } else {
                setFullTable(isAll);
                setTdata(items);
                newTData = items;
            }
        } else {
            setFullTable(true);
            setTdata(analytics);
            newTData = analytics;
        }
        setStartData(0);
        page.current = Math.floor(newTData.length / 10);
        data.current = Math.ceil(newTData.length / page.current);
        setEndData(data.current);
    };

    // on edit button click
    const handleEditChange = async (
        parameter,
        label,
        timeStamp,
        code,
        newId,
        duration,
        noteId,
        pressedKeyCode,
    ) => {
        setId(newId);
        const url = `${URL.DATA.ANALYTICS.EDIT}/${parameter}`;
        changeUrl(url, timeStamp, duration);
        handleNewLabel(label, timeStamp, code, parameter);
        fetchNotes(noteId);

        clearTimeout(tableTimer);
        tableTimer = setTimeout(() => {
            tableTimer = null;
            fetchChartPosition(code, parameter);
            const index = tdata.findIndex(item => {
                return item.id === newId;
            });
            setSelectionData({
                lastKeyCode: selectionData.keyCode,
                selectedIndex: index,
                keyCode: pressedKeyCode,
            });
        }, 1000);
    };

    useEffect(() => {
        const selectedRow = document.querySelector(".currentlySelected");
        if (
            selectedRow &&
            selectionData.lastKeyCode === selectionData.keyCode &&
            selectionData.keyCode !== undefined
        ) {
            const hasPressedUp = selectionData.keyCode === KEY_UP;
            // const mainScrollPostion = document.querySelector(".main").scrollTop;

            selectedRow.scrollIntoView(hasPressedUp);

            if (hasPressedUp) {
                const container = document.querySelector(".tableContainer");
                const header = document.querySelector(".tableContainer thead");
                container.scroll(container.scrollLeft, container.scrollTop - header.offsetHeight);
            }
            // document.querySelector(".main").scrollTop = mainScrollPostion;
        }
    }, [selectionData]);

    // useeffect for data
    useEffect(() => {
        if (tdata.length) {
            const selection = tdata.find(item => item.id === id);
            if (!selection) {
                const selectedItem = tdata[0];
                handleEditChange(
                    selectedItem.parameter,
                    selectedItem.label,
                    selectedItem.start,
                    selectedItem.code,
                    selectedItem.id,
                    selectedItem.duration,
                    selectedItem.noteId,
                );
            } else {
                setDataValue(
                    selection.parameter,
                    selection.label,
                    selection.duration,
                    selection.start,
                    selection.noteId,
                    selection.code,
                );
                // handleNewLabel(selection.label, selection.start, selection.code);
                // fetchChartPosition(selection.code, selection.parameter);
            }
        }
    }, [handleEditChange, id, tdata]);

    // function to populate dropdown
    const getFieldData = (field, data) => {
        let items = [];
        if (data) {
            if (field === "label") {
                const labeled = data.find(item => item[field].length);
                if (labeled) {
                    items.push("Labeled");
                }
                const notLabeled = data.find(item => !item[field].length);
                if (notLabeled) {
                    items.push("No Label");
                }
            } else {
                items = data.map(item => item[field]);
            }
            items.sort(function(first, second) {
                if (first < second) {
                    return -1;
                }
                if (first > second) {
                    return 1;
                }
                return 0;
            });
            return [...new Set(items.flat())];
        } else {
            return [];
        }
    };

    const handleScrollBar = event => {
        const scrollLength = parseInt(event.currentTarget.scrollTop);
        // if (scrollLength < 50 && startData > 9) {
        //     setStartData(startData - data.current);
        //     setEndData(endData - data.current);
        //     paperScroll.current.scrollTo(0, paperScroll.current.scrollHeight / 3);
        // }

        if (scrollLength > 450 && endData < tdata.length) {
            // setStartData(startData + data.current);
            setEndData(endData + data.current);
            // paperScroll.current.scrollTo(0, paperScroll.current.scrollHeight / 9);
        }
    };

    const onSortChange = sort => {
        sortFnVar = sort;
        let nextDateSort;
        const sortVar = sort === "date" ? currentDateSort : currentTimeSort;

        if (sortVar === "down") nextDateSort = "up";
        else if (sortVar === "up") nextDateSort = "default";
        else if (sortVar === "default") nextDateSort = "down";
        sort === "date" ? setCurrentDateSort(nextDateSort) : setCurrentTimeSort(nextDateSort);
        // setDataPerPage(10);
    };
    const sortDateIcon =
        sortTypes && sortTypes[currentDateSort] ? sortTypes[currentDateSort].sortImage : "";
    const sortTimeIcon =
        sortTypes && sortTypes[currentTimeSort] ? sortTypes[currentTimeSort].sortImage : "";
    const sortingDate =
        sortTypes && sortTypes[currentDateSort] ? sortTypes[currentDateSort].sortDateFunction : "";
    const sortingTime =
        sortTypes && sortTypes[currentTimeSort] ? sortTypes[currentTimeSort].sortTimeFunction : "";
    const sorting = sortFnVar === "date" ? sortingDate : sortingTime;

    useEffect(() => {
        // const timerValue = REFRESH_TIMER();
        updateFaultInterval();
        // const timer = setInterval(() => {
        // updateFaultInterval();
        // }, timerValue);
        // return () => clearInterval(timer);
    }, [updateFaultInterval]);

    const StyledTableRow = withStyles(theme => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.type === "dark" ? "#414141" : "#FBFBFB",
            },
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.type === "dark" ? "#3A3A3A" : "#F6F6F6",
            },
        },
    }))(TableRow);

    const StyledTableCell = withStyles(() => ({
        root: props => ({
            padding: "16px",
            // color: props.selected ? "#FFFFFF" : "#000000",
            color:
                localStorage.dark === "true" || props.selected
                    ? "#FFFFFF"
                    : props.selected
                    ? "#FFFFFF"
                    : "#A0A0A0",
        }),
    }))(TableCell);

    const handleCsv = () => {
        const csvData = tdata.map(row => {
            const csvRow = {};
            csvRow.id = row.id;
            csvRow.start = moment(row.start).format("Do MMMM YYYY, HH:mm:ss");
            csvRow.duration = row.duration;
            csvRow.code = row.code;
            csvRow.parameter = row.parameter;
            csvRow.description = row.description;
            csvRow.label = row.label;
            csvRow.tag = row.tag;
            csvRow.userTag = row.userTag;
            return csvRow;
        });
        const blob = new Blob([jsonToCSV(csvData)], { type: "text/csv;charset=utf-8;" });
        const now = moment().format("YYYYMMDD");
        saveAs(blob, `${csvData[0].siteId}_Fault_Events_${now}.csv`);
        // saveAs(blob, "Fault_Events.csv");
    };

    const cols = [
        { label: "Start Date/Time", name: "start" },
        { label: "Duration", name: "duration" },
        { label: "Code", name: "code" },
        { label: "Parameter", name: "parameter" },
        { label: "Diagnosis", name: "description" },
        { label: "Fault Labels", name: "label" },
        { label: "Tags", name: "tag" },
        { label: "User Tags", name: "userTag" },
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});
    const expandedDefault = useRef(false);

    const customRowRender = (row, dataIndex, rowIndex) => {
        // const handleAccordion = (event, expanded) => {
        //     event.stopPropagation();
        //     setIsRowExpanded(prev => {
        //         return {
        //             ...prev,
        //             [dataIndex]: !prev[dataIndex],
        //         };
        //     });
        // };
        const rowData = tdata[dataIndex];
        const selected = rowData.id === id;
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion
                        key={dataIndex}
                        // onChange={handleAccordion}
                        elevation={0}
                        style={
                            selected
                                ? {
                                      backgroundColor: "gray",
                                      color: "#ffffff",
                                  }
                                : {
                                      backgroundColor: !!(rowData.id % 2)
                                          ? localStorage.dark === "true"
                                              ? "#414141"
                                              : "#FBFBFB"
                                          : localStorage.dark === "true"
                                          ? "#3A3A3A"
                                          : "#F6F6F6",
                                  }
                        }
                        expanded={
                            isRowExpanded[dataIndex] === undefined
                                ? expandedDefault.current
                                : isRowExpanded[dataIndex]
                        }
                        square={true}
                    >
                        <AccordionSummary
                            onClick={event => {
                                event.stopPropagation();
                                handleEditChange(
                                    rowData.parameter,
                                    rowData.label,
                                    rowData.start,
                                    rowData.code,
                                    rowData.id,
                                    rowData.duration,
                                    rowData.noteId,
                                );
                            }}
                        >
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell selected={selected}>
                                            Start Date/Time
                                        </StyledTableCell>
                                        <StyledTableCell selected={selected}>
                                            <Moment unix tz="UTC" format="Do MMMM YYYY, HH:mm:ss">
                                                {rowData.start / 1000}
                                            </Moment>
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell selected={selected}>
                                            Duration
                                        </StyledTableCell>
                                        <StyledTableCell selected={selected}>
                                            {rowData.duration}
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell selected={selected}>Code</StyledTableCell>
                                        <StyledTableCell selected={selected}>
                                            {rowData.code}
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell selected={selected}>
                                            Parameter
                                        </StyledTableCell>
                                        <StyledTableCell selected={selected}>
                                            {rowData.parameter}
                                        </StyledTableCell>
                                    </TableRow>
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    Diagnosis
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {rowData.description}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    Fault Labels
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {rowData.label}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    Tags
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {rowData.tag}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell selected={selected}>
                                                    User Tags
                                                </StyledTableCell>
                                                <StyledTableCell selected={selected}>
                                                    {rowData.userTag}
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
                <TableCell
                    style={
                        selected
                            ? {
                                  backgroundColor: "gray",
                                  color: "#ffffff",
                              }
                            : {
                                  backgroundColor: !!(rowData.id % 2)
                                      ? localStorage.dark === "true"
                                          ? "#414141"
                                          : "#FBFBFB"
                                      : localStorage.dark === "true"
                                      ? "#3A3A3A"
                                      : "#F6F6F6",
                              }
                    }
                >
                    <IconButton
                        onClick={() => {
                            setIsRowExpanded(prev => {
                                return {
                                    ...prev,
                                    [dataIndex]: !prev[dataIndex],
                                };
                            });
                        }}
                    >
                        {isRowExpanded[dataIndex] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        // fixedHeader: true,
        tableBodyHeight: "333px",
        filter: true,
        filterType: "multiselect",
        print: false,
        viewColumns: false,
        elevation: 0,
        onDownload: () => {
            handleCsv();
            return false;
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    changeRowsPerPage={changeRowsPerPage}
                    changePage={changePage}
                    textLabels={textLabels}
                />
            );
        },
        customRowRender: customRowRender,
    };

    const getMuiTheme = () =>
        createMuiTheme({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 400,
                    md: 600,
                    lg: 1280,
                    xl: 1920,
                },
            },
            overrides: {
                MUIDataTableBodyCell: {
                    root: {
                        fontSize: "1.25rem",
                    },
                },
                MuiTableCell: {
                    root: {
                        fontSize: "1rem",
                        padding: 0,
                    },
                },
                MuiAccordionSummary: {
                    root: {
                        padding: 0,
                    },
                    content: {
                        margin: 0,
                        "&$expanded": {
                            margin: 0,
                        },
                    },
                },
                MUIDataTableToolbar: {
                    filterPaper: {
                        maxWidth: "100%",
                        width: "300px",
                    },
                    root: {
                        background: localStorage.dark === "true" ? "#201F25" : "#ffffff",
                    },
                },
                MuiIconButton: {
                    root: {
                        color: localStorage.dark === "true" ? "#ffffff" : "rgba(0, 0, 0, 0.54)",
                    },
                },
                MuiTableHead: {
                    root: {
                        display: "none",
                    },
                },
            },
        });

    const theme = getMuiTheme();

    return (
        <Grid container>
            <Paper elevation={0} className={classes.paperCSV}>
                <MenuExportCsv data={tdata} isNarrow={isNarrow} />
            </Paper>
            <Paper
                id="paper"
                elevation={0}
                className={`${classes.paper} tableContainer`}
                onScroll={handleScrollBar}
                ref={paperScroll}
            >
                {/* <MenuExportCsv data={tdata} /> */}
                {isNarrow ? (
                    <MuiThemeProvider theme={theme}>
                        <MUIDataTable data={tdata} columns={cols} options={options} />
                    </MuiThemeProvider>
                ) : (
                    <Table ref={faultTable} className={classes.table}>
                        <TableHead className={classes.head}>
                            <TableRow className={classes.tr}>
                                <td className={`${AnalyticTable.styles.time} ${classes.colStyle}`}>
                                    {VERBIAGE.ANALYTICS.START}
                                    <img
                                        alt="sorticon"
                                        src={sortDateIcon}
                                        onClick={() => onSortChange("date")}
                                        className={AnalyticTable.styles.sortIcon}
                                    />
                                </td>
                                <td className={classes.colStyle}>
                                    {VERBIAGE.ANALYTICS.DURATION}
                                    <img
                                        alt="sorticon"
                                        src={sortTimeIcon}
                                        onClick={() => onSortChange("time")}
                                        className={AnalyticTable.styles.sortIcon}
                                    />
                                </td>
                                <td
                                    className={`${classes.td} ${classes.tdStyle} ${
                                        currentfilter == "code" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                >
                                    {VERBIAGE.ANALYTICS.CODE}
                                    {faultEvents ? (
                                        <AnalyticsDropdown
                                            analyticsData={analytics}
                                            statusOfFilter={statusOfFilter}
                                            items={getFieldData("code", analytics)}
                                            currentItems={getFieldData("code", tdata)}
                                            field="code"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            search
                                            fulltable={fulltable}
                                        />
                                    ) : null}
                                </td>
                                <td
                                    className={`${classes.td} ${classes.tdStyle} ${
                                        currentfilter == "parameter" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                >
                                    {VERBIAGE.ANALYTICS.PARAMETER}
                                    {faultEvents ? (
                                        <AnalyticsDropdown
                                            analyticsData={analytics}
                                            statusOfFilter={statusOfFilter}
                                            items={getFieldData("parameter", analytics)}
                                            currentItems={getFieldData("parameter", tdata)}
                                            field="parameter"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            search
                                            fulltable={fulltable}
                                        />
                                    ) : null}
                                </td>
                                {/* <td className={classes.colStyle}>{VERBIAGE.ANALYTICS.DESCRIPTION}</td> */}
                                <td
                                    className={`${classes.td} ${classes.tdStyle} ${
                                        currentfilter == "description" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                >
                                    {VERBIAGE.ANALYTICS.DESCRIPTION}
                                    {faultEvents ? (
                                        <AnalyticsDropdownSearch
                                            analyticsData={analytics}
                                            statusOfFilter={statusOfFilter}
                                            items={getFieldData("description", analytics)}
                                            currentItems={getFieldData("description", tdata)}
                                            field="description"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            search
                                            fulltable={fulltable}
                                        />
                                    ) : null}
                                </td>
                                {/* <td
                                className={`${classes.td} ${classes.tdStyle} ${
                                    currentfilter == "viewed" && !fulltable
                                        ? classes.indication
                                        : classes.white
                                }`}
                            >
                                {VERBIAGE.ANALYTICS.VIEWED}
                                {faultEvents ? (
                                    <AnalyticsDropdown
                                        analyticsData={analytics}
                                        items={getFieldData("viewed", analytics)}
                                        currentItems={getFieldData("viewed", tdata)}
                                        field="viewed"
                                        filterData={filterData}
                                        dropDown={dropDown}
                                        setDropDown={setDropDown}
                                        fulltable={fulltable}
                                    />
                                ) : null}
                            </td> */}
                                <td
                                    className={`${classes.td} ${classes.tdStyle} ${
                                        currentfilter == "label" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                >
                                    {VERBIAGE.ANALYTICS.LABEL}
                                    {faultEvents ? (
                                        <AnalyticsDropdown
                                            analyticsData={analytics}
                                            items={getFieldData("label", analytics)}
                                            currentItems={getFieldData("label", tdata)}
                                            field="label"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            tdata={tdata}
                                            fulltable={fulltable}
                                            textSearch
                                        />
                                    ) : null}
                                </td>
                                <td
                                    className={`${classes.td} ${classes.tdStyle} ${
                                        currentfilter == "tag" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                >
                                    {VERBIAGE.ANALYTICS.TAG}
                                    {faultEvents ? (
                                        <AnalyticsDropdown
                                            analyticsData={analytics}
                                            statusOfFilter={statusOfFilter}
                                            items={getFieldData("tag", analytics)}
                                            currentItems={getFieldData("tag", tdata)}
                                            field="tag"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            search
                                            fulltable={fulltable}
                                        />
                                    ) : null}
                                </td>
                                <td
                                    className={`${classes.td} ${classes.tdStyle} ${
                                        currentfilter == "userTag" && !fulltable
                                            ? classes.indication
                                            : classes.white
                                    }`}
                                    style={borderStyle}
                                >
                                    {VERBIAGE.ANALYTICS.USERTAG}
                                    {faultEvents ? (
                                        <AnalyticsDropdown
                                            analyticsData={analytics}
                                            statusOfFilter={statusOfFilter}
                                            items={getFieldData("userTag", analytics)}
                                            currentItems={getFieldData("userTag", tdata)}
                                            field="userTag"
                                            filterData={filterData}
                                            dropDown={dropDown}
                                            setDropDown={setDropDown}
                                            search
                                            fulltable={fulltable}
                                        />
                                    ) : null}
                                </td>
                            </TableRow>
                            {/* {first
                                ? setDataValue(
                                      analytics[0] ? analytics[0].parameter : null,
                                      analytics[0] ? analytics[0].label : null,
                                      analytics[0] ? analytics[0].duration : null,
                                      analytics[0] ? analytics[0].start : null,
                                  )
                                : null} */}
                        </TableHead>
                        {faultEvents ? (
                            <TableBody>
                                {[...tdata]
                                    .sort(sorting)
                                    .slice(startData, endData)
                                    .map(table => (
                                        <StyledTableRow
                                            key={table.id}
                                            className={table.id === id ? "currentlySelected" : ""}
                                            style={
                                                table.id === id
                                                    ? {
                                                          backgroundColor: "gray",
                                                          color: "#ffffff",
                                                          cursor: "pointer",
                                                          height: "80px",
                                                      }
                                                    : { cursor: "pointer", height: "80px" }
                                            }
                                            onClick={() => {
                                                handleEditChange(
                                                    table.parameter,
                                                    table.label,
                                                    table.start,
                                                    table.code,
                                                    table.id,
                                                    table.duration,
                                                    table.noteId,
                                                    table.index,
                                                );
                                            }}
                                        >
                                            <td
                                                className={AnalyticTable.styles.col}
                                                style={{ width: "10%" }}
                                            >
                                                <Moment
                                                    unix
                                                    tz="UTC"
                                                    format="Do MMMM YYYY, HH:mm:ss"
                                                >
                                                    {table.start / 1000}
                                                </Moment>
                                            </td>
                                            <td
                                                className={AnalyticTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.duration}
                                            </td>
                                            <td
                                                className={AnalyticTable.styles.col}
                                                style={{ width: "5%" }}
                                            >
                                                {table.code}
                                            </td>
                                            <td
                                                className={AnalyticTable.styles.col}
                                                style={{ width: "7%" }}
                                            >
                                                {table.parameter}
                                            </td>
                                            <td className={AnalyticTable.styles.col}>
                                                {table.description}
                                            </td>
                                            {/* <td className={AnalyticTable.styles.col}>{table.viewed}</td> */}
                                            <td className={AnalyticTable.styles.col}>
                                                {table.label}
                                            </td>
                                            <td
                                                className={AnalyticTable.styles.col}
                                                style={{ width: "10%" }}
                                            >
                                                {table.tag.join(", ")}
                                            </td>
                                            <td
                                                className={AnalyticTable.styles.col}
                                                style={{ width: "10%" }}
                                            >
                                                {table.userTag.join(", ")}
                                            </td>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        ) : null}
                    </Table>
                )}
                {faultEvents ? null : (
                    <div className={AnalyticTable.styles.tableError}>
                        {`No Faults found in the last ${newInterval} ${newIntervalType}`}
                    </div>
                )}
            </Paper>
        </Grid>
    );
};
AnalyticTable.styles = {
    row: "webclient__chillerTable-row",
    col: "webclient__chillerTable-col",
    sortIcon: "webclient__analyticsTable-sortIcon",
    time: "webclient__analyticsTable-time",
    tableError: "webclient__analyticsTable-tableError",
};
const mapStateToProps = state => ({
    analytics: state.analytics.tableData,
    statusOfFilter: state.analytics.statusOfFilter,
});
const mapDispatchToProps = dispatch => ({
    updateFaultInterval: () => {
        dispatch(AnalyticsActions.updateFaultInterval());
    },
    fetchChartPosition: (code, parameter) => {
        dispatch(AnalyticsActions.fetchChartPosition(code, parameter));
    },
});
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AnalyticTable), () => true);
