/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import TableauV3 from "../components/TableauV3";
import useTableau from "../../../hooks/useTableau";
import { REFRESH_TIMER } from "../../../utils/enums";

const WaterTreatementTableauChartV3 = props => {
    const {
        addWorksheet,
        report,
        download,
        xs,
        sm,
        md,
        lg,
        configValues,
        setConfigValues,
        handleTitleLink,
        initialFilter,
        fullWidth,
        onError,
        tableauName,
        tableauLink,
        tableauToken,
        tableauOptions,
        tableauRefresh
    } = props;

    const configLoaded = useRef(false);

    const [height, setHeight] = useState(window.innerHeight - 170);

    const initialGridSize = { xs, sm, md, lg };

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight - 170);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (configValues && !configLoaded.current) {
            if (configValues.length > 0) {
                configLoaded.current = true;
            }
        }
    }, [configValues]);

    useEffect(() => {
        const timer = setInterval(refreshChart, REFRESH_TIMER());
        return () => clearInterval(timer);
    });
    // Tableau

    const [tableauViz, setTableauViz] = useState();

    const { tableauV3Adapter } = useTableau();

    const tableauOptionsMemo = useMemo(() => {
        if (configValues) {
            configValues.forEach(config => {
                tableauOptions[config.name] = config.value;
                if (config.value === "True" || config.value === "False") {
                    tableauOptions[config.name] = config.value === "True";
                }
            });
        }

        return tableauOptions;
    }, [tableauOptions, configValues]);

    const handleVizOnload = viz => {
        setTableauViz(viz);
    };

    const refreshChart = async () => {
        try {
            if (!tableauViz) throw new Error("tableauViz is not initialized");
            await tableauViz.refreshDataAsync();
        } catch (error) {
            // eslint-disable-next-line no-unused-expressions
            tableauRefresh?.();
        }
    };

    const onFirstInteractive = useCallback(() => {
        const worksheet = tableauV3Adapter(tableauViz.workbook.activeSheet);
        addWorksheet(worksheet);
        if (initialFilter) {
            initialFilter(worksheet);
        }
    }, [tableauViz, tableauV3Adapter, addWorksheet, initialFilter]);

    const onParameterValueChange = useCallback(
        () => async e => {
            if (configValues) {
                const param = await e.getParameterAsync();
                const paramName = param.getName();
                const paramNewValue = param.getCurrentValue().formattedValue;
                setConfigValues(prev => {
                    const index = prev.findIndex(config => config.name === paramName);
                    const arr = [...prev];
                    arr[index] = { ...arr[index], value: paramNewValue };
                    return arr;
                });
            }
        },
        [configValues, setConfigValues],
    );

    useEffect(() => {
        if (tableauViz) {
            // listen on first interactive
            tableauViz.addEventListener("firstinteractive", onFirstInteractive);
            // listen on parameter changed
            tableauViz.addEventListener("parametervaluechange", onParameterValueChange);
        }
    }, [tableauViz]);

    // Paper styling
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(0.5),
            color: theme.palette.text.secondary,
            height: fullWidth ? "110vh" : height
        },
        spacing: {
            opacity: 0
        },
        grid: {
            padding: "8px 5px",
            marginTop: "-1.5vh",
            [theme.breakpoints.up("xs")]: {
                marginTop: "5.5vh"
            },
            [theme.breakpoints.up("md")]: {
                marginTop: "-1.5vh"
            },
            [theme.breakpoints.up("sm")]: {
                marginTop: "-1.5vh"
            },
            [theme.breakpoints.up("lg")]: {
                marginTop: "2vh"
            },
            width: fullWidth ? "100vw" : height / 1.414
        }
    }));

    const classes = useStyles();

    return (
        <Grid container item className={classes.grid} {...initialGridSize}>
            <React.Fragment>
                <Grid item xs={12}>
                    <Paper className={classes.spacing} />
                </Grid>
            </React.Fragment>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0}>
                    {tableauLink && (
                        <TableauV3
                          name={tableauName}
                          link={tableauLink}
                          token={tableauToken}
                          options={tableauOptionsMemo}
                          onVizLoad={handleVizOnload}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default WaterTreatementTableauChartV3;
