/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import tableau from "tableau-api";
import api from "../../../utils/API";
import TableauMenu from "../components/TableauMenu";
// import SelectChart from "./SelectChart";
import URL from "../../../utils/URL";
import { VERBIAGE } from "../../../utils/enums";
import REFRESH_TIMER from "../../../utils/enums/Enum";

const ReportTableauChartV2 = props => {
    const {
        url,
        addWorksheet,
        report,
        download,
        xs,
        sm,
        md,
        lg,
        configValues,
        setConfigValues,
        handleTitleLink,
        initialFilter,
        fullWidth,
        onError,
        tableauLink,
        tableauName,
        tableauOptions
    } = props;

    const [viz, setViz] = useState(null);
    const [chartTitle, setChartTitle] = useState("");
    const configLoaded = useRef(false);
    const [refresh, toggleRefresh] = useState(false);

    const isMounted = useRef(false);

    const [height, setHeight] = useState(window.innerHeight - 170);

    const initialGridSize = {
        xs,
        sm,
        md,
        lg
    };

    const [gridSize, setGridSize] = useState(initialGridSize);
    const container = useRef();

    const refreshChart = () => {
        toggleRefresh(prev => {
            return !prev;
        });
    };
    const exportPDF = () => {
        viz.showExportPDFDialog();
    };

    // Preserve order of useEffects
    // Dispose first

    const [flag, setFlag] = useState(true);
    const handleResize = () => {
        setHeight(window.innerHeight - 170);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, [handleResize]);
    // useEffect(() => {
    //     const timerValue = REFRESH_TIMER();
    //     const timer = setInterval(() => {
    //         viz.refreshDataAsync();
    //     }, timerValue);
    //     return () => clearInterval(timer);
    // }, []);

    useEffect(() => {
        if (viz !== null) {
            viz.dispose();
            setTimeout(() => {
                setFlag(!flag);
            }, 100);
        }
    }, [url, configLoaded.current, refresh, localStorage.dark]);

    useEffect(() => {
        if (configValues && !configLoaded.current) {
            if (configValues.length > 0) {
                configLoaded.current = true;
            }
        }
    }, [configValues]);

    // Fetch link from API, then pass into Tableau JS API
    useEffect(() => {
        // If Alarm Config page (passing in param values in configValues)
        // then wait for configValues to be loaded and passed in before init viz
        if (configValues && !configLoaded.current) {
            return;
        }

        // Tableau JS API initialising
        const initTableau = link => {
            const options = {
                ...tableauOptions,
                onFirstInteractive: () => {
                    // Pass the worksheet to the parent component for easier handling of filters

                    const parameterCallback = async e => {
                        if (configValues) {
                            const param = await e.getParameterAsync();
                            const paramName = param.getName();
                            const paramNewValue = param.getCurrentValue().formattedValue;
                            if (isMounted.current) {
                                setConfigValues(prev => {
                                    const index = prev.findIndex(
                                        config => config.name === paramName,
                                    );
                                    const arr = [...prev];
                                    arr[index] = { ...arr[index], value: paramNewValue };
                                    return arr;
                                });
                            }
                        }
                    };
                    vz.addEventListener("parameterValueChange", parameterCallback);

                    // If worksheet
                    addWorksheet(vz.getWorkbook().getActiveSheet());
                    if (initialFilter) {
                        initialFilter(vz.getWorkbook().getActiveSheet());
                    }

                    // try {
                    //     console.log('initTableau')
                    //     // If dashboard
                    //     vz.getWorkbook()
                    //         .getActiveSheet()
                    //         .getWorksheets()
                    //         .forEach(ws => {
                    //             console.log('initTableau ws',  ws)
                    //             addWorksheet(ws);
                    //             if (initialFilter) {
                    //                 initialFilter(ws);
                    //                 console.log('initTableau initialFilter')
                    //             }
                    //         });
                    //         console.log('initTableau end')

                    // } catch (error) {
                    //     // If worksheet
                    //     addWorksheet(vz.getWorkbook().getActiveSheet());
                    //     if (initialFilter) {
                    //         initialFilter(vz.getWorkbook().getActiveSheet());
                    //     }
                    // }
                }
            };
            if (configValues) {
                configValues.forEach(config => {
                    options[config.name] = config.value;
                    if (config.value === "True" || config.value === "False") {
                        options[config.name] = config.value === "True";
                    }
                });
            }

            const vz = new window.tableau.Viz(container.current, link, options);
            return vz;
        };

        const init = async () => {
            const themeState = localStorage.dark === "true" ? "DARK" : "LIGHT";
            const fetchData = async () => {
                try {
                    const response = await api.get(download ? `${url}/${themeState}` : url, {
                        headers: {
                            "x-auth-token": localStorage.token
                        }
                    });
                    if (response.request.status === 403) {
                        fetchData();
                    } else {
                        if (isMounted.current) {
                            setChartTitle(response.data.name);
                        }
                        return response.data.link;
                    }
                } catch (error) {
                    if (onError) {
                        onError(error);
                    }
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.href = "/";
                    }
                }
            };
            const link = tableauLink ?? (await fetchData());

            if (isMounted.current && link) {
                setViz(initTableau(link));
                if (tableauName) {
                    setChartTitle(tableauName);
                }
            }
        };
        isMounted.current = true;
        init();

        return () => (isMounted.current = false);
    }, [flag]);

    // Paper styling
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(0.5),
            color: theme.palette.text.secondary,
            height: fullWidth ? "85vh" : height
        },
        spacing: {
            opacity: 0
        },
        grid: {
            padding: "8px 5px",
            marginTop: "-1.5vh",
            [theme.breakpoints.up("xs")]: {
                marginTop: "5.5vh"
            },
            [theme.breakpoints.up("md")]: {
                marginTop: "-1.5vh"
            },
            [theme.breakpoints.up("sm")]: {
                marginTop: "-1.5vh"
            },
            [theme.breakpoints.up("lg")]: {
                marginTop: "-3vh"
            },
            flexGrow: 1,
            width: fullWidth ? "100%" : height / 1.414
            // height: fullWidth ? '600px' : height
        }
    }));

    const classes = useStyles();

    return (
        <Grid
          container
          item
          xs={gridSize.xs}
          sm={gridSize.sm}
          md={gridSize.md}
          lg={gridSize.lg}
          className={classes.grid}
        >
            <React.Fragment>
                <Grid item xs={12}>
                    <TableauMenu
                      chartTitle={chartTitle}
                        // changeGrid={changeGrid}
                      viz={viz}
                      headerValue={false}
                      report={report || download}
                      refreshChart={refreshChart}
                      exportPDF={exportPDF}
                      handleTitleLink={handleTitleLink}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.spacing} />
                </Grid>
            </React.Fragment>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0}>
                    <div
                      ref={c => {
                            container.current = c;
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ReportTableauChartV2;
