import React, { useMemo } from "react";

import MenuIcon from "../MenuIcon";
import { VERBIAGE } from "../../../../utils/enums";
import ICONS from "../../../../utils/enums/IconData";

const VIEW_ALL_PERMISSIONS = ["root", "admin_level", "admin_access", "developer_access"];

const DwhMenu = ({ permission, isMobile, isTablet }) => {
    const dwhMainMenu = useMemo(() => {
        const menu = [
            {
                path: VERBIAGE.WAREHOUSE_PATH.HOME,
                src: ICONS.HOME,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.HOME,
                permissions: ["asset_access"]
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.SITE_MANAGEMENT,
                src: ICONS.SITE_OVERVIEW,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.SITE_MANAGEMENT,
                permissions: ["asset_access"],
                exact: false
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.SCADA_MANAGEMENT,
                src: ICONS.GRID,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.SCADA,
                permissions: ["asset_access"],
                exact: false
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.CHILLER_SPEC_MANAGEMENT,
                src: ICONS.THERMOMETER,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.CHILLER_SPECS,
                permissions: ["asset_access"],
                exact: false
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.WATER_PUMP_SPEC_MANAGEMENT,
                src: ICONS.ROTATE_CW,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.WATER_PUMP_SPECS,
                permissions: ["asset_access"],
                exact: false
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.PID_MANAGEMENT,
                src: ICONS.PID,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.PID,
                permissions: ["asset_access"],
                exact: false
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.COOLING_TOWER_MANAGEMENT,
                src: ICONS.WIND,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.COOLING_TOWER_SPECS,
                permissions: ["asset_access"],
                exact: false
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.INSTRUMENT,
                src: ICONS.TABLET,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.INSTRUMENT,
                permissions: ["asset_access"]
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.REPORTS,
                src: ICONS.REPORTS,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.REPORTS,
                permissions: ["asset_access"]
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.SETTING,
                src: ICONS.SITE_ONBOARDING,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.SETTING,
                permissions: ["asset_access"]
            },
            {
                path: VERBIAGE.WAREHOUSE_PATH.SUPPORT,
                src: ICONS.SUPPORT,
                text: VERBIAGE.WAREHOUSE_MENU_TEXT.SUPPORT,
                permissions: ["asset_access"]
            }
        ];

        if (VIEW_ALL_PERMISSIONS.indexOf(permission) > -1) {
            return menu;
        }

        return menu.filter(m => m.permissions.indexOf(permission) > -1);
    }, [permission]);

    return dwhMainMenu.map(menu => (
        <MenuIcon
          key={menu.path}
          path={menu.path}
          src={menu.src}
          alt={menu.text}
          text={menu.text}
          exact={menu.exact ?? true}
          isMobile={isMobile}
          isTablet={isTablet}
        />
    ));
};

export default DwhMenu;
