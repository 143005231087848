import React from "react";
import { Route } from "react-router-dom";
import { Login } from "./views";
import ForgotPassword from "./views/components/ForgotPassword";
import ResetPassword from "./views/components/ResetPassword";
import MissingDataAck from "./views/components/MissingDataAck";
import MissingDataDone from "./views/components/MissingDataDone";
import MissingDataResolved from "./views/components/MissingDataResolved";

const Authentication = () => (
    <div>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/resetPassword/:token" component={ResetPassword} />
        <Route exact path="/missingData/:status" component={MissingDataAck} />
        <Route exact path="/missingData/resolved/FAIL" component={MissingDataDone} />
        <Route exact path="/missingData/resolved/:token" component={MissingDataResolved} />
    </div>
);

export default Authentication;
