import ReportingActionTypes from "./ReportingActionTypes";

import api from "../../utils/API";
import URL from "../../utils/URL";

// TODO: use interceptor to send auth token header
function getRequestConfig(options = {}) {
    const token = localStorage.getItem("token");
    return {
        headers: {
            "x-auth-token": token
        },
        ...options
    };
}

const ReportingActions = {
    setReportLoading: data => ({
        type: ReportingActionTypes.SET_REPORT_LOADING,
        data
    }),
    setReportError: data => ({
        type: ReportingActionTypes.SET_REPORT_ERROR,
        data
    }),
    setReportMissingData: data => ({
        type: ReportingActionTypes.SET_REPORT_MISSING_DATA,
        data
    }),
    setReportMissingConfig: data => ({
        type: ReportingActionTypes.SET_REPORT_MISSING_CONFIG,
        data
    }),
    setReportRequest: () => dispatch => {
        dispatch(ReportingActions.setReportLoading(true));
        dispatch(ReportingActions.setReportError(null));
    },
    getReportMisingData: (missingParams = {}, isInitial = false) => async (dispatch, getState) => {
        try {
            dispatch(ReportingActions.setReportRequest());
            dispatch({
                type: ReportingActionTypes.SET_REPORT_MISSING_PARAMS,
                data: missingParams
            });

            const {
                reporting: {
                    missingData: { params }
                }
            } = getState();

            const response = await api.get(
                URL.DATA.REPORTING.MISSING_DATA,
                getRequestConfig({ params }),
            );
            dispatch(ReportingActions.setReportMissingData(response.data));
            if (isInitial && response.data.count > 0) {
                dispatch(ReportingActions.getReportMissinConfig());
            }
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
                return;
            }
            dispatch(ReportingActions.setReportError(error.message));
        } finally {
            dispatch(ReportingActions.setReportLoading(false));
        }
    },
    getReportMissinConfig: () => async dispatch => {
        try {
            const url = `${URL.DATA.REPORTING.MISSING_DATA}/config`;
            const response = await api.get(url, getRequestConfig());
            const { MISSING_TYPE: types, MISSING_PARAMS: params, STATUS: status } = response.data;
            dispatch(ReportingActions.setReportMissingConfig({ types, params, status }));
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }
};

export default ReportingActions;
