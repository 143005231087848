import React from "react";
import PropTypes from "prop-types";
import "../styles/modalclosebutton.scss";

const ModalCloseButton = props => {
    const { onClick } = props;
    return (
      <div className={ModalCloseButton.style.closeContainer}>
        <button
          type="button"
          onClick={onClick}
          className={ModalCloseButton.style.buttonContainer}
        >
          <i className="fa fa-close" />
        </button>
      </div>
    );
};
ModalCloseButton.style = {
    buttonContainer: "webclient__modal-close-button-container-button",
    closeContainer: "webclient__modal-close-button-container-close"
};
ModalCloseButton.propTypes = {
    onClick: PropTypes.func.isRequired
};
export default ModalCloseButton;
