/* eslint-disable max-len */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { VERBIAGE } from "../../utils/enums";
import ScadaOverview from "./scadaOverview/ScadaOverview";

const ScadaManagementTabs = () => {
    return (
        <Switch>
            <Route path={VERBIAGE.WAREHOUSE_PATH.SCADA_OVERVIEW} component={ScadaOverview} />
            <Route render={() => <Redirect to={VERBIAGE.WAREHOUSE_PATH.SCADA_OVERVIEW} />} />
        </Switch>
    );
};

export default ScadaManagementTabs;
