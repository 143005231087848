import React, { useState, useEffect, useMemo } from "react";
import {
    Button,
    makeStyles,
    Typography,
    Grid,
    Paper,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import { Edit, EditOutlined } from "@material-ui/icons";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import API from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { getResponseErrorDetails, scrollIntoFirstErrorElement } from "../../../utils/helpers";

import FormRenderField from "../../../dashboard/common/components/FormRenderField";

const WaterPumpSpecSchema = Yup.object().shape({
    wpPManuYear: Yup.number().integer(),
    wpMManuYear: Yup.number().integer(),
    wpType: Yup.string(),
    wpSubType: Yup.string(),
    wpVolt: Yup.number(),
    wpPhase: Yup.number(),
    wpHz: Yup.number(),
    wpFullP: Yup.number(),
    wpRla: Yup.number(),
    wpPHead: Yup.number(),
    wpPF: Yup.number(),
    wpPEff: Yup.number(),
    wpMEff: Yup.number(),
    wpMEffType: Yup.string(),
    wpMInsulType: Yup.string(),
    wpRpm: Yup.number(),
    wpStarter: Yup.number(),
    isActive: Yup.boolean()
});

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    title: {
        fontSize: theme.spacing(2),
        fontWeight: "bold"
    },
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    },
    hide: {
        display: "none"
    }
}));

const EditWaterPumpSpecOverview = ({ wpId }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const initData = useMemo(
        () => ({
            wpPManuYear: 0,
            wpMManuYear: 0,
            wpType: VERBIAGE.TEXT.EMPTY_STRING,
            wpSubType: VERBIAGE.TEXT.EMPTY_STRING,
            wpVolt: 0,
            wpPhase: 0,
            wpHz: 0,
            wpFullP: 0,
            wpRla: 0,
            wpPHead: 0,
            wpPF: 0,
            wpPEff: 0,
            wpMEff: 0,
            wpMEffType: VERBIAGE.TEXT.EMPTY_STRING,
            wpMInsulType: VERBIAGE.TEXT.EMPTY_STRING,
            wpRpm: 0,
            wpStarter: 0,
            isActive: true
        }),
        [],
    );
    const [values, setValues] = useState(initData);

    useEffect(() => {
        const fetchWaterPumpSpec = async () => {
            try {
                setLoading(true);

                const url = `${URL.DATA.DWH.WATERPUMP_SPEC}/${wpId}/waterPump`;
                const response = await API.get(url, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data) {
                    setValues({
                        ...initData,
                        ...response.data,
                        waterPumpSpecId: wpId
                    });
                }
            } catch (err) {
                if (err.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            } finally {
                setLoading(false);
            }
        };

        if (wpId) {
            fetchWaterPumpSpec();
        }
    }, [wpId, initData]);

    const {
        control,
        handleSubmit,
        setError: setFormError,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(WaterPumpSpecSchema),
        values
    });

    useEffect(() => {
        if (Object.keys(errors).length) {
            scrollIntoFirstErrorElement({
                elementIds: Object.keys(errors)
            });
        } else {
            setError(null);
        }
    }, [errors]);

    const onClose = () => {
        setError(null);
        setIsEdit(false);
    };

    const toggleEdit = () => {
        setIsEdit(e => !e);
    };

    const handleResponseError = response => {
        const resError = getResponseErrorDetails(response);
        if (resError.type === "invalid") {
            Object.keys(resError.errors).forEach(field => {
                setFormError(field, { type: "invalid", message: resError.errors[field] });
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstErrorElement({ elementIds: Object.keys(resError.errors) });
        } else {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        }
    };

    const onSubmit = async waterPumpData => {
        try {
            setLoading(true);

            const requestConfig = {
                url: `${URL.DATA.DWH.WATERPUMP_SPEC}/${wpId}/waterPump`,
                data: waterPumpData,
                headers: {
                    "x-auth-token": localStorage.token
                }
            };
            if (values._id) {
                requestConfig.method = "PUT";
                requestConfig.url = `${URL.DATA.DWH.WATERPUMP_SPEC}/${values._id}`;
            } else {
                requestConfig.method = "POST";
                requestConfig.url = `${URL.DATA.DWH.WATERPUMP_SPEC}/${wpId}/waterPump`;
            }
            const response = await API.request(requestConfig);
            if (response.data?.status === "error") {
                handleResponseError(response);
                return;
            }

            setValues(prev => ({ ...prev, ...response.data }));
            onClose();
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else if (err.response.status === 404) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            } else {
                handleResponseError(err.response);
            }
        } finally {
            setLoading(false);
        }
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    const classes = useStyles();

    const inputCols1 = [
        {
            name: "waterPumpSpecId",
            label: "Pump Specification ID",
            type: "number",
            numberType: "double",
            disabled: true
        },
        {
            name: "wpPManuYear",
            label: "Pump Manu. Year",
            type: "number"
        },
        {
            name: "wpMManuYear",
            label: "Motor Manu. Year",
            type: "number"
        },
        {
            name: "wpType",
            label: "Type",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_PUMP_TYPE
        },
        {
            name: "wpSubType",
            label: "SubType",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_PUMP_SUB_TYPE
        },
        {
            name: "wpVolt",
            label: "Volt",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpPhase",
            label: "Phase",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpHz",
            label: "Hz",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpFullP",
            label: "Full Preasure",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpRla",
            label: "RLA",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpPHead",
            label: "Pump Head",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpPF",
            label: "Pump Flow",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpPEff",
            label: "Pump Eff.",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpMEff",
            label: "Motor Eff.",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpMEffType",
            label: "Motor Eff. Type",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_MOTOR_EFF_TYPE
        },
        {
            name: "wpMInsulType",
            label: "Motor Insul. Type",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_MOTOR_INSUL_TYPE
        },
        {
            name: "wpRpm",
            label: "RPM",
            type: "number",
            numberType: "double"
        },
        {
            name: "wpStarter",
            label: "Starter",
            type: "number",
            numberType: "double"
        },
        {
            name: "createdAt",
            label: "Created Date",
            disabled: true
        },
        {
            name: "updatedAt",
            label: "Updated Date",
            disabled: true
        }
    ];

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12} container direction="row" alignItems="center" spacing={1}>
                    <Typography variant="span" className={classes.title}>
                        Pump Specification
                    </Typography>
                    {loading ? (
                        <CircularProgress size={12} />
                    ) : (
                        <IconButton onClick={toggleEdit}>
                            {isEdit ? <Edit /> : <EditOutlined />}
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={12} container spacing={0}>
                    {inputCols1.map(input => {
                        if (input.hide) return null;
                        return (
                            <Grid item container alignItems="center" key={input.name}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">{input.label}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {isEdit ? (
                                        <Controller
                                          name={input.name}
                                          control={control}
                                          render={({ field: { value, onChange } }) => (
                                                <FormRenderField
                                                  input={input}
                                                  value={value}
                                                  onChange={onChange}
                                                  errors={errors}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <Typography>{values[input.name]}</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                {!!error && (
                    <Grid item xs={12} className={classes.error}>
                        <Typography variant="subtitle1">{error}</Typography>
                    </Grid>
                )}
                {isEdit && (
                    <Grid item xs={12} container>
                        <Button
                          color="primary"
                          onClick={handleSubmit(onSubmit, onError)}
                          disabled={loading}
                        >
                            {VERBIAGE.BUTTONS.SUBMIT}
                        </Button>
                        <Button className={classes.error} onClick={onClose} disabled={loading}>
                            {VERBIAGE.BUTTONS.CLOSE}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default EditWaterPumpSpecOverview;
