/* eslint-disable max-len */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { VERBIAGE } from "../../utils/enums";

import SiteOverview from "./siteOverview/SiteOverview";
import SiteWaterPump from "./siteWaterPump/SiteWaterPump";
import SiteCoolingTower from "./siteCoolingTower/SiteCoolingTower";
import SitePid from "./sitePid/SitePid";
import SiteInstrument from "./siteInstrument/SiteInstrument";
import SiteChiller from "./siteChiller/SiteChiller";
import SiteEnvironment from "./siteEnvironment/SiteEnvironment";

const SiteManagementTabs = () => {
    return (
        <Switch>
            <Route path={VERBIAGE.WAREHOUSE_PATH.SITE_OVERVIEW} component={SiteOverview} />
            <Route path={VERBIAGE.WAREHOUSE_PATH.SITE_WATER_PUMP} component={SiteWaterPump} />
            <Route path={VERBIAGE.WAREHOUSE_PATH.SITE_COOLING_TOWER} component={SiteCoolingTower} />
            <Route path={VERBIAGE.WAREHOUSE_PATH.SITE_PID} component={SitePid} />
            <Route path={VERBIAGE.WAREHOUSE_PATH.SITE_INSTRUMENT} component={SiteInstrument} />
            <Route path={VERBIAGE.WAREHOUSE_PATH.SITE_CHILLER} component={SiteChiller} />
            <Route path={VERBIAGE.WAREHOUSE_PATH.SITE_ENVIRONMENT} component={SiteEnvironment} />
            <Route render={() => <Redirect to={VERBIAGE.WAREHOUSE_PATH.SITE_OVERVIEW} />} />
        </Switch>
    );
};

export default SiteManagementTabs;
