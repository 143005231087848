import React from "react";
import Typography from "@material-ui/core/Typography";

const NotAvailableRender = React.memo(({ condition, children }) => {
    if (!condition) {
        return <Typography>N/A</Typography>;
    }
    return children;
});

export default NotAvailableRender;
