/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, forwardRef } from "react";
import {
    Accordion,
    AccordionSummary,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Add, EditOutlined, ExpandMore } from "@material-ui/icons";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
// import MaterialTable from "material-table";
// import tableIcons from "../../../utils/tableIcons";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";
import EditSiteTable from "./EditSiteTableV2";
import CustomFooter from "./CustomFooter";

const SiteTable = () => {
    const [data, setData] = useState([]);
    // const columns = [
    //     { title: "Site ID", field: "siteId" },
    //     { title: "Site Name", field: "siteName" },
    //     { title: "Address", field: "address" }
    // ];
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    const isNarrow = useMediaQuery("(max-width: 600px)");

    const isMounted = useRef(false);

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        siteName: VERBIAGE.TEXT.EMPTY_STRING,
        address: VERBIAGE.TEXT.EMPTY_STRING,
        longitude: VERBIAGE.TEXT.EMPTY_STRING,
        latitude: VERBIAGE.TEXT.EMPTY_STRING,
        mapName: VERBIAGE.TEXT.EMPTY_STRING,
        mainLogo: VERBIAGE.TEXT.EMPTY_STRING,
        circleLogo: VERBIAGE.TEXT.EMPTY_STRING,
        bigLogo: VERBIAGE.TEXT.EMPTY_STRING,
        smallLogo: VERBIAGE.TEXT.EMPTY_STRING,
        mainNightLogo: VERBIAGE.TEXT.EMPTY_STRING,
        circleNightLogo: VERBIAGE.TEXT.EMPTY_STRING,
        bigNightLogo: VERBIAGE.TEXT.EMPTY_STRING,
        smallNightLogo: VERBIAGE.TEXT.EMPTY_STRING,
        numChillers: 0,
        numCoolingTowers: 0,
        numCHPumps: 0,
        numSCHPumps: 0,
        numCDPumps: 0,
        chillerPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        coolingTowerPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        CDPumpsPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        CHPumpsPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        SCHPumpsPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        refreshRate: 1,
        siteInCharge: [],
        siteManager: [],
        siteUsername: VERBIAGE.TEXT.EMPTY_STRING,
        tableauVersion: 2, // VERBIAGE.TEXT.NULL_VALUE,
        siteStatus: VERBIAGE.TEXT.NULL_VALUE
    };

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.PLANT_SETTINGS.SITES, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (isMounted.current) {
                    setData(response.data);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: "16px"
        }
    }))(TableCell);

    const cols = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <IconButton
                          onClick={() => {
                                setIsEdit(true);
                                editRef.current.update({
                                    ...editRef.current.getConfig(),
                                    ...data[dataIndex]
                                });
                                handleClick();
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    );
                }
            }
        },
        { label: "Site ID", name: "siteId" },
        { label: "Site Name", name: "siteName" },
        { label: "Address", name: "address" },
        {
            label: "Site In Charge",
            name: "sites",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <React.Fragment>
                            {data[dataIndex].siteInChargeName.map((row, i) => {
                                return (
                                    <div key={row} style={{ width: "100px", padding: "3px" }}>
                                        <span>{row}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "Site Manager",
            name: "sites",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <React.Fragment>
                            {data[dataIndex].siteManagerName.map((row, i) => {
                                return (
                                    <div key={row} style={{ width: "100px", padding: "3px" }}>
                                        <span>{row}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        { label: "Site Status", name: "siteStatus" },
        {
            label: "Tableau Version",
            name: "tableauVersion",
            options: {
                customBodyRender: value => {
                    return `v${value}`;
                }
            }
        }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        const rowData = data[dataIndex];
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell>
                                            <IconButton
                                              onClick={event => {
                                                    event.stopPropagation();
                                                    setIsEdit(true);
                                                    editRef.current.update({
                                                        ...editRef.current.getConfig(),
                                                        ...rowData
                                                    });
                                                    handleClick();
                                                }}
                                              onFocus={event => event.stopPropagation()}
                                            >
                                                <EditOutlined />
                                            </IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Site ID</StyledTableCell>
                                        <StyledTableCell>{rowData.siteId}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Site Name</StyledTableCell>
                                        <StyledTableCell>{rowData.siteName}</StyledTableCell>
                                    </TableRow>
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell>Address</StyledTableCell>
                                                <StyledTableCell>{rowData.address}</StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell>Site In Charge</StyledTableCell>
                                                <StyledTableCell>
                                                    {cols[4].options.customBodyRenderLite(
                                                        dataIndex,
                                                        rowIndex,
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell>Site Manager</StyledTableCell>
                                                <StyledTableCell>
                                                    {cols[5].options.customBodyRenderLite(
                                                        dataIndex,
                                                        rowIndex,
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                    <TableRow>
                                        <StyledTableCell>Tableau Version</StyledTableCell>
                                        <StyledTableCell>{`v${rowData.tableauVersion}`}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Site Status</StyledTableCell>
                                        <StyledTableCell>{rowData.siteStatus}</StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "400px",
        filter: false,
        print: false,
        viewColumns: false,
        download: false,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title="Add Site">
                        <IconButton
                          onClick={async () => {
                                await setIsEdit(false);
                                editRef.current.update(emptyConfig);
                                handleClick();
                            }}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    return (
        <div>
            <EditSiteTable
              isEdit={isEdit}
              open={open}
              setOpen={setOpen}
              ref={editRef}
              siteList={data}
            />
            <div className={classes.table}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable title="Site Data" data={data} columns={cols} options={options} />
                </MuiThemeProvider>
                {/* <MaterialTable
            icons={tableIcons}
            title="Sites Data"
            columns={columns}
            data={data}
            options={{
                        headerStyle: { position: "sticky", top: 0 },
                        maxBodyHeight: "400px"
                    }}
            localization={{
                        header: {
                            actions: ""
                        }
                    }}
            actions={[
                        {
                            icon: tableIcons.Add,
                            onClick: async (event, rowData) => {
                                await setIsEdit(false);
                                editRef.current.update(emptyConfig);
                                handleClick();
                            },
                            isFreeAction: true,
                            tooltip: "Add Site"
                        },
                        rowData => ({
                            icon: tableIcons.Edit,
                            onClick: async () => {
                                await setIsEdit(true);
                                editRef.current.update({
                                    ...editRef.current.getConfig(),
                                    ...rowData
                                });
                                handleClick();
                            },
                            tooltip: "Edit"
                        })
                    ]}
          /> */}
            </div>
        </div>
        //   </div>
    );
};

export default SiteTable;
