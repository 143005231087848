/* eslint-disable max-len */
import React, { useState, useRef, useEffect, useCallback } from "react";

import SiteSelector from "../SiteSelector";
import DwhDataTable from "../DwhDataTable";

import API from "../../../utils/API";
import URL from "../../../utils/URL";

import useQueryParams from "../../../hooks/useQueryParams";
import EditSiteInstrument from "./EditSiteInstrument";
import TagSiteInstrument from "./TagSiteInstrument";

const SiteInstrument = () => {
    const { getQuery } = useQueryParams();
    const siteNumber = getQuery("siteNumber");

    const [data, setData] = useState([]);
    const [openTag, setOpenTag] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [loading, setLoading] = useState(false);

    const tagRef = useRef();
    const editRef = useRef();

    const fetchData = useCallback(async siteId => {
        try {
            setLoading(true);

            const url = URL.DATA.DWH.SITE_INSTRUMENT;
            const response = await API.get(url, {
                params: {
                    siteId,
                    _sort: "id",
                    _order: "ASC"
                },
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            if (response.data) {
                setData(response.data);
            }
        } catch (err) {
            if (err.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (siteNumber) {
            fetchData(siteNumber);
        }
    }, [siteNumber, fetchData]);

    const handleClickDataTable = (e, { action, params }) => {
        switch (action) {
            case "add":
                editRef.current.init();
                setIsEdit(false);
                setOpenEdit(true);
                break;
            case "edit":
                editRef.current.update({
                    ...data[params.dataIndex]
                });
                setIsEdit(true);
                setOpenEdit(true);
                break;
            case "tag":
                tagRef.current.set({
                    ...data[params.dataIndex]
                });
                setOpenTag(true);
                break;
            default:
        }
    };

    const handleEditSuccess = siteId => {
        fetchData(siteId);
    };

    const columns = [
        { actions: ["tag", "edit"] },
        { label: "Instrument ID", name: "instId" },
        { label: "Category", name: "instCategory" },
        { label: "Serial No", name: "instSno" },
        { label: "Manufacturer", name: "instManufacturer" },
        { label: "Model", name: "instModel" },
        { label: "Description", name: "instDesc", narrow: true },
        {
            label: "Calibration Date",
            name: "instCalibrationDate",
            _options: { type: "date" },
            narrow: true
        },
        {
            label: "Comission Date",
            name: "instCommisionDate",
            _options: { type: "date" },
            narrow: true
        },
        { label: "St.Line Ds", name: "instStraightLineDs", narrow: true },
        { label: "St.Line Up", name: "instStraightLineUp", narrow: true },
        { label: "Orientation", name: "instOrientation", narrow: true },
        { label: "Voltage", name: "instSuppliedVoltage", narrow: true },
        { label: "UOM", name: "instSuppliedUom", narrow: true },
        {
            label: "Out.Type 1/UOM",
            name: "",
            options: {
                customBodyRender: (_, tableMeta) => {
                    const { instOutputType1, instOutputType1Uom } = data[tableMeta.rowIndex];
                    return `${instOutputType1}/${instOutputType1Uom}`;
                }
            },
            narrow: true
        },
        {
            label: "Out.Type 2/UOM",
            name: "",
            options: {
                customBodyRender: (_, tableMeta) => {
                    const { instOutputType2, instOutputType2Uom } = data[tableMeta.rowIndex];
                    return `${instOutputType2}/${instOutputType2Uom}`;
                }
            },
            narrow: true
        },
        { label: "Type", name: "instType", narrow: true },
        { label: "Active", name: "isActive", _options: { type: "boolean" }, narrow: true },
        { label: "Created Date", name: "createdAt", _options: { type: "date" }, narrow: true },
        { label: "Updated Date", name: "updatedAt", _options: { type: "date" }, narrow: true }
    ];

    const options = {
        viewColumns: true
    };

    return (
        <>
            <SiteSelector />
            {siteNumber && (
                <DwhDataTable
                  name="Site Instrument"
                  loading={loading}
                  data={data}
                  columns={columns}
                  options={options}
                  onClick={handleClickDataTable}
                />
            )}
            <TagSiteInstrument ref={tagRef} open={openTag} setOpen={setOpenTag} />
            <EditSiteInstrument
              ref={editRef}
              isEdit={isEdit}
              open={openEdit}
              setOpen={setOpenEdit}
              onSuccess={handleEditSuccess}
            />
        </>
    );
};

export default SiteInstrument;
