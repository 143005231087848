/* eslint-disable no-param-reassign */
import React from "react";
import {
    FormControl,
    InputLabel,
    Select,
    Checkbox,
    MenuItem,
    ListItemText
} from "@material-ui/core";

const SelectMultipleFilterDataTable = ({
    optionValues = [],
    filterList,
    onChange,
    index,
    column
}) => {
    const id = `${column.name.toLowerCase()}-select-multiple`;
    return (
        <FormControl>
            <InputLabel shrink id={id}>
                {column.label}
            </InputLabel>
            <Select
              multiple
              id={id}
              value={filterList[index]}
              renderValue={selected => selected.join(", ")}
              onChange={event => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                }}
            >
                {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                        <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                        <ListItemText primary={item} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectMultipleFilterDataTable;
