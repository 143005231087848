/* eslint-disable max-len */
import React, { useState, useEffect, memo } from "react";
import { TextField, Menu, MenuItem } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { VERBIAGE } from "../../../utils/enums/index";

const TextInputParse = memo(props => {
    const {
        type,
        value,
        onChange,
        placeholder,
        // id,
        multiline,
        live,
        disabled,
        styles,
        rows,
        helperText,
        defaultValue,
        userTagsChips
    } = props;

    // Changed TextInput to maintain its own state, and update parent component
    // state onBlur to improve typing performance
    const [input, setInput] = useState({
        target: {
            value: ""
        }
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const divRef = React.createRef(); // React.useRef();

    const handleSpecialChar = () => {
        setAnchorEl(divRef.current);
    };

    const handle2Change = event => {
        const stringValue = event.target.value;
        const lastChar = stringValue.slice(-1);
        if (lastChar === "@") {
            handleSpecialChar();
        }
        setInput({
            target: {
                value: event.target.value
            }
        });
    };

    const handleLiveChange = event => {
        const newInput = {
            target: {
                value: event.target.value
            }
        };
        setInput(newInput);
        onChange(newInput);
    };

    useEffect(() => {
        setInput({
            target: {
                value
            }
        });
    }, [value]);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleNotesClick = (event, index) => {
        setInput({
            target: {
                value: input.target.value + index
            }
        });
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                },
                input: {
                    color: theme.palette.type === "dark" ? "#ffff" : "black"
                }
            },
            MuiInputLabel: {
                outlined: {
                    color: theme.palette.type === "dark" ? "#ffff" : "rgba(0, 0, 0, 0.54)"
                }
            }
        }
    });

    return live ? (
        <MuiThemeProvider theme={themeOverride}>
            <TextField
              name="input"
              id={id}
              type={type}
              value={input.target.value}
              onChange={handleLiveChange}
              variant="outlined"
              label={placeholder}
              fullWidth
              multiline={multiline}
              rows={rows}
              disabled={disabled}
              helperText={helperText}
              defaultValue={defaultValue}
            />
        </MuiThemeProvider>
    ) : (
        <div ref={divRef}>
            <MuiThemeProvider theme={themeOverride}>
                <Menu
                  id={id}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    {userTagsChips &&
                        userTagsChips.map(data => {
                            return (
                                <MenuItem onClick={event => handleNotesClick(event, data.value)}>
                                    {data.label}
                                </MenuItem>

                                //   <li key={data.key}>
                                //     <Chip
                                //       label={data.label}
                                //       className={classes.chip}
                                //       onClick={() => handleNotesClick(data.value)}
                                //       style={{
                                //                 color:
                                //                     styleTheme.palette.type === "dark"
                                //                         ? styleTheme.palette.text.dark_grey
                                //                         : styleTheme.palette.text
                                //                               .light_black,
                                //                 border: `1px solid ${data.color}`,
                                //                 background: "transparent",
                                //                 height: "20px"
                                //             }}
                                //     />
                                //   </li>
                            );
                        })}
                </Menu>
                <TextField
                  name="input"
                  id={id}
                  type={type}
                  value={input.target.value}
                  onChange={handle2Change}
                  variant="outlined"
                  label={placeholder}
                  fullWidth
                  multiline={multiline}
                  rows={rows}
                  onBlur={() => onChange(input)}
                  disabled={disabled}
                  InputProps={styles}
                  helperText={helperText}
                  defaultValue={defaultValue}
                />
            </MuiThemeProvider>
        </div>
    );
});

export default TextInputParse;
