/* eslint-disable max-len */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { VERBIAGE } from "../../utils/enums";
import PidOverview from "./pidOverview/PidOverview";

const PidManagementTabs = () => {
    return (
        <Switch>
            <Route path={VERBIAGE.WAREHOUSE_PATH.PID_OVERVIEW} component={PidOverview} />
            <Route render={() => <Redirect to={VERBIAGE.WAREHOUSE_PATH.PID_OVERVIEW} />} />
        </Switch>
    );
};

export default PidManagementTabs;
