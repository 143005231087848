import React from "react";

import useQueryParams from "../../../hooks/useQueryParams";

import SiteSelector from "../SiteSelector";
import CoolingTowerSelector from "./CoolingTowerSelector";
import EditCoolingTowerSpecOverview from "./EditCoolingTowerSpecOverview";

const CoolingTowerSpecOverview = () => {
    const { getQuery } = useQueryParams();
    const ctId = getQuery("ctId");

    return (
        <>
            <SiteSelector />
            <CoolingTowerSelector />
            {ctId && <EditCoolingTowerSpecOverview ctId={ctId} />}
        </>
    );
};

export default CoolingTowerSpecOverview;
