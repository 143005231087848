/* eslint-disable max-len */
import React from "react";
import { Select, FormControl, FormHelperText, InputLabel, MenuItem } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { VERBIAGE } from "../../../utils/enums/index";

const DropdownSelect = props => {
    const {
        id,
        name,
        options,
        onChange,
        placeholder,
        isMulti,
        noInputLabel,
        error,
        helperText
    } = props;

    // Override border color of input
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            }
        }
    });

    const selectProps = {};
    if (noInputLabel) {
        selectProps.placeholder = placeholder;
        selectProps.inputProps = { "aria-label": "Without label" };
    } else {
        selectProps.label = placeholder;
    }

    return (
        <MuiThemeProvider theme={themeOverride}>
            <FormControl variant="outlined" fullWidth error={error}>
                {!noInputLabel && <InputLabel>{placeholder}</InputLabel>}
                <Select
                  id={id}
                  label={placeholder}
                  value={!name ? "" : name}
                  onChange={onChange}
                  fullWidth
                  variant="outlined"
                  multiple={isMulti}
                  MenuProps={{ disableScrollLock: true }}
                  {...selectProps}
                >
                    <MenuItem value="" disabled>
                        Please select...
                    </MenuItem>
                    {options.map(option => {
                        return (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </MuiThemeProvider>
    );
};

export default DropdownSelect;
