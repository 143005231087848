import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Grid,
    Tabs,
    Tab
} from "@material-ui/core";

import VERBIAGE from "../../../utils/enums/Verbiage";

import useQueryParams from "../../../hooks/useQueryParams";
import TextInput from "../../../dashboard/common/components/TextInput";
import TagSiteInstrumentChiller from "./TagSiteInstrumentChiller";
import TagSiteInstrumentWaterPump from "./TagSiteInstrumentWaterPump";
import TagSiteInstrumentInstrument from "./TagSiteInstrumentInstrument";

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    },
    hide: {
        display: "none"
    }
}));

const TagSiteInstrument = forwardRef((props, ref) => {
    const { open, setOpen } = props;

    const { getQuery } = useQueryParams();
    const siteNumber = getQuery("siteNumber");

    const [error, setError] = useState(null);
    const [instrument, setInstrument] = useState({});

    useImperativeHandle(ref, () => ({
        set(data) {
            setInstrument({ ...data });
        }
    }));

    const [value, setValue] = useState("chiller");
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const handleError = e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
    };

    const onSubmit = () => {
        setOpen(false);
    };

    const onClose = () => {
        setError(null);
        setOpen(false);
    };

    const classes = useStyles();

    return (
        <Dialog
          fullWidth
          maxWidth="md"
          aria-labelledby="form-tag-dialog"
          open={open}
          onClose={onClose}
        >
            <DialogTitle>{`To tag site's instrument data enter the details below.`}</DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item container alignItems="center">
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">Instrument ID</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextInput value={instrument.instrumentId} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                            >
                                <Tab value="chiller" label="CHILLER" wrapped />
                                <Tab value="waterPump" label="WATER PUMP" wrapped />
                                <Tab value="instrument" label="INSTRUMENT" wrapped />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12}>
                            {value === "chiller" && (
                                <TagSiteInstrumentChiller
                                  id={instrument._id}
                                  siteNumber={siteNumber}
                                  onError={handleError}
                                />
                            )}
                            {value === "waterPump" && (
                                <TagSiteInstrumentWaterPump
                                  id={instrument._id}
                                  siteNumber={siteNumber}
                                  onError={handleError}
                                />
                            )}
                            {value === "instrument" && (
                                <TagSiteInstrumentInstrument
                                  id={instrument._id}
                                  siteNumber={siteNumber}
                                  onError={handleError}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {!!error && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography variant="subtitle1">{error}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={onClose}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default TagSiteInstrument;
