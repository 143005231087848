import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { MuiThemeProvider, CircularProgress, Button } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

import CustomDataTableHead from "./customDataTable/CustomDataTableHead";

import ReportActions from "../../redux/ReportActions";
import Colors from "../../../utils/Colors";

const dynamicReportTheme = createMuiTheme({
    overrides: {
        MUIDataTable: {
            paper: {
                boxShadow: "none"
            }
        },
        MuiTableCell: {
            head: {
                backgroundColor: `${Colors.DARK_BLUE} !important`,
                color: Colors.WHITE,
                textTransform: "capitalize"
            }
        },
        MUIDataTableHeadCell: {
            data: {
                color: Colors.WHITE,
                textTransform: "capitalize"
            }
        },
        MuiTableSortLabel: {
            root: {
                color: `${Colors.WHITE} !important`
            }
        },
        MUIDataTableBodyRow: {
            root: {
                "&:nth-child(even)": {
                    backgroundColor: Colors.LIGHT_GREY
                }
            }
        }
    }
});

const DynamicReportTable = () => {
    const {
        loading,
        dynamicReport: { data }
    } = useSelector(state => state.report);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ReportActions.fetchDynamicReportData());
    }, [dispatch]);

    const addDynamicReportTab = useCallback(
        selectedReport => {
            dispatch(ReportActions.addDynamicReportTab(selectedReport));
        },
        [dispatch],
    );

    const customHeadRender = (columnMeta, handleToggleColumn, sort) => {
        const props = { columnMeta, handleToggleColumn, sort };
        return <CustomDataTableHead key={columnMeta.name} {...props} />;
    };

    const sortCompareDate = order => {
        return (obj1, obj2) => {
            const val1 = new Date(obj1.data).valueOf();
            const val2 = new Date(obj2.data).valueOf();
            return (val1 - val2) * (order === "asc" ? 1 : -1);
        };
    };

    const columns = useMemo(() => {
        return [
            { label: "Report Param", name: "main", options: { filter: false, display: false } },
            {
                name: "no",
                label: "No",
                options: {
                    filter: false,
                    sort: true,
                    customHeadRender
                }
            },
            {
                label: "Report Name",
                name: "name",
                options: {
                    filter: false,
                    customHeadRender,
                    customBodyRender: (value, tableMeta) => {
                        // eslint-disable-next-line no-unused-vars
                        const [main, _, name] = tableMeta.rowData;
                        return (
                            <Button onClick={() => addDynamicReportTab({ main, name })}>
                                {value}
                            </Button>
                        );
                    }
                }
            },
            {
                label: "Created Date",
                name: "createdAt",
                options: {
                    filter: false,
                    customHeadRender,
                    customBodyRender: value => {
                        return moment(value).format("YYYY-MM-DD hh:mm A");
                    },
                    sortCompare: sortCompareDate
                }
            },
            {
                label: "Updated Date",
                name: "updatedAt",
                options: {
                    filter: false,
                    customHeadRender,
                    customBodyRender: value => {
                        return moment(value).format("YYYY-MM-DD hh:mm A");
                    },
                    sortCompare: sortCompareDate
                }
            },
            { label: "Status", name: "status", options: { filter: false, customHeadRender } },
            { label: "Report Description", name: "description", options: { filter: false } }
        ];
    }, [addDynamicReportTab]);

    const options = {
        selectableRows: "none",
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: null,
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress size={24} />
                ) : (
                    "Sorry, there is no matching data to display"
                )
            }
        },
        customFooter: () => {
            return <></>;
        }
    };

    return (
        <MuiThemeProvider theme={dynamicReportTheme}>
            <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
    );
};

export default DynamicReportTable;
