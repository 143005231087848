/* eslint-disable max-len */
import React, { useState, useEffect, memo } from "react";
import { TextField } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { VERBIAGE } from "../../../utils/enums/index";
import Colors from "../../../utils/Colors";

const TextInput = memo(props => {
    const {
        type,
        value,
        onChange,
        placeholder,
        id,
        multiline,
        live,
        disabled,
        styles,
        rows,
        error,
        helperText,
        defaultValue,
        required,
        InputProps,
        inputProps,
        noInputLabel
    } = props;

    // Changed TextInput to maintain its own state, and update parent component
    // state onBlur to improve typing performance
    const [input, setInput] = useState({
        target: {
            value: ""
        }
    });

    const handleChange = event => {
        setInput({
            target: {
                value: event.target.value
            }
        });
    };

    const handleLiveChange = event => {
        const newInput = {
            target: {
                value: event.target.value
            }
        };
        setInput(newInput);
        onChange(newInput);
    };

    useEffect(() => {
        setInput({
            target: {
                value
            }
        });
    }, [value]);

    // Override border color of input
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                },
                input: {
                    color: theme.palette.type === "dark" ? "#ffff" : "black",
                    "&:disabled": {
                        backgroundColor: Colors.MED_GREY
                    }
                }
            },
            MuiInputLabel: {
                outlined: {
                    color: theme.palette.type === "dark" ? "#ffff" : "rgba(0, 0, 0, 0.54)"
                }
            }
        }
    });

    const textInputProps = { inputProps };
    if (noInputLabel) {
        textInputProps.placeholder = placeholder;
        textInputProps.InputLabelProps = { shrink: false };
    } else {
        textInputProps.label = placeholder;
    }

    return live ? (
        <MuiThemeProvider theme={themeOverride}>
            <TextField
              name="input"
              id={id}
              type={type}
              value={input.target.value}
              onChange={handleLiveChange}
              variant="outlined"
              fullWidth
              multiline={multiline}
              rowsMax={rows}
              disabled={disabled}
              error={error}
              helperText={helperText}
              defaultValue={defaultValue}
              required={required}
              {...textInputProps}
            />
        </MuiThemeProvider>
    ) : (
        <MuiThemeProvider theme={themeOverride}>
            <TextField
              name="input"
              id={id}
              type={type}
              value={input.target.value}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              multiline={multiline}
              rowsMax={rows}
              onBlur={() => onChange(input)}
              disabled={disabled}
              InputProps={InputProps}
              helperText={helperText}
              defaultValue={defaultValue}
              required={required}
              error={error}
              style={styles}
              {...textInputProps}
            />
        </MuiThemeProvider>
    );
});

export default TextInput;
