import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
// import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
// import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
// import MomentAdapter from "@date-io/moment";
// import { createMuiTheme, useTheme } from "@material-ui/core/styles";
import "../styles/mapTable.scss";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

const EditMissingDataMailerTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    // const [data, setData] = useState([]); // eslint-disable-line no-unused-vars
    const [error, setError] = useState("");

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        gap1Threshold: VERBIAGE.TEXT.EMPTY_STRING,
        gap1Limit: 1, // VERBIAGE.TEXT.EMPTY_STRING,
        gap1Count: 0, // VERBIAGE.TEXT.EMPTY_STRING,
        gap2Threshold: VERBIAGE.TEXT.EMPTY_STRING,
        gap2Limit: 1, // VERBIAGE.TEXT.EMPTY_STRING,
        gap2Count: 0, // VERBIAGE.TEXT.EMPTY_STRING,
        gap3Threshold: VERBIAGE.TEXT.EMPTY_STRING,
        gap3Limit: 1, // VERBIAGE.TEXT.EMPTY_STRING,
        gap3Count: 0, // VERBIAGE.TEXT.EMPTY_STRING,
        lastProcessedData: Date.now(),
        updated: VERBIAGE.TEXT.EMPTY_STRING,
        status: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);
    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleSite = ({ target: { value } }) => {
        updateConfig({ ...config, siteId: value });
    };

    const handleThreshold1 = ({ target: { value } }) => {
        updateConfig({ ...config, gap1Threshold: value });
    };

    const handleThreshold2 = ({ target: { value } }) => {
        updateConfig({ ...config, gap2Threshold: value });
    };

    const handleThreshold3 = ({ target: { value } }) => {
        updateConfig({ ...config, gap3Threshold: value });
    };

    const handleStatus = ({ target: { value } }) => {
        updateConfig({ ...config, status: value });
    };

    const handleSubmit = async () => {
        if (
            !config.siteId ||
            !config.gap1Threshold ||
            !config.gap2Threshold ||
            !config.gap3Threshold
        ) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        if (isEdit) {
            const _data = {
                siteId: config.siteId,
                gap1Threshold: config.gap1Threshold,
                gap2Threshold: config.gap2Threshold,
                gap3Threshold: config.gap3Threshold,
                status: config.status
            };
            const url = `${URL.DATA.MAINTENANCE_SETTINGS.MISSING_DATA_MAILER}/${config.siteId}`;
            try {
                const response = await api.put(url, _data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        } else {
            config.lastProcessedData = Date.now();
            const _data = {
                ...config
            };
            const url = URL.DATA.MAINTENANCE_SETTINGS.MISSING_DATA_MAILER;
            try {
                const response = await api.post(url, _data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${
                    isEdit ? "edit" : "add to"
                } the Missing Data Mailer, please enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <div className={EditMissingDataMailerTable.styles.inputContainer}>
                    <TextInput
                      onChange={handleSite}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
                      value={config.siteId}
                      type="text"
                      styles={{ readOnly: !!isEdit }}
                    />
                </div>
                <div className={EditMissingDataMailerTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_THRESHOLD_1}
                      onChange={handleThreshold1}
                      value={config.gap1Threshold}
                      type="text"
                    />
                </div>
                <div className={EditMissingDataMailerTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_THRESHOLD_2}
                      onChange={handleThreshold2}
                      value={config.gap2Threshold}
                      type="text"
                    />
                </div>
                <div className={EditMissingDataMailerTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_THRESHOLD_3}
                      onChange={handleThreshold3}
                      value={config.gap3Threshold}
                      type="text"
                    />
                </div>
                <div className={EditMissingDataMailerTable.styles.inputContainer}>
                    <DropdownSelect
                      name={config.status}
                      options={VERBIAGE.TEXT.STATUS_OPTIONS}
                      onChange={handleStatus}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_STATUS}
                    />
                </div>
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setOpen(false);
                        setError("");
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditMissingDataMailerTable.styles = {
    inputContainer: "webclient__mapTable-input"
};

export default EditMissingDataMailerTable;
