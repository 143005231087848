import { useSelector } from "react-redux";

const useMLConfig = () => {
    const { LOCKING_DURATION, POOLING_INTERVAL_LOCKING, POOLING_INTERVAL_MAIN } = useSelector(
        state => state.ML.config,
    );

    return {
        cloudLockingDurationInSeconds: LOCKING_DURATION * 1000,
        scadaPoolingIntervalInSeconds: POOLING_INTERVAL_LOCKING * 1000,
        mainPoolingIntervalInSeconds: POOLING_INTERVAL_MAIN * 1000
    };
};

export default useMLConfig;
