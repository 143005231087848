/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Switch,
    Typography,
    Grid
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";

import "../styles/mapTable.scss";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import DropdownSelectGroupings from "./DropdownSelectGroupings";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

const SiteValidationSchema = Yup.object().shape({
    siteId: Yup.string()
        .label("Site ID")
        .required(),
    siteName: Yup.string()
        .label("Site Name")
        .required(),
    bbpId: Yup.string()
        .label("BBP ID")
        .required(),
    address: Yup.string()
        .label("Address")
        .required(),
    longitude: Yup.string()
        .label("Longitude")
        .required(),
    latitude: Yup.string()
        .label("Latitude")
        .required(),
    mapName: Yup.string()
        .label("Map")
        .required(),
    mainLogo: Yup.string()
        .label("Main Logo")
        .required(),
    circleLogo: Yup.string()
        .label("Circle Logo")
        .required(),
    numChillers: Yup.number()
        .label("Number of Chillers")
        .integer()
        .min(0)
        .required(),
    numCoolingTowers: Yup.number()
        .label("Number of Cooling Towers")
        .integer()
        .min(0)
        .required(),
    numCDPumps: Yup.number()
        .label("Number of Condenser Pumps")
        .integer()
        .min(0)
        .required(),
    numCHPumps: Yup.number()
        .label("Number of Chiller Pumps")
        .integer()
        .min(0)
        .required(),
    numSCHPumps: Yup.number()
        .label("Number of Secondary Chiller Pumps")
        .integer()
        .min(0)
        .required(),
    siteInCharge: Yup.array(Yup.string().required())
        .label("Engineer in Charge")
        .min(1),
    siteManager: Yup.array(Yup.string().required())
        .label("Manager in Charge")
        .min(1)
});

const EditSiteTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, siteList } = props;

    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);

    const [mapOptions, setMapOptions] = useState([]);
    const [mapList, setMapList] = useState([]);
    const [siteIdList, setSiteIdList] = useState([]);
    const [inChargeOption, setInChargeOption] = useState([]);
    const [siteStatusOptions, setSiteStatusOptions] = useState([]);

    const isMounted = useRef(false);

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        siteName: VERBIAGE.TEXT.EMPTY_STRING,
        address: VERBIAGE.TEXT.EMPTY_STRING,
        longitude: VERBIAGE.TEXT.EMPTY_STRING,
        latitude: VERBIAGE.TEXT.EMPTY_STRING,
        mapName: VERBIAGE.TEXT.EMPTY_STRING,
        mainLogo: VERBIAGE.TEXT.EMPTY_STRING,
        circleLogo: VERBIAGE.TEXT.EMPTY_STRING,
        bigLogo: VERBIAGE.TEXT.EMPTY_STRING,
        smallLogo: VERBIAGE.TEXT.EMPTY_STRING,
        mainNightLogo: VERBIAGE.TEXT.EMPTY_STRING,
        circleNightLogo: VERBIAGE.TEXT.EMPTY_STRING,
        bigNightLogo: VERBIAGE.TEXT.EMPTY_STRING,
        smallNightLogo: VERBIAGE.TEXT.EMPTY_STRING,
        numChillers: 0,
        numCoolingTowers: 0,
        numCHPumps: 0,
        numSCHPumps: 0,
        numCDPumps: 0,
        refreshRate: 1,
        isLiveData: true, // VERBIAGE.TEXT.EMPTY_STRING,
        defaultDate: Date.now(), // VERBIAGE.TEXT.EMPTY_STRING,
        isSchematicsDeveloped: false, // VERBIAGE.TEXT.EMPTY_STRING,
        isAnalyticsDeveloped: false, // VERBIAGE.TEXT.EMPTY_STRING
        isLifecycleCTDeveloped: false,
        isLifecycleCHWPDeveloped: false,
        isLifecycleCDWPDeveloped: false,
        isOptimizationDeveloped: false,
        isReportsDeveloped: false,
        isAnalyticsBeingDeveloped: false,
        isAutoMLActive: false,
        isGreenMarkCertified: false,
        isWaterSavingsActive: true,
        oneMinTableName: VERBIAGE.TEXT.EMPTY_STRING,
        sixtyMinTableName: VERBIAGE.TEXT.EMPTY_STRING,
        faultEventTableName: VERBIAGE.TEXT.EMPTY_STRING,
        paramConfigTableName: VERBIAGE.TEXT.EMPTY_STRING,
        faultDiagnosisTableName: VERBIAGE.TEXT.EMPTY_STRING,
        faultCodeListTableName: VERBIAGE.TEXT.EMPTY_STRING,
        optimizationOutputTableName: VERBIAGE.TEXT.EMPTY_STRING,
        chillerPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        coolingTowerPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        CDPumpsPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        CHPumpsPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        SCHPumpsPrefix: VERBIAGE.TEXT.EMPTY_STRING,
        bbpId: VERBIAGE.TEXT.EMPTY_STRING,
        siteInCharge: [],
        siteManager: [],
        siteStatus: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, setConfig] = useState(emptyConfig);

    const {
        control,
        setValue,
        setError: setFormError,
        getValues,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(SiteValidationSchema),
        values: config
    });

    useImperativeHandle(ref, () => ({
        update(data) {
            if (isEdit) {
                let mapName;
                mapList.forEach(map => {
                    if (map.mapId === data.mapId) {
                        mapName = map.mapName;
                    }
                });
                setConfig(currConfig => ({
                    ...currConfig,
                    ...data,
                    latitude: data.location.latitude,
                    longitude: data.location.longitude,
                    mapName
                }));
            } else {
                setConfig(currConfig => ({
                    ...currConfig,
                    ...data
                }));
            }
        },
        getConfig() {
            return config;
        }
    }));

    const handleMap = field => {
        return e => {
            const mapValue = e.target.value;
            const map = mapList.find(m => m.mapName === mapValue);
            if (map) {
                setValue("latitude", map.latitude, { shouldValidate: true });
                setValue("longitude", map.longitude, { shouldValidate: true });
            }
            return field.onChange(mapValue);
        };
    };

    const setLocalStorage = (key, value) => {
        const formSiteId = getValues("siteId");
        if (config.siteId === formSiteId) {
            localStorage.setItem(key, value);
        }
    };

    const toggleSwitch = ({ name, onChange }) => {
        return (_, val) => {
            setLocalStorage(name, val);
            return onChange(val);
        };
    };

    const handleTime = ({ name, onChange }) => {
        return (moment, val) => {
            setLocalStorage(name, moment.valueOf());
            return onChange(val);
        };
    };

    const handleClose = () => {
        reset(config);
        setError(null);
        setOpen(false);
    };

    const isFieldExist = field => {
        return config[field] !== undefined;
    };

    const isFieldRequired = field => {
        if (!isFieldExist(field)) return false;
        return SiteValidationSchema.fields[field]?.exclusiveTests?.required !== undefined ?? false;
    };

    const scrollIntoFirstError = errs => {
        if (isEmpty(errs)) return;
        const elements = Object.keys(errs)
            .map(name => document.getElementById(name))
            .filter(el => !!el);
        elements.sort((a, b) => b.scrollHeight - a.scrollHeight);
        // eslint-disable-next-line no-unused-expressions
        elements[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    const mappingErrorFields = {
        main: "mainLogo",
        circle: "circleLogo",
        big: "bigLogo",
        small: "smallLogo",
        "nightMode.main": "mainNightLogo",
        "nightMode.circle": "circleNightLogo",
        "nightMode.big": "bigNightLogo",
        "nightMode.small": "smallNightLogo",
        mapId: "mapName"
    };

    const handleSubmitError = err => {
        switch (err?.response?.status) {
            case 401:
            case 403:
                localStorage.clear();
                window.location.href = "/";
                break;
            default:
        }
        const { details } = err?.response?.data || {};
        if (typeof details === "string") {
            // eslint-disable-next-line no-unused-vars
            const [_, errStrings] = details.split("-");
            try {
                const invalidFields = JSON.parse(errStrings.trim());
                const errs = {};
                invalidFields.forEach(path => {
                    const field = mappingErrorFields[path] ?? path;
                    setFormError(field, {
                        type: "invalid",
                        message: `This field value is invalid`
                    });
                    errs[field] = true;
                });
                setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
                scrollIntoFirstError(errs);
                return;
            } catch (e) {
                // setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            }
        } else if (details?.errors) {
            const errs = {};
            Object.keys(details.errors).forEach(path => {
                const message = details.errors[path].message.replace(path, "This field");
                const field = mappingErrorFields[path] ?? path;
                if (isFieldRequired(field)) {
                    setFormError(field, { type: "invalid", message });
                    errs[field] = true;
                }
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstError(errs);
            return;
        }
        setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
    };

    const onSubmit = async data => {
        // set mapId
        let mapId;
        mapList.forEach(map => {
            if (map.mapName === data.mapName) {
                mapId = map.mapId;
            }
        });
        const siteData = {
            ...data,
            mapId
        };
        if (isEdit) {
            const url = `${URL.DATA.PLANT_SETTINGS.SITES}/${config.siteId}`;
            try {
                setLoading(true);
                await api.put(url, siteData, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } catch (err) {
                handleSubmitError(err);
                return;
            } finally {
                setLoading(false);
            }
        } else {
            if (siteIdList.includes(siteData.siteId)) {
                setError(VERBIAGE.ERROR_TEXT.SITE_EXISTS);
                return;
            }
            const url = URL.DATA.PLANT_SETTINGS.SITES;
            try {
                setLoading(true);
                await api.post(url, siteData, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } catch (err) {
                handleSubmitError(err);
                return;
            } finally {
                setLoading(false);
            }
        }
        handleClose();
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    useEffect(() => {
        scrollIntoFirstError(errors);
    }, [errors]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const requestOptions = {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                };
                const [{ data: mapData }, { data: siteStatusData }] = await Promise.all([
                    api.get(URL.DATA.PLANT_SETTINGS.MAP, requestOptions),
                    api.get(URL.DATA.PLANT_SETTINGS.STATUS, requestOptions)
                ]);

                const options = [];
                mapData.forEach(map => {
                    options.push(map.mapName);
                });
                setMapOptions(options);
                setMapList(mapData);

                setSiteStatusOptions(siteStatusData);
                if (!isEdit) {
                    setValue("siteStatus", siteStatusData[0]);
                }
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        // only call fetchData when open state changes or about to add/edit
        fetchData(); // this will be called also when window is closing
    }, [open, isEdit, setValue]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await api.get(URL.DATA.PLANT_SETTINGS.INCHARGE, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });

                const option = [];
                data.forEach(incharge => {
                    option.push({
                        userId: incharge.userId,
                        publicName: incharge.publicName,
                        email: incharge.email,
                        permission: incharge.permission
                    });
                });
                setInChargeOption(option);

                const sites = [];
                siteList.forEach(site => {
                    sites.push(site.siteId);
                });
                setSiteIdList(sites);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();

        return () => (isMounted.current = false);
    }, [siteList]);

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            // wordWrap: "break-word",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    // Override border color of datetime picker
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            }
        }
    });

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} the sites list, please enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Controller
                          name="siteId"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
                                  onChange={onChange}
                                  value={value}
                                  error={!!errors.siteId}
                                  helperText={errors.siteId?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="siteName"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_NAME}
                                  onChange={onChange}
                                  value={value}
                                  error={!!errors.siteName}
                                  helperText={errors.siteName?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="bbpId"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_BBP_ID}
                                  onChange={onChange}
                                  value={value}
                                  error={!!errors.bbpId}
                                  helperText={errors.bbpId?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="refreshRate"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_REFRESH_RATE}
                                  error={!!errors.refreshRate}
                                  helperText={errors.refreshRate?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="numChillers"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_NUM_CHILLERS}
                                  onChange={onChange}
                                  value={value}
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  error={!!errors.numChillers}
                                  helperText={errors.numChillers?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="chillerPrefix"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CHILLERS_PREFIX}
                                  error={!!errors.chillerPrefix}
                                  helperText={errors.chillerPrefix?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="numCoolingTowers"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_NUM_CT}
                                  onChange={onChange}
                                  value={value}
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  error={!!errors.numCoolingTowers}
                                  helperText={errors.numCoolingTowers?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="coolingTowerPrefix"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CT_PREFIX}
                                  error={!!errors.coolingTowerPrefix}
                                  helperText={errors.coolingTowerPrefix?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="numCHPumps"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  type="number"
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_NUM_CHPUMPS}
                                  onChange={onChange}
                                  value={value}
                                  InputProps={{ inputProps: { min: 0 } }}
                                  error={!!errors.numCHPumps}
                                  helperText={errors.numCHPumps?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="CHPumpsPrefix"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CT_PREFIX}
                                  error={!!errors.CHPumpsPrefix}
                                  helperText={errors.CHPumpsPrefix?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="numSCHPumps"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_NUM_SCHPUMPS}
                                  onChange={onChange}
                                  value={value}
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  error={!!errors.numSCHPumps}
                                  helperText={errors.numSCHPumps?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="SCHPumpsPrefix"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SCHPUMPS_PREFIX}
                                  error={!!errors.SCHPumpsPrefix}
                                  helperText={errors.SCHPumpsPrefix?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="numCDPumps"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  type="number"
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_NUM_CDPUMPS}
                                  onChange={onChange}
                                  value={value}
                                  InputProps={{ inputProps: { min: 0 } }}
                                  error={!!errors.numCDPumps}
                                  helperText={errors.numCDPumps?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="CDPumpsPrefix"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CDPUMPS_PREFIX}
                                  error={!!errors.CDPumpsPrefix}
                                  helperText={errors.CDPumpsPrefix?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="mainLogo"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MAIN_LOGO}
                                  error={!!errors.mainLogo}
                                  helperText={errors.mainLogo?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="circleLogo"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CIRCLE_LOGO}
                                  error={!!errors.circleLogo}
                                  helperText={errors.circleLogo?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="bigLogo"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_BIG_LOGO}
                                  error={!!errors.bigLogo}
                                  helperText={errors.bigLogo?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="smallLogo"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SMALL_LOGO}
                                  error={!!errors.smallLogo}
                                  helperText={errors.smallLogo?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="mainNightLogo"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MAIN_NIGHT_LOGO}
                                  error={!!errors.mainNightLogo}
                                  helperText={errors.mainNightLogo?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="circleNightLogo"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CIRCLE_NIGHT_LOGO}
                                  error={!!errors.circleNightLogo}
                                  helperText={errors.circleNightLogo?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="bigNightLogo"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_BIG_NIGHT_LOGO}
                                  error={!!errors.bigNightLogo}
                                  helperText={errors.bigNightLogo?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="smallNightLogo"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SMALL_NIGHT_LOGO}
                                  error={!!errors.smallNightLogo}
                                  helperText={errors.smallNightLogo?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="address"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  value={value}
                                  onChange={onChange}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_ADDRESS}
                                  error={!!errors.address}
                                  helperText={errors.address?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="longitude"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LONG}
                                  onChange={onChange}
                                  value={value}
                                  error={!!errors.longitude}
                                  helperText={errors.longitude?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="latitude"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LAT}
                                  onChange={onChange}
                                  value={value}
                                  error={!!errors.latitude}
                                  helperText={errors.latitude?.message}
                                  required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="mapName"
                          control={control}
                          render={({ field }) => (
                                <DropdownSelect
                                  id={field.name}
                                  name={field.value}
                                  onChange={handleMap(field)}
                                  options={mapOptions}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_MAP}
                                  error={!!errors.mapName}
                                  helperText={errors.mapName?.message}
                                  closeMenuOnSelect
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="siteStatus"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <DropdownSelect
                                  id={name}
                                  name={value}
                                  onChange={onChange}
                                  options={siteStatusOptions}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_SITE_STATUS}
                                  isMulti={false}
                                  closeMenuOnSelect
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                  component="label"
                  container
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                    <Grid item xs={2}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_LIVE_BOOLEAN}
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                          name="isLiveData"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        {VERBIAGE.PLACEHOLDER_TEXT.EMPTY_STRING}
                    </Grid>
                </Grid>
                <div className={EditSiteTable.styles.inputContainer}>
                    <MuiThemeProvider theme={themeOverride}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Controller
                              name="defaultDate"
                              control={control}
                              render={({ field }) => (
                                    <KeyboardDateTimePicker
                                      id={field.name}
                                      value={field.value}
                                      onChange={handleTime(field)}
                                      variant="dialog"
                                      label={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TIME}
                                      inputVariant="outlined"
                                      format="DD/MM/YYYY HH:mm"
                                      fullWidth
                                    />
                                )}
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </div>
                <Grid
                  component="label"
                  container
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_REPORTS_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isReportsDeveloped"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_GREENMARK_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isGreenMarkCertified"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                  component="label"
                  container
                  justifyContent="space-around"
                  alignItems="center"
                  spacing={1}
                >
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_WATER_SAVINGS_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isWaterSavingsActive"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} />
                </Grid>
                <Grid
                  component="label"
                  container
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_SCHEMATICS_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isSchematicsDeveloped"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_ANALYTICS_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isAnalyticsDeveloped"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                  component="label"
                  container
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_LC_CT_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isLifecycleCTDeveloped"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_LC_CHWP_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isLifecycleCHWPDeveloped"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                  component="label"
                  container
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_LC_CDWP_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isLifecycleCDWPDeveloped"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_OPTIMIZATION_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isOptimizationDeveloped"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid
                  component="label"
                  container
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                >
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_DEVELOPER_ANALYTICS_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isAnalyticsBeingDeveloped"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {VERBIAGE.PLACEHOLDER_TEXT.SELECT_AUTO_ML_BOOLEAN}
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                          name="isAutoMLActive"
                          control={control}
                          render={({ field }) => (
                                <Switch
                                  id={field.name}
                                  checked={field.value}
                                  onChange={toggleSwitch(field)}
                                  classes={{ root: classes.root }}
                                  color="primary"
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Controller
                          name="siteInCharge"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <DropdownSelectGroupings
                                  id={name}
                                  options={inChargeOption}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_ENG_INCHARGE}
                                  onChange={onChange}
                                  name={value}
                                  error={!!errors.siteInCharge}
                                  helperText={errors.siteInCharge?.message}
                                  isMulti
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                          name="siteManager"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <DropdownSelectGroupings
                                  id={name}
                                  options={inChargeOption}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_MGR_INCHARGE}
                                  onChange={onChange}
                                  name={value}
                                  error={!!errors.siteManager}
                                  helperText={errors.siteManager?.message}
                                  isMulti
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="oneMinTableName"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TABLE_ONE_MINUTE}
                                  error={!!errors.oneMinTableName}
                                  helperText={errors.oneMinTableName?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="sixtyMinTableName"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TABLE_SIXTY_MINUTE}
                                  error={!!errors.sixtyMinTableName}
                                  helperText={errors.sixtyMinTableName?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="faultEventTableName"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TABLE_FAULT_EVENT}
                                  error={!!errors.faultEventTableName}
                                  helperText={errors.faultEventTableName?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="paramConfigTableName"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TABLE_PARAM_CONFIG}
                                  error={!!errors.paramConfigTableName}
                                  helperText={errors.paramConfigTableName?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="faultDiagnosisTableName"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={
                                        VERBIAGE.PLACEHOLDER_TEXT.ENTER_TABLE_FAULT_DIAGNOSIS
                                    }
                                  error={!!errors.faultDiagnosisTableName}
                                  helperText={errors.faultDiagnosisTableName?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="faultCodeListTableName"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={
                                        VERBIAGE.PLACEHOLDER_TEXT.ENTER_TABLE_FAULT_CODE_LIST
                                    }
                                  error={!!errors.faultCodeListTableName}
                                  helperText={errors.faultCodeListTableName?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                          name="optimizationOutputTableName"
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                                <TextInput
                                  id={name}
                                  onChange={onChange}
                                  value={value}
                                  placeholder={
                                        VERBIAGE.PLACEHOLDER_TEXT.ENTER_TABLE_OPTIMIZATION_OUTPUT
                                    }
                                  error={!!errors.optimizationOutputTableName}
                                  helperText={errors.optimizationOutputTableName?.message}
                                />
                            )}
                        />
                    </Grid>
                    {!!error && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography variant="subtitle1">{error}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                  color="primary"
                  onClick={handleSubmit(onSubmit, onError)}
                  disabled={loading}
                >
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={handleClose} disabled={loading}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditSiteTable.styles = {
    inputContainer: "webclient__mapTable-fixed"
};

export default EditSiteTable;
