/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import URL from "../../../utils/URL";
import DateRangeSlider from "./DateRangeSlider";
import ReportTableauChart from "../charts/ReportTableauChart";

const MobileDetect = require("mobile-detect");

const md = new MobileDetect(window.navigator.userAgent);
const ADJUST_BEFORE_DAYS = 30 * 24 * 60 * 60 * 1000;

const ReportView = props => {
    const { home } = props;
    const useStyles = makeStyles(() => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : "unset"
        },
        grid: {
            fontSize: "x-large",
            fontWeight: "500"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box"
        }
    }));
    const classes = useStyles();
    const [worksheets, setWorksheets] = useState([]);
    // DateRangeSlider data
    let lastUpdate = parseInt(localStorage.getItem("lastUpdate"), 10);
    const date = new Date(lastUpdate);
    const [max, setMax] = useState(date.getTime());
    const [min, setMin] = useState(
        date.setHours(date.getHours() - date.getHours(), 0, 0, 0) - ADJUST_BEFORE_DAYS,
    );
    //  const min = date.setFullYear(date.getFullYear() - 1);
    const [dates, setDates] = useState([min, max]);

    const initialFilter = ws => {
        lastUpdate = parseInt(localStorage.getItem("lastUpdate"), 10);
        const endDate = new Date(lastUpdate);
        const startDate = new Date(lastUpdate);
        ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(endDate));
        startDate.setHours(0, 0, 0, 0);
        ws.getWorkbook().changeParameterValueAsync(
            "WEBAPP_REFSTART",
            new Date(startDate.getTime() - ADJUST_BEFORE_DAYS),
        );
        const newValue = [startDate.getTime() - ADJUST_BEFORE_DAYS, endDate.getTime()];
        setDates(newValue);
        // onSliderChangeCommitted(null, newValue);
    };

    // DateRangeSlider methods
    const handleSliderChange = (event, newValue) => {
        setDates(newValue);
    };
    // Change datetime filter on worksheets only after slide change is committed
    const onSliderChangeCommitted = (event, newValue) => {
        const [start, end] = newValue;
        let startDate = new Date(start);
        let endDate = new Date(end);
        // adjust the time for GMT +8
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59);
        startDate = startDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000;
        endDate = endDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000;
        worksheets.forEach(ws => {
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(startDate));
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(endDate));
            //     ws.applyRangeFilterAsync(
            //     "SYS_TS_DATETIME",
            //     {
            //         min: new Date(startDate),
            //         max: new Date(endDate)
            //     },
            //     "replace",
            // );
        });
    };
    const handleStartDateInput = event => {
        if (event._isValid) {
            const newTime = new Date(event._d).getTime();
            setDates(prev => {
                const newValue = [newTime, prev[1]];
                onSliderChangeCommitted(null, newValue);
                setMin(newTime);
                return newValue;
            });
        }
    };
    const handleEndDateInput = event => {
        if (event._isValid) {
            const endDate = new Date(event._d);
            endDate.setHours(23, 59, 59);
            setDates(prev => {
                const newValue = [prev[0], endDate.getTime()];
                onSliderChangeCommitted(null, newValue);
                setMax(endDate);
                return newValue;
            });
        }
    };
    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };
    useEffect(() => {
        if (worksheets.length >= 1) {
            onSliderChangeCommitted(null, dates);
            handleSliderChange(null, dates);
        }
    }, [dates, onSliderChangeCommitted, handleSliderChange, worksheets]);
    return (
        <>
            <Grid className={classes.grid} item container justifyContent="space-between">
                <span>Site Reports</span>
                {/* <DateRangeSlider
                  dates={dates}
                  max={max}
                  min={min}
                  format="DD/MM/YYYY"
                  handleDates={handleSliderChange}
                  onSliderChangeCommitted={onSliderChangeCommitted}
                  handleStartInput={handleStartDateInput}
                  handleEndInput={handleEndDateInput}
                  id="reports"
                /> */}
            </Grid>
            <Grid item container>
                <ReportTableauChart
                  url={URL.DATA.REPORT.CHART}
                  addWorksheet={addWorksheet}
                  report
                  fullWidth
                  initialFilter={initialFilter}
                />
            </Grid>
        </>
    );
};
const mapStateToProps = state => ({
    home: state.home
});
// export default ReportView;

export default connect(mapStateToProps)(ReportView);
