import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles
} from "@material-ui/core";
import "../styles/mapTable.scss";
// import TextInput from "./TextInput";
// import api from "../../../utils/API";
// import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { toIntlDateTimeFormat } from "../../../utils/helpers";

const ShowAuditLogDetails = forwardRef((props, ref) => {
    const { open, setOpen, handleClick } = props;

    // const [error, setError] = useState("");

    const emptyConfig = {
        timestamp: VERBIAGE.TEXT.EMPTY_STRING,
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        userId: VERBIAGE.TEXT.EMPTY_STRING,
        username: VERBIAGE.TEXT.EMPTY_STRING,
        permissions: VERBIAGE.TEXT.EMPTY_STRING,
        type: VERBIAGE.TEXT.EMPTY_STRING,
        action: VERBIAGE.TEXT.EMPTY_STRING,
        status: VERBIAGE.TEXT.EMPTY_STRING,
        oldData: VERBIAGE.TEXT.EMPTY_STRING,
        newData: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            // wordWrap: "break-word",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog
          open={open}
          onClose={handleClick}
          aria-labelledby="form-dialog-title"
          fullWidth="md"
          maxWidth="md"
        >
            <DialogTitle>AuditLogs details</DialogTitle>

            <DialogContent>
                <div className={ShowAuditLogDetails.styles.text}>
                    Date / Time: 
{' '}
{toIntlDateTimeFormat(config.timestamp)}
                </div>
                <div className={ShowAuditLogDetails.styles.text}>
                    Site ID:
                    {config.siteId}
                </div>
                <div className={ShowAuditLogDetails.styles.text}>
                    User:
                    {config.username}
                </div>
                <div className={ShowAuditLogDetails.styles.text}>
                    Command Type:
                    {config.type}
                </div>
                <div className={ShowAuditLogDetails.styles.text}>
                    Action:
                    {config.action}
                </div>
                <div className={ShowAuditLogDetails.styles.text}>
                    Status:
                    {config.status}
                </div>
                <div className={ShowAuditLogDetails.styles.text}>
                    Description: 
{' '}
{config.description}
                </div>
                <br />
                <table style={{ width: "100%" }}>
                    <tr style={{ minwidth: "200px" }}>
                        <th style={{ minwidth: "200px", verticalAlign: "top" }}>Previous Data</th>
                        <th style={{ minwidth: "200px" }}>New Data</th>
                    </tr>
                    <tr>
                        <td style={{ minwidth: "300px", width: "300px", verticalAlign: "top" }}>
                            <pre style={{ minwidth: "200px" }} id="json">
                                {config.oldData}
                            </pre>
                        </td>
                        <td style={{ minwidth: "200px", width: "300px", verticalAlign: "top" }}>
                            <pre style={{ minwidth: "200px" }} id="json">
                                {config.newData}
                            </pre>
                        </td>
                    </tr>
                </table>
            </DialogContent>
            <DialogActions>
                <Button className={classes.primary} onClick={() => setOpen(false)}>
                    {VERBIAGE.BUTTONS.EXIT}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

ShowAuditLogDetails.styles = {
    inputContainer: "webclient__auditLogDisplay-input",
    text: "webclient__auditLogDisplay-text",
    errorText: "webclient__mapTable-error-text"
};

export default ShowAuditLogDetails;
