import React from "react";
import { useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";

import AirflowConnections from "./AirflowConnections";
import SiteDag from "./SiteDag";

import { VERBIAGE } from "../../../utils/enums";
import MLSettings from "./MLSettings";

const MobileDetect = require("mobile-detect");

const md = new MobileDetect(window.navigator.userAgent);

const MLSettingsTabs = ({ tab }) => {
    const { desktopMobileSwitch } = useSelector(state => state.home);

    const useStyles = makeStyles(() => ({
        root: {
            flexGrow: 1,
            padding: "10px"
        },
        main: {
            transition: "margin-left 0.5s",
            height: desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box"
        }
    }));
    const classes = useStyles();

    const tabView = () => {
        switch (tab) {
            case VERBIAGE.ML_SETTINGS_TAB_VALUE.SITE_DAG:
                return <SiteDag />;
            case VERBIAGE.ML_SETTINGS_TAB_VALUE.AIRFLOW:
                return <AirflowConnections />;
            default:
                return <MLSettings />;
        }
    };

    return <Grid className={classNames(classes.root, classes.main)}>{tabView()}</Grid>;
};

export default MLSettingsTabs;
