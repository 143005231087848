import RuleBasedOptimizationCDWPActionTypes from "./RuleBasedOptimizationCDWPActionTypes";

const initialState = {
    config: {
        loading: false,
        error: null,
        data: {
            flowKWChange: {
                FLOW_CHANGE_PERC: 0,
                KW_CHANGE_PERC: 0
            },
            formula: {
                POWER_MAGNITUDE: 0
            },
            settings: {
                LOAD_BASED: 0,
                TIME_BASED: 0
            }
        },
        lastUpdated: null
    },
    minMaxLoad: {
        loading: false,
        error: null,
        data: [],
        lastUpdated: null
    },
    minMaxTime: {
        loading: false,
        error: null,
        data: [],
        lastUpdated: null
    }
};

const OptimizationCDWPReducer = (state = initialState, action) => {
    switch (action.type) {
        case RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                }
            };
        case RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_CONFIG_DATA:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload,
                    lastUpdated: new Date().getTime()
                }
            };
        case RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_LOAD:
            return {
                ...state,
                minMaxLoad: {
                    ...state.minMaxLoad,
                    ...action.payload
                }
            };
        case RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_LOAD_DATA:
            return {
                ...state,
                minMaxLoad: {
                    ...state.minMaxLoad,
                    ...action.payload,
                    lastUpdated: new Date().getTime()
                }
            };
        case RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_TIME:
            return {
                ...state,
                minMaxTime: {
                    ...state.minMaxTime,
                    ...action.payload
                }
            };
        case RuleBasedOptimizationCDWPActionTypes.SET_OPTIMIZATION_CDWP_MIN_MAX_TIME_DATA:
            return {
                ...state,
                minMaxTime: {
                    ...state.minMaxTime,
                    ...action.payload,
                    lastUpdated: new Date().getTime()
                }
            };
        default:
            return state;
    }
};

export default OptimizationCDWPReducer;
