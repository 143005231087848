/* eslint-disable import/no-cycle */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { VERBIAGE } from "../utils/enums/index";

import SystemHeader from "../dashboard/common/components/SystemHeader";
import DashboardContainer from "../dashboard/Dashboard";
import Home from "./pages/Home";
import SiteManagement from "./pages/SiteManagement";
import ScadaManagement from "./pages/ScadaManagement";
import ChillerSpecManagement from "./pages/ChillerSpecManagement";
import WaterPumpSpecManagement from "./pages/WaterPumpSpecManagement";
import PidManagement from "./pages/PidManagement";
import CoolingTowerManagement from "./pages/CoolingTowerManagement";
import DwhActions from "./redux/DwhActions";

const DataWarehouseContainer = () => {
    const { home, authentication } = useSelector(state => state);

    const isLogin = localStorage.getItem("isUserAuthenticated");
    if (isLogin === null) {
        window.location.href = "/";
    }
    if (window.innerWidth < 1000) {
        localStorage.setItem("open", false);
    } else {
        localStorage.setItem("open", true);
    }

    // dwh config
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(DwhActions.fetchConfig());
    }, [dispatch]);

    // const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(() => ({
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            paddingTop: "70px",
            boxSizing: "border-box"
        }
    }));

    const classes = useStyles();

    return (
        <Router>
            <Switch>
                <Route path="/dashboard" component={DashboardContainer} />
                <div className="main-wrapper">
                    <div>
                        <SystemHeader authentication={authentication} />
                    </div>
                    <div className={classes.main}>
                        <Route path={VERBIAGE.WAREHOUSE_PATH.HOME} component={Home} />
                        <Route
                          path={VERBIAGE.WAREHOUSE_PATH.SITE_MANAGEMENT}
                          component={SiteManagement}
                        />
                        <Route
                          path={VERBIAGE.WAREHOUSE_PATH.SCADA_MANAGEMENT}
                          component={ScadaManagement}
                        />
                        <Route
                          path={VERBIAGE.WAREHOUSE_PATH.CHILLER_SPEC_MANAGEMENT}
                          component={ChillerSpecManagement}
                        />
                        <Route
                          path={VERBIAGE.WAREHOUSE_PATH.WATER_PUMP_SPEC_MANAGEMENT}
                          component={WaterPumpSpecManagement}
                        />
                        <Route
                          path={VERBIAGE.WAREHOUSE_PATH.PID_MANAGEMENT}
                          component={PidManagement}
                        />
                        <Route
                          path={VERBIAGE.WAREHOUSE_PATH.COOLING_TOWER_MANAGEMENT}
                          component={CoolingTowerManagement}
                        />
                    </div>
                </div>
            </Switch>
        </Router>
    );
};

DataWarehouseContainer.styles = {
    content: "nos-ds__dashboard-content"
};

export default DataWarehouseContainer;
