import React, { useState, useRef, useMemo, useEffect, useCallback } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";

import MUIDataTable from "mui-datatables";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import * as Yup from "yup";

import RuleBasedOptimizationCTSetpointForm from "./RuleBasedOptimizationCTSetpointForm";
import useRuleBasedOptimizationCT from "./hooks/useRuleBasedOptimizationCT";

const HeatRejPercSchema = Yup.object().shape({
    HJ_DEV_FROM_PERC: Yup.number()
        .label("HJ_DEV_FROM_PERC")
        .required(),
    HJ_DEV_TO_PERC: Yup.number()
        .label("HJ_DEV_TO_PERC")
        .required()
});
const WBTSchema = Yup.object().shape({
    WBT_FROM: Yup.number()
        .label("WBT_FROM")
        .required(),
    WBT_TO: Yup.number()
        .label("WBT_TO")
        .required()
});

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        "& .MuiTableCell-root": {
            padding: theme.spacing(1)
        },
        "& .MuiTableCell-head": {
            textAlign: "center"
        },
        "& .MuiTableCell-body": {
            textAlign: "center"
        }
    }
}));
const RuleBasedOptimizationCTSetpoint = () => {
    const {
        ctSetpoint: { loading, data },
        fetchSetpoint,
        updateSetpoint,
        deletePercentage,
        deleteWBT
    } = useRuleBasedOptimizationCT();

    useEffect(() => {
        fetchSetpoint();
    }, [fetchSetpoint]);

    const WBTParams = useMemo(() => {
        const [firstRow] = data;
        const params = omit(firstRow, ["%", "HJ_DEV_FROM_PERC", "HJ_DEV_TO_PERC"]);
        return params;
    }, [data]);

    const [isEdit, setIsEdit] = useState(false);
    const percentageFormRef = useRef();
    const wbtFormRef = useRef();
    const [openPercentageForm, setOpenPercentageForm] = useState(false);
    const [openWBTForm, setOpenWBTForm] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(null);

    const handleOpenDialog = useCallback(
        (action, dataIndex) => {
            switch (action) {
                case "addPercentage":
                    percentageFormRef.current.add();
                    setIsEdit(false);
                    setOpenPercentageForm(true);
                    break;
                case "editPercentage":
                    {
                        const { HJ_DEV_FROM_PERC, HJ_DEV_TO_PERC } = data[dataIndex];
                        percentageFormRef.current.edit({ HJ_DEV_FROM_PERC, HJ_DEV_TO_PERC });
                        setIsEdit(true);
                        setOpenPercentageForm(true);
                    }
                    break;
                case "deletePercentage":
                    {
                        const { HJ_DEV_FROM_PERC, HJ_DEV_TO_PERC } = data[dataIndex];
                        setDeleteDialog({
                            title: `Delete ${HJ_DEV_FROM_PERC} to ${HJ_DEV_TO_PERC}%`,
                            param: "percentage",
                            payload: { HJ_DEV_FROM_PERC, HJ_DEV_TO_PERC }
                        });
                    }
                    break;
                case "addWBT":
                    wbtFormRef.current.add();
                    setIsEdit(false);
                    setOpenWBTForm(true);
                    break;
                case "editWBT":
                    {
                        const { WBT_FROM, WBT_TO } = WBTParams[dataIndex].data;
                        wbtFormRef.current.edit({ WBT_FROM, WBT_TO });
                        setIsEdit(true);
                        setOpenWBTForm(true);
                    }
                    break;
                case "deleteWBT":
                    {
                        const { WBT_FROM, WBT_TO } = WBTParams[dataIndex].data;
                        setDeleteDialog({
                            title: `Delete ${WBT_FROM} to ${WBT_TO}`,
                            param: "wbt",
                            payload: { WBT_FROM, WBT_TO }
                        });
                    }
                    break;
                default:
            }
        },
        [WBTParams, data],
    );

    const handleFormSuccess = () => {
        fetchSetpoint(true);
    };

    const [editCTSetpoint, setEditCTSetpoint] = useState(null);

    const handleProcessEditSetpoint = value => {
        const { dataIndex, wbt, setpoint } = editCTSetpoint;
        updateSetpoint({ id: setpoint.id, value, wbt, dataIndex });
        setEditCTSetpoint(null);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialog(null);
    };

    const handleProcessDelete = () => {
        const { param, payload } = deleteDialog;
        if (param === "percentage") {
            deletePercentage(payload);
        } else if (param === "wbt") {
            deleteWBT(payload);
        }
        handleCloseDeleteDialog();
    };

    const WBTColumns = useMemo(() => {
        return Object.keys(WBTParams).map(wbt => ({
            name: wbt,
            options: {
                filter: false,
                sort: false,
                customHeadRender: ({ index, name }) => {
                    return (
                        <TableCell key={index}>
                            <Chip
                              size="small"
                              label={name}
                              onClick={() => handleOpenDialog("editWBT", name)}
                              onDelete={() => handleOpenDialog("deleteWBT", name)}
                            />
                        </TableCell>
                    );
                },
                customBodyRenderLite: dataIndex => {
                    const row = data[dataIndex];
                    const hjDevPerc = row["%"];
                    const setpoint = row[wbt].data;
                    return (
                        <Button
                          variant="text"
                          size="small"
                          title="Edit Setpoint"
                          onClick={() => {
                                setEditCTSetpoint({
                                    dataIndex,
                                    hjDevPerc,
                                    wbt,
                                    setpoint
                                });
                            }}
                        >
                            <Typography>{setpoint?.value ?? "-"}</Typography>
                        </Button>
                    );
                }
            }
        }));
    }, [WBTParams, handleOpenDialog, data]);

    let columns = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: dataIndex => {
                    return (
                        <Grid container justifyContent="flex-end" spacing={1}>
                            <IconButton
                              title="Delete Heat Rejection Percentage"
                              onClick={() => handleOpenDialog("deletePercentage", dataIndex)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    );
                }
            }
        },
        {
            name: "%",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: dataIndex => {
                    const row = data[dataIndex];
                    const heatRejectionPerc = row["%"];
                    return (
                        <Button
                          color="primary"
                          variant="text"
                          size="small"
                          title="Edit Heat Rejection Percentage"
                          onClick={() => handleOpenDialog("editPercentage", dataIndex)}
                          style={{ textTransform: "none" }}
                        >
                            <Typography>{`${heatRejectionPerc}%`}</Typography>
                        </Button>
                    );
                }
            }
        },
        ...WBTColumns
    ];
    if (data.length === 0) {
        columns = [];
    }

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        filter: false,
        print: false,
        viewColumns: false,
        download: false,
        customToolbar: () => {
            return (
                <>
                    <Tooltip title="Refresh">
                        <IconButton onClick={() => fetchSetpoint(true)}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                    <Button
                      disableElevation
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => handleOpenDialog("addPercentage")}
                      startIcon={<AddIcon size="small" />}
                    >
                        Heat Rejection Percentage
                    </Button>
                    {data.length > 0 && (
                        <Button
                          disableElevation
                          color="default"
                          size="small"
                          variant="contained"
                          onClick={() => handleOpenDialog("addWBT")}
                          startIcon={<AddIcon size="small" />}
                          style={{ marginLeft: 4 }}
                        >
                            WBT
                        </Button>
                    )}
                </>
            );
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress size={24} />
                ) : (
                    "Sorry, there is no matching data to display"
                )
            }
        }
    };

    const classes = useStyles();
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item className={classes.root}>
                <MUIDataTable title="CT Setpoint" data={data} columns={columns} options={options} />
                {/* HeatRejPerc Form */}
                <RuleBasedOptimizationCTSetpointForm
                  name="Heat Rejection Percentage"
                  param="percentage"
                  schema={HeatRejPercSchema}
                  initData={{
                        HJ_DEV_FROM_PERC: 0,
                        HJ_DEV_TO_PERC: 0
                    }}
                  ref={percentageFormRef}
                  isEdit={isEdit}
                  open={openPercentageForm}
                  setOpen={setOpenPercentageForm}
                  onSuccess={handleFormSuccess}
                />
                {/* WBT Form */}
                <RuleBasedOptimizationCTSetpointForm
                  name="WBT"
                  param="wbt"
                  schema={WBTSchema}
                  initData={{
                        WBT_FROM: 0,
                        WBT_TO: 0
                    }}
                  ref={wbtFormRef}
                  isEdit={isEdit}
                  open={openWBTForm}
                  setOpen={setOpenWBTForm}
                  onSuccess={handleFormSuccess}
                />
                {/* Quick edit setpoint dialog */}
                {editCTSetpoint && (
                    <RuleBasedOptimizationEditSetpointDialog
                      open={!!editCTSetpoint}
                      ct={editCTSetpoint}
                      onClose={() => setEditCTSetpoint(null)}
                      onEdit={handleProcessEditSetpoint}
                    />
                )}
                {/* Quick delete dialog */}
                {deleteDialog && (
                    <RuleBasedOptimizationDeleteDialog
                      title={deleteDialog.title}
                      open={!!deleteDialog}
                      onClose={handleCloseDeleteDialog}
                      onDelete={handleProcessDelete}
                    />
                )}
            </Grid>
        </Grid>
    );
};

/**
 * CT Setpoint Edit Dialog
 */
const RuleBasedOptimizationEditSetpointDialog = ({ ct, open, onClose, onEdit }) => {
    const { hjDevPerc, wbt, setpoint } = ct;
    const [value, setValue] = useState(setpoint.value);

    const onChange = e => {
        setValue(+e.target.value);
    };

    const onSave = () => {
        onEdit(value);
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit CT Setpoint</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Heat Rejection 
{' '}
<strong>{`${hjDevPerc}%`}</strong>
                    </DialogContentText>
                    <DialogContentText>
                        WBT 
{' '}
<strong>{wbt}</strong>
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="setpoint"
                      label="Setpoint (APP_T)"
                      type="number"
                      inputProps={{
                            min: 0,
                            step: 0.1
                        }}
                      value={value}
                      onChange={onChange}
                      fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSave} color="primary">
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
RuleBasedOptimizationEditSetpointDialog.propTypes = {
    ct: PropTypes.shape({
        hjDevPerc: PropTypes.string.isRequired,
        wbt: PropTypes.string.isRequired,
        setpoint: PropTypes.shape({
            id: PropTypes.number,
            value: PropTypes.number
        }).isRequired
    }).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};
RuleBasedOptimizationEditSetpointDialog.defaultProps = {
    onClose: () => {},
    onEdit: () => {}
};

/**
 * Delete dialog
 */
const RuleBasedOptimizationDeleteDialog = ({ title, open, onClose, onDelete }) => {
    return (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogActions>
                <Button onClick={onDelete} color="primary">
                    Delete
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};
RuleBasedOptimizationDeleteDialog.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onDelete: PropTypes.func
};
RuleBasedOptimizationDeleteDialog.defaultProps = {
    onClose: () => {},
    onDelete: () => {}
};

export default RuleBasedOptimizationCTSetpoint;
