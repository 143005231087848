import React, { useState, useEffect, useCallback } from "react";
import {
    Menu,
    MenuItem,
    FormControlLabel,
    createMuiTheme,
    MuiThemeProvider,
    Grid,
    ButtonBase
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "../../../theme/ThemeContext";
import { getSiteName, getActiveSiteName } from "../../../../utils/helpers";

const SiteMenu = ({ open, portfolio, onChange }) => {
    const themeState = useTheme();

    const siteMenuTheme = createMuiTheme({
        overrides: {
            MuiMenuItem: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
            MuiMenu: {
                list: {
                    background: themeState.dark === true ? "#201F25" : "FFFFFF",
                    color: themeState.dark === true ? "#ACACAC" : "#707070;"
                }
            }
        },
        typography: {
            subtitle1: {
                fontSize: "0.8rem",
                color: themeState.dark === true ? "#ACACAC" : "#707070"
            }
        }
    });

    const [siteName, setSiteName] = useState(getActiveSiteName());
    const [imageSrc, setImageSrc] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const setImage = useCallback(() => {
        if (themeState.dark) {
            setImageSrc(localStorage.getItem("imageOpenDark"));
        } else {
            setImageSrc(localStorage.getItem("imageOpenLight"));
        }
    }, [themeState]);

    const handleClickSiteLogo = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeSite = site => {
        document.cookie = `siteId=${site.siteId}`;
        localStorage.setItem("activeSite", site.siteId);
        localStorage.setItem("siteName", site.siteName);
        localStorage.setItem("siteStatus", site.siteStatus);
        localStorage.setItem("imageOpenDark", site.nightModeLogo.mainLogo);
        localStorage.setItem("imageOpenLight", site.dayModeLogo.mainLogo);
        localStorage.setItem("imageCloseDark", site.nightModeLogo.circleLogo);
        localStorage.setItem("imageCloseLight", site.dayModeLogo.circleLogo);
        setImage();
        setSiteName(site.siteName);
        setAnchorEl(null);
        onChange(site);
    };

    useEffect(() => {
        setImage();
    }, [setImage]);

    const imageStyle = { width: "50px", marginRight: "6px" };

    return (
        <MuiThemeProvider theme={siteMenuTheme}>
            <Grid container direction="column" justifyContent="center">
                <ButtonBase onClick={handleClickSiteLogo}>
                    <img src={imageSrc} alt="Site Logo" style={{ maxHeight: "50px" }} />
                </ButtonBase>
                <Menu
                  id="site-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  style={{
                        top: "25px"
                    }}
                >
                    {portfolio &&
                        portfolio.sites.map(option => {
                            return (
                                <MenuItem
                                  key={option.siteId}
                                  onClick={() => handleChangeSite(option)}
                                >
                                    <FormControlLabel
                                      control={
                                            themeState.dark ? (
                                                <img
                                                  src={option.nightModeLogo.smallLogo}
                                                  alt={option.siteName}
                                                  style={imageStyle}
                                                />
                                            ) : (
                                                <img
                                                  src={option.dayModeLogo.smallLogo}
                                                  alt={option.siteName}
                                                  style={imageStyle}
                                                />
                                            )
                                        }
                                      label={getSiteName(option)}
                                    />
                                </MenuItem>
                            );
                        })}
                </Menu>
                <Typography display="block" variant="subtitle1" align="center">
                    {open ? siteName : ""}
                </Typography>
            </Grid>
        </MuiThemeProvider>
    );
};

export default SiteMenu;
