import React from "react";
import PropTypes from "prop-types";
// import { Grid } from "@material-ui/core";
// import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import "../styles/cardData.scss";
import { ICONS } from "../../../utils/enums/index";

const useStyles = makeStyles(theme => ({
    savingFont: {
        fontSize: "18px",
        color: theme.palette.type === "dark" ? "#ACACAC" : "#707070",
        fontWeight: 500,
        padding: "2% 0",
        [theme.breakpoints.up("sm")]: {
            fontSize: "14px"
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "16px"
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "18px"
        }
    },
    titleFont: {
        fontSize: "14px",
        [theme.breakpoints.up("sm")]: {
            fontSize: "16px"
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "12px"
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "14px"
        },
        color: theme.palette.type === "dark" ? "#ACACAC" : "#707070"
    },
    unit: {
        fontSize: "10px",
        [theme.breakpoints.up("sm")]: {
            fontSize: "12px"
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "9px"
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "10px"
        }
    },
    card: {
        display: "flex",
        // flexDirection: "column",
        flexDirection: "row",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row"
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "column"
        },
        [theme.breakpoints.up("lg")]: {
            flexDirection: "row"
        },
        padding: "3% 0 3% 6%"
    },
    cardKpi: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "140px",
        paddingLeft: "6%",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-start"
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "column"
        },
        [theme.breakpoints.up("lg")]: {
            flexDirection: "row"
        }
    },
    sysEfficiencyLeft: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            justifyContent: "flex-start",
            width: "13vw"
        },
        [theme.breakpoints.up("lg")]: {
            justifyContent: "flex-start",
            width: "13.5vw"
        },
        [theme.breakpoints.up("xl")]: {
            justifyContent: "flex-start",
            width: "13vw"
        },
        height: "50px",
        paddingLeft: "3%"
    },
    sysEfficiencyRight: {
        display: "flex",
        // [theme.breakpoints.up("md")]: {
        //     justifyContent: "space-between",
        //     width: "103px"
        // },
        // [theme.breakpoints.up("lg")]: {
        //     justifyContent: "space-between",
        //     width: "120px"
        // },
        [theme.breakpoints.up("xl")]: {
            justifyContent: "space-between",
            width: "130px"
        },

        paddingLeft: "6%"
    },
    imageContainer: {
        width: "35px", // "22%",
        paddingTop: "12px"
    },
    thermalValue: {
        marginLeft: "4.5px"
    },
    dataContainer: {
        marginRight: "10px"
    },
    details: {
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        width: "90px"
    },
    cardDetails: {
        display: "flex",
        flexDirection: "row",
        padding: "2px"
    },
    content: {
        flex: "1 0 auto",
        padding: "4px",
        "&:last-child": {
            paddingBottom: "4px"
        }
    },
    media: {
        verticalAlignment: "center"
    }
}));

const CardData2 = props => {
    const { image, title, savings, valueColor, id, styleValue } = props;
    // const { image, title, savings, unit, valueColor, id, styleValue } = props;
    const classes = useStyles();
    let classValue = null;
    // let titleGrid = null;

    switch (id) {
        case "KpiSystem":
            classValue = classes.cardKpi;
            break;
        case "system":
            classValue = classes.sysEfficiency;
            if (title === "kW" || title === "RT") {
                // titleGrid = classes.dataContainer;
                classValue = classes.sysEfficiencyLeft;
            } else {
                // titleGrid = classes.thermalValue;
                classValue = classes.sysEfficiencyRight;
            }
            break;
        default:
            classValue = classes.card;
            break;
    }
    return (
      <Box border={0} className={classValue}>
        <div className={classes.cardDetails}>
          <div className={classes.imageContainer}>
            <CardMedia>
              <img
                src={image}
                alt="savings"
                            // verticalAlignment="center"
                className={styleValue}
              />
            </CardMedia>
            {/* <CardMedia 
                alt="savings" 
                className={styleValue}
                image={image}
            /> */}
          </div>
          <div>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography
                  className={classNames(classes.titleFont, CardData2.styles.title)}
                >
                  {title}
                </Typography>
                {/* <Typography variant="subtitle1" color="textSecondary"> */}
                <Typography
                  className={classes.savingFont}
                  style={{ color: valueColor }}
                >
                  {savings}
                </Typography>
              </CardContent>
              {/* <div className={classes.controls}>
                <IconButton aria-label="previous">
                    {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                </IconButton>
                <IconButton aria-label="play/pause">
                    <PlayArrowIcon className={classes.playIcon} />
                </IconButton>
                <IconButton aria-label="next">
                    {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                </IconButton>
                </div> */}
            </div>
          </div>
        </div>
      </Box>
        //   <Grid container className={classValue}>
        //     <Grid item className={classes.imageContainer}>
        //       <img src={image} alt="savings" className={styleValue} />
        //     </Grid>
        //     <Grid className={titleGrid}>
        //       <Grid item>
        //         <span className={classNames(classes.titleFont, CardData2.styles.title)}>
        //           {title}
        //         </span>
        //       </Grid>
        //       <Grid item>
        //         <span className={classes.savingFont} style={{ color: valueColor }}>
        //           {savings}
        //         </span>
        //       </Grid>
        //       <Grid container item className={classes.unit}>
        //         <span className={CardData2.styles.unit}>{unit}</span>
        //       </Grid>
        //     </Grid>
        //   </Grid>
    );
};

CardData2.styles = {
    image: "webclient__cardData-image",
    savings: "webclient__cardData-savings",
    title: "webclient__cardData-title"
    // unit: "webclient__cardData-unit"
};
CardData2.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    savings: PropTypes.string,
    // unit: PropTypes.string,
    valueColor: PropTypes.string
};

CardData2.defaultProps = {
    image: ICONS.WATER_SAVINGS,
    title: "Cost Savings",
    savings: "234567",
    // unit: VERBIAGE.TEXT.EMPTY_STRING,
    valueColor: "#707070"
};

export default CardData2;
