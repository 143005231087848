const PortfolioActionTypes = {
    SET_PORTFOLIOSAVINGS: "data/SET_PORTFOLIOSAVINGS",
    SET_KPI: "data/SET_KPI",
    SET_PORTFOLIOINTERVAL: "data/SET_PORTFOLIOINTERVAL",
    SET_MAP: "data/SET_MAP",
    SET_CHANGE_SITE: "data/SET_CHANGE_SITE",
    SET_TOGGLE_SIDEBAR: "data/SET_TOGGLE_SIDEBAR",
    SET_SITES: "data/SET_SITES",
    UPDATE_SITES: "data/UPDATE_SITES",
    SET_PORTFOLIO_BENCHMARKS: "data/SET_PORTFOLIO_BENCHMARKS"
};

export default PortfolioActionTypes;
