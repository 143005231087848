/* eslint-disable no-unused-vars  */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import URL from "../../../utils/URL";
import SiteOverviewChart from "./SiteOverviewChart";
import FaultsAndAlarmsMainTabs from "../Analytics/FaultsAndAlarmsMainTabs";
import OptimizationPerformanceView from "../Analytics/OptimizationView";
import OptimizationSummaryView from "../Analytics/OptimizationSummaryView";
import AnomalyDiagnosisView from "../../features/home/AnomalyDiagnosisView";
import AnalyticsView from "../../features/home/AnalyticsView";

const FaultsAndAlarmsPage = props => {
    // const { classes, tabStyle } = props;
    const {
        classes,
        tabStyle,
        handleFeedback,
        setTabDates,
        setTabMax,
        setTabMin,
        addWorksheet,
        worksheets,
        tab,
        dates,
        min,
        max,
        dateSlider,
        handleSliderChange,
        handleEndDateInput,
        handleStartDateInput,
        onSliderChangeCommitted,
        initialFilter
    } = props;

    // const [url, setUrl] = useState(URL.DATA.LIFE_CYCLE.HEALTH_SCORE);
    const [value, setValue] = useState("fault_summary");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const [worksheets, setWorksheets] = useState([]);
    // const addWorksheet = worksheet => {
    //     setWorksheets(prev => [...prev, worksheet]);
    // };

    return (
        <React.Fragment>
            <FaultsAndAlarmsMainTabs
              classes={classes}
              tabStyle={tabStyle}
              value={value}
              handleChange={handleChange}
            />

            {value === "fault_summary" && (
                <Grid container spacing={2}>
                    <AnalyticsView
                      setTabDates={setTabDates}
                      setTabMax={setTabMax}
                      setTabMin={setTabMin}
                      addWorksheet={addWorksheet}
                      worksheets={worksheets}
                      tab={value}
                      dateSlider={dateSlider}
                      dates={dates}
                      min={min}
                      max={max}
                      handleSliderChange={handleSliderChange}
                      onSliderChangeCommitted={onSliderChangeCommitted}
                      handleStartDateInput={handleStartDateInput}
                      handleEndDateInput={handleEndDateInput}
                      initialFilter={initialFilter}
                    />
                </Grid>
            )}

            {value === "fault_anomaly_diagnosis" && (
                <Grid container spacing={2}>
                    <AnomalyDiagnosisView
                      setTabDates={setTabDates}
                      setTabMax={setTabMax}
                      setTabMin={setTabMin}
                      addWorksheet={addWorksheet}
                      worksheets={worksheets}
                      tab={value}
                      dateSlider={dateSlider}
                      dates={dates}
                      min={min}
                      max={max}
                      handleSliderChange={handleSliderChange}
                      onSliderChangeCommitted={onSliderChangeCommitted}
                      handleStartDateInput={handleStartDateInput}
                      handleEndDateInput={handleEndDateInput}
                      initialFilter={initialFilter}
                    />
                </Grid>
            )}
        </React.Fragment>
    );
};

export default FaultsAndAlarmsPage;
