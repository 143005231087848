/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import TableauMenu from "./TableauMenu";
import TableauV3 from "./TableauV3";
import REFRESH_TIMER from "../../../utils/enums/Enum";
import useTableau from "../../../hooks/useTableau";

const SiteOverviewChartV3 = props => {
    const {
        addWorksheet,
        initialFilter,
        tall,
        combined,
        overview,
        xs,
        sm,
        md,
        lg,
        configValues,
        setConfigValues,
        handleTitleLink,
        alarmConfig,
        alarmConfigParam,
        download,
        tableauName,
        tableauLink,
        tableauToken,
        tableauOptions,
        tableauRefresh
    } = props;

    const configLoaded = useRef(false);

    const getHeight = height => {
        if (combined && overview) return "560px";
        if (combined) return "820px";
        if (tall) return "600px";
        return height;
    };

    const [height, setHeight] = useState(getHeight("250px"));

    const initialGridSize = {
        xs,
        sm,
        md,
        lg
    };

    const [gridSize, setGridSize] = useState(initialGridSize);

    const changeGrid = expand => {
        if (expand) {
            setGridSize(prev => {
                return {
                    xs,
                    sm: xs,
                    md: xs,
                    lg: xs
                };
            });
            setHeight("600px");
        } else {
            setGridSize(initialGridSize);
            setHeight(getHeight("250px"));
            // setHeight(tall ? "600px" : (combined ? "820px" : "250px"));
        }

        // eslint-disable-next-line no-unused-expressions
        tableauRefresh?.();
    };

    // Preserve order of useEffects
    useEffect(() => {
        if (configValues && !configLoaded.current) {
            if (configValues.length > 0) {
                configLoaded.current = true;
            }
        }
        if (alarmConfig && !configLoaded.current) {
            if (alarmConfig.length > 0) {
                configLoaded.current = true;
            }
        }
    }, [configValues, alarmConfig]);

    // Tableau

    const [tableauViz, setTableauViz] = useState();

    const { tableauV3Adapter } = useTableau();

    const tableauOptionsMemo = useMemo(() => {
        if (configValues) {
            configValues.forEach(config => {
                tableauOptions[config.name] = config.value;
                if (config.value === "True" || config.value === "False") {
                    tableauOptions[config.name] = config.value === "True";
                }
            });
        }

        if (alarmConfig && alarmConfigParam) {
            alarmConfig.forEach(row => {
                if (row.param === alarmConfigParam) {
                    row.data.forEach(config => {
                        tableauOptions[config.name] = config.value;
                    });
                }
            });
        }
        return tableauOptions;
    }, [tableauOptions, configValues, alarmConfig, alarmConfigParam]);

    const onFirstInteractive = useCallback(() => {
        const { activeSheet } = tableauViz.workbook;

        if (activeSheet.sheetType === "dashboard") {
            activeSheet.worksheets.forEach(ws => {
                const worksheet = tableauV3Adapter(ws);
                addWorksheet(worksheet);
                if (initialFilter) {
                    initialFilter(worksheet);
                }
            });
        } else {
            const worksheet = tableauV3Adapter(activeSheet);
            addWorksheet(worksheet);
            if (initialFilter) {
                initialFilter(worksheet);
            }
        }
    }, [tableauViz, tableauV3Adapter, addWorksheet, initialFilter]);

    const onParameterValueChange = useCallback(
        () => async e => {
            if (configValues) {
                const param = await e.getParameterAsync();
                const paramName = param.getName();
                const paramNewValue = param.getCurrentValue().formattedValue;
                setConfigValues(prev => {
                    const index = prev.findIndex(config => config.name === paramName);
                    const arr = [...prev];
                    arr[index] = { ...arr[index], value: paramNewValue };
                    return arr;
                });
            }
        },
        [configValues, setConfigValues],
    );

    useEffect(() => {
        if (tableauViz) {
            // listen on first interactive
            tableauViz.addEventListener("firstinteractive", onFirstInteractive);
            // listen on parameter changed
            tableauViz.addEventListener("parametervaluechange", onParameterValueChange);

            // refresh data interval
            const refreshTimeout = REFRESH_TIMER();
            const refreshDataTimer = setInterval(() => {
                tableauViz.refreshDataAsync();
            }, refreshTimeout);

            return () => {
                clearInterval(refreshDataTimer);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableauViz]);

    const handleVizOnload = viz => {
        setTableauViz(viz);
    };

    const refreshChart = async () => {
        try {
            if (!tableauViz) throw new Error("tableauViz is not initialized");
            await tableauViz.refreshDataAsync();
        } catch (error) {
            // eslint-disable-next-line no-unused-expressions
            tableauRefresh?.();
        }
    };

    const exportPDF = async () => {
        try {
            if (!tableauViz) throw new Error("tableauViz is not initialized");
            await tableauViz.showExportPDFDialog();
        } catch (error) {
            // handler error...
        }
    };

    // Paper styling
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(0.5),
            color: theme.palette.text.secondary,
            height: getHeight(height)
        },
        spacing: {
            padding: theme.spacing(0.5),
            height: "10px",
            opacity: 0
        },
        grid: {
            padding: "8px 5px"
        }
    }));

    const classes = useStyles();

    return (
        <Grid
          container
          item
          xs={gridSize.xs}
          sm={gridSize.sm}
          md={gridSize.md}
          lg={gridSize.lg}
          className={classes.grid}
        >
            <Grid item xs={12}>
                <TableauMenu
                  chartTitle={tableauName}
                  changeGrid={changeGrid}
                  headerValue
                  tall={tall}
                  report={download}
                  exportPDF={exportPDF}
                  refreshChart={refreshChart}
                  handleTitleLink={handleTitleLink}
                />
            </Grid>

            <Grid item xs={12}>
                <Paper className={classes.spacing} />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0}>
                    {tableauLink && (
                        <TableauV3
                          name={tableauName}
                          link={tableauLink}
                          token={tableauToken}
                          options={tableauOptionsMemo}
                          onVizLoad={handleVizOnload}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SiteOverviewChartV3;
