/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import VERBIAGE from "../../../utils/enums/Verbiage";
import PIDContainer from "./PIDContainer";
import PIDActions from "../../redux_PID/PIDActions";
import REFRESH_TIMER from "../../../utils/enums/Enum";
import Dropdown from "../components/Dropdown";

const MobileDetect = require("mobile-detect");

const PIDOverall = props => {
    const { fetchData, pid, home } = props;

    const md = new MobileDetect(window.navigator.userAgent);
    const isTablet = !!md.tablet();

    const groupName = pid && pid.pidData && pid.pidData.map(name => name.groupName);
    const [pidgroupName, setPidgroupName] = useState();
    const [dataPid, setdataPid] = useState();

    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        fetchData();
        const timer = setInterval(() => {
            fetchData();
        }, timerValue);
        return () => clearInterval(timer);
    }, [fetchData]);

    useEffect(() => {
        if (groupName && !pidgroupName) {
            setPidgroupName(groupName[0]);
        }
        if (!dataPid) {
            const initialPidData = pid && pid.pidData && pid.pidData[0] && pid.pidData[0].pidData;
            setdataPid(initialPidData);
        }
    }, [groupName, pidgroupName, dataPid, pid]);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        grid: {
            fontSize: "x-large",
            fontWeight: "500",
            marginTop: "6rem",
            textAlign: isTablet || home.desktopMobileSwitch === "desktop" ? "left" : "center"
        },
        subContainer: {
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white,
            minWidth: isTablet ? "auto" : "266px",
            borderRadius: "3px",
            margin: "0px 5px",
            padding: "0 0 10px 0"
        },
        subContainerDouble: {
            background:
                theme.palette.type === "dark"
                    ? theme.palette.background.tab
                    : theme.palette.common.white,
            borderRadius: "3px",
            margin: "0px 5px",
            maxWidth: isTablet && home.desktopMobileSwitch === "mobile" ? "auto" : "538px",
            minWidth: isTablet && home.desktopMobileSwitch === "mobile" ? "auto" : "538px",
            padding: "0 0 10px 0"
        },
        text1Container: {
            textAlign: "center",
            color:
                theme.palette.type === "dark"
                    ? theme.palette.text.dark_grey
                    : theme.palette.text.light_black,
            fontSize: "16px",
            fontWeight: "500px",
            padding: "10px 0px"
        },
        mainContainer: {
            display: "flex",
            flexDirection: isTablet || home.desktopMobileSwitch === "desktop" ? "row" : "column"
        },
        dropdown: {
            border:
                theme.palette.type === "dark"
                    ? "1px solid #444444"
                    : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}`, // "1px solid #3865A7",
            borderRadius: "4px",
            height: "22px",
            minWidth: "266px",
            margin: "10px 0 25px 0",
            color:
                theme.palette.type === "dark"
                    ? theme.palette.text.grey
                    : theme.palette.text.light_grey
        },
        pidContainer: {
            display: "flex",
            flexDirection: isTablet && home.desktopMobileSwitch === "mobile" ? "row" : "column"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box"
        }
    }));

    const classes = useStyles();

    const doubleLapStyle = {
        float: "left",
        width: "50%"
    };
    const doubleTabStyle = {
        display: "table-cell"
    };
    const handleChange = event => {
        const eventValue = event.target.value;
        setPidgroupName(eventValue);
        const filteredPidData = pid.pidData.filter(data => eventValue === data.groupName);
        setdataPid(filteredPidData[0].pidData);
    };

    return (
        <>
            {isTablet || home.desktopMobileSwitch === "desktop" ? (
                <div className={classes.mainContainer}>
                    {pid.pidData.map(data => (
                        <div
                          className={
                                data.pidData.length > 2
                                    ? classes.subContainerDouble
                                    : classes.subContainer
                            }
                          key={data.groupName}
                        >
                            <Typography className={classes.text1Container}>
                                {data.groupName}
                            </Typography>
                            <div className={data.pidData.length > 2 ? "" : classes.pidContainer}>
                                {data.pidData.map(obj => {
                                    if (data.pidData.length > 2) {
                                        return (
                                            <div
                                              style={
                                                    isTablet &&
                                                    home.desktopMobileSwitch === "mobile"
                                                        ? doubleTabStyle
                                                        : doubleLapStyle
                                                }
                                              key={obj.label}
                                            >
                                                <PIDContainer
                                                  Text={obj.label}
                                                  SPValue={obj.spValue}
                                                    // onSlide={slider}
                                                  PVValue={obj.pvValue}
                                                  min={obj.min}
                                                  max={obj.max}
                                                  density={obj.density}
                                                  values={obj.values}
                                                  outValue={obj.outValue}
                                                  PValue={obj.PValue}
                                                  IValue={obj.IValue}
                                                  DValue={obj.DValue}
                                                  DBValue={obj.DBValue}
                                                  MANValue={obj.MANValue}
                                                  MINValue={obj.MINValue}
                                                  MAXValue={obj.MAXValue}
                                                  checked={obj.auto}
                                                />
                                            </div>
                                        );
                                    }
                                    return (
                                        <div key={obj.label}>
                                            <PIDContainer
                                              Text={obj.label}
                                              SPValue={obj.spValue}
                                                // onSlide={slider}
                                              PVValue={obj.pvValue}
                                              min={obj.min}
                                              max={obj.max}
                                              density={obj.density}
                                              values={obj.values}
                                              outValue={obj.outValue}
                                              PValue={obj.PValue}
                                              IValue={obj.IValue}
                                              DValue={obj.DValue}
                                              DBValue={obj.DBValue}
                                              MANValue={obj.MANValue}
                                              MINValue={obj.MINValue}
                                              MAXValue={obj.MAXValue}
                                              checked={obj.auto}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <Grid item container justifyContent="center">
                    <Dropdown
                      value={pidgroupName}
                      options={groupName}
                      onChange={event => handleChange(event)}
                      className={classes.dropdown}
                    />
                    <div className={classes.mainContainer}>
                        <div className={classes.subContainer}>
                            <Typography className={classes.text1Container}>
                                {pidgroupName}
                            </Typography>
                            <div className={classes.pidContainer}>
                                {dataPid &&
                                    dataPid.map(obj => {
                                        return (
                                            <PIDContainer
                                              Text={obj.label}
                                              SPValue={obj.spValue}
                                              PVValue={obj.pvValue}
                                              min={obj.min}
                                              max={obj.max}
                                              density={obj.density}
                                              values={obj.values}
                                              outValue={obj.outValue}
                                              PValue={obj.PValue}
                                              IValue={obj.IValue}
                                              DValue={obj.DValue}
                                              DBValue={obj.DBValue}
                                              MANValue={obj.MANValue}
                                              MINValue={obj.MINValue}
                                              MAXValue={obj.MAXValue}
                                              checked={obj.auto}
                                              key={obj.label}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </Grid>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    pid: state.pid,
    home: state.home
});
const mapDispatchToProps = dispatch => ({
    fetchData: () => {
        dispatch(PIDActions.fetchData());
    }
});
PIDOverall.propTypes = {
    fetchData: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PIDOverall));
