/* eslint-disable jsx-a11y/anchor-is-valid  */
/* eslint-disable no-unused-vars  */
/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    Link
} from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";
import SiteOverviewChart from "../components/SiteOverviewChart";
import URL from "../../../utils/URL";
import api from "../../../utils/API";
import AnalyticsActions from "../../redux_Analytics/AnalyticsActions";

const OptimizationAutomationView = props => {
    const { /* changeTab, */ alarmConfig, fetchAlarmConfig } = props;
    const [worksheets, setWorksheets] = useState([]);
    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    const isMounted = useRef(false);

    const [faultRows, setFaultRows] = useState([]);

    // const setDataValue = (
    //     newParameter,
    //     newLabel,
    //     newDuration,
    //     newStartDate,
    //     noteIdNew,
    //     newCode,
    // ) => {
    //     // fetchChartPosition(newCode, newParameter);
    //     setLabel(newLabel);
    //     setParameter(newParameter);
    //     setDurationValue(newDuration);
    //     setUrl(`${URL.DATA.ANALYTICS.EDIT}/${newParameter}`);
    //     setTimeStamp(newStartDate);
    //     setStartDate(newStartDate - addStartBuffer(newDuration));
    //     setEndDate(newStartDate + changeDurationStructure(newDuration));
    //     fetchNotes(noteIdNew);
    //     // setParameterURL(URL.DATA.ANALYTICS.CHARTS.CHART_1);
    // };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const optimizationOutputResponse = await api.get(
                //     URL.DATA.ANALYTICS.OPTMIZATION.SETPOINT,
                //     {
                //         headers: {
                //             "x-auth-token": localStorage.token
                //         }
                //     },
                // );
                // // console.log("optimizationOutputResponse : ", optimizationOutputResponse);
                // if (isMounted.current) {
                //     setFaultRows(optimizationOutputResponse.data);
                // }
                fetchAlarmConfig();
            } catch (error) {
                if (error.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, []);

    const { isLiveData, defaultDate } = localStorage;

    const initialFilter = ws => {
        if (isLiveData === "false") {
            let date = new Date(parseInt(defaultDate, 10));
            date = date.getTime() - date.getTimezoneOffset() * 60 * 1000 - 1;
            // Skip Equipment Lifecycle Management chart
            if (ws.getName() === "Chiller 1") {
                return;
            }
            ws.applyRelativeDateFilterAsync("SYS_TS_DATETIME", {
                anchorDate: new Date(date),
                periodType: "Minute",
                rangeType: "LASTN",
                rangeN: 3 * 24 * 60
            });
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(date));
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(date));
        }
    };

    const useStyles = makeStyles(theme => ({
        paper: {
            padding: theme.spacing(0.5),
            color: theme.palette.text.secondary,
            height: "250px" // heightMulti
        },
        table: {
            padding: theme.spacing(0),
            color: theme.palette.text.secondary,
            // minHeight: "250px",
            height: "500px",
            overflowY: "auto"
        },
        spacing: {
            padding: theme.spacing(0.5),
            backgroundColor: "transparent",
            color: theme.palette.common.black,
            height: "1vh" // heightMulti
        },
        header: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    }));

    const classes = useStyles();
    const borderStyle = {
        borderRadius: "0 4px 0 0"
    };

    return (
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={12} md={12} lg={3}>
          <Grid container spacing={6}>
            <Grid item xs={6} sm={6} md={4} lg={12}>
              <SiteOverviewChart
                url={URL.DATA.ANALYTICS.OPTMIZATION.COST_IMPACT}
                addWorksheet={addWorksheet}
                double
                xs={12}
                sm={12}
                md={12}
                lg={12}
                initialFilter={initialFilter}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={12}>
              <SiteOverviewChart
                url={URL.DATA.ANALYTICS.OPTMIZATION.FET_IMPACT}
                addWorksheet={addWorksheet}
                double
                xs={12}
                sm={12}
                md={12}
                lg={12}
                alarmConfig={alarmConfig}
                alarmConfigParam="CHWS_HDR_T"
                initialFilter={initialFilter}
              />
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SiteOverviewChart
            url={URL.DATA.ANALYTICS.OPTMIZATION.LOOKUP_COMPLETE}
            addWorksheet={addWorksheet}
            xs={12}
            tall
          />
        </Grid>
      </Grid>
    );
};

// const mapStateToProps = state => ({
//     alarmConfig: state.analytics.alarmConfig
// });
// const mapDispatchToProps = dispatch => ({
//     fetchAlarmConfig: () => {
//         dispatch(AnalyticsActions.fetchAlarmConfig());
//     }
// });

export default connect(null, null)(OptimizationAutomationView);
