import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Accordion,
    AccordionSummary,
    CircularProgress,
    IconButton,
    Snackbar,
    Table,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery
} from "@material-ui/core";
import { Add, EditOutlined, ExpandMore } from "@material-ui/icons";
import { createMuiTheme, makeStyles, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import MUIDataTable, { TableBody } from "mui-datatables";
import moment from "moment";

import CustomFooter from "./CustomFooter";

import API from "../../../utils/API";
import URL from "../../../utils/URL";
import EditAirflowConnection from "./EditAirflowConnection";

const AirflowConnections = () => {
    const isMounted = useRef(false);
    const editRef = useRef();

    const [open, setOpen] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const handleClickAdd = () => {
        editRef.current.init();
        setIsEdit(false);
        setOpen(true);
    };

    const handleClickEdit = rowData => {
        const { basicAuth = "", ...row } = rowData;
        const [username = "", password = ""] = window.atob(basicAuth).split(":");
        editRef.current.update({
            ...row,
            username,
            password
        });
        setIsEdit(true);
        setOpen(true);
    };

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await API.get(URL.DATA.ML_SETTINGS.AIRFLOW, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });

            if (isMounted.current) {
                setData(
                    response.data.map(row => {
                        return {
                            ...row,
                            updatedAtStr: moment(row.updatedAt).format("DD MMM YY HH:mm:ss")
                        };
                    }),
                );
            }
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        isMounted.current = true;
        fetchData();
        return () => (isMounted.current = false);
    }, [fetchData]);

    const handleClose = refresh => {
        setOpen(false);
        if (refresh) {
            fetchData();
        }
    };

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    // MUITheme

    const isNarrow = useMediaQuery("(max-width: 600px)");

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTable: {
                          paper: {
                              boxShadow: "none"
                          }
                      },
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTable: {
                          paper: {
                              boxShadow: "none"
                          }
                      },
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    // MUIDataTable
    const [isRowExpanded, setIsRowExpanded] = useState({});

    const StyledTableCell = withStyles(() => ({
        root: {
            padding: "16px"
        }
    }))(TableCell);

    const customRowRender = (_row, dataIndex) => {
        const handleAccordion = (_event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        const rowData = data[dataIndex];
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell>
                                            <IconButton
                                              onClick={event => {
                                                    event.stopPropagation();
                                                    handleClickEdit(rowData);
                                                }}
                                              onFocus={event => event.stopPropagation()}
                                            >
                                                <EditOutlined />
                                            </IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>{rowData.name}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Host</StyledTableCell>
                                        <StyledTableCell>{rowData.host}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Active</StyledTableCell>
                                        <StyledTableCell>{rowData.isActive}</StyledTableCell>
                                    </TableRow>
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell>Last Update</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.updatedAtStr}
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "400px",
        filter: false,
        print: false,
        viewColumns: false,
        download: false,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title="Add Airflow Connection">
                        <IconButton onClick={handleClickAdd}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress size={24} />
                ) : (
                    "Sorry, there is no matching data to display"
                )
            }
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const columns = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: dataIndex => {
                    return (
                        <IconButton
                          onClick={() => {
                                handleClickEdit(data[dataIndex]);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    );
                }
            }
        },
        { label: "Name", name: "name" },
        { label: "Host", name: "host" },
        { label: "Description", name: "description" },
        {
            label: "Active",
            name: "isActive",
            options: {
                customBodyRender: value => value.toString().toUpperCase()
            }
        },
        {
            label: "Updated",
            name: "updatedAtStr"
        }
    ];

    return (
        <>
            <EditAirflowConnection
              ref={editRef}
              isEdit={isEdit}
              open={open}
              onClose={handleClose}
            />
            <div className={classes.table}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      title="Airflow Connections"
                      data={data}
                      columns={columns}
                      options={options}
                    />
                </MuiThemeProvider>
            </div>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={!!error}
              onClose={() => setError(null)}
              message={error}
            />
        </>
    );
};

export default AirflowConnections;
