import React, { useCallback, useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";

import debounce from "lodash/debounce";

import StyledTableRow from "./components/StyledTableRow";
import NotAvailableRender from "./components/NotAvailableRender";
import PIDSPCloudStatus from "./PID_SPCloudStatus";
import PIDSPScada from "./PID_SPScada";

const PIDAutoMLSetpointTableBody = ({ setpoints, onUpdate, onError, disabled }) => {
    const [values, setValues] = useState({
        rec_min_value: {},
        rec_max_value: {}
    });

    const [errors, setSpErrors] = useState({
        rec_min_value: {},
        rec_max_value: {}
    });

    useEffect(() => {
        setValues(
            setpoints.reduce(
                (acc, item) => {
                    acc.rec_min_value[item.SP] = item.rec_min_value;
                    acc.rec_max_value[item.SP] = item.rec_max_value;
                    return acc;
                },
                { rec_min_value: {}, rec_max_value: {} },
            ),
        );
    }, [setpoints]);

    const handleUpdateValueCb = useCallback(
        debounce((sp, key, value) => {
            const types = {
                rec_min_value: "min",
                rec_max_value: "max"
            };
            onUpdate({ sp, type: types[key], value });
        }, 2000),
        [],
    );
    const handleChangeValue = (row, key, column) => e => {
        const value = parseFloat(e.target.value);
        setValues(prevValues => ({
            ...prevValues,
            [key]: {
                ...prevValues[key],
                [row.SP]: value
            }
        }));

        let errorMessage;
        if (value < row.min_value) {
            errorMessage = `${row.SP} ${column}: ${value} < Min ${row.min_value}`;
        } else if (value > row.max_value) {
            errorMessage = `${row.SP} ${column}: ${value} > Max ${row.max_value}`;
        }

        setSpErrors(prevErrors => ({
            ...prevErrors,
            [key]: {
                ...prevErrors[key],
                [row.SP]: errorMessage
            }
        }));

        const spError = `${row.SP}_${key}`;
        if (errorMessage) {
            onError({ sp: spError, message: errorMessage });
        } else {
            onError({ sp: spError, message: null });
            handleUpdateValueCb(row.SP, key, value);
        }
    };

    const isSPInRange = row => {
        if (!row.min_value || !row.max_value) {
            return true;
        }
        return row.sp_in_range;
    };

    return setpoints.map(setpoint => (
        <StyledTableRow key={setpoint.SP} active={isSPInRange(setpoint) ? "true" : "false"}>
            <TableCell>{setpoint.name}</TableCell>
            <TableCell>
                <NotAvailableRender condition={setpoint.scada_status !== undefined}>
                    <PIDSPScada {...setpoint} />
                </NotAvailableRender>
            </TableCell>
            <TableCell>
                <NotAvailableRender condition={setpoint.cloud_status !== undefined}>
                    <PIDSPCloudStatus {...setpoint} disabled={disabled} />
                </NotAvailableRender>
            </TableCell>
            <TableCell>
                <NotAvailableRender condition={setpoint.min_value}>
                    {setpoint.min_value}
                </NotAvailableRender>
            </TableCell>
            <TableCell>
                <NotAvailableRender condition={setpoint.current_value}>
                    {setpoint.current_value}
                </NotAvailableRender>
            </TableCell>
            <TableCell>
                <NotAvailableRender condition={setpoint.rec_value}>
                    {setpoint.rec_value}
                </NotAvailableRender>
            </TableCell>
            <TableCell>
                <NotAvailableRender condition={setpoint.max_value}>
                    {setpoint.max_value}
                </NotAvailableRender>
            </TableCell>
            <TableCell>
                <NotAvailableRender condition={setpoint.max_value}>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      value={values.rec_min_value[setpoint.SP] ?? 0}
                      InputLabelProps={{ shrink: false }}
                      inputProps={{ step: 0.01 }}
                      error={errors.rec_min_value[setpoint.SP]}
                      onChange={handleChangeValue(setpoint, "rec_min_value", "Rec Min.")}
                    />
                </NotAvailableRender>
            </TableCell>
            <TableCell>
                <NotAvailableRender condition={setpoint.max_value}>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      value={values.rec_max_value[setpoint.SP] ?? 0}
                      InputLabelProps={{ shrink: false }}
                      inputProps={{ step: 0.1 }}
                      error={!!errors.rec_max_value[setpoint.SP]}
                      onChange={handleChangeValue(setpoint, "rec_max_value", "Rec Max.")}
                    />
                </NotAvailableRender>
            </TableCell>
        </StyledTableRow>
    ));
};

export default PIDAutoMLSetpointTableBody;
