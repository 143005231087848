import RuleBasedOptimizationCTActionTypes from "./RuleBasedOptimizationCTActionTypes";

const initialState = {
    ctCapacity: {
        loading: false,
        error: null,
        data: [],
        lastUpdated: null
    },
    ctSetpoint: {
        loading: false,
        error: null,
        data: [],
        lastUpdated: null
    }
};

const OptimizationCTReducer = (state = initialState, action) => {
    switch (action.type) {
        case RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_CAPACITY:
            return {
                ...state,
                ctCapacity: {
                    ...state.ctCapacity,
                    ...action.payload
                }
            };
        case RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_CAPACITY_DATA:
            return {
                ...state,
                ctCapacity: {
                    ...state.ctCapacity,
                    ...action.payload,
                    lastUpdated: new Date().getTime()
                }
            };
        case RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_SETPOINT:
            return {
                ...state,
                ctSetpoint: {
                    ...state.ctSetpoint,
                    ...action.payload
                }
            };
        case RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_SETPOINT_DATA:
            return {
                ...state,
                ctSetpoint: {
                    ...state.ctSetpoint,
                    ...action.payload,
                    lastUpdated: new Date().getTime()
                }
            };
        default:
            return state;
    }
};

export default OptimizationCTReducer;
