/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { VERBIAGE } from "../../../utils/enums/index";
import AnalyticsTime from "./AnalyticsTime";

const moment = require("moment");

const useStyles = makeStyles(theme => ({
    header: {
        color: theme.palette.type === "dark" ? theme.palette.text.dark : theme.palette.primary.main,
        margin: "4px",
        fontSize: "14px",
        paddingRight: "10px"
    },
    button: {
        height: "22px",
        margin: "4px",
        textTransform: "None",
        background:
            theme.palette.type === "dark"
                ? theme.palette.background.button
                : theme.palette.primary.main
    }
}));
const menuStyle = {
    color: "white",
    textDecoration: "none"
};

const MenuExportCsv = props => {
    const { data, isNarrow } = props;
    const classes = useStyles();
    const [newData, setNewData] = useState([]);

    const handleEllipsisClick = () => {
        const tempData = [];
        for (let i = 0; i < data.length; i++) {
            const newDict = {};
            newDict["id"] = data[i].id;
            newDict["start"] = moment(data[i].start).format("Do MMMM YYYY, HH:mm:ss");
            newDict["duration"] = data[i].duration;
            newDict["code"] = data[i].code;
            newDict["parameter"] = data[i].parameter;
            newDict["description"] = data[i].description;
            newDict["label"] = data[i].label;
            newDict["tag"] = data[i].tag;
            newDict["userTag"] = data[i].userTag;
            tempData.push(newDict);
        }
        setNewData(tempData);
    };

    return (
      <Grid container item xs={12} sm={12} md={12} lg={12} justify="flex-end">
        <Grid>
          <h2 className={classes.header}>
            {`${data.length}${VERBIAGE.ANALYTICS.FAULT_EVENT}`}
          </h2>
        </Grid>
        <Grid>
          <AnalyticsTime />
        </Grid>
        {!isNarrow && (
        <Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            onClick={handleEllipsisClick}
          >
            <CSVLink style={menuStyle} data={newData} filename="Fault_Events.csv">
              {VERBIAGE.BUTTONS.EXPORT_CSV}
            </CSVLink>
          </Button>
        </Grid>
            )}
      </Grid>
    );
};

export default MenuExportCsv;
