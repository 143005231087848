/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import { Menu, MenuItem, Checkbox, makeStyles } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import SearchFilter from "./SearchFilter";

const useStyles = makeStyles(() => ({
    span: {
        position: "absolute",
        // top: "2.7vh",
        cursor: "pointer"
    }
}));

const menuItemStyle = {
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "2.6vh"
};

const menuStyle = {
    maxHeight: "300px"
};

const AnalyticsDropdown = ({
    analyticsData,
    currentItems,
    items,
    field,
    filterData,
    dropDown,
    setDropDown,
    search,
    textSearch,
    tdata,
    fulltable,
    statusOfFilter
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [list, setList] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [emptyInput, setEmptyInput] = useState(true);
    const [tableUpdate, setTableUpdate] = useState(false);
    const [selectAll, setSelectAll] = useState(true);
    const [tdataLocal, setTdataLocal] = useState([]);
    const classes = useStyles();

    const getIds = (keyItem, isTextSearch = false) => {
        const idList = [];
        if (field === "label") {
            const searchList = isTextSearch ? tdata : tdataLocal;

            searchList.forEach(item => {
                let condition = false;

                if (isTextSearch) {
                    condition = item[field].toUpperCase().includes(keyItem.toUpperCase());
                } else {
                    condition =
                        (keyItem === "Labeled" && item[field].length) ||
                        (keyItem === "No Label" && !item[field].length);
                }

                if (condition) {
                    idList.push(item.id);
                }
            });
        } else {
            analyticsData.forEach(item => {
                if (
                    (!Array.isArray(item[field]) && item[field] === keyItem) ||
                    (Array.isArray(item[field]) && item[field].indexOf(keyItem) !== -1)
                ) {
                    idList.push(item.id);
                }
            });
        }
        return [...new Set(idList)];
    };

    const handleTextSearch = input => {
        filterData([...new Set(getIds(input, true))], field);
    };

    // getIds of visible items in table
    const getIdsVisible = itemList => {
        const visibleIdSet = itemList.map(item => getIds(item));
        return visibleIdSet.flat();
    };

    useEffect(() => {
        if (list.length && tableUpdate) {
            filterData([...new Set(getIdsVisible(list))], field);
        }
    }, [list]);

    useEffect(() => {
        if (tableUpdate) {
            filterData([...new Set(getIdsVisible(menuItems))], field);
        } else if (menuItems.length && !tableUpdate) {
            setTableUpdate(true);
        }
    }, [menuItems]);

    // function to handle menu close
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        if (dropDown !== field) {
            setEmptyInput(true);
            // setDropDown(field);
            setSelectAll(fulltable);
            if (search) {
                setMenuItems(items);
                setList(currentItems);
            } else if (!menuItems.length) {
                setMenuItems(items);
                setList(items);
            }
            if (!search) {
                setTdataLocal(tdata);
            }
        } else if (statusOfFilter) {
            setMenuItems(items);
            if (fulltable) {
                setList(items);
            }
            setSelectAll(fulltable);
        }
    };

    // function to handle menu open
    const handleClose = () => {
        setAnchorEl(null);
        setDropDown(field);
        setTableUpdate(false);
    };

    // function to handle select
    const handleSelect = event => {
        const keyItem = event.currentTarget.textContent;
        if (!tableUpdate) {
            setTableUpdate(true);
        }
        // new list from menu items
        const updatedMenuList = list.filter(item => menuItems.indexOf(item) !== -1);
        if (keyItem === "Select/Deselect All") {
            if (selectAll) {
                setSelectAll(false);
                setList([]);
            } else {
                setSelectAll(true);
                setList(menuItems);
            }
        } else {
            setSelectAll(false);
            if (list.indexOf(keyItem) === -1) {
                setList([...updatedMenuList, keyItem]);
            } else {
                const updatedList = updatedMenuList.filter(item => item !== keyItem);
                setList(updatedList);
            }
        }
    };

    return (
      <React.Fragment>
        <span
          className={classes.span}
          aria-controls={`simple-menu-${field}`}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <ArrowDropDown />
        </span>
        {items && (
        <Menu
          id={`menu-${field}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={menuStyle}
        >
          {search && (
          <SearchFilter
            items={items}
            setMenuItems={setMenuItems}
            emptyInput={emptyInput}
            setEmptyInput={setEmptyInput}
            setTableUpdate={setTableUpdate}
            tableUpdate={tableUpdate}
          />
                    )}

          {textSearch && <SearchFilter onTextSearch={handleTextSearch} />}

          <MenuItem
            onClick={e => handleSelect(e, field)}
            key="Select/Deselect All"
            style={menuItemStyle}
          >
            <span>
              <Checkbox
                checked={selectAll}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              Select/Deselect All
            </span>
          </MenuItem>
          {menuItems.map(item => (
            <MenuItem
              onClick={e => handleSelect(e, field)}
              key={item}
              style={menuItemStyle}
            >
              <span>
                <Checkbox
                  checked={list.indexOf(item) !== -1}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                {item}
              </span>
            </MenuItem>
                    ))}
        </Menu>
            )}
      </React.Fragment>
    );
};

export default AnalyticsDropdown;
