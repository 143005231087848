/* eslint-disable max-len */
import React from "react";
import { Typography, Grid, Switch } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker
} from "@material-ui/pickers";

import { MuiThemeProvider, withStyles, createMuiTheme, useTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";

import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import { VERBIAGE } from "../../../utils/enums";

const InputSwitch = withStyles(() => ({
    root: {
        marginBottom: "0 !important",
        transform: "scaleX(-1)"
    }
}))(Switch);

const FormRenderField = props => {
    const {
        input: {
            type = "text",
            name,
            isMulti,
            numberType,
            selectOptions = [],
            switchLabels,
            ...inputProps
        },
        value,
        onChange,
        errors = {}
    } = props;

    const baseProps = {
        noInputLabel: true,
        error: !!errors[name],
        helperText: errors[name]?.message,
        ...inputProps
    };
    if (type === "number") {
        baseProps.inputProps = {
            step: numberType === "double" ? 0.1 : 1,
            min: 0
        };
    }

    // Override border color of datetime picker
    const theme = useTheme();
    const dateTheme = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            }
        }
    });

    switch (type) {
        case "select":
            return (
                <DropdownSelect
                  id={name}
                  name={value}
                  onChange={onChange}
                  options={selectOptions}
                  isMulti={isMulti}
                  closeMenuOnSelect
                  noInputLabel
                  {...baseProps}
                />
            );
        case "switch":
            if (switchLabels) {
                return (
                    <Typography component="div">
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>{switchLabels.true}</Grid>
                            <Grid item>
                                <InputSwitch
                                  id={name}
                                  checked={value}
                                  onChange={onChange}
                                  color="primary"
                                />
                            </Grid>
                            <Grid item>{switchLabels.false}</Grid>
                        </Grid>
                    </Typography>
                );
            }
            return <InputSwitch id={name} checked={value} onChange={onChange} color="primary" />;

        case "time":
            return (
                <MuiThemeProvider theme={dateTheme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardTimePicker
                          id={name}
                          ampm={false}
                          value={value}
                          onChange={onChange}
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TIME}
                          inputVariant="outlined"
                          InputLabelProps={{ shrink: false }}
                          format="HH:mm"
                          fullWidth
                          clearable
                        />
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            );

        case "date":
            return (
                <MuiThemeProvider theme={dateTheme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          id={name}
                          value={value}
                          onChange={onChange}
                          variant="dialog"
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_DATE}
                          inputVariant="outlined"
                          format="DD/MM/YYYY"
                          InputLabelProps={{ shrink: false }}
                          fullWidth
                        />
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            );
        case "datetime":
            return (
                <MuiThemeProvider theme={dateTheme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDateTimePicker
                          id={name}
                          value={value}
                          onChange={onChange}
                          variant="dialog"
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TIME}
                          inputVariant="outlined"
                          format="DD/MM/YYYY HH:mm"
                          InputLabelProps={{ shrink: false }}
                          fullWidth
                        />
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            );

        case "textarea":
            return (
                <TextInput
                  id={name}
                  value={value}
                  onChange={onChange}
                  multiline
                  rows={4}
                  {...baseProps}
                />
            );

        case "number":
        default:
    }

    return <TextInput type={type} id={name} value={value} onChange={onChange} {...baseProps} />;
};

export default FormRenderField;
