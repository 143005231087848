import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    withStyles,
    makeStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../styles/chillerTable.scss";
import HomeActions from "../../features/redux/HomeActions";
import { REFRESH_TIMER, VERBIAGE } from "../../../utils/enums/index";

const ChillerTable = props => {
    const { fetchChillerGauge, home } = props;

    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        fetchChillerGauge();
        const timer = setInterval(() => {
            fetchChillerGauge();
        }, timerValue);
        return () => clearInterval(timer);
    }, [fetchChillerGauge]);

    const StyledTableRow = withStyles(theme => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.type === "dark" ? "#414141" : "#FBFBFB"
            },
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.type === "dark" ? "#3A3A3A" : "#F6F6F6"
            }
        }
    }))(TableRow);

    const useStyles = makeStyles(theme => ({
        table: {
            fontSize: "1rem",
            height: "220px",
            marginTop: "5px",
            width: "96%",
            marginLeft: "2%",
            textAlign: "center",
            color: theme.palette.type === "dark" ? "#FFFFFF" : "#A0A0A0"
        },
        head: {
            fontWeight: "500",
            color: theme.palette.type === "dark" ? "#FFFFFF" : "#707070",
            background: theme.palette.type === "dark" ? "#2F2F2F" : "#F7F7F7",
            textAlignLast: "center"
        },
        paper: {
            // paddingTop: "0.8vh",
            width: "100%",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "white"
        },
        tr: {
            height: "40px",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        firstRow: {
            borderRadius: "4px 0px 0px 0px"
        },
        lastRow: {
            borderRadius: "0px 4px 0px 0px"
        }
    }));

    const classes = useStyles();

    return (
      <Paper elevation={0} className={classes.paper}>
        {home && home.chillerGauge && home.chillerGauge.table && (
        <Table className={classes.table} stickyHeader>
          <TableHead className={classes.head}>
            <TableRow className={classes.tr}>
              <td className={classes.firstRow}>
                {VERBIAGE.SYSTEM_OVERVIEW.EQUIPMENT}
              </td>
              <td align="right">{VERBIAGE.SYSTEM_OVERVIEW.EFFICIENCY}</td>
              <td align="right" className={classes.lastRow}>
                {VERBIAGE.SYSTEM_OVERVIEW.ACTIVE_POWER}
              </td>
            </TableRow>
          </TableHead>
          <TableBody>
            {home.chillerGauge.table.map(table => (
              <StyledTableRow key={table.equipment}>
                <td>{table.equipment}</td>
                <td className={ChillerTable.styles.col}>{table.efficiency}</td>
                <td className={ChillerTable.styles.col}>{table.active_power}</td>
              </StyledTableRow>
                        ))}
          </TableBody>
        </Table>
            )}
      </Paper>
    );
};

ChillerTable.styles = {
    row: "webclient__chillerTable-row",
    col: "webclient__chillerTable-col"
};
const mapStateToProps = state => ({
    home: state.home
});
const mapDispatchToProps = dispatch => ({
    fetchChillerGauge: () => {
        dispatch(HomeActions.fetchChillerGauge());
    }
});
ChillerTable.propTypes = {
    fetchChillerGauge: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChillerTable));
