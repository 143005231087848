/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles
} from "@material-ui/core";
import { VERBIAGE } from "../../../utils/enums";
import URL from "../../../utils/URL";
import SiteOverviewChart from "./SiteOverviewChart";
import api from "../../../utils/API";

const EditAlarmConfigTable = props => {
    const { open, setOpen, selectedParam, data } = props;

    const [configValues, setConfigValues] = useState([]);
    const [url, setUrl] = useState("");

    const [worksheets, setWorksheets] = useState([]);
    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    const handleSubmit = async () => {
        try {
            const config = {
                data: configValues
            };
            const link = `${URL.DATA.ANALYTICS.ALARM_CONFIG.TABLE}/${selectedParam}`;
            await api.put(link, config, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            setOpen(false);
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    };

    useEffect(() => {
        if (data) {
            data.forEach(row => {
                if (selectedParam === row.param) {
                    setConfigValues(row.data);
                }
            });
        }
    }, [data, selectedParam]);

    useEffect(() => {
        let isMounted = true;
        if (selectedParam) {
            if (isMounted) {
                setUrl(URL.DATA.ANALYTICS.ALARM_CONFIG.CHART + selectedParam);
            }
        }

        return () => (isMounted = false);
    }, [selectedParam]);

    const useStyles = makeStyles(theme => ({
        dialogPaper: {
            // minHeight: "700px"
        },
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
      <Dialog classes={{ paper: classes.dialogPaper }} open={open} fullWidth maxWidth="lg">
        <DialogTitle>Edit the parameter configurations below</DialogTitle>
        <DialogContent>
          <SiteOverviewChart
            url={url}
            addWorksheet={addWorksheet}
            double
            tall
            xs={12}
            configValues={configValues}
            setConfigValues={setConfigValues}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
                        handleSubmit();
                    }}
          >
            {VERBIAGE.BUTTONS.SUBMIT}
          </Button>
          <Button
            className={classes.error}
            onClick={() => {
                        setOpen(false);
                    }}
          >
            {VERBIAGE.BUTTONS.CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default EditAlarmConfigTable;
