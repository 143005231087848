/* eslint-disable max-len */
import AnalyticsActionTypes from "./AnalyticsActionTypes";
import api from "../../utils/API";
import URL from "../../utils/URL";

const config = {
    headers: {
        // "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        "x-auth-token": ""
    }
};

function constructHeader() {
    const token = localStorage.getItem("token");

    config.headers["x-auth-token"] = `${token}`;
    return config;
}

const AnalyticsActions = {
    getFaultTableData: (data, filterType) => ({
        type: AnalyticsActionTypes.GET_FAULTTABLE,
        data,
        filterType
    }),
    getFaultTable: type => dispatch => {
        let filterType;
        if (type === "apply") {
            filterType = true;
        } else {
            filterType = false;
        }
        constructHeader();
        api.get(URL.DATA.ANALYTICS.FAULT_TABLE, config)
            .then(response => {
                dispatch(AnalyticsActions.getFaultTableData(response.data, filterType));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setInterval: data => ({
        type: AnalyticsActionTypes.UPDATE_FAULTTABLE,
        data
    }),
    updateFaultInterval: () => dispatch => {
        constructHeader();
        api.put(
            URL.DATA.ANALYTICS.UPDATE_FAULT_TABLE,
            {
                interval: localStorage.getItem("interval"),
                intervalType: localStorage.getItem("intervalType")
            },
            config,
        )
            .then(() => {
                dispatch(AnalyticsActions.getFaultTable("apply"));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setAnalyticChips: data => ({
        type: AnalyticsActionTypes.SET_ANALYTICS_CHIPS,
        data
    }),
    fetchAnalyticsChips: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.ANALYTICS.CHIP, config)
            .then(response => {
                dispatch(AnalyticsActions.setAnalyticChips(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setLabel: data => ({
        type: AnalyticsActionTypes.SET_LABEL,
        data
    }),
    handleLabel: fault => async dispatch => {
        constructHeader();
        await api
            .post(
                `${URL.DATA.ANALYTICS.LABEL}/${fault.time}/${fault.code}/${fault.parameter}`,
                {
                    label: fault.label.replace(/(\n\r|\n|\r)/gm, ""),
                    duration: {
                        value: fault.duration.value,
                        unit: fault.duration.unit
                    }
                },
                config,
            )
            .then(() => {
                dispatch(AnalyticsActions.getFaultTable());
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    fetchOldNotes: noteId => async dispatch => {
        try {
            constructHeader();
            const offset = new Date().getTimezoneOffset();
            // console.log("offset : ", offset);
            const response = await api.get(
                `${URL.DATA.ANALYTICS.NOTES}/${noteId}/${offset}`,
                config,
            );
            dispatch({ type: AnalyticsActionTypes.SET_NOTES, data: response.data });
        } catch (error) {
            // handle error
        }
    },
    handleNotes: (start, code, noteId, notes, parameter) => async dispatch => {
        try {
            constructHeader();
            await api.post(
                `${URL.DATA.ANALYTICS.NOTES}/${start}/${code}/${parameter}`,
                {
                    noteId,
                    notes: notes.replace(/(\n\r|\n|\r)/gm, "")
                },
                config,
            );
            dispatch(AnalyticsActions.getFaultTable());
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    },
    setSeverityChip: data => ({
        type: AnalyticsActionTypes.SET_CHIP_SEVERITY,
        data
    }),
    fetchSeverity: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.ANALYTICS.SEVERITY_CHIP, config)
            .then(response => {
                dispatch(AnalyticsActions.setSeverityChip(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setNotesChips: data => ({
        type: AnalyticsActionTypes.SET_NOTES_CHIPS,
        data
    }),
    fetchNotesChips: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.ANALYTICS.NOTES_CHIP, config)
            .then(response => {
                dispatch(AnalyticsActions.setNotesChips(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setUserTagsChips: data => ({
        type: AnalyticsActionTypes.SET_USERTAGS_CHIPS,
        data
    }),
    fetchUserTagsChips: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.ANALYTICS.USER_TAGS_CHIP, config)
            .then(response => {
                dispatch(AnalyticsActions.setUserTagsChips(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setChartPosition: data => ({
        type: AnalyticsActionTypes.GET_CHART_POSITION,
        data
    }),
    fetchChartPosition: (code, parameter) => async dispatch => {
        try {
            constructHeader();
            const response = await api.get(
                `${URL.DATA.ANALYTICS.GET_CHARTS}/${code}/${parameter}`,
                config,
            );
            if (response.data) {
                const chartData = {
                    charts: response.data,
                    code,
                    parameter
                };
                dispatch(AnalyticsActions.setChartPosition(chartData));
            }
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    },
    setAlarmConfig: data => ({
        type: AnalyticsActionTypes.SET_ALARM_CONFIG,
        data
    }),
    fetchAlarmConfig: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.ANALYTICS.ALARM_CONFIG.TABLE, config)
            .then(response => {
                dispatch(AnalyticsActions.setAlarmConfig(response.data.parameters));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    }
};

export default AnalyticsActions;
