import PlantSActionTypes from "./PlantSActionTypes";

const initialState = {
    equipment: "",
    image: ""
};

const PlantSReducer = (state = initialState, action) => {
    switch (action.type) {
        case PlantSActionTypes.GET_EQUIPMENT:
            localStorage.setItem("lastUpdate", action.data.lastUpdate);
            localStorage.setItem("utcOffset", action.data.utcOffset);
            localStorage.setItem("timezone", action.data.timezone);
            return {
                ...state,
                equipment: action.data
            };
        case PlantSActionTypes.GET_IMAGE:
            return {
                ...state,
                image: action.data
            };
        default:
            return state;
    }
};

export default PlantSReducer;
