import React from "react";
import ParamConfigTable from "./ParamConfigTable";
import ChartMapTable from "./ChartMapTable";
import FaultCodeTable from "./FaultCodeTable";
import NameMapTable from "./NameMapTable";

const AnalyticsSettingsTabs = props => {
    const { value } = props;

    return (
      <React.Fragment>
        {value === "faultCode" && <FaultCodeTable />}

        {value === "paramConfig" && <ParamConfigTable />}

        {value === "chartMap" && <ChartMapTable />}

        {value === "nameMap" && <NameMapTable />}
      </React.Fragment>
    );
};

export default AnalyticsSettingsTabs;
