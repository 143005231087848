import React, { useState, useRef, useEffect, useCallback } from "react";

import DwhDataTable from "../DwhDataTable";
import EditSiteOverview from "./EditSiteOverview";

import API from "../../../utils/API";
import URL from "../../../utils/URL";

const SiteOverview = () => {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [loading, setLoading] = useState(false);

    const editRef = useRef();

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);

            const response = await API.get(URL.DATA.DWH.SITE_DETAILS, {
                params: {
                    _sort: "id",
                    _order: "ASC"
                },
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            if (response.data) {
                setData(response.data);
            }
        } catch (err) {
            if (err.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleClickDataTable = (e, { action, params }) => {
        switch (action) {
            case "add":
                editRef.current.init();
                setIsEdit(false);
                setOpen(true);
                break;
            case "edit":
                editRef.current.update({
                    ...data[params.dataIndex]
                });
                setIsEdit(true);
                setOpen(true);
                break;
            default:
        }
    };

    const handleSaveSuccess = () => {
        fetchData();
    };

    const columns = [
        { actions: ["edit"] },
        { label: "BBP ID", name: "bbpId", options: { display: false } },
        { label: "Site ID", name: "siteId" },
        { label: "Site Name", name: "siteName" },
        { label: "Site Logo", name: "siteLogo", options: { display: false }, narrow: true },
        { label: "Person In Charge", name: "personInCharge" },
        { label: "Site Address", name: "siteAddress", narrow: true },
        { label: "Postal Code", name: "sitePostalCode" },
        { label: "Country", name: "siteCountryCode" },
        { label: "Remarks", name: "remarks", narrow: true },
        { label: "Industry Type", name: "industryType", narrow: true },
        { label: "Installed Chiller Qty", name: "chInstalledQty" },
        { label: "AC System Type", name: "acSysType" },
        { label: "Configuration", name: "configuration" },
        { label: "Created Date", name: "createdAt", _options: { type: "date" }, narrow: true },
        { label: "Updated Date", name: "updatedAt", _options: { type: "date" }, narrow: true }
    ];

    const options = {
        viewColumns: true
    };

    return (
        <>
            <DwhDataTable
              name="Site Overview"
              loading={loading}
              data={data}
              columns={columns}
              options={options}
              onClick={handleClickDataTable}
            />
            <EditSiteOverview
              ref={editRef}
              isEdit={isEdit}
              open={open}
              setOpen={setOpen}
              onSuccess={handleSaveSuccess}
            />
        </>
    );
};

export default SiteOverview;
