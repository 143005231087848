import React, { useState, useMemo } from "react";
import { Collapse, List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import MenuIcon from "../MenuIcon";
import { VERBIAGE } from "../../../../utils/enums";
import ICONS from "../../../../utils/enums/IconData";

const VIEW_ALL_PERMISSIONS = ["root", "admin_level", "admin_access", "developer_access"];

const AnalyticsMenu = ({ permission, narrow, isMobile, isTablet }) => {
    const activeSite = localStorage.getItem("activeSite");
    const analyticsMainMenu = useMemo(() => {
        const isAnalyticsDeveloped = localStorage.getItem("isAnalyticsDeveloped") === "true";
        const isReportsDeveloped = localStorage.getItem("isReportsDeveloped") === "true";

        const menu = [
            {
                path: VERBIAGE.DASHBOARD_PATH.SITE_OVERVIEW,
                src: ICONS.SITE_OVERVIEW,
                text: VERBIAGE.MENU_TEXT.SYSTEM,
                default: true
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.PORTFOLIO_VIEW,
                src: ICONS.GRID,
                text: VERBIAGE.MENU_TEXT.PORTFOLIO_VIEW,
                default: true
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.PLANT_OVERVIEW,
                src: ICONS.ZAP,
                text: VERBIAGE.MENU_TEXT.PLANT_SCHEMATICS,
                default: true
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.ANALYTICS_OVERVIEW,
                src: ICONS.ALGORITHM,
                text: VERBIAGE.MENU_TEXT.ANALYTICS,
                isAnalyticsDeveloped: true,
                permissions: ["asset_access", "bbp_access", "plant_level", "customer_access"]
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.REPORTS,
                src: ICONS.REPORTS,
                text: VERBIAGE.MENU_TEXT.REPORTS,
                isReportsDeveloped: true,
                permissions: ["asset_access", "bbp_access", "plant_level", "customer_access"]
            }
        ];

        // if (activeSite === "10022") {
        //     menu.push({
        //         path: VERBIAGE.DASHBOARD_PATH.WATER_TREATMENT,
        //         src: ICONS.DROPLET,
        //         text: "Water Treatment",
        //         default: true
        //     });
        // }

        if (VIEW_ALL_PERMISSIONS.indexOf(permission) > -1) {
            return menu;
        }

        return menu.filter(m => {
            if (m.default) return true;
            if (m?.permissions.indexOf(permission) > -1) {
                if (m.isAnalyticsDeveloped && isAnalyticsDeveloped) return true;
                if (m.isReportsDeveloped && isReportsDeveloped) return true;
            }
            return false;
        });
    }, [permission]);

    const analyticsPrivilegeMenu = useMemo(() => {
        const isAutoMLActive = localStorage.getItem("isAutoMLActive") === "true";
        const menu = [
            {
                path: VERBIAGE.DASHBOARD_PATH.PID,
                src: ICONS.PID,
                text: VERBIAGE.MENU_TEXT.PID
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.PLANT_SETTINGS,
                src: ICONS.PLANT,
                text: VERBIAGE.MENU_TEXT.PLANT_SETTINGS
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.MAINTENANCE_SETTINGS,
                src: ICONS.PLANT,
                text: VERBIAGE.MENU_TEXT.MAINTENANCE_SETTINGS
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.ANALYTICS_SETTINGS,
                src: ICONS.ANALYTICS,
                text: VERBIAGE.MENU_TEXT.ANALYTICS_SETTINGS
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.ADMIN_SETTINGS,
                src: ICONS.ADMIN,
                text: VERBIAGE.MENU_TEXT.ADMIN_SETTINGS
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.SITE_ONBOARDING,
                src: ICONS.SITE_ONBOARDING,
                text: VERBIAGE.MENU_TEXT.SITE_ONBOARDING
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.MONITORING,
                src: ICONS.MONITORING,
                text: VERBIAGE.MENU_TEXT.MONITORING
            },
            {
                path: VERBIAGE.DASHBOARD_PATH.REPORTING,
                src: ICONS.REPORTS,
                text: VERBIAGE.MENU_TEXT.REPORTING
            }
        ];
        if (isAutoMLActive) {
            menu.push({
                path: VERBIAGE.DASHBOARD_PATH.ML_SETTINGS,
                src: ICONS.ALGORITHM,
                text: VERBIAGE.MENU_TEXT.ML_SETTINGS
            });
        }

        if (VIEW_ALL_PERMISSIONS.indexOf(permission) > -1) {
            return menu;
        }

        return [];
    }, [permission]);

    const isOpen = localStorage.getItem("open") === "true";

    const [userSettingsOpen, setUserSettingsOpen] = useState(false);
    const [waterTreatmentOpen, setWaterTreatmentOpen] = useState(false);

    const handleUserSettings = () => {
        setUserSettingsOpen(!userSettingsOpen);
        localStorage.setItem("userSettingsOpen", !userSettingsOpen);
    };

    const handleWaterTreatment = () => {
        setWaterTreatmentOpen(!waterTreatmentOpen);
        localStorage.setItem("waterTreatmentOpen", !waterTreatmentOpen);
    };

    const useStyles = makeStyles(() => ({
        nested: {
            padding: "0 0 0 7px"
        },
        dividerFullWidth: {
            marginRight: "16px"
        }
    }));

    const classes = useStyles();

    return (
        <>
            {/* main menu */}
            {analyticsMainMenu.map(menu => (
                <MenuIcon
                  key={menu.path}
                  path={menu.path}
                  src={menu.src}
                  alt={menu.text}
                  text={menu.text}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
            ))}
            {/* user settings menu */}
            {narrow ? (
                <MenuIcon
                  path={VERBIAGE.DASHBOARD_PATH.CHANGE_PASSWORD}
                  src={ICONS.PASSWORD}
                  alt="adminsettings"
                  text={VERBIAGE.MENU_TEXT.CHANGE_PASSWORD}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
            ) : (
                <>
                    <MenuIcon
                      onClick={handleUserSettings}
                      src={ICONS.USER}
                      alt="usersettings"
                      text={VERBIAGE.MENU_TEXT.USER_SETTINGS}
                      isMobile={isMobile}
                      isTablet={isTablet}
                    />
                    <Collapse in={waterTreatmentOpen} timeout="auto">
                        <List disablePadding>
                            <ListItem button className={classes.nested}>
                                <MenuIcon
                                  path={VERBIAGE.DASHBOARD_PATH.CHANGE_PASSWORD}
                                  src={ICONS.PASSWORD}
                                  alt="adminsettings"
                                  text={VERBIAGE.MENU_TEXT.CHANGE_PASSWORD}
                                  isMobile={isMobile}
                                  isTablet={isTablet}
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                </>
            )}
            <Divider variant={isOpen ? "middle" : "fullWidth"} />
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
              align="right"
            >
                {isOpen ? "Privilege Access" : ""}
            </Typography>
            {/* privilage menu */}
            {analyticsPrivilegeMenu.map(menu => (
                <MenuIcon
                  key={menu.path}
                  path={menu.path}
                  src={menu.src}
                  alt={menu.text}
                  text={menu.text}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
            ))}

            {activeSite === "10003" ? (
                <>
                    <Divider variant={isOpen ? "middle" : "fullWidth"} />
                    <MenuIcon
                      onClick={handleWaterTreatment}
                      src={ICONS.DROPLET}
                      alt="waterTreatment"
                      text={VERBIAGE.MENU_TEXT.WATER_TREATMENT}
                      isMobile={isMobile}
                      isTablet={isTablet}
                    />
                    <Collapse in={waterTreatmentOpen} timeout="auto">
                        <List disablePadding>
                            <ListItem button className={classes.nested}>
                                <MenuIcon
                                  path={VERBIAGE.DASHBOARD_PATH.WATER_TREATMENT_SCHEMATICS}
                                  src={ICONS.ZAP}
                                  alt="waterTreatmentSchematics"
                                  text={VERBIAGE.MENU_TEXT.WATER_TREATMENT_SCHEMATICS}
                                  isMobile={isMobile}
                                  isTablet={isTablet}
                                />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <MenuIcon
                                  path={VERBIAGE.DASHBOARD_PATH.WATER_TREATMENT_DASHBOARD1}
                                  src={ICONS.GRID}
                                  alt="waterTreatmentDashboard1"
                                  text={VERBIAGE.MENU_TEXT.WATER_TREATMENT_DASHBOARD1}
                                  isMobile={isMobile}
                                  isTablet={isTablet}
                                />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <MenuIcon
                                  path={VERBIAGE.DASHBOARD_PATH.WATER_TREATMENT_DASHBOARD2}
                                  src={ICONS.GRID}
                                  alt="waterTreatmentDashboard2"
                                  text={VERBIAGE.MENU_TEXT.WATER_TREATMENT_DASHBOARD2}
                                  isMobile={isMobile}
                                  isTablet={isTablet}
                                />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <MenuIcon
                                  path={VERBIAGE.DASHBOARD_PATH.WATER_TREATMENT_REPORT}
                                  src={ICONS.REPORTS}
                                  alt="waterTreatmentReport"
                                  text={VERBIAGE.MENU_TEXT.WATER_TREATMENT_REPORT}
                                  isMobile={isMobile}
                                  isTablet={isTablet}
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                </>
            ) : (
                <div />
            )}
        </>
    );
};

export default AnalyticsMenu;
