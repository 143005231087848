/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import { Typography, CircularProgress } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { SelectMultipleFilterDataTable, DateRangeFilterDataTable } from "./dataTable";
import { toIntlDateTimeFormat } from "../../../utils/helpers";
import ReportingActions from "../../redux/ReportingActions";

const ReportingTable = () => {
    const {
        loading,
        missingData: { config, count, data }
    } = useSelector(state => state.reporting);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ReportingActions.getReportMisingData({}, true));
        return () => {
            // clear params
            dispatch(
                ReportingActions.getReportMisingData({
                    MISSING_PARAMS: null,
                    MISSING_TYPE: null,
                    STATUS: null,
                    SYS_TS_DATETIME_START: null,
                    SYS_TS_DATETIME_END: null
                }),
            );
        };
    }, [dispatch]);

    const missingDataColumns = useMemo(() => {
        return [
            { label: "SITE_ID", name: "SITE_ID", options: { filter: false, sort: false } },
            {
                label: "Params",
                name: "MISSING_PARAMS",
                options: {
                    display: "true",
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: v => v.map(type => `Params: ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: config.params
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            },
            {
                label: "Type",
                name: "MISSING_TYPE",
                options: {
                    display: "true",
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: v => v.map(type => `MISSING_TYPE: ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: config.types
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            },
            { label: "Notes", name: "NOTES", options: { filter: false, sort: false } },
            {
                label: "Start",
                name: "SYS_TS_DATETIME_START",
                options: {
                    filter: true,
                    filterType: "custom",
                    customBodyRender: value => {
                        return toIntlDateTimeFormat(new Date(value));
                    },
                    customFilterListOptions: {
                        render: v => {
                            const dfOptions = {
                                second: undefined,
                                timeZoneName: undefined
                            };
                            const from = v[0] ? toIntlDateTimeFormat(v[0], dfOptions) : null;
                            const to = v[1] ? toIntlDateTimeFormat(v[1], dfOptions) : null;
                            if (from && to) {
                                return [`Start From: ${from}`, `Start To: ${to}`];
                            }
                            if (from) {
                                return `Start From: ${from}`;
                            }
                            if (to) {
                                return `Start To: ${to}`;
                            }
                            return [];
                        },
                        update: (filterList, filterPos, index) => {
                            if (filterPos === 0) {
                                filterList[index].splice(filterPos, 1, "");
                            } else if (filterPos === 1) {
                                filterList[index].splice(filterPos, 1);
                            } else if (filterPos === -1) {
                                filterList[index] = [];
                            }
                            return filterList;
                        }
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = { filterList, onChange, index, column };
                            return <DateRangeFilterDataTable {...props} />;
                        }
                    }
                }
            },
            {
                label: "End",
                name: "SYS_TS_DATETIME_END",
                options: {
                    filter: true,
                    filterType: "custom",
                    customBodyRender: value => {
                        return toIntlDateTimeFormat(new Date(value));
                    },
                    customFilterListOptions: {
                        render: v => {
                            const dfOptions = {
                                second: undefined,
                                timeZoneName: undefined
                            };
                            const from = v[0] ? toIntlDateTimeFormat(v[0], dfOptions) : null;
                            const to = v[1] ? toIntlDateTimeFormat(v[1], dfOptions) : null;
                            if (from && to) {
                                return [`End From: ${from}`, `End To: ${to}`];
                            }
                            if (from) {
                                return `End From: ${from}`;
                            }
                            if (to) {
                                return `End To: ${to}`;
                            }
                            return [];
                        },
                        update: (filterList, filterPos, index) => {
                            if (filterPos === 0) {
                                filterList[index].splice(filterPos, 1, "");
                            } else if (filterPos === 1) {
                                filterList[index].splice(filterPos, 1);
                            } else if (filterPos === -1) {
                                filterList[index] = [];
                            }
                            return filterList;
                        }
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = { filterList, onChange, index, column };
                            return <DateRangeFilterDataTable {...props} />;
                        }
                    }
                }
            },
            {
                label: "Status",
                name: "STATUS",
                options: {
                    display: "true",
                    filter: true,
                    filterType: "custom",
                    customFilterListOptions: {
                        render: v => v.map(type => `Status: ${type}`)
                    },
                    filterOptions: {
                        display: (filterList, onChange, index, column) => {
                            const props = {
                                filterList,
                                onChange,
                                index,
                                column,
                                optionValues: config.status
                            };
                            return <SelectMultipleFilterDataTable {...props} />;
                        }
                    }
                }
            }
        ];
    }, [config]);

    const missingDataOptions = {
        selectableRows: "none",
        print: false,
        download: false,
        serverSide: true,
        rowsPerPageOptions: [10, 20, 50, 100],
        count,
        customSearchRender: debounceSearchRender(500),
        onSearchChange: searchText => {
            dispatch(ReportingActions.getReportMisingData({ NOTES: searchText }));
        },
        onFilterChange: (column, filterList, type, changedColumnIndex) => {
            if (type === "reset") {
                dispatch(
                    ReportingActions.getReportMisingData({
                        MISSING_PARAMS: null,
                        MISSING_TYPE: null,
                        STATUS: null,
                        SYS_TS_DATETIME_START: null,
                        SYS_TS_DATETIME_END: null
                    }),
                );
                return;
            }
            const value = filterList[changedColumnIndex]?.map(v => {
                if (v instanceof Date) {
                    return v.valueOf();
                }
                return v;
            });
            dispatch(
                ReportingActions.getReportMisingData({
                    [column]: value
                }),
            );
        },
        onChangePage: currentPage => {
            dispatch(ReportingActions.getReportMisingData({ page: currentPage + 1 }));
        },
        onChangeRowsPerPage: numberOfRows => {
            dispatch(ReportingActions.getReportMisingData({ perPage: numberOfRows }));
        },
        onColumnSortChange: (changedColumn, direction) => {
            dispatch(
                ReportingActions.getReportMisingData({
                    sortBy: changedColumn,
                    orderBy: direction
                }),
            );
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress size={24} />
                ) : (
                    "Sorry, there is no matching data to display"
                )
            }
        }
    };

    return (
        <>
            <MuiThemeProvider>
                <MUIDataTable
                  title={(
                        <Typography variant="h6">
                            Missing Data
                            {loading && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                        marginLeft: 15,
                                        position: "relative",
                                        top: 4
                                    }}
                                />
                            )}
                        </Typography>
                      )}
                  data={data}
                  columns={missingDataColumns}
                  options={missingDataOptions}
                />
            </MuiThemeProvider>
        </>
    );
};

export default ReportingTable;
