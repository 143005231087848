import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    RuleBasedOptimizationCHWPConfigActions,
    RuleBasedOptimizationCHWPMinMaxLoadActions,
    RuleBasedOptimizationCHWPMinMaxTimeActions
} from "../../../redux/RuleBasedOptimizationCHWPActions";

const useRuleBasedOptimizationCHWP = () => {
    const { config, minMaxLoad, minMaxTime } = useSelector(
        state => state.ruleBasedOptimizationCHWP,
    );

    const dispatch = useDispatch();

    const fetchConfig = useCallback(
        force => {
            dispatch(RuleBasedOptimizationCHWPConfigActions.fetch(force));
        },
        [dispatch],
    );
    const saveConfig = useCallback(
        data => {
            dispatch(RuleBasedOptimizationCHWPConfigActions.save(data));
        },
        [dispatch],
    );

    const fetchMinMaxLoad = useCallback(
        force => {
            dispatch(RuleBasedOptimizationCHWPMinMaxLoadActions.fetch(force));
        },
        [dispatch],
    );
    const deleteMinMaxLoad = useCallback(
        id => {
            dispatch(RuleBasedOptimizationCHWPMinMaxLoadActions.delete(id));
        },
        [dispatch],
    );

    const fetchMinMaxTime = useCallback(
        force => {
            dispatch(RuleBasedOptimizationCHWPMinMaxTimeActions.fetch(force));
        },
        [dispatch],
    );
    const deleteMinMaxTime = useCallback(
        id => {
            dispatch(RuleBasedOptimizationCHWPMinMaxTimeActions.delete(id));
        },
        [dispatch],
    );

    return {
        config,
        fetchConfig,
        saveConfig,
        minMaxLoad,
        fetchMinMaxLoad,
        deleteMinMaxLoad,
        minMaxTime,
        fetchMinMaxTime,
        deleteMinMaxTime
    };
};

export default useRuleBasedOptimizationCHWP;
