/* eslint-disable no-unused-vars  */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import URL from "../../../utils/URL";
import SiteOverviewChart from "./SiteOverviewChart";
import LifecycleTabs from "../Analytics/LifecycleTabs";
import api from "../../../utils/API";

const EnergyCycleChart = props => {
    const { classes, tabStyle } = props;

    // const [url, setUrl] = useState(URL.DATA.LIFE_CYCLE.HEALTH_SCORE);
    const [value, setValue] = useState("overview");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [worksheets, setWorksheets] = useState([]);
    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    // useEffect(() => {
    //     let newUrl;
    //     // if (lifecycleChart === "Health Status") {
    //     // newUrl = URL.DATA.LIFE_CYCLE.HEALTH_STATUS;
    //     // } else
    //     if (lifecycleChart === "Chiller") {
    //         newUrl = URL.DATA.LIFE_CYCLE.HEALTH_SCORE;
    //     } else if (lifecycleChart === "Chiller Pump") {
    //         newUrl = URL.DATA.LIFE_CYCLE.CHILLER_PUMP;
    //     } else if (lifecycleChart === "Condenser Pump") {
    //         newUrl = URL.DATA.LIFE_CYCLE.CONDENSOR_PUMP;
    //     }
    //     setUrl(newUrl);
    // }, [lifecycleChart]);

    const { isLiveData, defaultDate } = localStorage;
    const [combinedChiller, setCombinedChiller] = useState(false);
    const [combinedCT, setCombinedCT] = useState(false);
    const [combinedCHWP, setCombinedCHWP] = useState(false);
    const [combinedCDWP, setCombinedCDWP] = useState(false);
    const [chillerChartList, setChillerChartList] = useState([]);
    const [ctChartList, setctChartList] = useState([]);
    const [chwpChartList, setChwpChartList] = useState([]);
    const [cdwpChartList, setCdwpChartList] = useState([]);

    const initialFilter = ws => {
        if (isLiveData === "false") {
            let date = new Date(parseInt(defaultDate, 10));
            date = date.getTime() - date.getTimezoneOffset() * 60 * 1000 - 1;
            // Skip Equipment Lifecycle Management chart
            if (ws.getName() === "Chiller 1") {
                return;
            }
            ws.applyRelativeDateFilterAsync("SYS_TS_DATETIME", {
                anchorDate: new Date(date),
                periodType: "Minute",
                rangeType: "LASTN",
                rangeN: 3 * 24 * 60
            });
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(date));
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(date));
        } else {
            // const lastUpdate = localStorage.getItem("lastUpdate");
            // let date = new Date(lastUpdate);
            // date = date.getTime() - date.getTimezoneOffset() * 60 * 1000 - 1;
            // // Skip Equipment Lifecycle Management chart
            // if (ws.getName() === "Chiller 1") {
            //     return;
            // }
            // ws.applyRelativeDateFilterAsync("SYS_TS_DATETIME", {
            //     anchorDate: new Date(date),
            //     periodType: "Minute",
            //     rangeType: "LASTN",
            //     rangeN: 3 * 24 * 60
            // });
            // ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(date));
            // ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(date));
        }
    };

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const list = [];
                let lastItem = "";
                const response = await api.get(URL.DATA.LIFE_CYCLE.CHILLER.LIST, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                response.data.forEach(i => {
                    const url = `${URL.DATA.LIFE_CYCLE.CHART}/CHILLER/${i}`;
                    list.push(url);
                    lastItem = i;
                });
                if (isMounted) {
                    if (list.length === 1 && lastItem === 0) {
                        setCombinedChiller(true);
                    }
                    setChillerChartList(list);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();

        return () => (isMounted = false);
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const list = [];
                let lastItem = "";
                const response = await api.get(URL.DATA.LIFE_CYCLE.CHWP.LIST, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                response.data.forEach(i => {
                    const url = `${URL.DATA.LIFE_CYCLE.CHILLER.CHART}/CHWP/${i}`;
                    list.push(url);
                    lastItem = i;
                });
                if (isMounted) {
                    if (list.length === 1 && lastItem === 0) {
                        setCombinedCHWP(true);
                    }
                    setChwpChartList(list);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();

        return () => (isMounted = false);
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const list = [];
                let lastItem = "";
                const response = await api.get(URL.DATA.LIFE_CYCLE.CDWP.LIST, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                response.data.forEach(i => {
                    const url = `${URL.DATA.LIFE_CYCLE.CHART}/CDWP/${i}`;
                    list.push(url);
                    lastItem = i;
                });
                if (isMounted) {
                    if (list.length === 1 && lastItem === 0) {
                        setCombinedCDWP(true);
                    }
                    setCdwpChartList(list);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();

        return () => (isMounted = false);
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const list = [];
                let lastItem = "";
                const response = await api.get(URL.DATA.LIFE_CYCLE.CT.LIST, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                response.data.forEach(i => {
                    const url = `${URL.DATA.LIFE_CYCLE.CHART}/CT/${i}`;
                    list.push(url);
                    lastItem = i;
                });
                if (isMounted) {
                    if (list.length === 1 && lastItem === 0) {
                        setCombinedCT(true);
                    }
                    setctChartList(list);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();

        return () => (isMounted = false);
    }, []);

    return (
      <React.Fragment>
        <LifecycleTabs
          classes={classes}
          tabStyle={tabStyle}
          value={value}
          handleChange={handleChange}
        />

        {value === "overview" && (
        <Grid container spacing={2}>
          <SiteOverviewChart
            url={URL.DATA.LIFE_CYCLE.HEALTH_STATUS}
            addWorksheet={addWorksheet}
            xs={12}
            tall
            initialFilter={initialFilter}
          />
        </Grid>
            )}

        {value === "chiller" &&
                (combinedChiller === true ? (
                  <Grid container spacing={2}>
                    <SiteOverviewChart
                      url={URL.DATA.LIFE_CYCLE.CHILLER.CHART_COMBINED}
                      addWorksheet={addWorksheet}
                      xs={12}
                            // md={6}
                      combined
                    />
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    {chillerChartList.map(link => {
                            return (
                              <SiteOverviewChart
                                key={link}
                                url={link}
                                addWorksheet={addWorksheet}
                                xs={12}
                                md={6}
                                tall
                              />
                            );
                        })}
                  </Grid>
                ))
            // <Grid container spacing={2}>
            //   <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CHILLER.CH_1}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     // md={6}
            //     combined
            //   />
            //   {/* <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CHILLER.CH_2}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     md={6}
            //     tall
            //   />
            //   <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CHILLER.CH_3}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     md={6}
            //     tall
            //   />
            //   <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CHILLER.CH_4}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     md={6}
            //     tall
            //   /> */}
            // </Grid>
            }

        {value === "coolingTower" &&
                (combinedCT === true ? (
                  <Grid container spacing={2}>
                    <SiteOverviewChart
                      url={URL.DATA.LIFE_CYCLE.CT.CHART_COMBINED}
                      addWorksheet={addWorksheet}
                      xs={12}
                            // md={6}
                      combined
                    />
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    {ctChartList.map(link => {
                            return (
                              <SiteOverviewChart
                                key={link}
                                url={link}
                                addWorksheet={addWorksheet}
                                xs={12}
                                md={6}
                                tall
                              />
                            );
                        })}
                  </Grid>
                ))
            // <Grid container spacing={2}>
            //   <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CT.CT_1}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     // md={6}
            //     combined
            //   />
            //   {/* <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CT.CT_2}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     md={6}
            //     tall
            //   /> */}
            // </Grid>
            }

        {value === "chwPump" &&
                (combinedCHWP === true ? (
                  <Grid container spacing={2}>
                    <SiteOverviewChart
                      url={URL.DATA.LIFE_CYCLE.CHWP.CHART_COMBINED}
                      addWorksheet={addWorksheet}
                      xs={12}
                            // md={6}
                      combined
                    />
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    {chwpChartList.map(link => {
                            return (
                              <SiteOverviewChart
                                key={link}
                                url={link}
                                addWorksheet={addWorksheet}
                                xs={12}
                                md={6}
                                tall
                              />
                            );
                        })}
                  </Grid>
                    // <Grid container spacing={2}>
                    //   <SiteOverviewChart
                    //     url={URL.DATA.LIFE_CYCLE.CHWR_PUMP.PUMP_1}
                    //     addWorksheet={addWorksheet}
                    //     xs={12}
                    //     md={6}
                    //     tall
                    //   />
                    //   <SiteOverviewChart
                    //     url={URL.DATA.LIFE_CYCLE.CHWR_PUMP.PUMP_2}
                    //     addWorksheet={addWorksheet}
                    //     xs={12}
                    //     md={6}
                    //     tall
                    //   />
                    //   <SiteOverviewChart
                    //     url={URL.DATA.LIFE_CYCLE.CHWR_PUMP.PUMP_3}
                    //     addWorksheet={addWorksheet}
                    //     xs={12}
                    //     md={6}
                    //     tall
                    //   />
                    //   <SiteOverviewChart
                    //     url={URL.DATA.LIFE_CYCLE.CHWR_PUMP.PUMP_4}
                    //     addWorksheet={addWorksheet}
                    //     xs={12}
                    //     md={6}
                    //     tall
                    //   />
                    // </Grid>
                ))}

        {value === "cdwPump" &&
                (combinedCDWP === true ? (
                  <Grid container spacing={2}>
                    <SiteOverviewChart
                      url={URL.DATA.LIFE_CYCLE.CDWP.CHART_COMBINED}
                      addWorksheet={addWorksheet}
                      xs={12}
                            // md={6}
                      combined
                    />
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    {cdwpChartList.map(link => {
                            return (
                              <SiteOverviewChart
                                key={link}
                                url={link}
                                addWorksheet={addWorksheet}
                                xs={12}
                                md={6}
                                tall
                              />
                            );
                        })}
                  </Grid>
                ))
            // <Grid container spacing={2}>
            //   <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CDWS_PUMP.PUMP_1}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     md={6}
            //     // combined
            //     tall
            //   />
            //   <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CDWS_PUMP.PUMP_2}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     md={6}
            //     tall
            //   />
            //   <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CDWS_PUMP.PUMP_3}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     md={6}
            //     tall
            //   />
            //   <SiteOverviewChart
            //     url={URL.DATA.LIFE_CYCLE.CDWS_PUMP.PUMP_4}
            //     addWorksheet={addWorksheet}
            //     xs={12}
            //     md={6}
            //     tall
            //   />
            // </Grid>
            }
      </React.Fragment>
    );
};

export default EnergyCycleChart;
