/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useRef } from "react";
import {
    Accordion,
    AccordionSummary,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from "file-saver";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { EditOutlined, Publish, ExpandMore } from "@material-ui/icons";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import { VERBIAGE } from "../../../utils/enums";
import CSVUpload from "./CSVUpload";
import EditSchematicsTable from "./EditSchematicsTable";
import CustomFooter from "./CustomFooter";

const moment = require("moment");

const SchematicsTable = () => {
    const [data, setData] = useState([]);

    const handleCsv = () => {
        const csvData = data.map(row => {
            const csvRow = {};
            csvRow.siteId = row.siteId;
            csvRow.eqptId = row.eqptId;
            csvRow.label = row.label;
            csvRow.x_axis = row.x_axis;
            csvRow.y_axis = row.y_axis;
            csvRow.zoom = row.zoom;
            csvRow.labelZoom = row.labelZoom;
            csvRow.labelAlign = row.labelAlign;
            csvRow.label_x_axis = row.label_x_axis;
            csvRow.label_y_axis = row.labelMargin;
            csvRow.dataZoom = row.dataZoom;
            csvRow.dataAlign = row.dataAlign;
            csvRow.data_x_axis = row.data_x_axis;
            csvRow.data_y_axis = row.dataMargin;
            csvRow.status = row.status;
            csvRow.eqpt_type = row.eqpt_type;
            csvRow.type = row.type;
            return csvRow;
        });
        const blob = new Blob([jsonToCSV(csvData)], { type: "text/csv;charset=utf-8;" });
        const now = moment().format("YYYYMMDD");
        saveAs(blob, `${csvData[0].siteId}_schematics_${now}.csv`);
    };

    const [open, setOpen] = useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    const isNarrow = useMediaQuery("(max-width: 600px)");

    const isMounted = useRef(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleUploadClick = () => {
        setUploadOpen(!uploadOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.PLANT_SETTINGS.SCHEMATICS, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (isMounted.current) {
                    setData(response.data);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open, uploadOpen]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: "16px"
        }
    }))(TableCell);

    const cols = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                      <IconButton
                        onClick={() => {
                                editRef.current.update({
                                    ...editRef.current.getConfig(),
                                    ...data[dataIndex]
                                });
                                setIsEdit(true);
                                handleClick();
                            }}
                      >
                        <EditOutlined />
                      </IconButton>
                    );
                }
            }
        },
        { label: "Site ID", name: "siteId" },
        { label: "Equipment ID", name: "eqptId" },
        { label: "Equipment Type", name: "eqpt_type" },
        { label: "Label", name: "label" },
        { label: "Type", name: "type" },
        { label: "X Axis", name: "x_axis" },
        { label: "Y Axis", name: "y_axis" },
        { label: "Zoom", name: "zoom" },
        { label: "Label Zoom", name: "labelZoom" },
        { label: "Label X Axis", name: "label_x_axis" },
        { label: "Label Y Axis", name: "labelMargin" },
        { label: "Label Align", name: "labelAlign" },
        { label: "Data Zoom", name: "dataZoom" },
        { label: "Data X Axis", name: "data_x_axis" },
        { label: "Data Y Axis", name: "dataMargin" },
        { label: "Data Align", name: "dataAlign" },
        { label: "Status", name: "status" }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        const rowData = data[dataIndex];
        return (
          <TableRow key={dataIndex}>
            <TableCell>
              <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell />
                        <StyledTableCell>
                          <IconButton
                            onClick={event => {
                                                    event.stopPropagation();
                                                    editRef.current.update({
                                                        ...editRef.current.getConfig(),
                                                        ...rowData
                                                    });
                                                    setIsEdit(true);
                                                    handleClick();
                                                }}
                            onFocus={event => event.stopPropagation()}
                          >
                            <EditOutlined />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>Site ID</StyledTableCell>
                        <StyledTableCell>{rowData.siteId}</StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>Equipment ID</StyledTableCell>
                        <StyledTableCell>{rowData.eqptId}</StyledTableCell>
                      </TableRow>
                      {isRowExpanded[dataIndex] ? (
                        <React.Fragment>
                          <TableRow>
                            <StyledTableCell>Label</StyledTableCell>
                            <StyledTableCell>{rowData.label}</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>{rowData.type}</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>X Axis</StyledTableCell>
                            <StyledTableCell>{rowData.x_axis}</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>Y Axis</StyledTableCell>
                            <StyledTableCell>{rowData.y_axis}</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>Zoom</StyledTableCell>
                            <StyledTableCell>{rowData.zoom}</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>{rowData.status}</StyledTableCell>
                          </TableRow>
                        </React.Fragment>
                                    ) : null}
                    </TableBody>
                  </Table>
                </AccordionSummary>
              </Accordion>
            </TableCell>
          </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "600px",
        filter: false,
        print: false,
        viewColumns: false,
        customToolbar: () => {
            return (
              <React.Fragment>
                <Tooltip title="Upload CSV">
                  <IconButton onClick={() => setUploadOpen(true)}>
                    <Publish />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            );
        },
        onDownload: () => {
            handleCsv();
            return false;
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
              <CustomFooter
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                changeRowsPerPage={changeRowsPerPage}
                changePage={changePage}
                textLabels={textLabels}
              />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    return (
      <div>
        <EditSchematicsTable
          isEdit={isEdit}
          open={open}
          handleClick={handleClick}
          setOpen={setOpen}
          ref={editRef}
        />
        <CSVUpload
          open={uploadOpen}
          setOpen={setUploadOpen}
          handleClick={handleUploadClick}
          url={URL.DATA.PLANT_SETTINGS.SCHEMATICS_BULK}
        />
        <div className={classes.table}>
          <MuiThemeProvider theme={theme}>
            <MUIDataTable
              title="Schematics Data"
              data={data}
              columns={cols}
              options={options}
            />
          </MuiThemeProvider>
        </div>
      </div>
    );
};

export default SchematicsTable;
