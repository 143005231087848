/* eslint-disable max-len */
/* eslint-disable array-callback-return  */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import {
    Grid,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    withStyles,
    makeStyles
} from "@material-ui/core";

import classNames from "classnames";
import PortfolioActions from "../../redux/PortfolioActions";
import { VERBIAGE } from "../../../utils/enums/index";
// import REFRESH_TIMER from "../../../utils/enums/Enum";
import Dropdown from "../components/Dropdown";

const useStyles = makeStyles(theme => ({
    table: {
        fontSize: "1rem",
        height: "130px",
        marginTop: "5px",
        width: "96%",
        marginLeft: "2%",
        textAlign: "center",
        color: theme.palette.type === "dark" ? "#FFFFFF" : "#A0A0A0",
        paddingBottom: "5px"
    },
    head: {
        fontWeight: "500",
        color: theme.palette.type === "dark" ? "#FFFFFF" : "#707070",
        background: theme.palette.type === "dark" ? "#2F2F2F" : "#F7F7F7",
        textAlignLast: "center"
    },
    tr: {
        height: "40px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    td: {
        height: "40px",
        fontWeight: "500"
    },
    firstRow: {
        borderRadius: "4px 0px 0px 0px"
    },
    lastRow: {
        borderRadius: "0px 4px 0px 0px"
    },
    grid: {
        padding: "5px 5px"
    },
    saving: {
        width: "100%",
        // height: "100%",
        // marginTop: "2vh",
        display: "flex"
    },
    paper: {
        padding: theme.spacing(0),
        width: "100%",
        // height: "100%",
        backgroundColor: theme.palette.type === "dark" ? "#201F25" : "white",
        color: theme === "dark" ? "white" : "black",
        alignItems: "center"
    },
    time: {
        marginTop: "1vh",
        fontSize: "12px"
    },
    field: {
        padding: "5px"
    },
    dropdown: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}`, // "1px solid #3865A7",
        borderRadius: "4px",
        height: "23px",
        width: "150px"
    },
    horizontal: {
        width: "100%",
        borderBottom: theme.palette.type === "dark" ? "1px solid #444444" : "1px solid #EAEAEA"
    },
    button: {
        textTransform: "none",
        height: "25px",
        width: "63px",
        background: theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
    },
    intervalButton: {
        border: "none",
        borderRadius: "4px",
        color: "#FFFFFF",
        height: "23px",
        fontSize: "10px",
        outline: "none",
        cursor: "pointer"
    },
    label: {
        color: theme.palette.type === "dark" ? "#ACACAC" : "#707070"
    }
}));

const SystemBenchmark = props => {
    const { fetchPortfolioBenchmarks, portfolio, updatePortfolioInterval } = props;
    const classes = useStyles();
    const [date, setDate] = useState("Last 2 Weeks");
    const [number, setNumber] = useState("2");
    const [intervalType, setIntervalType] = useState("Weeks");

    useEffect(() => {
        // const timerValue = REFRESH_TIMER();
        fetchPortfolioBenchmarks();
        // const timer = setInterval(() => {
        //     fetchPortfolioBenchmarks();
        // }, timerValue);
        // return () => clearInterval(timer);
    }, [fetchPortfolioBenchmarks]);

    const StyledTableRow = withStyles(theme => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.type === "dark" ? "#414141" : "#FBFBFB"
            },
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.type === "dark" ? "#3A3A3A" : "#F6F6F6"
            }
        }
    }))(TableRow);

    // const handleIcon = img => {
    //     const entries = Object.entries(IMAGE);
    //     let imgURL;
    //     entries.map(([prop, val]) => {
    //         if (prop === img) {
    //             imgURL = val;
    //         }
    //     });
    //     return imgURL;
    // };

    const handleApply = async () => {
        await updatePortfolioInterval({ number, intervalType });
        // await fetchPortfolioBenchmarks();
    };
    const handleOnChange = event => {
        const val = event.target.value;
        const intervalArr = val.split(" ");
        setDate(val);
        setNumber(intervalArr[1]);
        setIntervalType(intervalArr[2]);
    };
    return (
        <div>
            <Grid
              xs={12}
              container
              item
              justify="flex-end" // "center"
              className={SystemBenchmark.styles.rootContainer}
            >
                <div className={classNames(classes.time, classes.label)}>Date/Time</div>
                <div>
                    <Grid item className={classes.field}>
                        <Dropdown
                          className={classes.dropdown}
                          options={VERBIAGE.PORTFOLIO_SCREEN.TIME}
                          value={date}
                          onChange={handleOnChange}
                        />
                    </Grid>
                </div>

                <div>
                    <Grid item className={classes.field}>
                        <div>
                            <button
                              type="submit"
                              className={classNames(classes.intervalButton, classes.button)}
                              onClick={handleApply}
                            >
                                {VERBIAGE.BUTTONS.APPLY}
                            </button>
                        </div>
                    </Grid>
                </div>
            </Grid>
            <Grid>
                <div className={classes.horizontal} />
            </Grid>

            <Paper elevation={0} className={classes.paper}>
                {portfolio && portfolio.benchmarks && portfolio.benchmarks.table && (
                    <Table className={classes.table} stickyHeader>
                        <TableHead className={classes.head}>
                            <TableRow className={classes.tr}>
                                <td className={classes.firstRow}>
                                    {VERBIAGE.PORTFOLIO_SCREEN.NAME}
                                </td>
                                <td align="right">{VERBIAGE.PORTFOLIO_SCREEN.SYS_PLANT_EFF}</td>
                                <td align="right">{VERBIAGE.PORTFOLIO_SCREEN.SYS_PLANT_KW}</td>
                                <td align="right" className={classes.lastRow}>
                                    {VERBIAGE.PORTFOLIO_SCREEN.SYS_PLANT_RT}
                                </td>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.td}>
                            {portfolio.benchmarks.table.map(table => (
                                <StyledTableRow key={table.name}>
                                    <td>{table.name}</td>
                                    <td className={SystemBenchmark.styles.col}>
                                        {table.SYS_PLANT_EFF}
                                    </td>
                                    <td className={SystemBenchmark.styles.col}>
                                        {table.SYS_PLANT_KW}
                                    </td>
                                    <td className={SystemBenchmark.styles.col}>
                                        {table.SYS_PLANT_RT}
                                    </td>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Paper>
        </div>
    );
};
SystemBenchmark.styles = {
    rootContainer: "webclient__portfolioRightView-container-root",
    image: "webclient__portfolioRightView-imageKpiSystem",
    row: "webclient__chillerTable-row",
    col: "webclient__chillerTable-col"
};

const mapStateToProps = state => ({
    portfolio: state.portfolio
});
const mapDispatchToProps = dispatch => ({
    fetchPortfolioBenchmarks: () => {
        dispatch(PortfolioActions.fetchPortfolioBenchmarks());
    },
    updatePortfolioInterval: interval => {
        dispatch(PortfolioActions.updatePortfolioInterval(interval));
    }
});
SystemBenchmark.propTypes = {
    fetchPortfolioBenchmarks: PropTypes.func.isRequired,
    updatePortfolioInterval: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemBenchmark));
