/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    makeStyles
} from "@material-ui/core";
import "../styles/mapTable.scss";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

const EditGroupTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");

    const [mapOptions, setMapOptions] = useState([]);
    const [mapList, setMapList] = useState([]);

    const [siteOptions, setSiteOptions] = useState([]);

    const emptyConfig = {
        groupId: VERBIAGE.TEXT.EMPTY_STRING,
        groupName: VERBIAGE.TEXT.EMPTY_STRING,
        sites: [],
        maps: []
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleGroupName = ({ target: { value } }) => {
        updateConfig({ ...config, groupName: value });
    };

    const handleSites = ({ target: { value } }) => {
        updateConfig({ ...config, sites: value });
    };

    const handleMaps = ({ target: { value } }) => {
        updateConfig({ ...config, maps: value });
    };

    const handleSubmit = async () => {
        const maps = [];
        config.maps.forEach(option => {
            mapList.forEach(map => {
                if (map.mapName === option) {
                    maps.push(map.mapId);
                }
            });
        });
        // if (config.maps.length !== config.sites.length) {
        //     setError(VERBIAGE.ERROR_TEXT.MAPS_SITES);
        //     return;
        // }
        if (isEdit) {
            const data = {
                ...config,
                maps
            };
            const url = `${URL.DATA.PLANT_SETTINGS.GROUPS}/${config.groupId}`;
            try {
                await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                return;
            }
        } else {
            const data = {
                ...config,
                maps
            };
            const url = URL.DATA.PLANT_SETTINGS.GROUPS;
            try {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                return;
            }
        }
        setOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data } = await api.get(URL.DATA.PLANT_SETTINGS.MAP, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                let options = [];
                data.forEach(map => {
                    options.push(map.mapName);
                });
                setMapOptions(options);
                setMapList(data);

                const response = await api.get(URL.DATA.PLANT_SETTINGS.SITES, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                data = response.data;
                options = [];
                data.forEach(site => {
                    options.push(site.siteId);
                });
                setSiteOptions(options);
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();
    }, [open]);

    const isDisabled = () => {
        if (!config.groupName || !config.sites || !config.maps) {
            return true;
        }
        return false;
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            // wordWrap: "break-word",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
      <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
        <DialogTitle>
          {`To ${
                    isEdit ? "edit" : "add to"
                } the groups list, please enter the details below.`}
        </DialogTitle>

        <DialogContent>
          <div className={EditGroupTable.styles.inputContainer}>
            <TextInput
              onChange={handleGroupName}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_GROUP}
              value={config.groupName}
              type="text"
            />
          </div>
          <div className={EditGroupTable.styles.inputContainer}>
            <DropdownSelect
              name={config.sites}
              options={siteOptions}
              onChange={handleSites}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.SITE}
              isMulti
            />
          </div>
          <div className={EditGroupTable.styles.inputContainer}>
            <DropdownSelect
              name={config.maps}
              options={mapOptions}
              onChange={handleMaps}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_MAP}
              isMulti
            />
          </div>
          {!!error && (
            <div className={classes.error}>
              <Typography>{error}</Typography>
            </div>
                )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleSubmit} disabled={isDisabled()}>
            {VERBIAGE.BUTTONS.SUBMIT}
          </Button>
          <Button className={classes.error} onClick={() => setOpen(false)}>
            {VERBIAGE.BUTTONS.CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
    );
});

EditGroupTable.styles = {
    inputContainer: "webclient__mapTable-input",
    errorText: "webclient__mapTable-error-text"
};

export default EditGroupTable;
