/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useState, useEffect, createContext, useContext } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import changeTheme from "./index";

const defaultContextData = {
    dark: false,
    toggle: () => {}
};

const ThemeContext = createContext(defaultContextData);
const useTheme = () => useContext(ThemeContext);

const useEffectDarkMode = () => {
    const [themeState, setThemeState] = useState({
        dark: false
    });

    useEffect(() => {
        const lsDark = localStorage.getItem("dark") === "true";
        setThemeState({ ...themeState, dark: lsDark });
    }, [themeState.dark]);

    return [themeState, setThemeState];
};

const ThemeContextProvider = ({ children }) => {
    const [themeState, setThemeState] = useEffectDarkMode();

    const toggle = () => {
        const dark = !themeState.dark;
        localStorage.setItem("dark", JSON.stringify(dark));
        setThemeState({ ...themeState, dark });
    };

    const computedTheme = themeState.dark ? changeTheme("DARK") : changeTheme("LIGHT");
    return (
      <ThemeProvider theme={computedTheme}>
        <ThemeContext.Provider
          value={{
                    dark: themeState.dark,
                    toggle
                }}
        >
          {children}
        </ThemeContext.Provider>
      </ThemeProvider>
    );
};

export { ThemeContextProvider, useTheme };
