/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-unused-vars  */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Typography, makeStyles, Button } from "@material-ui/core";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/login.scss";
import TextInput from "../../../dashboard/common/components/TextInput";
import URL from "../../../utils/URL";
import api from "../../../utils/API";
// import { TRUE } from "node-sass";

const MissingDataResolved = props => {
    const [reason, setReason] = useState("");
    const [status, setStatus] = useState("");
    const [success, setSuccess] = useState(false);

    const { match } = props;

    const handleReason = event => {
        setReason(event.target.value);
    };

    const handleSubmit = async () => {
        const { token } = match.params;
        const url = `${URL.AUTHENTICATION.MISSING_DATA_RESOLVED}/${token}`;
        const data = {
            reason
        };
        const response = await api.put(url, data);
        if (response.data.status === "ok") {
            setSuccess(true);
            setStatus(VERBIAGE.TEXT.MISSING_DATA_DONE_SUCCESS);
        } else {
            setStatus(VERBIAGE.TEXT.MISSING_DATA_DONE_FAIL);
        }
    };

    const useStyles = makeStyles(theme => ({
        status: {
            color: success ? theme.palette.success.dark : theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center",
            margin: "2vh 0 2vh 0"
        },
        button: {
            marginTop: "40px",
            borderRadius: "0em"
        },
        main: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center",
            background: "#ffffff"
        },
        sub: {
            border: "2px solid #006ab4",
            height: "240px",
            width: "35%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        input: {
            height: "50px",
            width: "70%",
            fontSize: "large",
            marginBottom: "60px"
        }
    }));

    const classes = useStyles();
    const multiline = true;

    return (
        <div className={classes.main}>
            <h1 className={MissingDataResolved.style.header}>{VERBIAGE.TEXT.MISSING_DATA_DONE}</h1>
            <div className={classes.sub}>
                <div className={classes.input}>
                    <TextInput
                      onChange={event => handleReason(event)}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTRT_MISSING_DATA_REASON}
                      value={reason}
                      multiline={multiline}
                      rows={4}
                      type="text"
                      disabled={success}
                    />
                </div>
                <input
                  type="button"
                  className={MissingDataResolved.style.button}
                  value={VERBIAGE.BUTTONS.SUBMIT}
                  onClick={handleSubmit}
                />
                <div className={classes.status}>
                    <Typography>{status}</Typography>
                </div>
            </div>
        </div>
    );
};

MissingDataResolved.style = {
    mainContainer: "webclient__login-screen-container-main",
    subContainer: "webclient__login-screen-container-sub",
    inputContainer: "webclient__login-screen-container-input",
    button: "webclient__login-screen-button",
    header: "webclient__login-screen-header",
    forgotPassword: "webclient__login-screen-forgot-password",
    errorText: "webclient__login-screen-error-text"
};
export default withRouter(MissingDataResolved);
