/* eslint-disable jsx-a11y/anchor-is-valid  */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent */
import React, { useState } from "react";
import { Grid, Menu, MenuItem, IconButton, Typography, Link } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import _ from "lodash";

const TableauMenu = props => {
    const {
        changeGrid,
        viz,
        headerValue,
        expansion,
        chartTitle,
        tall,
        refreshChart,
        handleTitleLink,
        report,
        exportPDF
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [expanded, setExpanded] = useState(false);

    const handleMenuClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const menuClose = ({ target: { innerText } }) => {
        setAnchorEl(null);
        if (_.trim(innerText) === "Expand") {
            setExpanded(true);
            changeGrid(true, viz);
            // expansion(12);
        } else if (_.trim(innerText) === "Collapse") {
            setExpanded(false);
            changeGrid(false);
            // expansion(8);
        } else if (_.trim(innerText) === "Refresh") {
            refreshChart();
        } else if (_.trim(innerText) === "Download") {
            exportPDF();
        }
    };

    return (
        <div>
            <Grid container style={{ height: "3vh" }}>
                <Grid
                  item
                  xs={10}
                  style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}
                >
                    {headerValue &&
                        (handleTitleLink ? (
                            <Typography variant="h6">
                                <Link onClick={handleTitleLink} href="#" underline="hover">
                                    {chartTitle}
                                </Link>
                            </Typography>
                        ) : (
                            <Typography style={{ fontSize: "13px" }} variant="h6">
                                {chartTitle}
                            </Typography>
                        ))}
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                >
                    <div style={{ textAlign: "right" }}>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleMenuClick}
                          onChange={menuClose}
                          style={{
                                padding: "0"
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={menuClose}
                          disableScrollLock
                        >
                            {expanded ? (
                                <div>
                                    <MenuItem value="Collapse" onClick={menuClose}>
                                        Collapse
                                    </MenuItem>
                                    <MenuItem value="Refresh" onClick={menuClose}>
                                        Refresh
                                    </MenuItem>
                                    {report && (
                                        <MenuItem value="Download" onClick={menuClose}>
                                            Download
                                        </MenuItem>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {!tall && !report && (
                                        <MenuItem value="Expand" onClick={menuClose}>
                                            Expand
                                        </MenuItem>
                                    )}
                                    <MenuItem value="Refresh" onClick={menuClose}>
                                        Refresh
                                    </MenuItem>
                                    {report && (
                                        <MenuItem value="Download" onClick={menuClose}>
                                            Download
                                        </MenuItem>
                                    )}
                                </div>
                            )}
                        </Menu>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
TableauMenu.propTypes = {
    headerValue: PropTypes.bool
};

TableauMenu.defaultProps = {
    headerValue: true
};

export default TableauMenu;
