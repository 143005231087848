const Colors = {
    WHITE: "#FFFFFF",
    DARK_BLUE: "#002A41",
    GREY_BLUE: "#3865A7",
    LIGHT_GREY: "#F6F6F6",
    MED_GREY: "#E2E2E2",
    DARK_MED_GREY: "#A0A0A0",
    DARK_GREY: "#707070"
};

export default Colors;
