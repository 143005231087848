import React from "react";
import {
    makeStyles,
    Typography,
    useTheme,
    MuiThemeProvider,
    Switch,
    createMuiTheme
} from "@material-ui/core";
import { BarChart, Bar, YAxis } from "recharts";
import classNames from "classnames";
import PropTypes from "prop-types";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import "../styles/pid.scss";
import VERBIAGE from "../../../utils/enums/Verbiage";

const useStyles = makeStyles(theme => ({
    text1Container: {
        textAlign: "center",
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey,
        fontSize: "14px",
        fontWeight: "500px",
        padding: "10px 0px"
    },
    mainContainer: {
        background: theme.palette.background.pid,
        margin: "10px 10px 0px 10px",
        // height: "69%",
        borderRadius: "3px",
        width: "246px"
    },
    inputContainer1: {
        background:
            theme.palette.type === "dark"
                ? theme.palette.background.grey_dark
                : theme.palette.common.white,
        width: "121px",
        height: "135px",
        padding: "10px",
        borderRadius: "3px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    },
    inputContainer2: {
        background:
            theme.palette.type === "dark"
                ? theme.palette.background.grey_dark
                : theme.palette.common.white,
        width: "121px",
        height: "152px",
        padding: "10px",
        marginTop: "13px",
        borderRadius: "3px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    },
    input1: {
        background:
            theme.palette.type === "dark"
                ? theme.palette.background.pid
                : theme.palette.background.light_grey,
        border: theme.palette.type === "dark" ? "1px solid #2F2F2F" : "1px solid #A0A0A0",
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey
    },
    input2: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}`, // "1px solid #3865A7",
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey
    },
    minContainer: {
        background: theme.palette.background.pid,
        // margin: "0px 10px 10px 10px",
        // height: "100%",
        borderTop: theme.palette.type === "dark" ? "1px solid #444444" : "1px solid #EAEAEA",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "7px 0",
        borderRadius: "0 0 4px 4px"
    },
    sliderText: {
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey
    },
    text: {
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey
    }
}));
const PIDContainer = props => {
    const {
        Text,
        SPValue,
        // onSlide,
        PVValue,
        min,
        max,
        values,
        density,
        outValue,
        PValue,
        IValue,
        DValue,
        DBValue,
        MANValue,
        MINValue,
        MAXValue,
        checked
    } = props;
    const switchTheme = createMuiTheme({
        palette: {
            primary: {
                main: "#3AAB2D"
            }
        }
    });
    const data = [
        {
            pv: PVValue
        }
    ];
    const classes = useStyles();
    const themeStyle = useTheme();
    const barStyle = {
        fill: themeStyle.palette.background.chart
    };
    const spStyle = { paddingLeft: "55px" };
    return (
        <div className={classes.mainContainer}>
            <Typography className={classes.text1Container}>{Text}</Typography>

            <div className={PIDContainer.styles.subContainer}>
                <div
                  className={
                        themeStyle.palette.type === "dark"
                            ? PIDContainer.styles.sliderDark
                            : PIDContainer.styles.sliderLight
                    }
                >
                    <Nouislider
                      start={[SPValue]}
                      pips={{ mode: "count", values: [values], density: [density] }}
                      range={{
                            min: [min],
                            max: [max]
                        }}
                      orientation="vertical"
                      direction="rtl"
                        // onSlide={onSlide}
                      disabled
                    />
                    <div
                      className={classNames(PIDContainer.styles.sliderText, classes.sliderText)}
                      style={spStyle}
                    >
                        {VERBIAGE.PID.SP}
                    </div>
                </div>
                <div>
                    <BarChart
                      width={15}
                      height={300}
                      data={data}
                      margin={{ right: 20 }}
                      style={{ left: 7 }}
                    >
                        <Bar dataKey="pv" fill="#3AAB2D" background={barStyle} />
                        <YAxis domain={[min, max]} />
                    </BarChart>
                    <div className={classNames(PIDContainer.styles.sliderText, classes.sliderText)}>
                        {VERBIAGE.PID.PV}
                    </div>
                </div>
                <div className={PIDContainer.styles.mainInputContainer}>
                    <div className={classes.inputContainer1}>
                        <div className={PIDContainer.styles.inputContainer}>
                            <span className={classNames(classes.text, PIDContainer.styles.text)}>
                                {VERBIAGE.PID.SP}
                            </span>
                            <input
                              disabled
                              type="number"
                              className={classNames(classes.input1, PIDContainer.styles.input1)}
                              value={SPValue}
                            />
                        </div>
                        <div className={PIDContainer.styles.inputContainer}>
                            <span className={classNames(classes.text, PIDContainer.styles.text)}>
                                {VERBIAGE.PID.PV}
                            </span>
                            <input
                              disabled
                              type="number"
                              className={classNames(classes.input1, PIDContainer.styles.input1)}
                              value={PVValue}
                            />
                        </div>
                        <div className={PIDContainer.styles.inputContainer}>
                            <span className={classNames(classes.text, PIDContainer.styles.text)}>
                                {VERBIAGE.PID.OUT}
                            </span>
                            <input
                              disabled
                              type="string"
                              className={classNames(classes.input1, PIDContainer.styles.input1)}
                              value={outValue}
                            />
                        </div>
                        <div className={PIDContainer.styles.inputContainer}>
                            <span className={classNames(classes.text, PIDContainer.styles.text)}>
                                {VERBIAGE.PID.AUTO}
                            </span>
                            <MuiThemeProvider theme={switchTheme}>
                                <Switch
                                    // size="small"
                                  checked={checked}
                                  color="primary"
                                  name="checked"
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                    <div className={classes.inputContainer2}>
                        <div className={PIDContainer.styles.inputContainer}>
                            <span className={classNames(classes.text, PIDContainer.styles.text)}>
                                {VERBIAGE.PID.P}
                            </span>
                            <input
                              disabled
                              type="string"
                              className={classNames(classes.input2, PIDContainer.styles.input2)}
                              value={PValue}
                            />
                        </div>
                        <div className={PIDContainer.styles.inputContainer}>
                            <span className={classNames(classes.text, PIDContainer.styles.text)}>
                                {VERBIAGE.PID.I}
                            </span>
                            <input
                              disabled
                              type="string"
                              className={classNames(classes.input2, PIDContainer.styles.input2)}
                              value={IValue}
                            />
                        </div>
                        <div className={PIDContainer.styles.inputContainer}>
                            <span className={classNames(classes.text, PIDContainer.styles.text)}>
                                {VERBIAGE.PID.D}
                            </span>
                            <input
                              disabled
                              type="string"
                              className={classNames(classes.input2, PIDContainer.styles.input2)}
                              value={DValue}
                            />
                        </div>
                        <div className={PIDContainer.styles.inputContainer}>
                            <span className={classNames(classes.text, PIDContainer.styles.text)}>
                                {VERBIAGE.PID.DB}
                            </span>
                            <input
                              disabled
                              type="string"
                              className={classNames(classes.input2, PIDContainer.styles.input2)}
                              value={DBValue}
                            />
                        </div>
                        <div className={PIDContainer.styles.inputContainer}>
                            <span className={classNames(classes.text, PIDContainer.styles.text)}>
                                {VERBIAGE.PID.MAN}
                            </span>
                            <input
                              disabled
                              type="string"
                              className={classNames(classes.input2, PIDContainer.styles.input2)}
                              value={MANValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.minContainer}>
                <div className={PIDContainer.styles.inputContainer}>
                    <span className={classNames(classes.text, PIDContainer.styles.text)}>
                        {VERBIAGE.PID.MIN}
                    </span>
                    <input
                      disabled
                      type="string"
                      className={classNames(classes.input2, PIDContainer.styles.input2)}
                      value={MINValue}
                    />
                </div>
                <div className={PIDContainer.styles.inputContainer}>
                    <span className={classNames(classes.text, PIDContainer.styles.text)}>
                        {VERBIAGE.PID.MAX}
                    </span>
                    <input
                      disabled
                      type="string"
                      className={classNames(classes.input2, PIDContainer.styles.input2)}
                      value={MAXValue}
                    />
                </div>
            </div>
        </div>
    );
};
PIDContainer.styles = {
    input1: "webclient__pid-input1",
    input2: "webclient__pid-input2",
    text: "webclient__pid-text",
    inputContainer: "webclient__pid-container-input",
    sliderText: "webclient__pid-sliderText",
    sliderDark: "webclient__pid-sliderDark",
    sliderLight: "webclient__pid-sliderLight",
    subContainer: "webclient__pid-container-sub",
    mainInputContainer: "webclient__pid-container-mainInput"
};
PIDContainer.propTypes = {
    Text: PropTypes.string,
    SPValue: PropTypes.number,
    // SPOnChange: PropTypes.func,
    // onSlide: PropTypes.func,
    PVValue: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    density: PropTypes.number,
    values: PropTypes.number,
    outValue: PropTypes.string,
    PValue: PropTypes.number,
    IValue: PropTypes.number,
    DValue: PropTypes.number,
    DBValue: PropTypes.number,
    MANValue: PropTypes.number,
    MINValue: PropTypes.number,
    MAXValue: PropTypes.number,
    checked: PropTypes.bool
};
PIDContainer.defaultProps = {
    Text: "PCHWP_DT",
    SPValue: 10,
    // SPOnChange: ()=>{},
    // onSlide: () => {},
    PVValue: 10,
    min: 0,
    max: 50,
    density: 2,
    values: 11,
    outValue: "20",
    PValue: 100,
    IValue: 15,
    DValue: 0,
    DBValue: 0,
    MANValue: 50,
    MINValue: 10,
    MAXValue: 15,
    checked: false
};
export default PIDContainer;
