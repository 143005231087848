/* eslint-disable max-len */
import React from "react";
import { makeStyles, MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Grid } from "@material-ui/core";
import { VERBIAGE } from "../../../utils/enums/index";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        textAlign: "center",
        paddingTop: "1vh",
        paddingRight: "1vw",
        display: "flex",
        // background: "#FFFFFF",
        height: "8vh",
        borderRadius: "4px 4px 0 0",
        [theme.breakpoints.down("sm")]: {
            paddingRight: "4vw",
            alignItems: "center",
            justifyContent: "center"
        },
        [theme.breakpoints.up("md")]: {
            justifyContent: "flex-end"
        }
        // [theme.breakpoints.up("lg")]: {
        //     background: theme.palette.type === "dark" ? "#201F25" : ""
        // },
        // [theme.breakpoints.up("md")]: {
        //     background: theme.palette.type === "dark" ? "#201F25" : ""
        // }
    },
    notchedOutline: {
        borderColor: theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
    },
    sliderContainer: {
        marginLeft: "10px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "20px"
        }
    },
    textColor: {
        color: "#7e7e7e"
    }
}));

const RangeSlider = props => {
    const theme = useTheme();
    const classes = useStyles();
    const theme1 = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                input: {
                    padding: "5px",
                    color: theme.palette.type === "dark" ? "#ffffff" : "#000000"
                }
            },
            MuiIconButton: {
                root: {
                    color: theme.palette.type === "dark" ? "#ffffff" : ""
                }
            },
            MuiInputLabel: {
                outlined: {
                    color: theme.palette.type === "dark" ? "#ffffff" : ""
                }
            },
            MuiSlider: {
                root: {
                    padding: "10px",
                    "@media (pointer:coarse)": {
                        padding: "10px"
                    },
                    width: "93%"
                }
            }
        }
    });
    const {
        dates,
        max,
        min,
        handleDates,
        handleStartInput,
        handleEndInput,
        onSliderChangeCommitted,
        id
    } = props;

    const themes = id === "analytics" ? theme1 : null;
    const gridSize = id === "analytics" ? 10 : 12;
    const borderClass = id ? classes.notchedOutline : null;

    return (
        <div className={classes.root}>
            <Grid item>
                {themes ? (
                    <MuiThemeProvider theme={themes}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid container className={classes.sliderContainer}>
                                <Grid container item xs={12}>
                                    <Grid item xs={12} sm={gridSize / 2}>
                                        <KeyboardDateTimePicker
                                          variant="dialog"
                                          label="Start Date"
                                          inputVariant="outlined"
                                          value={dates[0]}
                                          onChange={handleStartInput}
                                          format="DD/MM/YYYY HH:mm"
                                          DialogProps={{ disableScrollLock: true }}
                                          InputProps={{
                                                style: {
                                                    height: "30px"
                                                },
                                                classes: {
                                                    notchedOutline: borderClass,
                                                    input: classes.textColor
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={gridSize / 2}>
                                        <KeyboardDateTimePicker
                                          variant="dialog"
                                          label="End Date"
                                          inputVariant="outlined"
                                          value={dates[1]}
                                          onChange={handleEndInput}
                                          format="DD/MM/YYYY HH:mm"
                                          DialogProps={{ disableScrollLock: true }}
                                          InputProps={{
                                                style: {
                                                    height: "30px"
                                                },
                                                classes: {
                                                    notchedOutline: borderClass,
                                                    input: classes.textColor
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={gridSize}>
                                    <Slider
                                      value={dates}
                                      onChange={handleDates}
                                      min={min}
                                      max={max}
                                      step={0.1}
                                      onChangeCommitted={onSliderChangeCommitted}
                                    />
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                ) : (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Grid container className={classes.sliderContainer} justify="center">
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={12} sm={gridSize / 2}>
                                    <KeyboardDateTimePicker
                                      variant="dialog"
                                      label="Start Date"
                                      inputVariant="outlined"
                                      value={dates[0]}
                                      onChange={handleStartInput}
                                      format="DD/MM/YYYY HH:mm"
                                      DialogProps={{ disableScrollLock: true }}
                                      InputProps={{
                                            style: {
                                                height: "30px"
                                            },
                                            classes: {
                                                notchedOutline: borderClass,
                                                input: classes.textColor
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={gridSize / 2}>
                                    <KeyboardDateTimePicker
                                      variant="dialog"
                                      label="End Date"
                                      inputVariant="outlined"
                                      value={dates[1]}
                                      onChange={handleEndInput}
                                      format="DD/MM/YYYY HH:mm"
                                      DialogProps={{ disableScrollLock: true }}
                                      InputProps={{
                                            style: {
                                                height: "30px"
                                            },
                                            classes: {
                                                notchedOutline: borderClass,
                                                input: classes.textColor
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={gridSize - 1}>
                                <Slider
                                  value={dates}
                                  onChange={handleDates}
                                  min={min}
                                  max={max}
                                  step={60000}
                                  onChangeCommitted={onSliderChangeCommitted}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                )}
            </Grid>
        </div>
    );
};

export default RangeSlider;
