import React from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { VERBIAGE } from "../../../utils/enums/index";
import "../styles/buttonCommon.scss";
import TextInput from "../components/TextInput";
import TextInputParse from "../components/TextInputParse";

const useStyles = makeStyles(theme => ({
    button: {
        background:
            theme.palette.type === "dark"
                ? theme.palette.background.button
                : theme.palette.primary.main,
        textTransform: "none"
    }
}));
const ButtonCommon = React.memo(props => {
    const {
        placeholder,
        labelChange,
        userTagsChips,
        labelText,
        onClick,
        inActive,
        // className,
        buttonLabel,
        disableButton,
        rows,
        parse,
        styles
    } = props;

    const classes = useStyles();

    return (
      <div className={ButtonCommon.styles.rootContainer}>
        {parse ? (
          <div>
            <TextInputParse
              value={labelText}
              userTagsChips={userTagsChips}
              onChange={labelChange}
              placeholder={inActive ? "" : placeholder}
              disabled={inActive}
              multiline
              rows={rows}
              styles={styles}
            />
          </div>
            ) : (
              <div>
                <TextInput
                  value={labelText}
                  onChange={labelChange}
                  placeholder={inActive ? "" : placeholder}
                  disabled={inActive}
                  multiline
                  rows={rows}
                  styles={styles}
                />
              </div>
            )}
        {!disableButton && (
        <div className={ButtonCommon.styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={onClick}
            className={classNames(ButtonCommon.styles.button, classes.button)}
            disabled={inActive}
          >
            {buttonLabel}
          </Button>
        </div>
            )}
      </div>
    );
});
ButtonCommon.propTypes = {
    placeholder: PropTypes.string,
    labelText: PropTypes.string,
    // className: PropTypes.string,
    labelChange: PropTypes.func,
    onClick: PropTypes.func,
    buttonLabel: PropTypes.string,
    disableButton: PropTypes.bool
};
ButtonCommon.defaultProps = {
    placeholder: VERBIAGE.TEXT.EMPTY_STRING,
    labelText: VERBIAGE.TEXT.EMPTY_STRING,
    // className: VERBIAGE.TEXT.EMPTY_STRING,
    labelChange: () => {},
    onClick: () => {},
    buttonLabel: VERBIAGE.ANALYTICS.SUBMIT,
    disableButton: false
};
ButtonCommon.styles = {
    rootContainer: "webclient__buttonCommon-container-root",
    buttonContainer: "webclient__buttonCommon-container-button",
    textContainer: "webclient__buttonCommon-container-text",
    button: "webclient__buttonCommon-button",
    label: "webclient__buttonCommon-label",
    durationLabel: "webclient__buttonCommon-durationLabel"
};
export default ButtonCommon;
