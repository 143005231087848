import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    content: {
        backgroundColor: theme.palette.error.light
    }
}));
const PIDAutoMLSetpointTableAlert = ({ open, errors, onClose }) => {
    const classes = useStyles();
    return (
        <Snackbar
          anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
          open={open}
        >
            <SnackbarContent
              className={classes.content}
              message={(
                    <ul>
                        {Object.keys(errors).map(sp => (
                            <li key={sp}>{errors[sp]}</li>
                        ))}
                    </ul>
                  )}
              action={(
                    <>
                        <IconButton size="small" onClick={onClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                  )}
            />
        </Snackbar>
    );
};
PIDAutoMLSetpointTableAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default PIDAutoMLSetpointTableAlert;
