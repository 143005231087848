import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    CircularProgress,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    Table,
    TableBody,
    Box,
    Grid
} from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Add, EditOutlined, LocalOfferOutlined, ExpandMore } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import moment from "moment";

import CustomFooter from "../../dashboard/common/components/CustomFooter";

const DWHDataTable = props => {
    const { loading, title, name, data, columns: cols, options: opts, onClick } = props;

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const isNarrow = useMediaQuery("(max-width: 600px)");

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        },
        disableRipple: {
            "&:hover": {
                backgroundColor: "transparent"
            }
        }
    }));

    const classes = useStyles();

    const onAdd = e => {
        if (onClick) {
            onClick(e, { action: "add" });
        }
    };

    const [actionCol, ...restCols] = cols;

    const columns = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: dataIndex => {
                    return (
                        <Grid container spacing={1}>
                            {actionCol.actions.map(action => (
                                <Grid item xs={6} key={action}>
                                    <IconButton
                                      className={classes.disableRipple}
                                      onClick={e =>
                                            onClick(e, {
                                                action,
                                                params: { dataIndex }
                                            })
                                        }
                                    >
                                        {action === "tag" && (
                                            <LocalOfferOutlined
                                              style={{ transform: "scaleX(-1)" }}
                                            />
                                        )}
                                        {action === "edit" && <EditOutlined />}
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                    );
                }
            }
        },
        ...restCols.map(item => {
            const { _options, ...col } = item;
            switch (_options?.type) {
                case "boolean":
                    col.options = {
                        ...(col.options || {}),
                        customBodyRender: value => value.toString().toUpperCase()
                    };
                    break;
                case "date":
                    {
                        const format = _options.dateFormat ?? "DD-MM-YYYY HH:mm:ss";
                        col.options = {
                            ...(col.options || {}),
                            customBodyRender: value => moment(value).format(format)
                        };
                    }
                    break;
                default:
            }
            return col;
        })
    ];

    // narrow custom row render
    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: theme.spacing(1)
        }
    }))(TableCell);
    const customRowRender = (row, dataIndex) => {
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };

        const rowData = data[dataIndex];
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell>
                                            <Grid container direction="column" spacing={1}>
                                                {actionCol.actions.map(action => (
                                                    <Grid item xs={6}>
                                                        <IconButton
                                                          onClick={e =>
                                                                onClick(e, {
                                                                    action,
                                                                    params: { dataIndex }
                                                                })
                                                            }
                                                        >
                                                            {action === "tag" && (
                                                                <LocalOfferOutlined
                                                                  style={{
                                                                        transform: "scaleX(-1)"
                                                                    }}
                                                                />
                                                            )}
                                                            {action === "edit" && <EditOutlined />}
                                                        </IconButton>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </StyledTableCell>
                                    </TableRow>
                                    {columns.map(col => {
                                        if (!col.label) return null;
                                        if (col.narrow && !isRowExpanded[dataIndex]) return null;
                                        return (
                                            <TableRow>
                                                <StyledTableCell>{col.label}</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData[col.name]}
                                                </StyledTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    };

    const options = {
        tableBodyHeight: "500px",
        selectableRows: "none",
        fixedHeader: true,
        filter: false,
        print: false,
        viewColumns: false,
        download: false,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title={`Add ${name || title}`}>
                        <IconButton onClick={onAdd}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined,
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress size={24} />
                ) : (
                    "Sorry, there is no matching data to display"
                )
            }
        },
        ...opts
    };

    const getMUIThemeDataTable = () => {
        if (isNarrow) {
            return createMuiTheme({
                breakpoints: {
                    values: {
                        xs: 0,
                        sm: 400,
                        md: 600,
                        lg: 1280,
                        xl: 1920
                    }
                },
                overrides: {
                    MUIDataTableBodyCell: {
                        root: {
                            fontSize: "1.25rem"
                        }
                    },
                    MuiTableCell: {
                        root: {
                            fontSize: "1.25rem",
                            padding: "0 16 0 16"
                        }
                    },
                    MuiAccordionSummary: {
                        root: {
                            padding: 0
                        },
                        content: {
                            margin: 0,
                            "&$expanded": {
                                margin: 0
                            }
                        }
                    }
                }
            });
        }

        return createMuiTheme({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 400,
                    md: 600,
                    lg: 1280,
                    xl: 1920
                }
            },
            overrides: {
                MUIDataTableHeadCell: {
                    root: {
                        whiteSpace: "nowrap"
                    }
                },
                MUIDataTableBodyCell: {
                    root: {
                        fontSize: "1.25rem"
                    }
                }
            }
        });
    };

    const theme = getMUIThemeDataTable();

    return (
        <Box className={classes.table}>
            <MuiThemeProvider theme={theme}>
                <MUIDataTable title={title} data={data} columns={columns} options={options} />
            </MuiThemeProvider>
        </Box>
    );
};

export default DWHDataTable;
