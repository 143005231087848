import { useState, useEffect } from "react";
import "moment-timezone";
import _moment from "moment";
import api from "../../../utils/API";
import URL from "../../../utils/URL";

let LastUpdate = localStorage.getItem("lastUpdate");
let utcOffset = localStorage.getItem("utcOffset");
let timezone = localStorage.getItem("timezone");
let localData;

const Clock = () => {
    const [date, setDate] = useState(Date.now()); // eslint-disable-line no-unused-vars
    useEffect(() => {
        let isMounted = true;
        setInterval(() => {
            const fetchData = async () => {
                try {
                    const response = await api.get(URL.DATA.LAST_UPDATE, {
                        headers: {
                            "x-auth-token": localStorage.token
                        }
                    });
                    localStorage.setItem("lastUpdate", response.data.lastUpdate);
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.href = "/";
                    }
                }
            };
            fetchData();
            LastUpdate = parseInt(localStorage.getItem("lastUpdate"), 10);
            utcOffset = localStorage.getItem("utcOffset");
            timezone = localStorage.getItem("timezone");
            const offset = 0; // -x.getTimezoneOffset();
            localData = _moment(LastUpdate).utcOffset(offset + parseInt(utcOffset, 10), false);
            if (isMounted) {
                setDate(prev => prev + 1000);
            }
        }, 60000);
        return () => (isMounted = false);
    }, []);

    return `Last Data : ${_moment(localData).format(
        "dddd, MMMM Do YYYY, h:mm:ss a",
    )} (${timezone})`;
};

export default Clock;
