import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const StyledTableRow = withStyles(theme => ({
    root: {
        backgroundColor: props =>
            props.active === "true" ? theme.palette.common.white : grey[100]
    }
}))(TableRow);

export default StyledTableRow;
