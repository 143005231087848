/* eslint-disable max-len */
import PlantSActionTypes from "./PlantSActionTypes";
import api from "../../utils/API";
import URL from "../../utils/URL";

const config = {
    headers: {
        // "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        "x-auth-token": ""
    }
};

function constructHeader() {
    const token = localStorage.getItem("token");

    config.headers["x-auth-token"] = `${token}`;
    return config;
}

const PlantSActions = {
    getEquipmentData: data => ({
        type: PlantSActionTypes.GET_EQUIPMENT,
        data
    }),
    getEquipment: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.PLANT_SCHEMATIC.GET_EQUIPMENT, config)
            .then(response => {
                dispatch(PlantSActions.getEquipmentData(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    getImageData: data => ({
        type: PlantSActionTypes.GET_IMAGE,
        data
    }),
    getImage: () => dispatch => {
        constructHeader();
        api.get(URL.DATA.PLANT_SCHEMATIC.GET_IMAGE, config)
            .then(response => {
                dispatch(PlantSActions.getImageData(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    }
};

export default PlantSActions;
