import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    makeStyles,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from "@material-ui/core";

import React, { useState } from "react";
import axios from "axios";
import { VERBIAGE } from "../../../utils/enums";
import URL from "../../../utils/URL";

const moment = require("moment");

const useStyles = makeStyles(theme => ({
    header: {
        color: theme.palette.type === "dark" ? theme.palette.text.dark : theme.palette.primary.main,
        margin: "4px",
        fontSize: "14px",
        paddingRight: "10px"
    },
    button: {
        height: "22px",
        margin: "4px",
        textTransform: "None",
        background:
            theme.palette.type === "dark"
                ? theme.palette.background.button
                : theme.palette.primary.main
    },
    formControl: {
        margin: theme.spacing(3)
    },
    dialog: {
        backgroundColor:
            theme.palette.type === "dark"
                ? theme.palette.background.button
                : theme.palette.primary.main,
        color: theme.palette.type === "dark" ? "#FFFFFF" : "#A0A0A0",
        textAlign: "center",
        height: "40px"
    }
}));

const menuStyle = {
    color: "white",
    textDecoration: "none"
};

const AnalyticsEOPDataDialog = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState("");
    const [error, setError] = React.useState(false);

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });

        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    const handleRadioChange = event => {
        setValue(event.target.value);
        setError(false);
    };
    const authToken = localStorage.getItem("token");
    const activeSite = localStorage.getItem("activeSite");

    const handleSubmit = event => {
        event.preventDefault();
        axios
            .get(`${URL.DATA.ANALYTICS.ANOMALY.GET_EOP_DATA}${value}`, {
                headers: {
                    "x-auth-token": authToken
                }
            })
            .then(eopData => {
                const items = eopData.data.eop;
                const replacer = (key, eopValue) => (eopValue === null ? "" : eopValue);
                const header = Object.keys(items[0]);
                const csv = [
                    header.join(","), // header row first
                    ...items.map(row =>
                        header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(","),
                    )
                ].join("\r\n");

                const now = moment().format("YYYYMMDD");
                downloadFile({
                    data: csv,
                    fileName: `${activeSite}_eop_data_${value}_${now}.csv`,
                    fileType: "text/csv"
                });
            });
        setOpen(false);
    };

    return (
        <div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={menuStyle}
              className={classes.button}
              onClick={() => setOpen(true)}
            >
                {VERBIAGE.BUTTONS.EXPORT_EOP_DATA_CSV}
            </Button>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
            >
                <DialogTitle className={classes.dialog} id="dialog-title">
                    EOP Data Download
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <FormControl component="fieldset" error={error} className={classes.formControl}>
                        <FormLabel component="legend">Select Equipment Data to download</FormLabel>
                        <RadioGroup
                          aria-label="equipment"
                          name="equipment"
                          value={value}
                          onChange={handleRadioChange}
                        >
                            <FormControlLabel value="ch" control={<Radio />} label="CHILLER" />
                            <FormControlLabel
                              value="ct"
                              control={<Radio />}
                              label="COOLING TOWER"
                            />
                            <FormControlLabel
                              value="cdwp"
                              control={<Radio />}
                              label="CONDENSER PUMPS"
                            />
                            <FormControlLabel
                              value="chwpp"
                              control={<Radio />}
                              label="CHILLED WATER PUMP PRIMARY"
                            />
                            <FormControlLabel
                              value="chwps"
                              control={<Radio />}
                              label="CHILLED WATER PUMP SECONDARY"
                            />
                        </RadioGroup>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.button}
                        >
                            {VERBIAGE.BUTTONS.EXPORT_EOP_DATA_CSV}
                        </Button>
                    </FormControl>
                </form>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AnalyticsEOPDataDialog;
