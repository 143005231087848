/* eslint-disable max-len */
/* eslint-disable no-unused-vars  */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import "../styles/time.scss";
import { VERBIAGE } from "../../../utils/enums/index";
import Dropdown from "./Dropdown";

const useStyles = makeStyles(theme => ({
    button: {
        background: theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE, // "#3865A7",
        height: "22px",
        textTransform: "none"
    },
    field: {
        padding: "4px",
        paddingLeft: "0px",
        fontSize: "14px",
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey,
        fontWeight: 500
    },
    dropdown: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}`, // "1px solid #3865A7",
        borderRadius: "4px",
        height: "22px",
        width: "200px",
        color:
            theme.palette.type === "dark"
                ? theme.palette.text.grey
                : theme.palette.text.light_grey /* eslint-disable max-len */
    },
    number: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}`, // "1px solid #3865A7",
        width: "40px",
        // [theme.breakpoints.up("xs")]: {
        //     width: "6vw"
        // },
        // [theme.breakpoints.up("sm")]: {
        //     width: "5vw"
        // },
        // [theme.breakpoints.up("md")]: {
        //     width: "4vw"
        // },
        // [theme.breakpoints.up("lg")]: {
        //     width: "3vw"
        // },
        // [theme.breakpoints.up("xl")]: {
        //     width: "3vw"
        // },
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey
    },
    text: {
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey,
        fontWeight: 500
    }
}));
const FeedBackStatusDropdown = props => {
    const {
        currentValue, // intervalTypeValue,
        valueChange, // intervalTypeChange,
        onClick,
        button,
        list
    } = props;
    const classes = useStyles();

    return (
        <Grid container item justify="space-between">
            <Grid item className={classes.field}>
                <span className={classNames(FeedBackStatusDropdown.styles.text, classes.text)}>
                    {VERBIAGE.ANOMALY_DIAGNOSIS.STATUS}
                </span>
            </Grid>
            <Grid item className={classes.field}>
                <Dropdown
                  className={classes.dropdown}
                  value={currentValue}
                  options={list}
                  onChange={valueChange}
                />
            </Grid>
            {button && (
                <Grid item className={classes.field}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.button}
                      onClick={onClick}
                    >
                        {VERBIAGE.BUTTONS.APPLY}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

FeedBackStatusDropdown.propTypes = {
    currentValue: PropTypes.string,
    valueChange: PropTypes.func,
    onClick: PropTypes.func,
    button: PropTypes.bool
};
FeedBackStatusDropdown.defaultProps = {
    currentValue: VERBIAGE.TEXT.EMPTY_STRING,
    button: false,
    valueChange: () => {},
    onClick: () => {}
};
FeedBackStatusDropdown.styles = {
    input: "webclient__time-input",
    button: "webclient__time-button",
    text: "webclient__time-text",
    dropdown: "webclient__time-dropdown"
};

export default FeedBackStatusDropdown;
