import React from "react";
import classNames from "classnames";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import VERBIAGE from "../../../utils/enums/Verbiage";
import ICONS from "../../../utils/enums/IconData";
import "../styles/portfolioRightView.scss";

const useStyles = makeStyles(theme => ({
    header: {
        color: theme.palette.type === "dark" ? "#DFDFDF" : "#414141"
    },
    dot: {
        filter: theme.palette.type === "dark" ? "brightness(0) invert(1)" : "",
        cursor: "pointer"
    }
}));
const EllipsisPopUp = () => {
    const classes = useStyles();
    return (
      <div>
        <PopupState variant="popover" popupId="ellipsis-popup-popover">
          {popupState => (
            <div>
              <img
                alt="ellipsis"
                src={ICONS.ELLIPSIS}
                className={classNames(EllipsisPopUp.styles.ellipsis, classes.dot)}
                {...bindTrigger(popupState)}
              />
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                            }}
                transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                            }}
              >
                <Box p={2}>
                  <Typography>
                    <Link href="/">
                      {VERBIAGE.TEXT.COMPARE_WITH_SIMILAR_PROFILES}
                    </Link>
                  </Typography>
                </Box>
              </Popover>
            </div>
                )}
        </PopupState>
      </div>
    );
};
EllipsisPopUp.styles = {
    ellipsis: "webclient__portfolioRightView-ellipsis"
};
export default EllipsisPopUp;
