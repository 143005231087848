/* eslint-disable max-len */
/* eslint-disable no-unused-vars  */
/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Moment from "react-moment";
import "moment-timezone";
import "../styles/time.scss";
import { VERBIAGE, ICONS } from "../../../utils/enums/index";
import HomeActions from "../../features/redux/HomeActions";
import Dropdown from "./Dropdown";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    container: {
        maxHeight: "10px"
    },
    titleFont: {
        fontSize: ".7rem",
        [theme.breakpoints.up("sm")]: {
            fontSize: "0.8rem"
        },
        [theme.breakpoints.up("md")]: {
            fontSize: ".9rem"
        }
    },
    button: {
        background: theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE, // "#3865A7",
        height: "22px",
        textTransform: "none"
    },
    field: {
        padding: "4px"
    },
    date: {
        paddingTop: "7px",
        paddingRight: "4px"
    },
    dropdown: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}`, // "1px solid #3865A7",
        borderRadius: "4px",
        height: "22px",
        width: "78px"
    },
    number: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}` // "1px solid #3865A7"
    }
}));
const Time = props => {
    const { updateInterval, view, dropdown, onChangePlantView, handleSubmit } = props;
    const classes = useStyles();
    const [interval, setInterval] = useState("3");
    const [intervalType, setIntervalType] = useState("Days");
    const [plantView, setPlantView] = useState("Cooling Load");

    const handleApply = () => {
        handleSubmit(interval, intervalType);
    };
    const date = new Date();
    const onDropdownChange = event => {
        setPlantView(event);
        onChangePlantView(event);
    };

    // Must have this to apply filter when page loads
    // useEffect(() => {
    //     handleApply();
    // }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-end" item xs={12}>
                    {view && (
                        <Grid item className={classes.field}>
                            <span className={Time.styles.text}>View</span>
                        </Grid>
                    )}

                    <Grid item className={classes.field}>
                        {dropdown && (
                            <Dropdown
                              className={classes.dropdown}
                              value={plantView}
                              options={VERBIAGE.SYSTEM_OVERVIEW.VIEW}
                              onChange={event => onDropdownChange(event.target.value)}
                            />
                        )}
                    </Grid>
                    <Grid item className={classes.field}>
                        <span className={Time.styles.text}>Last</span>
                    </Grid>
                    <Grid item className={classes.field}>
                        <input
                          className={classNames(Time.styles.input, classes.number)}
                          type="number"
                          value={interval}
                          onChange={event => setInterval(event.target.value)}
                        />
                    </Grid>
                    <Grid item className={classes.field}>
                        <Dropdown
                          className={classes.dropdown}
                          value={intervalType}
                          options={VERBIAGE.SYSTEM_OVERVIEW.INTERVAL}
                          onChange={event => setIntervalType(event.target.value)}
                        />
                    </Grid>
                    <Grid item className={classes.field}>
                        {/* <button
                type="submit"
                className={classNames(Time.styles.button, classes.button)}
                onClick={handleApply}
              >
                {VERBIAGE.BUTTONS.APPLY}
              </button> */}
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.button}
                          onClick={handleApply}
                        >
                            {VERBIAGE.BUTTONS.APPLY}
                        </Button>
                    </Grid>
                    {/* <Grid item className={classes.field}>
              <img src={ICONS.PAUSE} alt="pause" className={Time.styles.image} />
            </Grid> */}
                </Grid>
                {/* <Grid container justify="flex-end" item xs={3}>
            <Grid item className={classes.date}>
              <span className={classNames(classes.titleFont, Time.styles.text)}>
                <Moment tz="Singapore" format="Do MMMM YYYY">
                  {date}
                </Moment>
              </span>
            </Grid>
          </Grid> */}
            </Grid>
        </div>
    );
};

const mapStateToProps = state => ({
    home: state.home
});

const mapDispatchToProps = dispatch => ({
    updateInterval: interval => {
        dispatch(HomeActions.updateInterval(interval));
    }
});
Time.propTypes = {
    view: PropTypes.bool,
    dropdown: PropTypes.bool,
    updateInterval: PropTypes.func.isRequired
};
Time.defaultProps = {
    view: false,
    dropdown: false
};
Time.styles = {
    rootContainer: "webclient__time-container-root",
    subContainer: "webclient__time-container-sub",
    dateContainer: "webclient__time-container-date",
    input: "webclient__time-input",
    button: "webclient__time-button",
    text: "webclient__time-text",
    dropdown: "webclient__time-dropdown",
    image: "webclient__time-image"
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Time));
