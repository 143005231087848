import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Tab, Tabs, makeStyles, useMediaQuery } from "@material-ui/core";

import MobileDetect from "mobile-detect";

import URL from "../../../utils/URL";
import { VERBIAGE } from "../../../utils/enums";

import PidSpec from "./PidSpec";

const PidTimeBased = () => {
    return <PidSpec title="PID time based" baseUrl={URL.DATA.DWH.PID_TIME_BASED} />;
};
const PidPressureBased = () => {
    return <PidSpec title="PID pressure based" baseUrl={URL.DATA.DWH.PID_PRESSURE_BASED} />;
};
const PidLoadBased = () => {
    return <PidSpec title="PID load based" baseUrl={URL.DATA.DWH.PID_LOAD_BASED} />;
};
const PidTempBased = () => {
    return <PidSpec title="PID temp based" baseUrl={URL.DATA.DWH.PID_TEMP_BASED} />;
};

const PidOverviewTabs = () => {
    const home = useSelector(state => state.home);
    const [value, setValue] = useState(false);

    const location = useLocation();

    const tabs = useMemo(() => {
        return [
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.PID_TIME_BASED,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.PID_TIME_BASED,
                path: VERBIAGE.WAREHOUSE_PATH.PID_TIME_BASED
            },
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.PID_PRESSURE_BASED,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.PID_PRESSURE_BASED,
                path: VERBIAGE.WAREHOUSE_PATH.PID_PRESSURE_BASED
            },
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.PID_LOAD_BASED,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.PID_LOAD_BASED,
                path: VERBIAGE.WAREHOUSE_PATH.PID_LOAD_BASED
            },
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.PID_TEMP_BASED,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.PID_TEMP_BASED,
                path: VERBIAGE.WAREHOUSE_PATH.PID_TEMP_BASED
            }
        ];
    }, []);

    useEffect(() => {
        const tab = tabs.find(t => t.path === location.pathname);
        setValue(tab?.value ?? false);
    }, [location.pathname, tabs]);

    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        }
    }));

    const classes = useStyles();

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons={useMediaQuery("(max-width: 850px)") ? "on" : "auto"}
                >
                    {tabs.map(tab => (
                        <Tab
                          key={tab.value}
                          value={tab.value}
                          label={tab.label}
                          component={Link}
                          to={() => `${tab.path}${location.search}`}
                        />
                    ))}
                </Tabs>
                <Switch>
                    <Route path={VERBIAGE.WAREHOUSE_PATH.PID_TIME_BASED} component={PidTimeBased} />
                    <Route
                      path={VERBIAGE.WAREHOUSE_PATH.PID_PRESSURE_BASED}
                      component={PidPressureBased}
                    />
                    <Route path={VERBIAGE.WAREHOUSE_PATH.PID_LOAD_BASED} component={PidLoadBased} />
                    <Route path={VERBIAGE.WAREHOUSE_PATH.PID_TEMP_BASED} component={PidTempBased} />
                </Switch>
            </Grid>
        </Grid>
    );
};

export default PidOverviewTabs;
