/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const VERBIAGE = {
    TEXT: {
        PERMISSIONS: [
            "customer_access",
            "bbp_access",
            "asset_access",
            "developer_access",
            "admin_access"
        ], // ["admin_level", "plant_level", "basic_level"],
        CHART_MAP_USAGE_OPTIONS: [
            "ALARM_CONFIG",
            "ANALYTICS_OVERVIEW",
            "EQPT_LIFECYCLE",
            "LABEL_SUPPORT",
            "PLANT_OVERVIEW",
            "OPTIMIZATION",
            "SYSTEM_REPORT",
            "DYNAMIC_REPORT"
        ],
        VALUE_STRING_OPTIONS: ["null", "True", "False"],
        STATUS_OPTIONS: ["ENABLED", "DISABLED"],
        BOOLEAN_OPTIONS: ["YES", "NO"],
        THEME_OPTIONS: ["LIGHT", "DARK"],
        MONTH_OPTIONS: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ],
        CURRENCY_OPTIONS: ["SGD", "RM", "CNY", "TWD", "THB", "PHP", "IDR", "INR", "KRW"],
        // CURRENCY_SGD: 'Singapore Dollar',
        // CURRENCY_RM: 'Malaysian Ringgit',
        // CURRENCY_CNY: 'Chinese Yuan',
        // CURRENCY_TWD: 'New Taiwan Dollar',
        // CURRENCY_THB: 'Thailand Baht',
        // CURRENCY_PHP: 'Philippine Peso',
        // CURRENCY_IDR: 'Indonesian Rupiah',
        // CURRENCY_INR: 'Indian Rupees',
        // CURRENCY_KRW: 'South Korean Won',

        EMAIL_REGEX: /\S+@\S+\.\S+/,
        PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#\$%\^&\*])(?=.{8,})/,
        HEADER: "User Login",
        REGISTER: "Registration",
        CHANGE_PASSWORD: "Change Password",
        CURRENT_PASSWORD: "Current Password",
        NEW_PASSWORD: "New Password",
        CONFIRM_PASSWORD: "Confirm Password",
        NEW_USER: "Register for an account",
        LOGIN: "Already have an account? Login here",
        EMPTY_STRING: "",
        NULL_VALUE: "null",
        TARGET: "Target",
        BASELINE: "Baseline",
        EFF_UNIT: "kW/RTon",
        LASTEST_DATA: "Latest Data",
        HOTEL_MICHAEL: "Hotel Michael",
        SITE: "Site",
        TARIFF: "Tariff",
        BASELINE_EFFICIENCY: "Baseline Efficiency",
        SYSTEM_EFFICIENCY: "System Efficiency",
        MY_KPI: "My KPIs(System)",
        SYSTEM_BENCHMARK: "System Benchmark",
        MESSAGE_BULLETIN: "Message Bulletin",
        REGISTRATION_SUCCESS: "Registration successful!",
        COMPARE_WITH_SIMILAR_PROFILES: "Compare with similar profiles",
        ANALYTICS: "Analytics View",
        PASSWORD_CHANGE_SUCCESS: "Password changed successfully",
        FORGOT_PASSWORD: "Forgot Password",
        RESET_PASSWORD: "Reset Password",
        FORGOT_PASSWORD_SUBMITTED: "Please check your email for a password reset link",
        RESET_PASSWORD_SUCCESS: "Password reset succesfully, proceed to login page to login",
        RESET_PASSWORD_FAIL: "Token may have expired, please request for a new password reset link",
        MISSING_DATA_ACK_SUCCESS: "Missing Data Acknowledged",
        MISSING_DATA_ACK_FAIL: "Missing Data Acknowledgement Fail",
        // MISSING_DATA_DONE_SUCCESS: "Missing Data Resolved",
        MISSING_DATA_DONE: "Missing Data Resolved",
        MISSING_DATA_DONE_SUCCESS: "Reason submitted succesfully.",
        MISSING_DATA_DONE_FAIL_MAIN: "Missing Data Resolved Fail",
        MISSING_DATA_DONE_FAIL: "The issue may have been resolved previously or token expired."
    },
    PLACEHOLDER_TEXT: {
        USERNAME: "Enter username",
        PASSWORD: "Enter password",
        EMAIL: "Enter email",
        CONFIRM_PASSWORD: "Confirm password",
        NAME: "Enter name",
        PERMISSIONS: "Select Access Type",
        SITE: "Select Site ID",
        ENTER_SITE_ID: "Enter Site ID",
        ENTER_SITE_LOGO: "Enter Site Logo URL",
        ENTER_BBP_ID: "Enter BBP ID",
        ENTER_SITE_NAME: "Enter Site Name",
        ENTER_ADDRESS: "Enter Address",
        ENTER_LONG: "Enter Longitude",
        ENTER_LAT: "Enter Latitude",
        SELECT_MAP: "Select map...",
        SELECT_TABLEAU_VERSION: "Select Tableau API Version",
        ENTER_TABLEAU_USERNAME: "Enter Tableau Username",
        SELECT_SITE_STATUS: "Select Site Status",
        ENTER_GROUP: "Enter group name",
        ENTER_MAP: "Enter map name",
        ENTER_ZOOM: "Enter zoom level",
        ENTER_EQPT_ZOOM: "Enter Eqpt zoom level",
        ENTER_LABEL_ZOOM: "Enter Label zoom level",
        ENTER_LABEL_MARGIN: "Enter Label Y Axis",
        ENTER_LABEL_X_AXIS: "Enter Label X Axis",
        ENTER_LABEL_ALIGN: "Enter Label Align",
        ENTER_DATA_ZOOM: "Enter Data zoom level",
        ENTER_DATA_MARGIN: "Enter Data Y Axis",
        ENTER_DATA_X_AXIS: "Enter Data X Axis",
        ENTER_DATA_ALIGN: "Enter Data Align",
        TYPE_HERE: "Type here...",
        ENTER_DATE: "Date",
        ENTER_TIME: "Time",
        ENTER_CODE: "Enter Code",
        ENTER_PARAM: "Enter Config",
        ENTER_MAIN: "Enter Parameter",
        ENTER_VALUE: "Enter Value",
        ENTER_MODEL: "Enter Model",
        SELECT_USAGE: "Select Usage",
        ENTER_POSITION: "Enter Position",
        ENTER_LINK: "Enter Link",
        ENTER_NAME: "Enter Display Name",
        ENTER_DESCRIPTION: "Enter Description",
        ENTER_MAIN_LOGO: "Enter Main Logo",
        ENTER_CIRCLE_LOGO: "Enter Circle Logo",
        ENTER_BIG_LOGO: "Enter Big Logo",
        ENTER_SMALL_LOGO: "Enter Small Logo",
        ENTER_MAIN_NIGHT_LOGO: "Enter Main Night Logo",
        ENTER_CIRCLE_NIGHT_LOGO: "Enter Circle Night Logo",
        ENTER_BIG_NIGHT_LOGO: "Enter Big Night Logo",
        ENTER_SMALL_NIGHT_LOGO: "Enter Small Night Logo",
        ENTER_NUM_CHILLERS: "Enter Number of Chillers",
        ENTER_NUM_CT: "Enter Number of Cooling Towers",
        ENTER_NUM_CHPUMPS: "Enter Number of Chiller Pumps",
        ENTER_NUM_SCHPUMPS: "Enter Number of Secondary Chiller Pumps",
        ENTER_NUM_CDPUMPS: "Enter Number of Condenser Pumps",
        ENTER_CHILLERS_PREFIX: "Enter Chillers Prefix",
        ENTER_CT_PREFIX: "Enter Cooling Towers Prefix",
        ENTER_CHPUMPS_PREFIX: "Enter Chiller Pumps Prefix",
        ENTER_SCHPUMPS_PREFIX: "Enter Secondary Chiller Pumps Prefix",
        ENTER_CDPUMPS_PREFIX: "Enter Condenser Pumps Prefix",
        SELECT_VALUE_BOOLEAN: "Select True/False (if applicable)",
        SELECT_STATUS: "Select Status",
        SITE_ID: "Site ID",
        ENTER_DESC: "Enter Description",
        ENTER_WEB_NAME: "Enter Web Name",
        ENTER_DATA_NAME: "Enter Data Name",
        ENTER_CLIENT_NAME: "Enter Client Name",
        ENTER_NAME_MAPPING_STATUS: "Select Status",
        ENTER_EQPT_ID: "Enter Equipment ID",
        ENTER_EQPT_TYPE: "Select Equipment Type",
        ENTER_LABEL: "Enter Label",
        ENTER_X_AXIS: "Enter X Axis",
        ENTER_Y_AXIS: "Enter Y Axis",
        ENTER_STATUS: "Enter Status",
        ENTER_TYPE: "Enter Type",
        SELECT_GROUP: "Select Group",
        SELECT_THEME: "Select Theme",
        ENTER_REFRESH_RATE: "Enter Refresh Rate",
        SELECT_LIVE_BOOLEAN: "Is Live Data",
        SELECT_SCHEMATICS_BOOLEAN: "Is Schematics Developed?",
        SELECT_REPORTS_BOOLEAN: "Is Reports Available?",
        SELECT_ANALYTICS_BOOLEAN: "Is Analytics Developed?",
        SELECT_LC_CT_BOOLEAN: "Is LC Cooling Tower Developed?",
        SELECT_LC_CHWP_BOOLEAN: "Is LC CH Pump Developed?",
        SELECT_LC_CDWP_BOOLEAN: "Is LC CD Pump Developed?",
        SELECT_OPTIMIZATION_BOOLEAN: "Is Optimization Developed?",
        SELECT_DEVELOPER_ANALYTICS_BOOLEAN: "Developing Analytics?",
        SELECT_AUTO_ML_BOOLEAN: "is PID Auto ML Active?",
        SELECT_GREENMARK_BOOLEAN: "Is Green Mark Certified?",
        SELECT_WATER_SAVINGS_BOOLEAN: "is Water Savings Displayed?",
        ENTER_MT_DATE: "Enter Maintenance Date",
        ENTER_MT_REMINDER: "Enter Reminder Date",
        ENTER_MT_NOTIFICATION: "Enter Notification",
        SELECT_RECEIVE_EMAIL: "Can Receive Email?",

        // Site Config
        ENTER_CONFIG_TYPE: "Enter Config Type",
        ENTER_CONFIG_SCOPE: "Enter Config Scope",
        ENTER_CONFIG_KEY: "Enter Config Key",
        ENTER_CONFIG_VALUE: "Enter Config Value",
        ENTER_CONFIG_DESCRIPTION: "Enter Config Description",

        // Version History
        ENTER_HISTORY_SITE_ID: "Enter Site ID",
        ENTER_HISTORY_TYPE: "Enter Type",
        ENTER_HISTORY_DETAILS: "Enter Details",
        ENTER_HISTORY_VERSION: "Enter Version",
        ENTER_HISTORY_DATE: "Enter Change Date",

        ENTER_TABLE_ONE_MINUTE: "Enter 1 Minute Table Name",
        ENTER_TABLE_SIXTY_MINUTE: "Enter 60 Minutes Table Name",
        ENTER_TABLE_FAULT_EVENT: "Enter Fault Event Table Name",
        ENTER_TABLE_PARAM_CONFIG: "Enter Param Config Table Name",
        ENTER_TABLE_FAULT_DIAGNOSIS: "Enter Fault Diagnosis Table Name",
        ENTER_TABLE_FAULT_CODE_LIST: "Enter Fault Code List Table Name",
        ENTER_TABLE_OPTIMIZATION_OUTPUT: "Enter Optimization Output Table Name",

        // PID
        ENTER_PID_GROUPNAME: "Enter Group Name",
        ENTER_PID_PARAMETER: "Enter SP Parameter",
        ENTER_PID_LABEL: "Enter SP Parameter Label",
        ENTER_PID_PV_PARAMETER: "Enter PV Parameter",
        ENTER_PID_PV_PARAMETER2: "Enter PV Additional Parameter",
        ENTER_PID_PV_OPERATION: "Enter Operation",
        ENTER_PID_CV_PARAMETER: "Enter CV Parameter",
        ENTER_PID_SCALE_MIN: "Enter Min Scale",
        ENTER_PID_SCALE_MAX: "Enter Max Scale",
        ENTER_PID_SCALE_INTERVAL: "Enter Scale Interval",
        ENTER_PID_PVALUE: "Enter P Value",
        ENTER_PID_IVALUE: "Enter I Value",
        ENTER_PID_DVALUE: "Enter D Value",
        ENTER_PID_DBVALUE: "Enter DB Value",
        ENTER_PID_MANVALUE: "Enter Man Value",
        ENTER_PID_MINVALUE: "Enter Min Value",
        ENTER_PID_MAXVALUE: "Enter Max Value",

        // SAVINGS OFFSET
        ENTER_OFFSET_YEAR: "Enter Year",
        ENTER_OFFSET_MONTH: "Enter Month",
        ENTER_OFFSET_KWH: "Enter KWH",
        ENTER_OFFSET_RTH: "Enter RTH",
        ENTER_OFFSET_COST: "Enter Cost",
        ENTER_OFFSET_STATUS: "Enter Status",

        // SAVINGS CONFIG
        ENTER_SAVINGS_CONFIG_SINCE: "Since",
        ENTER_SAVINGS_CONFIG_HOURLY: "Hourly (Start Time)",
        ENTER_SAVINGS_CONFIG_DAILY: "Daily (Start Time)",
        ENTER_SAVINGS_CONFIG_MONTHLY: "Monthly (Start Time)",

        ENTRT_MISSING_DATA_REASON: "Enter Reason",
        SELECT_ENG_INCHARGE: "Select Engineer In Charge",
        SELECT_MGR_INCHARGE: "Select Manager In Charge",
        ENTER_THRESHOLD_1: "Enter Threshold 1 (in mins)",
        ENTER_THRESHOLD_2: "Enter Threshold 2 (in mins)",
        ENTER_THRESHOLD_3: "Enter Threshold 3 (in mins)",

        // CURRENCY CONVERTER
        ENTER_CURRENCY_CONVERTER_CURRENCY: "Currency",
        ENTER_CURRENCY_CONVERTER_RATE: "Rate (vs SGD)",
        ENTER_CURRENCY_CONVERTER_VALID_FROM: "Valid From",

        // DWH
        SELECT_IS_ACTIVE: "Is Active",
        SELECT_SITE: "Select Site",

        ENTER_PERSON_IN_CHARGE: "Person In Charge",
        ENTER_POSTAL_CODE: "Enter Postal Code",
        ENTER_COUNTRY: "Enter Country",
        ENTER_REMARKS: "Enter Remarks",
        ENTER_INDUSTRY_TYPE: "Enter Industry Type",
        ENTER_CHILLER_COUNT: "Enter Number of Chiller Installed",
        SELECT_AC_SYSTEM_TYPE: "Select AC System Type",
        SELECT_CONFIGURATION: "Select Configuration",

        ENTER_WATER_PUMP_ID: "Enter Water Pump ID",
        SELECT_IS_PRIMARY: "Is Primary ?",
        ENTER_MOTOR_BRAND: "Enter Motor Brand",
        ENTER_MOTOR_MODEL: "Enter Motor Model",
        ENTER_PUMP_BRAND: "Enter Pump Brand",
        ENTER_PUMP_MODEL: "Enter Pump Model",
        ENTER_TYPE_OF_STARTER: "Enter Type of Starter",
        SELECT_IS_VSD_BYPASS: "Is VSD Bypass ?",
        SELECT_IS_OUT_FORCE: "Is Out Force ?",
        ENTER_OUT_FORCE_VALUE: "Enter Out Force Value",

        ENTER_BRAND: "Enter Brand",

        SELECT_TYPE: "Select Type",

        ENTER_PID_ID: "Enter PID ID",
        ENTER_SP_SELECTION: "Enter SP Selection",

        ENTER_CATEGORY: "Enter Category",
        ENTER_SERIAL_NO: "Enter Serial No",
        ENTER_MANUFACTURER: "Enter Manufacturer",
        ENTER_CALIBRATION_DATE: "Enter Calibration Date",
        ENTER_COMMISION_DATE: "Enter Commision Date",
        ENTER_ST_LINE_DS: "Enter St.Line Ds",
        ENTER_ST_LINE_UP: "Enter St.Line Up",
        SELECT_ORIENTATION: "Select Orientation",
        ENTER_VOLTAGE: "Enter Voltage",
        ENTER_UOM: "Enter UOM",

        ENTER_CHILLER_ID: "Enter Chiller ID",

        ENTER_ENVIRONMENT_ID: "Enter Environment ID",
        ENTER_PLANT_ROOM_WBT: "Enter Plant Room WBT",
        ENTER_PLANT_ROOM_DBT: "Enter Plant Room DBT",
        ENTER_PLANT_ROOM_HUMIDITY: "Enter Plant Room Humidity",
        ENTER_CLIMATE: "Enter Climate",

        SELECT_SCADA_TYPE: "Select Scada Type",
        ENTER_SCADA_NAME: "Enter Scada Name",
        ENTER_TOTAL_MAX_TAGS: "Enter Total Max Tags",
        ENTER_SCADA_BRAND: "Enter Scada Brand",
        ENTER_DISTRIBUTOR: "Enter Distributor",
        ENTER_LICENSE_CODE: "Enter License Code",

        ENTER_MANUFACTURER_YEAR: "Enter Manufacturer Year",
        ENTER_REFRIGERANT: "Enter Refrigerant",
        ENTER_COMPRESSOR_TYPE: "Enter Compressor Type",

        ENTER_PARTLOAD_PERCENT: "Enter Part Load Percentage",
        ENTER_PARTLOAD_VALUE: "Enter Part Load Value",

        ENTER_PUMP_TYPE: "Enter Pump Type",
        ENTER_PUMP_SUB_TYPE: "Enter Pump Sub Type",
        ENTER_MOTOR_EFF_TYPE: "Enter Motor Eff. Type",
        ENTER_MOTOR_INSUL_TYPE: "Enter Motor Insul. Type",

        ENTER_PID_FROM: "Select From",
        ENTER_PID_TO: "Select To",
        ENTER_PID_SP: "Enter SP",

        ENTER_DIMENSION: "Enter Dimension",
        SELECT_MTR_VAL_TYPE: "Select Motor Val Type",

        LAST_PLACEHOLDER_TEXT: "",

        ENTER_AIRFLOW_NAME: "Enter Airflow Name",
        ENTER_AIRFLOW_HOST: "Enter Airflow Host",
        ENTER_AIRFLOW_USERNAME: "Enter Airflow Username",
        ENTER_AIRFLOW_PASSWORD: "Enter Airflow Password",
        ENTER_AIRFLOW_ACTIVE: "Is Active",

        ENTER_SITE_AIRFLOW: "Select Airflow Connection",
        ENTER_SITE_TRAIN_DAG_ID: "Enter Train DAG ID",
        ENTER_SITE_PREDICT_DAG_ID: "Enter Predict DAG ID"
    },
    BUTTONS: {
        LOGIN: "LOGIN",
        REGISTER: "REGISTER",
        LOADING: "Loading...",
        DISPLAY_CHART: "DISPLAY CHART",
        CHART: "CHARTS",
        REPORT: "REPORT",
        ACK: "ACK",
        NEW: "NEW",
        LOGOUT: "LOGOUT",
        DATE_FILTER: "DATE FILTER",
        CHILLER_OBSERVER: "CHILLER INFO",
        APPLY: "Apply",
        UPDATE: "Update",
        SUBMIT: "SUBMIT",
        CLOSE: "DISCARD",
        CLOSE2: "CLOSE",
        EDIT: "Edit",
        ADD_NOTES: "Add Notes",
        EXPORT_CSV: "Export CSV",
        EXPORT_TABLE_CSV: "Export Table",
        EXPORT_EOP_DATA_CSV: "Export EOP Data",
        EXIT: "CLOSE"
    },
    ERROR_TEXT: {
        CURRENT_PASSWORD: "Current password is incorrect",
        CONFIRM_PASSWORD: "The password confirmation does not match",
        EMAIL_INVALID: "You have entered an invalid email address",
        PASSWORDS: "Passwords do not match",
        REGISTRATION: "Registration failed, please check password strength",
        USER_EXISTS: "Registration failed, username may already exist",
        MAPS_SITES: "Number of sites and maps must match",
        SITE_EXISTS: "The site you have entered already exists",
        ERROR_OCCURED: "An error has occured, please try again",
        PERMISSION: "Permission denied, please contact system admin",
        EMPTY_LOGIN: "Username and password cannot be empty",
        EMPTY_FIELDS: "Fields cannot be empty",
        INVALID_VALUES: "There are some invalid values, please check again",
        INVALID_PASSWORD:
            "Password must contain at least 8 characters and at least 1 special character"
    },
    MENU_TEXT: {
        REPORTS: "Reports",
        PARAMETER_NAME: "Parameter Name",
        TABLE_NAME: "Table Name",
        START_DATE: "Start Date",
        END_DATE: "End Date",
        CHARTS: "Chart",
        SYSTEM: "Plant Overview",
        ANOMALY: "Algorithm",
        POWER: "Power",
        HOME: "Home",
        SUPPORT: "Support",
        YEAR: " 2020-2023 BBP",
        COPY_RIGHT: "All Rights Reserved",
        PORTFOLIO_VIEW: "Portfolio View",
        TABLEAU: "Tableau",
        ONBOARDING: "Admin Settings",
        MAP_TABLE: "Map List",
        PLANT: "Plant",
        GROUP: "Group",
        PLANT_SETTINGS: "Plant Settings",
        MAINTENANCE_SETTINGS: "Maintenance Settings",
        ANALYTICS: "Analytics",
        PLANT_SCHEMATICS: "Plant Schematics",
        WATER_TREATMENT: "Water Treatment",
        PLANT_OVERVIEW: "Plant Overview",
        ANALYTICS_SETTINGS: "Analytics Settings",
        ADMIN_SETTINGS: "Admin Settings",
        USER_SETTINGS: "User Settings",
        SITE_ONBOARDING: "Site Onboarding",
        CHANGE_PASSWORD: "Change Password",
        PID: "PID",
        MONITORING: "Monitoring",
        APP_VERSION: "What's New",
        SAVINGS_OFFSET: "Savings Offset",
        SAVINGS_CONFIG: "Savings Configuration",
        MISSING_DATA_MAILER: "Missing Data Email",
        SITE_CONFIG: "Site Config",
        REPORTING: "Reporting",
        REPORTING_MISSING_DATA: "Missing Data",
        ML_SETTINGS: "ML Settings",
        CURRENCY_CONVERTER: "Currency Converter",
        WATER_TREATMENT_PLANT: "Water Treatment Plant",
        WATER_TREATMENT_SCHEMATICS: "Schematics",
        WATER_TREATMENT_DASHBOARD1: "Status",
        WATER_TREATMENT_DASHBOARD2: "Trend",
        WATER_TREATMENT_REPORT: "Report"
    },
    FETCHDATA_TEXT: {
        PARAMETER_NAME: "Parameter Name",
        TABLE_NAME: "Table Name",
        START_DATE: "Start Date",
        END_DATE: "End Date"
    },
    ANOMOLY_TEXT: {
        ANOMOLY_DETECT: "Anomalies Detected:",
        VIEWED: "Viewed",
        COST_IMPACT: "Cost Impact ($)",
        DATE: "January 25,2019",
        NEW: "NEW",
        COST: "-61.07",
        ANOMOLY: "Anomaly Detection",
        ACTUAL: "Actual kw and Predicted Kw",
        CAUSE: "What is the cause of deviation?",
        CHILLER_UTILIZATION: "Chiller Utilization",
        CONDENSOR_SENSOR: "Condensor sensor",
        CHILLER_POWER: "Chiller Power"
    },
    SYSTEM_OVERVIEW: {
        CHILLER_PERFORMANCE: "Chiller Plant Performance",
        CHILLER_PLANT: "Chiller Plant Performance Summary",
        CHILLER_EFFICIENCY: "Chiller Efficiency",
        PUMP_EFFICIENCY: "Pump Efficiency",
        COOLING_TOWER_EFFICIENCY: "Cooling Tower Efficiency",
        INTERVAL: ["Minutes", "Hours", "Days", "Weeks"],
        VIEW: ["Cooling Load", "Power", "Heat Balance"],
        VIEW_MAP_CHART: {
            "Cooling Load": `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYS_PLANT_RT`,
            Power: `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYS_PLANT_KW`,
            "Heat Balance": `${process.env.BASE_URL}/api/v1/siteSummary/chart/SYS_PLANT_HEATBAL`
        },
        TIME: ["Last 1 Weeks", "Last 2 Weeks", "Last 4 Weeks", "Last 2 Months", "Last 3 Months"],
        EQUIPMENT: "Equipment",
        EFFICIENCY: "Efficiency(kW/RTon)",
        ACTIVE_POWER: "Active Power(kW)"
    },
    PORTFOLIO_SCREEN: {
        ANOMALY: "Anomalies Detected",
        ALERTS: "New Alerts",
        NAME: "Site",
        SYS_PLANT_EFF: "Eff",
        SYS_PLANT_KW: "KW",
        SYS_PLANT_RT: "RT",
        TIME: ["Last 1 Weeks", "Last 2 Weeks", "Last 4 Weeks"]
    },
    ANALYTICS: {
        FAULT_LABEL: "Fault Label",
        FAULT_DURATION: "Fault Duration: ",
        ID: "Id",
        START: "Start Date/Time",
        DURATION: "Duration",
        CODE: "Code",
        PARAMETER: "Parameter",
        DESCRIPTION: "Diagnosis",
        VIEWED: "Viewed",
        LABEL: "Fault Labels",
        TAG: "Tags",
        NOTES: "Notes",
        USERTAG: "User Tags",
        ANALYTICS_OVERVIEW: "/dashboard/analyticsOverview",
        CHANGE_PASSWORD: "/dashboard/changePassword",
        LIFECYCLE_CHARTS: ["Chiller", "Chiller Pump", "Condenser Pump"],
        SUBMIT: "Submit",
        LAST: "Last",
        FAULT_EVENT: " Fault Events found"
    },
    ANOMALY_DIAGNOSIS: {
        START: "Date of Anomaly",
        DURATION: "Duration (mins)",
        PARAMETER: "Parameter",
        ENERGY_KW: "Energy Cons(kW)",
        PRED_ENERGY_KW: "Predicted Energy Cons(kW)",
        ACTUAL_EFF: "Actual Eff (kW/RT)",
        PREDICTED_EFF: "Predicted Eff (kW/RT)",
        EFF_DEVIATION: "Eff. Deviation (kW/RT)",
        EFF_DEV_PERCENT: "Eff. Deviation (%)",
        REASON_1: "1st Reason",
        REASON_2: "2nd Reason",
        REASON_3: "3rd Reason",
        COST_IMPACT: "Cost Impact ($)",
        WATER_IMPACT: "Water Impact",
        ENERGY_IMPACT: "Energy Impact (kWh)",
        STATUS: "Status",
        CAUSE: "Cause of Diagnosis",
        FEEDBACK: "Feedback",
        FEEDBACK_STATUS_LIST: ["PENDING ACK.", "ACKNOWLEDGED", "SOLVED", "NOT AN ISSUE"],
        UPDATED_ON: "Updated on",
        DIAGNOSIS: "Reason"
    },
    PID: {
        SP: "SP",
        PV: "PV",
        OUT: "OUT",
        AUTO: "AUTO",
        P: "P",
        I: "I",
        D: "D",
        DB: "DB",
        MAN: "MAN",
        MIN: "MIN",
        MAX: "MAX"
    },
    DASHBOARD_PATH: {
        REPORTS: "/dashboard/reports",
        FETCH_DATA: "/dashboard/fetchData",
        ALGORITHM: "/dashboard/chillerObserver",
        SITE_OVERVIEW: "/dashboard/plantOverview",
        LINK_VIEW: "/dashboard/linkView",
        PORTFOLIO_VIEW: "/dashboard/portfolioView",
        ONBOARDING: "/dashboard/onboarding",
        MAP_TABLE: "/dashboard/mapTable",
        PLANT: "/dashboard/plantTable",
        GROUP: "/dashboard/groupTable",
        PLANT_SETTINGS: "/dashboard/plantSettings",
        MAINTENANCE_SETTINGS: "/dashboard/maintenanceSettings",
        ANALYTICS_OVERVIEW: "/dashboard/analyticsOverview",
        PLANT_OVERVIEW: "/dashboard/plantSchematic",
        ANALYTICS_SETTINGS: "/dashboard/analyticsSettings",
        ADMIN_SETTINGS: "/dashboard/adminSettings",
        SITE_ONBOARDING: "/dashboard/siteOnboarding",
        CHANGE_PASSWORD: "/dashboard/changePassword",
        PID: "/dashboard/PID",
        FORGOT_PASSWORD: "/forgotPassword",
        LOGIN: "/",
        MONITORING: "/dashboard/monitoring",
        APP_VERSION: "/dashboard/version",
        SAVINGS_OFFSET: "/dashboard/savingsOffset",
        SAVINGS_CONFIG: "/dashboard/savingsConfig",
        MISSING_DATA_MAILER: "/dashboard/missingDataEmail",
        SITE_CONFIG: "/dashboard/siteConfig",
        REPORTING: "/dashboard/reporting",
        ML_SETTINGS: "/dashboard/mlSettings",
        CURRENCY_CONVERTER: "/dashboard/currencyConverter",
        WATER_TREATMENT_SCHEMATICS: "/dashboard/waterTreatment/schematics",
        WATER_TREATMENT_DASHBOARD1: "/dashboard/waterTreatment/dashboard1",
        WATER_TREATMENT_DASHBOARD2: "/dashboard/waterTreatment/dashboard2",
        WATER_TREATMENT_REPORT: "/dashboard/waterTreatment/report"
    },
    WAREHOUSE_PATH: {
        HOME: "/dwh/home",
        SITE_MANAGEMENT: "/dwh/site",
        SITE_OVERVIEW: "/dwh/site/overview",
        SITE_WATER_PUMP: "/dwh/site/waterpump",
        SITE_COOLING_TOWER: "/dwh/site/coolingtower",
        SITE_PID: "/dwh/site/pid",
        SITE_INSTRUMENT: "/dwh/site/instrument",
        SITE_CHILLER: "/dwh/site/chiller",
        SITE_ENVIRONMENT: "/dwh/site/environment",
        SCADA_MANAGEMENT: "/dwh/scada",
        SCADA_OVERVIEW: "/dwh/scada/overview",
        CHILLER_SPEC_MANAGEMENT: "/dwh/chiller",
        CHILLER_SPEC_OVERVIEW: "/dwh/chiller/overview",
        CHILLER_SPEC_PARTLOAD: "/dwh/chiller/partload",
        WATER_PUMP_SPEC_MANAGEMENT: "/dwh/waterpump",
        WATER_PUMP_SPEC_OVERVIEW: "/dwh/waterpump/overview",
        WATER_PUMP_SPEC_CURVE: "/dwh/waterpump/curve",
        PID_MANAGEMENT: "/dwh/pid",
        PID_OVERVIEW: "/dwh/pid/overview",
        PID_TIME_BASED: "/dwh/pid/overview/timeBased",
        PID_PRESSURE_BASED: "/dwh/pid/overview/pressureBased",
        PID_LOAD_BASED: "/dwh/pid/overview/loadBased",
        PID_TEMP_BASED: "/dwh/pid/overview/tempBased",
        COOLING_TOWER_MANAGEMENT: "/dwh/coolingtower",
        COOLING_TOWER_OVERVIEW: "/dwh/coolingtower/overview",
        INSTRUMENT: "/dwh/instrument",
        REPORTS: "/dwh/report",
        SETTING: "/dwh/setting",
        SUPPORT: "/dwh/support"
    },
    WAREHOUSE_MENU_TEXT: {
        HOME: "Home",
        SITE_MANAGEMENT: "Site Management",
        SCADA: "Scada",
        SCADA_MANAGEMENT: "Scada Management",
        CHILLER_SPECS: "Chiller Specs",
        CHILLER_SPEC_MANAGEMENT: "Chiller Specification Management",
        WATER_PUMP_SPECS: "Water Pump Specs",
        WATER_PUMP_SPEC_MANAGEMENT: "Water pump Specification Management",
        PID: "PID",
        PID_MANAGEMENT: "PID Management",
        COOLING_TOWER_SPECS: "Cooling Tower Specs",
        COOLING_TOWER_SPEC_MANAGEMENT: "Cooling Tower Specification Management",
        INSTRUMENT: "Instrument",
        REPORTS: "Reports",
        SETTING: "Setting",
        SUPPORT: "Support"
    },
    WAREHOUSE_TAB_TEXT: {
        SITE_OVERVIEW: "SITE OVERVIEW",
        SITE_WATER_PUMP: "SITE WATER PUMP",
        SITE_COOLING_TOWER: "SITE COOLING TOWER",
        SITE_PID: "SITE PID",
        SITE_INSTRUMENT: "SITE INSTRUMENT",
        SITE_CHILLER: "SITE CHILLER",
        SITE_ENVIRONMENT: "SITE ENVIRONMENT",
        SCADA_OVERVIEW: "SCADA OVERVIEW",
        CHILLER_SPEC_OVERVIEW: "OVERVIEW",
        CHILLER_SPEC_PARTLOAD: "PARTLOAD",
        WATER_PUMP_SPEC_OVERVIEW: "OVERVIEW",
        WATER_PUMP_SPEC_CURVE: "CURVE",
        PID_OVERVIEW: "OVERVIEW",
        PID_TIME_BASED: "TIME BASED",
        PID_PRESSURE_BASED: "PRESSURE BASED",
        PID_LOAD_BASED: "LOAD BASED",
        PID_TEMP_BASED: "TEMP BASED",
        COOLING_TOWER_SPEC_OVERVIEW: "OVERVIEW"
    },
    WAREHOUSE_TAB_VALUE: {
        SITE_OVERVIEW: "siteOverview",
        SITE_WATER_PUMP: "siteWaterPump",
        SITE_COOLING_TOWER: "siteCoolingTower",
        SITE_PID: "sitePid",
        SITE_INSTRUMENT: "siteInstrument",
        SITE_CHILLER: "siteChiller",
        SITE_ENVIRONMENT: "siteEnvironment",
        SCADA_OVERVIEW: "scadaOverview",
        CHILLER_SPEC_OVERVIEW: "chillerSpecOverview",
        CHILLER_SPEC_PARTLOAD: "chillerSpecPartload",
        WATER_PUMP_SPEC_OVERVIEW: "waterPumpOverview",
        WATER_PUMP_SPEC_CURVE: "waterPumpCurve",
        PID_OVERVIEW: "pidOverview",
        PID_TIME_BASED: "timeBased",
        PID_PRESSURE_BASED: "pressureBased",
        PID_LOAD_BASED: "loadBased",
        PID_TEMP_BASED: "tempBased",
        COOLING_TOWER_SPEC_OVERVIEW: "coolingTowerOverview"
    },
    REPORT_TAB_TEXT: {
        SITE_REPORT: "SITE REPORT",
        SAVINGS_REPORT: "SAVINGS REPORT",
        DYNAMIC_REPORT: "DYNAMIC REPORT"
    },
    REPORT_TAB_VALUE: {
        SITE_REPORT: "SITE_REPORT",
        SAVINGS_REPORT: "SAVINGS_REPORT",
        DYNAMIC_REPORT: "DYNAMIC_REPORT"
    },
    PID_TAB_TEXT: {
        OVERALL: "OVERALL",
        AUTO_ML: "AUTO ML",
        OPTIMIZATION: "RULE BASED OPTIMIZATION"
    },
    PID_TAB_VALUE: {
        OVERALL: "OVERALL",
        AUTO_ML: "AUTO_ML",
        OPTIMIZATION: "OPTIMIZATION"
    },
    ML_SETTINGS_TAB_TEXT: {
        ML_SETTINGS: "AUTO ML",
        AIRFLOW: "AIRFLOW",
        SITE_DAG: "SITE DAG"
    },
    ML_SETTINGS_TAB_VALUE: {
        ML_SETTINGS: "AUTO_ML",
        AIRFLOW: "AIRFLOW",
        SITE_DAG: "SITE_DAG"
    },
    RULE_BASED_OPTIMIZATION_TAB_TEXT: {
        COOLING_TOWER: "COOLING TOWER",
        CONDENSER_WATER_PUMP: "CONDENSER WATER PUMP",
        CHILLED_WATER_PUMP: "CHILLED WATER PUMP"
    },
    RULE_BASED_OPTIMIZATION_TAB_VALUE: {
        COOLING_TOWER: "COOLING_TOWER",
        CONDENSER_WATER_PUMP: "CONDENSER_WATER_PUMP",
        CHILLED_WATER_PUMP: "CHILLED_WATER_PUMP"
    },
    RULE_BASED_OPTIMIZATION_CT_TAB_TEXT: {
        CAPACITY: "CAPACITY",
        SETPOINT: "SETPOINT"
    },
    RULE_BASED_OPTIMIZATION_CT_TAB_VALUE: {
        CAPACITY: "CAPACITY",
        SETPOINT: "SETPOINT"
    },
    RULE_BASED_OPTIMIZATION_CDWP_TAB_TEXT: {
        MIN_MAX_LOAD: "MIN MAX LOAD",
        MIN_MAX_TIME: "MIN MAX TIME",
        CONFIG: "CONFIG"
    },
    RULE_BASED_OPTIMIZATION_CDWP_TAB_VALUE: {
        MIN_MAX_LOAD: "MIN_MAX_LOAD",
        MIN_MAX_TIME: "MIN_MAX_TIME",
        CONFIG: "CONFIG"
    },
    RULE_BASED_OPTIMIZATION_CHWP_TAB_TEXT: {
        MIN_MAX_LOAD: "MIN MAX LOAD",
        MIN_MAX_TIME: "MIN MAX TIME",
        CONFIG: "CONFIG"
    },
    RULE_BASED_OPTIMIZATION_CHWP_TAB_VALUE: {
        MIN_MAX_LOAD: "MIN_MAX_LOAD",
        MIN_MAX_TIME: "MIN_MAX_TIME",
        CONFIG: "CONFIG"
    },
    COLOUR_YELLOW: "#FFE600",
    COLOUR_NEW_YELLOW: "#FFC600",
    COLOUR_DARK_BLUE: "#002A41",
    COLOUR_LIGHT_BLUE: "#00AEEF",

    VERSION: "v3.9",
    VERSION_DATA_PIPELINE: "2",
    VERSION_TABLEAU: "1"
};
export default VERBIAGE;
