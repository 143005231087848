import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Grid,
    FormLabel
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import API from "../../../utils/API";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { getResponseErrorDetails, scrollIntoFirstErrorElement } from "../../../utils/helpers";
import useQueryParams from "../../../hooks/useQueryParams";

import FormRenderField from "../../../dashboard/common/components/FormRenderField";

const PidTimeBasedSchema = Yup.object().shape({
    from: Yup.string().required(),
    to: Yup.string().required(),
    sp: Yup.number().required()
});

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    },
    hide: {
        display: "none"
    }
}));

const EditPidSpec = forwardRef((props, ref) => {
    const { baseUrl, title, isEdit, open, setOpen, onSuccess } = props;

    const { getQuery } = useQueryParams();
    const pidId = getQuery("pidId");

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const initData = {
        from: VERBIAGE.TEXT.EMPTY_STRING,
        to: VERBIAGE.TEXT.EMPTY_STRING,
        sp: 0,
        isActive: true
    };
    const [values, setValues] = useState(initData);

    const {
        control,
        handleSubmit,
        reset,
        setError: setFormError,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(PidTimeBasedSchema),
        values
    });

    useImperativeHandle(ref, () => ({
        init() {
            setValues({ ...initData });
        },
        update(data) {
            setValues({ ...initData, ...data });
        }
    }));

    useEffect(() => {
        if (Object.keys(errors).length) {
            scrollIntoFirstErrorElement({
                elementIds: Object.keys(errors)
            });
        } else {
            setError(null);
        }
    }, [errors]);

    const onClose = () => {
        reset(initData);
        setError(null);
        setOpen(false);
    };

    const handleResponseError = response => {
        const resError = getResponseErrorDetails(response);
        if (resError.type === "invalid") {
            Object.keys(resError.errors).forEach(field => {
                setFormError(field, { type: "invalid", message: resError.errors[field] });
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstErrorElement({ elementIds: Object.keys(resError.errors) });
        } else {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        }
    };

    const onSubmit = async data => {
        try {
            setLoading(true);

            const requestConfig = {
                data,
                headers: {
                    "x-auth-token": localStorage.token
                }
            };
            if (values._id) {
                requestConfig.method = "PUT";
                requestConfig.url = `${baseUrl}/${values._id}`;
            } else {
                requestConfig.method = "POST";
                requestConfig.url = `${baseUrl}/${pidId}/pid`;
            }
            const response = await API.request(requestConfig);
            if (response.data?.status === "error") {
                handleResponseError(response);
                return;
            }
            if (onSuccess) {
                onSuccess(pidId);
            }
            onClose();
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else if (err.response.status === 404) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            } else {
                handleResponseError(err.response);
            }
        } finally {
            setLoading(false);
        }
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    const classes = useStyles();

    const inputs = [
        {
            name: "from",
            label: "FROM",
            type: "time",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_FROM,
            required: true
        },
        {
            name: "to",
            label: "TO",
            type: "time",
            numberType: "double",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_TO,
            required: true
        },
        {
            name: "sp",
            label: "SP",
            type: "number",
            numberType: "double",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_SP,
            required: true
        },
        {
            name: "isActive",
            label: "Active",
            type: "switch",
            switchLabels: { true: "Yes", false: "No" },
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_IS_ACTIVE
        }
    ];

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-site-dialog">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} ${title} data enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    {inputs.map(input => {
                        if (input.hide) return null;
                        return (
                            <Grid item container alignItems="center" key={input.name}>
                                <Grid item xs={3}>
                                    <FormLabel required={input.required} error={errors[input.name]}>
                                        {input.label}
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={9}>
                                    <Controller
                                      name={input.name}
                                      control={control}
                                      render={({ field: { value, onChange } }) => (
                                            <FormRenderField
                                              input={input}
                                              value={value}
                                              onChange={onChange}
                                              errors={errors}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                    {!!error && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography variant="subtitle1">{error}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                  color="primary"
                  onClick={handleSubmit(onSubmit, onError)}
                  disabled={loading}
                >
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={onClose} disabled={loading}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default EditPidSpec;
