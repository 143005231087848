import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const ReportTableauChartDefault = props => {
    const { fullWidth } = props;
    const height = window.innerHeight - 170;

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(0.5),
            color: theme.palette.text.secondary,
            height: fullWidth ? "85vh" : height
        },
        spacing: {
            opacity: 0
        },
        grid: {
            padding: "8px 5px",
            marginTop: "-1.5vh",
            [theme.breakpoints.up("xs")]: {
                marginTop: "5.5vh"
            },
            [theme.breakpoints.up("md")]: {
                marginTop: "-1.5vh"
            },
            [theme.breakpoints.up("sm")]: {
                marginTop: "-1.5vh"
            },
            [theme.breakpoints.up("lg")]: {
                marginTop: "-3vh"
            },
            width: fullWidth ? "880px" : height / 1.414
        }
    }));

    const classes = useStyles();

    return (
        <Grid container className={classes.grid}>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0} />
            </Grid>
        </Grid>
    );
};

export default ReportTableauChartDefault;
