/* eslint-disable react/destructuring-assignment  */
/* eslint-disable no-unused-vars  */
import React, { useState } from "react";
import { Grid, Tab, Tabs, useMediaQuery } from "@material-ui/core";

const LifecycleTabs = props => {
    const { classes, tabStyle, value, handleChange } = props;

    const isLifecycleCTDeveloped = localStorage.getItem("isLifecycleCTDeveloped");
    const isLifecycleCHWPDeveloped = localStorage.getItem("isLifecycleCHWPDeveloped");
    const isLifecycleCDWPDeveloped = localStorage.getItem("isLifecycleCDWPDeveloped");
    const isAnalyticsBeingDeveloped = localStorage.getItem("isAnalyticsBeingDeveloped");
    const permission = localStorage.getItem("permission");
    return (
        <Grid container className={classes.tabContainer}>
            <Grid item xs={12} style={{ marginTop: -15 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons={useMediaQuery("(max-width: 850px)") ? "on" : "auto"}
                  classes={{
                        fixed: classes.fixed,
                        scrollable: classes.fixed
                    }}
                >
                    <Tab style={tabStyle} value="overview" label="Overview" wrapped />
                    <Tab style={tabStyle} value="chiller" label="Chiller" wrapped />
                    <Tab
                      style={tabStyle}
                      value="coolingTower"
                      label="Cooling Tower"
                      wrapped
                      disabled={
                            !(
                                ((permission === "admin_access" ||
                                    permission === "developer_access") &&
                                    isAnalyticsBeingDeveloped === "true") ||
                                isLifecycleCTDeveloped === "true"
                            )
                        }
                    />
                    <Tab
                      style={tabStyle}
                      value="chwPump"
                      label="Chilled Water Pump"
                      wrapped
                      disabled={
                            !(
                                ((permission === "admin_access" ||
                                    permission === "developer_access") &&
                                    isAnalyticsBeingDeveloped === "true") ||
                                isLifecycleCHWPDeveloped === "true"
                            )
                        }
                    />
                    <Tab
                      style={tabStyle}
                      value="cdwPump"
                      label="Condenser Water Pump"
                      wrapped
                      disabled={
                            !(
                                ((permission === "admin_access" ||
                                    permission === "developer_access") &&
                                    isAnalyticsBeingDeveloped === "true") ||
                                isLifecycleCDWPDeveloped === "true"
                            )
                        }
                    />
                </Tabs>
            </Grid>
        </Grid>
    );
};

export default LifecycleTabs;
