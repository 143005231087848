import PIDActionTypes from "./PIDActionTypes";

const initialState = {
    pidData: []
};
const PIDReducer = (state = initialState, action) => {
    switch (action.type) {
        case PIDActionTypes.SET_DATA:
            return {
                ...state,
                pidData: action.data
            };
        default:
            return state;
    }
};
export default PIDReducer;
