import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Button,
    Grid,
    Paper,
    Typography,
    Dialog,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    makeStyles
} from "@material-ui/core";

import useQueryParams from "../../hooks/useQueryParams";

import Colors from "../../utils/Colors";
import api from "../../utils/API";
import URL from "../../utils/URL";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    label: {
        color: Colors.DARK_BLUE
    },
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1)
        }
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        "& img": {
            objectFit: "contain"
        }
    }
}));

const SiteSelector = () => {
    const { getQuery, setQuery } = useQueryParams();
    const [open, setOpen] = useState(false);
    const [selectedSiteNumber, setSelectedSiteNumber] = useState(getQuery("siteNumber"));
    const [sites, setSites] = useState([]);

    const history = useHistory();
    const location = useLocation();

    const isMounted = useRef(false);

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await api.get(URL.DATA.DWH.SITE_DETAILS, {
                    headers: {
                        "x-auth-token": localStorage.token
                    },
                    params: {
                        isActive: true,
                        _select: ["siteId", "siteName", "siteLogo"],
                        _sort: "siteName",
                        _order: "ASC"
                    }
                });

                if (isMounted.current) {
                    setSites(response.data);
                }
            } catch (error) {
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchSites();

        return () => (isMounted.current = false);
    }, []);

    useEffect(() => {
        const dwhSiteNumber = localStorage.getItem("dwhSiteNumber");
        if (!selectedSiteNumber && dwhSiteNumber) {
            setSelectedSiteNumber(dwhSiteNumber);
            history.push({
                pathname: location.pathname,
                search: `?siteNumber=${dwhSiteNumber}`
            });
        }
    }, [selectedSiteNumber, location.pathname, history]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSelect = siteNumber => {
        if (siteNumber) {
            setSelectedSiteNumber(siteNumber);
            localStorage.setItem("dwhSiteNumber", siteNumber);
            history.push({
                pathname: location.pathname,
                search: setQuery({ siteNumber })
            });
        } else {
            localStorage.removeItem("dwhSiteNumber");
        }
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={3} lg={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!sites.length}
                      onClick={handleOpen}
                    >
                        Select Site
                    </Button>
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    {selectedSiteNumber && (
                        <Typography className={classes.label}>
                            Selected Site Number: 
{' '}
{selectedSiteNumber}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <SiteSelectorDialog
              fullWidth
              maxWidth="xs"
              open={open}
              onClose={handleClose}
              onSelect={handleSelect}
              sites={sites}
              selected={selectedSiteNumber}
            />
        </Paper>
    );
};

const SiteSelectorDialog = ({ sites, selected, onSelect, ...props }) => {
    const classes = useStyles();

    return (
        <Dialog {...props}>
            <List>
                {sites.map(site => (
                    <ListItem
                      key={site.siteName}
                      selected={site.siteId === selected}
                      onClick={() => onSelect(site.siteId)}
                      className={classes.root}
                      button
                    >
                        <ListItemAvatar>
                            <Avatar
                              src={site.siteLogo}
                              alt={site.siteName}
                              variant="square"
                              className={classes.large}
                            />
                        </ListItemAvatar>
                        <ListItemText primary={site.siteName} secondary={site.siteId} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default SiteSelector;
