import React, { useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";

import RuleBasedOptimizationCTCapacity from "./RuleBasedOptimizationCTCapacity";
import RuleBasedOptimizationCTSetpoint from "./RuleBasedOptimizationCTSetpoint";
import { VERBIAGE } from "../../../utils/enums";

const useStyles = makeStyles(theme => ({
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`
    }
}));
const RuleBasedOptimizationCT = () => {
    const tabs = useMemo(
        () => [
            {
                label: VERBIAGE.RULE_BASED_OPTIMIZATION_CT_TAB_TEXT.CAPACITY,
                value: VERBIAGE.RULE_BASED_OPTIMIZATION_CT_TAB_VALUE.CAPACITY
            },
            {
                label: VERBIAGE.RULE_BASED_OPTIMIZATION_CT_TAB_TEXT.SETPOINT,
                value: VERBIAGE.RULE_BASED_OPTIMIZATION_CT_TAB_VALUE.SETPOINT
            }
        ],
        [],
    );

    const [tab, setTab] = useState(tabs[0].value);

    const handleChangeTab = (_, selectedTab) => {
        setTab(selectedTab);
    };

    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Tabs
                  orientation="vertical"
                  value={tab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="Rule based optimization CT vertical tabs"
                  className={classes.tabs}
                >
                    {tabs.map(t => (
                        <Tab key={t.value} value={t.value} label={t.label} />
                    ))}
                </Tabs>
            </Grid>
            <Grid item xs={10}>
                <RuleBasedOptimizationCTTabPanel tab={tab} />
            </Grid>
        </Grid>
    );
};

const RuleBasedOptimizationCTTabPanel = ({ tab }) => {
    switch (tab) {
        case VERBIAGE.RULE_BASED_OPTIMIZATION_CT_TAB_VALUE.CAPACITY:
            return <RuleBasedOptimizationCTCapacity />;
        case VERBIAGE.RULE_BASED_OPTIMIZATION_CT_TAB_VALUE.SETPOINT:
            return <RuleBasedOptimizationCTSetpoint />;
        default:
    }
    return null;
};

export default RuleBasedOptimizationCT;
