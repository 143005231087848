import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";

import PIDCloudButton from "./PID_SPCloudButton";
import MLActions from "../../redux/MLActions";
import ToggleButton from "./components/ToggleButton";

const PIDSPCloudStatus = ({ disabled, ...setpoint }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") return;
        setOpen(false);
    };

    const { SP, cloud_status: enable, cloud_registry: address, loading } = setpoint;

    const dispatch = useDispatch();

    const handleToggleStatus = () => {
        dispatch(MLActions.postSetpointStatus(SP, !enable));
        setOpen(false);
    };

    return (
        <>
            <PIDCloudButton {...setpoint} disabled={disabled} onClick={handleOpen} />
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Typography>
                        Are you sure want to &nbsp;
                        <strong>{enable ? "Disable" : "Enable"}</strong>
                        &nbsp;Setpoint&nbsp;
                        <strong>{`${SP} (address: ${address})`}</strong>
?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <ToggleButton color="primary" disabled={loading} onClick={handleToggleStatus}>
                        {enable ? "Disable" : "Enable"}
                    </ToggleButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
PIDSPCloudStatus.propTypes = {
    enabled: PropTypes.bool,
    sp: PropTypes.string,
    lastUpdatedAt: PropTypes.string,
    disabled: PropTypes.bool
};
PIDSPCloudStatus.defaultProps = {
    enabled: false,
    sp: null,
    lastUpdatedAt: null,
    disabled: false
};

export default PIDSPCloudStatus;
