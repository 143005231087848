import React from "react";
import MapTable from "./MapTable";
import SiteTable from "./SiteTable";
import GroupTable from "./GroupTable";
import GaugeRangeTable from "./GaugeRangeTable";
import SchematicsTable from "./SchematicsTable";
import PIDDataTable from "./PIDDataTable";
// import SavingsTable from "./SavingsTable";
import AssetsListTable from "./siteAssets/AssetsListTable";
import AssetsMongoDBListTable from "./siteAssets/AssetsMongoDBListTable";
import { isEdgeUI } from "../../../utils/helpers";

const PlantTabs = props => {
    const { value } = props;

    return (
        <div>
            {value === "maps" && <MapTable />}
            {value === "sites" && <SiteTable />}
            {value === "groups" && <GroupTable />}
            {value === "gaugeRange" && <GaugeRangeTable />}
            {value === "schematics" && <SchematicsTable />}
            {/* value === "baselines" && <BaselineTable /> */}
            {/* value === "targets" && <TargetTable /> */}
            {/* value === "tariffs" && <TariffTable /> */}
            {value === "piddata" && <PIDDataTable />}
            {/* value === "savings" && <SavingsTable /> */}
            {value === "assets" && !isEdgeUI() && <AssetsListTable />}
            {value === "assets" && isEdgeUI() && <AssetsMongoDBListTable />}
        </div>
    );
};

export default PlantTabs;
