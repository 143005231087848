/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Typography,
    Link
} from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { VERBIAGE } from "../../../../utils/enums";
import api from "../../../../utils/API";

const AssetUpload = props => {
    const { open, setOpen, handleClick, url } = props;
    const [error, setError] = useState("");
    const [imageBase64, setImageBase64] = useState();

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImageBase64(reader.result.replace(/^data:.+;base64,/, ""));
            };
        });
    }, []);

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        multiple: false,
        onDrop
    });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>{`${file.path} - ${file.size} bytes`}</li>
    ));

    useEffect(() => {
        acceptedFiles.length = 0;
    }, [open]);

    const handleSubmit = async () => {
        const data = {
            data: {
                name: acceptedFiles[0].path,
                type: acceptedFiles[0].type,
                imageBase64
            }
        };
        try {
            const response = await api.post(url, data, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
        } catch (err) {
            if (err.response && err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            return;
        }
        setOpen(false);
    };

    const baseStyle = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out"
    };

    const activeStyle = {
        borderColor: "#2196f3"
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {})
        }),
        [baseStyle, isDragActive, activeStyle],
    );

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClick}>
            <DialogTitle>
                To upload a file, click or drag and drop the file into the box below
            </DialogTitle>
            <DialogContent>
                <section className="container">
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>
                            Drag and drop some files here, or 
{' '}
<Link>click to select files</Link>
                        </p>
                    </div>
                    <aside>
                        <h4>Files:</h4>
                        <ul>{files}</ul>
                    </aside>
                </section>

                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssetUpload;
