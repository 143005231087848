/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import MLActions from "../../redux/MLActions";
import { toClientDate } from "../../../utils/helpers";
import LoadingRetry from "../components/LoadingRetry";
import PIDAutoMLAirflowDialog from "./PIDAutoMLAirflowDialog";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(2)
    }
}));
const PIDAutoMLTrain = () => {
    const { train } = useSelector(state => state.ML);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(MLActions.getTrainLastDagRun());
    }, [dispatch]);

    const openTrainDialog = () => {
        setOpen(true);
        dispatch(MLActions.getTrainDagRuns());
    };

    const closeTrainDialog = () => {
        setOpen(false);
    };

    const handleTrigger = () => {
        dispatch(MLActions.triggerTrainDagRun());
    };

    const classes = useStyles();
    return (
        <>
            <Grid item>
                <Typography variant="subtitle1" align="center">
                    Last Training
                </Typography>
            </Grid>
            <Grid item>
                <LoadingRetry
                  loading={train.loading}
                  loadingText="Loading training data..."
                  error={!!train.error}
                  errorText={train.error}
                  retryFn={() => dispatch(MLActions.getTrainLastDagRun())}
                />
                {!train.error && train.lastDagRun && (
                    <Typography align="center">
                        {toClientDate(train.lastDagRun?.execution_date)}
                    </Typography>
                )}
            </Grid>
            <Grid item container justifyContent="center">
                {!train.error && train.dagId && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={openTrainDialog}
                    >
                        Train Now
                    </Button>
                )}
            </Grid>
            <PIDAutoMLAirflowDialog
              title="Train"
              runningText="Training"
              submitText="Train Now"
              airflow={train}
              open={open}
              onClose={closeTrainDialog}
              onSubmit={handleTrigger}
            />
        </>
    );
};

export default PIDAutoMLTrain;
