/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { Button, Typography, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import VERBIAGE from "../../../utils/enums/Verbiage";

const useStyles = makeStyles(theme => ({
    icon: {
        alignItems: "center",
        width: "18px",
        height: "18px"
    },
    text: {
        fontWeight: 500
    },
    active: {
        color:
            theme.palette.type === "dark" ? VERBIAGE.COLOUR_LIGHT_BLUE : VERBIAGE.COLOUR_LIGHT_BLUE, // "#3865A7",
        "& $icon": {
            filter: " brightness(0.7) sepia(1) hue-rotate(180deg) saturate(7)"
            // filter: " brightness(0.3) sepia(1) hue-rotate(140deg) saturate(6)"
        }
    }
}));

const MenuIcon = props => {
    const { path, onClick, text, src, alt, isMobile, isTablet, exact = true } = props;
    const classes = useStyles();

    return (
        <div>
            {path ? (
                <Button
                  activeClassName={classes.active}
                  className="sidebar-link"
                  component={RouterLink}
                  exact={exact}
                  to={path}
                  style={isTablet ? { display: "block", textAlign: "center" } : {}}
                  onClick={onClick}
                  title={text}
                >
                    {isTablet ? (
                        <>
                            <div>
                                <img src={src} alt={alt} className={classes.icon} />
                            </div>
                            <div>
                                <span style={{ paddingLeft: "0" }} className="icon-text">
                                    {text}
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <img src={src} alt={alt} className={classes.icon} />
                            {!isMobile && (
                                <Typography
                                  variant="subtitle1"
                                  component="span"
                                  align="left"
                                  className={classNames(classes.text, "icon-text")}
                                >
                                    {text}
                                </Typography>
                            )}
                        </>
                    )}
                </Button>
            ) : (
                <Button
                    // activeClassName={classes.active}
                  className="sidebar-link"
                  style={isTablet ? { display: "block", textAlign: "center" } : {}}
                  onClick={onClick}
                >
                    {isTablet ? (
                        <>
                            <div>
                                <img src={src} alt={alt} className={classes.icon} />
                            </div>
                            <div>
                                <span style={{ paddingLeft: "0" }} className="icon-text">
                                    {text}
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <img src={src} alt={alt} className={classes.icon} />
                            {!isMobile && (
                                <span className="icon-text" style={{ textAlign: "left" }}>
                                    {text}
                                </span>
                            )}
                        </>
                    )}
                </Button>
            )}
        </div>
    );
};
MenuIcon.propTypes = {
    path: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func
};
MenuIcon.defaultProps = {
    path: VERBIAGE.TEXT.EMPTY_STRING,
    src: VERBIAGE.TEXT.EMPTY_STRING,
    alt: VERBIAGE.TEXT.EMPTY_STRING,
    text: VERBIAGE.TEXT.EMPTY_STRING,
    onClick: () => {}
};
export default MenuIcon;
