import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Grid,
    Tabs,
    Tab
} from "@material-ui/core";

import VERBIAGE from "../../../utils/enums/Verbiage";
import useQueryParams from "../../../hooks/useQueryParams";

import TextInput from "../../../dashboard/common/components/TextInput";
import TagSiteWaterPumpChiller from "./TagSiteWaterPumpChiller";
import TagSiteWaterPumpInstrument from "./TagSiteWaterPumpInstrument";

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    },
    hide: {
        display: "none"
    }
}));

const TagSiteWaterPump = forwardRef((props, ref) => {
    const { open, setOpen } = props;

    const { getQuery } = useQueryParams();
    const siteNumber = getQuery("siteNumber");

    const [error, setError] = useState(null);
    const [waterPump, setWaterPump] = useState({});

    useImperativeHandle(ref, () => ({
        set(data) {
            setWaterPump({ ...data });
        }
    }));

    const [value, setValue] = useState("chiller");

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const handleError = e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
    };

    const onClose = () => {
        setError(null);
        setOpen(false);
    };

    const classes = useStyles();

    return (
        <Dialog
          fullWidth
          maxWidth="md"
          aria-labelledby="form-tag-dialog"
          open={open}
          onClose={onClose}
        >
            <DialogTitle>{`To tag site's water pump data enter the details below.`}</DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item container alignItems="center">
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">Water Pump ID</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextInput value={waterPump.wpId} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                            >
                                <Tab value="chiller" label="CHILLER" wrapped />
                                <Tab value="instrument" label="INSTRUMENT" wrapped />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12}>
                            {value === "chiller" && (
                                <TagSiteWaterPumpChiller
                                  id={waterPump._id}
                                  siteNumber={siteNumber}
                                  onError={handleError}
                                />
                            )}
                            {value === "instrument" && (
                                <TagSiteWaterPumpInstrument
                                  id={waterPump._id}
                                  siteNumber={siteNumber}
                                  onError={handleError}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {!!error && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography variant="subtitle1">{error}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button className={classes.error} onClick={onClose}>
                    {VERBIAGE.BUTTONS.CLOSE2}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default TagSiteWaterPump;
