/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-unused-vars  */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Typography, makeStyles, Button } from "@material-ui/core";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/login.scss";
import TextInput from "../../../dashboard/common/components/TextInput";
import URL from "../../../utils/URL";
import api from "../../../utils/API";

const ResetPassword = props => {
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [status, setStatus] = useState("");
    const [success, setSuccess] = useState(false);

    const { match } = props;

    const handlePassword = event => {
        setPassword(event.target.value);
    };

    const handlePasswordConfirm = event => {
        setPasswordConfirm(event.target.value);
    };

    const handleClick = () => {
        props.history.push(VERBIAGE.DASHBOARD_PATH.LOGIN);
    };

    const handleSubmit = async () => {
        if (password !== passwordConfirm) {
            setStatus(VERBIAGE.ERROR_TEXT.PASSWORDS);
            return;
        }

        if (!VERBIAGE.TEXT.PASSWORD_REGEX.test(password)) {
            setStatus(VERBIAGE.ERROR_TEXT.INVALID_PASSWORD);
            return;
        }

        const { token } = match.params;
        const url = `${URL.AUTHENTICATION.RESET_PASSWORD}/${token}`;
        const data = {
            password,
            passwordConfirm
        };
        const response = await api.put(url, data);
        if (response.data.status === "ok") {
            setSuccess(true);
            setStatus(VERBIAGE.TEXT.RESET_PASSWORD_SUCCESS);
        } else {
            setStatus(VERBIAGE.TEXT.RESET_PASSWORD_FAIL);
        }
    };

    const useStyles = makeStyles(theme => ({
        status: {
            color: success ? theme.palette.success.dark : theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center",
            margin: "2vh 0 2vh 0"
        },
        button: {
            marginTop: "20px"
        },
        main: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center",
            background: "#ffffff"
        },
        sub: {
            border: "2px solid #006ab4",
            height: "400px",
            width: "35%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        input: {
            height: "50px",
            width: "70%",
            fontSize: "large",
            marginBottom: "30px"
        }
    }));

    const classes = useStyles();

    return (
      <div className={classes.main}>
        <h1 className={ResetPassword.style.header}>{VERBIAGE.TEXT.RESET_PASSWORD}</h1>
        <div className={classes.sub}>
          <div className={classes.input}>
            <TextInput
              onChange={event => handlePassword(event)}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.PASSWORD}
              value={password}
              type="password"
            />
          </div>
          <div className={classes.input}>
            <TextInput
              onChange={event => handlePasswordConfirm(event)}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.CONFIRM_PASSWORD}
              value={passwordConfirm}
              type="password"
            />
          </div>
          <input
            type="button"
            className={ResetPassword.style.button}
            value={VERBIAGE.BUTTONS.SUBMIT}
            onClick={handleSubmit}
          />
          {success && (
            <Button
              color="primary"
              size="large"
              className={classes.button}
              onClick={handleClick}
            >
              Back to Login
            </Button>
                )}
          <div className={classes.status}>
            <Typography>{status}</Typography>
          </div>
        </div>
      </div>
    );
};

ResetPassword.style = {
    mainContainer: "webclient__login-screen-container-main",
    subContainer: "webclient__login-screen-container-sub",
    inputContainer: "webclient__login-screen-container-input",
    button: "webclient__login-screen-button",
    header: "webclient__login-screen-header",
    forgotPassword: "webclient__login-screen-forgot-password",
    errorText: "webclient__login-screen-error-text"
};
export default withRouter(ResetPassword);
