import React, { useState, useEffect } from "react";
import {
    Box,
    Checkbox,
    CircularProgress,
    createMuiTheme,
    makeStyles,
    MuiThemeProvider
} from "@material-ui/core";

import moment from "moment";

import MUIDataTable from "mui-datatables";

import API from "../../../utils/API";
import URL from "../../../utils/URL";

const TagSiteInstrumentChiller = props => {
    const { id, siteNumber, onError } = props;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async (_id, siteId) => {
            try {
                setLoading(true);

                const url = `${URL.DATA.DWH.SITE_INSTRUMENT}/${siteId}/chillers/${_id}`;
                const response = await API.get(url, {
                    params: {
                        _sort: "id",
                        _order: "ASC"
                    },
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data) {
                    setData(response.data);
                }
            } catch (err) {
                if (err.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData(id, siteNumber);
    }, [id, siteNumber]);

    const toggleTag = async (waterPumpId, chillerId, tagged) => {
        try {
            let url = `${URL.DATA.DWH.SITE_INSTRUMENT}/${waterPumpId}/chiller/${chillerId}`;
            if (tagged) {
                url += "/add";
            } else {
                url += "/remove";
            }
            await API.put(url, null, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
        } catch (err) {
            if (err.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            onError(err);
        }
    };

    const handleChange = dataIndex => {
        return e => {
            const tagged = e.target.checked;
            setData(
                data.map((item, i) => {
                    if (i === dataIndex) {
                        return { ...item, tagged };
                    }
                    return { ...item };
                }),
            );
            toggleTag(id, data[dataIndex]._id, tagged);
        };
    };

    const columns = [
        {
            name: "",
            options: {
                customBodyRenderLite: dataIndex => {
                    return (
                        <Checkbox
                          checked={data[dataIndex].tagged}
                          onChange={handleChange(dataIndex)}
                          name="tagged"
                          color="primary"
                        />
                    );
                }
            }
        },
        { label: "Chiller ID", name: "chId" },
        {
            label: "Created Date",
            name: "createdAt",
            options: {
                customBodyRender: value => moment(value).format("DD-MM-YYYY HH:mm:ss")
            }
        },
        {
            label: "Updated Date",
            name: "updatedAt",
            options: {
                customBodyRender: value => moment(value).format("DD-MM-YYYY HH:mm:ss")
            }
        }
    ];

    const options = {
        selectableRows: "none",
        search: false,
        filter: false,
        print: false,
        viewColumns: false,
        download: false,
        elevation: 0,
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress size={24} />
                ) : (
                    "Sorry, there is no matching data to display"
                )
            }
        }
    };

    const getMUITheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTableHeadCell: {
                    root: {
                        "&:nth-child(1)": {
                            width: 50
                        }
                    }
                }
            }
        });

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    return (
        <Box className={classes.table}>
            <MuiThemeProvider theme={getMUITheme()}>
                <MUIDataTable data={data} columns={columns} options={options} />
            </MuiThemeProvider>
        </Box>
    );
};

export default TagSiteInstrumentChiller;
