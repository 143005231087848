/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-unused-vars  */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Typography, makeStyles, Button, Link, Grid } from "@material-ui/core";
import crypto from "crypto";
import { GoogleLogin } from "react-google-login";
import VERBIAGE from "../../../utils/enums/Verbiage";
import ICONS from "../../../utils/enums/IconData";
import AuthenticationActions from "../../redux/AuthenticationActions";
import "../styles/login.scss";
import TextInput from "../../../dashboard/common/components/TextInput";
import URL from "../../../utils/URL";

const clientId = `${process.env.GOOGLE_CLIENT_ID}`;
const algorithm = `${process.env.ALGORITHM}`;
const secretKey = `${process.env.SECRET_KEY}`;
const encryptedLogin = `${process.env.ENCRYPTED_LOGIN}` === "true";

const Login = props => {
    const { authentication, postCredentials } = props;
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleUsernameChange = event => {
        setUserName(event.target.value);
    };
    const handleUserPasswordChange = event => {
        setPassword(event.target.value);
    };
    const handleClick = () => {
        props.history.push(VERBIAGE.DASHBOARD_PATH.FORGOT_PASSWORD);
    };

    const handleSubmit = async () => {
        if (!userName || !password) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_LOGIN);
            return;
        }
        const data = { googleSignIn: false, email: userName, password };

        await postCredentials(data, algorithm, secretKey);
        // await postCredentials({ userName, password });
        if (authentication.isUserAuthenticated) {
            props.history.push(VERBIAGE.DASHBOARD_PATH.SITE_OVERVIEW);
        }
    };
    const redirectToDashboard = () => {
        const isUserAuthenticated = localStorage.getItem("isUserAuthenticated");
        if (isUserAuthenticated === "true") {
            props.history.push(VERBIAGE.DASHBOARD_PATH.SITE_OVERVIEW);
        }
        setError(authentication.error);
    };

    useEffect(() => redirectToDashboard(), [authentication]);

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center",
            margin: "2vh 0 2vh 0"
        },
        button: {
            marginTop: "20px"
        }
    }));

    const classes = useStyles();

    const onSuccess = async res => {
        const data = { googleSignIn: true, email: res.profileObj.email, tokenId: res.tokenId };
        // await postCredentials({payload});
        await postCredentials(data, algorithm, secretKey);
        if (authentication.isUserAuthenticated) {
            props.history.push(VERBIAGE.DASHBOARD_PATH.SITE_OVERVIEW);
        }
    };

    const onFailure = res => {
        // console.log('[Login Failed] res : ', res)
    };

    return (
        <div className={Login.style.mainContainer}>
            <Link
              className={Login.style.logoContainer}
              href={URL.BBP.MAIN}
              target="_blank"
              rel="noopener"
            >
                <img className={Login.style.bbpLogo} src={ICONS.BBPLogo} alt="BBP" />
            </Link>
            <div className={Login.style.subContainer}>
                <h1 className={Login.style.header}>{VERBIAGE.TEXT.HEADER}</h1>
                <div className={Login.style.inputContainer}>
                    <img className={Login.style.inputIcon} src={ICONS.LoginUser} alt="User Icon" />
                    <TextInput
                      className={Login.style.inputContainer}
                      onChange={event => handleUsernameChange(event)}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.USERNAME}
                      value={userName}
                      live
                    />
                </div>
                <div className={Login.style.inputContainer}>
                    <img
                      className={Login.style.inputIcon}
                      src={ICONS.LoginPassword}
                      alt="Password Icon"
                    />
                    <TextInput
                      className={Login.style.inputContainer}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.PASSWORD}
                      onChange={event => handleUserPasswordChange(event)}
                      value={password}
                      type="password"
                      live
                    />
                </div>
                <input
                  type="button"
                  className={Login.style.button}
                  value={VERBIAGE.BUTTONS.LOGIN}
                    // disabled={userName === "" || password === ""}
                  onClick={handleSubmit}
                />
                <Button
                  color="primary"
                  size="large"
                  className={classes.button}
                  onClick={handleClick}
                >
                    Forgot Password
                </Button>
                <div className={classes.error} style={{ margin: "0px", marginBottom: "10px" }}>
                    <Typography>{error}</Typography>
                </div>
                {/* <GoogleLogin
                    clientId={clientId}
                    buttonText="Sign In with Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy="single_host_origin"
                    style={{ marginTop: "100px" }}
                    isSignedIn={false}
                /> */}
            </div>
            <div>
                <img className={Login.style.leftImage} src={ICONS.BBPLoginLeft} alt="BBP" />
            </div>
            <div>
                <img className={Login.style.rightImage} src={ICONS.BBPLoginRight} alt="BBP" />
            </div>
        </div>
    );
};

Login.propTypes = {
    postCredentials: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    authentication: state.authentication
});

const mapDispatchToProps = dispatch => ({
    postCredentials: credentials => {
        dispatch(
            AuthenticationActions.postCredentials(
                encryptedLogin,
                credentials,
                algorithm,
                secretKey,
            ),
        );
    }
});

Login.style = {
    mainContainer: "webclient__login-screen-container-main",
    subContainer: "webclient__login-screen-container-sub",
    inputContainer: "webclient__login-screen-container-input",
    button: "webclient__login-screen-button",
    header: "webclient__login-screen-header",
    forgotPassword: "webclient__login-screen-forgot-password",
    errorText: "webclient__login-screen-error-text",
    inputIcon: "webclient__login-screen-container-input-icon",
    logoContainer: "webclient__login-screen-logo-container",
    bbpLogo: "webclient__login-screen-bbp-logo",
    leftImage: "webclient__login-screen-left-image",
    rightImage: "webclient__login-screen-right-image"
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
