import React, { useEffect, useCallback } from "react";
import useTableau from "../../../hooks/useTableau";

import ReportTableauChartV2 from "./ReportTableauChartV2";
import ReportTableauChartV3 from "./ReportTableauChartV3";
import ReportTableauChartDefault from "./ReportTableauChartDefault";

const ReportTableauChart = props => {
    const { url, fullWidth, download, ...chartProps } = props;

    const {
        fetchChart,
        tableauVersion,
        tableauName,
        tableauLink,
        tableauToken,
        tableauOptions
    } = useTableau({
        options: {
            hideTabs: false
        }
    });

    const fetchReportChart = useCallback(
        dispose => {
            const themeState = localStorage.dark === "true" ? "DARK" : "LIGHT";
            const siteChartUrl = download ? `${url}/${themeState}` : url;
            fetchChart(siteChartUrl, dispose);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [url],
    );

    useEffect(() => {
        fetchReportChart(true);
    }, [fetchReportChart]);

    switch (tableauVersion) {
        case 2:
            return (
                <ReportTableauChartV2
                  tableauLink={tableauLink}
                  tableauName={tableauName}
                  tableauOptions={tableauOptions}
                  {...props}
                />
            );
        case 3:
            return (
                <ReportTableauChartV3
                  fullWidth={fullWidth}
                  download={download}
                  tableauName={tableauName}
                  tableauLink={tableauLink}
                  tableauToken={tableauToken}
                  tableauOptions={tableauOptions}
                  tableauRefresh={() => fetchReportChart(true)}
                  {...chartProps}
                />
            );
        default:
    }

    return <ReportTableauChartDefault fullWidth={fullWidth} />;
};

export default ReportTableauChart;
