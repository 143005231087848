import { applyMiddleware, createStore } from "redux";
// import { devToolsEnhancer, composeWithDevTools } from "redux-devtools-extension";
import { composeWithDevTools } from "redux-devtools-extension";
// import logger from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./Reducer";

// const middlewares = [thunk, logger];
const middlewares = [thunk];

const composedEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares),
    // devToolsEnhancer( {trace: true})
);
const store = createStore(
    reducer,
    composedEnhancer,
    // applyMiddleware(...middlewares)
);

export default store;
