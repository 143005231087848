/* eslint-disable no-unused-vars  */
import React, { useState, useEffect, useRef } from "react";
import {
    Accordion,
    AccordionSummary,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { EditOutlined, ExpandMore } from "@material-ui/icons";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
// import EditFaultCodeTable from "./EditFaultCodeTable";
// import { VERBIAGE } from "../../../utils/enums";
import CustomFooter from "./CustomFooter";

const moment = require("moment");

const MonitoringData = () => {
    const [data, setData] = useState([]);

    const [open, setOpen] = useState(false);
    // const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    const isNarrow = useMediaQuery("(max-width: 600px)");

    const isMounted = useRef(false);

    // const emptyConfig = {
    //     siteId: "all",
    //     code: VERBIAGE.TEXT.EMPTY_STRING,
    //     parameter: VERBIAGE.TEXT.EMPTY_STRING,
    //     description: VERBIAGE.TEXT.EMPTY_STRING
    // };

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.SYSTEM.MONITORING, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (isMounted.current) {
                    let i;
                    for (i = 0; i < response.data.length; i++) {
                        response.data[i].time = moment(response.data[i].time)
                            .add(moment().utcOffset(), "minutes")
                            .format("DD MMM YYYY HH:mm:ss.SSS");
                    }

                    setData(response.data);
                }
            } catch (error) {
                // console.log("error : ", error);
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(() => ({
        root: {
            padding: "16px"
        }
    }))(TableCell);

    const cols = [
        // {
        //     name: "",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         empty: true,
        //         customBodyRenderLite: (dataIndex, rowIndex) => {
        //             return (
        //                 <IconButton
        //                   onClick={() => {
        //                         editRef.current.update({
        //                             ...editRef.current.getConfig(),
        //                             ...data[dataIndex]
        //                         });
        //                         setIsEdit(true);
        //                         handleClick();
        //                     }}
        //                 >
        //                     <EditOutlined />
        //                 </IconButton>
        //             );
        //         }
        //     }
        // },
        { label: "System", name: "system" },
        { label: "Type", name: "type", options: { searchable: false } },
        { label: "Time", name: "time", options: { searchable: false } },
        { label: "Result", name: "result", options: { searchable: false } },
        { label: "Status", name: "status", options: { searchable: false } }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        // eslint-disable-line no-unused-vars
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        const rowData = data[dataIndex];
        return (
          <TableRow key={dataIndex}>
            <TableCell>
              <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <StyledTableCell />
                        <StyledTableCell>
                          <IconButton
                            onClick={event => {
                                                    event.stopPropagation();
                                                    editRef.current.update({
                                                        ...editRef.current.getConfig(),
                                                        ...rowData
                                                    });
                                                    // setIsEdit(true);
                                                    handleClick();
                                                }}
                            onFocus={event => event.stopPropagation()}
                          >
                            <EditOutlined />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>System</StyledTableCell>
                        <StyledTableCell>{rowData.system}</StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>{rowData.type}</StyledTableCell>
                      </TableRow>
                      {isRowExpanded[dataIndex] ? (
                        <React.Fragment>
                          <TableRow>
                            <StyledTableCell>Time</StyledTableCell>
                            <StyledTableCell>{rowData.time}</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>Result</StyledTableCell>
                            <StyledTableCell>{rowData.result}</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>{rowData.status}</StyledTableCell>
                          </TableRow>
                        </React.Fragment>
                                    ) : null}
                    </TableBody>
                  </Table>
                </AccordionSummary>
              </Accordion>
            </TableCell>
          </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "400px",
        filter: true,
        print: false,
        viewColumns: false,
        download: false,
        search: true,
        // customToolbar: () => {
        //     return (
        //         <React.Fragment>
        //             <Tooltip title="Add Fault Code">
        //                 <IconButton
        //                   onClick={() => {
        //                         editRef.current.update(emptyConfig);
        //                         setIsEdit(false);
        //                         handleClick();
        //                     }}
        //                 >
        //                     <Add />
        //                 </IconButton>
        //             </Tooltip>
        //         </React.Fragment>
        //     );
        // },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
              <CustomFooter
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                changeRowsPerPage={changeRowsPerPage}
                changePage={changePage}
                textLabels={textLabels}
              />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      },
                      MUIDataTableHeadCell: {
                          root: {
                              "&:nth-child(2)": {
                                  width: 70
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MUIDataTableHeadCell: {
                          root: {
                              "&:nth-child(2)": {
                                  width: 70
                              }
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    return (
      <div>
        {/* <EditFaultCodeTable
              isEdit={isEdit}
              open={open}
              handleClick={handleClick}
              setOpen={setOpen}
              ref={editRef}
            /> */}
        <div className={classes.table}>
          <MuiThemeProvider theme={theme}>
            <MUIDataTable
              title="Monitoring Data"
              data={data}
              columns={cols}
              options={options}
            />
          </MuiThemeProvider>
        </div>
      </div>
    );
};

export default MonitoringData;
