import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Tab, Tabs, Grid, Paper, makeStyles } from "@material-ui/core";
import classNames from "classnames";

import { VERBIAGE } from "../../../utils/enums";

import "../styles/portfolioView.scss";
import SystemHeader from "../../common/components/SystemHeader";
import PIDTabs from "../../common/components/PIDTabs";

const MobileDetect = require("mobile-detect");

const PIDView = () => {
    const { authentication, home } = useSelector(state => state);

    const tabs = [
        {
            label: VERBIAGE.PID_TAB_TEXT.OVERALL,
            value: VERBIAGE.PID_TAB_VALUE.OVERALL
        },
        {
            label: VERBIAGE.PID_TAB_TEXT.OPTIMIZATION,
            value: VERBIAGE.PID_TAB_VALUE.OPTIMIZATION
        }
    ];

    const isAutoMLActive = localStorage.getItem("isAutoMLActive") === "true";
    if (isAutoMLActive) {
        tabs.splice(1, 0, {
            label: VERBIAGE.PID_TAB_TEXT.AUTO_ML,
            value: VERBIAGE.PID_TAB_VALUE.AUTO_ML
        });
    }

    const [tab, setTab] = useState(tabs[0].value);

    const handleChangeTab = (_, selectedTab) => {
        setTab(selectedTab);
    };

    /** styles */
    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.type === "dark" ? "white" : "black",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff"
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            paddingTop: "70px",
            boxSizing: "border-box"
        }
    }));

    const classes = useStyles();

    return (
        <div className="main-wrapper">
            <div>
                <SystemHeader authentication={authentication} />
            </div>
            <div className={classes.main}>
                <Grid container item xs={12} className={classes.root}>
                    {/* header start */}
                    <Grid item xs={12}>
                        <div className={classNames(PIDView.styles.headerContainer, classes.title)}>
                            <span>{VERBIAGE.MENU_TEXT.PID}</span>
                        </div>
                    </Grid>
                    {/* header end */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Tabs
                              value={tab}
                              onChange={handleChangeTab}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                              scrollButtons={window.innerWidth < 850 ? "on" : "auto"}
                            >
                                {tabs.map(t => (
                                    <Tab key={t.value} value={t.value} label={t.label} />
                                ))}
                            </Tabs>
                            <Paper className={classes.paper} elevation={0}>
                                <PIDTabs tab={tab} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
PIDView.styles = {
    headerContainer: "webclient__portfolioView-container-header"
};

export default PIDView;
