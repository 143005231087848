import React, { useState, useEffect, useMemo } from "react";
import {
    Button,
    makeStyles,
    Typography,
    Grid,
    Paper,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import { Edit, EditOutlined } from "@material-ui/icons";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import API from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { getResponseErrorDetails, scrollIntoFirstErrorElement } from "../../../utils/helpers";

import FormRenderField from "../../../dashboard/common/components/FormRenderField";

const ChillerSpecSchema = Yup.object().shape({
    chManuYear: Yup.number().integer(),
    chRefrigerant: Yup.string(),
    compressorType: Yup.string(),
    fullLoadPowerKw: Yup.number(),
    fullLoadEffKwrt: Yup.number(),
    chwChF: Yup.number(),
    chwsChT: Yup.number(),
    chwrChT: Yup.number(),
    evaChDrop: Yup.number(),
    cdwChF: Yup.number(),
    cdwsChT: Yup.number(),
    cdwrChT: Yup.number(),
    cdChDrop: Yup.number(),
    chRla: Yup.number(),
    chVolt: Yup.number().integer(),
    chPhase: Yup.number().integer(),
    chHz: Yup.number().integer(),
    chFoulingFactor: Yup.number(),
    chwChAppT: Yup.number(),
    cdwChAppT: Yup.number(),
    chLoadType: Yup.string(),
    chChwFMin: Yup.number(),
    chChwFMax: Yup.number(),
    chCdwFMin: Yup.number(),
    chCdwFMax: Yup.number(),
    chCompOilType: Yup.string(),
    chCompOilBrand: Yup.string(),
    chRefQtyKg: Yup.number(),
    chOilQtyL: Yup.number()
});

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    title: {
        fontSize: theme.spacing(2),
        fontWeight: "bold"
    },
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    },
    hide: {
        display: "none"
    }
}));

const EditChillerSpecOverview = ({ chId }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const initData = useMemo(
        () => ({
            chillerSpecId: 0,
            chManuYear: 0,
            chRefrigerant: VERBIAGE.TEXT.EMPTY_STRING,
            compressorType: VERBIAGE.TEXT.EMPTY_STRING,
            fullLoadPowerKw: 0,
            fullLoadEffKwrt: 0,
            chwChF: 0,
            chwsChT: 0,
            chwrChT: 0,
            evaChDrop: 0,
            cdwChF: 0,
            cdwsChT: 0,
            cdwrChT: 0,
            cdChDrop: 0,
            chRla: 0,
            chVolt: 0,
            chPhase: 0,
            chHz: 0,
            chFoulingFactor: 0,
            chwChAppT: 0,
            cdwChAppT: 0,
            chLoadType: VERBIAGE.TEXT.EMPTY_STRING,
            chChwFMin: 0,
            chChwFMax: 0,
            chCdwFMin: 0,
            chCdwFMax: 0,
            chCompOilType: VERBIAGE.TEXT.EMPTY_STRING,
            chCompOilBrand: VERBIAGE.TEXT.EMPTY_STRING,
            chRefQtyKg: 0,
            chOilQtyL: 0
        }),
        [],
    );
    const [values, setValues] = useState(initData);

    useEffect(() => {
        const fetchChillerSpec = async () => {
            try {
                setLoading(true);

                const url = `${URL.DATA.DWH.CHILLER_SPEC}/${chId}/chiller`;
                const response = await API.get(url, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data) {
                    setValues({
                        ...initData,
                        ...response.data,
                        chillerSpecId: chId
                    });
                }
            } catch (err) {
                if (err.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            } finally {
                setLoading(false);
            }
        };

        if (chId) {
            fetchChillerSpec();
        }
    }, [chId, initData]);

    const {
        control,
        handleSubmit,
        setError: setFormError,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(ChillerSpecSchema),
        values
    });

    useEffect(() => {
        if (Object.keys(errors).length) {
            scrollIntoFirstErrorElement({
                elementIds: Object.keys(errors)
            });
        } else {
            setError(null);
        }
    }, [errors]);

    const onClose = () => {
        setError(null);
        setIsEdit(false);
    };

    const toggleEdit = () => {
        setIsEdit(e => !e);
    };

    const handleResponseError = response => {
        const resError = getResponseErrorDetails(response);
        if (resError.type === "invalid") {
            Object.keys(resError.errors).forEach(field => {
                setFormError(field, { type: "invalid", message: resError.errors[field] });
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstErrorElement({ elementIds: Object.keys(resError.errors) });
        } else {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        }
    };

    const onSubmit = async chillerData => {
        try {
            setLoading(true);

            const requestConfig = {
                data: chillerData,
                headers: {
                    "x-auth-token": localStorage.token
                }
            };
            if (values._id) {
                requestConfig.method = "PUT";
                requestConfig.url = `${URL.DATA.DWH.CHILLER_SPEC}/${values._id}`;
            } else {
                requestConfig.method = "POST";
                requestConfig.url = `${URL.DATA.DWH.CHILLER_SPEC}/${chId}/chiller`;
            }
            const response = await API.request(requestConfig);
            if (response.data?.status === "error") {
                handleResponseError(response);
                return;
            }

            setValues(prev => ({ ...prev, ...response.data }));
            onClose();
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else if (err.response.status === 404) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            } else {
                handleResponseError(err.response);
            }
        } finally {
            setLoading(false);
        }
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    const classes = useStyles();

    const inputCols1 = [
        {
            name: "chillerSpecId",
            label: "Chiller Specification ID",
            type: "number",
            disabled: true
        },
        {
            name: "chManuYear",
            label: "Manufacturer Year",
            type: "number",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_MANUFACTURER_YEAR
        },
        {
            name: "chRefrigerant",
            label: "Refrigerant",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_REFRIGERANT
        },
        {
            name: "compressorType",
            label: "Compressor Type",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_COMPRESSOR_TYPE
        },
        {
            name: "chRt",
            label: "CH_RT",
            hide: values.chRt === undefined,
            disabled: true
        },
        {
            name: "chHeatrej",
            label: "CH_HEATREJ",
            hide: values.chHeatrej === undefined,
            disabled: true
        },
        {
            name: "fullLoadPowerKw",
            label: "F.Load P KW",
            type: "number",
            numberType: "double"
        },
        {
            name: "fullLoadEffKwrt",
            label: "F.Load Eff",
            type: "number",
            numberType: "double"
        },
        {
            name: "chwChF",
            label: "CHW_CH_F",
            type: "number",
            numberType: "double"
        },
        {
            name: "chwsChT",
            label: "CHWS_CH_T",
            type: "number",
            numberType: "double"
        },
        {
            name: "chwrChT",
            label: "CHWR_CH_T",
            type: "number",
            numberType: "double"
        },
        {
            name: "chwChDt",
            label: "CHW_CH_DT",
            hide: values.chwChDt === undefined,
            disabled: true
        },
        {
            name: "evaChDrop",
            label: "EVA_CH_DROP",
            type: "number",
            numberType: "double"
        },
        {
            name: "cdwChF",
            label: "CDW_CH_F",
            type: "number",
            numberType: "double"
        },
        {
            name: "cdwsChT",
            label: "CDWS_CH_T",
            type: "number",
            numberType: "double"
        },
        {
            name: "cdwrChT",
            label: "CDWR_CH_T",
            type: "number",
            numberType: "double"
        },
        {
            name: "cdwChDt",
            label: "CDW_CH_DT",
            hide: values.cdwChDt === undefined,
            disabled: true
        },
        {
            name: "cdChDrop",
            label: "CD_CH_DROP",
            type: "number",
            numberType: "double"
        },
        {
            name: "chRla",
            label: "CH_RLA",
            type: "number",
            numberType: "double"
        },
        {
            name: "chVolt",
            label: "CH_VOLT",
            type: "number"
        },
        {
            name: "chPhase",
            label: "CH_PHASE",
            type: "number"
        },
        {
            name: "chHz",
            label: "CH_HZ",
            type: "number"
        },
        {
            name: "chFoulingFactor",
            label: "Fouling Factor",
            type: "number",
            numberType: "double"
        },
        {
            name: "chwChAppT",
            label: "CHW_CH_APP_T",
            type: "number",
            numberType: "double"
        },
        {
            name: "cdwChAppT",
            label: "CDW_CH_APP_T",
            type: "number",
            numberType: "double"
        },
        {
            name: "chLoadType",
            label: "Load Type"
        }
    ];
    const inputCols2 = [
        {
            name: "chChwFMin",
            label: "CH_CHW_F_MIN",
            type: "number",
            numberType: "double"
        },
        {
            name: "chChwFMax",
            label: "CH_CHW_F_MAX",
            type: "number",
            numberType: "double"
        },
        {
            name: "chCdwFMin",
            label: "CH_CDW_F_MIN",
            type: "number",
            numberType: "double"
        },
        {
            name: "chCdwFMax",
            label: "CH_CDW_F_MAX",
            type: "number",
            numberType: "double"
        },
        {
            name: "chCompOilType",
            label: "Compressor Oil Type"
        },
        {
            name: "chCompOilBrand",
            label: "Compressor Oil Brand"
        },
        {
            name: "chRefQtyKg",
            label: "Refrigerant Qty KG",
            type: "number",
            numberType: "double"
        },
        {
            name: "chOilQtyL",
            label: "Oil Qty in Litre",
            type: "number",
            numberType: "double"
        },
        {
            name: "createdAt",
            label: "Created Date",
            disabled: true
        },
        {
            name: "updatedAt",
            label: "Updated Date",
            disabled: true
        }
    ];

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12} container direction="row" alignItems="center" spacing={1}>
                    <Typography variant="span" className={classes.title}>
                        Chiller Specification
                    </Typography>
                    {loading ? (
                        <CircularProgress size={12} />
                    ) : (
                        <IconButton onClick={toggleEdit}>
                            {isEdit ? <Edit /> : <EditOutlined />}
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={12} md={6} container spacing={0}>
                    {inputCols1.map(input => {
                        if (input.hide) return null;
                        return (
                            <Grid item container alignItems="center" key={input.name}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">{input.label}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {isEdit ? (
                                        <Controller
                                          name={input.name}
                                          control={control}
                                          render={({ field: { value, onChange } }) => (
                                                <FormRenderField
                                                  input={input}
                                                  value={value}
                                                  onChange={onChange}
                                                  errors={errors}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <Typography>{values[input.name]}</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item xs={12} md={6} container>
                    {inputCols2.map(input => {
                        if (input.hide) return null;
                        return (
                            <Grid item container alignItems="center" key={input.name}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">{input.label}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {isEdit ? (
                                        <Controller
                                          name={input.name}
                                          control={control}
                                          render={({ field: { value, onChange } }) => (
                                                <FormRenderField
                                                  input={input}
                                                  value={value}
                                                  onChange={onChange}
                                                  errors={errors}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <Typography>{values[input.name]}</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                {!!error && (
                    <Grid item xs={12} className={classes.error}>
                        <Typography variant="subtitle1">{error}</Typography>
                    </Grid>
                )}
                {isEdit && (
                    <Grid item xs={12} container>
                        <Button
                          color="primary"
                          onClick={handleSubmit(onSubmit, onError)}
                          disabled={loading}
                        >
                            {VERBIAGE.BUTTONS.SUBMIT}
                        </Button>
                        <Button className={classes.error} onClick={onClose} disabled={loading}>
                            {VERBIAGE.BUTTONS.CLOSE}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default EditChillerSpecOverview;
