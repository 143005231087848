import HomeActionTypes from "./HomeActionTypes";

const MobileDetect = require("mobile-detect");

const md = new MobileDetect(window.navigator.userAgent);
const mql = window.matchMedia("(orientation: portrait)");
// const initialOrientation = window.screen.orientation.angle === 0 ? "landscape" : "portrait";
const initialOrientation = mql.matches ? "portrait" : "landscape";

const initialState = {
    msg: "",
    isPasswordChange: false,
    data: "",
    lineData: "",
    composite: "",
    cost: "",
    startDate: "",
    endDate: "",
    systemData: "",
    plant: "",
    interval: "",
    savings: [],
    chillerGauge: "",
    desktopMobileSwitch: md.mobile() ? "mobile" : "desktop",
    orientation: initialOrientation
};

const HomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case HomeActionTypes.SET_PASSWORD:
            return {
                ...state,
                isPasswordChange: !!action.payload.status,
                msg: action.payload.status
            };
        case HomeActionTypes.SET_DATA:
            return {
                ...state,
                data: action.data[0]
            };
        case HomeActionTypes.SET_LINE_GRAPH:
            return {
                ...state,
                lineData: action.data.data
            };
        case HomeActionTypes.SET_DATA_COMPOSITE:
            return {
                ...state,
                composite: action.data
            };
        case HomeActionTypes.SET_POST_DATA_COMPOSITE:
            return {
                ...state
            };
        case HomeActionTypes.SET_COST:
            return {
                ...state,
                cost: action.data
            };
        case HomeActionTypes.SET_SYSTEM_OVERVIEW:
            return {
                ...state,
                systemData: action.data
            };
        case HomeActionTypes.SET_PLANT:
            return {
                ...state,
                plant: action.data
            };
        case HomeActionTypes.SET_INTERVAL:
            return {
                ...state,
                interval: action.data
            };
        case HomeActionTypes.SET_SAVINGS:
            return {
                ...state,
                savings: [...action.data]
            };
        case HomeActionTypes.SET_CHILLER_GAUGE:
            localStorage.setItem("lastUpdate", action.data.lastUpdate);
            localStorage.setItem("utcOffset", action.data.utcOffset);
            localStorage.setItem("timezone", action.data.timezone);
            return {
                ...state,
                chillerGauge: action.data
            };
        case HomeActionTypes.TOGGLE_DESKTOP_MOBILE: {
            const viewport = document.getElementsByName("viewport")[0];
            if (state.desktopMobileSwitch === "desktop") {
                viewport.setAttribute(
                    "content",
                    "width=device-width, initial-scale=1, minimum-scale=1",
                );
            } else {
                viewport.setAttribute("content", "width=1024");
            }
            return {
                ...state,
                desktopMobileSwitch: state.desktopMobileSwitch === "desktop" ? "mobile" : "desktop"
            };
        }
        case HomeActionTypes.SET_ORIENTATION: {
            return {
                ...state,
                orientation: action.data
            };
        }
        default:
            return state;
    }
};

export default HomeReducer;
