/* eslint-disable max-len */
/* eslint-disable no-bitwise */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect, useRef } from "react";
import {
    Accordion,
    AccordionSummary,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from "file-saver";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Publish, ExpandMore } from "@material-ui/icons";
import _ from "lodash";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import { VERBIAGE } from "../../../utils/enums";
import CSVUpload from "./CSVUpload";
import CustomFooter from "./CustomFooter";

const moment = require("moment");

const hexToRgbA = hex => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join("")}`;
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},1)`;
    }
    return "rgba(255, 255, 255, 1)";
};

const GaugeRangeTable = () => {
    const [data, setData] = useState([]);

    const handleCsv = () => {
        const csvData = data.map(row => {
            const _thresholdValue = _.dropRight(_.drop(row.thresholdValue));
            const csvRow = {};
            csvRow.siteId = row.siteId;
            csvRow.type = row.type;
            csvRow.minValue = row.minValue;
            csvRow.maxValue = row.maxValue;
            csvRow.thresholdValue = `[${_thresholdValue}]`;
            return csvRow;
        });
        const blob = new Blob([jsonToCSV(csvData)], { type: "text/csv;charset=utf-8;" });
        const now = moment().format("YYYYMMDD");
        saveAs(blob, `${csvData[0].siteId}_gauge_range_${now}.csv`);
        // saveAs(blob, "Gauge Range Data.csv");
    };

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    const isNarrow = useMediaQuery("(max-width: 600px)");

    const isMounted = useRef(false);

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        type: VERBIAGE.TEXT.EMPTY_STRING,
        minValue: VERBIAGE.TEXT.EMPTY_STRING,
        maxValue: VERBIAGE.TEXT.EMPTY_STRING
    };

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.PLANT_SETTINGS.THRESHOLD, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (isMounted.current) {
                    setData(response.data);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: "16px"
        }
    }))(TableCell);

    const cols = [
        { label: "Site ID", name: "siteId" },
        { label: "Type", name: "type" },
        { label: "Min Value", name: "minValue" },
        { label: "Max Value", name: "maxValue" },
        {
            label: "Threshold",
            name: "threshold",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].threshold.map(row => {
                        return {
                            text: `From ${row.min} to ${row.max} - Stroke: ${row.strokeStyle}`,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                const rgba = hexToRgbA(row.strokeStyle);
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                        {/* Container for color indicator */}
                                        <div
                                          style={{
                                                padding: "3 5 5 5",
                                                borderRadius: "1px",
                                                display: "inline-block",
                                                cursor: "pointer"
                                            }}
                                        >
                                            {/* Actual colour indicator */}
                                            <div
                                              style={{
                                                    width: "14px",
                                                    height: "14px",
                                                    borderRadius: "1px",
                                                    background: rgba
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        const rowData = data[dataIndex];
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell>Site ID</StyledTableCell>
                                        <StyledTableCell>{rowData.siteId}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Type</StyledTableCell>
                                        <StyledTableCell>{rowData.type}</StyledTableCell>
                                    </TableRow>
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell>Min Value</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.minValue}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Max Value</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.maxValue}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Threshold</StyledTableCell>
                                                <StyledTableCell>
                                                    {cols[4].options.customBodyRenderLite(
                                                        dataIndex,
                                                        rowIndex,
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "400px",
        filter: false,
        print: false,
        viewColumns: false,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title="Upload CSV">
                        <IconButton onClick={() => setOpen(true)}>
                            <Publish />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
        onDownload: () => {
            handleCsv();
            return false;
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    return (
        <div>
            <CSVUpload
              open={open}
              setOpen={setOpen}
              handleClick={handleClick}
              url={URL.DATA.PLANT_SETTINGS.THRESHOLD_BULK}
            />
            <div className={classes.table}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      title="Gauge Range Data"
                      data={data}
                      columns={cols}
                      options={options}
                    />
                </MuiThemeProvider>
            </div>
        </div>
    );
};

export default GaugeRangeTable;
