import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SyncIcon from "@material-ui/icons/Sync";

import MLActions from "../../redux/MLActions";
import { toClientDate } from "../../../utils/helpers";

const PIDAutoMLRefresh = () => {
    const { modbusEnableML, setpoint } = useSelector(state => state.ML);
    const dispatch = useDispatch();

    const handleSync = () => {
        dispatch(MLActions.getModbusHeartBeat(true));
        dispatch(MLActions.getPLCHeartBeat(true));
        dispatch(MLActions.getModbusEnableML(true));
        dispatch(MLActions.getSetpointData(true));
    };

    return (
        <>
            <Button
              color="primary"
              variant="contained"
              size="small"
              startIcon={<SyncIcon />}
              disabled={modbusEnableML.loading && setpoint.loading}
              onClick={handleSync}
            >
                Sync
            </Button>
            {setpoint.lastUpdatedAt && (
                <Box pt={0.5}>
                    <Typography variant="caption" color="textSecondary">
                        {`Last synced on ${toClientDate(setpoint.lastUpdatedAt)}`}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default PIDAutoMLRefresh;
