const AnomalyDiagnosisActionTypes = {
    GET_DIAGNOSIS_TABLE: "data/GET_DIAGNOSIS_TABLE",
    UPDATE_DIAGNOSIS_TABLE: "data/UPDATE_DIAGNOSIS_TABLE",
    GET_FEEDBACK: "data/GET_FEEDBACK",
    UPDATE_ACTIVE_DIAGNOSIS: "data/UPDATE_ACTIVE_DIAGNOSIS"
    // SET_ANALYTICS_CHIPS: "data/SET_ANALYTICS_CHIPS",
    // SET_LABEL: "data/SET_LABEL",
    // SET_CHIP_SEVERITY: "data/SET_CHIP_SEVERITY",
    // SET_NOTES: "data/SET_NOTES",
    // SET_NOTES_CHIPS: "data/SET_NOTES_CHIPS",
    // SET_USERTAGS_CHIPS: "data/SET_USERTAGS_CHIPS",
    // GET_CHART_POSITION: "data/GET_CHART_POSITION",
    // SET_ALARM_CONFIG: "data/SET_ALARM_CONFIG"
};

export default AnomalyDiagnosisActionTypes;
