/* eslint-disable no-unused-vars  */
import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { VERBIAGE } from "../../../utils/enums/index";
import "../styles/portfolioRightView.scss";
import MessageBulletin from "./MessageBulletin";
import CardPortfolioSavings from "./CardPortfolioSavings";
import SystemEfficiency from "./SystemEfficiency";
import KpiSystem from "./KpiSystem";
import ICONS from "../../../utils/enums/IconData";
import EllipsisPopUp from "./EllipsisPopUp";
import SystemBenchmark from "./SystemBenchmark";

const useStyles = makeStyles(theme => ({
    header: {
        color: theme.palette.type === "dark" ? "#DFDFDF" : "#414141"
    },
    day: {
        color: theme.palette.type === "dark" ? "#A0A0A0" : "#707070"
    },
    hotel: {
        color: theme.palette.type === "dark" ? "#CFCFCF" : "#414141"
    },
    label: {
        color: theme.palette.type === "dark" ? "#ACACAC" : "#707070"
    },
    gridBg: {
        background: theme.palette.type === "dark" ? "#201F25" : "#ffffff"
    },
    dot: {
        filter: theme.palette.type === "dark" ? "brightness(0) invert(1)" : ""
    }
}));

const PortfolioRightView = props => {
    const { portfolio, siteName } = props;

    const getCookie = name => {
        // Split cookie string and get all individual name=value pairs in an array
        const cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
          and compare it with the given string */
            if (name === cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }

        // Return null if not found
        return null;
    };

    const noBaselineSite = ["10027", "10030", "10031"];
    let noBaseline = false;
    const siteId = getCookie("siteId");
    if (noBaselineSite.includes(siteId)) {
        noBaseline = true;
    }

    const classes = useStyles();
    const date = new Date();
    return (
        <div>
            <Grid>
                {/* <div
                    className={classNames(
                        PortfolioRightView.styles.LastestContainer,
                        classes.header,
                    )}
                >
                    <span>{VERBIAGE.TEXT.LASTEST_DATA}</span>
                    <img
                      alt="ellipsis"
                      src={ICONS.ELLIPSIS}
                      className={classNames(PortfolioRightView.styles.ellipsis, classes.dot)}
                    />
                </div> */}
                {/* <div
                    className={classNames(PortfolioRightView.styles.timeZoneContainer, classes.day)}
                >
                    <Moment tz="Singapore" format="MMMM Do YYYY, h:mm:ss a">
                        {date}
                    </Moment>
                </div> */}
                {/* HotelMichael */}
                <Grid elevation={0}>
                    <Paper
                      className={classNames(
                            PortfolioRightView.styles.hotelContainer,
                            classes.gridBg,
                        )}
                      elevation={0}
                    >
                        <div
                          className={classNames(
                                PortfolioRightView.styles.michaelContainer,
                                classes.hotel,
                            )}
                        >
                            {siteName}
                        </div>
                        {noBaseline ? (
                            <div />
                        ) : (
                            <Grid
                              container
                              item
                              xs={12}
                              className={PortfolioRightView.styles.tariffContainer}
                            >
                                <div
                                  className={classNames(
                                        PortfolioRightView.styles.labelContainer,
                                        classes.label,
                                    )}
                                >
                                    {VERBIAGE.TEXT.TARIFF}
                                </div>
                                <div
                                  className={classNames(
                                        PortfolioRightView.styles.numberContainer,
                                        classes.label,
                                    )}
                                >
                                    {portfolio.savings.tariff}
                                </div>
                                <div
                                  className={classNames(
                                        PortfolioRightView.styles.labelContainer,
                                        classes.label,
                                    )}
                                >
                                    {VERBIAGE.TEXT.BASELINE_EFFICIENCY}
                                </div>
                                <div
                                  className={classNames(
                                        PortfolioRightView.styles.numberContainer,
                                        classes.label,
                                    )}
                                >
                                    {portfolio.savings.baseline}
                                </div>
                            </Grid>
                        )}
                    </Paper>
                </Grid>

                {/* card saving start */}
                <Grid item xs={12} sm={12} md={12}>
                    {noBaseline ? <div /> : <CardPortfolioSavings />}
                </Grid>
                {/* card saving end */}
                {/* System Efficiency start */}
                <Grid className={PortfolioRightView.styles.subheaderContainer}>
                    <div
                      className={classNames(
                            PortfolioRightView.styles.LastestContainer,
                            classes.header,
                        )}
                    >
                        <span>{VERBIAGE.TEXT.SYSTEM_EFFICIENCY}</span>
                        <EllipsisPopUp />
                    </div>
                </Grid>
                <Grid item xs={12} elevation={0}>
                    <SystemEfficiency />
                </Grid>
                {/* System Efficiency end */}

                {/* System Benchmark start */}
                <Grid className={PortfolioRightView.styles.subheaderContainer}>
                    <div
                      className={classNames(
                            PortfolioRightView.styles.LastestContainer,
                            classes.header,
                        )}
                    >
                        <span>{VERBIAGE.TEXT.SYSTEM_BENCHMARK}</span>
                        <img
                          alt="ellipsis"
                          src={ICONS.ELLIPSIS}
                          className={classNames(PortfolioRightView.styles.ellipsis, classes.dot)}
                        />
                    </div>
                </Grid>

                <Grid>
                    <Paper elevation={0} className={classes.gridBg}>
                        <SystemBenchmark />
                        {/* <KpiSystem /> */}
                    </Paper>
                </Grid>

                {/* System Benchmark end */}
                {/* MY KPI start */}
                {/* <Grid className={PortfolioRightView.styles.subheaderContainer}>
                    <div
                      className={classNames(
                            PortfolioRightView.styles.LastestContainer,
                            classes.header,
                        )}
                    >
                        <span>{VERBIAGE.TEXT.MY_KPI}</span>
                        <img
                          alt="ellipsis"
                          src={ICONS.ELLIPSIS}
                          className={classNames(PortfolioRightView.styles.ellipsis, classes.dot)}
                        />
                    </div>
                </Grid> */}

                {/* <Grid>
                    <Paper elevation={0} className={classes.gridBg}>
                        <KpiSystem />
                    </Paper>
                </Grid> */}

                {/* MY KPI end */}

                {/* message buttetin start */}
                {/* <Grid className={PortfolioRightView.styles.subheaderContainer}>
            <div
              className={classNames(
                            PortfolioRightView.styles.LastestContainer,
                            classes.header,
                        )}
            >
              <span>{VERBIAGE.TEXT.MESSAGE_BULLETIN}</span>
              <img
                alt="ellipsis"
                src={ICONS.ELLIPSIS}
                className={classNames(PortfolioRightView.styles.ellipsis, classes.dot)}
              />
            </div>
          </Grid>
          <Grid elevation={0}>
            <MessageBulletin />
          </Grid> */}
                {/* message buttetin end  */}
            </Grid>
        </div>
    );
};
PortfolioRightView.styles = {
    LastestContainer: "webclient__portfolioRightView-container-lastest",
    timeZoneContainer: "webclient__portfolioRightView-container-timeZone",
    tariffContainer: "webclient__portfolioRightView-container-tariff",
    hotelContainer: "webclient__portfolioRightView-container-hotel",
    michaelContainer: "webclient__portfolioRightView-container-michael",
    labelContainer: "webclient__portfolioRightView-container-label",
    subheaderContainer: "webclient__portfolioRightView-container-subheader",
    numberContainer: "webclient__portfolioRightView-container-number",
    ellipsis: "webclient__portfolioRightView-ellipsis"
};
const mapStateToProps = state => ({
    portfolio: state.portfolio
});
export default connect(mapStateToProps, null)(withRouter(PortfolioRightView));
