import React, { useEffect, useMemo, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";

import LoadingRetry from "../components/LoadingRetry";
import PIDAutoMLSetpointTableBody from "./PIDAutoMLSetpointTableBody";
import PIDAutoMLSetpointTableAlert from "./PIDAutoMLSetpointTableAlert";
import useMLSetpointData from "./hooks/useMLSetpointData";

function arraySort(array, comparator) {
    const mapping = array.map((el, index) => [el, index]);
    mapping.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return mapping.map(el => el[0]);
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const PIDAutoMLSetpointTable = () => {
    const {
        disabled,
        loading,
        loaded,
        error,
        errors,
        hasErrors,
        setErrors,
        clearErrors,
        setpoints,
        fetchData,
        setRecommended
    } = useMLSetpointData();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSpError = ({ sp, message }) => {
        setErrors(sp, message);
    };

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("cloud_registry");

    const tableHeaders = [
        { id: "setpoint", label: "Setpoint", sortable: true, TableCellProps: { width: "20%" } },
        {
            id: "scada_registry",
            label: "SCADA",
            sortable: true,
            TableCellProps: { width: "10%", align: "center" }
        },
        {
            id: "cloud_registry",
            label: "CLOUD",
            sortable: true,
            TableCellProps: { width: "10%", align: "center" }
        },
        { id: "min", label: "Min.", sortable: true },
        {
            id: "currentVal",
            label: "Current Val.",
            sortable: true,
            TableCellProps: { width: "10%" }
        },
        { id: "recVal", label: "Rec Val.", sortable: true, TableCellProps: { width: "10%" } },
        { id: "maxVal", label: "Max Val.", sortable: true, TableCellProps: { width: "10%" } },
        { id: "recMin", label: "Rec Min." },
        { id: "recMax", label: "Rec Min." }
    ];

    const setpointsSorted = useMemo(() => {
        return arraySort(setpoints, getComparator(order, orderBy));
    }, [setpoints, order, orderBy]);

    const handleTabelSort = id => () => {
        const isAsc = orderBy === id && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(id);
    };

    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map(header => {
                            if (!header.sortable) {
                                return (
                                    <TableCell key={header.id} {...header.TableCellProps}>
                                        {header.label}
                                    </TableCell>
                                );
                            }

                            const sortDirection =
                                header.sortable && orderBy === header.id ? order : false;
                            return (
                                <TableCell
                                  key={header.id}
                                  sortDirection={sortDirection}
                                  {...header.TableCellProps}
                                >
                                    <TableSortLabel
                                      active={orderBy === header.id}
                                      direction={orderBy === header.id ? order : "asc"}
                                      onClick={handleTabelSort(header.id)}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loaded ? (
                        <PIDAutoMLSetpointTableBody
                          disabled={disabled}
                          setpoints={setpointsSorted}
                          onUpdate={setRecommended}
                          onError={handleSpError}
                        />
                    ) : (
                        <TableRow>
                            <TableCell colSpan={9}>
                                <LoadingRetry
                                  loading={loading}
                                  loadingText="Loading setpoint data..."
                                  error={!!error}
                                  retryFn={fetchData}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <PIDAutoMLSetpointTableAlert open={hasErrors} errors={errors} onClose={clearErrors} />
        </>
    );
};

export default PIDAutoMLSetpointTable;
