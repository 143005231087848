import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    // FormControlLabel,
    Grid,
    makeStyles,
    Paper,
    Switch,
    Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Draggable from "react-draggable";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";

const PurpleSwitch = withStyles({
    switchBase: {
        color: green[300],
        "&$checked": {
            color: green[500]
        },
        "&$checked + $track": {
            backgroundColor: "#9F9F9F"
        },
        alignItems: "center"
    },
    checked: {},
    track: {}
})(Switch);

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const EditPIDDataTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        groupName: VERBIAGE.TEXT.EMPTY_STRING,
        parameter: VERBIAGE.TEXT.EMPTY_STRING,
        pvParameter: VERBIAGE.TEXT.EMPTY_STRING,
        pvParameter2: VERBIAGE.TEXT.NULL_VALUE,
        pvOperation: VERBIAGE.TEXT.EMPTY_STRING,
        out: VERBIAGE.TEXT.EMPTY_STRING,
        cvParameter: VERBIAGE.TEXT.NULL_VALUE,
        label: VERBIAGE.TEXT.EMPTY_STRING,
        min: VERBIAGE.TEXT.EMPTY_STRING,
        max: VERBIAGE.TEXT.EMPTY_STRING,
        interval: VERBIAGE.TEXT.EMPTY_STRING,
        auto: true,
        PValue: VERBIAGE.TEXT.EMPTY_STRING, // null,
        IValue: VERBIAGE.TEXT.EMPTY_STRING, // null,
        DValue: VERBIAGE.TEXT.EMPTY_STRING, // null,
        DBValue: VERBIAGE.TEXT.EMPTY_STRING, // null,
        MANValue: VERBIAGE.TEXT.EMPTY_STRING, // null,
        MINValue: VERBIAGE.TEXT.EMPTY_STRING, // null,
        MAXValue: VERBIAGE.TEXT.EMPTY_STRING, // null,
        status: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [auto, setAuto] = useState(true);
    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    useEffect(() => {
        let isMounted = true;
        // const fetchData = async () => {
        //     try {
        //         const siteResponse = await api.get(URL.DATA.ADMIN_SETTINGS.SITES, {
        //             headers: {
        //                 "x-auth-token": localStorage.token
        //             }
        //         });
        //         // if (isMounted) {
        //         //     // setSiteList(siteResponse.data);
        //         //     setSiteOptions(siteResponse.data.map(row => row.siteId));
        //         // }
        //     } catch (err) {
        //         if (err.response.status === 401) {
        //             localStorage.clear();
        //             window.location.href = "/";
        //         }
        //     }
        // };

        // fetchData();
        if (isMounted) {
            setAuto(config.auto);
        }

        return () => (isMounted = false);
    }, [config.auto, config.groupName, isEdit, open]);

    const handleSite = ({ target: { value } }) => {
        updateConfig({ ...config, siteId: value });
    };

    const handleGroupName = ({ target: { value } }) => {
        updateConfig({ ...config, groupName: value });
    };

    const handleParameter = ({ target: { value } }) => {
        updateConfig({ ...config, parameter: value });
    };

    const handlePVParameter = ({ target: { value } }) => {
        updateConfig({ ...config, pvParameter: value });
    };

    const handlePVParameter2 = ({ target: { value } }) => {
        updateConfig({ ...config, pvParameter2: value });
    };

    const handlePVOperation = ({ target: { value } }) => {
        updateConfig({ ...config, pvOperation: value });
    };

    // const handleOut = ({ target: { value } }) => {
    //     updateConfig({ ...config, out: value });
    // };

    const handleCVParameter = ({ target: { value } }) => {
        updateConfig({ ...config, cvParameter: value });
    };

    const handleLabel = ({ target: { value } }) => {
        updateConfig({ ...config, label: value });
    };

    const handleMin = ({ target: { value } }) => {
        updateConfig({ ...config, min: value });
    };

    const handleMax = ({ target: { value } }) => {
        updateConfig({ ...config, max: value });
    };

    const handleInterval = ({ target: { value } }) => {
        updateConfig({ ...config, interval: value });
    };

    const handlePValue = ({ target: { value } }) => {
        updateConfig({ ...config, PValue: value });
    };

    const handleIValue = ({ target: { value } }) => {
        updateConfig({ ...config, IValue: value });
    };

    const handleDValue = ({ target: { value } }) => {
        updateConfig({ ...config, DValue: value });
    };

    const handleDBValue = ({ target: { value } }) => {
        updateConfig({ ...config, DBValue: value });
    };

    const handleMANValue = ({ target: { value } }) => {
        updateConfig({ ...config, MANValue: value });
    };

    const handleMINValue = ({ target: { value } }) => {
        updateConfig({ ...config, MINValue: value });
    };

    const handleMAXValue = ({ target: { value } }) => {
        updateConfig({ ...config, MAXValue: value });
    };

    const handleStatus = ({ target: { value } }) => {
        updateConfig({ ...config, status: value });
    };

    const togglePIDAuto = event => {
        updateConfig({ ...config, auto: event.target.checked });
        setAuto(event.target.checked);
        // localStorage.setItem("isSchematicsDeveloped", event.target.checked);
    };

    const isNumeric = value => {
        // return /^\d+$/.test(value);
        return /^\d*\.?\d+$/.test(value);
    };

    const handleSubmit = async () => {
        if (
            !config.groupName ||
            !config.parameter ||
            !config.pvParameter ||
            !config.label ||
            !config.status ||
            // !config.PValue ||
            !config.siteId ||
            !config.MINValue ||
            !config.MAXValue
        ) {
            setError("SiteId,Status,Group,SP,Label,PV,Min/Max ", VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        if (
            !isNumeric(config.min) ||
            !isNumeric(config.max) ||
            !isNumeric(config.interval)
            // !isNumeric(config.PValue) ||
            // !isNumeric(config.IValue) ||
            // !isNumeric(config.DValue) ||
            // !isNumeric(config.DBValue) ||
            // !isNumeric(config.MANValue) ||
            // !isNumeric(config.MINValue) ||
            // !isNumeric(config.MAXValue)
        ) {
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            return;
        }

        // ignore checking PID values
        /* if (
            (!isNumeric(config.PValue) && config.PValue !== "null") ||
            (!isNumeric(config.IValue) && config.IValue !== "null") ||
            (!isNumeric(config.DValue) && config.DValue !== "null") ||
            (!isNumeric(config.DBValue) && config.DBValue !== "null") ||
            (!isNumeric(config.MANValue) && config.MANValue !== "null") ||
            (!isNumeric(config.MINValue) && config.MINValue !== "null") ||
            (!isNumeric(config.MAXValue) && config.MAXValue !== "null")
        ) {
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            return;
        } */

        const data = {
            siteId: config.siteId,
            groupName: config.groupName,
            parameter: config.parameter,
            pvParameter: config.pvParameter,
            pvParameter2: config.pvParameter2,
            pvOperation: config.pvOperation,
            out: config.out,
            cvParameter: config.cvParameter,
            label: config.label,
            min: config.min,
            max: config.max,
            interval: config.interval,
            auto: config.auto,
            PValue: config.PValue,
            IValue: config.IValue,
            DValue: config.DValue,
            DBValue: config.DBValue,
            MANValue: config.MANValue,
            MINValue: config.MINValue,
            MAXValue: config.MAXValue,
            status: config.status
        };
        const url = URL.DATA.PLANT_SETTINGS.PIDDATA;
        try {
            if (isEdit) {
                await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } else {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            }
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            return;
        }
        updateConfig({
            ...config,
            ...emptyConfig
        });

        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        },
        label: {
            marginLeft: "16px"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog
          PaperComponent={PaperComponent}
          fullWidth
          maxWidth="md"
          open={open}
          onClose={handleClick}
            // aria-labelledby="form-dialog-title"
          aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                {`To ${isEdit ? "edit" : "add to"} the PID Data, please ${
                    isEdit ? "update" : "enter"
                } the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item md={3} xs={4}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
                              onChange={handleSite}
                              value={config.siteId}
                              type="text"
                              fullWidth
                              margin="dense"
                            />
                        </div>
                    </Grid>
                    <Grid item md={3} xs={4}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <DropdownSelect
                              name={config.status}
                              options={VERBIAGE.TEXT.STATUS_OPTIONS}
                              onChange={handleStatus}
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_NAME_MAPPING_STATUS}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_PARAMETER}
                              onChange={handleParameter}
                              value={config.parameter}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_LABEL}
                              onChange={handleLabel}
                              value={config.label}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_GROUPNAME}
                              onChange={handleGroupName}
                              value={config.groupName}
                              type="text"
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_PV_PARAMETER}
                              onChange={handlePVParameter}
                              value={config.pvParameter}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_PV_OPERATION}
                              onChange={handlePVOperation}
                              value={config.pvOperation}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_PV_PARAMETER2}
                              onChange={handlePVParameter2}
                              value={config.pvParameter2}
                              type="text"
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_CV_PARAMETER}
                              onChange={handleCVParameter}
                              value={config.cvParameter}
                              type="text"
                            />
                        </div>
                    </Grid>
                </Grid>
                <Divider variant="middle" />
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  display="block"
                  variant="body2"
                  align="left"
                >
                    Range Scale Parameters
                </Typography>
                <Grid container spacing={1}>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_SCALE_MIN}
                              onChange={handleMin}
                              value={config.min}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_SCALE_MAX}
                              onChange={handleMax}
                              value={config.max}
                              type="text"
                            />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_SCALE_INTERVAL}
                              onChange={handleInterval}
                              value={config.interval}
                              type="text"
                            />
                        </div>
                    </Grid>
                </Grid>

                <Divider variant="middle" />
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  display="block"
                  variant="body2"
                  align="left"
                >
                    PID Values
                </Typography>

                <Grid container justify="space-evenly" alignItems="center" spacing={1}>
                    {/* <Typography component="div"> */}
                    {/* <Grid
                component="label"
                container
                justify="space-evenly"
                alignItems="center"
                spacing={3}
              > */}
                    <Grid item xs={1}>
                        {/* {"MANUAL"} */}
                        <Typography
                            // className={classes.dividerFullWidth}
                          color="textSecondary"
                          display="block"
                          variant="h6"
                          align="right"
                        >
                            MANUAL
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <PurpleSwitch
                          checked={auto}
                          onChange={togglePIDAuto}
                          classes={{
                                root: classes.root
                            }}
                          color="primary"
                          name="auto"
                          disabled
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {/* {"AUTO"} */}
                        <Typography
                            // className={classes.dividerFullWidth}
                          color="textSecondary"
                          display="block"
                          variant="h6"
                          align="left"
                        >
                            AUTO
                        </Typography>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_PVALUE}
                              onChange={handlePValue}
                              value={config.PValue}
                              type="text"
                                // disabled
                            />
                        </div>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_IVALUE}
                              onChange={handleIValue}
                              value={config.IValue}
                              type="text"
                                // disabled
                            />
                        </div>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_DVALUE}
                              onChange={handleDValue}
                              value={config.DValue}
                              type="text"
                                // disabled
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item md={3} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_DBVALUE}
                              onChange={handleDBValue}
                              value={config.DBValue}
                              type="text"
                                // disabled
                            />
                        </div>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_MANVALUE}
                              onChange={handleMANValue}
                              value={config.MANValue}
                              type="text"
                                // disabled
                            />
                        </div>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_MINVALUE}
                              onChange={handleMINValue}
                              value={config.MINValue}
                              type="text"
                                // disabled
                            />
                        </div>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <div className={EditPIDDataTable.styles.inputContainer}>
                            <TextInput
                              placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_PID_MAXVALUE}
                              onChange={handleMAXValue}
                              value={config.MAXValue}
                              type="text"
                                // disabled
                            />
                        </div>
                    </Grid>
                </Grid>

                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditPIDDataTable.styles = {
    inputContainer: "webclient__mapTable-inputLeft",
    errorText: "webclient__mapTable-error-text"
};

export default EditPIDDataTable;
