/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";

/**
 * DateTimeFilterDataTable interface
 * isValidFn?: (selectedDate: Date) => [Boolean, string | null]
 */
const DateTimeFilterDataTable = ({ filterList, onChange, index, column, isValidFn }) => {
    const [errorMessage, setErrorMessage] = useState(null);
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              label={column.label}
              showTodayButton
              clearable
              value={filterList[index][0] ?? null}
              onChange={momentDate => {
                    if (!momentDate && filterList[index].length) {
                        filterList[index].splice(0, 1);
                    } else {
                        const selectedDate = momentDate.toDate();
                        if (isValidFn) {
                            const [isValid, error] = isValidFn(selectedDate);
                            setErrorMessage(error);
                            if (!isValid) return;
                        }
                        filterList[index][0] = selectedDate;
                    }
                    onChange(filterList[index], index, column);
                }}
              error={!!errorMessage}
              helperText={errorMessage}
              InputLabelProps={{
                    shrink: true
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default DateTimeFilterDataTable;
