/* eslint-disable max-len */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { VERBIAGE } from "../../utils/enums";
import CoolingTowerSpecOverview from "./coolingTowerSpec/CoolingTowerSpecOverview";

const CoolingTowerSpecManagementTabs = () => {
    return (
        <Switch>
            <Route
              path={VERBIAGE.WAREHOUSE_PATH.COOLING_TOWER_OVERVIEW}
              component={CoolingTowerSpecOverview}
            />
            <Route
              render={() => <Redirect to={VERBIAGE.WAREHOUSE_PATH.COOLING_TOWER_OVERVIEW} />}
            />
        </Switch>
    );
};

export default CoolingTowerSpecManagementTabs;
