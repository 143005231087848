/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    Link
} from "@material-ui/core";

import { durationFromDates, durationFromSeconds, toClientDate } from "../../../utils/helpers";

import AirflowStateIcon from "../components/AirflowStateIcon";
import TableCellNoBorder from "../components/TableCellNoBorder";

import LoadingText from "../components/LoadingText";

const PIDAutoMLAirflowDialog = props => {
    const { title, runningText, submitText, airflow, open, onClose, onSubmit } = props;

    const [running, setRunning] = useState(false);

    useEffect(() => {
        setRunning(airflow.lastDagRun?.state === "running");
    }, [airflow.lastDagRun]);

    const handleClose = (e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            onClose();
        }
    };

    const handleSubmit = () => {
        setRunning(true);
        onSubmit();
    };

    return (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll="paper"
          maxWidth="md"
          fullWidth
          disableEscapeKeyDown
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Typography variant="h4">DAG</Typography>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCellNoBorder component="th" style={{ width: "20%" }}>
                                    DAG ID
                                </TableCellNoBorder>
                                <TableCellNoBorder>
                                    <Link href={airflow.dagLink} rel="noopener" target="_blank">
                                        {airflow.dagId}
                                    </Link>
                                </TableCellNoBorder>
                            </TableRow>
                            <TableRow>
                                <TableCellNoBorder component="th">
                                    {`Last ${runningText}`}
                                </TableCellNoBorder>
                                <TableCellNoBorder>
                                    {toClientDate(airflow.lastDagRun?.execution_date)}
                                </TableCellNoBorder>
                            </TableRow>
                            <TableRow>
                                <TableCellNoBorder component="th">Last Status</TableCellNoBorder>
                                <TableCellNoBorder>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="flex-start"
                                      spacing={2}
                                    >
                                        <AirflowStateIcon state={airflow.lastDagRun?.state} />
                                        <Typography>{airflow.lastDagRun?.state}</Typography>
                                    </Grid>
                                </TableCellNoBorder>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Divider />
                    <Typography variant="h4">DAG runs</Typography>
                    {airflow.loading ? (
                        <LoadingText text="Loading list DAG runs..." />
                    ) : (
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    {airflow.dagRuns.map(dagRun => (
                                        <TableCell
                                          key={dagRun.dag_run_id}
                                          component="th"
                                          align="center"
                                        >
                                            <Tooltip
                                              placement="top"
                                              title={(
                                                    <List dense>
                                                        <ListItem>
                                                            <ListItemText
                                                              primary={`DAG Run ID: ${dagRun.dag_run_id}`}
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                              primary={`Run Type: ${dagRun.run_type}`}
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                              primary={`Status: ${dagRun.state}`}
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                              primary={`Start Date: ${toClientDate(
                                                                    dagRun.start_date,
                                                                )}`}
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                              primary={`End Date: ${toClientDate(
                                                                    dagRun.end_date,
                                                                )}`}
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                              primary={`Duration: ${durationFromDates(
                                                                    dagRun.start_date,
                                                                    dagRun.end_date,
                                                                )}`}
                                                            />
                                                        </ListItem>
                                                    </List>
                                                  )}
                                            >
                                                <Grid container justifyContent="center" spacing={2}>
                                                    <Grid item>
                                                        <Typography>
                                                            {toClientDate(dagRun.execution_date)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      container
                                                      direction="row"
                                                      justifyContent="center"
                                                      spacing={2}
                                                    >
                                                        <AirflowStateIcon state={dagRun.state} />
                                                    </Grid>
                                                </Grid>
                                            </Tooltip>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {airflow.tasks.map(t => (
                                    <TableRow key={t.task_id}>
                                        <TableCell component="th">{t.task_id}</TableCell>
                                        {airflow.taskInstances.map(taskInstance => {
                                            const task = taskInstance[t.task_id];
                                            if (!task) {
                                                return (
                                                    <TableCell align="center">
                                                        <AirflowStateIcon />
                                                    </TableCell>
                                                );
                                            }

                                            return (
                                                <TableCell align="center">
                                                    <Tooltip
                                                      placement="top"
                                                      title={(
                                                            <List dense>
                                                                <ListItem>
                                                                    <ListItemText
                                                                      primary={`Status: ${task.state}`}
                                                                    />
                                                                </ListItem>
                                                                <ListItem>
                                                                    <ListItemText
                                                                      primary={`Started: ${toClientDate(
                                                                            task.start_date,
                                                                        )}`}
                                                                    />
                                                                </ListItem>
                                                                <ListItem>
                                                                    <ListItemText
                                                                      primary={`Duration: ${durationFromSeconds(
                                                                            task.duration,
                                                                        )}`}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                          )}
                                                    >
                                                        <AirflowStateIcon state={task.state} />
                                                    </Tooltip>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={running}
                  onClick={handleSubmit}
                >
                    {running ? <LoadingText text={runningText} /> : submitText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PIDAutoMLAirflowDialog;
