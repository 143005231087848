/* eslint-disable react/destructuring-assignment  */
import React, { useEffect, useMemo, useState } from "react";
import { Tab, Tabs, Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import MobileDetect from "mobile-detect";

import { VERBIAGE } from "../../utils/enums/index";

import SiteManagementTabs from "../components/SiteManagementTabs";

const SiteManagement = () => {
    const location = useLocation();

    const home = useSelector(state => state.home);

    const [value, setValue] = useState(false);

    const tabs = useMemo(() => {
        return [
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.SITE_OVERVIEW,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.SITE_OVERVIEW,
                path: VERBIAGE.WAREHOUSE_PATH.SITE_OVERVIEW,
                exact: true
            },
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.SITE_WATER_PUMP,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.SITE_WATER_PUMP,
                path: VERBIAGE.WAREHOUSE_PATH.SITE_WATER_PUMP
            },
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.SITE_COOLING_TOWER,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.SITE_COOLING_TOWER,
                path: VERBIAGE.WAREHOUSE_PATH.SITE_COOLING_TOWER
            },
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.SITE_PID,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.SITE_PID,
                path: VERBIAGE.WAREHOUSE_PATH.SITE_PID
            },
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.SITE_INSTRUMENT,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.SITE_INSTRUMENT,
                path: VERBIAGE.WAREHOUSE_PATH.SITE_INSTRUMENT
            },
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.SITE_CHILLER,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.SITE_CHILLER,
                path: VERBIAGE.WAREHOUSE_PATH.SITE_CHILLER
            },
            {
                label: VERBIAGE.WAREHOUSE_TAB_TEXT.SITE_ENVIRONMENT,
                value: VERBIAGE.WAREHOUSE_TAB_VALUE.SITE_ENVIRONMENT,
                path: VERBIAGE.WAREHOUSE_PATH.SITE_ENVIRONMENT
            }
        ];
    }, []);

    useEffect(() => {
        const tab = tabs.find(t => t.path === location.pathname);
        setValue(tab?.value ?? false);
    }, [location.pathname, tabs]);

    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        }
    }));

    const classes = useStyles();

    return (
        <>
            <Grid container item xs={12} className={classes.root}>
                {/* header */}
                <Grid item xs={12}>
                    <div
                      className={classNames(SiteManagement.styles.headerContainer, classes.title)}
                    >
                        <span>{VERBIAGE.WAREHOUSE_MENU_TEXT.SITE_MANAGEMENT}</span>
                    </div>
                </Grid>
                {/* content */}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Tabs
                          value={value}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons={useMediaQuery("(max-width: 850px)") ? "on" : "auto"}
                        >
                            {tabs.map(tab => (
                                <Tab
                                  key={tab.value}
                                  value={tab.value}
                                  label={tab.label}
                                  component={Link}
                                  to={() => {
                                        return tab.exact
                                            ? tab.path
                                            : `${tab.path}${location.search}`;
                                    }}
                                />
                            ))}
                        </Tabs>
                        <SiteManagementTabs />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
SiteManagement.styles = {
    headerContainer: "webclient__portfolioView-container-header"
};
export default SiteManagement;
