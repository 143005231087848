export default class DynamicReportStorage {
    constructor(key) {
        this.key = key;
    }

    load(defaultValues = []) {
        let data;
        try {
            data = JSON.parse(localStorage.getItem(this.key)) ?? defaultValues;
        } catch (error) {
            data = defaultValues;
        }
        return data;
    }

    set(data) {
        localStorage.setItem(this.key, JSON.stringify(data));
    }

    sync(data = []) {
        if (!data.length) return [false];

        const currTabs = this.load();
        if (!currTabs.length) return [false];

        let hasChanged = false;
        const newTabs = [];
        for (let i = 0; i < currTabs.length; i++) {
            const tab = currTabs[i];
            const originTab = data.find(t => t.main === tab.value);
            // removed
            if (!originTab) {
                hasChanged = true;
            }
            // title changed
            else if (tab.label !== originTab.name) {
                newTabs.push({ label: originTab.name, value: originTab.main });
                hasChanged = true;
            } else {
                newTabs.push({ label: originTab.name, value: originTab.main });
            }
        }

        return [hasChanged, newTabs];
    }
}
