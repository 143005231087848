/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-nested-ternary  */
import React, { useState } from "react";
import classNames from "classnames";
import { Tab, Tabs, Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SystemHeader from "../components/SystemHeader";
import VERBIAGE from "../../../utils/enums/Verbiage";
import AnalyticsTabs from "./analyticsTab";
// import AnalyticsTime from "./AnalyticsTime";
// import AnalyticsTime from "./AnalyticsTime";
// import LifecycleChartSelect from "./LifecycleChartSelect";

const MobileDetect = require("mobile-detect");

const AnalyticsTabSettings = props => {
    const { home } = props;
    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: "10px 10px 10px 25px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        grid: {
            padding: "7px"
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        tabContainer: {
            flexDirection: "column",
            height: "60px",
            width: "calc(100% - 8px)",
            [theme.breakpoints.up("xs")]: {
                flexDirection: "row"
                // height: "160px"
            },
            [theme.breakpoints.up("1200")]: {
                flexDirection: "column"
                // height: "60px"
            }
        },
        fixed: {
            display: "flex"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box",
            paddingTop: "70px"
        }
    }));

    // const { authentications } = props;
    const isAnalyticsDeveloped = localStorage.getItem("isAnalyticsDeveloped");
    const isOptimizationDeveloped = localStorage.getItem("isOptimizationDeveloped");
    const permission = localStorage.getItem("permission");
    const isAnalyticsBeingDeveloped = localStorage.getItem("isAnalyticsBeingDeveloped");

    const [value, setValue] = useState(
        isAnalyticsDeveloped === "false" ? "faults_and_alarms" : "analyticsOverview",
    );
    const classes = useStyles();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // const [lifecycleChart, setLifecycleChart] = useState("Chiller");
    // on dropdown change
    // const handleOnChange = event => {
    //     const val = event.target.value;
    //     setLifecycleChart(val);
    // };

    const [worksheets, setWorksheets] = useState([]);
    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    // RangeSlider data
    const date = new Date();
    const [max, setMax] = useState(date.getTime());
    // initialStart is 1 day before, min is 3 days before
    date.setDate(date.getDate() - 1);
    date.setHours(date.getHours() + 1);
    const initialStart = date.setMinutes(0, 0, 0);
    const [min, setMin] = useState(date.setDate(date.getDate() - 2));
    const [dates, setDates] = useState([initialStart, max]);

    // RangeSlider methods
    const handleSliderChange = (event, newValue) => {
        setDates(newValue);
    };
    // Change datetime filter on worksheets only after slide change is committed
    const onSliderChangeCommitted = (event, newValue) => {
        const [start, end] = newValue;
        let startDate = new Date(start);
        let endDate = new Date(end);

        // adjust the time for GMT +8
        startDate = startDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000;
        endDate = endDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000;
        worksheets.forEach(ws => {
            ws.applyRangeFilterAsync(
                "SYS_TS_DATETIME",
                {
                    min: new Date(startDate),
                    max: new Date(endDate)
                },
                "replace",
            );
        });
    };

    const initialFilter = ws => {
        const [start, end] = dates;
        let startDate = new Date(start);
        let endDate = new Date(end);

        // adjust the time for GMT +8
        startDate = startDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000;
        endDate = endDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000;
        ws.applyRangeFilterAsync(
            "SYS_TS_DATETIME",
            {
                min: new Date(startDate),
                max: new Date(endDate)
            },
            "replace",
        );
    };

    const handleStartDateInput = event => {
        if (event._isValid) {
            const newTime = new Date(event._d).getTime();
            setDates(prev => {
                const newValue = [newTime, prev[1]];
                onSliderChangeCommitted(null, newValue);
                setMin(newTime);
                return newValue;
            });
        }
    };

    const handleEndDateInput = event => {
        if (event._isValid) {
            const newTime = new Date(event._d).getTime();
            setDates(prev => {
                const newValue = [prev[0], newTime];
                onSliderChangeCommitted(null, newValue);
                setMax(newTime);
                return newValue;
            });
        }
    };

    // useEffect(() => {
    //     if (worksheets.length >= 1) {
    //         onSliderChangeCommitted(null, dates);
    //     }
    // }, [worksheets, max]);

    const tabStyle = {
        fontSize: "13px",
        fontWeight: "500",
        textTransform: "capitalize",
        minWidth: "100px"
    };
    // const header = value;
    return (
        <div className="main-wrapper">
            <div>
                <SystemHeader authentication={props.authentication} />
            </div>
            <div className={classes.main}>
                <Grid container item xs={12} className={classes.root} spacing={2}>
                    {/* header start */}
                    <Grid item xs={12}>
                        <div
                          className={classNames(
                                AnalyticsTabSettings.styles.headerContainer,
                                classes.title,
                            )}
                        >
                            <span>{VERBIAGE.MENU_TEXT.ANALYTICS}</span>
                        </div>
                    </Grid>
                    {/* header end */}
                    <Grid container className={classes.tabContainer}>
                        <Grid item xs={12}>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="secondary"
                              textColor="secondary"
                              variant="scrollable"
                              scrollButtons={useMediaQuery("(max-width: 850px)") ? "on" : "auto"}
                              classes={{
                                    fixed: classes.fixed,
                                    scrollable: classes.fixed
                                }}
                            >
                                <Tab
                                  style={tabStyle}
                                  value="analyticsOverview"
                                  label="Analytics Overview"
                                  wrapped
                                    // disabled={!authentications.isAnalyticsDeveloped}
                                    // disabled={isAnalyticsDeveloped === "false"}
                                  disabled={
                                        isAnalyticsDeveloped === "false" &&
                                        !(
                                            isAnalyticsBeingDeveloped === "true" &&
                                            (permission === "admin_level" ||
                                                permission === "developer_access" ||
                                                permission === "admin_access")
                                        )
                                    }
                                />
                                {/* <Tab
                                  style={tabStyle}
                                  value="anomalyDetection"
                                  label="Faults & Alarms"
                                  wrapped
                                /> */}
                                <Tab
                                  style={tabStyle}
                                  value="faults_and_alarms"
                                  label="Faults & Alarms"
                                  wrapped
                                />
                                {/* <Tab
                                  style={tabStyle}
                                  value="anomalyDiagnosis"
                                  label="Anomaly Diagnosis"
                                  wrapped
                                /> */}
                                <Tab
                                  style={tabStyle}
                                  value="equipmentLifecycle"
                                  label="Equipment Lifecycle"
                                  wrapped
                                    // disabled={!authentications.isAnalyticsDeveloped}
                                    // disabled={isAnalyticsDeveloped === "false"}
                                  disabled={
                                        isAnalyticsDeveloped === "false" &&
                                        !(
                                            isAnalyticsBeingDeveloped === "true" &&
                                            (permission === "admin_level" ||
                                                permission === "developer_access" ||
                                                permission === "admin_access")
                                        )
                                    }
                                />
                                <Tab
                                  style={tabStyle}
                                  value="config"
                                  label="Alarm Config"
                                  wrapped
                                    // disabled={!authentications.isAnalyticsDeveloped}
                                    // disabled={isAnalyticsDeveloped === "false"}
                                  disabled={
                                        isAnalyticsDeveloped === "false" &&
                                        !(
                                            isAnalyticsBeingDeveloped === "true" &&
                                            (permission === "admin_level" ||
                                                permission === "developer_access" ||
                                                permission === "admin_access")
                                        )
                                    }
                                />
                                <Tab
                                  style={tabStyle}
                                  value="energyOptimization"
                                  label="Energy Optimization"
                                  wrapped
                                    // disabled={!authentications.isAnalyticsDeveloped}
                                  disabled={
                                        (isAnalyticsDeveloped === "false" ||
                                            isOptimizationDeveloped === "false") &&
                                        !(
                                            isAnalyticsBeingDeveloped === "true" &&
                                            (permission === "admin_level" ||
                                                permission === "developer_access" ||
                                                permission === "admin_access")
                                        )
                                    }
                                />
                            </Tabs>
                        </Grid>
                        {/* <div>
                {header === "anomalyDetection" ? <AnalyticsTime/> : ""
                            // header === "equipmentLifecycle" ? (
                            // <LifecycleChartSelect
                            //   handleOnChange={handleOnChange}
                            //   lifecycleChart={lifecycleChart}
                            // />
                            // ) : (
                            // )}
                            }
              </div> */}
                    </Grid>
                    <AnalyticsTabs
                      value={value}
                      addWorksheet={addWorksheet}
                      worksheets={worksheets}
                      changeTab={setValue}
                        //   lifecycleChart={lifecycleChart}
                      setTabDates={setDates}
                      setTabMax={setMax}
                      setTabMin={setMin}
                      dates={dates}
                      min={min}
                      max={max}
                      handleSliderChange={handleSliderChange}
                      onSliderChangeCommitted={onSliderChangeCommitted}
                      handleStartDateInput={handleStartDateInput}
                      handleEndDateInput={handleEndDateInput}
                      classes={classes}
                      tabStyle={tabStyle}
                      initialFilter={initialFilter}
                    />
                </Grid>
                {/* <Grid>
            <AnalyticsTime />
          </Grid> */}
            </div>
        </div>
    );
};
AnalyticsTabSettings.styles = {
    headerContainer: "webclient__portfolioView-container-header",
    datePaper: "webclient__portfolioView-datepaper"
};

const mapStateToProps = state => ({
    home: state.home,
    authentications: state.authentication
});

// export default AnalyticsTabSettings;
export default connect(mapStateToProps, null)(withRouter(AnalyticsTabSettings));
