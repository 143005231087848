/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classNames from "classnames";
import "../styles/siteOverview.scss";
import SystemHeader from "../../common/components/SystemHeader";
import { VERBIAGE } from "../../../utils/enums/index";
import PlantView from "../../common/plantSchematic/plantView";
import ICONS from "../../../utils/enums/IconData";

const MobileDetect = require("mobile-detect");

const PlantOverview = props => {
    const { home } = props;
    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            paddingLeft: "10px",
            paddingRight: "5px",
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : "unset"
        },
        paper: {
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "white"
        },
        construct: {
            minHeight: "400px",
            textAlign: "center",
            margin: "auto",
            width: "60%",
            padding: "150px",
            fontSize: "20px"
        },
        icon: {
            width: "5%"
        },
        grid: {
            padding: "8px 5px"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            boxSizing: "border-box"
        }
    }));
    const classes = useStyles();
    const isSchematicsDeveloped = localStorage.getItem("isSchematicsDeveloped");
    return (
        <div className="main-wrapper">
            <div>
                <SystemHeader authentication={props.authentication} />
            </div>
            <div className={classNames(classes.root, classes.main)}>
                <Grid container>
                    <Grid container item xs={12} className={classes.grid}>
                        <span className={PlantOverview.styles.siteText}>
                            {VERBIAGE.MENU_TEXT.PLANT_SCHEMATICS}
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        {isSchematicsDeveloped === "true" ? (
                            <Paper className={classes.paper}>
                                <PlantView />
                            </Paper>
                        ) : (
                            <Paper className={classes.paper}>
                                <div className={classes.construct}>
                                    <img className={classes.icon} src={ICONS.Triangle} alt="Icon" />
                                    <p>Under Construction</p>
                                </div>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

PlantOverview.styles = {
    siteText: "webclient__siteOverview-site-text"
};
const mapStateToProps = state => ({
    home: state.home
});
export default connect(mapStateToProps)(PlantOverview);
