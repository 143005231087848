/* eslint-disable max-len */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import Dropdown from "./Dropdown";
import { VERBIAGE } from "../../../utils/enums/index";

const useStyles = makeStyles(theme => ({
    dropdown: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_LIGHT_BLUE}`, // "1px solid #3865A7",
        borderRadius: "4px",
        height: "3vh",
        width: "110px"
    },
    root: {
        flexGrow: 1
    },
    center: {
        padding: "0.5vh !important",
        textAlign: "center"
    },
    text: {
        color: "#7e7e7e",
        marginTop: "1rem",
        marginRight: "2px",
        marginLeft: "0.1px",
        fontWeight: 500,
        fontSize: 11
    }
}));

const SelectChart = props => {
    const { options, changeChart } = props;

    const [chart, setChart] = useState("Cooling Load");

    const onDropdownChange = event => {
        setChart(event);
        changeChart(event);
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={1} className={classes.center}>
                    <Typography variant="caption" className={classes.text}>
                        View
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Dropdown
                      className={classes.dropdown}
                      value={chart}
                      options={options}
                      onChange={event => onDropdownChange(event.target.value)}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default SelectChart;
