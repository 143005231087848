/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
import compact from "lodash/compact";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";

import API from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

import "../styles/mapTable.scss";

const EditSiteDag = forwardRef((props, ref) => {
    const { isEdit, open, onClose } = props;

    const [inputErrors, setInputErrors] = useState({});
    const [hasInputError, setHasInputError] = useState(false);
    const [error, setError] = useState("");

    const [loading, setLoading] = useState(false);

    const initConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        airflow: VERBIAGE.TEXT.EMPTY_STRING,
        trainDagId: VERBIAGE.TEXT.EMPTY_STRING,
        predictDagId: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, setConfig] = useState(initConfig);
    const [airflowData, setAirflowData] = useState([]);
    const [airflowOptions, setAirflowOptions] = useState([]);

    useImperativeHandle(ref, () => ({
        init() {
            setConfig(initConfig);
        },
        update(data) {
            setConfig({
                ...config,
                ...data
            });
        }
    }));

    useEffect(() => {
        const fetchAirflow = async () => {
            try {
                const response = await API.request({
                    headers: {
                        "x-auth-token": localStorage.token
                    },
                    url: URL.DATA.ML_SETTINGS.AIRFLOW,
                    method: "GET",
                    params: {
                        isActive: true,
                        _select: ["_id", "name"]
                    }
                });
                setAirflowData(response.data);
                setAirflowOptions(response.data.map(item => item.name));
            } catch (err) {
                setInputErrors(curr => ({ ...curr, airflow: err.message }));
            }
        };
        if (open) {
            fetchAirflow();
        }
    }, [open]);

    useEffect(() => {
        setHasInputError(compact(Object.values(inputErrors)).length);
    }, [inputErrors]);

    const handleTextChange = field => {
        return ({ target: { value } }) => {
            const errRequired = !value ? "This field is required" : undefined;
            setInputErrors(curr => ({ ...curr, [field]: errRequired }));
            if (errRequired) return;
            setConfig({ ...config, [field]: value });
        };
    };

    const handleAirflowChange = e => {
        setConfig({ ...config, airflow: e.target.value });
    };

    const closeDialog = (e, refresh) => {
        setError(null);
        setInputErrors({});
        onClose(refresh);
    };

    const checkError = () => {
        let hasErrRequired = false;
        ["siteId", "airflow", "trainDagId", "predictDagId"].forEach(field => {
            const errRequired = !config[field] ? "This field is required" : undefined;
            if (errRequired && !hasErrRequired) {
                hasErrRequired = true;
            }
            setInputErrors(curr => ({ ...curr, [field]: errRequired }));
        });
        return hasErrRequired;
    };

    const handleSubmit = async () => {
        if (checkError()) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }

        try {
            setLoading(true);

            const { _id, airflow, ...data } = config;
            const airflowId = airflowData.find(item => item.name === airflow)?._id;

            const requestConfig = {
                headers: {
                    "x-auth-token": localStorage.token
                },
                url: URL.DATA.ML_SETTINGS.SITE_DAG,
                method: "POST",
                data: {
                    ...data,
                    airflow: airflowId
                }
            };
            if (_id) {
                requestConfig.method = "PUT";
                requestConfig.url += `/${_id}`;
            }

            const response = await API.request(requestConfig);
            if ([200, 201].includes(response.status)) {
                closeDialog(null, true);
            }
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else {
                const { details } = err.response.data;
                if (typeof details === "object" && details.errors) {
                    Object.keys(details.errors).forEach(field => {
                        const message = details.errors[field].message
                            .replace(/^Path/i, "")
                            .replace(new RegExp(`\`${field}\`|"${field}"|${field}`), "This field")
                            .trim();
                        setInputErrors(curr => ({ ...curr, [field]: message }));
                    });
                    setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} the Site DAG, please enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <div className={EditSiteDag.styles.inputContainer}>
                    <TextInput
                      type="text"
                      onChange={handleTextChange("siteId")}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
                      value={config.siteId}
                      error={inputErrors.siteId}
                      helperText={inputErrors.siteId}
                      disabled={isEdit}
                      required
                    />
                </div>
                <div className={EditSiteDag.styles.inputContainer}>
                    <DropdownSelect
                      name={config.airflow}
                      placeholder="Select Airflow Connection"
                      onChange={handleAirflowChange}
                      options={airflowOptions}
                      error={inputErrors.airflow}
                      helperText={inputErrors.airflow}
                      closeMenuOnSelect
                      required
                    />
                </div>
                <div className={EditSiteDag.styles.inputContainer}>
                    <TextInput
                      type="text"
                      onChange={handleTextChange("trainDagId")}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_TRAIN_DAG_ID}
                      value={config.trainDagId}
                      error={inputErrors.trainDagId}
                      helperText={inputErrors.trainDagId}
                      required
                    />
                </div>
                <div className={EditSiteDag.styles.inputContainer}>
                    <TextInput
                      type="text"
                      onChange={handleTextChange("predictDagId")}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_PREDICT_DAG_ID}
                      value={config.predictDagId}
                      error={inputErrors.predictDagId}
                      helperText={inputErrors.predictDagId}
                      required
                    />
                </div>
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={loading || hasInputError} onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={closeDialog}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditSiteDag.styles = {
    inputContainer: "webclient__mapTable-input"
};

export default EditSiteDag;
