/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect, useRef } from "react";
import {
    Accordion,
    AccordionSummary,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Publish, VisibilityOutlined, ExpandMore } from "@material-ui/icons";
import MomentAdapter from "@date-io/moment";
import api from "../../../../utils/API";
import URL from "../../../../utils/URL";
import CustomFooter from "../CustomFooter";

import AssetDetailForm from "./AssetDetailForm";
import AssetUpload from "./AssetUpload";

const moment = new MomentAdapter();

const AssetListTable = () => {
    const [data, setData] = useState([]);

    const [open, setOpen] = useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    const isNarrow = useMediaQuery("(max-width: 600px)");

    const isMounted = useRef(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleUploadClick = () => {
        setUploadOpen(!uploadOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.PLANT_SETTINGS.ASSETS, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                const parsedDates = response.data.map(row => {
                    return {
                        ...row,
                        parsedDate: moment.parse(row.createdOn).format("YYYY-MM-DD HH:mm:ss Z")
                    };
                });
                if (isMounted.current) {
                    setData(parsedDates);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open, uploadOpen]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: "16px"
        }
    }))(TableCell);

    const cols = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <IconButton
                          onClick={() => {
                                editRef.current.update({
                                    ...editRef.current.getData(),
                                    ...data[dataIndex]
                                });
                                setIsEdit(true);
                                handleClick();
                            }}
                        >
                            <VisibilityOutlined />
                        </IconButton>
                    );
                }
            }
        },
        { label: "Name", name: "name" },
        { label: "Type", name: "type" },
        { label: "Created Date", name: "parsedDate" },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <img
                          className="preview"
                          src={data[dataIndex].url}
                          alt={data[dataIndex].name}
                          height="50"
                        />
                    );
                }
            }
        }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        const rowData = data[dataIndex];
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell>
                                            <IconButton
                                              onClick={event => {
                                                    event.stopPropagation();
                                                    editRef.current.update({
                                                        ...editRef.current.getData(),
                                                        ...rowData
                                                    });
                                                    setIsEdit(true);
                                                    handleClick();
                                                }}
                                              onFocus={event => event.stopPropagation()}
                                            >
                                                <VisibilityOutlined />
                                            </IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>{rowData.name}</StyledTableCell>
                                    </TableRow>
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell>Type</StyledTableCell>
                                                <StyledTableCell>{rowData.type}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Date</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.parsedDate}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Preview</StyledTableCell>
                                                <StyledTableCell>
                                                    <img
                                                      className="preview"
                                                      src={rowData.url}
                                                      alt={rowData.name}
                                                      height="50"
                                                    />
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "400px",
        filter: false,
        print: false,
        viewColumns: false,
        download: false,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title="Upload Asset">
                        <IconButton onClick={() => setUploadOpen(true)}>
                            <Publish />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    return (
        <div>
            <AssetDetailForm
              isEdit={isEdit}
              open={open}
              handleClick={handleClick}
              setOpen={setOpen}
              ref={editRef}
            />
            <AssetUpload
              open={uploadOpen}
              setOpen={setUploadOpen}
              handleClick={handleUploadClick}
              url={URL.DATA.PLANT_SETTINGS.ASSETS_UPLOAD}
            />
            <div className={classes.table}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable title="Assets" data={data} columns={cols} options={options} />
                </MuiThemeProvider>
            </div>
        </div>
    );
};

export default AssetListTable;
