import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import useRuleBasedOptimizationCHWP from "./hooks/useRuleBasedOptimizationCHWP";

const RuleBasedOptimizationCHWPConfig = () => {
    const { config, fetchConfig, saveConfig } = useRuleBasedOptimizationCHWP();

    const [flowKWChange, setFlowKWChange] = React.useState(config.data.flowKWChange);
    const [formula, setFormula] = React.useState(config.data.formula);
    const [settings, setSettings] = React.useState(config.data.settings);

    useEffect(() => {
        fetchConfig();
    }, [fetchConfig]);

    useEffect(() => {
        setFlowKWChange(prev => ({ ...prev, ...config.data.flowKWChange }));
        setFormula(prev => ({ ...prev, ...config.data.formula }));
        setSettings(prev => ({ ...prev, ...config.data.settings }));
    }, [config.data]);

    const handleFlowKWChange = field => event => {
        setFlowKWChange(prev => ({ ...prev, [field]: event.target.value }));
    };
    const handleFormulaChange = field => event => {
        setFormula(prev => ({ ...prev, [field]: event.target.value }));
    };
    const handleSettingChange = field => event => {
        setSettings(prev => ({ ...prev, [field]: Number(event.target.checked) }));
    };

    const handleSave = () => {
        saveConfig({ flowKWChange, formula, settings });
    };

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h6">
                    Chiller Efficiency Improvement per Percentage Flow increase
                </Typography>
            </Grid>
            <Grid item container spacing={2}>
                <Grid item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="FLOW_CHANGE_PERC"
                      label="Flow Increase"
                      type="number"
                      inputProps={{ min: 0, max: 100, step: 0.1 }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                      InputLabelProps={{ shrink: true }}
                      value={flowKWChange.FLOW_CHANGE_PERC}
                      onChange={handleFlowKWChange("FLOW_CHANGE_PERC")}
                      disabled={config.loading}
                      required
                    />
                </Grid>
                <Grid item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="KW_CHANGE_PERC"
                      label="Efficiency Improvement"
                      type="number"
                      inputProps={{ min: 0, max: 100, step: 0.1 }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                      InputLabelProps={{ shrink: true }}
                      value={flowKWChange.KW_CHANGE_PERC}
                      onChange={handleFlowKWChange("KW_CHANGE_PERC")}
                      disabled={config.loading}
                      required
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="h6">KW Calculation Formula</Typography>
            </Grid>
            <Grid item>
                <TextField
                  variant="outlined"
                  name="POWER_MAGNITUDE"
                  label="Power"
                  type="number"
                  aria-describedby="power-helper-text"
                  inputProps={{ min: 0, step: 0.1 }}
                  InputLabelProps={{ shrink: true }}
                  value={formula.POWER_MAGNITUDE}
                  onChange={handleFormulaChange("POWER_MAGNITUDE")}
                  disabled={config.loading}
                  required
                />
                <FormHelperText id="power-helper-text">
                    (P1/P2)^
                    <strong>{formula.POWER_MAGNITUDE}</strong>
{' '}
= (DT2/DT1)
                </FormHelperText>
            </Grid>
            <Grid item>
                <Typography variant="h6">Time/Load Based</Typography>
            </Grid>
            <Grid item>
                <FormGroup>
                    <FormControlLabel
                      control={(
                            <Checkbox
                              checked={Boolean(settings.LOAD_BASED)}
                              onChange={handleSettingChange("LOAD_BASED")}
                              name="LOAD_BASED"
                            />
                          )}
                      label="LOAD BASED"
                    />
                    <FormControlLabel
                      control={(
                            <Checkbox
                              checked={Boolean(settings.TIME_BASED)}
                              onChange={handleSettingChange("TIME_BASED")}
                              name="TIME_BASED"
                            />
                          )}
                      label="TIME BASED"
                    />
                </FormGroup>
            </Grid>
            <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                  disabled={config.loading}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};

export default RuleBasedOptimizationCHWPConfig;
