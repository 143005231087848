/* eslint-disable max-len */
/* eslint-disable no-unused-vars  */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import "../styles/time.scss";
import { VERBIAGE } from "../../../utils/enums/index";
import Dropdown from "./Dropdown";

const useStyles = makeStyles(theme => ({
    button: {
        background: theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE, // "#3865A7",
        height: "22px",
        textTransform: "none"
    },
    field: {
        padding: "4px"
    },
    dropdown: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}`, // "1px solid #3865A7",
        borderRadius: "4px",
        height: "22px",
        width: "100px",
        color:
            theme.palette.type === "dark"
                ? theme.palette.text.grey
                : theme.palette.text.light_grey /* eslint-disable max-len */
    },
    number: {
        border:
            theme.palette.type === "dark"
                ? "1px solid #444444"
                : `1px solid ${VERBIAGE.COLOUR_DARK_BLUE}`, // "1px solid #3865A7",
        width: "50px",
        // [theme.breakpoints.up("xs")]: {
        //     width: "6vw"
        // },
        // [theme.breakpoints.up("sm")]: {
        //     width: "5vw"
        // },
        // [theme.breakpoints.up("md")]: {
        //     width: "4vw"
        // },
        // [theme.breakpoints.up("lg")]: {
        //     width: "3vw"
        // },
        // [theme.breakpoints.up("xl")]: {
        //     width: "3vw"
        // },
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey,
        fontSize: 14,
        fontWeight: 500,
        paddingTop: "2px"
    },
    text: {
        color:
            theme.palette.type === "dark" ? theme.palette.text.grey : theme.palette.text.light_grey
    }
}));
const Interval = props => {
    const {
        intervalValue,
        intervalChange,
        intervalTypeValue,
        intervalTypeChange,
        onClick,
        button,
        faultLabel
    } = props;
    const classes = useStyles();

    return (
        <Grid container item justify="flex-end">
            <Grid item className={classes.field}>
                {faultLabel ? (
                    <span className={classNames(Interval.styles.text, classes.text)}>
                        {VERBIAGE.ANALYTICS.FAULT_DURATION}
                    </span>
                ) : (
                    <span className={classNames(Interval.styles.text, classes.text)}>
                        {VERBIAGE.ANALYTICS.LAST}
                    </span>
                )}
            </Grid>
            <Grid item className={classes.field}>
                <input
                  className={classNames(Interval.styles.input, classes.number)}
                  type="number"
                  value={intervalValue}
                  onChange={intervalChange}
                />
            </Grid>
            <Grid item className={classes.field}>
                <Dropdown
                  className={classes.dropdown}
                  value={intervalTypeValue}
                  options={VERBIAGE.SYSTEM_OVERVIEW.INTERVAL}
                  onChange={intervalTypeChange}
                />
            </Grid>
            {button && (
                <Grid item className={classes.field}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.button}
                      onClick={onClick}
                    >
                        {VERBIAGE.BUTTONS.APPLY}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

Interval.propTypes = {
    intervalValue: PropTypes.string,
    intervalTypeValue: PropTypes.string,
    intervalTypeChange: PropTypes.func,
    intervalChange: PropTypes.func,
    onClick: PropTypes.func,
    button: PropTypes.bool
};
Interval.defaultProps = {
    intervalValue: VERBIAGE.TEXT.EMPTY_STRING,
    intervalTypeValue: VERBIAGE.TEXT.EMPTY_STRING,
    button: false,
    intervalTypeChange: () => {},
    intervalChange: () => {},
    onClick: () => {}
};
Interval.styles = {
    input: "webclient__time-input",
    button: "webclient__time-button",
    text: "webclient__time-text",
    dropdown: "webclient__time-dropdown"
};

export default Interval;
