import Exclamation from "../../../assets/exclamation.png";
import Arrow from "../../../assets/arrow1.png";
import Logo from "../../../assets/bbp_logo_dark_background.png";
import Pause from "../../../assets/pause.svg";
import CapLogo from "../../../assets/Resorts-World-Sentosa-Logo 1.svg";
import SiteOverview from "../../../assets/layout.svg";
import Home from "../../../assets/home.svg";
import Charts from "../../../assets/activity.svg";
import Algorithm from "../../../assets/trending-up.svg";
import Reports from "../../../assets/pie-chart.svg";
import Support from "../../../assets/help-circle.svg";
import Power from "../../../assets/power.svg";
import Notification from "../../../assets/bell.svg";
import Lock from "../../../assets/lock.svg";
import Logout from "../../../assets/log-out.svg";
import Sun from "../../../assets/sun.svg";
import Moon from "../../../assets/moon.svg";
import Grid from "../../../assets/grid.svg";
import Battery from "../../../assets/battery-charging.svg";
import Leaf from "../../../assets/Leaf.svg";
import Droplet from "../../../assets/droplet.svg";
import Alerttriangle from "../../../assets/alert-triangle.svg";
import AlertCircle from "../../../assets/alert-circle.svg";
import Ellipsis from "../../../assets/Shape.svg";
import Filter from "../../../assets/filter.png";
import Zap from "../../../assets/Zap.svg";
import SortUp from "../../../assets/up.png";
import SortDown from "../../../assets/down.png";
import Sort from "../../../assets/Twosidearrows.png";
import Admin from "../../../assets/admin.svg";
import Plant from "../../../assets/plant.svg";
import Analytics from "../../../assets/analytics.svg";
import User from "../../../assets/user.svg";
import Password from "../../../assets/password.svg";
import Pid from "../../../assets/PID.svg";
import InfoIcon from "../../../assets/info.svg";
import Onboard from "../../../assets/onboarding.svg";
import Monitoring from "../../../assets/monitoring.svg";
import Thermometer from "../../../assets/thermometer2.svg";
import RotateCW from "../../../assets/rotate-cw.svg";
import Wind from "../../../assets/wind.svg";
import Tablet from "../../../assets/tablet.svg";
import BBPLogo from "../../../assets/bbp_new_logo.png";
import BBPLoginRight from "../../../assets/login-right-image.jpg";
import BBPLoginLeft from "../../../assets/login-left-image.jpg";
import LoginUser from "../../../assets/outline-person.svg";
import LoginPassword from "../../../assets/lock2.svg";
import Triangle from "../../../assets/triangle.svg";

const ICONS = {
    EXCLAMATION: Exclamation,
    ARROW: Arrow,
    LOGO: Logo,
    PAUSE: Pause,
    CAP_LOGO: CapLogo,
    HOME: Home,
    SITE_OVERVIEW: SiteOverview,
    REPORTS: Reports,
    CHARTS: Charts,
    ALGORITHM: Algorithm,
    SUPPORT: Support,
    POWER: Power,
    NOTIFICATION: Notification,
    LOCK: Lock,
    LOGOUT: Logout,
    SUN: Sun,
    MOON: Moon,
    GRID: Grid,
    BATTERY: Battery,
    LEAF: Leaf,
    DROPLET: Droplet,
    CIRCLE: AlertCircle,
    TRIANGLE: Alerttriangle,
    ELLIPSIS: Ellipsis,
    FILTER: Filter,
    ZAP: Zap,
    SORT_UP: SortUp,
    SORT_DOWN: SortDown,
    SORT: Sort,
    ADMIN: Admin,
    PLANT: Plant,
    ANALYTICS: Analytics,
    USER: User,
    PASSWORD: Password,
    PID: Pid,
    SITE_ONBOARDING: Onboard,
    MONITORING: Monitoring,
    THERMOMETER: Thermometer,
    ROTATE_CW: RotateCW,
    WIND: Wind,
    TABLET: Tablet,
    InfoIcon,
    BBPLogo,
    BBPLoginRight,
    BBPLoginLeft,
    LoginUser,
    LoginPassword,
    Triangle
};

export default ICONS;
