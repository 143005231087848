import React from "react";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeContextProvider } from "../dashboard/theme/ThemeContext";
// import PrivateRoutes from "./PrivateRoutes";
import Dashboard from "../dashboard/Dashboard";
import Authentication from "../authentication/Authentication";
import DataWarehouse from "../dwh/DataWarehouse";

const Routes = () => {
    return (
        <ThemeContextProvider>
            <CssBaseline />
            <Router>
                <Route path="/" component={Authentication} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/dwh" component={DataWarehouse} />
            </Router>
        </ThemeContextProvider>
    );
};

export default Routes;
