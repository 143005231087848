import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Grid,
    FormLabel
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import FormRenderField from "../../../dashboard/common/components/FormRenderField";

import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import { getResponseErrorDetails, scrollIntoFirstErrorElement } from "../../../utils/helpers";

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        textAlign: "center"
    }
}));

const EditSiteOverview = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, onSuccess } = props;

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [inChargeOption, setInChargeOption] = useState([]);

    const { SiteACSystemTypes, SiteConfiguration } = useSelector(state => state.dwh.config);
    const SiteOverviewSchema = useMemo(() => {
        return Yup.object().shape({
            siteId: Yup.string()
                .label("Site ID")
                .required(),
            siteName: Yup.string()
                .label("Site Name")
                .required(),
            bbpId: Yup.string()
                .label("BBP ID")
                .required(),
            siteLogo: Yup.string()
                .url()
                .label("Site Logo"),
            personInCharge: Yup.string()
                .required()
                .label("Person in Charge"),
            siteAddress: Yup.string()
                .label("Address")
                .required(),
            remarks: Yup.string().label("Address"),
            sitePostalCode: Yup.string()
                .label("Postal Code")
                .required(),
            siteCountryCode: Yup.string()
                .label("Country")
                .required(),
            industryType: Yup.string().label("Industry Type"),
            acSysType: Yup.mixed()
                .oneOf(SiteACSystemTypes)
                .label("AC System Type"),
            chInstalledQty: Yup.number()
                .required()
                .label("Number of Chillers")
                .integer()
                .min(0)
        });
    }, [SiteACSystemTypes]);

    const [values, setValues] = useState({});

    const {
        control,
        handleSubmit,
        reset,
        setError: setFormError,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(SiteOverviewSchema),
        values
    });

    const initData = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        siteName: VERBIAGE.TEXT.EMPTY_STRING,
        bbpId: VERBIAGE.TEXT.EMPTY_STRING,
        personInCharge: VERBIAGE.TEXT.EMPTY_STRING,
        siteAddress: VERBIAGE.TEXT.EMPTY_STRING,
        sitePostalCode: VERBIAGE.TEXT.EMPTY_STRING,
        siteCountryCode: VERBIAGE.TEXT.EMPTY_STRING,
        remarks: VERBIAGE.TEXT.EMPTY_STRING,
        industryType: VERBIAGE.TEXT.EMPTY_STRING,
        chInstalledQty: 0,
        siteManager: VERBIAGE.TEXT.EMPTY_STRING,
        acSysType: VERBIAGE.TEXT.EMPTY_STRING,
        configuration: VERBIAGE.TEXT.EMPTY_STRING,
        isActive: true
    };

    useImperativeHandle(ref, () => ({
        init() {
            setValues({ ...initData });
        },
        update(data) {
            setValues({ ...initData, ...data });
        }
    }));

    useEffect(() => {
        if (Object.keys(errors).length) {
            scrollIntoFirstErrorElement({
                elementIds: Object.keys(errors)
            });
        } else {
            setError(null);
        }
    }, [errors]);

    useEffect(() => {
        if (!open) {
            return;
        }

        const fetchData = async () => {
            try {
                const { data } = await api.get(URL.DATA.PLANT_SETTINGS.INCHARGE, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                setInChargeOption(data.map(incharge => incharge.publicName));
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        fetchData();
    }, [open]);

    const onClose = () => {
        reset(initData);
        setError(null);
        setOpen(false);
    };

    const handleResponseError = response => {
        const resError = getResponseErrorDetails(response);
        if (resError.type === "invalid") {
            Object.keys(resError.errors).forEach(field => {
                setFormError(field, { type: "invalid", message: resError.errors[field] });
            });
            setError(VERBIAGE.ERROR_TEXT.INVALID_VALUES);
            scrollIntoFirstErrorElement({ elementIds: Object.keys(resError.errors) });
        } else {
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
        }
    };

    const onSubmit = async data => {
        try {
            setLoading(true);

            const requestConfig = {
                data,
                headers: {
                    "x-auth-token": localStorage.token
                }
            };
            if (isEdit) {
                requestConfig.method = "PUT";
                requestConfig.url = `${URL.DATA.DWH.SITE_DETAILS}/${values.siteId}`;
            } else {
                requestConfig.method = "POST";
                requestConfig.url = URL.DATA.DWH.SITE_DETAILS;
            }
            const response = await api.request(requestConfig);
            if (response.data?.status === "error") {
                handleResponseError(response);
                return;
            }
            if (onSuccess) {
                onSuccess();
            }
            onClose();
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            } else if (err.response.status === 404) {
                setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            } else {
                handleResponseError(err.response);
            }
        } finally {
            setLoading(false);
        }
    };

    const onError = () => {
        setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
    };

    const classes = useStyles();

    const inputs = [
        {
            name: "siteName",
            label: "Site Name",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_NAME,
            disabled: isEdit,
            required: true
        },
        {
            name: "siteId",
            label: "Site Number",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID,
            disabled: isEdit,
            required: true
        },
        {
            name: "bbpId",
            label: "BBP ID",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_BBP_ID,
            disabled: isEdit,
            required: true
        },
        {
            name: "siteLogo",
            label: "Site Logo",
            type: "url",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_LOGO
        },
        {
            name: "personInCharge",
            label: "Person in Charge",
            type: "select",
            selectOptions: inChargeOption,
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_PERSON_IN_CHARGE,
            isMulti: false,
            required: true
        },
        {
            name: "siteAddress",
            label: "Site Address",
            type: "textarea",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_ADDRESS,
            required: true
        },
        {
            name: "sitePostalCode",
            label: "Postal Code",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_POSTAL_CODE,
            required: true
        },
        {
            name: "siteCountryCode",
            label: "Country",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_COUNTRY,
            required: true
        },
        {
            name: "remarks",
            label: "Remarks",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_REMARKS,
            required: false
        },
        {
            name: "industryType",
            label: "Industry Type",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_INDUSTRY_TYPE
        },
        {
            name: "chInstalledQty",
            label: "Inst. Chiller Qty",
            type: "number",
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.ENTER_CHILLER_COUNT
        },
        {
            name: "acSysType",
            label: "AC System Type",
            type: "select",
            selectOptions: SiteACSystemTypes.filter(v => v !== ""),
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_AC_SYSTEM_TYPE
        },
        {
            name: "configuration",
            label: "Configuration",
            type: "select",
            selectOptions: SiteConfiguration.filter(v => v !== ""),
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_CONFIGURATION
        },
        {
            name: "isActive",
            label: "Active",
            type: "switch",
            switchLabels: { true: "Yes", false: "No" },
            placeholder: VERBIAGE.PLACEHOLDER_TEXT.SELECT_IS_ACTIVE
        }
    ];

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-site-dialog">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} site data enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    {inputs.map(input => (
                        <Grid item container alignItems="center">
                            <Grid item xs={3}>
                                <FormLabel required={input.required} error={errors[input.name]}>
                                    {input.label}
                                </FormLabel>
                            </Grid>
                            <Grid item xs={9}>
                                <Controller
                                  name={input.name}
                                  control={control}
                                  render={({ field }) => (
                                        <FormRenderField
                                          input={input}
                                          value={field.value}
                                          onChange={field.onChange}
                                          errors={errors}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    {!!error && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography variant="subtitle1">{error}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                  color="primary"
                  onClick={handleSubmit(onSubmit, onError)}
                  disabled={loading}
                >
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button className={classes.error} onClick={onClose} disabled={loading}>
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default EditSiteOverview;
