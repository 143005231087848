/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "../styles/filtermodal.scss";
import ModalCloseButton from "./ModalCloseButton";
import { VERBIAGE } from "../../utils/enums/index";
import HomeActions from "../features/redux/HomeActions";

const FilterModal = props => {
    const { handleClose, postDataComposite } = props;

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const handleDateFilter = async () => {
        await postDataComposite({ startDate, endDate });
        handleClose();
    };

    const isDisabled = () => {
        if (startDate !== "" && endDate !== "") {
            return false;
        }
        return true;
    };
    return (
      <div className={FilterModal.style.mainContainer}>
        <div className={FilterModal.style.closeIcon}>
          <ModalCloseButton onClick={handleClose} />
        </div>
        <div className={FilterModal.style.subContainer}>
          <div className={FilterModal.style.startContainer}>
            <label>{VERBIAGE.MENU_TEXT.START_DATE}</label>
          </div>
          <div>
            <input
              type="text"
              value={startDate}
              onChange={event => setStartDate(event.target.value)}
              className={FilterModal.style.dateContainer}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.DATE}
            />
          </div>
          <div className={FilterModal.style.startContainer}>
            <label>{VERBIAGE.MENU_TEXT.END_DATE}</label>
          </div>
          <div>
            <input
              type="text"
              value={endDate}
              onChange={event => setEndDate(event.target.value)}
              className={FilterModal.style.dateContainer}
              placeholder={VERBIAGE.PLACEHOLDER_TEXT.DATE}
            />
          </div>
          <div className={FilterModal.style.chartContainer}>
            <input
              type="button"
              className={FilterModal.style.displayContainer}
              value="APPLY"
              onClick={handleDateFilter}
              disabled={isDisabled()}
            />
          </div>
        </div>
      </div>
    );
};
const mapStateToProps = state => ({
    home: state.home,
    authentication: state.authentication
});

const mapDispatchToProps = dispatch => ({
    postDataComposite: credentials => {
        dispatch(HomeActions.postDataComposite(credentials));
    }
});
FilterModal.style = {
    mainContainer: "webclient__filtermodal-container-main",
    subContainer: "webclient__filtermodal-container-sub",
    startContainer: "webclient__filtermodal-container-start",
    dateContainer: "webclient__filtermodal-container-date",
    displayContainer: "webclient__filtermodal-container-display",
    chartContainer: "webclient__filtermodal-container-chart",
    closeIcon: "webclient__filtermodal-container-close"
};
FilterModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    postDataComposite: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilterModal));
