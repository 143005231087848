import React, { useMemo } from "react";
import { Link, Grid, ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { split, trim, trimStart } from "lodash";
import classnames from "classnames";
import { VERBIAGE } from "../../../../utils/enums/index";
import Version from "../../../../Version";

const Copyright = ({ open = true, onOpen, onClose }) => {
    const version = useMemo(() => {
        const serverVersion = trim(localStorage.getItem("server_version"), '"');
        const clientVersion = Version.VERSION;
        const _serverVersion = split(trimStart(serverVersion, "v"), ".");
        const _clientVersion = split(trimStart(clientVersion, "v"), ".");
        let diffVersion = "";
        let versionDifferent = false;
        for (let i = 0; _clientVersion.length - 1 >= i; i++) {
            if (versionDifferent) {
                diffVersion = `${diffVersion}.`;
            }

            if (_serverVersion[i] !== _clientVersion[i]) {
                if (versionDifferent === false) {
                    diffVersion = "-";
                }
                versionDifferent = true;
            }

            if (versionDifferent) {
                diffVersion = `${diffVersion}${_clientVersion[i]}`;
            }
        }
        const versionRef = localStorage.getItem("siteVersion");
        const versionData = versionRef
            ? versionRef.split("-")
            : `${VERBIAGE.VERSION}-${VERBIAGE.VERSION_DATA_PIPELINE}-1`.split("-");
        versionData[0] = serverVersion;
        const siteVersion = versionData.join("-");
        return siteVersion; // `${serverVersion}${diffVersion}`;
    }, []);

    const useStyles = makeStyles(theme => ({
        copyright: {
            paddingLeft: theme.spacing(1)
        },
        panelIcon: {
            borderRadius: 0
        },
        fullWidth: {
            width: "100%"
        }
    }));
    const classes = useStyles();

    if (!open) {
        return (
            <ButtonBase className={classes.fullWidth} onClick={onOpen}>
                <i
                  className={classnames(
                        classes.panelIcon,
                        classes.fullWidth,
                        "fa fa-angle-left fa-2x arrow-icon",
                    )}
                  aria-hidden="true"
                />
            </ButtonBase>
        );
    }

    return (
        <Grid container direction="row" justifyContent="space-between">
            <Grid item xs container direction="column" className={classes.copyright}>
                <Typography>
                    &#169;
                    {VERBIAGE.MENU_TEXT.YEAR}
                </Typography>
                <Typography>{VERBIAGE.MENU_TEXT.COPY_RIGHT}</Typography>
                <Link href="/dashboard/version">{version}</Link>
            </Grid>
            <Grid item xs container justifyContent="flex-end" alignItems="flex-end">
                <ButtonBase onClick={onClose}>
                    <i
                      className={classnames(
                            classes.panelIcon,
                            classes.fullWidth,
                            "fa fa-angle-left fa-2x arrow-icon",
                        )}
                      aria-hidden="true"
                    />
                </ButtonBase>
            </Grid>
        </Grid>
    );
};

export default Copyright;
