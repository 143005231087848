/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
    Accordion,
    AccordionSummary,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import MomentAdapter from "@date-io/moment";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import CustomFooter from "./CustomFooter";
import EditSessionTable from "./EditSessionTable";

const moment = new MomentAdapter();

const SessionTable = () => {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const isNarrow = useMediaQuery("(max-width: 600px)");
    const editRef = useRef();

    const isMounted = useRef(false);

    // open close modal
    const handleClick = () => {
        setOpen(!open);
    };

    // fetch current logged in users
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.ADMIN_SETTINGS.SESSIONS, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                const parsedDates = response.data.map(row => {
                    return {
                        ...row,
                        parsedCreatedAt: moment.parse(row.createdAt).format("DD MMM YY HH:mm:ss"),
                        parsedUpdatedAt: moment.parse(row.updatedAt).format("DD MMM YY HH:mm:ss")
                    };
                });
                if (isMounted.current) {
                    setData(parsedDates);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();
        return () => (isMounted.current = false);
    }, [open]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: "16px",
            overflowWrap: "anywhere"
        }
    }))(TableCell);

    const cols = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <IconButton
                          onClick={() => {
                                editRef.current.update({
                                    ...editRef.current.getConfig(),
                                    ...data[dataIndex]
                                });
                                handleClick();
                            }}
                        >
                            <Delete />
                        </IconButton>
                    );
                }
            }
        },
        { label: "Name", name: "username" },
        { label: "Email", name: "email" },
        { label: "Latest Update", name: "parsedUpdatedAt" },
        { label: "Login Time", name: "parsedCreatedAt" }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        // data should contain current logged in users
        const rowData = data[dataIndex];
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell />
                                    <StyledTableCell>
                                        <IconButton
                                          onClick={event => {
                                                handleClick();
                                            }}
                                          onFocus={event => event.stopPropagation()}
                                        />
                                        <Delete />
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell> Email </StyledTableCell>
                                    <StyledTableCell>{rowData.email}</StyledTableCell>
                                </TableRow>
                                {isRowExpanded[dataIndex] ? (
                                    <React.Fragment>
                                        <TableRow>
                                            <StyledTableCell> Latest Update</StyledTableCell>
                                            <StyledTableCell>
                                                {rowData.parsedCreatedAt}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>Login Time</StyledTableCell>
                                            <StyledTableCell>
                                                {rowData.parsedUpdatedAt}
                                            </StyledTableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ) : null}
                            </TableBody>
                        </Table>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "400px",
        filter: false,
        print: false,
        viewColumns: false,
        download: false,
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    return (
        <div>
            <EditSessionTable
              open={open}
              handleClick={handleClick}
              setOpen={setOpen}
              ref={editRef}
            />
            <div className={classes.table}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      title="Session Data"
                      data={data}
                      columns={cols}
                      options={options}
                    />
                </MuiThemeProvider>
            </div>
        </div>
    );
};

export default SessionTable;
