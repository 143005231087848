import React from "react";

import useQueryParams from "../../../hooks/useQueryParams";

import SiteSelector from "../SiteSelector";
import WaterPumpSelector from "./WaterPumpSelector";
import EditWaterPumpSpecOverview from "./EditWaterPumpSpecOverview";

const WaterPumpSpecOverview = () => {
    const { getQuery } = useQueryParams();
    const wpId = getQuery("wpId");

    return (
        <>
            <SiteSelector />
            <WaterPumpSelector />
            {wpId && <EditWaterPumpSpecOverview wpId={wpId} />}
        </>
    );
};

export default WaterPumpSpecOverview;
