/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Accordion,
    AccordionSummary,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Add, EditOutlined, ExpandMore, Publish } from "@material-ui/icons";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import MomentAdapter from "@date-io/moment";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from "file-saver";

import api from "../../../utils/API";
import URL from "../../../utils/URL";
import { getCookie, getMonthNameByMonth } from "../../../utils/helpers";
import EditCurrencyConverterTable from "./EditCurrencyConverterTable";
import { VERBIAGE } from "../../../utils/enums";
import CustomFooter from "./CustomFooter";
import CSVUpload from "./CSVUpload";
import AuditLogActions from "../../redux/AuditLogActions";

const moment = new MomentAdapter();

const CurrencyConverterTable = () => {
    const [data, setData] = useState([]);
    const [latest, setLatest] = useState(true);

    const [refetchData, setRefetchData] = useState(false);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    const isNarrow = useMediaQuery("(max-width: 600px)");

    const isMounted = useRef(false);

    const emptyConfig = {
        currency: VERBIAGE.TEXT.EMPTY_STRING,
        rate: VERBIAGE.TEXT.EMPTY_STRING,
        validFrom: VERBIAGE.TEXT.EMPTY_STRING
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const fetchLatestData = useCallback(async () => {
        try {
            const response = await api.get(
                `${URL.DATA.MAINTENANCE_SETTINGS.CURRENCY_CONVERTER}/latest`,
                {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                },
            );
            const parsedDates = response.data.map(row => {
                return {
                    ...row,
                    validFrom: moment.parse(row.validFrom).format("LL")
                    // monthName: getMonthNameByMonth(row.month)
                };
            });

            if (isMounted.current) {
                setData(parsedDates);
            }
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }, []);

    const fetchData = useCallback(async () => {
        try {
            const response = await api.get(`${URL.DATA.MAINTENANCE_SETTINGS.CURRENCY_CONVERTER}`, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            const parsedDates = response.data.map(row => {
                return {
                    ...row,
                    validFrom: moment.parse(row.validFrom).format("LL")
                    // monthName: getMonthNameByMonth(row.month)
                };
            });

            if (isMounted.current) {
                setData(parsedDates);
            }
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }, []);

    useEffect(() => {
        isMounted.current = true;
        fetchLatestData();

        return () => (isMounted.current = false);
    }, [open, fetchLatestData]);

    useEffect(() => {
        if (!refetchData) return;

        fetchLatestData();
    }, [refetchData, fetchData, fetchLatestData]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: "16px"
        }
    }))(TableCell);

    const cols = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <IconButton
                          onClick={() => {
                                const offsetData = data[dataIndex];
                                editRef.current.update({
                                    ...editRef.current.getConfig(),
                                    ...offsetData
                                });
                                setIsEdit(true);
                                handleClick();
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    );
                }
            }
        },
        { label: "ID", name: "id" },
        {
            label: "Currency",
            name: "currency",
            options: {
                filter: true
            }
        },
        {
            label: "Rate (vs SGD)",
            name: "rate",
            options: {
                setCellProps: () => ({ style: { justifyContent: "end" } })
            }
        },
        { label: "Valid From", name: "validFrom" }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        const rowData = data[dataIndex];
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell>
                                            <IconButton
                                              onClick={event => {
                                                    event.stopPropagation();
                                                    editRef.current.update({
                                                        ...editRef.current.getConfig(),
                                                        ...rowData
                                                    });
                                                    setIsEdit(true);
                                                    handleClick();
                                                }}
                                              onFocus={event => event.stopPropagation()}
                                            >
                                                <EditOutlined />
                                            </IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Site ID</StyledTableCell>
                                        <StyledTableCell>{rowData.siteId}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Year</StyledTableCell>
                                        <StyledTableCell>{rowData.year}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Month</StyledTableCell>
                                        <StyledTableCell>{rowData.month}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>KWH Offset</StyledTableCell>
                                        <StyledTableCell>{rowData.kwhOffset}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>RTH Offset</StyledTableCell>
                                        <StyledTableCell>{rowData.rthOffset}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Cost Offset</StyledTableCell>
                                        <StyledTableCell>{rowData.costOffset}</StyledTableCell>
                                    </TableRow>
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell>Last Update</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.parsedDate}
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                    <TableRow>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>{rowData.status}</StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    };

    // CSV Upload

    const [openCsvUpload, setOpenCsvUpload] = useState(false);

    const handleClickCsvUpload = () => setOpenCsvUpload(!openCsvUpload);

    const handleDownloadCsv = () => {
        setRefetchData(false);
        const csvData = [];
        for (let i = 0; i < data.length; i++) {
            const row = data[i];
            csvData.push({
                currency: row.currency,
                rate: row.rate,
                valid_from: row.validFrom
            });
        }
        const blob = new Blob([jsonToCSV(csvData)], { type: "text/csv;charset=utf-8;" });
        const now = moment.parse(new Date()).format("YYYYMMDD");
        const siteId = getCookie("siteId") ?? csvData[0].siteId;
        saveAs(blob, `${siteId}_currency_converter_${now}.csv`);
    };

    const handleSuccessCsvUpload = () => {
        setOpenCsvUpload(false);
        setRefetchData(true);
    };

    // theme

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "600px",
        filter: true,
        print: false,
        viewColumns: false,
        rowStyle: { height: 30 },
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title="Upload CSV">
                        <IconButton onClick={() => setOpenCsvUpload(true)}>
                            <Publish />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add Currency Conversion">
                        <IconButton
                          onClick={() => {
                                editRef.current.update({
                                    ...emptyConfig,
                                    date: Date.now()
                                });
                                setIsEdit(false);
                                handleClick();
                            }}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>

                    {latest ? (
                        <Tooltip title="Get Complete Data">
                            <IconButton
                              onClick={() => {
                                    // const offsetData = data[dataIndex];
                                    // editRef.current.update({
                                    //     ...editRef.current.getConfig(),
                                    //     ...offsetData
                                    // });
                                    // setIsEdit(true);
                                    // handleClick();
                                    setLatest(false);
                                    fetchData();
                                }}
                            >
                                <DatasetOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Get Latest Data">
                            <IconButton
                              onClick={() => {
                                    // const offsetData = data[dataIndex];
                                    // editRef.current.update({
                                    //     ...editRef.current.getConfig(),
                                    //     ...offsetData
                                    // });
                                    // setIsEdit(true);
                                    // handleClick();
                                    setLatest(true);
                                    fetchLatestData();
                                }}
                            >
                                <UpdateOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </React.Fragment>
            );
        },
        // onFilterChange: (column, filterList, type, changedColumnIndex) => {
        //     if (type === "reset") {
        //         dispatch(
        //             AuditLogActions.getAuditLogData({
        //                 MISSING_PARAMS: null,
        //                 MISSING_TYPE: null,
        //                 STATUS: null,
        //                 SYS_TS_DATETIME_START: null,
        //                 SYS_TS_DATETIME_END: null
        //             }),
        //         );
        //         return;
        //     }
        //     const value = filterList[changedColumnIndex]?.map(v => {
        //         if (v instanceof Date) {
        //             return v.valueOf();
        //         }
        //         return v;
        //     });
        //     dispatch(
        //         AuditLogActions.getAuditLogData({
        //             [column]: value
        //         }),
        //     );
        // },
        onDownload: () => {
            handleDownloadCsv();
            return false;
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "5px"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    return (
        <div>
            <EditCurrencyConverterTable
              isEdit={isEdit}
              open={open}
              handleClick={handleClick}
              setOpen={setOpen}
              ref={editRef}
            />
            <CSVUpload
              open={openCsvUpload}
              setOpen={setOpenCsvUpload}
              handleClick={handleClickCsvUpload}
              onSuccess={handleSuccessCsvUpload}
              multiple={false}
              url={URL.DATA.MAINTENANCE_SETTINGS.CURRENCY_CONVERTER_BULK}
            />
            <div className={classes.table}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      title={
                            latest
                                ? "Currency Conversion (Latest Data)"
                                : "Currency Conversion (Complete Data)"
                        }
                      data={data}
                      columns={cols}
                      options={options}
                    />
                </MuiThemeProvider>
            </div>
        </div>
    );
};

export default CurrencyConverterTable;
