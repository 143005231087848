import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
// import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
// import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
// import MomentAdapter from "@date-io/moment";
// import { createMuiTheme, useTheme } from "@material-ui/core/styles";
import "../styles/mapTable.scss";
import TextInput from "./TextInput";
// import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

const EditSiteConfigTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    // const [data, setData] = useState([]); // eslint-disable-line no-unused-vars
    const [error, setError] = useState("");

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        section1: VERBIAGE.TEXT.EMPTY_STRING,
        section2: VERBIAGE.TEXT.EMPTY_STRING,
        config_key: VERBIAGE.TEXT.EMPTY_STRING,
        config_val: VERBIAGE.TEXT.EMPTY_STRING,
        description: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleType = ({ target: { value } }) => {
        updateConfig({ ...config, section1: value });
    };

    const handleScope = ({ target: { value } }) => {
        updateConfig({ ...config, section2: value });
    };

    const handleKey = ({ target: { value } }) => {
        updateConfig({ ...config, config_key: value });
    };

    const handleValue = ({ target: { value } }) => {
        updateConfig({ ...config, config_val: value });
    };

    const handleDescription = ({ target: { value } }) => {
        updateConfig({ ...config, description: value });
    };

    const handleSubmit = async () => {
        if (!config.section1 || !config.section2 || !config.config_key || !config.config_val) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        if (isEdit) {
            const _data = {
                siteId: config.siteId,
                section1: config.section1,
                section2: config.section2,
                config_key: config.config_key,
                config_val: config.config_val,
                description: config.description
            };
            const url = `${URL.DATA.MAINTENANCE_SETTINGS.SITE_CONFIG}/${config.siteId}`;
            try {
                const response = await api.put(url, _data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        } else {
            const _data = {
                ...config
            };
            const url = URL.DATA.MAINTENANCE_SETTINGS.SITE_CONFIG;
            try {
                const response = await api.post(url, _data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${
                    isEdit ? "edit" : "add to"
                } the Site Config, please enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    <TextInput
                      onChange={handleType}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CONFIG_TYPE}
                      value={config.section1}
                      type="text"
                      styles={{ readOnly: !!isEdit }}
                    />
                </div>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    <TextInput
                      onChange={handleScope}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CONFIG_SCOPE}
                      value={config.section2}
                      type="text"
                      styles={{ readOnly: !!isEdit }}
                    />
                </div>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    <TextInput
                      onChange={handleKey}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CONFIG_KEY}
                      value={config.config_key}
                      type="text"
                      styles={{ readOnly: !!isEdit }}
                    />
                </div>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    <TextInput
                      onChange={handleValue}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CONFIG_VALUE}
                      value={config.config_val}
                      type="text"
                    />
                </div>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    <TextInput
                      onChange={handleDescription}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_CONFIG_DESCRIPTION}
                      value={config.description}
                      type="text"
                    />
                </div>
                {/* <div className={EditSiteConfigTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_YEAR}
                      onChange={handleYear}
                      value={config.year}
                      type="text"
                      styles={{ readOnly: !!isEdit }}
                    />
                </div>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    {isEdit ? (
                        <TextInput
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_MONTH}
                          value={config.monthName}
                          type="text"
                          styles={{ readOnly: true }}
                        />
                    ) : (
                        <DropdownSelect
                          name={config.monthName}
                          options={VERBIAGE.TEXT.MONTH_OPTIONS}
                          onChange={handleMonth}
                          placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_MONTH}
                        />
                    )}
                </div>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_KWH}
                      onChange={handleKWHOffset}
                      value={config.kwhOffset}
                      type="text"
                    />
                </div>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_RTH}
                      onChange={handleRTHOffset}
                      value={config.rthOffset}
                      type="text"
                    />
                </div>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_COST}
                      onChange={handleCostOffset}
                      value={config.costOffset}
                      type="text"
                    />
                </div>
                <div className={EditSiteConfigTable.styles.inputContainer}>
                    <DropdownSelect
                      name={config.status}
                      options={VERBIAGE.TEXT.STATUS_OPTIONS}
                      onChange={handleStatus}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_OFFSET_STATUS}
                    />
                </div> */}
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setOpen(false);
                        setError("");
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditSiteConfigTable.styles = {
    inputContainer: "webclient__mapTable-input"
};

export default EditSiteConfigTable;
