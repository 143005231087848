import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tab, Tabs, Grid, Paper, makeStyles, ButtonBase, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import classNames from "classnames";

import { VERBIAGE } from "../../../utils/enums";

import "../styles/portfolioView.scss";
import SystemHeader from "../../common/components/SystemHeader";
import ReportsTabs from "../../common/components/ReportsTabs";

import ReportActions from "../../redux/ReportActions";

const MobileDetect = require("mobile-detect");

const ReportsView = () => {
    const {
        authentication,
        home,
        report: {
            dynamicReport: { tabs: dynamicReportTabs },
            dynamicReportTab
        }
    } = useSelector(state => state);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ReportActions.loadDynamicReportTabs());
        return () => dispatch(ReportActions.selectDynamicReportTab(null));
    }, [dispatch]);

    const tabs = useMemo(() => {
        return [
            {
                label: VERBIAGE.REPORT_TAB_TEXT.SITE_REPORT,
                value: VERBIAGE.REPORT_TAB_VALUE.SITE_REPORT
            },
            {
                label: VERBIAGE.REPORT_TAB_TEXT.DYNAMIC_REPORT,
                value: VERBIAGE.REPORT_TAB_VALUE.DYNAMIC_REPORT
            },
            ...dynamicReportTabs.map(t => ({ ...t, isDynamic: true }))
        ];
    }, [dynamicReportTabs]);

    const [tab, setTab] = useState(tabs[0].value);

    const handleChangeTab = (_, selectedTab) => {
        setTab(selectedTab);
    };

    const handleRemoveTab = (selectedTab, index) => {
        return e => {
            e.stopPropagation();
            dispatch(ReportActions.removeDynamicReportTab(selectedTab));
            if (tab === selectedTab.value) {
                setTab(tabs[index - 1].value);
            }
        };
    };

    useEffect(() => {
        if (dynamicReportTab) {
            setTab(dynamicReportTab.value);
        }
    }, [dynamicReportTab]);

    /** styles */
    const md = new MobileDetect(window.navigator.userAgent);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1),
            paddingTop:
                home.desktopMobileSwitch === "mobile" &&
                (home.orientation === "portrait" || !md.mobile())
                    ? "74px"
                    : ""
        },
        paper: {
            padding: theme.spacing(0.5),
            textAlign: "center",
            height: "95%",
            [theme.breakpoints.up("xs")]: {
                height: "905px"
            },
            [theme.breakpoints.up("sm")]: {
                height: "905px"
            },
            [theme.breakpoints.up("md")]: {
                height: "95%"
            },
            [theme.breakpoints.up("lg")]: {
                height: "95%"
            },
            [theme.breakpoints.up("xl")]: {
                height: "95%"
            },
            color: theme.palette.type === "dark" ? "white" : "black",
            backgroundColor: theme.palette.type === "dark" ? "#201F25" : "ffffff"
        },
        title: {
            color: theme.palette.type === "dark" ? "#DFDFDF" : "#000000"
        },
        main: {
            transition: "margin-left 0.5s",
            height: home.desktopMobileSwitch === "desktop" && !!md.mobile() ? "auto" : "100vh",
            overflow: "auto",
            flex: "1",
            paddingTop: "70px",
            boxSizing: "border-box"
        }
    }));

    const classes = useStyles();

    return (
        <div className="main-wrapper">
            <div>
                <SystemHeader authentication={authentication} />
            </div>
            <div className={classes.main}>
                <Grid container item xs={12} className={classes.root}>
                    {/* header start */}
                    <Grid item xs={12}>
                        <div
                          className={classNames(
                                ReportsView.styles.headerContainer,
                                classes.title,
                            )}
                        >
                            <span>{VERBIAGE.MENU_TEXT.REPORTS}</span>
                        </div>
                    </Grid>
                    {/* header end */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Tabs
                              value={tab}
                              onChange={handleChangeTab}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                              scrollButtons={window.innerWidth < 850 ? "on" : "auto"}
                            >
                                {tabs.map((t, i) => (
                                    <Tab
                                      key={t.value}
                                      value={t.value}
                                      label={(
                                            <ReportTabsLabel
                                              tab={t}
                                              onRemove={handleRemoveTab(t, i)}
                                            />
                                          )}
                                    />
                                ))}
                            </Tabs>
                            <Paper className={classes.paper} elevation={0}>
                                <ReportsTabs tab={tab} changeTab={setTab} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
ReportsView.styles = {
    headerContainer: "webclient__portfolioView-container-header"
};

const ReportTabsLabel = ({ tab, onRemove }) => {
    if (!tab.isDynamic) {
        return <Typography>{tab.label}</Typography>;
    }
    return (
        <Grid container>
            <Grid item xs={8}>
                <Typography>{tab.label}</Typography>
            </Grid>
            <Grid item xs={4}>
                <ButtonBase onClick={onRemove}>
                    <ClearIcon />
                </ButtonBase>
            </Grid>
        </Grid>
    );
};

export default ReportsView;
