import React from "react";
import ReportingTable from "./ReportingTable";

const ReportingTabs = props => {
    const { value } = props;

    return <React.Fragment>{value === "missingData" && <ReportingTable />}</React.Fragment>;
};

export default ReportingTabs;
