/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useRef } from "react";
import {
    Accordion,
    AccordionSummary,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from "file-saver";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { EditOutlined, Publish, ExpandMore } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import _ from "lodash";
import CustomFooter from "../CustomFooter";
import EditSchematicsTable from "../EditSchematicsTable";
import CSVUpload from "../CSVUpload";
import { VERBIAGE } from "../../../../utils/enums";
import URL from "../../../../utils/URL";
import api from "../../../../utils/API";

const moment = require("moment");

const OnboardingMappingTable = () => {
    const [data, setData] = useState([]);
    let dataVersion = localStorage.getItem("dataVersion");

    const handleCsv = () => {
        let ctr = 1;
        const fileteredData = _.filter(data, o => {
            return o.version === dataVersion;
        });
        const csvData = fileteredData.map(row => {
            const csvRow = {};
            csvRow.itemNo = ctr++;
            csvRow.siteId = row.siteId;
            csvRow.version = row.version;
            csvRow.eqpt = row.eqpt;
            csvRow.name = row.name;
            csvRow.type = row.type;
            csvRow.unit = row.unit;
            csvRow.description = row.description;
            csvRow.tag = row.tag;
            csvRow.relationship = row.relationship;
            csvRow.sequence = row.sequence;
            return csvRow;
        });
        const blob = new Blob([jsonToCSV(csvData)], { type: "text/csv;charset=utf-8;" });
        const now = moment().format("YYYYMMDD");
        saveAs(blob, `${csvData[0].siteId}_onboarding_map_${now}.csv`);
    };

    const [open, setOpen] = useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    const isNarrow = useMediaQuery("(max-width: 600px)");

    const isMounted = useRef(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleUploadClick = () => {
        setUploadOpen(!uploadOpen);
    };

    let onboardingSite = localStorage.getItem("onboardingSite");
    const optionsData = JSON.parse(localStorage.getItem("optionsData"));
    useEffect(() => {
        const fetchData = async () => {
            try {
                onboardingSite = localStorage.getItem("onboardingSite");

                const response = await api.get(
                    `${URL.DATA.SITE_ONBOARDING.MAPPING_TABLE}/${onboardingSite}`,
                    {
                        headers: {
                            "x-auth-token": localStorage.token
                        }
                    },
                );

                if (isMounted.current) {
                    if (response.data.status === "error") {
                        setData([]);
                    } else {
                        setData(response.data);
                    }
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open, uploadOpen]);
    const useStyles = makeStyles(theme => ({
        header: {
            color:
                theme.palette.type === "dark"
                    ? theme.palette.text.dark
                    : theme.palette.primary.main,
            margin: "4px",
            fontSize: "14px",
            paddingRight: "10px"
        },
        table: {
            width: "100%"
        },
        row: {
            height: "200px"
        },
        paperCSV: {
            height: "50px",
            borderRadius: "4px 4px 0px 0px",
            width: "100%",
            border: theme.palette.type === "dark" ? "10px solid #201F25" : "10px solid #ffffff",
            background: theme.palette.type === "dark" ? "#201F25" : "#ffffff"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: "16px"
        }
    }))(TableCell);

    const cols = [
        // {
        //     name: "",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         empty: true,
        //         customBodyRenderLite: (dataIndex, rowIndex) => {
        //             return (
        //               <IconButton
        //                 onClick={() => {
        //                         editRef.current.update({
        //                             ...editRef.current.getConfig(),
        //                             ...data[dataIndex]
        //                         });
        //                         setIsEdit(true);
        //                         handleClick();
        //                     }}
        //               >
        //                 <EditOutlined />
        //               </IconButton>
        //             );
        //         }
        //     }
        // },
        { label: "Site ID", name: "siteId" },
        {
            label: "Version",
            name: "version",
            options: {
                filter: true,
                filterList: [`${dataVersion}`],
                customFilterListRender: v => `Version: ${v}`
            }
        },
        { label: "Eqpt", name: "eqpt" },
        {
            label: "Name",
            name: "name"
            // options: {
            //     filter: true,
            //     filterList: ["SYS_TS_DATETIME"],
            //     customFilterListRender: (v) => `Name: ${v}`
            // }
        },
        { label: "Type", name: "type" },
        { label: "Unit", name: "unit" },
        { label: "Description", name: "description" },
        { label: "Tag", name: "tag" },
        { label: "Relationship", name: "relationship" },
        { label: "Seq", name: "sequence" }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        const handleAccordion = (event, expanded) => {
            setIsRowExpanded(prev => {
                return {
                    ...prev,
                    [dataIndex]: expanded
                };
            });
        };
        const rowData = data[dataIndex];
        return (
            <TableRow key={dataIndex}>
                <TableCell>
                    <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell>
                                            <IconButton
                                              onClick={event => {
                                                    event.stopPropagation();
                                                    editRef.current.update({
                                                        ...editRef.current.getConfig(),
                                                        ...rowData
                                                    });
                                                    setIsEdit(true);
                                                    handleClick();
                                                }}
                                              onFocus={event => event.stopPropagation()}
                                            >
                                                <EditOutlined />
                                            </IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Site ID</StyledTableCell>
                                        <StyledTableCell>{rowData.siteId}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Version</StyledTableCell>
                                        <StyledTableCell>{rowData.version}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>{rowData.name}</StyledTableCell>
                                    </TableRow>
                                    {isRowExpanded[dataIndex] ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <StyledTableCell>Unit</StyledTableCell>
                                                <StyledTableCell>{rowData.unit}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Description</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.description}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Tag</StyledTableCell>
                                                <StyledTableCell>{rowData.tag}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Relationship</StyledTableCell>
                                                <StyledTableCell>
                                                    {rowData.relationship}
                                                </StyledTableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                    </Accordion>
                </TableCell>
            </TableRow>
        );
    };

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "600px",
        filter: true,
        print: false,
        viewColumns: false,
        // download: false,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title="Upload CSV">
                        <IconButton onClick={() => setUploadOpen(true)}>
                            <Publish />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
        onDownload: () => {
            handleCsv();
            return false;
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem",
                              height: "12px"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick2 = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleClickListItem = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        localStorage.setItem("onboardingSite", optionsData[index].siteId);
        dataVersion = optionsData[index].dataVersion;
        localStorage.setItem("dataVersion", optionsData[index].dataVersion);

        setAnchorEl(null);
        setOpen(!open);
    };

    return (
        <div>
            {/* <EditSchematicsTable
          isEdit={isEdit}
          open={open}
          handleClick={handleClick}
          setOpen={setOpen}
          ref={editRef}
        /> */}
            <CSVUpload
              open={uploadOpen}
              setOpen={setUploadOpen}
              handleClick={handleUploadClick}
              overwrite
              url={`${URL.DATA.SITE_ONBOARDING.MAPPING_BULK}/${onboardingSite}`}
            />
            <div className={classes.table}>
                <MuiThemeProvider theme={theme}>
                    <Paper elevation={0} className={classes.paperCSV}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={handleClick2}
                                  variant="contained"
                                  color="primary"
                                >
                                    Select Site
                                </Button>
                            </Grid>
                            <Grid item>
                                <h2 className={classes.header}>
                                    {`Selected Site : ${onboardingSite}`}
                                </h2>
                            </Grid>
                        </Grid>

                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                            {optionsData &&
                                optionsData.map((option, index) => (
                                    <MenuItem
                                      key={option.siteId}
                                      selected={index === selectedIndex}
                                      onClick={event => handleMenuItemClick(event, index)}
                                    >
                                        {option.siteId}
                                    </MenuItem>
                                ))}
                        </Menu>
                    </Paper>

                    <MUIDataTable
                      title="Mapping Data"
                      data={data}
                      columns={cols}
                      options={options}
                      aria-label="a dense table"
                        //   filterModel={{
                        //     items: [
                        //       { columnField: 'name', operatorValue: 'contains', value: 'SYS_TS_DATETIME' }
                        //     ]
                        //   }}
                    />
                </MuiThemeProvider>
            </div>
        </div>
    );
};

export default OnboardingMappingTable;
