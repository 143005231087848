/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OpenLayerWrapper from "./OpenLayerWrapper";
import { REFRESH_TIMER, SVG } from "../../../utils/enums/index";
import PlantActions from "../../redux_PlantSchematic/PlantSActions";
import VERBIAGE from "../../../utils/enums/Verbiage";

const PlantView = props => {
    const { plantSchematic, getEquipment, getImage } = props;

    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        getImage();
        getEquipment();
        const timer = setInterval(() => {
            getImage();
            getEquipment();
        }, timerValue);
        return () => clearInterval(timer);
    }, [getEquipment, getImage]);

    const makeOptions = data => {
        const equipments = [];

        // CHILLER
        data.CHILLER.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                status: equipment.status,
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    data1: equipment.data.data1,
                    data2: equipment.data.data2,
                    dataMargin: equipment.data.dataMargin || 0,
                    labelMargin: equipment.data.labelMargin || 0,
                    dataZoom: equipment.data.dataZoom || 1,
                    labelZoom: equipment.data.labelZoom || 1,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                    // sdraStatus: equipment.data.sdraStatus
                }
            });
        });

        // COOLING_TOWER
        data.COOLING_TOWER.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                status: equipment.status,
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    data1: equipment.data.data1,
                    data2: equipment.data.data2,
                    labelMargin: equipment.data.labelMargin || -120,
                    dataMargin: equipment.data.dataMargin || -120,
                    dataZoom: equipment.data.dataZoom || 1,
                    labelZoom: equipment.data.labelZoom || 1,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // CHILLER_PUMP
        data.CHILLER_PUMP.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                status: equipment.status,
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    data1: equipment.data.data1,
                    data2: equipment.data.data2,
                    dataMargin: equipment.data.dataMargin || 0,
                    labelMargin: equipment.data.labelMargin || 0,
                    dataZoom: equipment.data.dataZoom || 1,
                    labelZoom: equipment.data.labelZoom || 1,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // CONDENSER_PUMP
        data.CONDENSER_PUMP.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                status: equipment.status,
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    data1: equipment.data.data1,
                    data2: equipment.data.data2,
                    dataMargin: equipment.data.dataMargin || 0,
                    labelMargin: equipment.data.labelMargin || 0,
                    dataZoom: equipment.data.dataZoom || 1,
                    labelZoom: equipment.data.labelZoom || 1,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // VALVE
        data.VALVE.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                status: equipment.status,
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    dataMargin: equipment.data.dataMargin || 0,
                    labelMargin: equipment.data.labelMargin || 0,
                    dataZoom: equipment.data.dataZoom || 1,
                    labelZoom: equipment.data.labelZoom || 1,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // TEMP_SENSOR
        data.TEMP_SENSOR.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    data1: equipment.data.data1,
                    dataZoom: equipment.data.dataZoom || 1,
                    labelZoom: equipment.data.labelZoom || 1,
                    dataMargin: equipment.data.dataMargin || 0,
                    labelMargin: equipment.data.labelMargin || 0,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // PRESSURE_SENSOR
        data.PRESSURE_SENSOR.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    data1: equipment.data.data1,
                    dataZoom: equipment.data.dataZoom || 1,
                    labelZoom: equipment.data.labelZoom || 1,
                    dataMargin: equipment.data.dataMargin || 0,
                    labelMargin: equipment.data.labelMargin || 0,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // FLOW_SENSOR
        data.FLOW_SENSOR.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    data1: equipment.data.data1,
                    dataZoom: equipment.data.dataZoom || 1,
                    labelZoom: equipment.data.labelZoom || 1,
                    dataMargin: equipment.data.dataMargin || 0,
                    labelMargin: equipment.data.labelMargin || 0,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // GENERIC_KW_SENSOR
        data.GENERIC_KW_SENSOR.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    data1: equipment.data.data1,
                    dataZoom: equipment.data.dataZoom || 1,
                    labelZoom: equipment.data.labelZoom || 1,
                    dataMargin: equipment.data.dataMargin || 0,
                    labelMargin: equipment.data.labelMargin || 0,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // ARROWS
        data.ARROWS.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    colour: equipment.data.color,
                    labelZoom: equipment.data.labelZoom || 1,
                    labelMargin: equipment.data.labelMargin || 0,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 10, // only for rightarrow
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // EXTRA_WATER_PUMP
        data.EXTRA_WATER_PUMP.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                status: equipment.status,
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    // data1: equipment.data.data1,
                    // data2: equipment.data.data2,
                    labelMargin: equipment.data.labelMargin || 0,
                    dataMargin: equipment.data.dataMargin || 0,
                    // dataZoom: equipment.data.dataZoom,
                    labelZoom: equipment.data.labelZoom || 1,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });

        // WATER_TANK
        data.WATER_TANK.map(equipment => {
            equipments.push({
                id: equipment.id,
                type: SVG.IMAGE_TYPES[equipment.type],
                status: equipment.status,
                location: [equipment.location[0], equipment.location[1]],
                zoomLevel: equipment.zoom,
                data: {
                    label: equipment.data.label,
                    // data1: equipment.data.data1,
                    // data2: equipment.data.data2,
                    dataMargin: equipment.data.dataMargin || 0,
                    labelMargin: equipment.data.labelMargin || 0,
                    // dataZoom: equipment.data.dataZoom,
                    labelZoom: equipment.data.labelZoom || 1,
                    labelAlign: equipment.data.labelAlign || "horizontal",
                    dataAlign: equipment.data.dataAlign || "horizontal",
                    label_x_axis: equipment.data.label_x_axis || 0,
                    data_x_axis: equipment.data.data_x_axis || 0
                }
            });
        });
        // PERFORMANCE
        if (data.PERFORMANCE) {
            data.PERFORMANCE.map(equipment => {
                equipments.push({
                    id: equipment.id,
                    type: SVG.IMAGE_TYPES[equipment.type],
                    location: [equipment.location[0], equipment.location[1]],
                    zoomLevel: equipment.zoom,
                    data: {
                        label: equipment.data.label,
                        data1: equipment.data.data1,
                        dataMargin: equipment.data.dataMargin || 0,
                        labelMargin: equipment.data.labelMargin || 0,
                        dataZoom: equipment.data.dataZoom,
                        labelZoom: equipment.data.labelZoom || 1,
                        labelAlign: equipment.data.labelAlign || "horizontal",
                        dataAlign: equipment.data.dataAlign || "horizontal",
                        label_x_axis: equipment.data.label_x_axis || 0,
                        data_x_axis: equipment.data.data_x_axis || 0,
                        image_x_axis: equipment.data.image_x_axis || 0,
                        imageMargin: equipment.data.imageMargin || 0,
                        color:
                            equipment.data.color === VERBIAGE.COLOUR_YELLOW
                                ? VERBIAGE.COLOUR_NEW_YELLOW
                                : equipment.data.color
                        // imageZoom: equipment.data.imageZoom || 1
                    }
                });
            });
        }

        return (
            <OpenLayerWrapper
              options={{
                    image: {
                        width: plantSchematic.image.width,
                        height: plantSchematic.image.height,
                        url: plantSchematic.image.diagram,
                        zoom: plantSchematic.image.zoom
                    },
                    devices: equipments
                }}
            />
        );
    };

    return (
        <div className="main-wrapper">
            {plantSchematic && plantSchematic.equipment && makeOptions(plantSchematic.equipment)}
        </div>
    );
};

const mapStateToProps = state => ({
    plantSchematic: state.plantSchematic
});

const mapDispatchToProps = dispatch => ({
    getEquipment: () => {
        dispatch(PlantActions.getEquipment());
    },
    getImage: () => {
        dispatch(PlantActions.getImage());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlantView));
