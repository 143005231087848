/* eslint-disable no-console */
import MLActionTypes from "./MLActionTypes";

import api from "../../utils/API";
import URL from "../../utils/URL";

// TODO: use interceptor to send auth token header
function getRequestConfig(options = {}) {
    const token = localStorage.getItem("token");
    return {
        timeout: 30000,
        headers: {
            "x-auth-token": token
        },
        ...options
    };
}

const getMLSettings = () => async dispatch => {
    api.get(`${URL.DATA.ML_SETTINGS.ML_SETTINGS}/config`, getRequestConfig()).then(({ data }) =>
        dispatch({
            type: MLActionTypes.SET_ML_CONFIG,
            payload: data
        }),
    );
};

const ModbusActions = {
    getModbusHeartBeat: force => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_ML_MODBUS_HEARTBEAT,
                payload: {
                    loading: true,
                    error: null,
                    ...(force ? { loaded: false } : {})
                }
            });

            const response = await api.get(URL.DATA.ML.MODBUS_HEARTBEAT, getRequestConfig());
            dispatch({
                type: MLActionTypes.SET_ML_MODBUS_HEARTBEAT,
                payload: {
                    loading: false,
                    error: null,
                    loaded: true,
                    data: response.data
                }
            });
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
                return;
            }
            dispatch({
                type: MLActionTypes.SET_ML_MODBUS_HEARTBEAT,
                payload: {
                    loading: false,
                    error: error.response?.data?.message
                }
            });
        }
    },
    getPLCHeartBeat: force => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_ML_PLC_HEARTBEAT,
                payload: {
                    loading: true,
                    error: null,
                    ...(force ? { loaded: false } : {})
                }
            });

            const response = await api.get(URL.DATA.ML.PLC_HEARTBEAT, getRequestConfig());
            dispatch({
                type: MLActionTypes.SET_ML_PLC_HEARTBEAT,
                payload: {
                    loading: false,
                    error: null,
                    loaded: true,
                    data: response.data
                }
            });
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
                return;
            }
            dispatch({
                type: MLActionTypes.SET_ML_PLC_HEARTBEAT,
                payload: {
                    loading: false,
                    error: error.response?.data?.message
                }
            });
        }
    },
    getModbusEnableML: force => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_MODBUS_ENABLE_ML,
                payload: {
                    loading: true,
                    error: null,
                    ...(force ? { loaded: false } : {})
                }
            });

            const response = await api.get(URL.DATA.ML.MODBUS_ENABLE_ML, getRequestConfig());
            const { enabled, updated_at: lastUpdatedAt } = response.data;
            dispatch({
                type: MLActionTypes.SET_MODBUS_ENABLE_ML,
                payload: {
                    loading: false,
                    loaded: true,
                    enabled,
                    lastUpdatedAt
                }
            });
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
                return;
            }
            dispatch({
                type: MLActionTypes.SET_MODBUS_ENABLE_ML,
                payload: {
                    loading: false,
                    error: error.response?.data?.message
                }
            });
        }
    },
    postModbusEnableML: enable => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_MODBUS_ENABLE_ML,
                payload: {
                    error: null,
                    updating: true
                }
            });

            const response = await api.post(
                URL.DATA.ML.MODBUS_ENABLE_ML,
                { enable },
                getRequestConfig(),
            );

            dispatch({
                type: MLActionTypes.SET_ML_MODBUS_ML,
                payload: {
                    enabled: response.enabled,
                    lastUpdatedAt: response.updated_at
                }
            });
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            dispatch({
                type: MLActionTypes.SET_ML_MODBUS_ML,
                payload: {
                    error: error.message
                }
            });
        } finally {
            dispatch({
                type: MLActionTypes.SET_ML_MODBUS_ML,
                payload: {
                    updating: false
                }
            });
        }
    }
};

const MLSetpointActions = {
    getSetpointData: force => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT,
                payload: {
                    loading: true,
                    ...(force ? { loaded: false } : {})
                }
            });

            const response = await api.get(URL.DATA.ML.SETPOINT_DATA, getRequestConfig());
            const {
                setpoint,
                ml_sp_in_range: spInRange,
                updated_at: lastUpdatedAt
            } = response.data;
            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT,
                payload: {
                    loading: false,
                    loaded: true,
                    error: null,
                    spErrors: {},
                    spInRange,
                    setpoint,
                    lastUpdatedAt
                }
            });
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
                return;
            }
            console.log(error);
            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT,
                payload: {
                    error: error.response?.data?.message,
                    loading: false
                }
            });
        }
    },
    postSetpointRecData: data => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT_SP_ERROR,
                sp: data.sp,
                error: null
            });
            await api.post(URL.DATA.ML.SETPOINT_RECOMMENDED, data, getRequestConfig());
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            const errorMessage = error.response?.data?.description ?? error.message;
            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT_SP_ERROR,
                sp: data.sp,
                error: `${data.sp}: ${errorMessage}`
            });
        }
    },
    getAllSetpointStatus: () => async (dispatch, getState) => {
        try {
            const {
                ML: {
                    setpoint: { setpoint }
                }
            } = getState();

            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT,
                payload: {
                    loading: true
                }
            });

            const response = await api.get(URL.DATA.ML.SETPOINT_STATUS, getRequestConfig());
            if (response.data.setpoint) {
                const updatedSetpoint = { ...setpoint };
                Object.keys(response.data.setpoint).forEach(sp => {
                    if (sp in updatedSetpoint) {
                        updatedSetpoint[sp].enabled = response.setpoint[sp];
                    }
                });
                dispatch({
                    type: MLActionTypes.SET_ML_SETPOINT,
                    payload: {
                        loading: false,
                        setpoint: updatedSetpoint
                    }
                });
            }
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
                return;
            }
            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT,
                payload: {
                    loading: false,
                    error: error.response?.data?.message
                }
            });
        }
    },
    getSetpointStatus: sp => async dispatch => {
        try {
            const response = await api.get(
                `${URL.DATA.ML.SETPOINT_STATUS}/${sp}`,
                getRequestConfig(),
            );
            if (sp in response.data.setpoint) {
                // eslint-disable-next-line camelcase
                const { cloud_status, scada_status } = response.data.setpoint[sp];
                const payload = { cloud_status, scada_status };
                if (payload.cloud_status === payload.scada_status) {
                    payload.cloudLocking = null;
                    payload.scadaPooling = null;
                }
                dispatch({
                    type: MLActionTypes.SET_ML_SETPOINT_SP,
                    sp,
                    payload
                });
            }
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
                return;
            }
            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT,
                payload: {
                    error: error.response?.data?.message
                }
            });
        }
    },
    setSetpointError: (sp, error) => ({
        type: MLActionTypes.SET_ML_SETPOINT_SP_ERROR,
        sp,
        error
    }),
    clearSetpointErrors: () => ({
        type: MLActionTypes.SET_ML_SETPOINT,
        payload: {
            errors: {}
        }
    }),
    postSetpointStatus: (sp, enable) => async (dispatch, getState) => {
        try {
            const {
                ML: { config }
            } = getState();

            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT_SP,
                sp,
                payload: {
                    error: null,
                    loading: true
                }
            });

            const response = await api.post(
                `${URL.DATA.ML.SETPOINT_STATUS}/${sp}`,
                { enable },
                getRequestConfig(),
            );
            const spPayload = { loading: false };
            if (response.data.setpoint) {
                const { cloud, scada } = response.data.setpoint[sp];
                spPayload.cloud_status = cloud;
                spPayload.scada_status = scada;
                const date = new Date();
                date.setSeconds(date.getSeconds() + (config.LOCKING_DURATION ?? 30));
                const timestamp = date.valueOf();
                switch (response.data.status) {
                    case 0:
                        spPayload.cloudLocking = timestamp;
                        break;
                    default:
                        spPayload.cloudLocking = timestamp;
                        spPayload.scadaPooling = timestamp;
                        break;
                }
            }
            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT_SP,
                sp,
                payload: spPayload
            });
        } catch (error) {
            // TODO: use interceptor for handling request error
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }

            const errorMessage = error.response?.data?.description ?? error.message;
            dispatch({
                type: MLActionTypes.SET_ML_SETPOINT_SP,
                sp,
                payload: {
                    error: `${sp} ${enable ? "Enable" : "Disable"}: ${errorMessage}`,
                    loading: false
                }
            });
        }
    }
};

const MLTrainActions = {
    getTrainLastDagRun: () => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_ML_TRAIN,
                payload: {
                    loading: true
                }
            });

            const url = `${URL.DATA.ML.TRAIN}/dagRun`;
            const response = await api.get(url, getRequestConfig());

            dispatch({
                type: MLActionTypes.SET_ML_TRAIN,
                payload: {
                    error: null,
                    ...response.data
                }
            });
        } catch (error) {
            if (error.response?.status === 422) {
                dispatch({
                    type: MLActionTypes.SET_ML_TRAIN,
                    payload: {
                        error: error.response?.data?.detail ?? error.response?.data?.title
                    }
                });
            }
        } finally {
            dispatch({
                type: MLActionTypes.SET_ML_TRAIN,
                payload: {
                    loading: false
                }
            });
        }
    },
    getTrainDagRuns: (params, options = {}) => async (dispatch, getState) => {
        try {
            const { skipLoading = false, eventSourceData } = options;
            if (!skipLoading) {
                dispatch({
                    type: MLActionTypes.SET_ML_TRAIN,
                    payload: {
                        loading: true
                    }
                });
            }

            const url = `${URL.DATA.ML.TRAIN}/dagRuns`;
            const response = await api.get(url, getRequestConfig({ params }));

            const payload = response.data;
            const { dagRuns } = payload;
            if (dagRuns.length) {
                payload.lastDagRun = dagRuns[dagRuns.length - 1];
                const {
                    ML: {
                        train: { lastTaskId }
                    }
                } = getState();
                if (
                    eventSourceData?.task_id === lastTaskId &&
                    eventSourceData?.state !== payload.lastDagRun.state
                ) {
                    dispatch(MLTrainActions.getTrainDagRuns());
                }
            }

            dispatch({
                type: MLActionTypes.SET_ML_TRAIN,
                payload: {
                    ...payload,
                    loading: false,
                    error: null
                }
            });
        } catch (error) {
            if (error.response?.status === 422) {
                dispatch({
                    type: MLActionTypes.SET_ML_TRAIN,
                    payload: {
                        error: error.response?.data?.detail ?? error.response?.data?.title,
                        loading: false
                    }
                });
            }
        }
    },
    triggerTrainDagRun: () => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_ML_TRAIN,
                payload: {
                    triggerDagRun: null
                }
            });

            const url = `${URL.DATA.ML.TRAIN}/dagRun`;
            const response = await api.post(url, null, getRequestConfig());

            dispatch({
                type: MLActionTypes.SET_ML_TRAIN,
                payload: {
                    triggerDagRun: response.data
                }
            });
        } catch (error) {
            if (error.response?.status === 422) {
                dispatch({
                    type: MLActionTypes.SET_ML_TRAIN,
                    payload: {
                        error: error.response?.data?.detail ?? error.response?.data?.title
                    }
                });
            }
        }
    }
};

const MLPredictActions = {
    getPredictLastDagRun: () => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_ML_PREDICT,
                payload: {
                    error: null,
                    loading: true
                }
            });

            const url = `${URL.DATA.ML.PREDICT}/dagRun`;
            const response = await api.get(url, getRequestConfig());

            dispatch({
                type: MLActionTypes.SET_ML_PREDICT,
                payload: response.data
            });
        } catch (error) {
            if (error.response?.status === 422) {
                dispatch({
                    type: MLActionTypes.SET_ML_PREDICT,
                    payload: {
                        error: error.response?.data?.detail ?? error.response?.data?.title
                    }
                });
            }
        } finally {
            dispatch({
                type: MLActionTypes.SET_ML_PREDICT,
                payload: {
                    loading: false
                }
            });
        }
    },
    getPredictDagRuns: (params, options = {}) => async (dispatch, getState) => {
        try {
            const { skipLoading = false, eventSourceData } = options;
            if (!skipLoading) {
                dispatch({
                    type: MLActionTypes.SET_ML_PREDICT,
                    payload: {
                        loading: true
                    }
                });
            }

            const url = `${URL.DATA.ML.PREDICT}/dagRuns`;
            const response = await api.get(url, getRequestConfig({ params }));

            const payload = response.data;
            const { dagRuns } = payload;
            if (dagRuns.length) {
                payload.lastDagRun = dagRuns[dagRuns.length - 1];
                const {
                    ML: {
                        predict: { lastTaskId }
                    }
                } = getState();
                if (
                    eventSourceData?.task_id === lastTaskId &&
                    eventSourceData?.state !== payload.lastDagRun.state
                ) {
                    dispatch(MLTrainActions.getTrainDagRuns());
                }
            }

            dispatch({
                type: MLActionTypes.SET_ML_PREDICT,
                payload: {
                    ...payload,
                    loading: false,
                    error: null
                }
            });
        } catch (error) {
            if (error.response?.status === 422) {
                dispatch({
                    type: MLActionTypes.SET_ML_PREDICT,
                    payload: {
                        error: error.response?.data?.detail ?? error.response?.data?.title,
                        loading: false
                    }
                });
            }
        }
    },
    triggerPredictDagRun: () => async dispatch => {
        try {
            dispatch({
                type: MLActionTypes.SET_ML_PREDICT,
                payload: {
                    triggerDagRun: null
                }
            });

            const url = `${URL.DATA.ML.PREDICT}/dagRun`;
            const response = await api.post(url, null, getRequestConfig());

            dispatch({
                type: MLActionTypes.SET_ML_PREDICT,
                payload: {
                    triggerDagRun: response.data
                }
            });
        } catch (error) {
            if (error.response?.status === 422) {
                dispatch({
                    type: MLActionTypes.SET_ML_PREDICT,
                    payload: {
                        error: error.response?.data?.detail ?? error.response?.data?.title
                    }
                });
            }
        }
    }
};

export default {
    getMLSettings,
    ...ModbusActions,
    ...MLSetpointActions,
    ...MLTrainActions,
    ...MLPredictActions
};
