/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    makeStyles,
    Typography,
    Link
} from "@material-ui/core";
import { readString } from "react-papaparse";
import { useDropzone } from "react-dropzone";
import { VERBIAGE } from "../../../utils/enums";
import api from "../../../utils/API";

const CSVUpload = props => {
    const { open, setOpen, handleClick, url, overwrite, onSuccess, multiple = true } = props;

    const [arr, setArr] = useState([]);
    const [errorValues, setErrorValues] = useState([]);
    const [errorGroup, setErrorGroup] = useState({});
    const [isOverwrite, setIsOverwrite] = useState(false);

    const [error, setError] = useState("");

    const onDrop = useCallback(acceptedFiles => {
        setArr([]);
        setErrorValues([]);
        setErrorGroup({});
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                const transform = value => {
                    // if value is an array
                    if (value.charAt(0) === "[" && value.charAt(value.length - 1) === "]") {
                        const split = value
                            .substr(1, value.length - 2)
                            .split(",")
                            .map(item => item.trim());
                        return split;
                    }
                    return value;
                };
                const config = {
                    header: true,
                    transform,
                    skipEmptyLines: true
                };
                const res = readString(reader.result, config);
                const data = res.data.map((item, index) => ({
                    ...item,
                    metadata: {
                        row: index + 2,
                        filename: file.name
                    }
                }));
                setArr(prev => {
                    return [...prev, ...data];
                });
            };
        });
    }, []);

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: ".csv, text/csv, application/vnd.ms-excel",
        multiple,
        onDrop
    });

    useEffect(() => {
        setArr([]);
        setErrorValues([]);
        setErrorGroup({});
        acceptedFiles.length = 0;
    }, [open]);

    const handleError = response => {
        if (Array.isArray(response.data)) {
            const errValues = [];
            const errGroup = {};
            response.data.forEach((item, i) => {
                if (item.status === "FAILED") {
                    if (item.filename) {
                        if (!errGroup[item.filename]) {
                            errGroup[item.filename] = [];
                        }
                        errGroup[item.filename].push({
                            row: item.row,
                            details: item.details
                        });
                    } else {
                        errValues.push(i + 2);
                    }
                }
            });
            setErrorValues(errValues);
            setErrorGroup(errGroup);
            return;
        }

        // eslint-disable-next-line max-len
        setError(
            VERBIAGE.ERROR_TEXT.ERROR_OCCURED + (response.details ? ` : ${response.details}` : ""),
        );
    };

    const handleSubmit = async () => {
        const data = {
            data: arr,
            overwrite: isOverwrite
        };
        try {
            const response = await api.post(url, data, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            // Checking for rows that returned an error, and displaying them
            if (response.data.status === "error") {
                handleError(response.data);
                return;
            }
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            handleError(err.response.data);
            return;
        }
        setOpen(false);
        if (onSuccess) onSuccess();
    };

    const baseStyle = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out"
    };

    const activeStyle = {
        borderColor: "#2196f3"
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {})
        }),
        [baseStyle, isDragActive, activeStyle],
    );

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        },
        errorGroup: {
            color: theme.palette.error.dark
        }
    }));

    const classes = useStyles();

    const files = acceptedFiles.map(({ path, name, size }) => (
        <li key={path}>
            <Typography>{`${path} - ${size} bytes`}</Typography>
            {!!errorGroup[name] && (
                <ul className={classes.errorGroup}>
                    {errorGroup[name].map(item => (
                        <li key={item.filename + item.row}>
                            <Typography>{`Row ${item.row}: ${item.details}`}</Typography>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    ));

    const handleChange = name => event => {
        setIsOverwrite(event.target.checked);
    };

    return (
        <Dialog open={open} onClose={handleClick}>
            <DialogTitle>
                To upload a CSV file, click or drag and drop the file into the box below
            </DialogTitle>
            {overwrite ? (
                <DialogContent>
                    <FormControlLabel
                      control={(
                            <Checkbox
                              checked={isOverwrite}
                              onChange={handleChange("isOverwrite")}
                              value={isOverwrite}
                            />
                          )}
                      label="Do you want to overwrite current mapping? (Untick is to just update)"
                    />
                </DialogContent>
            ) : (
                <div />
            )}
            <DialogContent>
                <section className="container">
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>
                            Drag and drop some files here, or 
{' '}
<Link>click to select files</Link>
                        </p>
                    </div>
                    <aside>
                        <h4>Files</h4>
                        <ol>{files}</ol>
                    </aside>
                </section>

                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
                {errorValues.length > 0 && (
                    <div className={classes.error}>
                        <Typography>{`Error in row(s)\n ${errorValues.join(", ")}`}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CSVUpload;
