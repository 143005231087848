/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import "../styles/mapTable.scss";
import TextInput from "./TextInput";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

const EditBaselineTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        value: VERBIAGE.TEXT.EMPTY_STRING,
        date: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleSite = ({ target: { value } }) => {
        updateConfig({ ...config, siteId: value });
    };

    const handleValue = ({ target: { value } }) => {
        updateConfig({ ...config, value });
    };

    const handleTime = event => {
        const newTimestamp = new Date(event._d).getTime();
        updateConfig({ ...config, date: newTimestamp });
    };

    const handleSubmit = async () => {
        if (!config.siteId || !config.value || !config.date) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        if (isEdit) {
            const data = {
                siteId: config.siteId,
                value: config.value,
                date: config.date
            };
            const url = `${URL.DATA.PLANT_SETTINGS.BASELINES}/${config.id}`;
            try {
                const response = await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        } else {
            const data = {
                ...config
            };
            const url = URL.DATA.PLANT_SETTINGS.BASELINES;
            try {
                const response = await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                if (response.data.status === "error") {
                    setError(
                        VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                            (response.data.details ? ` : ${response.data.details}` : ""),
                    );
                    return;
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setError(
                    VERBIAGE.ERROR_TEXT.ERROR_OCCURED +
                        (err.response.data.details ? ` : ${err.response.data.details}` : ""),
                );
                return;
            }
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    // Override border color of datetime picker
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            }
        }
    });

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${
                    isEdit ? "edit" : "add to"
                } the baselines list, please enter the details below.`}
            </DialogTitle>

            <DialogContent>
                <div className={EditBaselineTable.styles.inputContainer}>
                    <TextInput
                      onChange={handleSite}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_SITE_ID}
                      value={config.siteId}
                      type="text"
                    />
                </div>
                <div className={EditBaselineTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_VALUE}
                      onChange={handleValue}
                      value={config.value}
                      type="text"
                    />
                </div>
                <div className={EditBaselineTable.styles.inputContainer}>
                    <MuiThemeProvider theme={themeOverride}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDateTimePicker
                              variant="dialog"
                              label={VERBIAGE.PLACEHOLDER_TEXT.ENTER_TIME}
                              inputVariant="outlined"
                              value={config.date}
                              onChange={handleTime}
                              format="DD/MM/YYYY HH:mm"
                              fullWidth
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </div>
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setOpen(false);
                        setError("");
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditBaselineTable.styles = {
    inputContainer: "webclient__mapTable-input"
};

export default EditBaselineTable;
