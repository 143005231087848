import { useLocation } from "react-router-dom";

const useQueryParams = () => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const getQuery = key => {
        return searchParams.get(key);
    };

    const setQuery = (params = {}) => {
        Object.keys(params).forEach(key => {
            searchParams.set(key, params[key]);
        });
        return searchParams.toString();
    };

    const deleteQuery = key => {
        searchParams.delete(key);
        return searchParams.toString();
    };

    const queryParams = () => {
        return searchParams.toString();
    };

    return { getQuery, setQuery, deleteQuery, queryParams };
};

export default useQueryParams;
