/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { Grid, Button, makeStyles, Dialog } from "@material-ui/core";
import { VERBIAGE } from "../../../utils/enums/index";
import AnalyticsTime from "./AnalyticsTime";
import AnomalyDiagnosisActions from "../../redux_AnomalyDiagnosis/AnomalyDiagnosisActions";

import AnalyticsEOPDataDialog from "../components/AnalyticsEOPDataDialog";

const moment = require("moment");

const useStyles = makeStyles(theme => ({
    header: {
        color: theme.palette.type === "dark" ? theme.palette.text.dark : theme.palette.primary.main,
        margin: "4px",
        fontSize: "14px",
        paddingRight: "10px"
    },
    button: {
        height: "22px",
        margin: "4px",
        textTransform: "None",
        background:
            theme.palette.type === "dark"
                ? theme.palette.background.button
                : theme.palette.primary.main
    }
}));
const menuStyle = {
    color: "white",
    textDecoration: "none"
};

const ExportDiagnosisSummaryCSV = props => {
    const { data, isNarrow } = props;
    const classes = useStyles();
    const [newData, setNewData] = useState([]);

    const handleExportCSVClick = () => {
        const tempData = [];
        for (let i = 0; i < data.length; i++) {
            const newDict = {};
            newDict["id"] = data[i].id;
            newDict["start"] = moment(data[i].start).format("Do MMMM YYYY, HH:mm:ss");
            newDict["duration"] = data[i].duration;
            newDict["parameter"] = data[i].equipment;
            newDict["sysPlantRT"] = data[i].sysPlantRT;
            newDict["KW"] = data[i].energyConsumption;
            newDict["predKW"] = data[i].predictionEnergyConsumption;
            newDict["eff"] = data[i].actualEfficiency;
            newDict["predEff"] = data[i].predictionEfficiency;
            newDict["devKW"] = data[i].deviationEnergyConsumption;
            newDict["devEff"] = data[i].deviationEfficiency;
            newDict["dev%"] = data[i].percentageDeviationEfficiency;
            newDict["reason1"] = data[i].reason1;
            newDict["reason2"] = data[i].reason2;
            newDict["reason3"] = data[i].reason1;
            newDict["costImpact"] = data[i].costImpact;
            newDict["waterImpact"] = data[i].waterImpact;
            newDict["energyImpact"] = data[i].energyImpact;
            newDict["status"] = data[i].status;
            newDict["feedback"] = data[i].feedback;

            tempData.push(newDict);
        }

        setNewData(tempData);
    };

    const now = moment().format("YYYYMMDD");

    return (
        <Grid container item xs={12} sm={12} md={12} lg={12} justify="flex-end">
            <Grid>
                <h2 className={classes.header}>
                    {`${data.length}${VERBIAGE.ANALYTICS.FAULT_EVENT}`}
                </h2>
            </Grid>
            <Grid>
                <AnalyticsTime />
            </Grid>
            {!isNarrow && (
                <Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.button}
                      onClick={handleExportCSVClick}
                    >
                        <CSVLink
                          style={menuStyle}
                          data={newData}
                          filename={`${data[0]?.siteId}_anomaly_diagnosis_${now}.csv`}
                        >
                            {VERBIAGE.BUTTONS.EXPORT_TABLE_CSV}
                        </CSVLink>
                    </Button>
                </Grid>
            )}
            <Grid>
                <AnalyticsEOPDataDialog />
            </Grid>
        </Grid>
    );
};

export default ExportDiagnosisSummaryCSV;
