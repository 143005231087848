import React, { useState, useRef, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";

import MUIDataTable from "mui-datatables";

import RuleBasedOptimizationCHWPMinMaxTimeForm from "./RuleBasedOptimizationCHWPMinMaxTimeForm";
import useRuleBasedOptimizationCHWP from "./hooks/useRuleBasedOptimizationCHWP";

const RuleBasedOptimizationCHWPMinMaxTime = () => {
    const {
        minMaxTime: { loading, data },
        fetchMinMaxTime,
        deleteMinMaxTime
    } = useRuleBasedOptimizationCHWP();

    useEffect(() => {
        fetchMinMaxTime();
    }, [fetchMinMaxTime]);

    const handleRefresh = () => {
        fetchMinMaxTime(true);
    };

    const editRef = useRef();
    const [isEdit, setIsEdit] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);

    const [deleteRow, setDeleteRow] = useState();

    const handleOpenDialog = (action, dataIndex) => {
        switch (action) {
            case "add":
                editRef.current.init();
                setIsEdit(false);
                setOpenEditDialog(true);
                break;
            case "edit":
                editRef.current.update({
                    ...data[dataIndex]
                });
                setIsEdit(true);
                setOpenEditDialog(true);
                break;
            case "delete":
                setDeleteRow(data[dataIndex]);
                break;
            default:
        }
    };

    const handleEditSuccess = () => {
        fetchMinMaxTime(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteRow(null);
    };

    const handleDelete = () => {
        deleteMinMaxTime(deleteRow.id);
        setDeleteRow(null);
    };

    const columns = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: dataIndex => {
                    return (
                        <Grid container justifyContent="flex-end" spacing={1}>
                            <IconButton
                              title="Edit"
                              onClick={() => handleOpenDialog("edit", dataIndex)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                              title="Delete"
                              onClick={() => handleOpenDialog("delete", dataIndex)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    );
                }
            }
        },
        { name: "TIME_FROM", label: "Time From" },
        { name: "TIME_TO", label: "Time To" },
        { name: "MIN_DT", label: "Min DT" },
        { name: "MAX_DT", label: "Max DT" },
        { name: "MIN_FLOW", label: "Min Flow" }
    ];

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        filter: false,
        print: false,
        viewColumns: false,
        download: false,
        customToolbar: () => {
            return (
                <>
                    <Tooltip title="Refresh">
                        <IconButton onClick={handleRefresh}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add CHWP Min Max Time">
                        <IconButton onClick={() => handleOpenDialog("add")}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </>
            );
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress size={24} />
                ) : (
                    "Sorry, there is no matching data to display"
                )
            }
        }
    };

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <MUIDataTable
                  title="CHWP Min Max Time"
                  data={data}
                  columns={columns}
                  options={options}
                />
                <RuleBasedOptimizationCHWPMinMaxTimeForm
                  ref={editRef}
                  isEdit={isEdit}
                  open={openEditDialog}
                  setOpen={setOpenEditDialog}
                  onSuccess={handleEditSuccess}
                />
                {deleteRow && (
                    <DeleteDialog
                        // eslint-disable-next-line max-len
                      title={`Are you sure want to delete Min Max Time: ${deleteRow.TIME_FROM} - ${deleteRow.TIME_TO}`}
                      open={!!deleteRow}
                      onClose={handleCloseDeleteDialog}
                      onDelete={handleDelete}
                    />
                )}
            </Grid>
        </Grid>
    );
};

function DeleteDialog({ title, open, onClose, onDelete }) {
    return (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogActions>
                <Button onClick={onDelete} color="primary" autoFocus>
                    Delete
                </Button>
                <Button onClick={onClose} color="default">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RuleBasedOptimizationCHWPMinMaxTime;
