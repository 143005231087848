/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import AnomalyDiagnosisActionTypes from "./AnomalyDiagnosisActionTypes";
import api from "../../utils/API";
import URL from "../../utils/URL";

const config = {
    headers: {
        // "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        "x-auth-token": ""
    }
};

function constructHeader() {
    const token = localStorage.getItem("token");

    config.headers["x-auth-token"] = `${token}`;
    return config;
}

const AnomalyDiagnosisActions = {
    getDiagnosisTableData: (data, filterType) => ({
        type: AnomalyDiagnosisActionTypes.GET_DIAGNOSIS_TABLE,
        data,
        filterType
    }),
    getDiagnosisTable: type => dispatch => {
        let filterType;
        if (type === "apply") {
            filterType = true;
        } else {
            filterType = false;
        }
        constructHeader();
        api.get(URL.DATA.ANALYTICS.ANOMALY.DIAGNOSIS_TABLE, config)
            .then(response => {
                dispatch(AnomalyDiagnosisActions.getDiagnosisTableData(response.data, filterType));
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    handleFeedback: reason => async dispatch => {
        constructHeader();
        await api
            .post(
                `${URL.DATA.ANALYTICS.ANOMALY.FEEDBACK}/${reason.time}/${reason.equipment}`,
                {
                    status: reason.status,
                    feedback: reason.feedback.replace(/(\n\r|\n|\r)/gm, "")
                },
                config,
            )
            .then(() => {
                // dispatch(AnalyticsActions.getFaultTable());
                dispatch(AnomalyDiagnosisActions.getDiagnosisTable());
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    // setInterval: data => ({
    //     type: AnomalyDiagnosisActionTypes.UPDATE_DIAGNOSIS_TABLE,
    //     data
    // }),
    updateDiagnosisInterval: () => dispatch => {
        constructHeader();
        api.put(
            URL.DATA.ANALYTICS.UPDATE_FAULT_TABLE,
            {
                interval: localStorage.getItem("interval"),
                intervalType: localStorage.getItem("intervalType")
            },
            config,
        )
            .then(() => {
                dispatch(AnomalyDiagnosisActions.getDiagnosisTable("apply"));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    setActiveDiagnosis: data => ({
        type: AnomalyDiagnosisActionTypes.UPDATE_ACTIVE_DIAGNOSIS,
        data
    }),
    updateDiagnosisSelected: (start, equipment) => dispatch => {
        // console.log("updateDiagnosisSelected");
        constructHeader();
        api.put(
            URL.DATA.ANALYTICS.ANOMALY.UPDATE_DIAGNOSIS_SELECTED,
            {
                start,
                equipment
            },
            config,
        )
            .then(() => {
                // dispatch(AnomalyDiagnosisActions.getDiagnosisTable("apply"));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
    },
    fetchChartPosition: (code, parameter) => async dispatch => {
        try {
            constructHeader();
            const response = await api.get(
                `${URL.DATA.ANALYTICS.GET_CHARTS}/${code}/${parameter}`,
                config,
            );
            if (response.data) {
                const chartData = {
                    charts: response.data,
                    code,
                    parameter
                };
                dispatch(AnomalyDiagnosisActions.setChartPosition(chartData));
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }
    // fetchEOP: (equipment) => async dispatch => {
    //     try {
    //         constructHeader();
    //         const response = await api.get(`${URL.DATA.ANALYTICS.ANOMALY.GET_EOP_DATA}${equipment}`, config);
    //         if (response.data) {
    //             // console.log("response.data : ", response.data);
    //             // const chartData = {
    //             //     charts: response.data,
    //             //     code,
    //             //     parameter
    //             // };
    //             // dispatch(AnomalyDiagnosisActions.setChartPosition(chartData));
    //         }
    //     } catch (error) {
    //         if (error.response && error.response.status === 401) {
    //             localStorage.clear();
    //             window.location.href = "/";
    //         }
    //     }
    // }
};

export default AnomalyDiagnosisActions;
