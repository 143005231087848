/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import MobileDetect from "mobile-detect";

import AnalyticsMenu from "./AnalyticsMenu";
import DwhMenu from "./DwhMenu";
import SiteMenu from "./SiteMenu";
import SwitchPlatform from "./SwitchPlatform";
import SwitchTheme from "./SwitchTheme";
import Copyright from "./Copyright";

import usePlatform from "../../../../hooks/usePlatform";

import { REFRESH_TIMER } from "../../../../utils/enums";
import PortfolioActions from "../../../redux/PortfolioActions";

import "../../styles/sidebar.scss";

const SideBar = props => {
    const {
        portfolio,
        setToggleOption,
        fetchSites,
        fetchUpdateChangeSite,
        handleSiteName1,
        isMobile,
        isTablet,
        home,
        narrow
    } = props;

    // const md = new MobileDetect(window.navigator.userAgent);

    const getPadding = () => {
        if (home.orientation !== "landscape" && home.desktopMobileSwitch !== "desktop") {
            return "0px";
        }
        return "75px";
    };

    const useStyles = makeStyles(theme => ({
        background: {
            background: theme.palette.type === "dark" ? "#0B0B0B" : "",
            paddingTop: getPadding(),
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: home.desktopMobileSwitch === "desktop" ? "100%" : "100vh",
            left: 0,
            backgroundColor: "$white",
            overflowX: "hidden",
            whiteSpace: "nowrap"
        },
        icon: {
            filter: " brightness(0.3) sepia(1) hue-rotate(180deg) saturate(6)",
            marginLeft: "2.5vw"
        },
        icon1: {
            filter: " brightness(0.3) sepia(1) hue-rotate(180deg) saturate(6)"
        },
        nested: {
            padding: "0 0 0 7px"
        },
        clients: {
            textAlign: "center",
            cursor: "pointer"
        },
        dividerFullWidth: {
            marginRight: "16px"
        },
        container2: {
            width: "100%",
            height: "auto",
            padding: "1%",
            fontSize: "16px"
        },
        footer: {
            minHeight: "250px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
        }
    }));

    const classes = useStyles();

    const [open, setOpen] = useState(localStorage.getItem("open") === "true");

    const permission = localStorage.getItem("permission");

    const { platform } = usePlatform();

    const sidebarRef = useRef(null);

    useEffect(() => {
        setToggleOption(open);
    }, [open, setToggleOption]);

    useEffect(() => {
        const timerValue = REFRESH_TIMER();
        fetchSites();
        const timer = setInterval(() => {
            fetchSites();
        }, timerValue);
        return () => clearInterval(timer);
    }, [fetchSites]);

    const handleDrawerOpen = () => {
        setOpen(true);
        localStorage.setItem("open", true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        localStorage.setItem("open", false);
    };

    const handleChangeSite = site => {
        const { siteId, siteName } = site;
        const requestBody = { siteId: { siteId } };
        fetchUpdateChangeSite({ requestBody });
        handleSiteName1(siteName);
    };

    // useEffect for storing sidebar scroll position for phone mobile view
    useEffect(() => {
        if (sidebarRef.current) {
            const element = document.getElementById("mobile-tabs");
            const landscapeSidebar = sidebarRef.current;
            if (element !== null) {
                const scroll = localStorage.getItem("mobileTabScroll");
                element.scrollLeft = scroll;
            }
            if (landscapeSidebar !== null) {
                const scrollLandscape = localStorage.getItem("mobileTabScrollLandscape");
                landscapeSidebar.scrollTop = scrollLandscape;
            }
            return () => {
                if (element !== null) {
                    localStorage.setItem("mobileTabScroll", element.scrollLeft);
                }
                if (landscapeSidebar !== null) {
                    localStorage.setItem("mobileTabScrollLandscape", landscapeSidebar.scrollTop);
                }
            };
        }
    }, [sidebarRef]);
    // include this dependency to run this effect again after this component renders

    if (isMobile) {
        return (
            <>
                <SiteMenu portfolio={portfolio} onChange={handleChangeSite} />
                <AnalyticsMenu
                  open={open}
                  permission={permission}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
                <SwitchPlatform open={open} permission={permission} />
                <SwitchTheme open={open} />
            </>
        );
    }

    return (
        <Paper
          ref={sidebarRef}
          className={`${classes.background} ${open ? "open" : "close"}`}
          square
        >
            <Box>
                {platform === "analytics" ? (
                    <>
                        <SiteMenu open={open} portfolio={portfolio} onChange={handleChangeSite} />
                        <AnalyticsMenu
                          permission={permission}
                          open={open}
                          narrow={narrow}
                          isMobile={isMobile}
                          isTablet={isTablet}
                        />
                    </>
                ) : (
                    <DwhMenu permission={permission} isMobile={isMobile} isTablet={isTablet} />
                )}
            </Box>
            <Box className={classes.footer}>
                <SwitchPlatform open={open} permission={permission} />
                <SwitchTheme open={open} />
                <Copyright open={open} onOpen={handleDrawerOpen} onClose={handleDrawerClose} />
            </Box>
        </Paper>
    );
};

const mapStateToProps = state => ({
    portfolio: state.portfolio,
    home: state.home
});
const mapDispatchToProps = dispatch => ({
    setToggleOption: toggle => {
        dispatch(PortfolioActions.setToggleOption(toggle));
    },
    fetchSites: () => {
        dispatch(PortfolioActions.fetchSites());
    },
    fetchUpdateChangeSite: credentials => {
        dispatch(PortfolioActions.fetchUpdateChangeSite(credentials));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBar));
