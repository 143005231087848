/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";

import { EventSourcePolyfill } from "event-source-polyfill";

import PIDAutoMLRefresh from "./PIDAutoMLRefresh";
import PIDAutoMLModbusEnable from "./PIDAutoMLModbusEnable";
import PIDAutoMLInterlocks from "./PIDAutoMLInterlocks";
import PIDAutoMLTrain from "./PIDAutoMLTrain";
import PIDAutoMLPredict from "./PIDAutoMLPredict";
import PIDAutoMLSetpointTable from "./PIDAutoMLSetpointTable";

import MLActions from "../../redux/MLActions";
import URL from "../../../utils/URL";
import useMLConfig from "./hooks/useMLConfig";

const PIDAutoML = () => {
    const { mainPoolingIntervalInSeconds } = useMLConfig();
    const {
        train: { dagId: trainDagId },
        predict: { dagId: predictDagId }
    } = useSelector(state => state.ML);
    const dispatch = useDispatch();

    const eventSource = useRef();

    useEffect(() => {
        dispatch(MLActions.getMLSettings());

        eventSource.current = new EventSourcePolyfill(URL.STREAM_URL, {
            headers: {
                "x-auth-token": localStorage.getItem("token")
            }
        });
        eventSource.current.onopen = () => {
            console.debug("[eventSource] opened");
        };
        eventSource.current.onerror = e => {
            console.debug("[eventSource] error:", e);
        };
        return () => {
            eventSource.current.close();
            console.debug("[eventSource] closed!");
        };
    }, [dispatch]);
    // sse: train
    useEffect(() => {
        if (eventSource.current && trainDagId) {
            const trainDagRunListener = e => {
                const eventSourceData = JSON.parse(e.data);
                console.debug(`[eventSource] ${trainDagId}:`, eventSourceData);
                dispatch(
                    MLActions.getTrainDagRuns(null, {
                        skipLoading: true,
                        eventSourceData
                    }),
                );
            };

            console.debug(`[eventSource] addEventListener: ${trainDagId}`);
            eventSource.current.addEventListener(trainDagId, trainDagRunListener);
            return () => {
                console.debug(`[eventSource] removeEventListener: ${trainDagId}`);
                eventSource.current.removeEventListener(trainDagId, trainDagRunListener);
            };
        }
    }, [trainDagId, dispatch]);
    // sse: predict
    useEffect(() => {
        if (eventSource.current && predictDagId) {
            const predictDagRunListener = e => {
                const eventSourceData = JSON.parse(e.data);
                console.debug(`[eventSource] ${predictDagId}:`, eventSourceData);
                dispatch(
                    MLActions.getPredictDagRuns(null, {
                        skipLoading: true,
                        eventSourceData
                    }),
                );
            };

            console.debug(`[eventSource] addEventListener: ${predictDagId}`);
            eventSource.current.addEventListener(predictDagId, predictDagRunListener);
            return () => {
                console.debug(`[eventSource] removeEventListener: ${predictDagId}`);
                eventSource.current.removeEventListener(predictDagId, predictDagRunListener);
            };
        }
    }, [predictDagId, dispatch]);

    // pooling get modbus hb, modbus enable ml & setpoint status
    const poolingSyncCb = useCallback(() => {
        dispatch(MLActions.getModbusHeartBeat());
        dispatch(MLActions.getPLCHeartBeat());
        dispatch(MLActions.getModbusEnableML());
        dispatch(MLActions.getAllSetpointStatus());
    }, [dispatch]);

    useEffect(() => {
        console.log("mainPoolingIntervalInSeconds", mainPoolingIntervalInSeconds);
        if (mainPoolingIntervalInSeconds) {
            const intervalId = setInterval(poolingSyncCb, mainPoolingIntervalInSeconds);
            console.log("poolingSyncCb", { mainPoolingIntervalInSeconds, intervalId });
            return () => {
                console.log("clearInterval:poolingSyncCb");
                clearInterval(intervalId);
            };
        }
    }, [mainPoolingIntervalInSeconds, poolingSyncCb]);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item container justifyContent="space-between" alignItems="flex-start">
                <Grid item xs>
                    <PIDAutoMLRefresh />
                </Grid>
                <Grid item xs container justifyContent="flex-end" alignItems="center" spacing={2}>
                    <PIDAutoMLModbusEnable />
                </Grid>
            </Grid>
            <Grid item container alignItems="center" spacing={2}>
                <PIDAutoMLInterlocks />
            </Grid>
            <Grid item container spacing={2}>
                <Grid
                  item
                  container
                  xs={6}
                  sm={4}
                  md={3}
                  justifyContent="center"
                  direction="column"
                >
                    <PIDAutoMLTrain />
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  sm={4}
                  md={3}
                  justifyContent="center"
                  direction="column"
                >
                    <PIDAutoMLPredict />
                </Grid>
            </Grid>
            <Grid item>
                <PIDAutoMLSetpointTable />
            </Grid>
        </Grid>
    );
};

export default PIDAutoML;
