import React, { useState } from "react";
import { Switch, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "../../../theme/ThemeContext";
import ICONS from "../../../../utils/enums/IconData";

const SwitchTheme = ({ open = true }) => {
    const theme = useTheme();
    const [state, setState] = useState({
        checked: localStorage.dark === "true"
    });

    const handleSwitch = event => {
        setState({ ...state, [event.target.name]: event.target.checked });
        theme.toggle();
    };

    const useStyles = makeStyles(() => ({
        container: {
            marginBottom: "1rem"
        },
        switch: {
            marginBottom: "0 !important"
        },
        icon: {
            filter: "brightness(0.3) sepia(1) hue-rotate(180deg) saturate(6)"
        }
    }));
    const classes = useStyles();

    const showSelectedTheme = () => {
        if (theme.dark) {
            return <img src={ICONS.MOON} alt="dark" className={classes.icon} />;
        }
        return <img src={ICONS.SUN} alt="light" className={classes.icon} />;
    };

    return (
        <Grid container justifyContent="center" className={classes.container}>
            {open ? (
                <img src={ICONS.SUN} alt="light" className={!state.checked ? classes.icon : null} />
            ) : (
                showSelectedTheme()
            )}
            <Switch
              size="small"
              checked={state.checked}
              onChange={handleSwitch}
              color="primary"
              name="checked"
              className={classes.switch}
            />
            {open && (
                <img src={ICONS.MOON} alt="moon" className={state.checked ? classes.icon : null} />
            )}
        </Grid>
    );
};

export default SwitchTheme;
