/* eslint-disable max-len */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { VERBIAGE } from "../../utils/enums";
import WaterPumpSpecOverview from "./waterPumpSpec/WaterPumpSpecOverview";
import WaterPumpSpecCurve from "./waterPumpSpec/WaterPumpSpecCurve";

const WaterPumpSpecManagementTabs = () => {
    return (
        <Switch>
            <Route
              path={VERBIAGE.WAREHOUSE_PATH.WATER_PUMP_SPEC_OVERVIEW}
              component={WaterPumpSpecOverview}
            />
            <Route
              path={VERBIAGE.WAREHOUSE_PATH.WATER_PUMP_SPEC_CURVE}
              component={WaterPumpSpecCurve}
            />
            <Route
              render={() => <Redirect to={VERBIAGE.WAREHOUSE_PATH.WATER_PUMP_SPEC_OVERVIEW} />}
            />
        </Switch>
    );
};

export default WaterPumpSpecManagementTabs;
