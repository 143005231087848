/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import tableIcons from "../../../utils/tableIcons";
import { VERBIAGE } from "../../../utils/enums";
import EditAlarmConfigTable from "./EditAlarmConfigTable";

const AlarmConfigTable = () => {
    const [data, setData] = useState([]);
    const columns = [
        { title: "Parameter", field: "param" },
        {
            title: "Configurations",
            field: "data",
            render: rowData => {
                const parsedRow = rowData.data.map(row => {
                    return `${row.name}: ${row.value}`;
                });
                return (
                  <React.Fragment>
                    {parsedRow.map((row, i) => {
                            return (
                              <div key={i} style={{ width: "350px", padding: "3px" }}>
                                <span>{row}</span>
                              </div>
                            );
                        })}
                  </React.Fragment>
                );
            }
        }
    ];

    const isMounted = useRef(false);

    const [isEdit, setIsEdit] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedParam, setSelectedParam] = useState("");

    const emptyConfig = {
        mapName: VERBIAGE.TEXT.EMPTY_STRING,
        longitude: VERBIAGE.TEXT.EMPTY_STRING,
        latitude: VERBIAGE.TEXT.EMPTY_STRING,
        zoom: VERBIAGE.TEXT.EMPTY_STRING,
        mapId: VERBIAGE.TEXT.EMPTY_STRING
    };

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.ANALYTICS.ALARM_CONFIG.TABLE, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });

                if (isMounted.current) {
                    setData(response.data.parameters);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    return (
      <div>
        <EditAlarmConfigTable
          open={open}
          setOpen={setOpen}
          selectedParam={selectedParam}
          data={data}
        />
        <div className={classes.table}>
          <MaterialTable
            icons={tableIcons}
            title="Alarm Config"
            columns={columns}
            data={data}
            options={{
                        headerStyle: { position: "sticky", top: 0 },
                        maxBodyHeight: "400px"
                    }}
            localization={{
                        header: {
                            actions: ""
                        }
                    }}
            actions={[
                        rowData => ({
                            icon: tableIcons.Edit,
                            onClick: () => {
                                setSelectedParam(rowData.param);
                                handleClick();
                            },
                            tooltip: "Edit"
                        })
                    ]}
          />
        </div>
      </div>
    );
};

export default withRouter(AlarmConfigTable);
