/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Typography,
    Link,
    Grid
} from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { VERBIAGE } from "../../../../utils/enums";
import API from "../../../../utils/API";
import URL from "../../../../utils/URL";

const AssetMongoDBUpload = props => {
    const { open, setOpen, handleClick } = props;
    const [error, setError] = useState(null);
    const [previewFiles, setPreviewFiles] = useState({});

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setPreviewFiles(prev => ({ ...prev, [file.name]: reader.result }));
            };
        });
    }, []);

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        multiple: true,
        onDrop
    });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>{`${file.path} - ${file.size} bytes`}</Grid>
                <Grid item>
                    <img src={previewFiles[file.name]} alt={file.name} height={30} />
                </Grid>
            </Grid>
        </li>
    ));

    useEffect(() => {
        acceptedFiles.length = 0;
    }, [open]);

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append(
                "metadata",
                JSON.stringify({
                    siteId: localStorage.getItem("activeSite")
                }),
            );
            acceptedFiles.forEach(file => {
                formData.append("files[]", file);
            });
            await API.post(URL.DATA.PLANT_SETTINGS.ASSETS_BULK, formData, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            setError(null);
            setOpen(false);
        } catch (err) {
            setError(err.message);
        }
    };

    const baseStyle = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out"
    };

    const activeStyle = {
        borderColor: "#2196f3"
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {})
        }),
        [baseStyle, isDragActive, activeStyle],
    );

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClick}>
            <DialogTitle>
                To upload a file, click or drag and drop the file into the box below
            </DialogTitle>
            <DialogContent>
                <section className="container">
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>
                            Drag and drop some files here, or 
{' '}
<Link>click to select files</Link>
                        </p>
                    </div>
                    <aside>
                        <h4>Files:</h4>
                        <ul>{files}</ul>
                    </aside>
                </section>

                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError(null);
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssetMongoDBUpload;
