import { combineReducers } from "redux";
import authentication from "./authentication/redux/AuthenticationReducer";
import home from "./dashboard/features/redux/HomeReducer";
import portfolio from "./dashboard/redux/PortfolioReducer";
import analytics from "./dashboard/redux_Analytics/AnalyticsReducer";
import diagnosis from "./dashboard/redux_AnomalyDiagnosis/AnomalyDiagnosisReducer";
import plantSchematic from "./dashboard/redux_PlantSchematic/PlantSReducer";
import pid from "./dashboard/redux_PID/PIDReducer";
import auditLogs from "./dashboard/redux/AuditLogReducer";
import report from "./dashboard/redux/ReportReducer";
import reporting from "./dashboard/redux/ReportingReducer";
import ML from "./dashboard/redux/MLReducer";
import ruleBasedOptimizationCT from "./dashboard/redux/RuleBasedOptimizationCTReducer";
import ruleBasedOptimizationCDWP from "./dashboard/redux/RuleBasedOptimizationCDWPReducer";
import ruleBasedOptimizationCHWP from "./dashboard/redux/RuleBasedOptimizationCHWPReducer";
import dwh from "./dwh/redux/DwhReducer";

export default combineReducers({
    authentication,
    home,
    portfolio,
    analytics,
    diagnosis,
    plantSchematic,
    pid,
    auditLogs,
    report,
    reporting,
    ML,
    ruleBasedOptimizationCT,
    ruleBasedOptimizationCDWP,
    ruleBasedOptimizationCHWP,
    dwh
});
