import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";

const LoadingText = ({ text = "" }) => {
    return (
        <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item>
                <CircularProgress size={18} />
            </Grid>
            <Grid item>
                <Typography>{text}</Typography>
            </Grid>
        </Grid>
    );
};

export default LoadingText;
