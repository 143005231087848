/* eslint-disable jsx-a11y/anchor-is-valid  */
/* eslint-disable no-unused-vars  */
/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    Link
} from "@material-ui/core";
import { connect } from "react-redux";
import SiteOverviewChart from "../components/SiteOverviewChart";
import URL from "../../../utils/URL";
import api from "../../../utils/API";
import AnalyticsActions from "../../redux_Analytics/AnalyticsActions";

const AnalyticsOverview = props => {
    const { changeTab, alarmConfig, fetchAlarmConfig } = props;
    const [worksheets, setWorksheets] = useState([]);
    const addWorksheet = worksheet => {
        setWorksheets(prev => [...prev, worksheet]);
    };

    const isMounted = useRef(false);

    const [lifeCycleRows, setLifeCycleRows] = useState([]);
    const [faultRows, setFaultRows] = useState([]);

    const handleClickLifeCycle = () => {
        changeTab("equipmentLifecycle");
    };

    const handleClickFault = () => {
        changeTab("anomalyDetection");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const lifeCycleResponse = await api.get(URL.DATA.ANALYTICS.OVERVIEW.LIFECYCLE, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                const faultResponse = await api.get(URL.DATA.ANALYTICS.OVERVIEW.FAULT, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });

                if (isMounted.current) {
                    setLifeCycleRows(lifeCycleResponse.data);
                    setFaultRows(faultResponse.data);
                }
                fetchAlarmConfig();
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, []);

    const { isLiveData, defaultDate } = localStorage;

    const initialFilter = ws => {
        if (isLiveData === "false") {
            let date = new Date(parseInt(defaultDate, 10));
            date = date.getTime() - date.getTimezoneOffset() * 60 * 1000 - 1;
            // Skip Equipment Lifecycle Management chart
            if (ws.getName() === "Chiller 1") {
                return;
            }
            ws.applyRelativeDateFilterAsync("SYS_TS_DATETIME", {
                anchorDate: new Date(date),
                periodType: "Minute",
                rangeType: "LASTN",
                rangeN: 3 * 24 * 60
            });
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(date));
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(date));
        } else {
            const lastUpdate = parseInt(localStorage.getItem("lastUpdate"), 10);
            let date = new Date(lastUpdate);
            // date = date.getTime() - date.getTimezoneOffset() * 60 * 1000 - 1;
            date = date.getTime() - date.getTimezoneOffset() * 2 * 60 * 1000;
            // Skip Equipment Lifecycle Management chart
            if (ws.getName() === "Chiller 1") {
                return;
            }
            ws.applyRelativeDateFilterAsync("SYS_TS_DATETIME", {
                anchorDate: new Date(date),
                periodType: "Minute",
                rangeType: "LASTN",
                rangeN: 3 * 24 * 60
            });
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(date));
            ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(date));
        }
    };

    useEffect(() => {
        const lastUpdate = parseInt(localStorage.getItem("lastUpdate"), 10);
        if (worksheets.length > 0) {
            let date = new Date(lastUpdate);
            // date = date.getTime() - date.getTimezoneOffset() * 60 * 1000 - 1;
            date = date.getTime() - date.getTimezoneOffset() * 2 * 60 * 1000;
            worksheets.forEach(ws => {
                // Skip Equipment Lifecycle Management chart
                if (ws.getName() === "Chiller 1") {
                    return;
                }
                ws.applyRelativeDateFilterAsync("SYS_TS_DATETIME", {
                    anchorDate: new Date(date),
                    periodType: "Minute",
                    rangeType: "LASTN",
                    rangeN: 3 * 24 * 60
                });
                ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFSTART", new Date(date));
                ws.getWorkbook().changeParameterValueAsync("WEBAPP_REFEND", new Date(date));
            });
        }
    }, [worksheets]);

    const useStyles = makeStyles(theme => ({
        paper: {
            padding: theme.spacing(0.5),
            color: theme.palette.text.secondary,
            height: "250px" // heightMulti
        },
        table: {
            padding: theme.spacing(0),
            color: theme.palette.text.secondary,
            height: "250px",
            overflowY: "auto"
        },
        spacing: {
            padding: theme.spacing(0.5),
            backgroundColor: "transparent",
            color: theme.palette.common.black,
            height: "1vh" // heightMulti
        },
        header: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    }));

    const classes = useStyles();
    const borderStyle = {
        borderRadius: "0 4px 0 0"
    };

    return (
        <Grid container spacing={2}>
            <SiteOverviewChart
              url={URL.DATA.ANALYTICS.OVERVIEW.SYS_PLANT_EFF}
              addWorksheet={addWorksheet}
              double
              xs={12}
              sm={12}
              md={6}
              lg={6}
              initialFilter={initialFilter}
            />
            <SiteOverviewChart
              url={URL.DATA.ANALYTICS.OVERVIEW.SYS_PLANT_KW_RT}
              addWorksheet={addWorksheet}
              double
              xs={12}
              sm={12}
              md={6}
              lg={6}
              alarmConfig={alarmConfig}
              alarmConfigParam="CHWS_HDR_T"
              initialFilter={initialFilter}
            />
            <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        <Link onClick={handleClickFault} href="#">
                            Faults & Alarms
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.spacing} />
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.table}>
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.header}>Start</TableCell>
                                    {/* <TableCell align="right" className={classes.header}>
                      Code
                    </TableCell> */}
                                    <TableCell align="right" className={classes.header}>
                                        Parameter
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={classes.header}
                                      style={borderStyle}
                                    >
                                        Description
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {faultRows.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {new Date(parseInt(row.start, 10)).toLocaleString()}
                                        </TableCell>
                                        {/* <TableCell align="right">{row.code}</TableCell> */}
                                        <TableCell align="right">{row.parameter}</TableCell>
                                        <TableCell align="left">{row.description}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
            {/* <Grid container item xs={12} md={3}>
          <Grid item xs={12}>
            <Typography variant="h6">
              <Link onClick={handleClickLifeCycle} href="#">
                Equipment Lifecycle Management
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.spacing} />
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.table}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header}>ID</TableCell>
                    <TableCell align="right" className={classes.header}>
                      Name
                    </TableCell>
                    <TableCell align="right" className={classes.header}>
                      Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lifeCycleRows.map(row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                                ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid> */}
            <SiteOverviewChart
              url={URL.DATA.ANALYTICS.OVERVIEW.LIFECYCLE}
              addWorksheet={addWorksheet}
              xs={12}
              md={6}
              handleTitleLink={handleClickLifeCycle}
            />
        </Grid>
    );
};

const mapStateToProps = state => ({
    alarmConfig: state.analytics.alarmConfig
});
const mapDispatchToProps = dispatch => ({
    fetchAlarmConfig: () => {
        dispatch(AnalyticsActions.fetchAlarmConfig());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsOverview);
