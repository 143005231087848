/* eslint-disable no-unused-vars */
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Button, Dialog, DialogActions, DialogTitle, makeStyles } from "@material-ui/core";
// import { createConfigItem } from "@babel/core";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";

const EditSessionTable = forwardRef((props, ref) => {
    const { open, setOpen, handleClick } = props;
    const [error, setError] = useState("");

    const emptyConfig = {
        createdAt: VERBIAGE.TEXT.EMPTY_STRING,
        email: VERBIAGE.TEXT.EMPTY_STRING,
        token: VERBIAGE.TEXT.EMPTY_STRING,
        updatedAt: VERBIAGE.TEXT.EMPTY_STRING,
        username: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            const rowData = {
                ...config,
                ...data
            };
            updateConfig(rowData);
        },
        getConfig() {
            return config;
        }
    }));

    const handleSubmit = async () => {
        const url = URL.DATA.ADMIN_SETTINGS.SESSIONS;
        try {
            await api.delete(`${url}`, {
                headers: {
                    "x-auth-token": localStorage.token
                },
                data: {
                    token: config.token,
                    email: config.email
                }
            });
            setError("");
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
{' '}
End session for
{config.username}
?
            </DialogTitle>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default EditSessionTable;
