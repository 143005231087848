/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import SiteOverviewChart from "./SiteOverviewChart";
import RangeSlider from "./RangeSlider";
import URL from "../../../utils/URL";

const AnomalyDetectionCharts = props => {
    const {
        addWorksheet,
        newChartPositionDetails,
        newChartPositionOverview,
        code,
        parameter,
        tabValue,
        initialFilter,
        value
    } = props;
    const getDetailsChartList = () => {
        const detailsChartList = [];
        if (newChartPositionDetails.length) {
            const lastIndex = newChartPositionDetails[newChartPositionDetails.length - 1];
            for (let i = newChartPositionDetails[0]; i <= lastIndex; i++) {
                if (newChartPositionDetails.indexOf(i) !== -1) {
                    const url = `${URL.DATA.ANALYTICS.CHARTS.CHART}/CHART_${i}`;
                    // const url =
                    //     tabValue === "details"
                    //         ? `${URL.DATA.ANALYTICS.CHARTS.CHART}/CHART_${i}`
                    //         : `${URL.DATA.ANALYTICS.CHARTS.CHART}/CHART_${i}/system`;
                    detailsChartList.push(
                        newChartPositionDetails.length === 1 ? (
                            <SiteOverviewChart
                              key={`${code}${parameter}${i}`}
                              url={url}
                              addWorksheet={addWorksheet}
                              xs={12}
                              combined
                              overview
                              initialFilter={initialFilter}
                              value={value}
                            />
                        ) : (
                            <SiteOverviewChart
                              key={`${code}${parameter}${i}`}
                              url={url}
                              addWorksheet={addWorksheet}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              initialFilter={initialFilter}
                              value={value}
                            />
                        ),
                    );
                } else {
                    detailsChartList.push(
                        <Grid
                          key={`${code}${parameter}${i}`}
                          container
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          initialFilter={initialFilter}
                          value={value}
                        />,
                    );
                }
            }
        }
        return detailsChartList;
    };
    const getOverviewChartList = () => {
        const overviewChartList = [];
        if (newChartPositionOverview.length) {
            const lastIndex = newChartPositionOverview[newChartPositionOverview.length - 1];
            for (let i = newChartPositionOverview[0]; i <= lastIndex; i++) {
                if (newChartPositionOverview.indexOf(i) !== -1) {
                    const url = `${URL.DATA.ANALYTICS.CHARTS.CHART}/CHART_${i}/system`;
                    // const url =
                    //     tabValue === "details"
                    //         ? `${URL.DATA.ANALYTICS.CHARTS.CHART}/CHART_${i}`
                    //         : `${URL.DATA.ANALYTICS.CHARTS.CHART}/CHART_${i}/system`;
                    overviewChartList.push(
                        newChartPositionOverview.length === 1 ? (
                            <SiteOverviewChart
                              key={`${code}${parameter}${i}`}
                              url={url}
                              addWorksheet={addWorksheet}
                              xs={12}
                              combined
                              overview
                              initialFilter={initialFilter}
                              value={value}
                            />
                        ) : (
                            <SiteOverviewChart
                              key={`${code}${parameter}${i}`}
                              url={url}
                              addWorksheet={addWorksheet}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              initialFilter={initialFilter}
                              value={value}
                            />
                        ),
                    );
                } else {
                    overviewChartList.push(
                        <Grid
                          key={`${code}${parameter}${i}`}
                          container
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          initialFilter={initialFilter}
                          value={value}
                        />,
                    );
                }
            }
        }
        return overviewChartList;
    };
    const getParameterChart = () => {
        return (
            <Grid container>
                <SiteOverviewChart
                  key={`${code}${parameter}`}
                  addWorksheet={addWorksheet}
                  url={URL.DATA.ANALYTICS.CHARTS.CHART_1}
                  xs={12}
                  tall
                  initialFilter={initialFilter}
                  value={value}
                />
            </Grid>
        );
    };
    if (newChartPositionDetails || newChartPositionOverview) {
        return (
            <Grid>
                {tabValue === "parameter" ? (
                    getParameterChart()
                ) : tabValue === "details" ? (
                    <Grid container item>
                        {getDetailsChartList()}
                    </Grid>
                ) : (
                    <Grid container item>
                        {getOverviewChartList()}
                    </Grid>
                )}
            </Grid>
        );
    }
    return <Grid />;
};
const mapStateToProps = state => ({
    newChartPositionDetails: state.analytics.chartData.charts.details,
    newChartPositionOverview: state.analytics.chartData.charts.overview,
    code: state.analytics.chartData.code,
    parameter: state.analytics.chartData.parameter
});
export default connect(mapStateToProps)(AnomalyDetectionCharts);
