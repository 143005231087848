const HomeActionTypes = {
    SET_PASSWORD: "authentication/SET_PASSWORD",
    SET_DATA: "data/SET_DATA",
    SET_LINE_GRAPH: "data/LINE_GRAPH",
    SET_DATA_COMPOSITE: "data/SET_DATA_COMPOSITE",
    SET_POST_DATA_COMPOSITE: "data/SET_POST_DATA_COMPOSITE",
    SET_COST: "data/SET_COST",
    SET_SYSTEM_OVERVIEW: "data/SET_SYSTEM_OVERVIEW",
    SET_SAVINGS: "data/SET_SAVINGS",
    SET_CHILLER_GAUGE: "data/SET_CHILLER_GAUGE",
    SET_PLANT: "data/SET_PLANT",
    SET_INTERVAL: "data/SET_INTERVAL",
    TOGGLE_DESKTOP_MOBILE: "data/TOGGLE_DESKTOP_MOBILE",
    SET_ORIENTATION: "data/SET_ORIENTATION"
};

export default HomeActionTypes;
