import React, { useState, useCallback, useEffect, useRef } from "react";

import API from "../../../utils/API";
import URL from "../../../utils/URL";
import useQueryParams from "../../../hooks/useQueryParams";

import SiteSelector from "../SiteSelector";
import ChillerSelector from "./ChillerSelector";
import DWHDataTable from "../DwhDataTable";
import EditChillerSpecPartload from "./EditChillerSpecPartload";

const ChillerSpecPartload = () => {
    const { getQuery } = useQueryParams();
    const chId = getQuery("chId");

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const editRef = useRef();

    const fetchData = useCallback(async id => {
        try {
            setLoading(true);

            const url = `${URL.DATA.DWH.CHILLER_PARTLOAD}/${id}/chiller`;
            const response = await API.get(url, {
                headers: {
                    "x-auth-token": localStorage.token
                }
            });
            if (response.data) {
                setData(response.data);
            }
        } catch (err) {
            if (err.response?.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (chId) {
            fetchData(chId);
        }
    }, [chId, fetchData]);

    const handleClickDataTable = (e, { action, params }) => {
        switch (action) {
            case "add":
                editRef.current.init();
                setIsEdit(false);
                setOpen(true);
                break;
            case "edit":
                editRef.current.update({
                    ...data[params.dataIndex]
                });
                setIsEdit(true);
                setOpen(true);
                break;
            default:
        }
    };

    const handleSaveSuccess = id => {
        fetchData(id);
    };

    const columns = [
        { actions: ["edit"] },
        { label: "Part Load %", name: "chPartloadPerc" },
        { label: "Part Load Value", name: "chPartloadValue" },
        { label: "Active", name: "isActive", _options: { type: "boolean" } },
        { label: "Created Date", name: "createdAt", _options: { type: "date" }, narrow: true },
        { label: "Updated Date", name: "updatedAt", _options: { type: "date" }, narrow: true }
    ];

    return (
        <>
            <SiteSelector />
            <ChillerSelector />
            {chId && (
                <DWHDataTable
                  title="Part Load"
                  loading={loading}
                  data={data}
                  columns={columns}
                  onClick={handleClickDataTable}
                />
            )}
            <EditChillerSpecPartload
              ref={editRef}
              isEdit={isEdit}
              open={open}
              setOpen={setOpen}
              onSuccess={handleSaveSuccess}
            />
        </>
    );
};

export default ChillerSpecPartload;
