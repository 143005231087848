import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";

const EditChartMapTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");

    const emptyConfig = {
        usage: VERBIAGE.TEXT.EMPTY_STRING,
        main: VERBIAGE.TEXT.EMPTY_STRING,
        position: VERBIAGE.TEXT.EMPTY_STRING,
        link: VERBIAGE.TEXT.EMPTY_STRING,
        name: VERBIAGE.TEXT.EMPTY_STRING,
        description: VERBIAGE.TEXT.EMPTY_STRING,
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        status: VERBIAGE.TEXT.EMPTY_STRING,
        theme: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleUsage = ({ target: { value } }) => {
        updateConfig({ ...config, usage: value });
    };

    const handleMain = ({ target: { value } }) => {
        updateConfig({ ...config, main: value });
    };

    const handlePosition = ({ target: { value } }) => {
        updateConfig({ ...config, position: value });
    };

    const handleLink = ({ target: { value } }) => {
        updateConfig({ ...config, link: value });
    };

    const handleName = ({ target: { value } }) => {
        updateConfig({ ...config, name: value });
    };

    const handleDescription = ({ target: { value } }) => {
        updateConfig({ ...config, description: value });
    };

    const handleStatus = ({ target: { value } }) => {
        updateConfig({ ...config, status: value });
    };

    const handleTheme = ({ target: { value } }) => {
        updateConfig({ ...config, theme: value });
    };

    const handleSubmit = async () => {
        if (
            !config.usage ||
            !config.main ||
            (!config.position && config.position < 0) ||
            !config.link ||
            !config.name ||
            !config.status ||
            !config.siteId ||
            !config.theme
        ) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }
        const data = {
            usage: config.usage,
            main: config.main,
            position: config.position,
            link: config.link,
            name: config.name,
            description: config.description,
            siteId: config.siteId,
            status: config.status,
            theme: config.theme
        };
        const url = URL.DATA.ANALYTICS.SETTINGS.CHART_MAP;
        try {
            if (isEdit) {
                await api.put(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } else {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            }
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} the chart mapping, please ${
                    isEdit ? "update" : "enter"
                } the details below.`}
            </DialogTitle>

            <DialogContent>
                <div className={EditChartMapTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.SITE_ID}
                      value={config.siteId}
                      type="text"
                      disabled={!!config.siteId}
                    />
                </div>
                <div className={EditChartMapTable.styles.inputContainer}>
                    <DropdownSelect
                      name={config.usage}
                      options={VERBIAGE.TEXT.CHART_MAP_USAGE_OPTIONS}
                      onChange={handleUsage}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_USAGE}
                    />
                </div>
                <div className={EditChartMapTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MAIN}
                      onChange={handleMain}
                      value={config.main}
                      type="text"
                    />
                </div>
                <div className={EditChartMapTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_NAME}
                      onChange={handleName}
                      value={config.name}
                      type="text"
                    />
                </div>
                <div className={EditChartMapTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_DESCRIPTION}
                      onChange={handleDescription}
                      value={config.description}
                      type="text"
                      multiline
                      rows={4}
                    />
                </div>
                <div className={EditChartMapTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_POSITION}
                      onChange={handlePosition}
                      value={config.position}
                      type="text"
                    />
                </div>
                <div className={EditChartMapTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_LINK}
                      onChange={handleLink}
                      value={config.link}
                      type="text"
                    />
                </div>
                <div className={EditChartMapTable.styles.inputContainer}>
                    <DropdownSelect
                      name={config.theme}
                      options={VERBIAGE.TEXT.THEME_OPTIONS}
                      onChange={handleTheme}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_THEME}
                    />
                </div>
                <div className={EditChartMapTable.styles.inputContainer}>
                    <DropdownSelect
                      name={config.status}
                      options={VERBIAGE.TEXT.STATUS_OPTIONS}
                      onChange={handleStatus}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.SELECT_STATUS}
                    />
                </div>
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditChartMapTable.styles = {
    inputContainer: "webclient__mapTable-input",
    errorText: "webclient__mapTable-error-text"
};

export default EditChartMapTable;
