import { withStyles } from "@material-ui/core";
import { green, grey, red, yellow } from "@material-ui/core/colors";
import StopIcon from "@material-ui/icons/Stop";

const AirflowStateIcon = withStyles(() => {
    const getColor = props => {
        switch (props.state) {
            case "success":
                return green[800];
            case "running":
                return green[400];
            case "queued":
                return grey[500];
            case "failed":
                return red[800];
            case "upstream_failed":
                return red[400];
            case "up_for_retry":
            case "up_for_reschedule":
                return yellow[500];
            default:
                return grey[100];
        }
    };
    return {
        root: {
            color: props => getColor(props)
        }
    };
})(StopIcon);

export default AirflowStateIcon;
