/* eslint-disable max-len */
import React from "react";
import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { VERBIAGE } from "../../../utils/enums/index";

const DropdownSelectSite = props => {
    const { name, options, onChange, placeholder, isMulti, disabled } = props;

    // Override border color of input
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            }
        }
    });

    return (
        <MuiThemeProvider theme={themeOverride}>
            <FormControl variant="outlined" fullWidth>
                <InputLabel>{placeholder}</InputLabel>
                <Select
                  disabled={disabled}
                  label={placeholder}
                  value={name}
                  onChange={onChange}
                  fullWidth
                  variant="outlined"
                  multiple={isMulti}
                  MenuProps={{ disableScrollLock: true }}
                >
                    <MenuItem value="" disabled>
                        Please select...
                    </MenuItem>
                    {options.map(option => {
                        return (
                            <MenuItem key={option.siteId} value={option.siteId}>
                                {`${option.siteId} : ${option.siteName}`}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </MuiThemeProvider>
    );
};

export default DropdownSelectSite;
