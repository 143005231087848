import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { Gauge } from "../../../../assets/gauge.min";
import "../styles/dialGauge.scss";
import VERBIAGE from "../../../utils/enums/Verbiage";

const createOptions = (staticLabels, staticZones, theme) => {
    return {
        angle: -0.2,
        radiusScale: 0.9,
        pointer: {
            length: 0.5,
            strokeWidth: 0.034,
            color: theme === "dark" ? "white" : "black"
        },
        lineWidth: 0.08,
        staticLabels: {
            ...staticLabels,
            font: "10px sans-serif",
            color: theme === "dark" ? "white" : "black"
        },
        staticZones
    };
};

const DialGaugeChart = ({
    max,
    min,
    labelValue,
    value,
    valueColor,
    staticLabels,
    staticZones,
    unit,
    label
}) => {
    const canvasRef = useRef();
    const canvasContainer = useRef();
    const theme = useTheme();

    useEffect(() => {
        const canvasStyle = {
            height: "100%",
            width: "100%"
        };

        if (canvasContainer) {
            const { offsetWidth, offsetHeight } = canvasContainer.current;

            const idealHeight = offsetWidth * 0.65;

            canvasStyle.height = `${offsetHeight <= idealHeight ? offsetHeight : idealHeight}px`;
            canvasStyle.width = `${offsetWidth}px`;
        }

        if (canvasRef) {
            canvasRef.current.setAttribute("style", "");
            canvasRef.current.setAttribute("height", canvasStyle.height);
            canvasRef.current.setAttribute("width", canvasStyle.width);

            const gauge = new Gauge(canvasRef.current);
            gauge.setOptions(createOptions(staticLabels, staticZones, theme.palette.type));
            gauge.maxValue = max; // set max gauge value
            gauge.setMinValue(min); // Prefer setter over gauge.minValue = 0
            gauge.animationSpeed = 1; // set animation speed (32 is default value)
            gauge.set(value); // set actual value
        }
    }, [max, min, value, staticLabels, staticZones, theme.palette.type]);

    return (
      <div className="dialGaugeContainer">
        <div className="gaugeLabel">{label}</div>
        <div className="canvasContainer" ref={canvasContainer}>
          <canvas ref={canvasRef} className="chart" />
        </div>
        <div
          className="currentValue"
          style={{
                    color:
                        valueColor === VERBIAGE.COLOUR_YELLOW
                            ? VERBIAGE.COLOUR_NEW_YELLOW
                            : valueColor
                }}
        >
          {labelValue}
        </div>
        <div className="unit">{unit}</div>
      </div>
    );
};

DialGaugeChart.propTypes = {
    max: PropTypes.number.isRequired,
    min: PropTypes.number,
    value: PropTypes.number.isRequired,
    labelValue: PropTypes.number.isRequired,
    valueColor: PropTypes.string,
    unit: PropTypes.string,
    label: PropTypes.string,
    staticLabels: PropTypes.object.isRequired,
    staticZones: PropTypes.array.isRequired
};

DialGaugeChart.defaultProps = {
    min: 0,
    valueColor: "#000000",
    label: VERBIAGE.TEXT.EMPTY_STRING,
    unit: VERBIAGE.TEXT.EMPTY_STRING
};

export default DialGaugeChart;
