import React from "react";
import BaselineTable from "./BaselineTable";
import TargetTable from "./TargetTable";
import TariffTable from "./TariffTable";
import EfficiencyParamTable from "./EfficiencyParamTable";
import SavingsOffsetTable from "./SavingsOffsetTable";
import SavingsConfigTable from "./SavingsConfigTable";
import MissingDataMailerTable from "./MissingDataMailerTable";
import SiteConfigTable from "./SiteConfigTable";
import CurrencyConverterTable from "./CurrencyConverterTable";

const MaintenanceTabs = props => {
    const { value } = props;

    return (
        <div>
            {value === "efficiencyParam" && <EfficiencyParamTable />}
            {value === "baselines" && <BaselineTable />}
            {value === "targets" && <TargetTable />}
            {value === "tariffs" && <TariffTable />}
            {value === "savingsOffset" && <SavingsOffsetTable />}
            {value === "savingsConfig" && <SavingsConfigTable />}
            {value === "missingDataMailer" && <MissingDataMailerTable />}
            {value === "siteConfig" && <SiteConfigTable />}
            {value === "currency" && <CurrencyConverterTable />}
        </div>
    );
};

export default MaintenanceTabs;
