import React, { useEffect, useCallback } from "react";
import useTableau from "../../../hooks/useTableau";

import WaterTreatementTableauChartV3 from "./WaterTreatementTableauChartV3";

const WaterTreatmentTableauChart = props => {
    const { url, fullWidth, download, ...chartProps } = props;

    const {
        fetchChart,
        // tableauVersion,
        tableauName,
        tableauLink,
        tableauToken,
        tableauOptions
    } = useTableau({
        options: {
            hideTabs: false
        }
    });

    const fetchWaterTreatmentChart = useCallback(
        dispose => {
            const themeState = localStorage.dark === "true" ? "DARK" : "LIGHT";
            const siteChartUrl = `${url}/${themeState}`;
            fetchChart(siteChartUrl, dispose);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [url],
    );

    useEffect(() => {
        fetchWaterTreatmentChart(true);
    }, [fetchWaterTreatmentChart]);

    return (
        <WaterTreatementTableauChartV3
          fullWidth={fullWidth}
          download={download}
          tableauName={tableauName}
          tableauLink={tableauLink}
          tableauToken={tableauToken}
          tableauOptions={tableauOptions}
          tableauRefresh={() => fetchWaterTreatmentChart(true)}
          {...chartProps}
        />
    );
};

export default WaterTreatmentTableauChart;
