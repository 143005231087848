import React from "react";
import MonitoringData from "./MonitoringData";
import AuditLogTable from "./AuditLogTable";

const MonitoringTab = props => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === "monitoringData" && <MonitoringData />}
            {value === "auditLogs" && <AuditLogTable />}
        </React.Fragment>
    );
};

export default MonitoringTab;
