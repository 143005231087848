/* eslint-disable max-len */
/* eslint-disable no-bitwise */
/* eslint-disable react/destructuring-assignment  */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect, useRef } from "react";
import {
    Accordion,
    AccordionSummary,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    Table,
    TableBody
} from "@material-ui/core";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from "file-saver";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Publish, EditOutlined, ExpandMore } from "@material-ui/icons";
import _ from "lodash";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import { VERBIAGE } from "../../../utils/enums";
import CSVUpload from "./CSVUpload";
import CustomFooter from "./CustomFooter";

const moment = require("moment");

const EfficiencyParamTable = () => {
    const [data, setData] = useState([]);

    const handleCsv = () => {
        // download CSV
        const csvData = [];
        for (let i = 0; i < data.length; i++) {
            const csvRow = {};
            csvRow.siteId = data[i].siteId;
            csvRow.dateStart = moment(data[i].dateStart).format("DD-MMM-YY"); // data[i].dateStart;
            csvRow.multi = data[i].multi;
            csvRow.description = data[i].data[0].description;
            csvRow.option = data[i].option;

            for (let j = 0; j < data[i].data.length; j++) {
                csvRow.timeStartHour = data[i].data[j].timeStartHour;
                csvRow.timeStartMin = data[i].data[j].timeStartMin;
                csvRow.timeEndHour = data[i].data[j].timeEndHour;
                csvRow.timeEndMin = data[i].data[j].timeEndMin;
                csvRow.dayStart = data[i].data[j].dayStart;
                csvRow.dayEnd = data[i].data[j].dayEnd;
                csvRow.baseline = data[i].data[j].baseline;
                csvRow.target = data[i].data[j].target;
                csvRow.currency = data[i].data[j].currency;
                csvRow.tariff = data[i].data[j].tariff;

                csvRow.ctBaseline = data[i].data[j].ctBaseline;
                csvRow.ctTarget = data[i].data[j].ctTarget;
                csvRow.chBaseline = data[i].data[j].chBaseline;
                csvRow.chTarget = data[i].data[j].chTarget;
                csvRow.chwppBaseline = data[i].data[j].chwppBaseline;
                csvRow.chwppTarget = data[i].data[j].chwppTarget;
                csvRow.chwpsBaseline = data[i].data[j].chwpsBaseline;
                csvRow.chwpsTarget = data[i].data[j].chwpsTarget;
                csvRow.cdwpBaseline = data[i].data[j].cdwpBaseline;
                csvRow.cdwpTarget = data[i].data[j].cdwpTarget;

                csvData.push(csvRow);
            }
        }
        const blob = new Blob([jsonToCSV(csvData)], { type: "text/csv;charset=utf-8;" });
        const now = moment().format("YYYYMMDD");
        saveAs(blob, `${csvData[0].siteId}_efficiency_params_${now}.csv`);
        // saveAs(blob, "Gauge Range Data.csv");
    };

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const editRef = useRef();
    const isNarrow = useMediaQuery("(max-width: 600px)");

    const isMounted = useRef(false);

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        dateStart: VERBIAGE.TEXT.EMPTY_STRING,
        multi: VERBIAGE.TEXT.EMPTY_STRING,
        description: VERBIAGE.TEXT.EMPTY_STRING,
        option: VERBIAGE.TEXT.EMPTY_STRING,
        timeStartHour: VERBIAGE.TEXT.EMPTY_STRING,
        timeStartMin: VERBIAGE.TEXT.EMPTY_STRING,
        timeEndHour: VERBIAGE.TEXT.EMPTY_STRING,
        timeEndMin: VERBIAGE.TEXT.EMPTY_STRING,
        dayStart: VERBIAGE.TEXT.EMPTY_STRING,
        dayEnd: VERBIAGE.TEXT.EMPTY_STRING,
        baseline: VERBIAGE.TEXT.EMPTY_STRING,
        target: VERBIAGE.TEXT.EMPTY_STRING,
        currency: VERBIAGE.TEXT.EMPTY_STRING,
        tariff: VERBIAGE.TEXT.EMPTY_STRING,
        ctBaseline: VERBIAGE.TEXT.EMPTY_STRING,
        ctTarget: VERBIAGE.TEXT.EMPTY_STRING,
        chBaseline: VERBIAGE.TEXT.EMPTY_STRING,
        chTarget: VERBIAGE.TEXT.EMPTY_STRING,
        chwppBaseline: VERBIAGE.TEXT.EMPTY_STRING,
        chwppTarget: VERBIAGE.TEXT.EMPTY_STRING,
        chwpsBaseline: VERBIAGE.TEXT.EMPTY_STRING,
        chwpsTarget: VERBIAGE.TEXT.EMPTY_STRING,
        cdwpBaseline: VERBIAGE.TEXT.EMPTY_STRING,
        cdwpTarget: VERBIAGE.TEXT.EMPTY_STRING
    };

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(URL.DATA.MAINTENANCE_SETTINGS.EFFICIENCY_PARAMS, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                const parsedDates = response.data.map(row => {
                    // console.log("row : ", row);
                    // console.log("row.dateStart : ", row.dateStart);
                    return {
                        ...row,
                        multiText: row.multi ? "TRUE" : "FALSE",
                        parsedDate: moment(row.dateStart).format("DD MMM YY HH:mm:ss")
                    };
                });
                // console.log("parsedDates : ", parsedDates);
                if (isMounted.current) {
                    // setData(response.data);
                    setData(parsedDates);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };

        isMounted.current = true;
        fetchData();

        return () => (isMounted.current = false);
    }, [open]);

    const useStyles = makeStyles(() => ({
        table: {
            width: "100%"
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: "16px"
        }
    }))(TableCell);

    const cols = [
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true
                // customBodyRenderLite: (dataIndex, rowIndex) => {
                //     return (
                //         <IconButton
                //             onClick={() => {
                //                 editRef.current.update({
                //                     ...editRef.current.getConfig(),
                //                     ...data[dataIndex]
                //                 });
                //                 setIsEdit(true);
                //                 handleClick();
                //             }}
                //         >
                //             <EditOutlined />
                //         </IconButton>
                //     );
                // }
            }
        },
        { label: "Site ID", name: "siteId" },
        { label: "Date", name: "parsedDate" },
        { label: "Multi", name: "multiText" },
        { label: "Option", name: "option" },
        {
            label: "Day",
            name: "data",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: `From ${row.dayStartText} to ${row.dayEndText}`,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                        {/* Container for color indicator */}
                                        <div
                                          style={{
                                                padding: "3 5 5 5",
                                                borderRadius: "1px",
                                                display: "inline-block",
                                                cursor: "pointer"
                                            }}
                                        >
                                            {/* Actual colour indicator */}
                                            <div
                                              style={{
                                                    width: "14px",
                                                    height: "14px",
                                                    borderRadius: "1px"
                                                    // background: rgba
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "Time",
            name: "data",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: `From ${row.timeStartHour
                                .toString()
                                .padStart(2, "0")}${row.timeStartMin
                                .toString()
                                .padStart(2, "0")} to ${row.timeEndHour
                                .toString()
                                .padStart(2, "0")}${row.timeEndMin.toString().padStart(2, "0")}`,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "Baseline",
            name: "data",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.baseline,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "Target",
            name: "data",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.target,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "Currency",
            name: "data",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.currency,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "Tariff",
            name: "data",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.tariff,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CT Baseline",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.ctBaseline,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CT Target",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.ctTarget,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CH Baseline",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.chBaseline,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CH Target",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.chTarget,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CHWPP Baseline",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.chwppBaseline,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CHWPP Target",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.chwppTarget,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CHWPS Baseline",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.chwpsBaseline,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CHWPS Target",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.chwpsTarget,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CDWP Baseline",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.cdwpBaseline,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            label: "CDWP Target",
            name: "data",
            options: {
                display: "false",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const parsedRow = data[dataIndex].data.map(row => {
                        return {
                            text: row.cdwpTarget,
                            strokeStyle: row.strokeStyle
                        };
                    });
                    return (
                        <React.Fragment>
                            {parsedRow.map(row => {
                                return (
                                    <div key={row.text} style={{ display: "flex", padding: "3px" }}>
                                        <span>{row.text}</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            }
        }
    ];

    const [isRowExpanded, setIsRowExpanded] = useState({});

    const customRowRender = (row, dataIndex, rowIndex) => {
        /*
                const handleAccordion = (event, expanded) => {
                    setIsRowExpanded(prev => {
                        return {
                            ...prev,
                            [dataIndex]: expanded
                        };
                    });
                };
                const rowData = data[dataIndex];
                return (
                    <TableRow key={dataIndex}>
                        <TableCell>
                            <Accordion key={dataIndex} onChange={handleAccordion} elevation={0}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <StyledTableCell>Site ID</StyledTableCell>
                                                <StyledTableCell>{rowData.siteId}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell>Type</StyledTableCell>
                                                <StyledTableCell>{rowData.type}</StyledTableCell>
                                            </TableRow>
                                            {isRowExpanded[dataIndex] ? (
                                                <React.Fragment>
                                                    <TableRow>
                                                        <StyledTableCell>Min Value</StyledTableCell>
                                                        <StyledTableCell>
                                                            {rowData.minValue}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <StyledTableCell>Max Value</StyledTableCell>
                                                        <StyledTableCell>
                                                            {rowData.maxValue}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <StyledTableCell>Threshold</StyledTableCell>
                                                        <StyledTableCell>
                                                            {cols[4].options.customBodyRenderLite(
                                                                dataIndex,
                                                                rowIndex,
                                                            )}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ) : null}
                                        </TableBody>
                                    </Table>
                                </AccordionSummary>
                            </Accordion>
                        </TableCell>
                    </TableRow>
                );
        */
    };

    const options = {
        selectableRows: "none",
        fixedHeader: true,
        tableBodyHeight: "600px",
        filter: false,
        print: false,
        viewColumns: true,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title="Upload CSV">
                        <IconButton onClick={() => setOpen(true)}>
                            <Publish />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
        onDownload: () => {
            handleCsv();
            return false;
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changeRowsPerPage={changeRowsPerPage}
                  changePage={changePage}
                  textLabels={textLabels}
                />
            );
        },
        customRowRender: isNarrow ? customRowRender : undefined
    };

    const getMuiTheme = () =>
        isNarrow
            ? createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "0 16 0 16"
                          }
                      },
                      MuiAccordionSummary: {
                          root: {
                              padding: 0
                          },
                          content: {
                              margin: 0,
                              "&$expanded": {
                                  margin: 0
                              }
                          }
                      }
                  }
              })
            : createMuiTheme({
                  breakpoints: {
                      values: {
                          xs: 0,
                          sm: 400,
                          md: 600,
                          lg: 1280,
                          xl: 1920
                      }
                  },
                  overrides: {
                      MUIDataTableBodyCell: {
                          root: {
                              fontSize: "1.25rem"
                          }
                      },
                      MuiTableCell: {
                          root: {
                              fontSize: "1.25rem",
                              padding: "5px"
                          }
                      }
                  }
              });

    const theme = getMuiTheme();

    return (
        <div>
            {/* <EditSchematicsTable
              isEdit={isEdit}
              open={open}
              handleClick={handleClick}
              setOpen={setOpen}
              ref={editRef}
            /> */}
            <CSVUpload
              open={open}
              setOpen={setOpen}
              handleClick={handleClick}
              url={URL.DATA.MAINTENANCE_SETTINGS.EFFICIENCY_PARAMS_BULK}
            />
            <div className={classes.table}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      title="Efficiency Parameter Data"
                      data={data}
                      columns={cols}
                      options={options}
                    />
                </MuiThemeProvider>
            </div>
        </div>
    );
};

export default EfficiencyParamTable;
