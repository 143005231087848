import React from "react";
import AdminTable from "./AdminTable";
import MaintenanceTable from "./MaintenanceTable";
import VersionHistoryTable from "./VersionHistoryTable";
import SessionTable from "./SessionTable";

const AdminSettingsTabs = props => {
    const { value } = props;

    return (
        <div>
            {value === "adminList" && <AdminTable />}
            {value === "maintenance" && <MaintenanceTable />}
            {value === "history" && <VersionHistoryTable />}
            {value === "sessionList" && <SessionTable />}
        </div>
    );
};

export default AdminSettingsTabs;
