/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import TextInput from "./TextInput";
import DropdownSelect from "./DropdownSelect";
import api from "../../../utils/API";
import URL from "../../../utils/URL";
import VERBIAGE from "../../../utils/enums/Verbiage";
import "../styles/mapTable.scss";

const EditMaintenanceTable = forwardRef((props, ref) => {
    const { isEdit, open, setOpen, handleClick } = props;

    const [error, setError] = useState("");
    const [siteOptions, setSiteOptions] = useState([]);

    const emptyConfig = {
        siteId: VERBIAGE.TEXT.EMPTY_STRING,
        notification: VERBIAGE.TEXT.EMPTY_STRING,
        date: VERBIAGE.TEXT.EMPTY_STRING,
        reminder: VERBIAGE.TEXT.EMPTY_STRING
    };

    const [config, updateConfig] = useState(emptyConfig);

    useImperativeHandle(ref, () => ({
        update(data) {
            updateConfig({
                ...config,
                ...data
            });
        },
        getConfig() {
            return config;
        }
    }));

    const handleDate = event => {
        const newDate = new Date(event._d).getTime();
        updateConfig({ ...config, date: newDate });
    };

    const handleReminder = event => {
        const newReminder = new Date(event._d).getTime();
        updateConfig({ ...config, reminder: newReminder });
    };

    const handleSite = ({ target: { value } }) => {
        updateConfig({ ...config, siteId: value });
    };

    const handleNotification = ({ target: { value } }) => {
        updateConfig({ ...config, notification: value });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await api.get(URL.DATA.PLANT_SETTINGS.SITES, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
                const options = ["all"];
                data.forEach(site => {
                    options.push(site.siteId);
                });
                setSiteOptions(options);
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            }
        };
        fetchData();
    }, [open]);

    const handleSubmit = async () => {
        if (!config.date || !config.reminder || !config.siteId || !config.notification) {
            setError(VERBIAGE.ERROR_TEXT.EMPTY_FIELDS);
            return;
        }

        const data = {
            date: config.date,
            reminder: config.reminder,
            siteId: config.siteId,
            notification: config.notification
        };
        const url = URL.DATA.ADMIN_SETTINGS.MAINTENANCE;
        try {
            if (isEdit) {
                await api.put(`${url}/${config.id}`, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            } else {
                await api.post(url, data, {
                    headers: {
                        "x-auth-token": localStorage.token
                    }
                });
            }
            setError("");
        } catch (err) {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
            setError(VERBIAGE.ERROR_TEXT.ERROR_OCCURED);
            return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        error: {
            color: theme.palette.error.dark,
            variant: "subtitle1",
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    // Override border color of input
    const theme = useTheme();
    const themeOverride = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                notchedOutline: {
                    borderWidth: "1px",
                    borderColor:
                        theme.palette.type === "dark" ? "#444444" : VERBIAGE.COLOUR_DARK_BLUE // "#3865A7"
                }
            }
        }
    });

    return (
        <Dialog open={open} onClose={handleClick} aria-labelledby="form-dialog-title">
            <DialogTitle>
                {`To ${isEdit ? "edit" : "add to"} the maintenance list, please ${
                    isEdit ? "update" : "enter"
                } the details below.`}
            </DialogTitle>

            <DialogContent>
                <div className={EditMaintenanceTable.styles.inputContainer}>
                    <DropdownSelect
                      name={config.siteId}
                      options={siteOptions}
                      onChange={handleSite}
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.SITE}
                    />
                </div>
                <div className={EditMaintenanceTable.styles.inputContainer}>
                    <TextInput
                      placeholder={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MT_NOTIFICATION}
                      onChange={handleNotification}
                      value={config.notification}
                      type="text"
                    />
                </div>
                <div className={EditMaintenanceTable.styles.inputContainer}>
                    <MuiThemeProvider theme={themeOverride}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDateTimePicker
                              variant="dialog"
                              label={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MT_DATE}
                              inputVariant="outlined"
                              value={config.date}
                              onChange={handleDate}
                              format="DD/MM/YYYY HH:mm"
                              fullWidth
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </div>
                <div className={EditMaintenanceTable.styles.inputContainer}>
                    <MuiThemeProvider theme={themeOverride}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDateTimePicker
                              variant="dialog"
                              label={VERBIAGE.PLACEHOLDER_TEXT.ENTER_MT_REMINDER}
                              inputVariant="outlined"
                              value={config.reminder}
                              onChange={handleReminder}
                              format="DD/MM/YYYY HH:mm"
                              fullWidth
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </div>
                {!!error && (
                    <div className={classes.error}>
                        <Typography>{error}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    {VERBIAGE.BUTTONS.SUBMIT}
                </Button>
                <Button
                  className={classes.error}
                  onClick={() => {
                        setError("");
                        setOpen(false);
                    }}
                >
                    {VERBIAGE.BUTTONS.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

EditMaintenanceTable.styles = {
    inputContainer: "webclient__mapTable-input",
    errorText: "webclient__mapTable-error-text"
};

export default EditMaintenanceTable;
