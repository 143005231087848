/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

import MLActions from "../../redux/MLActions";
import useMLConfig from "./hooks/useMLConfig";

const useTooltipStyles = makeStyles(theme => ({
    arrow: {
        color: theme.palette.common.black
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 11
    }
}));
const PIDSPScada = setpoint => {
    const { scadaPoolingIntervalInSeconds } = useMLConfig();
    const { SP, scada_status: enabled, scada_registry: address, scadaPooling } = setpoint;
    const [colorWeight, setColorWeight] = useState(500);

    const poolingRef = useRef();

    const clearPoolingCb = useCallback(() => {
        if (poolingRef.current) {
            console.log("clearPoolingCb:", poolingRef.current);
            clearInterval(poolingRef.current);
            setColorWeight(500);
        }
    }, []);

    const dispatch = useDispatch();
    const poolingCb = useCallback(
        async pooling => {
            console.log(`${SP}pooling:poolingCb:starting`);
            const poolingLeftInMs = pooling - new Date();
            if (poolingLeftInMs > 0) {
                dispatch(MLActions.getSetpointStatus(SP));
            } else {
                console.log(`${SP}pooling:poolingCb:destroy!`);
                clearPoolingCb();
            }
        },
        [SP, dispatch, clearPoolingCb],
    );

    useEffect(() => {
        if (scadaPooling) {
            const poolingInMs = scadaPooling - new Date();
            console.log("scadaPooling:poolingInMs", poolingInMs);
            if (poolingInMs > 0) {
                poolingRef.current = setInterval(() => {
                    poolingCb(scadaPooling);
                }, scadaPoolingIntervalInSeconds);
                setColorWeight(200);
                return () => {
                    console.log("scadaPooling:destroy", poolingRef.current);
                    clearPoolingCb();
                };
            }
        }

        if (poolingRef.current) {
            console.log("scadaPooling:destroy", poolingRef.current);
            clearPoolingCb();
            // eslint-disable-next-line consistent-return
            return;
        }

        return clearPoolingCb();
    }, [scadaPoolingIntervalInSeconds, scadaPooling, poolingCb, clearPoolingCb]);

    const bgColor = enabled ? green[colorWeight] : red[colorWeight];
    const tooltipClasses = useTooltipStyles();
    return (
        <Tooltip arrow placement="top" classes={tooltipClasses} title={`Address ${address}`}>
            <Box pt={1} pb={1} textAlign="center" bgcolor={bgColor} color="black">
                <Typography variant="body2">{enabled ? "ENABLED" : "DISABLED"}</Typography>
            </Box>
        </Tooltip>
    );
};

export default PIDSPScada;
