import React from "react";
import useQueryParams from "../../../hooks/useQueryParams";

import SiteSelector from "../SiteSelector";
import PidSelector from "./PidSelector";
import PidOverviewTabs from "./PidOverviewTabs";

const PidOverview = () => {
    const { getQuery } = useQueryParams();
    const pidId = getQuery("pidId");

    return (
        <>
            <SiteSelector />
            <PidSelector />
            {pidId && <PidOverviewTabs />}
        </>
    );
};

export default PidOverview;
