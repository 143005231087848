/* eslint-disable max-len */
import RuleBasedOptimizationCTActionTypes from "./RuleBasedOptimizationCTActionTypes";

import API from "../../utils/API";
import URL from "../../utils/URL";

// TODO: use interceptor to send auth token header
function getRequestConfig(options = {}) {
    const token = localStorage.getItem("token");
    return {
        timeout: 30000,
        headers: {
            "x-auth-token": token
        },
        ...options
    };
}

export const RuleBasedOptimizationCTCapacityActions = {
    init: payload => ({
        type: RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_CAPACITY,
        payload: { loading: true, error: null, ...payload }
    }),
    setError: error => ({
        type: RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_CAPACITY,
        payload: { loading: false, error }
    }),
    setData: data => ({
        type: RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_CAPACITY_DATA,
        payload: { loading: false, data }
    }),
    fetch: force => dispatch => {
        const payload = {};
        if (force) payload.data = [];
        dispatch(RuleBasedOptimizationCTCapacityActions.init(payload));
        API.get(URL.DATA.RULE_OPTMIZATION.CT_CAPACITY, getRequestConfig())
            .then(({ data }) => dispatch(RuleBasedOptimizationCTCapacityActions.setData(data)))
            .catch(error =>
                dispatch(RuleBasedOptimizationCTCapacityActions.setError(error.message)),
            );
    },
    delete: id => (dispatch, getState) => {
        const {
            ruleBasedOptimizationCT: { ctCapacity }
        } = getState();

        dispatch(
            RuleBasedOptimizationCTCapacityActions.setData(
                ctCapacity.data.filter(item => item.id !== id),
            ),
        );

        API.delete(`${URL.DATA.RULE_OPTMIZATION.CT_CAPACITY}/${id}`, getRequestConfig()).catch(
            error => {
                dispatch(RuleBasedOptimizationCTCapacityActions.setError(error.message));
                dispatch(RuleBasedOptimizationCTCapacityActions.setData(ctCapacity.data));
            },
        );
    }
};

export const RuleBasedOptimizationCTSetpointActions = {
    init: payload => ({
        type: RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_SETPOINT,
        payload: { loading: true, error: null, ...payload }
    }),
    setError: error => ({
        type: RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_SETPOINT,
        payload: { loading: false, error }
    }),
    setData: data => ({
        type: RuleBasedOptimizationCTActionTypes.SET_OPTIMIZATION_CT_SETPOINT_DATA,
        payload: { loading: false, data }
    }),
    fetch: force => dispatch => {
        const payload = {};
        if (force) payload.data = [];
        dispatch(RuleBasedOptimizationCTSetpointActions.init(payload));
        API.get(URL.DATA.RULE_OPTMIZATION.CT_SETPOINT, getRequestConfig())
            .then(({ data }) => dispatch(RuleBasedOptimizationCTSetpointActions.setData(data)))
            .catch(error =>
                dispatch(RuleBasedOptimizationCTSetpointActions.setError(error.message)),
            );
    },
    updateSetpoint: payload => (dispatch, getState) => {
        const { id, value, wbt, dataIndex } = payload;

        const {
            ruleBasedOptimizationCT: { ctSetpoint }
        } = getState();

        const cloneData = [...ctSetpoint.data];
        cloneData[dataIndex][wbt].data.value = value;
        dispatch(RuleBasedOptimizationCTSetpointActions.setData(cloneData));

        const url = `${URL.DATA.RULE_OPTMIZATION.CT_SETPOINT}/setpoint/${id}`;
        API.put(url, { APP_T: value }, getRequestConfig()).catch(error => {
            dispatch(RuleBasedOptimizationCTSetpointActions.setError(error.message));
            dispatch(RuleBasedOptimizationCTSetpointActions.setData(ctSetpoint.data));
        });
    },
    deletePercentage: payload => (dispatch, getState) => {
        const { HJ_DEV_FROM_PERC, HJ_DEV_TO_PERC } = payload;
        const {
            ruleBasedOptimizationCT: { ctSetpoint }
        } = getState();

        const cloneData = [...ctSetpoint.data];
        dispatch(
            RuleBasedOptimizationCTSetpointActions.setData(
                cloneData.filter(item => {
                    return (
                        item.HJ_DEV_FROM_PERC !== HJ_DEV_FROM_PERC &&
                        item.HJ_DEV_TO_PERC !== HJ_DEV_TO_PERC
                    );
                }),
            ),
        );

        const url = `${URL.DATA.RULE_OPTMIZATION.CT_SETPOINT}/percentage`;
        API.delete(url, getRequestConfig({ data: payload })).catch(error => {
            dispatch(RuleBasedOptimizationCTSetpointActions.setError(error.message));
            dispatch(RuleBasedOptimizationCTSetpointActions.setData(ctSetpoint.data));
        });
    },
    deleteWBT: payload => dispatch => {
        dispatch(RuleBasedOptimizationCTSetpointActions.init({ data: [] }));
        const url = `${URL.DATA.RULE_OPTMIZATION.CT_SETPOINT}/wbt`;
        API.delete(url, getRequestConfig({ data: payload }))
            .then(() => dispatch(RuleBasedOptimizationCTSetpointActions.fetch()))
            .catch(error =>
                dispatch(RuleBasedOptimizationCTSetpointActions.setError(error.message)),
            );
    }
};
